import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const ESTADOCIVIL_SEARCH_BY_ID = gql`
    query findEstadoCivilById(
        $id: Int!
    ) {
    findEstadoCivilById(
        id: $id
    ){
        id
        nombre
    }
}
`;

const UPDATE_ESTADOCIVIL_MUTATION = gql`
    mutation updateEstadoCivil(
        $id: ID!
        $nombre: String!
    ) {
        updateEstadoCivil(entity:{
            id: $id
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

const DELETE_ESTADOCIVIL_MUTATION = gql`
    mutation deleteEstadoCivil(
        $id: Int!
    ) {
        deleteEstadoCivil(id: $id)
    }
`;

function EstadoCivilEdit() {
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        ESTADOCIVIL_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findEstadoCivilById?.nombre);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findEstadoCivilById?.nombre
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateEstadoCivilMutation] = useMutation(UPDATE_ESTADOCIVIL_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/estadoCivil";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                showMessageWithTimerAndRedirect('success', t("common.message.confirmation.save.success"), 1500, returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteEstadoCivilMutation] = useMutation(DELETE_ESTADOCIVIL_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/estadoCivil");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateEstadoCivilMutation({
            variables:{
                id: id,
                nombre: nombre
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteEstadoCivilMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="estadoCivil"
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 20,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoCivil.field.nombre")})
                        }
                    }
                ]}
                returnUrl="/estadoCivil"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default EstadoCivilEdit