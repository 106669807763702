import React, { useEffect, useState } from 'react'
import * as Croppie from "croppie/croppie"
import "croppie/croppie.css"    

let croppie;
export default function AvatarModal(props) {
    const {open, setOpen, imageData, uploadAvatar} = props;

    const [avatarCrop, setAvatarCrop] = useState()

    useEffect(() => {
        const croppieElement = document.getElementById('croppie');
        if (open && imageData && croppieElement && croppieElement.className.indexOf('croppie-container') < 0) {
            croppie = new Croppie(croppieElement, {
                viewport: {
                    width: 200,
                    height: 200,
                    type: 'square',
                    format: 'jpeg'
                },
            });
            croppie.bind({
                url: imageData,
                zoom: 0
            });
        }
    })

    const handleCrop = () =>{
        croppie.result({
            type:"base64",
            size:{
                width: 400,
                height: 400
            }
        }).then(base64 => {
            setAvatarCrop(base64);
        });
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type:mimeString});
    }

    const handleUploadCrop = () =>{
        const blobImage = dataURItoBlob(avatarCrop);
        uploadAvatar(blobImage);
    }

    if(open){
        return (
            <>
                <div className="modal fade show d-block" id="modal-default" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Actualizar Avatar</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setOpen(false)}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col mb-3">
                                    <div id="croppie" className="pb-4"></div>
                                </div>
                                <hr className="m-2"/>
                                <div className="col">
                                    <button type="button" className="btn btn-info" onClick={()=>{handleCrop()}}>Cortar Avatar</button>
                                </div>
                                {
                                    avatarCrop &&
                                    (
                                        <>
                                            <hr className="m-2"/>
                                            <div className="col">
                                                <h5 className="text-muted mb-3">Previsualización</h5>
                                            </div>
                                            <div className="col">
                                                <div className="row d-flex flex-row justify-content-around">
                                                    <div className="col-5">
                                                        <img src={avatarCrop} alt="" className="img-fluid elevation-2"/>
                                                    </div>
                                                    <div className="col-5">
                                                        <img src={avatarCrop} alt="" className="img-circle img-fluid elevation-2"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }                               
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>{setOpen(false)}}>Cerrar</button>
                                <button type="button" disabled={!avatarCrop} className="btn btn-info" onClick={()=>{handleUploadCrop(avatarCrop)}}>Guardar Avatar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }else{
        return(<></>);
    }
}
