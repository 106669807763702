import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_OFF_LIMIT_MUTATION = gql`
    mutation createOffLimit(
        $postulante: Int!
        $fechaFin: LocalDate
        $fechaInicio: LocalDate!
        $observaciones: String
    ) {
        createOffLimit(entity:{
            postulante: $postulante
            fechaFin: $fechaFin
            fechaInicio: $fechaInicio
            observaciones: $observaciones
        }) {
            id
        }
    }
`;

function OffLimitEdit() {
    const history = useHistory();

    const [postulante, setPostulante] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [observaciones, setObservaciones] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createOffLimitMutation] = useMutation(CREATE_OFF_LIMIT_MUTATION, {
        onCompleted: () => {
            if(_isMounted.current){
                let returnUrl = "/private/offLimit";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createOffLimitMutation({
            variables:{
                postulante: postulante,
                fechaFin: endDate,
                fechaInicio: startDate,
                observaciones: observaciones
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardCreateEntity
                entityName="offLimit"
                handleSubmit={handleSubmit}
                columnsCount="1"
                values={[
                    {
                        name: "periodo",
                        type: "dateRange",
                        maxLength: 100,
                        setStartDate: setStartDate,
                        startDate: startDate,
                        setEndDate: setEndDate,
                        endDate: endDate,
                        validationRulesStart:{
                            required: true
                        },
                        validationMessagesStart:{
                            required: t("common.message.field.required", {0: t("offLimit.field.periodoDesde")}),
                        },
                        validationMessagesEnd:{
                            required: t("common.message.field.required", {0: t("offLimit.field.periodoHasta")}),
                        },
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-5 col-md-3 col-xs-12",
                    },
                    {
                        type: "autocomplete",
                        name: "postulante",
                        entity:"Postulante",
                        by: "Alias",
                        filterName: "alias",
                        setValue: setPostulante,
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-4 col-xs-12",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("offLimit.field.postulante")}),
                        }
                    },
                    {
                        type: "textarea",
                        name: "observaciones",
                        value: observaciones,
                        setValue: setObservaciones,
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-4 col-xs-12"
                    }
                ]}
                returnUrl="/offLimit"
                >
            </CardCreateEntity>
        </>
    )
}

export default OffLimitEdit