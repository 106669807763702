import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import AuthContext from '../../../context/AuthContext';
import SidebarContext from '../../../context/SidebarContext';
import CreateSeguimientoModal from './modals/CreateSeguimientoModal';
import EditSeguimientoModal from './modals/EditSeguimientoModal';
import OverlayScrollbars from 'admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars';
import DeleteEntityButton from '../../DeleteEntityButton';
import Autocomplete from '../../Autocomplete';
import AccionesSeguimiendoSelect from '../../AccionesSeguimiendoSelect';
import $ from "jquery"
import moment from 'moment';
import MenuIcon from '../../MenuIcon';
import SelectKeyValue from '../../SelectKeyValue';
import Blockquote from '../../Blockquote';

const FIND_SEGUIMIENTO_LIST_BY_FILTER = gql`
    query findSeguimientoPageByFilter(
        $empresa: Int
        $postulante: Int
        $responsable: Int
        $accion: Int
        $proyecto: Int
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findSeguimientoPageByFilter(
            filter:{
                empresa: $empresa
                postulante: $postulante
                responsable: $responsable
                proyecto: $proyecto
                accion: $accion
            }
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fecha
                    importante
                    comentario
                    accion{
                        id
                        nombre
                        icon
                        color
                    }
                    contacto{
                        alias
                    }
                    empresa{
                        contacto{
                            alias
                        }
                    }
                    postulante{
                        contacto{
                            alias
                        }
                    }
                    proyecto{
                        alias
                        ref
                    }
                    responsable{
                        id
                        contacto{
                            alias
                        }
                    }
                }
            }
        }
    }
`;



export default function SeguimientosTimeLine(props) {
    const { hide } = useContext(SidebarContext);
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    Moment.globalLocale = 'es';

    const [labelEmpresa, setLabelEmpresa] = useState(props.labelEmpresa);
    const [labelPostulante, setLabelPostulante] = useState(props.labelPostulante);
    const [labelProyecto, setLabelProyecto] = useState(props.labelProyecto);

    const [seguimientosList, setSeguimientosList] = useState([]);
    const [hasNextPage, setHasNextPage] = useState();
    
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState();

    const [empresa, setEmpresa] = useState(props.empresa);
    const [postulante, setPostulante] = useState(props.postulante);
    const [proyecto, setProyecto] = useState(props.proyecto);
    const [accionSeguimiento, setAccionSeguimiento] = useState(props.accionSeguimiento);
    const [responsable, setResponsable] = useState((!props.empresa&&!props.postulante&&!props.proyecto&&!props.accionSeguimiento&&!props.responsable)?parseInt(userData?.usuario.id):props.responsable);
    
    const [t] = useTranslation("global");

    const [sortOptions] = useState({
        sortColumn: "fecha",
        sortDirection: "DESC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        setPageNumber(0)
    }, [empresa, postulante, proyecto, accionSeguimiento, responsable]);

    const [searchSeguimientos, {loading, error}] = useLazyQuery(FIND_SEGUIMIENTO_LIST_BY_FILTER, {
        onCompleted: (data) => {
            const seguimientos = seguimientosList.concat(data?.findSeguimientoPageByFilter?.edges);
            setHasNextPage(data?.findSeguimientoPageByFilter?.pageInfo?.hasNextPage);               
            setSeguimientosList(seguimientos);
        }
    });

    const refetch = function() {
        if(
            empresa||
            postulante||
            proyecto||
            accionSeguimiento||
            responsable
        ){
            setSeguimientosList([]);
            searchSeguimientos({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    empresa: empresa?empresa:null,
                    postulante: postulante?postulante:null,
                    proyecto: proyecto?proyecto:null,
                    accion: accionSeguimiento?accionSeguimiento:null,
                    responsable: responsable?responsable:null,
                }
            })
        }
    };
    
    useEffect(() => {
        if(
            empresa||
            postulante||
            proyecto||
            accionSeguimiento||
            responsable
        ){
            setSeguimientosList([]);
            searchSeguimientos({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    empresa: empresa?empresa:null,
                    postulante: postulante?postulante:null,
                    proyecto: proyecto?proyecto:null,
                    accion: accionSeguimiento?accionSeguimiento:null,
                    responsable: responsable?responsable:null,
                }
            })
        }else{
            setSeguimientosList([]);
            setHasNextPage(false);
            setPageNumber(0);
        }
    }, [accionSeguimiento, empresa, pageNumber, pageSize, postulante, proyecto, responsable, searchSeguimientos, sortOptions.sortColumn, sortOptions.sortDirection]);

    useEffect(() => {
        if(pageNumber>0 && !loading){
            setTimeout(function() {
                const endPage = $(".end-page");
                if(endPage){
                    endPage[0]?.scrollIntoView();
                }
            }, 200);
        };
    }, [loading, pageNumber]);

    useEffect(() => {
        $(".control-sidebar").css({
            bottom: $(".main-footer").outerHeight(),
            top: $(".main-header").outerHeight()
        });
        OverlayScrollbars(document.querySelectorAll('#scroll-body'), { });
    }, []);
    
    

    const groupBy = function(xs, key) {
        if(xs && xs.length>0){
            return xs.reduce(function(rv, x) {
                (rv[x[key].substr(0, 10)] = rv[x[key].substr(0, 10)] || []).push(x);
                return rv;
            }, {});
        }else{
            return null;
        }
        
    };

    const getSeguimientos = () =>{
        const seguimientosByDate = groupBy(seguimientosList.map(element=>element.node), 'fecha')
        return seguimientosByDate?Object.entries(seguimientosByDate):[];
    }

    const clearFilter = () =>{
        setPostulante(null);
        setEmpresa(null);
        setProyecto(null);
        setAccionSeguimiento(null);
        setResponsable(null);
        setHasNextPage(false)
        setSeguimientosList([]);
    }

    const TimelineTitle = (props) => {
        const {
            empresa,
            setEmpresa,
            postulante,
            setPostulate,
            proyecto,
            setProyecto,
            responsable,
            setResponsable,
            accionSeguimiento,
            setAccionSeguimiento,
            labelEmpresa,
            labelPostulante,
            labelProyecto
        } = props;
        
        return(
            <div className="parrish-form row">
                <div className="col-xs-12 col-sm-12">
                    <div className="parrish-form row p-2">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-postulante"
                                        entity="Postulante"
                                        by="Alias"
                                        placeholder={t("postulante.label.entity")}
                                        filterName="alias"
                                        value={postulante}
                                        initialLabel={labelPostulante}
                                        setValue={(val, label)=>{setPostulate(val); setLabelPostulante(label)}} />
                                </div>
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-proyecto"
                                        entity="Proyecto"
                                        by="Alias"
                                        placeholder={t("postulante.field.proyecto")}
                                        filterName="alias"
                                        value={proyecto}
                                        initialLabel={labelProyecto}
                                        setValue={(val, label)=>{setProyecto(val); setLabelProyecto(label)}} />
                                </div>
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-empresa"
                                        entity="Empresa"
                                        by="Alias"
                                        placeholder={t("postulante.field.empresa")}
                                        filterName="alias"
                                        value={empresa}
                                        initialLabel={labelEmpresa}
                                        setValue={(val, label)=>{setEmpresa(val); setLabelEmpresa(label)}} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <div className="col-4">
                                    <SelectKeyValue
                                        isClerable={true}
                                        entity="Usuario"
                                        placeholder={t("postulante.field.responsable")}
                                        name="value-responsable"
                                        value={responsable}
                                        onChange={(e) => setResponsable(e.target.value)}/>
                                </div>
                                <div className="col-4">
                                    <AccionesSeguimiendoSelect all={true} value={accionSeguimiento} setValue={setAccionSeguimiento}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="card card-info card-outline card-outline-tabs bg-light shadow-none mb-4 bg-light border rounded-0 h-100">
                <div className="card-header d-flex align-items-center p-2">
                    <h3 className="card-title text-dark font-weight-bold mr-auto"><i className="fas fa-shoe-prints fa-rotate-270 mr-2"></i>{t("seguimiento.label.entity")}</h3>
                    <div className="card-tools m-0">
                        <button
                            className="btn btn-xs btn-info bg-info btn-tool ml-2"
                            title={t("common.button.agregar")}
                            onClick={()=>{setShowCreate(true)}}>
                                <i className="fas fa-plus"></i>
                        </button>
                        <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool ml-2" onClick={()=>clearFilter()}>
                            <i className="fas fa-eraser"></i>
                        </button>
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{
                                hide();
                            }}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="border-bottom shadow-sm">
                    <TimelineTitle                       
                        empresa={empresa}
                        setEmpresa={setEmpresa}
                        labelEmpresa={labelEmpresa}
                        postulante={postulante}
                        setPostulate={setPostulante}
                        labelPostulante={labelPostulante}
                        proyecto={proyecto}
                        setProyecto={setProyecto}
                        labelProyecto={labelProyecto}
                        responsable={responsable}
                        setResponsable={setResponsable}
                        accionSeguimiento={accionSeguimiento}
                        setAccionSeguimiento={setAccionSeguimiento}
                    />
                </div>
                <div id="scroll-body" className="card-body">
                    
                    <div className="parrish-form row ">

                        <div className="col-xs-12 col-sm-12 d-flex justify-content-center">
                            {!loading &&
                                !error &&
                                seguimientosList.length<1?
                                <div className="alert alert-info alert-dismissible mt-3 mb-3 p-3 fit-content">
                                    <h5 className="m-0 p-0">{t("seguimiento.message.emptyList")}</h5>
                                </div>
                                :
                                <div className="timeline mb-4 w-100">
                                    {!loading &&
                                        !error && 
                                        getSeguimientos().map((element)=>{
                                            const fecha = element[0];
                                            const seguimientos = element[1];

                                            return(
                                                <React.Fragment key={"accion-label-"+fecha}>
                                                    <div className="time-label">
                                                        <span className="bg-info"><Moment format={"DD MMM yyyy"}>{fecha}</Moment></span>
                                                    </div>
                                                    {
                                                        seguimientos.map((seguimiento)=>{
                                                            return(
                                                                <div key={seguimiento.id}>
                                                                    <i className={"text-white "+seguimiento.accion.icon} style={{backgroundColor: seguimiento.accion.color}}/>
                                                                    <div className="timeline-item">
                                                                        <h3 className="timeline-header w-100 d-flex align-items-center">
                                                                            {seguimiento.importante&&
                                                                                <span className="d-inline-block"><h6 className="m-0 mr-2"><i className="fas fa-exclamation-triangle text-danger" /></h6></span>
                                                                            }
                                                                            
                                                                            <b className={"mr-auto "+(seguimiento.importante?"text-danger":"text-dark")}>{seguimiento.accion.nombre}</b>
                                                                            <div className="float-right d-flex align-items-center">
                                                                                <span className="text-muted small align-self-center mr-2">{seguimiento.responsable.contacto.alias}</span>
                                                                                <span className="text-muted small align-self-center mr-2"><i className="fas fa-clock"/>{moment(seguimiento.fecha).format('HH:mm')}</span>
                                                                                {userData?.usuario?.id === seguimiento.responsable?.id &&
                                                                                    <>
                                                                                        <button type="button" title={t("common.button.editar")} className="btn btn-xs bg-info ml-auto mt-0 mb-0" onClick={()=>{
                                                                                            console.log("seguimiento",seguimiento.id);
                                                                                            setShowEdit(true);
                                                                                            setEditId(seguimiento.id);
                                                                                        }}>
                                                                                            <i className="fas fa-edit"></i>
                                                                                        </button>
                                                                                            <DeleteEntityButton className="ml-2" entity="Seguimiento" id={seguimiento.id} label={seguimiento.accion.nombre+" "+seguimiento.responsable.contacto.alias} onCompleted={()=>{
                                                                                                refetch()
                                                                                            }} />
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </h3>
                                                                        <div className="timeline-body">
                                                                            <Blockquote text={seguimiento.comentario} />
                                                                        </div>
                                                                        <div className="timeline-footer border-top d-flex flex-wrap pt-1 pb-1">
                                                                            {/*TODO mostrar segun corresponda el tipo de busqueda*/}
                                                                            {seguimiento.postulante && !postulante &&
                                                                                <span title={seguimiento.postulante?.contacto?.alias} className="badge label-quicksearch-POSTULANTE text-left mr-2 mb-1 mt-1">
                                                                                    <MenuIcon keyMenu="postulante.label.menu" className="mr-2"/>{seguimiento.postulante?.contacto?.alias}
                                                                                </span>
                                                                            }
                                                                            {seguimiento.proyecto && !proyecto &&
                                                                                <span title={seguimiento.proyecto?.alias} className="badge label-quicksearch-PROYECTO text-left mr-2 mb-1 mt-1">
                                                                                    <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/>{seguimiento.proyecto?.alias}
                                                                                </span>
                                                                            }
                                                                            {seguimiento.empresa && !empresa&&
                                                                                <span title={seguimiento.empresa?.contacto?.alias} className="badge label-quicksearch-EMPRESA text-left mr-2 mb-1 mt-1">
                                                                                    <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{seguimiento.empresa?.contacto?.alias}
                                                                                </span>
                                                                            }
                                                                            {seguimiento.contacto &&
                                                                                <span title={seguimiento.contacto?.alias} className="badge badge-secondary text-left mb-1 mt-1">
                                                                                    <MenuIcon keyMenu="contacto.label.home" className="mr-2"/>{seguimiento.contacto?.alias}
                                                                                </span>
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                                )
                                        })
                                    }
                                    <div>
                                        <i className="fas fa-clock bg-gray end-page"></i>
                                    </div>
                                </div>
                            }
                        </div>
                        {hasNextPage &&
                            <div className="col-xs-12 col-sm-12 d-flex justify-content-center mb-4">
                                <button type="button" className="btn btn-block btn-outline-info" onClick={()=>{setPageNumber(pageNumber+1);}}>
                                    Ver {pageSize} mas...
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CreateSeguimientoModal 
                show={showCreate} 
                setShow={setShowCreate} 
                idEmpresa={empresa} 
                idPostulante={postulante} 
                idProyecto={proyecto}
                labelEmpresa={labelEmpresa}
                labelPostulante={labelPostulante}
                labelProyecto={labelProyecto}
                refetch={refetch}/>
            {editId&&
                <EditSeguimientoModal
                    id={editId}
                    show={showEdit}
                    setShow={setShowEdit}
                    refetch={refetch}
                    idEmpresa={empresa} 
                    idPostulante={postulante} 
                    idProyecto={proyecto}
                    labelEmpresa={labelEmpresa}
                    labelPostulante={labelPostulante}
                    labelProyecto={labelProyecto}/>
            }
        </>
    )
}