import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"

const OFF_LIMIT_SEARCH = gql`
    query findOffLimitPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $postulante: Int
        $estado: String
    ) {
    findOffLimitPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            postulante: $postulante
            estado: $estado
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                postulante{
                    contacto{
                        alias
                    }
                }
                responsable{
                    contacto{
                        alias
                    }
                }
                fechaFin
                fechaInicio
                observaciones
                estado
            }
        }       
    }
}
`;

function OffLimitList(props){
    const {searchFilter, setSearchFilter} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "postulante.contacto.alias",
        sortDirection: "ASC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        OFF_LIMIT_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                postulante: searchFilter.postulante?parseInt(searchFilter.postulante):null,
                estado: searchFilter.estado
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="offLimit"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "autocomplete",
                        name: "postulante",
                        entity:"Postulante",
                        by: "Alias",
                        filterName: "alias"
                    },
                    {
                        type: "options",
                        name: "estado",
                        options: [
                            {
                                value:"ACTIVO",
                                label:"ACTIVO"
                            },
                            {
                                value:"VENCIDO",
                                label:"VENCIDO"
                            }
                        ]
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findOffLimitPageByFilter"
                entityName="offLimit"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/offLimit/edit/"
                createUrl="/offLimit/create"
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id"
                    },
                    {
                        name: "estado",
                        disableSort: true,
                        type: "text",
                        className: "text-center",
                        render: (element) =>{
                            return(<span className={"badge badge-"+(element.node.estado==="ACTIVO"?"success":"danger")}>{element.node.estado}</span>)
                        }
                    },
                    {
                        name: "postulante",
                        customSortKey: "postulante.contacto.alias",
                        type: "text",
                        render: (element) =>{
                            return(<>{element.node.postulante.contacto.alias}</>)
                        }
                    },
                    {
                        name: "fechaInicio",
                        type: "date"
                    },
                    {
                        name: "fechaFin",
                        type: "date"
                    },
                    {
                        name: "responsable",
                        type: "text",
                        render: (element) =>{
                            return(<>{element.node.responsable.contacto.alias}</>)
                        }
                    },
                ]}>
            </CardDatatable>
        </>
    );
}

export default OffLimitList;