import React, { useContext, useEffect, useState } from 'react'
import $ from 'jquery'
import {} from 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars'
import UserTools from '../components/UserTools';
// eslint-disable-next-line no-unused-vars
import * as AdminLte from 'admin-lte';
import SidebarContext from '../context/SidebarContext';
import QuickSearch from '../components/QuickSearch';
import Alertas from '../components/Alertas';
import AuthContext from '../context/AuthContext';
import AsociarProyectoDialog from '../pages/app/gestion/postulante/dialogs/asociarProyecto';
import PushMenu from '../components/PushMenu';
import { useTranslation } from 'react-i18next';
import Fullscreen from '../components/Fullscreen';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '../components/MenuIcon';
import SecuredSection from '../components/SecuredSection';
import PERMISOS_ESPECIALES from '../enums/PermisosEspeciales';

export default function Header() {
    const history = useHistory();
    const [t] = useTranslation("global");
    const { isLogged } = useContext(AuthContext);
    const { setSideBarState, show } = useContext(SidebarContext);
    const [showProyectoDialog, setShowProyectoDialog] = useState(false);

    useEffect(() => {
        PushMenu._jQueryInterface.call($('[data-widget="pushmenu"]'));
        Fullscreen._jQueryInterface.call($('[data-widget="fullscreen"]'));
    }, [])
    
    return (isLogged&&
        <>
        <nav className="main-header navbar navbar-expand navbar-dark navbar-info">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button id="pushmenu-button" className="nav-link btn text-white" data-widget="pushmenu"><i className="fas fa-bars" /></button>
                </li>
            </ul>
            <ul className="navbar-nav m-0 d-none d-lg-flex d-xl-flex">
                <li className="nav-item d-flex">
                    <SecuredSection idPermiso={PERMISOS_ESPECIALES.HABILITAR_QUICKLINK_POSTULANTES}>
                        <button className="nav-link btn bg-gray-dark ml-2" title={t("postulante.label.entity")} onClick={()=>{
                            history.push("/private/postulante");
                        }}>
                            <MenuIcon keyMenu="postulante.label.menu"/>
                        </button>
                    </SecuredSection>
                    <SecuredSection idPermiso={PERMISOS_ESPECIALES.HABILITAR_QUICKLINK_PROYECTOS}>
                        <button className="nav-link btn bg-gray-dark ml-2" title={t("proyecto.label.entity")} onClick={()=>{
                            history.push("/private/proyecto");
                        }}>
                            <MenuIcon keyMenu="proyecto.label.menu"/>
                        </button>
                    </SecuredSection>
                    <SecuredSection idPermiso={PERMISOS_ESPECIALES.HABILITAR_QUICKLINK_EMPRESAS}>
                        <button className="nav-link btn bg-gray-dark ml-2" title={t("empresa.label.entity")} onClick={()=>{
                            history.push("/private/empresa");
                        }}>
                            <MenuIcon keyMenu="empresa.label.menu"/>
                        </button>
                    </SecuredSection>
                </li>
            </ul>
            <QuickSearch />
            <ul className="navbar-nav ml-3 d-none d-lg-flex d-xl-flex">
                {/*<UsersOnline />*/}
                <li className="nav-item d-flex">
                    <button className="nav-link btn bg-warning" title={t("common.label.seguimiento")} onClick={()=>{
                        setSideBarState({
                            mode: "seguimiento",
                            empresa: null,
                            postulante: null,
                            proyecto: null,
                            responsable: null,
                            accionSeguimiento: null,
                            labelEmpresa: null,
                            labelPostulante: null,
                            labelProyecto: null
                        })
                        show();
                    }}>
                        <i className="fas fa-shoe-prints fa-rotate-270"></i>
                    </button>
                    <button className="nav-link btn bg-warning ml-2" title={t("common.label.referencia")} onClick={()=>{
                        setSideBarState({
                            mode: "referencias",
                            labelPostulante: null,
                            labelProyecto: null,
                            postulante: null,
                            proyecto: null,
                            labelEmpresa: null,
                            empresa: null
                        })
                        show();
                    }}>
                        <i className="fas fa-user-secret"></i>
                    </button>
                    <button className="nav-link btn bg-warning ml-2" title={t("common.label.asociarPostulanteAProyecto")} onClick={(e)=>{
                        e.preventDefault();
                        setShowProyectoDialog(true);
                    }}>
                        <i className="fas fa-search-plus"></i>
                    </button>
                    <button className="nav-link btn bg-warning ml-2" title={t("postulante.label.nuevoPostulante")} onClick={(e)=>{
                        e.preventDefault();
                        history.push("/private/postulante/create/");
                    }}>
                        <i className="fas fa-plus"></i>
                    </button>
                </li>               
                <Alertas />

                {/*<li className="nav-item dropdown">
                    <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                        <i className="far fa-bell"></i>
                        <span className="badge badge-danger navbar-badge position-absolute">9</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item">
                            <i className="fas fa-envelope mr-2"></i> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item">
                            <i className="fas fa-users mr-2"></i> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item">
                            <i className="fas fa-file mr-2"></i> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item dropdown-footer">See All Notifications</button>
                    </div>
                </li>
                    */}
                    

            </ul>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown user-menu">
                    <UserTools/>
                </li>
                <li className="nav-item">
                    <Link className="nav-link btn" target="_blank" title="Abrir nueva pestaña" to={(window.sessionStorage.getItem("jwt")?("/jwt/"+window.sessionStorage.getItem("jwt")):"/")}>
                        <i className="fas fa-external-link-alt"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <button className="nav-link btn" data-widget="fullscreen" title="Fullscreen">
                        <i className="fas fa-expand-arrows-alt"></i>
                    </button>
                </li>
                {/*
                 <li className="nav-item">
                    <button type="button" className="nav-link btn" title="Configuration" onClick={()=>{toggleSidebar()}}>
                        <i className="fas fa-cog" />
                    </button>
                </li>
                */}
            </ul>
        </nav>
        <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} />
        </>
    )
}
