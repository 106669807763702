import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import Datatable from '../../../../../components/Datatable';
import DownloadAdjuntosDialog from '../../../../../components/DownloadAdjuntosDialog';
import FlagsPostulante from '../../../../../components/FlagsPostulante';
import PrivateLink from '../../../../../components/navigation/PrivateLink';
import SelectEntity from '../../../../../components/SelectEntity';
import AuthContext from '../../../../../context/AuthContext';
import SidebarContext from '../../../../../context/SidebarContext';
import AsociarProyectoDialog from '../dialogs/asociarProyecto';

const FIND_CONEXIONES_BY_EXPERIENCIA_LABORAL = gql`
    query findConexionesByExperienciaLaboral(
        $idExperienciaLaboral: Int!
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findConexionesByExperienciaLaboral(
            idExperienciaLaboral: $idExperienciaLaboral
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fechaInicio
                    fechaFin
                    fechasEstimadas
                    ultimoTrabajo
                    postulante{
                        id
                        fechaActualizacion
                        destacado
                        fechaNacimientoEstimada
                        incubadora
                        incubadoraProspecto
                        opportunity
                        placement
                        verificar
                        offLimits
                        qaInfo{
                            indice
                            recomendaciones
                        }
                        contacto{
                            id
                            personaFisica
                            alias
                            email
                            linkedin
                            telefonosFijos
                            telefonosMoviles
                        }
                        adjuntoList{
                            referencia
                            fechaActualizacion
                            contentType
                            nombreOriginal
                            nombreServidor
                            responsable{
                                contacto{
                                    alias
                                }
                            }
                        }
                    }
                    areaEmpresa{
                        nombre
                    }
                    cargo{
                        nombre
                    }
                    posicion
                }
            }  
        }
    }
`;

const FIND_CONEXIONES_BY_FORMACION_ACADEMICA = gql`
    query findConexionesByFormacionAcademica(
        $idFormacionAcademica: Int!
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findConexionesByFormacionAcademica(
            idFormacionAcademica: $idFormacionAcademica
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fechaInicio
                    fechaFin
                    fechasEstimadas
                    postulante{
                        id
                        fechaActualizacion
                        destacado
                        fechaNacimientoEstimada
                        incubadora
                        incubadoraProspecto
                        opportunity
                        placement
                        verificar
                        offLimits
                        qaInfo{
                            indice
                            recomendaciones
                        }
                        contacto{
                            id
                            alias
                            personaFisica
                            email
                            linkedin
                            telefonosFijos
                            telefonosMoviles
                            foto
                        }
                        adjuntoList{
                            referencia
                            fechaActualizacion
                            contentType
                            nombreOriginal
                            nombreServidor
                            responsable{
                                contacto{
                                    alias
                                }
                            }
                        }
                    }
                    nivelEstudio{
                        nombre
                    }
                    areaEstudio{
                        nombre
                    }
                }
            }  
        }
    }
`;

export default function ConexionesTab(props) {
    const [t] = useTranslation("global");
    const {id} = useParams();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    const { setSideBarState, show } = useContext(SidebarContext);

    const [showProyectoDialog, setShowProyectoDialog] = useState(false);
    const [proyectoPostulanteSelected, setProyectoPostulanteSelected] = useState();
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "postulante.contacto.alias",
        sortDirection: "ASC"
    });

    const [data, setData] = useState();

    const [searchFilter, setSearchFilter] = useState({
        idExperienciaLaboral: null,
        idFormacionAcademica: null
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);    

    const [findConexionesByFormacionAcademica, {errors: errorsByFormacionAcademica, loading: loadingByFormacionAcademica}] = useLazyQuery(FIND_CONEXIONES_BY_FORMACION_ACADEMICA, {
        onCompleted: (data) => {
            setData(data);
        }
    });

    const [findConexionesByExperienciaLaboral, {errors: errorsByExperienciaLaboral, loading: loadingByExperienciaLaboral}] = useLazyQuery(FIND_CONEXIONES_BY_EXPERIENCIA_LABORAL, {
        onCompleted: (data) => {
            setData(data);
        }
    });

    function setIdExperienciaLaboral(value) {
        setSearchFilter({
            idExperienciaLaboral: value,
            idFormacionAcademica: ""
        });
    }

    function setIdFormacionAcademica(value) {
        setSearchFilter({
            idExperienciaLaboral: "",
            idFormacionAcademica: value
        });
    }

    const [showAdjuntoDialog, setShowAdjuntoDialog] = useState(false);
    const [adjuntosList] = useState();
    
    useEffect(() => {
        if(searchFilter.idExperienciaLaboral){
            findConexionesByExperienciaLaboral({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    idExperienciaLaboral: searchFilter.idExperienciaLaboral,
                }
            });
        }

        if(searchFilter.idFormacionAcademica){
            findConexionesByFormacionAcademica({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    idFormacionAcademica: searchFilter.idFormacionAcademica,
                }
            });
        }
                
    }, [searchFilter, findConexionesByExperienciaLaboral, findConexionesByFormacionAcademica, id, pageNumber, pageSize, sortOptions])

    const postulanteInfoColumns = [
        {
            name: "postulante",
            translationKey: "postulante.label.entity",
            customSortKey: "postulante.contacto.alias",
            className: "col-2",
            render: (element) =>{
                const postulante = element.node.postulante;
                return(
                    <>
                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <div className="text-center">
                                    <FlagsPostulante postulante={postulante} />
                                </div>
                                <div className="text-center">
                                    <ContactoAvatarImg id={postulante?.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                    {postulante.contacto?.fechaNacimiento&&
                                        <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                    }
                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                        <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                    <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                </PrivateLink>
                            </div>
                        </div>
                    </>
                );
            }
        },
    ]

    const accionesColumn = [
        {
            name: "asociarProyecto",
            customHeader: <></>,
            customClassHeader: "bg-info text-center pl-0 pr-0",
            className: "text-center",
            render: (element)=>{
                return(
                    <div className="d-flex justify-content-center">
                        <button
                            type="button"
                            title={t("postulante.field.seguimiento")}
                            className={"btn btn-xs bg-yellow m-1"}
                            onClick={()=>{
                                setSideBarState({
                                    mode: "seguimiento",
                                    postulante: element.node.postulante?.id,
                                    labelPostulante: element.node.postulante?.contacto?.alias,
                                    labelEmpresa: null,
                                    labelProyecto: null,
                                    empresa: null,
                                    proyecto: null,
                                    responsable: null,
                                    accionSeguimiento: null,
                                })
                                show();
                            }}>
                            <i className="fas fa-shoe-prints fa-rotate-270"></i>
                        </button> 
                        <button
                            type="button"
                            className="btn btn-xs bg-yellow m-1" onClick={()=>{
                                setSideBarState({
                                    mode: "referencias",
                                    labelProyecto: null,
                                    proyecto: null,
                                    postulante: element.node.postulante?.id,
                                    labelPostulante: element.node.postulante?.contacto?.alias,
                                    labelEmpresa: null,
                                    empresa: null
                                })
                                show();
                            }}>
                                <i className="fas fa-user-secret"></i>
                        </button>
                        <button
                            type="button"
                            title={t("postulante.field.asociarProyecto")}
                            className={"btn btn-xs bg-info m-1"}
                            onClick={(e)=>{
                                e.preventDefault();
                                setProyectoPostulanteSelected(element.node);
                                setShowProyectoDialog(true);
                            }}>
                            <i className="fas fa-search-plus"></i>
                        </button>
                    </div>
                )
            }
        }
    ];

    const periodoColumns = [
        {
            name: "periodo",
            translationKey: "postulante.field.periodo",
            colSpan: 2,
            type: "text",
            customSortKey: "fechaInicio",
            className: "text-center",
            render: (element)=>{
                return( element.node.fechaInicio &&
                    <p className="text-nowrap">
                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaInicio}</Moment></span>
                    </p>);
            }
        },
        {
            name: "periodoFin",
            translationKey: "postulante.field.periodo",
            type: "text",
            customSortKey: "fechaFin",
            className: "text-center",
            render: (element)=>{
                return( element.node.fechaFin &&
                    <p className="text-nowrap">
                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaFin}</Moment></span>
                    </p>);
            }
        }
    ];

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                (loadingByFormacionAcademica && !errorsByFormacionAcademica) &&
                (loadingByExperienciaLaboral && !errorsByExperienciaLaboral) && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <DownloadAdjuntosDialog entity="Postulante" show={showAdjuntoDialog} setShow={setShowAdjuntoDialog} adjuntosList={adjuntosList}/>
            <div className="card-header pr-0 pl-0">
                <div className="parrish-form row ">
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-genero" className="parrish-label col-sm-2 col-xs-12">{t("postulante.label.formacionAcademica")}</label>
                            <div className="col">
                                <SelectEntity
                                    entity="FormacionAcademicaKeyValue"
                                    label="label"
                                    name="value-formacion-academica"
                                    value={searchFilter.idFormacionAcademica}
                                    filterBy="ByPostulante"
                                    filterValue={id}
                                    filterName="idPostulante"
                                    filterType="Int!"
                                    onChange={(e) => {
                                        setIdFormacionAcademica(e.target.value);
                                        setPageNumber(0);
                                    }}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-genero" className="parrish-label col-sm-2 col-xs-12">{t("postulante.label.experienciaLaboral")}</label>
                            <div className="col">
                                <SelectEntity
                                    entity="ExperienciaLaboralKeyValue"
                                    label="label"
                                    name="value-experiencia-laboral"
                                    value={searchFilter.idExperienciaLaboral}
                                    filterBy="ByPostulante"
                                    filterValue={id}
                                    filterName="idPostulante"
                                    filterType="Int!"
                                    onChange={(e) => {
                                        setIdExperienciaLaboral(e.target.value);
                                        setPageNumber(0);
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pr-0 pl-0">
                {searchFilter.idExperienciaLaboral &&
                    <Datatable
                        data={data}
                        loading={loadingByExperienciaLaboral}
                        error={errorsByExperienciaLaboral}
                        sortOptions={sortOptions}
                        dataWrapper="findConexionesByExperienciaLaboral"
                        entityName="conexion"
                        setPageSize={setPageSize}
                        setPageNumber={setPageNumber}
                        setSortOptions={setSortOptions}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        columnsDefinition={[
                            ...postulanteInfoColumns,
                            {
                                name: "trabajoActual",
                                customHeader: <span className="p-1" title={t("Experiencia laboral actual")}><i className="fas fa-laptop"></i></span>,
                                disableSort: true,
                                customClassHeader: "bg-info text-center pl-0 pr-0",
                                className: "text-center",
                                render: (element)=>{
                                    return(
                                        !element.node.fechaFin&&
                                            <p title={t("Experiencia laboral actual")} className={"badge badge-info align-self-center"}>
                                                <i className="fas fa-laptop"></i>
                                            </p>
                                    );
                                }
                            },
                            ...periodoColumns,
                            {
                                name: "areaEmpresa",
                                customSortKey: "areaEmpresa.nombre",
                                render: (element)=>{
                                    return(
                                        <p>{element.node.areaEmpresa.nombre}</p>
                                    );
                                }
                            },
                            {
                                name: "cargo",
                                customSortKey: "cargo.nombre",
                                render: (element)=>{
                                    return(
                                        <p>{element.node.cargo.nombre}</p>
                                    );
                                }
                            },
                            {
                                name: "posicion",
                                type: "text"
                            },
                            ...accionesColumn
                        ]}
                    />
                }
                {searchFilter.idFormacionAcademica &&
                    <Datatable
                            data={data}
                            loading={loadingByFormacionAcademica}
                            error={errorsByFormacionAcademica}
                            sortOptions={sortOptions}
                            dataWrapper="findConexionesByFormacionAcademica"
                            entityName="conexion"
                            setPageSize={setPageSize}
                            setPageNumber={setPageNumber}
                            setSortOptions={setSortOptions}
                            pageSize={pageSize}
                            pageNumber={pageNumber}
                            columnsDefinition={[                               
                                ...postulanteInfoColumns,
                                ...periodoColumns,
                                {
                                    name: "nivelEstudio.nombre",
                                    customSortKey: "nivelEstudio.nombre",
                                    translationKey: "conexion.field.nivelEstudio",
                                    type: "text"
                                },
                                {
                                    name: "areaEstudio.nombre",
                                    customSortKey: "areaEstudio.nombre",
                                    translationKey: "conexion.field.areaEstudio",
                                    type: "text"
                                },
                                ...accionesColumn
                            ]}
                    />
                }                
            </div>
            <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={proyectoPostulanteSelected?.postulante?.id} aliasPostulante={proyectoPostulanteSelected?.postulante?.contacto?.alias} />
        </div>        
    )
}
