import React, { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import "popper.js/dist/popper";
import EstadoComprobante from '../estados/EstadoComprobante';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const CTA_CTE_COMPROBANTE_SEARCH = gql`
    query findComprobanteConciliacionListByCliente(
        $cliente: Int
    )  {
        findComprobanteConciliacionListByCliente(
            idCliente: $cliente
        ) {
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaRegistro
            registradoPor {
                contacto {
                    alias
                }
            }
            fechaAprobacion
            aprobadoPor {
                contacto {
                    alias
                }
            }
            fechaEmision
            emitidoPor {
                contacto {
                    alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                    alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            proyecto {
                id
                ref
            }
            cbteFechaEmision
            cbteFechaVencimiento
            cbteTipo {
                nemotecnico
            }
            cbteNro
            cbteDetalle
            cbteMoneda {
                id
                simbolo
            }
            cbteImporteFinal
            cbteImporteIva
            cbteImporteNeto
            cbteImpacto
            cotizacionPeso
        }
    }
`;

function ComprobanteList(props){
    const {searchFilter, comprobantesSelId, toggleComprobanteId, setTotalComprobantesSel} = props;
    const [t] = useTranslation("global");
    const {loading, error, data} = useQuery(
        CTA_CTE_COMPROBANTE_SEARCH,
        {
            variables: {               
                cliente: searchFilter.cliente?parseInt(searchFilter.cliente):null,
            }
        }
    );

    useEffect(() => {
        if(!loading && !error && data){
            setTotalComprobantesSel(data?.findComprobanteConciliacionListByCliente)
        }
    }, [data, error, loading, setTotalComprobantesSel])

    const onCompobanteSwitchChange= (id, e)=>{
        toggleComprobanteId(id);
    }

    const isSelected= (id)=>{
        return comprobantesSelId.includes(parseInt(id));
    }
    
    return (<>
        <h4>{t("")}</h4>
        <SimpleDatatable
            data={data?.findComprobanteConciliacionListByCliente}
            entityName="comprobante"
            editUrl="/private/comprobante/edit/"
            columnsDefinition={[
                {
                    name: "id",
                    type: "id"
                },
                {
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-align-center pl-0 pr-0",
                    className:"text-center",
                    disableSort: true,
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                    }
                },
                {
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoComprobante element={element}/>)
                    }
                },
                {
                    name: "proyecto",
                    render: (element)=>{
                        return(<p><PrivateLink className="parrish-link" to={"/proyecto/edit/"+element.proyecto.id}>[{element.proyecto.id}] - {element.proyecto.ref}</PrivateLink></p>)
                    }
                },
                {
                    name: "cbteFechaEmision",
                    type: "date"
                },
                {
                    
                    name: "cbteFechaVencimiento",
                    type: "date"
                },
                {
                    name: "detalle",
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.cbteTipo.nemotecnico+(element.cbteNro?" ["+element.cbteNro+"]":"")}</p>
                            <p>({element.cbteDetalle})</p>
                        </>)
                    }
                },
                {
                    name: "moneda",
                    className:"text-align-center",
                    customHeader: <></>,
                    render: (element)=>{
                        return(element.cbteMoneda?.simbolo)
                    }
                },
                {  
                    name: "cbteImporteNeto",
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeNeto = element.cbteImporteNeto*element.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeNeto}/>
                        )
                    }
                },
                {  
                    name: "cbteImporteIva",
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeIva = element.cbteImporteIva*element.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeIva}/>
                        )
                    }
                },
                {  
                    name: "cbteImporteFinal",
                    type: "money",
                    render: (element)=>{
                        const importeFinal = element.cbteImporteFinal*element.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeFinal}/>
                        )
                    }
                },
                {
                    name: "comprobanteConciliacionSel",
                    className: (searchFilter.cliente?"m-3 text-center":"d-none"),
                    customClassHeader: (searchFilter.cliente?"":"d-none"),
                    customHeader: (<></>),
                    render: (element)=>{
                        if(searchFilter.cliente)
                            return(
                                <div className="custom-control custom-checkbox">
                                    <input className="custom-control-input custom-control-input-info" 
                                        autoComplete="off"
                                        type="checkbox"
                                        id={"comprobante-check-"+element.id}
                                        checked={isSelected(element.id)}
                                        onChange={(e) => {onCompobanteSwitchChange(element.id, e)}} />
                                    <label className="custom-control-label ml-2" htmlFor={"comprobante-check-"+element.id}></label>
                                </div>
                            )
                    }
                }
            ]}>
        </SimpleDatatable>
    </>);
}

export default ComprobanteList;