import React, { useState } from "react";
import SidebarStateContext from "../context/SidebarContext";
import $ from 'jquery';
import ControlSidebar from 'admin-lte/build/js/ControlSidebar';

function SidebarStateProvider({ children }) {
  // app state
  const [state, setState] = useState({});
  const [show, setShow] = useState(false);

  return (
    <SidebarStateContext.Provider value={{ 
        sideBarState: state,
        setSideBarState: (stateParam) => {
          setState({
            ...state,
            ...stateParam
          });
        },
        show: () => {
          ControlSidebar._jQueryInterface.call($('#side-bar'), 'show');
          setShow(true);
        },
        hide: () => {
          ControlSidebar._jQueryInterface.call($('#side-bar'), 'collapse');
          setState({});
          setShow(false);
        },
        isShow: () => {
          return show;
        }
      }}>
        {children}
    </SidebarStateContext.Provider>
  );
}

export default SidebarStateProvider;