import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

export default function Paginator(props) {
    const {
        data,
        setPageSize,
        setPageNumber,
        pageSize,
        pageNumber
    } = props;
    const [t] = useTranslation("global");

    const [pageNumberDelayed, setPageNumberDelayed] = useState(pageNumber?pageNumber:0);
    const [debouncePageNumber] = useDebounce(pageNumberDelayed, 500);

    useEffect(() => {
        setPageNumber(debouncePageNumber);
    }, [debouncePageNumber, setPageNumber])

    const firstPage = () =>{
        setPageNumber(0);
    }

    const lastPage = () =>{
        setPageNumber(data?.queryInfo?.totalPages-1);
    }

    const nextPage = () =>{
        if(data?.pageInfo?.hasNextPage){
            setPageNumber(pageNumber+1);
        }
    }

    const prevPage = () =>{
        if(data?.pageInfo?.hasPreviousPage){
            setPageNumber(pageNumber-1);
        }
    }

    const changePageSize = (size) =>{
        firstPage();
        setPageSize(size);
    }

    return(
        <div className="d-flex">
            <div className="pagination pagination-sm mr-2">
                <div className="input-group input-group-sm mb-2">
                    <div className="input-group-prepend input-group-prepend-sm">
                        <div className="input-group-text input-group-text">{t("common.label.paginado.pageSizeLabel")}</div>
                    </div>
                    <select value={pageSize} onChange={(e)=>changePageSize(e.target.value)} className="form-control select2">
                        <option value={20} label="20"/>
                        <option value={30} label="30"/>
                        <option value={40} label="40"/>
                        <option value={50} label="50"/>
                        <option value={75} label="75"/>
                        <option value={100} label="100"/>
                    </select>
                </div>
            </div>

            <div className="pagination pagination-sm mr-2">
                <div className="input-group input-group-sm mb-2">
                    <input autoComplete="off" className="form-control parrish-input-small"
                        name="currentPage"
                        type="number"
                        min={1}
                        max={data?.queryInfo?.totalPages}
                        value={pageNumberDelayed+1}
                        onChange={(e) => {
                            const selectedPage = parseInt(e.target.value);
                            var pageNumberValue = Math.min(Math.max(parseInt(selectedPage), 1), data?.queryInfo?.totalPages);
                            setPageNumberDelayed(pageNumberValue-1);
                        }} />
                    <div className="input-group-append input-group-append-sm">
                        <div className="input-group-text input-group-text">{t("common.label.paginado.ir")}</div>
                    </div>
                </div>
            </div>

            <div className="pagination pagination-sm">
                <div className="input-group input-group-sm mb-2">
                    <div className="input-group-prepend input-group-prepend-sm">
                        <button className={`page-link bg-light rounded-left`} onClick={firstPage} disabled={!data?.pageInfo?.hasPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </button>
                    </div>
                    <div className="input-group-prepend input-group-prepend-sm">
                        <button className={`page-link bg-light`} onClick={prevPage} disabled={!data?.pageInfo?.hasPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                    </div>
                    <div className="input-group-prepend input-group-prepend-sm">
                        <div className="input-group-text bg-light bg-white">{ (pageNumber+1) + " " + t("common.label.paginado.of") + " " + (data?.queryInfo?.totalPages) }</div>
                    </div>
                    <div className="input-group-prepend input-group-prepend-sm">
                        <button className={`page-link bg-light`} onClick={nextPage} disabled={!data?.pageInfo?.hasNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                    <div className="input-group-prepend input-group-prepend-sm">
                        <button className={`page-link bg-light rounded-right`} onClick={lastPage} disabled={!data?.pageInfo?.hasNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}