import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SidebarContext from '../context/SidebarContext';
import DownloadAdjuntosDialog from './DownloadAdjuntosDialog';
import FlagButtonBoolean from './FlagButtonBoolean';

export default function FlagsProyecto(props) {
    const { setSideBarState, show } = useContext(SidebarContext);
    const [t] = useTranslation("global");
    const {proyecto, hideReferenciasySeguimiento = false} = props;

    const [showAdjuntoDialog, setShowAdjuntoDialog] = useState(false);
    const [adjuntosList, setAdjuntosList] = useState();

    const openAdjuntosDialog = (adjuntoList) =>{
        setAdjuntosList(adjuntoList);
        setShowAdjuntoDialog(true);
    }

    const adjuntoClick= (e) =>{
        e.preventDefault();
        if(proyecto.adjuntoList&&proyecto.adjuntoList.length>0){
            openAdjuntosDialog(proyecto.adjuntoList);
        }
    }
    
    return(proyecto?
        <>
            <DownloadAdjuntosDialog entity="Postulante" show={showAdjuntoDialog} setShow={setShowAdjuntoDialog} adjuntosList={adjuntosList}/>
            <div id={"empresa-"+proyecto?.id} className="align-self-center flag-container">
                <div className="d-flex justify-content-center">
                    <div className={"small-flag bg-info"+(proyecto.adjuntoList&&proyecto.adjuntoList.length>0?" on":" off")} data-toggle="tooltip" data-html="true" title={proyecto.adjuntoList&&proyecto.adjuntoList.length>0?t("proyecto.label.adjuntos"):null} onClick={(e)=>{adjuntoClick(e)}}>
                        <i className="fas fa-paperclip"></i>
                    </div>
                {!hideReferenciasySeguimiento&&
                <>
                        <FlagButtonBoolean
                            icon="fas fa-shoe-prints fa-rotate-270"
                            onClass="bg-yellow"
                            offClass="bg-yellow"
                            value={true}
                            setValue={()=>{
                                setSideBarState({
                                    mode: "seguimiento",
                                    proyecto: proyecto.id,
                                    labelProyecto: proyecto?.contacto?.nombre,
                                    labelPostulante: null,
                                    labelEmpresa: null,
                                    postulante: null,
                                    empresa: null,
                                    responsable: null,
                                    accionSeguimiento: null,
                                })
                                show();
                            }}
                        />
                        <FlagButtonBoolean
                            icon="fas fa-user-secret"
                            onClass="bg-yellow"
                            offClass="bg-yellow"
                            value={true}
                            setValue={()=>{
                                setSideBarState({
                                    mode: "referencias",
                                    labelProyecto: proyecto?.contacto?.nombre,
                                    proyecto: proyecto.id,
                                    labelPostulante: null,
                                    postulante: null,
                                    labelEmpresa: null,
                                    empresa: null
                                })
                                show();
                            }}
                        />
                </>
                }
            </div>
            </div>
        </>
        :
        null
    );
}
