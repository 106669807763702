import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import DatePicker from '../../../../components/DatePicker';
import Autocomplete from '../../../../components/Autocomplete';
import NumberFormat from 'react-number-format';
import MultipleAutocomplete from '../../../../components/MultipleAutocomplete';
import $ from 'jquery'
import useAlertMessage from '../../../../hooks/useAlertMessage';
import ContactoSelector from '../../../../components/ContactoSelector';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import { DateRfc }  from '../../DateRfc';

const CREATE_PROYECTO_MUTATION = gql`
    mutation createProyecto(
        $tipoProyecto: Int!
        $empresa: Int!
        $areaEmpresa: Int!
        $cargo: Int!
        $posicion: String!
        $contacto: Int!
        $pais: Int!
        $socia: Int!
        $fechaInicioBusqueda: LocalDate!
        $moneda: Int
        $sueldoBrutoMensual: Int
        $beneficios: String
        $bonus: String
        $descripcion: String
        $asociadoList: [Int!]
    ) {
        createProyecto(
            entity:{
                tipoProyecto: $tipoProyecto
                empresa: $empresa
                areaEmpresa: $areaEmpresa
                cargo: $cargo
                posicion: $posicion
                contacto: $contacto
                pais: $pais
                socia: $socia
                fechaInicioBusqueda: $fechaInicioBusqueda
                moneda: $moneda
                sueldoBrutoMensual: $sueldoBrutoMensual
                beneficios: $beneficios
                bonus: $bonus
                descripcion: $descripcion
                asociadoList: $asociadoList
            }
        ) {
            id
        }
    }
`;

function ProyectoCreate() {
    const [t] = useTranslation("global");
    const history = useHistory();
    let { showErrorToast } = useAlertMessage();
    const ref = useRef();

    const [createProyectoMutation] = useMutation(CREATE_PROYECTO_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/proyecto/edit/"+data.createProyecto.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [state, setState] = useState({
        tipoProyecto: "",
        empresa: "",
        areaEmpresa: "",
        cargo: "",
        posicion: "",
        contacto: "",
        pais: "",
        sociaResponsable: "",
        fechaInicioBusqueda: new DateRfc(),
        moneda: "",
        sueldoBrutoMensual: "",
        beneficios: "",
        bonus: "",
        descripcion: "",
        asociadoList: null
    });

    const [datosContacto, setDatosContacto] = useState({
        alias: "",
        email: "",
        telefonosFijos: "",
        telefonosMoviles: ""
    });

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createProyectoMutation({
            variables: {
                tipoProyecto: state.tipoProyecto,
                empresa: state.empresa,
                areaEmpresa: state.areaEmpresa,
                cargo: state.cargo,
                posicion: state.posicion,
                contacto: state.contacto,
                pais: state.pais,
                socia: state.socia,
                fechaInicioBusqueda: state.fechaInicioBusqueda,
                moneda: state.moneda?state.moneda:null,
                sueldoBrutoMensual: state.sueldoBrutoMensual?state.sueldoBrutoMensual:null,
                beneficios: state.beneficios?state.beneficios:null,
                bonus: state.bonus?state.bonus:null,
                descripcion: state.descripcion?state.descripcion:null,
                asociadoList: state.asociadoList?state.asociadoList:null
            }
        });
    }

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-tipoProyecto": "required",
                "value-fecha-inicio": "required",
                "value-socia": "required",
                "value-contacto": "required",
                "value-empresa": "required",
                "value-area-empresa": "required",
                "value-cargo": "required",
                "value-posicion": "required",
                "value-pais": "required",
                "value-beneficios": "required",
                "value-bonus": "required",
                "value-sueldo-bruto": "required",
                "value-moneda": "required",
                "value-asociados-list": "required"
            },
            messages: {
                "value-tipoProyecto": {
                    required: t("common.message.field.required", {0: t("proyecto.field.tipoProyecto")})
                },
                "value-fecha-inicio": {
                    required: t("common.message.field.required", {0: t("proyecto.field.fechaInicioBusqueda")})
                },
                "value-socia": {
                    required: t("common.message.field.required", {0: t("proyecto.field.socia")})
                },
                "value-contacto": {
                    required: t("common.message.field.required", {0: t("proyecto.field.contacto")})
                },
                "value-empresa": {
                    required: t("common.message.field.required", {0: t("proyecto.field.empresa")})
                },
                "value-area-empresa": {
                    required: t("common.message.field.required", {0: t("proyecto.field.areaEmpresa")})
                },
                "value-cargo": {
                    required: t("common.message.field.required", {0: t("proyecto.field.cargo")})
                },
                "value-posicion": {
                    required: t("common.message.field.required", {0: t("proyecto.field.posicion")})
                },
                "value-pais": {
                    required: t("common.message.field.required", {0: t("proyecto.field.pais")})
                },
                "value-beneficios": {
                    required: t("common.message.field.required", {0: t("proyecto.field.beneficios")})
                },
                "value-bonus": {
                    required: t("common.message.field.required", {0: t("proyecto.field.bonus")})
                },
                "value-sueldo-bruto": {
                    required: t("common.message.field.required", {0: t("proyecto.field.sueldoBruto")})
                },
                "value-moneda": {
                    required: t("common.message.field.required", {0: t("proyecto.field.moneda")})
                },
                "value-asociados-list": {
                    required: t("common.message.field.required", {0: t("proyecto.field.asociadoList")})
                }
                
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    

    return (<>
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-outline card-info">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{history.goBack()}}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row">

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-tipoProyecto" className="parrish-label col-2 required">{t("proyecto.field.tipoProyecto")}</label>
                                <div className="col-8">
                                    <SelectKeyValue
                                        entity="TipoProyecto"
                                        name={'value-tipoProyecto'}
                                        value={state.tipoProyecto}
                                        required
                                        onChange={(e) => {
                                            handleChange("tipoProyecto", e);
                                        }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-empresa" className="parrish-label col-2 required">{t("proyecto.field.empresa")}</label>
                                <div className="col-8">
                                    <Autocomplete
                                        name="value-empresa"
                                        entity="Empresa"
                                        by="Alias"
                                        filterName="alias"
                                        value={state.empresa}
                                        setValue={(value)=>handleChange("empresa", {target:{value:value}})} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-area-empresa" className="parrish-label col-2 required">{t("proyecto.field.areaEmpresa")}</label>
                                <div className="col-8">
                                    <SelectKeyValue
                                        entity="AreaEmpresa"
                                        by="Nombre"
                                        name={'value-area-empresa'}
                                        value={state.areaEmpresa}
                                        required
                                        onChange={(e) => {
                                            handleChange("areaEmpresa", e);
                                        }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-cargo" className="parrish-label col-2 required">{t("proyecto.field.cargo")}</label>
                                <div className="col-8">
                                    <SelectKeyValue
                                        entity="Cargo"
                                        by="Nombre"
                                        name={'value-cargo'}
                                        value={state.cargo}
                                        required
                                        onChange={(e) => {
                                            handleChange("cargo", e);
                                        }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-posicion" className="parrish-label col-2 required">{t("proyecto.field.posicion")}</label>
                                <div className="col-8">
                                    <input autoComplete="off" type="text"
                                        name={'value-posicion'}
                                        className="parrish-input"
                                        value={state.posicion}
                                        onChange={(e) => handleChange("posicion", e)}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-pais" className="parrish-label col-2 required">{t("proyecto.field.pais")}</label>
                                <div className="col-8">
                                    <SelectKeyValue
                                        entity="Pais"
                                        label="nombre"
                                        name={'value-pais'}
                                        value={state.pais}
                                        required
                                        onChange={(e) => {
                                            handleChange("pais", e);
                                        }}/>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fecha-inicio" className="parrish-label col-2 required">{t("proyecto.field.fechaInicioBusqueda")}</label>
                                <div className="col-8">
                                    <DatePicker
                                        name="value-fecha-inicio"
                                        selected={state.fechaInicioBusqueda}
                                        onChange={date => handleChange("fechaInicioBusqueda", {target:{value:date}})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-contacto" className="parrish-label col-2 required">{t("proyecto.field.contacto")}</label>
                                <div className="col-8">
                                    <ContactoSelector
                                        cliente={state.contacto}
                                        setCliente={(value)=>{handleChange("contacto", {target:{value:value}})}}
                                        setContacto={(contacto)=>{
                                            setDatosContacto({
                                                alias: contacto?.alias?contacto?.alias:"",
                                                email: contacto?.email?contacto?.email:"",
                                                telefonosFijos: contacto?.telefonosFijos?contacto?.telefonosFijos:"",
                                                telefonosMoviles: contacto?.telefonosMoviles?contacto?.telefonosMoviles:""
                                            });
                                    }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.email")}</label>
                                <div className="col-8">
                                    <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.email} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.telefonosFijos")}</label>
                                <div className="col-8">
                                    <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.telefonosFijos} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.telefonosMoviles")}</label>
                                <div className="col-8">
                                    <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.telefonosMoviles} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-socia" className="parrish-label col-2 required">{t("proyecto.field.socia")}</label>
                                <div className="col-8">
                                    <SelectKeyValue   
                                        entity="UsuarioActivo"
                                        name="value-socia"
                                        value={state.socia}
                                        onChange={(e) => handleChange("socia", e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="parrish-form row pr-2 pl-2">

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-descripcion" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.descripcion")}</label>
                                <div className="col-sm-12 col-xs-12">
                                    <textarea name="value-descripcion" value={state.descripcion} onChange={(e)=>{handleChange("descripcion", e)}} className="form-control form-control-sm" rows="19"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="row">
                                <label htmlFor="value-asociados-list" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.asociados")}</label>
                                <div className="col-12">
                                    <MultipleAutocomplete
                                        name="value-asociados-list"
                                        entity="UsuarioActivo"
                                        by="Alias"
                                        filterName="alias"
                                        searchInFocus={true}
                                        setValue={(value)=>{
                                            handleChange("asociadoList", {target:{value:value}})
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4 mb-4">
                                <div className="col-12 d-flex">
                                    <label htmlFor="value-sueldo-bruto" className="parrish-label text-nowrap mr-3 required">{t("proyecto.field.sueldoBruto")}</label>
                                    <SelectKeyValue
                                        entity="Moneda"
                                        name="value-moneda"
                                        value={state.moneda}
                                        placeholder={" "}
                                        required
                                        onChange={(e) => {
                                            handleChange("moneda", e)
                                        }}/>
                                    <NumberFormat
                                        name="value-sueldo-bruto"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={state.sueldoBrutoMensual}
                                        className={"parrish-input text-right ml-2"}
                                        onChange={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                handleChange("sueldoBrutoMensual", {target:{value:parseInt(val.replaceAll(".","").replaceAll(",","."))}});
                                            }
                                        }}
                                        />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="value-beneficios" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.beneficios")}</label>
                                <div className="col-sm-12 col-xs-12">
                                    <textarea name="value-beneficios" value={state.beneficios} onChange={(e)=>{handleChange("beneficios", e)}} className="form-control form-control-sm" rows="5"></textarea>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="value-bonus" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.bonus")}</label>
                                <div className="col-sm-12 col-xs-12">
                                    <textarea name="value-bonus" value={state.bonus} onChange={(e)=>{handleChange("bonus", e)}} className="form-control form-control-sm" rows="5"></textarea>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </>)
}

export default ProyectoCreate