import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import Datatable from '../../../../components/Datatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"
import { useTranslation } from 'react-i18next';
import EstadoMovimiento from '../estados/EstadoMovimiento';
import MONEDA from '../../../../enums/Moneda';
import ESTADO_ADMINISTRATIVO from '../../../../enums/EstadoAdministrativo';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import CurrencyFormat from '../../../../components/CurrencyFormat';

const COMPROBANTE_SEARCH = gql`
    query findMovimientoCobranzaPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int
        $cliente: Int
        $cuenta: Int
        $formaPago: Int
        $retencion: Int
        $fechaAcreditacionDesde: LocalDate
        $fechaAcreditacionHasta: LocalDate
        $estado: Int
        $proyecto: Int
    ) {
        findMovimientoCobranzaPageByFilter(
        filter: {
            id: $id
            cliente: $cliente
            cuenta: $cuenta
            formaPago: $formaPago
            retencion: $retencion
            fechaAcreditacionDesde: $fechaAcreditacionDesde
            fechaAcreditacionHasta: $fechaAcreditacionHasta
            estado: $estado
            proyecto: $proyecto
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                adjunto
                estado {
                    id
                    color
                    nombre
                }
                fechaAcreditacion
                fechaRegistro
                registradoPor {
                    contacto {
                        alias
                    }
                }
                fechaConciliacion
                conciliadoPor {
                    contacto {
                        alias
                    }
                }
                fechaCancelacion
                canceladoPor {
                    contacto {
                        alias
                    }
                }
                fechaCobranza
                cobradoPor {
                    contacto {
                        alias
                    }
                }
                cliente {
                    id
                    contacto{
                        alias
                    }
                }
                moneda {
                    id
                    simbolo
                }
                formaPago{
                    nombre
                }
                retencion{
                    nombre
                }
                cuenta{
                    nombre
                    banco{
                        nombre
                    }
                    tipoCuenta{
                        nombre
                    }
                    moneda{
                        simbolo
                    }
                }
                impacto
                importe
                cotizacionPeso
                detalle
                detalleCbtesAsociados
                chequeBanco{
                    nombre
                }
                chequeFecha
                chequeNro
                chequeTitular
            }
        }
    }
}
`;

const COMPROBANTE_TOTALES = gql`
    query findComprobanteImportesTotalesByMonedaEstado(
        $moneda: Int!
        $estados: [Int!]!
    ) {
        findComprobanteImportesTotalesByMonedaEstado(
            moneda: $moneda
            estados: $estados
        ) {
            referencia
            importeFinal
            importeIva
            importeNeto
        }
    }
`;

const MOVIMIENTO_TOTALES = gql`
    query findMovimientoImportesTotalesByMonedaEstado(
        $moneda: Int!
        $estados: [Int!]!
    ) {
        findMovimientoImportesTotalesByMonedaEstado(
            moneda: $moneda
            estados: $estados
        ) {
            referencia
            importeFinal
            importeIva
            importeNeto
        }
    }
`;

function CobranzaList(props){
    const [t] = useTranslation("global");
    const {searchFilter, setSearchFilter} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaAcreditacion",
        sortDirection: "DESC"
    });

    const codigoMonedaTotales="AR$ ";

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        COMPROBANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: null,
                cliente: null,
                cuenta: null,
                formaPago: null,
                retencion: null,
                fechaAcreditacionDesde: null,
                fechaAcreditacionHasta: null,
                estado: null,
                proyecto: null
            }
        }
    );

    const totalImporte  = data?data["findMovimientoCobranzaPageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.importe*currVal.node.cotizacionPeso*currVal.node.impacto;
    } , 0):0;

    const {loading: loadingTotalComprobante, error: errorTotalComprobante, data: dataTotalComprobante} = useQuery(
        COMPROBANTE_TOTALES,
        {
            variables: {
                moneda: MONEDA.PESOS,
                estados: [ESTADO_ADMINISTRATIVO.EMITIDO]
            }
        }
    );

    const {loading: loadingTotalMovimiento, error: errorTotalMovimiento, data: dataTotalMovimiento} = useQuery(
        MOVIMIENTO_TOTALES,
        {
            variables: {
                moneda: MONEDA.PESOS,
                estados: [ESTADO_ADMINISTRATIVO.IMPACTADO]
            }
        }
    );

    return (
        <>
        <CardFilter
            entityName="movimiento"
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            filters={[
                {
                    name: "id",
                    type: "number",
                },
                {
                    type: "select",
                    name: "estado",
                    entity:"EstadoAdministrativo",
                    label: "nombre"
                },
                {
                    type: "autocomplete",
                    name: "cliente",
                    entity:"EmpresaCliente",
                    by: "Alias",
                    filterName: "alias"
                },
                {
                    type: "autocomplete",
                    name: "proyecto",
                    entity:"Proyecto",
                    by: "EmpresaRef",
                    filterName: "ref"
                },
                {
                    type: "select",
                    name: "cuenta",
                    entity:"Cuenta",
                    label:"nombre,moneda{simbolo}",
                    displayText:(element)=>{
                        return (element.nombre+" ["+element.moneda.simbolo+"]");
                    }
                },
                {
                    type: "select",
                    name: "formaPago",
                    entity:"FormaPago",
                    label: "nombre"
                },
                {
                    type: "select",
                    name: "retencion",
                    entity:"Retencion",
                    label: "nombre"
                },
                {
                    type: "dateRange",
                    name: "fechaAcreditacion",
                    startDateName: "fechaAcreditacionDesde",
                    endDateName: "fechaAcreditacionHasta",
                    translationKey: "movimiento.field.fechaAcreditacion"
                }
            ]}></CardFilter>
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.listado")}</h3>
                    <div className="card-tools m-0">
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/cobranza/create"}>
                            <i className="fas fa-plus"></i>
                        </PrivateLink>
                    </div>
                </div>
                <div className="card-body">
                {
                        !loadingTotalComprobante &&
                        !errorTotalComprobante &&
                        !loadingTotalMovimiento &&
                        !errorTotalMovimiento &&

                        <div className="d-flex justify-content-center">
                            <div className="small-box text-center bg-white fit-content mr-3">
                                <div className="inner">
                                    <h5>{t("cobranza.label.totalComprobantesSeleccionados")}</h5>
                                    <h2 className="d-flex justify-content-center m-1">
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeFinal}
                                        />
                                    </h2>
                                    <small className="d-flex justify-content-center m-1">
                                        <b>IVA: </b>
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeIva}/>
                                        <b className="ml-1">NETO: </b>
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeNeto}/>
                                    </small>
                                </div>       
                            </div>
                            <div className="small-box text-center bg-white fit-content mr-3">
                                <div className="inner">
                                    <h5>{t("cobranza.label.totalMovimientosSeleccionados")}</h5>
                                    <h2 className="d-flex justify-content-center m-1">
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalMovimiento?.findMovimientoImportesTotalesByMonedaEstado[0].importeFinal}/>
                                    </h2>
                                </div>       
                            </div>
                            <div className="small-box text-center bg-white fit-content mr-3">
                                <div className="inner">
                                    <h5>{t("cobranza.label.diferenciaSeleccionados")}</h5>
                                    <h2 className="d-flex justify-content-center m-1">
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeFinal - dataTotalMovimiento?.findMovimientoImportesTotalesByMonedaEstado[0].importeFinal}/>                                       
                                    </h2>
                                </div>       
                            </div>
                        </div>
                    }
                    <Datatable
                        data={data}
                        loading={loading}
                        error={error}
                        sortOptions={sortOptions}
                        dataWrapper="findMovimientoCobranzaPageByFilter"
                        entityName="movimiento"
                        setPageSize={setPageSize}
                        setPageNumber={setPageNumber}
                        setSortOptions={setSortOptions}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        editUrl="/private/cobranza/edit/"
                        headerBottom={
                            <tr>
                                <th className="bg-white pr-2">
                                    <div>{codigoMonedaTotales}</div>
                                </th>
                                <th className="bg-white pr-2">
                                    <div>
                                        <CurrencyFormat
                                            additionalClassName="float-right"
                                            value={totalImporte}
                                        />
                                    </div>
                                </th>
                            </tr>
                        }
                        columnsDefinition={[
                            {
                                name: "id",
                                type: "id",
                                rowSpan: 2
                            },
                            {
                                name: "adjunto",
                                customHeader: (<i className="fas fa-paperclip"></i>),
                                customClassHeader: "bg-info text-center pl-2 pr-2",
                                disableSort: true,
                                className: "text-center",
                                render: (element)=>{
                                    return(<DownloadAdjunto filename={element.node.adjunto} entity="Movimiento"/>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "estado",
                                className:"text-align-center",
                                render: (element)=>{
                                    return(<EstadoMovimiento element={element}/>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "cliente",
                                render: (element)=>{
                                    return(<p><PrivateLink className="parrish-link" to={"/empresa/edit/"+element.node.cliente.id}>{element.node.cliente.contacto.alias}</PrivateLink></p>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "fechaAcreditacion",
                                type: "date",
                                rowSpan: 2
                            },
                            {
                                name: "formaPago",
                                render: (element)=>{
                                    return(<>
                                        <p>{element.node.formaPago.nombre}</p>
                                        {element.node?.retencion&&
                                            <p className="text-bold">[{element.node.retencion.nombre}]</p>
                                        }
                                        {element.node?.chequeNro&&
                                            <p className="text-bold">[{element.node.chequeNro}]</p>
                                        }
                                    </>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "cuenta",
                                render: (element)=>{
                                    return(<>
                                        {
                                            element.node.cuenta &&
                                                element.node.cuenta?.nombre+" - ["+element.node.cuenta?.moneda?.simbolo+"]"
                                        }
                                    </>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "detalle",
                                render: (element)=>{
                                    return(<>
                                        <p className="text-bold">{element.node.detalleCbtesAsociados}</p>
                                        <p>{element.node.detalle?"("+element.node.detalle+")":""}</p>
                                    </>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "moneda",
                                className:"text-align-center",
                                customClassHeader: "bg-info text-center pl-0 pr-0",
                                customHeader: <></>,
                                disableSort: true,
                                render: (element)=>{
                                    return(<p>{element.node.moneda?.simbolo}</p>)
                                }
                            },
                            {  
                                name: "importe",
                                type: "money",
                                customHeader: t("movimiento.field.importe"),
                                customClassHeader: "bg-info",
                                render: (element)=>{
                                    const importe = element.node.importe*element.node.impacto;
                                    return(
                                        <CurrencyFormat
                                            additionalClassName="float-right"
                                            value={importe} />
                                    )
                                }
                            }
                        ]}>
                            
                    </Datatable>
                </div>
            </div>
            
        </>
    );
}

export default CobranzaList;