import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

const GET_AVATAR = gql`
    query findContactoAvatar($idContacto: Int!, $avatarSize: Int!){
        findContactoAvatar(
            idContacto: $idContacto
            avatarSize: $avatarSize
        )
    }
`;

function ContactoAvatarImg({personaFisica, ...props}) {
    const { id, size, foto } = props;
    const [avatar, setAvatar] = useState(null);

    const [loadAvatar, { loading, error }] = useLazyQuery(GET_AVATAR, {
        onCompleted: (data) => {
            setAvatar(data?.findContactoAvatar);
        }
    });

    const getAvatarSrc= () => {
        const emtpyAvatar = personaFisica?'/img/unknown-user.png':'/img/unknown-industry.png';//AVATAR EMPRESA
        if(id){
            if(avatar){
                return avatar;
            }else{
                return emtpyAvatar;
            }
        }else{
            return emtpyAvatar;
        }
        
    }

    useEffect(() => {
        if(foto&&id){
            loadAvatar({
                variables: {
                    idContacto: id,
                    avatarSize: size?size:200
                }
            });
        }
    }, [foto, id, loadAvatar, size])

    return (!loading && !error &&
        <img {...props} src={getAvatarSrc()} alt={props.alt?props.alt:"graphqlImage"}/>
    )
}

ContactoAvatarImg.propTypes = {
    personaFisica: PropTypes.bool.isRequired,
};

export default ContactoAvatarImg