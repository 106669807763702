import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_PERFIL_MUTATION = gql`
    mutation createPerfil(       
        $nombre: String!
    ) {
        createPerfil(entity:{
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

function PerfilCreate() {
    const history = useHistory();

    const [nombre, setNombre] = useState('');
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createPerfilMutation] = useMutation(CREATE_PERFIL_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/perfil";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createPerfilMutation({
            variables:{
                nombre: nombre,
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardCreateEntity
            entityName="perfil"
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "nombre",
                    type: "text",
                    value: nombre,
                    setValue: setNombre
                }
            ]}
            returnUrl="/perfil"
            >
        </CardCreateEntity>
    )
}

export default PerfilCreate