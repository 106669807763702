import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DownloadAdjuntosDialog from './DownloadAdjuntosDialog';
import $ from "jquery";

export default function FlagsPostulante(props) {
    const [t] = useTranslation("global");
    const {postulante} = props;
    const incubadoraClass = (postulante.incubadora||postulante.incubadoraProspecto)?(postulante.incubadora?"bg-teal":(postulante.incubadoraProspecto?"bg-orange":"")):"bg-info off";
    const incubadoraTitle = (postulante.incubadora||postulante.incubadoraProspecto)?(postulante.incubadora?t("postulante.field.incubadora")+"\n":postulante.incubadoraProspecto?t("postulante.field.incubadoraProspecto"):""):null;

    let proyectosActivosLabel = "<p>"+t("postulante.label.proyectosActivos")+"</p>";
    if(postulante.proyectosActivos&&postulante.proyectosActivos.length>0){
        proyectosActivosLabel+="<ul>"
        postulante.proyectosActivos.forEach(proyectoActivo => {
            proyectosActivosLabel+= "<li>"+proyectoActivo.label+"</li>";
        });
        proyectosActivosLabel+="</ul>"
    }
    
    const [showAdjuntoDialog, setShowAdjuntoDialog] = useState(false);
    const [adjuntosList, setAdjuntosList] = useState();

    const openAdjuntosDialog = (adjuntoList) =>{
        setAdjuntosList(adjuntoList);
        setShowAdjuntoDialog(true);
    }

    const adjuntoClick= (e) =>{
        e.preventDefault();
        if(postulante.adjuntoList&&postulante.adjuntoList.length>0){
            openAdjuntosDialog(postulante.adjuntoList);
        }
    }

    const whatsappClick= (e) =>{
        e.preventDefault();
        if(postulante.contacto.telefonosMoviles){
            const numericOnly = postulante.contacto.telefonosMoviles.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + numericOnly;
        }
    }

    const emailClick= (e) =>{
        e.preventDefault();
        if(postulante.contacto.email){
            window.location.href = "mailto:"+postulante.contacto.email;
        }
    }

    /*
    postulante.destacado
    postulante.incubadora
    postulante.incubadoraProspecto
    postulante.opportunity
    postulante.placement
    postulante.verificar
    postulante.offLimits
    */

    /*
    postulante.incubadora
    postulante.incubadoraProspecto
    */

    useEffect(() => {
        $("#postulante-"+postulante?.id+' [data-toggle="tooltip"]').tooltip("dispose");
        $("#postulante-"+postulante?.id+' [data-toggle="tooltip"]').tooltip();
    }, [postulante?.id])

    return(
        <>
            <DownloadAdjuntosDialog entity="Postulante" show={showAdjuntoDialog} setShow={setShowAdjuntoDialog} adjuntosList={adjuntosList}/>
            <div id={"postulante-"+postulante?.id} className="flag-container ml-2 mr-3">
                <div className="row">
                    <div className={"small-flag bg-info"+(postulante.adjuntoList&&postulante.adjuntoList.length>0?" on":" off")} data-toggle="tooltip" data-html="true" title={postulante.adjuntoList&&postulante.adjuntoList.length>0?t("postulante.label.adjuntos"):null} onClick={(e)=>{adjuntoClick(e)}}>
                        <i className="fas fa-paperclip"></i>
                    </div>
                    <div data-template='<div class="large-tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-center"></div></div>' className={"small-flag"+(postulante.emailFalso?" bg-danger":" bg-info")} data-toggle="tooltip" data-html="true" title={postulante.contacto.email?("<p class='m-1'>"+postulante.contacto.email+"</p>"):null} onClick={(e)=>{emailClick(e)}}>
                        <i className="fas fa-envelope"></i>
                    </div>
                    <div className={"small-flag bg-info"+(postulante.contacto.telefonosFijos?" on":" off")} data-toggle="tooltip" data-html="true" title={postulante.contacto.telefonosFijos?("<p class='m-1'><b>FIJOS:</b> "+postulante.contacto.telefonosFijos+"</p>"):""}>
                        <i className="fas fa-phone"></i>
                    </div>
                    <div className={"small-flag bg-info"+(postulante.contacto.telefonosMoviles?" on":" off")} data-toggle="tooltip" data-html="true" title={postulante.contacto.telefonosMoviles?("<p class='m-1'><b>CEL:</b> "+postulante.contacto.telefonosMoviles+"</p>"):""} onClick={(e)=>{whatsappClick(e)}}>
                        <i className="fab fa-whatsapp"></i>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex flex-column">
                        <div data-template='<div class="large-tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-toggle="tooltip" data-html="true" title={proyectosActivosLabel} className={"large-tooltip small-flag"+(postulante.proyectosActivos&&postulante.proyectosActivos.length>0?" bg-success on":" bg-info off")}>
                            <i className="fa-custom-x1">
                                <img src="/icons/header_proceso.png" alt="logo-icon"/>
                            </i>
                        </div>
                        <div data-toggle="tooltip" data-html="true" title={postulante.placement?t("postulante.field.placement"):null} className={"small-flag"+(postulante.placement?" bg-success on":" bg-info off")}>
                            <i className="fa-custom-x1">
                                <img src="/icons/cliente_24.png" alt="logo-icon"/>
                            </i>
                        </div>
                    </div>

                    <div data-toggle="tooltip" data-html="true" title={incubadoraTitle} className={"large-flag "+incubadoraClass}>
                        <i className="fa-custom-x2">
                            <img src="/icons/incubadora_44.png" alt="logo-icon"/>
                        </i>
                    </div>
                    <div className="d-flex flex-column">
                        <div data-toggle="tooltip" data-html="true" title={postulante.destacado?t("postulante.label.destacado"):null} className={"small-flag"+(postulante.destacado?" bg-warning on":" bg-info off")}>
                            <i className="fas fa-star"></i>
                        </div>
                        <div data-toggle="tooltip" data-html="true" title={postulante.opportunity?t("postulante.label.oportunidad"):null} className={"small-flag "+(postulante.opportunity?" bg-blue on":" bg-info off")}>
                            <i className="fa-custom-x1">
                                <img src="/icons/opportunity_24.png" alt="logo-icon"/>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div data-toggle="tooltip" data-html="true" title={postulante.verificar?t("postulante.label.verificar"):null} className={"small-flag "+(postulante.verificar?" bg-danger on":" bg-info off")}>
                        <i className="fas fa-exclamation-triangle"></i>
                    </div>
                    <div data-toggle="tooltip" data-html="true" title={postulante.offLimits?t("postulante.label.offLimits"):null} className={"small-flag "+(postulante.offLimits?" bg-danger on":" bg-info off")}>
                        <i className="fas fa-user-slash"></i>
                    </div>
                    <div role="button" data-toggle="tooltip" data-html="true" title={postulante.contacto.linkedin} className={"small-flag "+(postulante.contacto.linkedin?" bg-blue on":" bg-info off")}
                        onClick={()=>{
                            if(postulante.contacto.linkedin){
                                const prefix = "http://";
                                const securePrefix = "https://";
                                let url;
                                if(postulante.contacto.linkedin.substr(0, prefix.length) === prefix){
                                    url = postulante.contacto.linkedin;
                                }
                                if(postulante.contacto.linkedin.substr(0, securePrefix.length) === securePrefix){
                                    url = postulante.contacto.linkedin;
                                }else{
                                    url = prefix+postulante.contacto.linkedin;
                                }
                                window.open(url, "_blank")
                            }
                        }}>
                        <i className="fab fa-linkedin-in"></i>
                    </div>
                </div>
            </div>
        </>
    );
}
