import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function DownloadAdjunto(props) {
    const {entity, filename, type, contentType, originalFilename, downloadMode = false} = props;
    const [t] = useTranslation("global");

    const DOWNLOAD_ADJUNTO = gql`
        query download${entity}Adjunto(
            $filename: String!
        ) {
            download${entity}Adjunto(
                filename: $filename
            )
        }
    `;

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type:mimeString});
    }

    const [downloadAdjuntoQuery] = useLazyQuery(DOWNLOAD_ADJUNTO, {
        onCompleted: (dataAdjunto) => {
            const dataFile = dataAdjunto?dataAdjunto['download'+entity+'Adjunto']:null;
            if(dataFile){
                console.log(dataFile)
                const data = dataURItoBlob(dataAdjunto['download'+entity+'Adjunto']);

                if(downloadMode){
                    saveData(new Blob([data], {type: contentType?contentType:'octet/stream'}), originalFilename?originalFilename:filename);
                }else{
                    window.open(URL.createObjectURL(new Blob([data], {type: contentType?contentType:'application/pdf'})));
                }
    
            }
        }
    });

    const downloadAdjunto = () => {
        downloadAdjuntoQuery({variables: {filename: filename}});
    }

    var saveData = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const render = () => {
        if(type && type==="tool"){
            return (
                <button type="button" className="btn btn-xs bg-info btn-tool ml-2" title={t("common.label.descargarAdjunto")} onClick={()=>{downloadAdjunto()}}>
                    <i className="fas fa-cloud-download-alt"></i>
                </button>
            );
        }else{
            return (
                <i title={t("common.label.descargarAdjunto")} onClick={()=>{downloadAdjunto()}} className="fas fa-cloud-download-alt"></i>
            );
        }
    }
    
    return (
        filename?
        render()
        :
        <></>
    )

}
