import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import AccionesSeguimiendoSelect from '../../../../../../components/AccionesSeguimiendoSelect';
import PRIORIDAD from '../../../../../../enums/Prioridad';
import FlagButtonBoolean from '../../../../../../components/FlagButtonBoolean';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';
import MultipleAutocomplete from '../../../../../../components/MultipleAutocomplete';

const AGREGAR_RESPONSABLE = gql`
    mutation agregarResponsableAlProyectoPostulante(
        $idUsuario: Int!
        $idProyectoPostulante: Int!
    ) {
        agregarResponsableAlProyectoPostulante(
            idUsuario: $idUsuario
            idProyectoPostulante: $idProyectoPostulante
        )
    }
`;

const ELIMINAR_RESPONSABLE = gql`
    mutation eliminarResponsableDelProyectoPostulante(
        $idUsuario: Int!
        $idProyectoPostulante: Int!
    ) {
        eliminarResponsableDelProyectoPostulante(
            idUsuario: $idUsuario
            idProyectoPostulante: $idProyectoPostulante
        )
    }
`;

const UPDATE_PROYECTO_POSTULANTE_MUTATION = gql`
    mutation updateProyectoPostulante(
        $id: ID!
        $estado: Int!
        $prioridad: Int!
        $accion: Int
        $importante: Boolean
        $comentario: String
    ) {
        updateProyectoPostulante(
            entity:{
                id: $id
                estado: $estado
                prioridad: $prioridad
                accion: $accion
                importante: $importante
                comentario: $comentario
            }
        ) {
            id
        }
    }
`;

const UPDATE_PROYECTOS_POSTULANTES_MUTATION = gql`
    mutation updateProyectoPostulanteList(       
        $entities: [ProyectoPostulanteInput!]!
    ) {
        updateProyectoPostulanteList(
            entities: $entities
        )
    }
`;

const PROYECTO_POSTULANTE_SEARCH_BY_ID = gql`
    query findProyectoPostulanteById(
        $id: Int!
    ) {
    findProyectoPostulanteById(
        id: $id
    ){
        id
        etapa{
            id
        }
        estado{
            id
        }
        prioridad{
            id
        }
        responsableList {
            id
            contacto{
                alias
            }
        }
    }
}
`;

export default function ControlEstado(props) {
    const { proyectosPostulante, postulanteLabel, refetch, show, setShow } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const { showErrorToast, clearPopUps } = useAlertMessage();

    const [etapa, setEtapa] = useState("");
    const [estado, setEstado] = useState("");
    const [prioridad, setPrioridad] = useState("");
    const [responsableList, setResponsableList] = useState([]);
    const [accion, setAccion] = useState("");
    const [importante, setImportante] = useState("");
    const [comentario, setComentario] = useState("");

    const [agregarResponsableMutation] = useMutation(AGREGAR_RESPONSABLE,{
        onCompleted: () => {
            console.log("ENTRO");
            refetch();
            refetchProyectoPostulante();
        }
    });

    const agregarResponsable = (selectedUser) => {
        if(selectedUser.value){
            agregarResponsableMutation({
                variables: {
                    idUsuario: parseInt(selectedUser.value),
                    idProyectoPostulante: Object.keys(proyectosPostulante)?.[0]
                }
            });
        }
    }

    const [eliminarResponsableMutation] = useMutation(ELIMINAR_RESPONSABLE,{
        onCompleted: () => {
            console.log("ENTRO");
            refetch();
            refetchProyectoPostulante();
        }
    });

    const eliminarResponsable = (selectedUser) => {
        if(selectedUser.value){
            eliminarResponsableMutation({
                variables: {
                    idUsuario: parseInt(selectedUser.value),
                    idProyectoPostulante: Object.keys(proyectosPostulante)?.[0]
                }
            });
        }
    }

    const [searchProyectoPostulanteById] = useLazyQuery(PROYECTO_POSTULANTE_SEARCH_BY_ID, {
        onCompleted: (data) => {
            const proyectoPostulante = data?.findProyectoPostulanteById;
            console.log("ID: ",proyectoPostulante?.id);
            console.log("RESPONSABLE LIST: ",proyectoPostulante?.responsableList);
            console.log("PROYECTO POSTULANTE: ",proyectoPostulante);

            setEstado(parseInt(proyectoPostulante?.estado?.id));
            setPrioridad(parseInt(proyectoPostulante?.prioridad?.id));
            setEtapa(parseInt(proyectoPostulante?.etapa?.id));
            setResponsableList(proyectoPostulante?.responsableList);
        }
    });

    const [updateProyectoPostulanteMutation] = useMutation(UPDATE_PROYECTO_POSTULANTE_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });

    const [updateProyectosPostulantesMutation] = useMutation(UPDATE_PROYECTOS_POSTULANTES_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });
    
    const updateProyectoPostulante = () => {
        
        if(Object.keys(proyectosPostulante).length===1){
            const idProyectoPostulante = parseInt(Object.keys(proyectosPostulante)[0]);
            updateProyectoPostulanteMutation({variables: {
                id: idProyectoPostulante,
                estado: estado,
                prioridad: prioridad,
                accion: accion?accion:null,
                importante: importante?importante:null,
                comentario: comentario?comentario:null,
            }})
        }else if(Object.keys(proyectosPostulante).length>1){
            const entities = Object.keys(proyectosPostulante).map(key => {
                const proyectoPostulante = proyectosPostulante[key];
                return({
                    id: key,
                    estado: parseInt(proyectoPostulante?.estado?.id),
                    prioridad: parseInt(proyectoPostulante?.prioridad?.id),
                    accion: accion?accion:null,
                    importante: importante?importante:null,
                    comentario: comentario?comentario:null,
                })
            })
            updateProyectosPostulantesMutation({variables: {
                entities: entities               
            }});
        }
    }
    
    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!estado){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.estado")})});
        }
        if(!prioridad){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.prioridad")})});
        }
        if(!responsableList?.length){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.responsable")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const handleSave = () =>{
        if(validate()){
            updateProyectoPostulante();
        }
    }

    const [multiple, setMultiple] = useState(false);

    const refetchProyectoPostulante = () => {
        const idProyectoPostulante = parseInt(Object.keys(proyectosPostulante)[0]);
        searchProyectoPostulanteById({
            variables:{
                id: idProyectoPostulante
            }
        })
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            if(Object.keys(proyectosPostulante).length===1){
                setMultiple(false);
                const idProyectoPostulante = parseInt(Object.keys(proyectosPostulante)[0]);
                searchProyectoPostulanteById({
                    variables:{
                        id: idProyectoPostulante
                    }
                })
                setAccion("");
                setImportante("");
                setComentario("");
                setResponsableList([]);
                // TODO ERS ver que hacer con los responsables
            }else{
                const proyectoPostulante = proyectosPostulante[Object.keys(proyectosPostulante)[0]];
                setMultiple(true);
                setEtapa(parseInt(proyectoPostulante?.etapa?.id))
                setEstado("");
                setPrioridad("");
                setResponsableList([]);
                // TODO ERS ver que hacer con los responsables
                setAccion("");
                setImportante("");
                setComentario("");
            }
        }
    }, [proyectosPostulante, searchProyectoPostulanteById, show]);

    return (
        <>
            <div ref={ref} className="modal" role="dialog">
                {show&&
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header pl-3 p-2">
                                <h5 className="modal-title">{t("proyecto.label.actualizarEstadoDe")}{postulanteLabel}</h5>
                                <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body parrish-form">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-estado" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("postulante.field.estado")}</label>
                                            <div className="col-sm-10 col-xs-12">
                                                {etapa&&
                                                    <SelectKeyValue
                                                        entity="EstadoPostulante"
                                                        by="Etapa"
                                                        name={'value-estado'}
                                                        value={estado}
                                                        filterName="idEtapa"
                                                        filterValue={etapa}
                                                        onChange={(e) => {
                                                            setEstado(e.target.value);
                                                        }}/>
                                                }
                                            </div>
                                        </div>
                                        {!multiple&&
                                            <>
                                                <div className="form-group row">
                                                    <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("postulante.field.prioridad")}</label>
                                                    <div className="col-10 d-flex">
                                                        <div className="custom-control custom-radio d-flex mr-2">
                                                            <input autoComplete="off" id="etapa-quick-win" name="value-prioridad"
                                                                onChange={(e)=>{setPrioridad(e.target.value)}}
                                                                checked={parseInt(prioridad) === PRIORIDAD.QUICK_WIN}
                                                                className="custom-control-input custom-control-input-info"
                                                                value={PRIORIDAD.QUICK_WIN}
                                                                type="radio"/>
                                                            <label htmlFor="etapa-quick-win" className="custom-control-label d-flex">
                                                                <FlagButtonBoolean
                                                                    icon="fas fa-running"
                                                                    onClass={"bg-maroon"}
                                                                    value={true}
                                                                />
                                                                <span className="ml-1">{t("postulante.field.prioridadQuickWin")}</span>
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mr-2">
                                                            <input autoComplete="off" id="etapa-interesting-match" name="value-prioridad"
                                                                onChange={(e)=>{setPrioridad(e.target.value)}}
                                                                checked={parseInt(prioridad) === PRIORIDAD.INTERESTING_MATCH}
                                                                className="custom-control-input custom-control-input-info"
                                                                value={PRIORIDAD.INTERESTING_MATCH}
                                                                type="radio"/>
                                                            
                                                            <label htmlFor="etapa-interesting-match" className="custom-control-label d-flex">
                                                                <FlagButtonBoolean
                                                                    icon="fas fa-running"
                                                                    onClass={"bg-indigo"}
                                                                    value={true}
                                                                />
                                                                <span className="ml-1">{t("postulante.field.prioridadInterestingMatch")}</span>
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mr-2">
                                                            <input autoComplete="off" id="etapa-stardard-match" name="value-prioridad"
                                                                onChange={(e)=>{setPrioridad(e.target.value)}}
                                                                checked={parseInt(prioridad) === PRIORIDAD.STANDARD_MATCH}
                                                                className="custom-control-input custom-control-input-info"
                                                                value={PRIORIDAD.STANDARD_MATCH}
                                                                type="radio"/>
                                                            <label htmlFor="etapa-stardard-match" className="custom-control-label d-flex">
                                                                <FlagButtonBoolean
                                                                    icon="fas fa-running"
                                                                    onClass={"bg-info"}
                                                                    value={true}
                                                                />
                                                                <span className="ml-1">{t("postulante.field.prioridadStardardMatch")}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("postulante.field.responsable")}</label>
                                                    <div className="col-sm-10 col-xs-12">
                                                        <MultipleAutocomplete
                                                            name="value-responsable-list"
                                                            entity="UsuarioActivo"
                                                            by="Alias"
                                                            filterName="alias"
                                                            searchInFocus={true}
                                                            value={
                                                                responsableList?.map((element)=>{
                                                                    return ({
                                                                        label: element?.contacto?.alias,
                                                                        value: element?.id
                                                                    })
                                                                })
                                                            }
                                                            onRemove={(value)=>{
                                                                eliminarResponsable(value);
                                                            }}
                                                            onSelect={(value)=>{
                                                                agregarResponsable(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <h5 className="mt-3 p-0 text-uppercase text-center">{t("proyecto.label.seguimiento")}</h5>
                                        <div className="form-group row">
                                            <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12 text-right align-self-start">{t("postulante.field.accion")}</label>
                                            <div className="col-sm-6 col-xs-12">
                                                <AccionesSeguimiendoSelect value={accion} setValue={setAccion}/>
                                            </div>
                                            <div className="col-sm-4 text-center">
                                                <input autoComplete="off"
                                                    className="btn m-2"
                                                    type="checkbox"
                                                    checked={importante}
                                                    onChange={(e)=>{setImportante(e.target.checked)}} />
                                                <span>{t("seguimiento.field.importante")}</span>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <label htmlFor="value-comentario" className="parrish-label col-sm-2 col-xs-12 text-right align-self-start">{t("postulante.field.comentario")}</label>
                                            <div className="col-sm-10 col-xs-12">
                                                <textarea
                                                    name="value-comentario"
                                                    value={comentario}
                                                    onChange={e => setComentario(e.target.value)}
                                                    className="form-control form-control-sm" rows="6"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            
                                        </div>   
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                    {t("common.button.guardar")}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
