import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import SidebarContext from '../context/SidebarContext';

export default function SeguimientoButton(props) {
    const [t] = useTranslation("global");
    const {idEmpresa, idPostulante, idResponsable, idProyecto, idAccionSeguimiento, className, labelEmpresa, labelPostulante, labelProyecto} = props;
    const { setSideBarState, show } = useContext(SidebarContext);
    
    return(
        <button
            type="button"
            title={t("postulante.field.seguimiento")}
            className={className?className:"btn btn-xs bg-yellow m-1"}
            onClick={()=>{
                setSideBarState({
                    mode: "seguimiento",
                    empresa: idEmpresa?idEmpresa:null,
                    postulante: idPostulante?idPostulante:null,
                    responsable: idResponsable?idResponsable:null,
                    proyecto: idProyecto?idProyecto:null,
                    accionSeguimiento: idAccionSeguimiento?idAccionSeguimiento:null,
                    labelEmpresa: labelEmpresa?labelEmpresa:null,
                    labelPostulante: labelPostulante?labelPostulante:null,
                    labelProyecto: labelProyecto?labelProyecto:null
                })
                show();
            }}>
            <i className="fas fa-shoe-prints fa-rotate-270"></i>
        </button> 
    );
}
