import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import ProvinciaCrud from "./provincia/crud"

const PAIS_SEARCH_BY_ID = gql`
    query findPaisById(
        $id: Int!
    ) {
    findPaisById(
        id: $id
    ){
        id
        codigo
        nombre
        provincias{
            id
            nombre
        }
    }
}
`;

const UPDATE_PAIS_MUTATION = gql`
    mutation updatePais(
        $id: ID!
        $codigo: String
        $nombre: String!
    ) {
        updatePais(entity:{
            id: $id
            codigo: $codigo
            nombre: $nombre
        }) {
            id
            codigo
            nombre
        }
    }
`;

const DELETE_PAIS_MUTATION = gql`
    mutation deletePais(
        $id: Int!
    ) {
        deletePais(id: $id)
    }
`;

function PaisEdit() {
    const history = useHistory();
    const { id } = useParams();
    const { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [codigo, setCodigo] = useState('');
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data, refetch } = useQuery(
        PAIS_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setCodigo(data?.findPaisById?.codigo?data.findPaisById.codigo:"");
            setNombre(data?.findPaisById?.nombre?data.findPaisById.nombre:"");
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findPaisById?.codigo,
        data?.findPaisById?.nombre
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updatePaisMutation] = useMutation(UPDATE_PAIS_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/pais/edit/"+data.updatePais.id;
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deletePaisMutation] = useMutation(DELETE_PAIS_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/pais");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updatePaisMutation({
            variables:{
                id: id,
                codigo: codigo,
                nombre: nombre
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deletePaisMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    const Tabs = () => {
        return(
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted active"
                                id="custom-tabs-one-home-tab"
                                data-toggle="pill"
                                href="#custom-tabs-one-home"
                                role="tab"
                                aria-controls="custom-tabs-one-home"
                                aria-selected="true">
                                Provincias
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                            <ProvinciaCrud idPais={data?.findPaisById?.id} refetch={refetch}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }







    







    return (
        <>
        <CardEditEntity
            entityName="pais"
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "nombre",
                    type: "text",
                    maxLength: 50,
                    value: nombre,
                    setValue: setNombre,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("pais.field.nombre")})
                    }
                },
                {
                    name: "codigo",
                    type: "text",
                    maxLength: 2,
                    value: codigo,
                    setValue: setCodigo,
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("pais.field.codigo")})
                    }
                }
            ]}
            returnUrl="/pais"
            id={id}
            label={nombre}
            deleteAction={deleteAction}
            loading={loading}
            error={error}
            >
        </CardEditEntity>
        <Tabs/>
        </>
    )
}

export default PaisEdit