/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useContext } from 'react'
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"
import * as $ from 'jquery';
import Treeview from '../components/Treeview'
import AppStateContext from "../context/AuthContext"
import PrivateLink from '../components/navigation/PrivateLink';
import Sidebar from './Sidebar';
import SidebarContext from '../context/SidebarContext';

function getData(userData){
    const data = [];

    userData?.menuList?.sort((a, b) => (a.orden > b.orden) ? 1 : -1)
    userData?.menuList?.forEach(val => data.push(Object.assign({}, val)));

    let treeRoot = [];
    let currentLevel = 0;
    let lastParent = null;
    let lastElement = null;

    data.splice(0, 1);

    for(let spliceElement = data.splice(0, 1); spliceElement.length>0; spliceElement = data.splice(0, 1)){
        spliceElement = spliceElement[0];
        let level = spliceElement.nivel-1;
        if(level === 0){
            lastParent = spliceElement;
            treeRoot.push(spliceElement);
        }
        else
        {
            if(level>currentLevel){//Sube
                currentLevel = level;
                lastParent = lastElement;
            }else if(level<currentLevel){//Baja
                currentLevel = level;               
            }
            spliceElement.parent = lastParent;
            if(lastParent.childs===undefined){
                lastParent.childs = [];
            }
            lastParent.childs.push(spliceElement);
        }
        lastElement = spliceElement;
    }
    return treeRoot;
}

function MenuTreeRender(props){
    const location = useLocation();
    const [t] = useTranslation("global");
    
    return(
        props.items.map((itemMenu)=>{
            if(itemMenu.childs){
                return(
                    <li className={"nav-item "+(props.isRoot?"":"ml-3")} key={itemMenu.orden}>
                        <a href="#" className="nav-link w-100">
                            {
                                (itemMenu.imagen)?<i className={'nav-icon ml-1 '+itemMenu.imagen} />:''
                            }
                            <p>
                                {t(itemMenu.nombre)}
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className={"nav nav-treeview"} style={{display: "none"}}>
                            <MenuTreeRender items={itemMenu.childs} isRoot={false}/>
                        </ul>
                    </li>
                );
            }else{
                return(
                    <li className={"nav-item "+(props.isRoot?"":"ml-3")} key={itemMenu.orden}>
                        <PrivateLink to={itemMenu.accion} className={'nav-link w-100'+(location.pathname?.toString().toLowerCase().startsWith("/private"+itemMenu.accion?.toString().toLowerCase())?' active':'')}>
                            {
                                (itemMenu.imagen)?<i className={'nav-icon '+itemMenu.imagen} />:null
                            }
                            <p>{t(itemMenu.nombre)}</p>
                        </PrivateLink>
                    </li>
                );
            }
        })
    )
}

function MenuTree(props){
    const { menuData } = props;
    const location = useLocation();
    const data = getData(menuData);

    const refreshTreeview = ()=>{
        $(document).off('click');
        Treeview._jQueryInterface.call($('[data-widget="parrish-treeview"]'), 'init');
    }

    useEffect(() => {
        refreshTreeview();
    }, [location.pathname]);

    return(<>
        <ul id="parrish-menu" data-widget="parrish-treeview" data-accordion="true" className="nav nav-pills nav-sidebar flex-column text-sm nav-compact nav-child-indent nav-collapse-hide-child" role="menu">
            <MenuTreeRender items={data} location={location.pathname} isRoot={true}/>
        </ul>
    </>)
}

export default function Menu(){
    const { appGetUserData } = useContext(AppStateContext);
    const { isShow, hide } = useContext(SidebarContext);
    const [t] = useTranslation("global");
    
    return (
        <>
            <aside id="side-bar" className="control-sidebar control-sidebar-light parrish-sidebar">
                <Sidebar/>
            </aside>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <PrivateLink to="/home" className="brand-link navbar-info elevation-4">
                    <img src="/logo-header.png" alt="{t('layout.value.project.title')}" className="brand-image img-circle bg-white elevation-3" />
                    <span className="brand-text text-light font-weight-light">{t("layout.value.project.title")}</span>
                </PrivateLink>
                <div className="sidebar">
                    <nav className="mt-2">
                        <MenuTree menuData={appGetUserData()}/>
                    </nav>
                </div>
            </aside>
            {isShow()&&
                <div className="sidebar-overlay" onClick={()=>{
                    hide();
                }}/>
            }
        </>
    )
}
