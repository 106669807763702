import React, { useState, useEffect, useRef, useContext } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client';
import { } from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import DatePicker from '../../../../components/DatePicker';
import PageHeader from '../../../../components/PageHeader';
import moment from 'moment'
import AppStateContext from "../../../../context/AuthContext"
import UserAvatarImg from '../../../../components/UserAvatarImg';
import AvatarModal from './avatarModal';
import CardFooterRecordData from '../../../../components/CardFooterRecordData';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import { DateRfc }  from '../../DateRfc';

const CONTACTO_SEARCH_BY_ID = gql`
    query findUsuarioById(
        $id: Int!
    ) {
    findUsuarioById(
        id: $id
    ){
        id
        nombre
        apellido
        email
        fechaAlta
        perfil{
            id
            nombre
        }
        contacto{
            id
            alias
            nacionalidad{
                id
                nombre
            }
            pais{
                id
                nombre
            }
            provincia{
                id
                nombre
            }
            fechaNacimiento
            personaFisica
            apellido
            codigoPostal
            domicilio
            email
            facebook
            foto
            linkedin
            localidad
            nombre
            observaciones
            skype
            telefonosFijos
            telefonosMoviles
            twitter
            website
        }
    }
}
`;


const UPDATE_CONTACTO_MUTATION = gql`
    mutation updateMyProfile(
        $id: ID!, 
        $nombre: String!, 
        $apellido: String!, 
        $email: String!, 
        $nacionalidad: Int, 
        $pais: Int, 
        $provincia: Int, 
        $fechaNacimiento: LocalDate, 
        $codigoPostal: String, 
        $domicilio: String, 
        $facebook: String, 
        $linkedin: String, 
        $localidad: String, 
        $skype: String, 
        $telefonosFijos: String, 
        $telefonosMoviles: String, 
        $twitter: String, 
        $website: String
    ) {
    updateMyProfile(entity:{
        idUsuario:$id,
        nombre: $nombre,
        apellido: $apellido,
        email: $email,
        nacionalidad: $nacionalidad, 
        pais: $pais, 
        provincia: $provincia, 
        fechaNacimiento: $fechaNacimiento, 
        codigoPostal: $codigoPostal, 
        domicilio: $domicilio, 
        facebook: $facebook, 
        linkedin: $linkedin, 
        localidad: $localidad, 
        skype: $skype, 
        telefonosFijos: $telefonosFijos, 
        telefonosMoviles: $telefonosMoviles, 
        twitter: $twitter, 
        website: $website
    }) {
        id
        fechaAlta
        nombre
        apellido
        email
        idioma{
            codigo
            nombre
        }
        perfil{
            nombre
        }
        contacto {
            id
            alias
            nacionalidad {
                id
                nombre
            }
            pais {
                id
                nombre
            }
            provincia {
                id
                nombre
            }
            fechaNacimiento
            apellido
            codigoPostal
            contentType
            domicilio
            email
            facebook
            foto
            linkedin
            localidad
            nombre
            skype
            telefonosFijos
            telefonosMoviles
            twitter
            website
        }
    }
    }
`;

const UPDATE_AVATAR_MUTATION = gql`
  mutation updateMyAvatar($avatar: Upload!) {
    updateMyAvatar(avatar: $avatar) {
        id
    }
  }
`;

const DELETE_AVATAR_MUTATION = gql`
  mutation deleteMyAvatar{
    deleteMyAvatar{
        id
    }
  }
`;

function ContactoEdit(props) {
    const { appGetUserData, refreshCurrentUserData } = useContext(AppStateContext);
    const userData = appGetUserData();
    const [id] = useState(parseInt(userData?.usuario?.id));
    let { showMessageWithTimerAndExecute, showErrorToast, clearPopUps } = useAlertMessage();

    const fileMaxSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.fwk.config.max.file.size"})?.valor;

    const [tempAvatar, setTempAvatar] = useState();
    const [openAvatarModal, setOpenAvatarModal] = useState(false);

    const [nacionalidad, setNacionalidad] = useState('');
    const [pais, setPais] = useState('');
    const [provincia, setProvincia] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState();
    const [apellido, setApellido] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [email, setEmail] = useState('');
    const [facebook, setFacebook] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [nombre, setNombre] = useState('');
    const [skype, setSkype] = useState('');
    const [telefonosFijos, setTelefonosFijos] = useState('');
    const [telefonosMoviles, setTelefonosMoviles] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');

    const fileInput = React.createRef();

    const [t] = useTranslation("global");

    const _isMounted = useRef(true);
    const ref = useRef();
    const { loading, error, data } = useQuery(
        CONTACTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    useEffect(() => {
        if (!loading && !error) {
            setNombre(data?.findUsuarioById?.nombre ? data?.findUsuarioById?.nombre : '');
            setApellido(data?.findUsuarioById?.apellido ? data?.findUsuarioById?.apellido : '');
            setEmail(data?.findUsuarioById?.email ? data?.findUsuarioById?.email : '');

            setNacionalidad(data?.findUsuarioById?.contacto?.nacionalidad?.id);
            setPais(data?.findUsuarioById?.contacto?.pais?.id);
            setProvincia(data?.findUsuarioById?.contacto?.provincia?.id);
            if (data?.findUsuarioById?.contacto?.fechaNacimiento) {
                setFechaNacimiento(new DateRfc(data.findUsuarioById.contacto.fechaNacimiento));
            }
            setCodigoPostal(data?.findUsuarioById?.contacto?.codigoPostal ? data?.findUsuarioById?.contacto?.codigoPostal : '');
            setDomicilio(data?.findUsuarioById?.contacto?.domicilio ? data?.findUsuarioById?.contacto?.domicilio : '');
            setFacebook(data?.findUsuarioById?.contacto?.facebook ? data?.findUsuarioById?.contacto?.facebook : '');
            setLinkedin(data?.findUsuarioById?.contacto?.linkedin ? data?.findUsuarioById?.contacto?.linkedin : '');
            setLocalidad(data?.findUsuarioById?.contacto?.localidad ? data?.findUsuarioById?.contacto?.localidad : '');
            setSkype(data?.findUsuarioById?.contacto?.skype ? data?.findUsuarioById?.contacto?.skype : '');
            setTelefonosFijos(data?.findUsuarioById?.contacto?.telefonosFijos ? data?.findUsuarioById?.contacto?.telefonosFijos : '');
            setTelefonosMoviles(data?.findUsuarioById?.contacto?.telefonosMoviles ? data?.findUsuarioById?.contacto?.telefonosMoviles : '');
            setTwitter(data?.findUsuarioById?.contacto?.twitter ? data?.findUsuarioById?.contacto?.twitter : '');
            setWebsite(data?.findUsuarioById?.contacto?.website ? data?.findUsuarioById?.contacto?.website : '');
        }
        return () => { _isMounted.current = true };
    }, [loading, error, data?.findUsuarioById])

    const [updateContactoMutation] = useMutation(UPDATE_CONTACTO_MUTATION, {
        onCompleted: (data) => {
            if (_isMounted.current) {
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1500, () => { });
                refreshCurrentUserData(data?.updateMyProfile);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () => {
        updateContactoMutation({
            variables: {
                id: id,
                nombre: nombre,
                apellido: apellido,
                email: email,
                nacionalidad: parseInt(nacionalidad),
                pais: parseInt(pais),
                provincia: parseInt(provincia),
                fechaNacimiento: fechaNacimiento ? moment(fechaNacimiento).format("YYYY-MM-DD") : null,
                codigoPostal: codigoPostal,
                domicilio: domicilio,
                facebook: facebook,
                linkedin: linkedin,
                localidad: localidad,
                skype: skype,
                telefonosFijos: telefonosFijos,
                telefonosMoviles: telefonosMoviles,
                twitter: twitter,
                website: website
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    const handleChange = (file) => {
        clearPopUps();
        if(file.size/1024/1024 < (fileMaxSizeDefaultParam?parseInt(fileMaxSizeDefaultParam):20)){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setTempAvatar(reader.result);
                setOpenAvatarModal(true);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }else{
            showErrorToast([{message: t("common.message.size-file-exceed")}]);
        }
    }

    const linkClick= (e,url) =>{
        e.preventDefault();
        if(url){
            // Check if the URL starts with "http://" or "https://"
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                // If not, prepend "http://"
                url = "https://" + url;
            }
            
            window.open(url,"_blank");
        }
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const [updateAvatar] = useMutation(UPDATE_AVATAR_MUTATION, {
        onCompleted: (data) => {
            window.location.reload();
        },
        onError: (error) => {
        }
    });

    const uploadAvatar = (newAvatar) => {
        updateAvatar({ variables: { avatar: newAvatar } })
    }

    const { showConfirmDelete } = useAlertMessage();

    const [deleteContactoAvatar] = useMutation(DELETE_AVATAR_MUTATION, {
        onCompleted: (data) => {
            window.location.reload();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const deleteAvatar = () => {
        showConfirmDelete(
            "",
            "My Avatar",
            (id)=>{deleteContactoAvatar()}
        );
    }

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="content-wrapper px-3">
                <PageHeader entityName="miPerfil" />

                <div className="container-fluid">
                    <div className="parrish-form row">
                        <div className="col-md-2 pl-0 d-flex align-items-stretch">
                            <div className="card card-info card-outline w-100">
                                <div className="card-body box-profile">

                                    <div className="text-center">
                                        <div className="avatar-container">
                                            <label className="btn remove-btn btn-xs btn-info border-light position-absolute" onClick={()=>deleteAvatar()}>
                                                <i className="fas fa-trash"></i>
                                            </label>
                                            <label className="btn btn-xs btn-info border-light position-absolute" forhtml="my-file-selector">
                                                <input autoComplete="off" id="my-file-selector" type="file" className="d-none" onChange={({ target: { files: [file] } }) => handleChange(file)} ref={fileInput} />
                                                <i className="fas fa-camera"></i>
                                            </label>
                                            <AvatarModal open={openAvatarModal} setOpen={setOpenAvatarModal} imageData={tempAvatar} uploadAvatar={uploadAvatar} />
                                            <UserAvatarImg className="profile-user-img img-fluid img-circle border-info" alt="User profile picture" />
                                        </div>
                                    </div>

                                    <h3 className="profile-username text-center">{data?.findUsuarioById?.contacto.alias}</h3>
                                    <p className="text-muted text-center">{t("miPerfil.field.perfil")}: {data?.findUsuarioById?.perfil?.nombre}</p>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>{t("miPerfil.field.edad")}</b> <span className="float-right">{data?.findUsuarioById?.contacto?.fechaNacimiento ? moment().diff(data?.findUsuarioById?.contacto?.fechaNacimiento, 'years', false) : "-"} {t("common.label.anios")}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 pr-0 d-flex flex-column align-items-stretch">
                            <div className="card card-outline card-info w-100">
                                {/*
                                    loading &&
                                    !error && (
                                        <div className="overlay">
                                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                        </div>
                                    )
                                */}
                                <div className="card-header">
                                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })}</h3>
                                    <div className="card-tools">
                                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                                            <i className="fas fa-save"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">


                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group row">
                                                <label htmlFor="value-nombre" className="parrish-label col-3 text-right required">{t("Nombre")}</label>
                                                <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-nombre'}
                                                        className="parrish-input"
                                                        value={nombre}
                                                        maxLength={150}
                                                        onChange={(e) => setNombre(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-apellido" className="parrish-label col-3 text-right required">{t("Apellido")}</label>
                                                <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-apellido'}
                                                        className="parrish-input"
                                                        value={apellido}
                                                        maxLength={150}
                                                        onChange={(e) => setApellido(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="value-apellido" className="parrish-label col-3 text-right font-weight-normal ">{t("F. Nacimiento")}</label>
                                                <div className="col-8">
                                                    <DatePicker
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        className="border-danger"
                                                        selected={fechaNacimiento}
                                                        onChange={date => setFechaNacimiento(date)} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-apellido" className="parrish-label col-3 text-right font-weight-normal">{t("Nacionalidad")}</label>
                                                <div className="col-8">
                                                    <SelectKeyValue
                                                        entity="Pais"
                                                        label="nombre"
                                                        name={'value-nacionalidad'}
                                                        value={nacionalidad}
                                                        required
                                                        onChange={(e) => {
                                                            setNacionalidad(e.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <h5 className="text-center mt-4">REDES SOCIALES</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-linkedin" className="parrish-label col-3 text-right font-weight-normal">{t("LinkedIn")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,linkedin)}}>
                                                            <span className="input-group-text">
                                                                <i className="fab fa-linkedin-in"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-linkedin'}
                                                            className="form-control"
                                                            value={linkedin}
                                                            maxLength={150}
                                                            onChange={(e) => setLinkedin(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-website" className="parrish-label col-3 text-right font-weight-normal">{t("Website")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,website)}}>
                                                            <span className="input-group-text">
                                                                <i className="fas fa-globe-americas"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-website'}
                                                            className="parrish-input"
                                                            value={website}
                                                            maxLength={150}
                                                            onChange={(e) => setWebsite(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-skype" className="parrish-label col-3 text-right font-weight-normal">{t("Skype")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-skype"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-skype'}
                                                            className="form-control"
                                                            value={skype}
                                                            maxLength={150}
                                                            onChange={(e) => setSkype(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-twitter" className="parrish-label col-3 text-right font-weight-normal">{t("Twitter")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-twitter"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-twitter'}
                                                            className="form-control"
                                                            value={twitter}
                                                            maxLength={150}
                                                            onChange={(e) => setTwitter(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-facebook" className="parrish-label col-3 text-right font-weight-normal">{t("Facebook")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-facebook-f"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-facebook'}
                                                            className="form-control"
                                                            value={facebook}
                                                            maxLength={150}
                                                            onChange={(e) => setFacebook(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <h5 className="text-center">DATOS DE CONTACTO</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-email" className="parrish-label col-3 text-right required">{t("Email")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{emailClick(e,email)}}>
                                                            <span className="input-group-text">
                                                                <i className="fas fa-envelope"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-email'}
                                                            className="parrish-input"
                                                            value={email}
                                                            maxLength={150}
                                                            onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-telefono" className="parrish-label col-3 text-right font-weight-normal">{t("Tel. Fijos")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fas fa-phone"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-telefono'}
                                                            className="form-control"
                                                            value={telefonosFijos}
                                                            maxLength={150}
                                                            onChange={(e) => setTelefonosFijos(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-celular" className="parrish-label col-3 text-right font-weight-normal">{t("Tel. Móviles")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{whatsappClick(e,telefonosMoviles)}}>
                                                            <span className="input-group-text">
                                                                <i className="fab fa-whatsapp"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-celular'}
                                                            className="form-control"
                                                            value={telefonosMoviles}
                                                            maxLength={150}
                                                            onChange={(e) => setTelefonosMoviles(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <h5 className="text-center mt-4">DOMICILIO</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-domicilio" className="parrish-label col-3 text-right font-weight-normal">{t("Domicilio")}</label>
                                                <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-domicilio'}
                                                        className="parrish-input"
                                                        value={domicilio}
                                                        maxLength={150}
                                                        onChange={(e) => setDomicilio(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-localidad" className="parrish-label col-3 text-right font-weight-normal">{t("Localidad")}</label>
                                                <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-localidad'}
                                                        className="parrish-input"
                                                        value={localidad}
                                                        maxLength={150}
                                                        onChange={(e) => setLocalidad(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-pais" className="parrish-label col-3 text-right font-weight-normal">{t("País")}</label>
                                                <div className="col-8">
                                                    <SelectKeyValue
                                                        entity="Pais"
                                                        label="nombre"
                                                        name={'value-pais'}
                                                        value={pais}
                                                        required
                                                        onChange={(e) => {
                                                            setPais(e.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-provincia" className="parrish-label col-3 text-right font-weight-normal">{t("Provincia")}</label>
                                                <div className="col-8">
                                                    <SelectKeyValue
                                                        entity="Provincia"
                                                        by="PaisNombre"
                                                        label="nombre"
                                                        name={'value-provincia'}
                                                        value={provincia}
                                                        filterValue={pais}
                                                        filterName="idPais"
                                                        onChange={(e) => {
                                                            setProvincia(e.target.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-cp" className="parrish-label col-3 text-right font-weight-normal">{t("Código Postal")}</label>
                                                <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-cp'}
                                                        className="parrish-input"
                                                        value={codigoPostal}
                                                        maxLength={150}
                                                        onChange={(e) => setCodigoPostal(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CardFooterRecordData record={data?.findUsuarioById} datePropName="fechaAlta" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ContactoEdit