import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import { } from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import DatePicker from './../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import EstadoMovimiento from '../estados/EstadoMovimiento';
import EstadoComprobante from '../estados/EstadoComprobante';
import FORMA_PAGO from '../../../../enums/FormaPago';
import MONEDA from '../../../../enums/Moneda';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import { DateRfc }  from '../../DateRfc';

const MOVIMIENTO_SEARCH_BY_ID = gql`
    query findMovimientoById(
        $id: Int!
    ) {
    findMovimientoById(
        id: $id
    ){
        id
        chequeBanco{
            id
            nombre
        }
        cliente {
            id
            contacto{
                alias
            }
        }
        cuenta{
            id
            nombre
            moneda{
                simbolo
            }
        }
        estado{
            id
            nombre
            color
        }
        formaPago{
            id
            nombre
        }
        moneda{
            id
            simbolo
        }
        referencia{
            id
        }
        retencion{
            id
            nombre
        }
        chequeFecha
        fechaAcreditacion
        fechaCancelacion
        canceladoPor{
            id
            contacto{
                alias
            }
        }
        fechaCobranza
        cobradoPor{
            id
            contacto{
                alias
            }
        }
        fechaConciliacion
        conciliadoPor{
            id
            contacto{
                alias
            }
        }
        fechaRegistro
        registradoPor{
            id
            contacto{
                alias
            }
        }
        cotizacionDolar
        cotizacionPeso
        impacto
        importe
        adjunto
        chequeNro
        chequeTitular
        detalle
        comprobantesAsociadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaRegistro
            registradoPor {
                contacto {
                alias
                }
            }
            fechaAprobacion
            aprobadoPor {
                contacto {
                alias
                }
            }
            fechaEmision
            emitidoPor {
                contacto {
                alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            proyecto {
                id
                ref
            }
            cbteFechaEmision
            cbteFechaVencimiento
            cbteTipo {
                nemotecnico
            }
            cbteNro
            cbteDetalle
            cbteMoneda {
                id
                simbolo
            }
            cbteImporteFinal
            cbteImporteIva
            cbteImporteNeto
            cbteImpacto
            cotizacionDolar
            cotizacionPeso
        }
        movimientosRelacionadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaAcreditacion
            fechaRegistro
            registradoPor {
                contacto {
                    alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                    alias
                }
            }
            fechaCancelacion
            canceladoPor {
                contacto {
                    alias
                }
            }
            fechaCobranza
            cobradoPor {
                contacto {
                    alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            moneda {
                id
                simbolo
            }
            formaPago{
                nombre
            }
            retencion{
                nombre
            }
            cuenta{
                nombre
                moneda{
                    simbolo
                }
            }
            impacto
            importe
            cotizacionDolar
            cotizacionPeso
            detalle
            detalleCbtesAsociados
            chequeBanco{
                nombre
            }
            chequeFecha
            chequeNro
            chequeTitular
        }
    }
}
`;

function MovimientoEdit() {
    let history = useHistory();
    let { id } = useParams();

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const { loading, error, data } = useQuery(
        MOVIMIENTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [fechaRegistro, setFechaRegistro] = useState();
    const [fechaAcreditacion, setFechaAcreditacion] = useState();
    const [fechaCancelacion, setFechaCancelacion] = useState();
    const [fechaCobranza, setFechaCobranza] = useState();
    const [fechaConciliacion, setFechaConciliacion] = useState();
    const [cuentaLabel, setCuentaLabel] = useState("")

    useEffect(() => {
        if (!loading && !error && data?.findMovimientoById) {
            const movimiento = data?.findMovimientoById;
            if(movimiento.fechaRegistro){
                setFechaRegistro(new DateRfc(movimiento.fechaRegistro));
            }
            if(movimiento.fechaAcreditacion){
                setFechaAcreditacion(new DateRfc(movimiento.fechaAcreditacion));
            }
            if(movimiento.fechaCancelacion){
                setFechaCancelacion(new DateRfc(movimiento.fechaCancelacion));
            }
            if(movimiento.fechaCobranza){
                setFechaCobranza(new DateRfc(movimiento.fechaCobranza));
            }
            if(movimiento.fechaConciliacion){
                setFechaConciliacion(new DateRfc(movimiento.fechaConciliacion));
            }
            const cuentaNombre = data?.findMovimientoById?.cuenta?.nombre;
            const monedaSimbolo = data?.findMovimientoById?.cuenta?.moneda?.simbolo;
            setCuentaLabel(data?.findMovimientoById?.cuenta?(cuentaNombre+" ["+monedaSimbolo+"]"):"");
        }
        return () => { _isMounted.current = false };
    }, [data?.findMovimientoById, error, loading])

    const monedaId = data?.findMovimientoById?.moneda.id;
    
    let totalMovimientos = data?.findMovimientoById?.movimientosRelacionadosList?.reduce((total, currVal) =>{
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.importe*cotizacionMoneda*currVal.impacto;
    } , 0);
    totalMovimientos += data?.findMovimientoById?.importe*data?.findMovimientoById?.impacto;
    
    const totalComprobantes = data?.findMovimientoById?.comprobantesAsociadosList?.reduce((total, currVal) => {
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.cbteImporteFinal*cotizacionMoneda*currVal.cbteImpacto;
    } , 0);

    return (
        <>
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}

                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })} - <span className={"badge badge-"+data?.findMovimientoById?.estado?.color}>{data?.findMovimientoById?.estado?.nombre}</span></h3>
                    <div className="card-tools m-0">
                        <DownloadAdjunto filename={data?.findMovimientoById?.adjunto} entity="Movimiento" type="tool"/>
                        <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaRegistro}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.registradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaCobranza" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCobranza")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaCobranza}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.cobradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaConciliacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaConciliacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaConciliacion}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.conciliadoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaCancelacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCancelacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaCancelacion}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.canceladoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="filter-cliente" className="parrish-label col-sm-2 col-xs-12">{t("movimiento.field.cliente")}</label>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.cliente?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("movimiento.label.datosCobranza")}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaAcreditacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaAcreditacion")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker readOnly selected={fechaAcreditacion}/>
                                </div>
                            </div>
                        </div>

                        { data?.findMovimientoById?.referencia &&
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="filter-movimientoReferenciado" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.movimientoReferenciado")}</label>
                                    <div className="col-sm-2 col-xs-12 d-flex">
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.referencia?.id}/>
                                        <button disabled={!data?.findMovimientoById?.referencia} className="btn btn-xs bg-info ml-1 mr-1 pl-2 pr-2" onClick={()=>{history.push("/private/movimiento/edit/"+data?.findMovimientoById?.referencia?.id)}}>
                                            <i className="far fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-detalle" className="parrish-label col-sm-2 col-xs-12">{t("movimiento.field.detalle")}</label>
                                <div className="col-sm-10 col-xs-12">
                                    <textarea name="value-detalle" value={data?.findMovimientoById?.detalle} className="form-control form-control-sm" rows="3" disabled={true}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12">
                                    {t("movimiento.field.cotizacion", { 1: "(U$D)", 0: data?.findMovimientoById?.moneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={data?.findMovimientoById?.cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        disabled
                                        />
                                </div>
        
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12">
                                    {t("movimiento.field.cotizacion", { 1: "(AR$)", 0: data?.findMovimientoById?.moneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    name="value-cotizacionPeso"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={4}
                                    fixedDecimalScale="true"
                                    value={data?.findMovimientoById?.cotizacionPeso}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-moneda" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.moneda?.simbolo}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-importe" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.importe")}</label>
                                <div className="col-sm-4 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={data?.findMovimientoById?.importe}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-formaPago" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.formaPago")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.formaPago?.nombre}/>
                                </div>
                            </div>
                        </div>

                        {
                            parseInt(data?.findMovimientoById?.formaPago.id) === FORMA_PAGO.RETENCION &&
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="filter-retencion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.retencion")}</label>
                                    <div className="col-sm-4 col-xs-12">
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.retencion?.nombre}/>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            parseInt(data?.findMovimientoById?.formaPago.id) === FORMA_PAGO.CHEQUE &&
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="filter-chequeFecha" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.chequeFecha")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <DatePicker readOnly selected={new DateRfc(data?.findMovimientoById?.chequeFecha)}/>
                                        </div>
                                    </div>
                                </div>
                        }

                        {parseInt(data?.findMovimientoById?.formaPago.id) !== FORMA_PAGO.CHEQUE &&
                            parseInt(data?.findMovimientoById?.formaPago.id) !== FORMA_PAGO.RETENCION &&
                            <div className="col-xs-12 col-sm-6"></div>
                        }

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cuenta" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.cuenta")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={cuentaLabel}/>
                                </div>
                            </div>
                        </div>

                        {
                            parseInt(data?.findMovimientoById?.formaPago.id) === FORMA_PAGO.CHEQUE &&
                            <>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="filter-chequeFecha" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.chequeBanco")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.chequeBanco.nombre}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6"></div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="filter-chequeFecha" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.chequeTitular")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.chequeTitular}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6"></div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="filter-chequeFecha" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.chequeNro")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findMovimientoById?.chequeNro}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase active"
                                id="custom-tabs-info-adicional-tab"
                                data-toggle="pill"
                                href="#custom-tabs-info-adicional"
                                role="tab"
                                aria-controls="custom-tabs-info-adicional"
                                aria-selected="true">
                                {t("movimiento.label.informacionAdicionalYResumenDelMovimiento")}
                            </a>
                        </li>
                    </ul>
                </div>
                
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-info-adicional" role="tabpanel" aria-labelledby="custom-tabs-info-adicional-tab">
                            <div className="d-flex justify-content-center">
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("movimiento.label.totalComprobantes")}</h5>
                                            <h2>
                                                <CurrencyFormat 
                                                    prefix={data?.findMovimientoById?.moneda.simbolo}
                                                    value={totalComprobantes}/>
                                            </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("movimiento.label.totalMovimientos")}</h5>
                                        <h2>
                                            <CurrencyFormat 
                                                prefix={data?.findMovimientoById?.moneda.simbolo}
                                                value={totalMovimientos}/>
                                        </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("movimiento.label.totalDiferencia")}</h5>
                                        <h2>
                                            <CurrencyFormat 
                                                className={(totalComprobantes-totalMovimientos)===0?"text-success":"text-danger"}
                                                prefix={data?.findMovimientoById?.moneda.simbolo}
                                                value={totalComprobantes-totalMovimientos}/>
                                        </h2>
                                    </div>       
                                </div>
                            </div>
                            <SimpleDatatable
                                data={data?.findMovimientoById?.comprobantesAsociadosList}
                                editUrl="/private/comprobante/edit/"
                                entityName="comprobante"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="11">
                                            <div>{t("movimiento.label.comprobantesAsociados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className:"text-center",
                                        render: (element)=>{
                                            return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                                        }
                                    },
                                    {
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoComprobante element={element}/>)
                                        }
                                    },
                                    {
                                        name: "proyecto",
                                        render: (element)=>{
                                            return(<p><Link className="text-dark" to={"/proyecto/edit/"+element.proyecto.id}>[{element.proyecto.id}] - {element.proyecto.ref}</Link></p>)
                                        }
                                    },
                                    {
                                        name: "cbteFechaEmision",
                                        type: "date"
                                    },
                                    {
                                        name: "cbteFechaVencimiento",
                                        type: "date"
                                    },
                                    {
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.cbteTipo.nemotecnico+(element.cbteNro?" ["+element.cbteNro+"]":"")}</p>
                                                <p>({element.cbteDetalle})</p>
                                            </>)
                                        }
                                    },
                                    {
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        render: (element)=>{
                                            return(element.cbteMoneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "cbteImporteNeto",
                                        className:"text-right",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeNeto = element.cbteImporteNeto*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeNeto}/>
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteIva",
                                        className:"text-right",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeIva = element.cbteImporteIva*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeIva}/>
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteFinal",
                                        className:"text-right",
                                        type: "money",
                                        render: (element)=>{
                                            const importeFinal = element.cbteImporteFinal*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeFinal}/>
                                            )
                                        }
                                    }
                                ]}
                            />
                            <SimpleDatatable
                                data={data?.findMovimientoById?.movimientosRelacionadosList}
                                editUrl="/private/movimiento/edit/"
                                entityName="movimiento"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="10">
                                            <div>{t("movimiento.label.movimientosRelacionados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className:"text-center",
                                        render: (element)=>{
                                            return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                                        }
                                    },
                                    {
                                        
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoMovimiento element={element}/>)
                                        }
                                    },
                                    {
                                        
                                        name: "cliente",
                                        render: (element)=>{
                                            return(element.cliente.contacto.alias)
                                        }
                                    },
                                    {
                                        name: "fechaAcreditacion",
                                        type: "date"
                                    },
                                    {
                                        
                                        name: "formaDePago",
                                        render: (element)=>{
                                            return(<>
                                                <p>{element.formaPago.nombre}</p>
                                                {element.retencion&&
                                                    <p className="text-bold">[{element.retencion.nombre}]</p>
                                                }
                                                {element.chequeNro&&
                                                    <p className="text-bold">[{element.chequeNro}]</p>
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "cuenta",
                                        render: (element)=>{
                                            return(<>
                                                {
                                                    element.cuenta &&
                                                        element.cuenta?.nombre+" - ["+element.cuenta?.moneda?.simbolo+"]"
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.detalleCbtesAsociados}</p>
                                                <p>{element.detalle?"("+element.detalle+")":""}</p>
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        disableSort: true,
                                        render: (element)=>{
                                            return(element.moneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "importe",
                                        type: "money",
                                        render: (element)=>{
                                            const importe = element.importe*element.impacto;
                                            return(
                                                <CurrencyFormat value={importe}/>
                                            )
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MovimientoEdit