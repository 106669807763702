import React, { useState } from 'react'
import ProvinciaList from './list'
import ProvinciaCreate from './create'
import ProvinciaEdit from './edit'

export default function ProvinciaCrud(props) {
    const { idPais, refetch } = props;
    const [crudMode, setCrudModeState] = useState("list");
    const [editId, setEditId] = useState();

    const setCrudMode = (crudModeParam) => {
        setCrudModeState(crudModeParam);
        if(crudModeParam==='list'){
            refetch();
        }
    }

    return (
        <>
        {
            crudMode==='list'?
                <ProvinciaList idPais={idPais} setCrudMode={setCrudMode} setEditId={setEditId}></ProvinciaList>
            :
            null
        }
        {
            crudMode==='create'?
                <ProvinciaCreate setCrudMode={setCrudMode} setEditId={setEditId}></ProvinciaCreate>
            :
            null
        }
        {
            crudMode==='edit'?
                <ProvinciaEdit setCrudMode={setCrudMode} editId={editId}></ProvinciaEdit>
            :
            null
        }
        </>
    )
}
