import React, { useState, useEffect, useRef } from 'react'
import ConfigData from "../../../config.json"
import { Link, useHistory } from 'react-router-dom';
import logoImg from '../../../assets/images/logo.png'
import { useMutation, gql } from '@apollo/client';
import $ from 'jquery'
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import SecurityPageWrapper from './SecurityPageWrapper';
import useAlertMessage from '../../../hooks/useAlertMessage';

const FORGOT_PASSWORD_MUTATION = gql`
    mutation ForgotPasswordMutation(
        $email: String!
    ) {
        forgotPassword(input:{ email: $email }){
            infoCode
            infoMessage
        }
    }
`;

export default function ForgotPasword() {
    const [email, setEmail] = useState("");
    const [t] = useTranslation("global");
    const ref = useRef();
    const history = useHistory();
    let { showSuccess } = useAlertMessage();

    useEffect(() => {
        $(ref.current).validate({
            rules: {
              email: {
                required: true,
                email: true,
              },
            },
            messages: {
              email: {
                required: t("common.message.field.required", {0: t("login.label.placeholders.email")}),
                email: t("common.message.invalid.email.format")
              },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
              error.addClass('invalid-feedback');
              element.closest('.input-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
              $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass('is-invalid');
            }
        });   
    });
    t("login.message.password.with.")
    const [
        forgotPasswordMutation
    ] = useMutation(FORGOT_PASSWORD_MUTATION, {
        onCompleted: (data) => {
            showSuccess(data?.forgotPassword?.infoMessage, () => {
                history.push("/")
            });
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        forgotPasswordMutation({variables: { email: email } });
    }

    return (
        <SecurityPageWrapper>
            <div className="login-box">
                <div className="card">
                    <div className="card-header text-align-center mt-3">
                        <img src={logoImg} alt={ConfigData.APP_TITLE_NAME}/>
                    </div>
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">{t("login.label.forgotpassword.instructions")}</p>
                        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                            <div className="input-group mb-3">
                                <input autoComplete="off" type="email"
                                    name="email"
                                    className="form-control"
                                    placeholder={t("login.label.placeholders.email")}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-info btn-block">{t("login.label.resetear")}</button>
                                    <Link className="btn btn-info btn-block" to="/">{t("common.button.cancelar")}</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer text-align-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-align-center">powered by <a className="text-info" href="http://www.parrish.com.ar/">Parrish Software Factory</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SecurityPageWrapper>
    )
}