import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import $ from "jquery";
import { } from "jquery-validation";
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import DatePicker from './../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import SelectEntity from '../../../../components/SelectEntity';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import MONEDA from '../../../../enums/Moneda';
import useCotizacionHelper from '../../../../helpers/useCotizacionHelper';
import { DateRfc }  from '../../DateRfc';

const MOVIMIENTO_INIT_RETENCION_BY_ID = gql`
    query initMovimientoRetencionForMovimiento(
        $id: Int!
    ) {
    initMovimientoRetencionForMovimiento(
        id: $id
    ){
        chequeBanco{
            id
        }
        cliente {
            id
            contacto{
                alias
            }
        }
        cuenta{
            id
        }
        estado{
            id
            nombre
        }
        formaPago{
            id
            nombre
        }
        moneda{
            id
            simbolo
        }
        referencia{
            id
        }
        retencion{
            id
            nombre
        }
        chequeFecha
        fechaAcreditacion
        fechaCancelacion
        canceladoPor{
            id
            contacto{
                alias
            }
        }
        fechaCobranza
        cobradoPor{
            id
            contacto{
                alias
            }
        }
        fechaConciliacion
        conciliadoPor{
            id
            contacto{
                alias
            }
        }
        fechaRegistro
        registradoPor{
            id
            contacto{
                alias
            }
        }
        cotizacionDolar
        cotizacionPeso
        impacto
        adjunto
        chequeNro
        chequeTitular
        detalle
    }
}
`;

const CREATE_MOVIMIENTO_MUTATION = gql`
    mutation createMovimiento(
        $cliente: Int!
        $fechaAcreditacion: LocalDate!
        $detalle: String
        $cotizacionDolar: Float!
        $cotizacionPeso: Float!
        $moneda: Int!
        $importe: Float!
        $formaPago: Int!
        $cuenta: Int
        $movReferencia: Int
        $retencion: Int
    ) {
        createMovimiento(entity:{
            cliente: $cliente
            fechaAcreditacion: $fechaAcreditacion
            detalle: $detalle
            cotizacionDolar: $cotizacionDolar
            cotizacionPeso: $cotizacionPeso
            moneda: $moneda
            importe: $importe
            formaPago: $formaPago
            cuenta: $cuenta
            movReferencia: $movReferencia
            retencion: $retencion
        }) {
            id
        }
    }
`;

function CreateRetencion() {
    const history = useHistory();
    let { id } = useParams();
    const ref = useRef();
    let { showErrorToast } = useAlertMessage();

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const { loading, error, data } = useQuery(
        MOVIMIENTO_INIT_RETENCION_BY_ID,
        {
            variables: {
                id: parseInt(id)
            }
        }
    );

    const [fechaAcreditacion, setFechaAcreditacion] = useState();
    const [detalle, setDetalle] = useState();
    const [cotizacionPeso, setCotizacionPeso] = useState();
    const [cotizacionDolar, setCotizacionDolar] = useState();
    const [importe, setImporte] = useState();
    const [retencion, setRetencion] = useState();

    const [fechaRegistro, setFechaRegistro] = useState();
    const [fechaCancelacion, setFechaCancelacion] = useState();
    const [fechaCobranza, setFechaCobranza] = useState();
    const [fechaConciliacion, setFechaConciliacion] = useState();

    const cotizacionHelper = useCotizacionHelper({
        setCotizacionPeso: (cotizacion)=>{
            setCotizacionPeso(cotizacion);
        },
        setCotizacionDolar: (cotizacion)=>{
            setCotizacionDolar(cotizacion);
        }
    });

    const [createMovimientoMutation] = useMutation(CREATE_MOVIMIENTO_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/cobranza/edit/"+data.createMovimiento.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createMovimientoMutation({
            variables:{
                cliente: parseInt(data?.initMovimientoRetencionForMovimiento?.cliente?.id),
                fechaAcreditacion: fechaAcreditacion?fechaAcreditacion:null,
                detalle: detalle?detalle:null,
                cotizacionDolar: cotizacionDolar?cotizacionDolar:null,
                cotizacionPeso: cotizacionPeso?cotizacionPeso:null,
                moneda: parseInt(data?.initMovimientoRetencionForMovimiento?.moneda?.id),
                importe: importe?importe:null,
                formaPago: 3,//RETENCION
                cuenta: parseInt(data?.initMovimientoRetencionForMovimiento?.cuenta?.id),
                retencion: retencion,
                movReferencia: parseInt(data?.initMovimientoRetencionForMovimiento?.referencia?.id)
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-fechaAcreditacion": "required",
                "value-cotizacionPeso": "required",
                "value-cotizacionDolar": "required",
                "value-importe": "required",
                "value-retencion": "required"
            },
            messages: {
                "value-fechaAcreditacion": {
                    required: t("common.message.field.required", {0: t("movimiento.field.fechaAcreditacion")})
                },
                "value-cotizacionPeso": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cotizacion")})
                },
                "value-cotizacionDolar": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cotizacion")})
                },
                "value-importe": {
                    required: t("common.message.field.required", {0: t("movimiento.field.importe")})
                },
                "value-retencion": {
                    required: t("common.message.field.required", {0: t("movimiento.field.retencion")})
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    }, [showErrorToast, t])

    useEffect(() => {
        if (!loading && !error && data?.initMovimientoRetencionForMovimiento) {
            const movimiento = data?.initMovimientoRetencionForMovimiento;
            setDetalle(movimiento.detalle ? movimiento.detalle : null);
            if(movimiento.fechaRegistro){
                setFechaRegistro(new DateRfc(movimiento.fechaRegistro));
            }
            if(movimiento.fechaAcreditacion){
                const originalFechaAcreditacion  = new DateRfc(movimiento.fechaAcreditacion);
                setFechaAcreditacion(originalFechaAcreditacion);
                cotizacionHelper.execute(originalFechaAcreditacion, data?.initMovimientoRetencionForMovimiento?.moneda?.id);
            }
            if(movimiento.fechaCancelacion){
                setFechaCancelacion(new DateRfc(movimiento.fechaCancelacion));
            }
            if(movimiento.fechaCobranza){
                setFechaCobranza(new DateRfc(movimiento.fechaCobranza));
            }
            if(movimiento.fechaConciliacion){
                setFechaConciliacion(new DateRfc(movimiento.fechaConciliacion));
            }
        }
        return () => { _isMounted.current = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data?.initMovimientoRetencionForMovimiento])

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.creacion")}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to="/cobranza">
                            <i className="fas fa-times"></i>
                        </PrivateLink>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaRegistro}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.registradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaCobranza" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCobranza")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaCobranza}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.cobradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaConciliacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaConciliacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaConciliacion}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.conciliadoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaCancelacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCancelacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaCancelacion}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.canceladoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="filter-cliente" className="parrish-label col-sm-2 col-xs-12">{t("movimiento.field.cliente")}</label>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.cliente?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("movimiento.label.datosCobranza")}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaAcreditacion" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.fechaAcreditacion")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker
                                    name="value-fechaAcreditacion"
                                    maxDate={new DateRfc()}
                                    selected={fechaAcreditacion}
                                    onChange={date => {
                                        setFechaAcreditacion(date);
                                        cotizacionHelper.execute(date, data?.initMovimientoRetencionForMovimiento?.moneda?.id);
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-movimientoReferenciado" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.movimientoReferenciado")}</label>
                                <div className="col-sm-2 col-xs-12 d-flex">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.referencia?.id}/>
                                    <button disabled={!data?.initMovimientoRetencionForMovimiento?.referencia} className="btn btn-xs bg-info ml-1 mr-1 pl-2 pr-2" onClick={()=>{history.push("/private/movimiento/edit/"+data?.initMovimientoRetencionForMovimiento?.referencia?.id)}}>
                                        <i className="far fa-eye"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="filter-detalle" className="parrish-label col-sm-2 col-xs-12">{t("movimiento.field.detalle")}</label>
                                <div className="col-sm-10 col-xs-12">
                                    <textarea name="value-detalle" value={detalle} onChange={(e)=>{setDetalle(e.target.value)}} className="form-control form-control-sm" rows="3"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("movimiento.field.cotizacion", { 1: "(U$D)", 0: data?.initMovimientoRetencionForMovimiento?.moneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        disabled={parseInt(data?.initMovimientoRetencionForMovimiento?.moneda?.id)===MONEDA.DOLARES}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionDolar(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("movimiento.field.cotizacion", { 1: "(AR$)", 0: data?.initMovimientoRetencionForMovimiento?.moneda?.label })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionPeso"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionPeso}
                                        className={"parrish-input text-right"}
                                        disabled={parseInt(data?.initMovimientoRetencionForMovimiento?.moneda?.id)===MONEDA.PESOS}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionPeso(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-moneda" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.moneda?.simbolo}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-importe" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.importe")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <NumberFormat
                                        name="value-importe"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale="true"
                                        value={importe}
                                        className={"parrish-input text-right"}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setImporte(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-formaPago" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.formaPago")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.initMovimientoRetencionForMovimiento?.formaPago?.nombre}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-retencion" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.retencion")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <SelectEntity
                                        entity="Retencion"
                                        label="nombre"
                                        name={'value-retencion'}
                                        value={retencion}
                                        required
                                        onChange={(e) => {
                                            setRetencion(e.target.value);
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CreateRetencion