import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CardDatatable from '../../../../components/CardDatatable';
import FlagsPostulante from '../../../../components/FlagsPostulante';
import moment from 'moment';
import Moment from 'react-moment';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import ContactoAvatarImg from '../../../../components/ContactoAvatarImg';
import AsociarProyectoDialog from './dialogs/asociarProyecto';
import EditarContactoDialog from '../proyecto/dialogs/editarContactoDialog';
import PostulanteFilter from './filter';
import SeguimientoButton from '../../../../components/SeguimientoButton';
import MenuIcon from '../../../../components/MenuIcon';
import SidebarContext from '../../../../context/SidebarContext';

const POSTULANTE_SEARCH = gql`
    query findPostulantePageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $emailFalso: Boolean
        $edadDesde: Int
        $edadHasta: Int
        $nacionalidad: Int
        $paisResidencia: Int
        $provinciaList: [Int!]
        $genero: String
        $fechaExperienciaDesde: LocalDate
        $fechaExperienciaHasta: LocalDate
        $posicion: String
        $empresaList: [Int!]
        $industriaList: [Int!]
        $areaEmpresaList: [Int!]
        $cargoList: [Int!]
        $fechaFormacionDesde: LocalDate
        $fechaFormacionHasta: LocalDate
        $conocimientoList: [ConocimientoTupleInput!]
        $titulo: String
        $institucionList: [Int!]
        $nivelEstudioList: [Int!]
        $areaEstudioList: [Int!]
        $proyectoList: [Int!]
        $responsable: Int
        $destacado: Boolean
        $incubadora: Boolean
        $incubadoraProspecto: Boolean
        $opportunity: Boolean
        $placement: Boolean
        $verificar: Boolean
        $experienciaPais: Int
        $formacionPais: Int
        $tags: String
        $keywords: String
    ) {
    findPostulantePageByFilter(
        filter: {
            emailFalso: $emailFalso
            edadDesde: $edadDesde
            edadHasta: $edadHasta
            nacionalidad: $nacionalidad
            paisResidencia: $paisResidencia
            provinciaList: $provinciaList
            genero: $genero
            fechaExperienciaDesde: $fechaExperienciaDesde
            fechaExperienciaHasta: $fechaExperienciaHasta
            posicion: $posicion
            empresaList: $empresaList
            industriaList: $industriaList
            areaEmpresaList: $areaEmpresaList
            cargoList: $cargoList
            fechaFormacionDesde: $fechaFormacionDesde
            fechaFormacionHasta: $fechaFormacionHasta
            conocimientoList: $conocimientoList
            titulo: $titulo
            institucionList: $institucionList
            nivelEstudioList: $nivelEstudioList
            areaEstudioList: $areaEstudioList
            proyectoList: $proyectoList
            responsable: $responsable
            destacado: $destacado
            incubadora: $incubadora
            incubadoraProspecto: $incubadoraProspecto
            opportunity: $opportunity
            placement: $placement
            verificar: $verificar
            experienciaPais: $experienciaPais
            formacionPais: $formacionPais
            tags: $tags
            keywords: $keywords
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                fechaActualizacion
                destacado
                fechaNacimientoEstimada
                incubadora
                incubadoraProspecto
                opportunity
                placement
                verificar
                offLimits
                appraisal
                emailFalso
                dni
                genero
                hijos
                qaInfo {
                    indice
                    recomendaciones
                }
                contacto{
                    id
                    alias
                    personaFisica
                    email
                    telefonosFijos
                    telefonosMoviles
                    fechaNacimiento
                    linkedin
                    foto
                }
                estadoCivil{
                    nombre
                }
                responsable{
                    contacto{
                        alias
                    }
                }
                adjuntoList{
                    referencia
                    fechaActualizacion
                    contentType
                    nombreOriginal
                    nombreServidor
                    responsable{
                        contacto{
                            alias
                        }
                    }
                }
                ultimoTrabajo{
                    id
                    ultimoTrabajo
                    fechaInicio
                    fechaFin
                    empresa{
                        contacto{
                            alias
                        }
                    }
                    posicion
                }
                ultimoSeguimiento{
                    id
                    fecha
                    importante
                    comentario
                    accion{
                        id
                        nombre
                        icon
                        color
                    }
                    empresa{
                        contacto{
                            alias
                        }
                    }
                    proyecto{
                        id
                        alias
                    }
                    responsable{
                        id
                        contacto{
                            alias
                        }
                    }
                }
                proyectosActivos{
                    id
                    label
                }
                tags
            }
        }
    }
}
`;

function PostulanteList(props){
    const { setSideBarState, show } = useContext(SidebarContext);
    const [t] = useTranslation("global");
    const {searchFilter, setSearchFilter} = props;
    const [showProyectoDialog, setShowProyectoDialog] = useState(false);
    const [idPostulante, setIdPostulante] = useState();
    const [aliasPostulante, setAliasPostulante] = useState();
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "contacto.alias",
        sortDirection: "ASC"
    });

    //const { setSideBarState, show } = useContext(SidebarContext);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data, refetch} = useQuery(
        POSTULANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                //Datos de Contacto:
                emailFalso: searchFilter.emailFalso ? searchFilter.emailFalso : null,
                edadDesde: searchFilter.edadDesde ? searchFilter.edadDesde : null,
                edadHasta: searchFilter.edadHasta ? searchFilter.edadHasta : null,
                nacionalidad: searchFilter.nacionalidad ? searchFilter.nacionalidad : null,
                paisResidencia: searchFilter.paisResidencia ? searchFilter.paisResidencia : null,
                provinciaList: searchFilter.provinciaList ? searchFilter.provinciaList.map(element=>element.value) : [],
                genero: searchFilter.genero ? searchFilter.genero : null,
                fechaExperienciaDesde: searchFilter.fechaExperienciaDesde ? searchFilter.fechaExperienciaDesde : null,
                fechaExperienciaHasta: searchFilter.fechaExperienciaHasta ? searchFilter.fechaExperienciaHasta : null,
                posicion: searchFilter.posicion ? searchFilter.posicion : null,
                empresaList: searchFilter.empresaList ? searchFilter.empresaList.map(element=>element.value) : [],
                industriaList: searchFilter.industriaList ? searchFilter.industriaList.map(element=>element.value) : [],
                areaEmpresaList: searchFilter.areaEmpresaList ? searchFilter.areaEmpresaList.map(element=>element.value) : [],
                cargoList: searchFilter.cargoList ? searchFilter.cargoList.map(element=>element.value) : [],
                fechaFormacionDesde: searchFilter.fechaFormacionDesde ? searchFilter.fechaFormacionDesde : null,
                fechaFormacionHasta: searchFilter.fechaFormacionHasta ? searchFilter.fechaFormacionHasta : null,
                conocimientoList: searchFilter.conocimientoList ? searchFilter.conocimientoList : [],
                titulo: searchFilter.titulo ? searchFilter.titulo : null,
                institucionList: searchFilter.institucionList ? searchFilter.institucionList.map(element=>element.value) : [],
                nivelEstudioList: searchFilter.nivelEstudioList ? searchFilter.nivelEstudioList.map(element=>element.value) : [],
                areaEstudioList: searchFilter.areaEstudioList ? searchFilter.areaEstudioList.map(element=>element.value) : [],
                proyectoList: searchFilter.proyectoList ? searchFilter.proyectoList.map(element=>element.value) : [],
                responsable: searchFilter.responsable ? searchFilter.responsable : null,
                destacado: searchFilter.destacado ? searchFilter.destacado : null,
                incubadora: searchFilter.incubadora ? searchFilter.incubadora : null,
                incubadoraProspecto: searchFilter.incubadoraProspecto ? searchFilter.incubadoraProspecto : null,
                opportunity: searchFilter.opportunity ? searchFilter.opportunity : null,
                placement: searchFilter.placement ? searchFilter.placement : null,
                experienciaPais: searchFilter.experienciaPais ? searchFilter.experienciaPais : null,
                formacionPais: searchFilter.formacionPais ? searchFilter.formacionPais : null,
                tags: searchFilter.tags ? searchFilter.tags : null,
                keywords: searchFilter.keywords ? searchFilter.keywords : null,
                verificar: searchFilter.verificar ? searchFilter.verificar : null,
            }
        }
    );

    return (
        <div id="postulante-list">
            <PostulanteFilter searchFilterOriginal={searchFilter} setSearchFilterOriginal={setSearchFilter} refetch={refetch} displayFilterStore={true}/>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findPostulantePageByFilter"
                entityName="postulante"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/postulante/edit/"
                createUrl="/postulante/create/"
                columnsDefinition={[
                    {
                        name: "postulante",
                        customSortKey: "contacto.alias",
                        translationKey: "postulante.label.entity",
                        className: "col-2",
                        render: (element) =>{
                            const postulante = element.node;
                            return(
                                <>
                                    <div className="row">
                                        <div className="col d-flex justify-content-center">
                                            <div className="text-center">
                                                <FlagsPostulante postulante={postulante} />
                                            </div>
                                            <div className="text-center">
                                                <ContactoAvatarImg id={postulante?.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                                {postulante.contacto?.fechaNacimiento&&
                                                    <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                                }
                                                <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                                    <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                                <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                            </PrivateLink>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    },
                    {
                        name: "appraisalAndUltimoSeguimiento",
                        disableSort: true,
                        render: (element) =>{
                            const seguimiento = element.node.ultimoSeguimiento;
                            return(
                                <>
                                {element.node.appraisal&&
                                    <div className="card card-outline seguimiento-card bg-light-gray">
                                        <div className="card-body p-2">
                                            <b>{t("postulante.field.appraisal")}: </b>{element.node.appraisal}
                                        </div>
                                    </div>
                                }
                                {element.node.tags&&
                                    <div className="card card-outline seguimiento-card bg-light-gray">
                                        <div className="card-body p-2 d-flex justify-content-start align-items-center">
                                            <span className="m-1 p-1"><b>{t("postulante.field.tags")}: </b></span>
                                            {element.node.tags?.split(";").map(element=>{
                                                return(<h5 className="m-0"><span className="badge badge-secondary m-1 p-1 font-weight-normal">{element}</span></h5>);
                                            })}
                                        </div>
                                    </div>
                                }
                                {element.node.ultimoSeguimiento&&
                                <>
                                    <div className="card card-outline seguimiento-card bg-light-gray">
                                        <div className="card-header p-1">
                                            <div className="d-flex align-items-center">
                                                <div className="mr-2 rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: seguimiento.accion.color}}>
                                                    <h6 className="align-self-center m-0">
                                                        <i className={seguimiento.accion.icon+" text-white"} />
                                                    </h6>
                                                </div>
                                                <b className={"mr-2 text-dark"}>{seguimiento.accion.nombre}</b>
                                                {seguimiento.importante&&
                                                    <>

                                                    <span className="font-weight-bold text-danger mr-2 small float-right align-self-center">(<i className="fas fa-exclamation-triangle text-danger"/> {t("common.label.importante")})</span>
                                                    </>
                                                }
                                                <span className="mr-auto"></span>
                                                <span className="mr-2 text-muted small float-right align-self-center">{seguimiento.responsable.contacto.alias}</span>
                                                <span className="rounded p-1 bg-info"><Moment format={"DD MMM yyyy"}>{seguimiento.fecha}</Moment></span>
                                            </div>
                                        </div>
                                        <div className="card-body p-2">
                                            {seguimiento.comentario}
                                        </div>
                                        <div className="card-footer p-1 pr-2 pl-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    {seguimiento.proyecto&&
                                                        <span className="badge badge-secondary align-self-center mr-2">
                                                            <PrivateLink className="text-light" to={"/proyecto/edit/"+seguimiento.proyecto?.id}>
                                                                <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/>{seguimiento.proyecto?.alias}
                                                            </PrivateLink>
                                                        </span>
                                                    }
                                                    {seguimiento.empresa &&
                                                        <span className="badge badge-secondary align-self-center">
                                                            <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{seguimiento.empresa?.contacto?.alias}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                </>
                            );
                        }
                    },
                    {
                        name: "ultimoTrabajoRegistrado",
                        disableSort: true,
                        render: (element) =>{
                            const meses = element.node.ultimoTrabajo?moment().diff(element.node.ultimoTrabajo.fechaFin?element.node.ultimoTrabajo.fechaFin:element.node.ultimoTrabajo.fechaInicio, 'months',false):0;
                            const classNamePeriod = (meses>25?"text-danger":(meses>13?"text-orange":"text-success"));
                            return(element.node.ultimoTrabajo&&
                                <>
                                    <p className={"font-weight-bold "+(!element.node.ultimoTrabajo?.fechaFin?"text-success":"text-danger")}>{element.node.ultimoTrabajo?.empresa.contacto.alias}</p>
                                    <p>{element.node.ultimoTrabajo?.posicion}</p>
                                    <p className={"text-nowrap "+classNamePeriod}>
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.ultimoTrabajo?.fechaInicio}</Moment></span>
                                        {
                                            element.node.ultimoTrabajo?.fechaFin?
                                                <> - <Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.ultimoTrabajo?.fechaFin}</Moment></>
                                            :
                                                <span> - <span>{t("Actualidad")}</span></span>
                                        }
                                    </p>
                                    <p className={classNamePeriod}>({t("common.label.hace")} {meses} {t("postulante.field.meses")})</p>
                                </>
                            );
                        },
                    },
                    {
                        name: "asociarProyecto",
                        disableSort: true,
                        customHeader: <></>,
                        customClassHeader: "bg-info text-center pl-0 pr-0",
                        className: "text-center",
                        render: (element)=>{
                            return(
                                <>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            type="button"
                                            title={t("postulante.field.asociarProyecto")}
                                            className={"btn btn-xs bg-info m-1"}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setAliasPostulante(element.node.contacto?.alias);
                                                setIdPostulante(element.node.id);
                                                setShowProyectoDialog(true);
                                            }}>
                                            <i className="fas fa-search-plus"></i>
                                        </button>
                                        <EditarContactoDialog contactoId={element.node.contacto?.id} refetch={refetch} />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                    <button
                                            type="button"
                                            title={t("postulante.label.referencias")}
                                            className="btn btn-xs bg-yellow m-1" onClick={()=>{
                                                setSideBarState({
                                                    mode: "referencias",
                                                    labelProyecto: null,
                                                    proyecto: null,
                                                    labelPostulante: element.node.contacto.alias,
                                                    postulante: element.node.id,
                                                    labelEmpresa: null,
                                                    empresa: null
                                                })
                                                show();
                                            }}>
                                                <i className="fas fa-user-secret"></i>
                                        </button>
                                        <SeguimientoButton idPostulante={parseInt(element.node.id)} labelPostulante={element.node.contacto.alias} />
                                    </div>
                                </>
                            )
                        }
                    }
                ]}>
            </CardDatatable>
            <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={idPostulante} aliasPostulante={aliasPostulante} />
        </div>
    );
}

export default PostulanteList;