import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import PostulanteList from './list'
import PostulanteEdit from './edit'
import PostulanteCreate from './create'
import { useHistory, useParams } from 'react-router';

function PostulanteIndex(){
    const entityName = "postulante";
    const [searchFilter, setSearchFilter] = useState({
        //Datos de Contacto:
        alias: "",	
        emailFalso: "",
        edadDesde: "",
        edadHasta: "",
        localidad: "",
        pais: "",
        provinciaList: [],
        genero: "",
        //Experiencia Laboral: 
        fechaExperienciaDesde: null,
        fechaExperienciaHasta: null,
        posicion: "",
        empresaList: [],
        industriaList: [],
        areaEmpresaList: [],
        cargoList: [],
        //Formacion Academica: 
        fechaFormacionDesde: null,
        fechaFormacionHasta: null,
        conocimientoList: [],
        titulo: "",
        institucionList: [],
        nivelEstudioList: [],
        areaEstudioList: [],
        //Informacion de Talenters: 
        proyectoList: [],
        responsable: "",
        destacado: "",
        incubadora: "",
        incubadoraProspecto: "",
        opportunity: "",
        placement: "",
        verificar: "",
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <PostulanteList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></PostulanteList>
                :
                null
            }
            {
                mode==='edit'?
                    <PostulanteEdit></PostulanteEdit>
                :
                null
            }
            {
                mode==='create'?
                    <PostulanteCreate></PostulanteCreate>
                :
                null
            }
        </div>
    );
}

export default PostulanteIndex;