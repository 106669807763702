import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AccionesSeguimiendoSelect from '../../../../../components/AccionesSeguimiendoSelect';
import ETAPA_PROYECTO from '../../../../../enums/EtapaProyecto';
import PRIORIDAD from '../../../../../enums/Prioridad';
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import $ from 'jquery'
import Autocomplete from '../../../../../components/Autocomplete';
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean';
import SelectKeyValue from '../../../../../components/SelectKeyValue';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';


const ASOCIAR_POSTULANTE_PROYECTO_MUTATION = gql`
    mutation asociarPostulanteAProyecto(
        $etapa: Int!
        $estado: Int
        $postulante: Int!
        $prioridad: Int!
        $proyecto: Int!
        $responsableList: [Int!]!
        $accion: Int
        $comentario: String
    ) {
        asociarPostulanteAProyecto(
            entity: {
                etapa: $etapa
                estado: $estado
                postulante: $postulante
                prioridad: $prioridad
                proyecto: $proyecto
                responsableList: $responsableList
                accion: $accion
                comentario: $comentario
            }
        ) {
            id
        }
    }
`;

export default function AsociarProyectoDialog(props) {
    const ref = useRef();
    const {show, setShow, idPostulante, aliasPostulante} = props;
    let { showErrorToast, clearPopUps } = useAlertMessage();

    const [t] = useTranslation("global");

    const [postulante, setPostulante] = useState();
    const [proyecto, setProyecto] = useState();
    const [responsableList, setResponsableList] = useState([]);
    const [etapa, setEtapa] = useState(ETAPA_PROYECTO.IDENTIFICADO);
    const [prioridad, setPrioridad] = useState(PRIORIDAD.QUICK_WIN);
    const [estado, setEstado] = useState(1);
    const [accionSeguimiento, setAccionSeguimiento] = useState();
    const [comentarioSeguimiento, setComentarioSeguimiento] = useState()

    const [asociarPostulanteProyectoMutation] = useMutation(ASOCIAR_POSTULANTE_PROYECTO_MUTATION,{
        onCompleted: (data) => {
            setShow(false);
        },
        onError: (error) => {
            clearPopUps();
            let errorList = [];
            errorList.push({message: t(error)});
            if(errorList.length>0){
                showErrorToast(errorList);
            }
        }
    });

    const asociarPostulanteProyecto = (newAvatar) => {
        asociarPostulanteProyectoMutation({
            variables: {
                postulante: idPostulante?parseInt(idPostulante):(postulante?parseInt(postulante):null),
                proyecto: parseInt(proyecto),
                responsableList: responsableList?responsableList:null,
                etapa: etapa,
                estado: estado?parseInt(estado):null,
                prioridad: prioridad,
                accion: accionSeguimiento?parseInt(accionSeguimiento):null,
                comentario: comentarioSeguimiento?comentarioSeguimiento:null
            }
        })
    }

    const clearData = (newAvatar) => {
        setProyecto("");
        setResponsableList([]);
        setEtapa(ETAPA_PROYECTO.IDENTIFICADO);
        setEstado(1);
        setPrioridad(PRIORIDAD.QUICK_WIN);
        setAccionSeguimiento("");
        setComentarioSeguimiento("");
    }

    useEffect(() => {
        clearData();
    }, [show])

    const handleSubmit = () => {
        if(validate()){
            asociarPostulanteProyecto();
        }
    }

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!proyecto){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.proyecto")})});
        }
        if(!responsableList || !responsableList.length>0){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.responsable")})});
        }
        if(!postulante && !idPostulante){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.label.entity")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
    }, [show]);

    return (
        <div ref={ref} className="modal" role="dialog">
            { show &&
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("empresa.label.asociarProyectos")}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body parrish-form">
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-postulante" className="parrish-label col-2 text-right required">{t("postulante.label.entity")}</label>
                                        <div className="col-10">
                                            {idPostulante?
                                                <input autoComplete="off" className="parrish-input" value={aliasPostulante} disabled={true} />
                                            :
                                                <Autocomplete
                                                    name="value-postulante"
                                                    entity="Postulante"
                                                    by="Alias"
                                                    filterName="alias"
                                                    value={postulante}
                                                    setValue={(val)=>{setPostulante(val);}} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-proyecto" className="parrish-label col-2 text-right required">{t("postulante.field.proyecto")}</label>
                                        <div className="col-10">
                                            <Autocomplete
                                                name="value-proyecto"
                                                entity="ProyectoActivo"
                                                by="Alias"
                                                filterName="alias"
                                                value={proyecto}
                                                setValue={(val)=>{setProyecto(val);}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-responsable-list" className="parrish-label col-2 text-right required">{t("postulante.field.responsables")}</label>
                                        <div className="col-10">
                                            <MultipleAutocomplete
                                                name="value-responsable-list"
                                                entity="UsuarioActivo"
                                                by="Alias"
                                                filterName="alias"
                                                searchInFocus={true}
                                                setValue={(value)=>{
                                                    setResponsableList(value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-etapa" className="parrish-label col-2 text-right required">{t("postulante.field.etapa")}</label>
                                        <div className="col-8 d-flex">
                                            <div className="custom-control custom-radio mr-2">
                                                <input autoComplete="off" id="etapa-source" name="value-etapa"
                                                    onChange={(e)=>{setEstado(1);setEtapa(e.target.value)}}
                                                    checked={parseInt(etapa) === ETAPA_PROYECTO.SOURCE}
                                                    value={ETAPA_PROYECTO.SOURCE}
                                                    className="custom-control-input custom-control-input-info"
                                                    type="radio"/>
                                                <label htmlFor="etapa-source" className="custom-control-label">{t("postulante.field.etapaSource")}</label>
                                            </div>
                                            <div className="custom-control custom-radio mr-2">
                                                <input autoComplete="off" id="etapa-identificado" name="value-etapa"
                                                    onChange={(e)=>{setEstado(1);setEtapa(e.target.value);}}
                                                    checked={parseInt(etapa) === ETAPA_PROYECTO.IDENTIFICADO}
                                                    value={ETAPA_PROYECTO.IDENTIFICADO}
                                                    className="custom-control-input custom-control-input-info"
                                                    type="radio" />
                                                <label htmlFor="etapa-identificado" className="custom-control-label">{t("postulante.field.etapaIdentificado")}</label>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-prioridad" className="parrish-label col-2 text-right required">{t("postulante.field.prioridad")}</label>
                                        <div className="col-8 d-flex">
                                            <div className="custom-control custom-radio d-flex mr-2">
                                                <input autoComplete="off" id="etapa-quick-win" name="value-prioridad"
                                                    onChange={(e)=>{setPrioridad(e.target.value)}}
                                                    checked={parseInt(prioridad) === PRIORIDAD.QUICK_WIN}
                                                    className="custom-control-input custom-control-input-info"
                                                    value={PRIORIDAD.QUICK_WIN}
                                                    type="radio"/>
                                                <label htmlFor="etapa-quick-win" className="custom-control-label d-flex">
                                                    <FlagButtonBoolean
                                                        icon="fas fa-running"
                                                        onClass={"bg-maroon"}
                                                        value={true}
                                                    />
                                                    <span className="ml-1">{t("postulante.field.prioridadQuickWin")}</span>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mr-2">
                                                <input autoComplete="off" id="etapa-interesting-match" name="value-prioridad"
                                                    onChange={(e)=>{setPrioridad(e.target.value)}}
                                                    checked={parseInt(prioridad) === PRIORIDAD.INTERESTING_MATCH}
                                                    className="custom-control-input custom-control-input-info"
                                                    value={PRIORIDAD.INTERESTING_MATCH}
                                                    type="radio"/>
                                                
                                                <label htmlFor="etapa-interesting-match" className="custom-control-label d-flex">
                                                    <FlagButtonBoolean
                                                        icon="fas fa-running"
                                                        onClass={"bg-indigo"}
                                                        value={true}
                                                    />
                                                    <span className="ml-1">{t("postulante.field.prioridadInterestingMatch")}</span>
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mr-2">
                                                <input autoComplete="off" id="etapa-stardard-match" name="value-prioridad"
                                                    onChange={(e)=>{setPrioridad(e.target.value)}}
                                                    checked={parseInt(prioridad) === PRIORIDAD.STANDARD_MATCH}
                                                    className="custom-control-input custom-control-input-info"
                                                    value={PRIORIDAD.STANDARD_MATCH}
                                                    type="radio"/>
                                                <label htmlFor="etapa-stardard-match" className="custom-control-label d-flex">
                                                    <FlagButtonBoolean
                                                        icon="fas fa-running"
                                                        onClass={"bg-info"}
                                                        value={true}
                                                    />
                                                    <span className="ml-1">{t("postulante.field.prioridadStardardMatch")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-estado" className="parrish-label col-2 text-right">{t("postulante.field.estado")}</label>
                                        <div className="col-10">
                                            <SelectKeyValue
                                                entity="EstadoPostulante"
                                                by="Etapa"
                                                name={'value-estado'}
                                                value={estado}
                                                filterValue={etapa}
                                                filterName="idEtapa"
                                                onChange={(e) => {
                                                    setEstado(e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm mt-4 p-2 text-center text-uppercase">
                                    <h4>{t("seguimiento.label.entity")}</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-accion" className="parrish-label col-2 text-right">{t("seguimiento.field.accion")}</label>
                                        <div className="col-10">
                                            <AccionesSeguimiendoSelect value={accionSeguimiento} setValue={setAccionSeguimiento}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-comentario" className="parrish-label col-2 text-right align-self-start">{t("seguimiento.field.comentario")}</label>
                                        <div className="col-10">
                                        <textarea
                                            name="value-comentario"
                                            value={comentarioSeguimiento}
                                            onChange={(e)=>{setComentarioSeguimiento(e.target.value)}}
                                            className="form-control form-control-sm"
                                            rows="4"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSubmit()}}>
                                {t("proyecto.button.asociar")}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
