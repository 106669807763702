import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import DatePicker from '../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import moment from 'moment';
import EstadoComprobante from '../estados/EstadoComprobante';
import EstadoMovimiento from '../estados/EstadoMovimiento';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import MONEDA from '../../../../enums/Moneda';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import { DateRfc }  from '../../DateRfc';

const COMPROBANTE_SEARCH_BY_ID = gql`
    query findComprobanteById(
        $id: Int!
    ) {
    findComprobanteById(
        id: $id
    ){
        id
        cbteFechaEmision
        cbteFechaVencimiento
        fechaAprobacion
        fechaConciliacion
        fechaEmision
        fechaRegistro
        cbteImporteFinal
        cbteImporteIva
        cbteImporteNeto
        cbteImpacto
        cotizacionDolar
        cotizacionPeso
        adjunto
        cbteDetalle
        cbteNro
        registradoPor{
            contacto{
                alias
            }
        }
        aprobadoPor{
            contacto{
                alias
            }
        }
        emitidoPor{
            contacto{
                alias
            }
        }
        proyecto{
            id
            ref
        }
        cbteTipo{
            letra
            nemotecnico
        }
        cbteMoneda{
            id
            simbolo
        }
        estado{
            nombre
            color
        }
        referencia{
            id
            cbteTipo{
                nemotecnico
            }
        }
        comprobantesRelacionadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaRegistro
            registradoPor {
                contacto {
                alias
                }
            }
            fechaAprobacion
            aprobadoPor {
                contacto {
                alias
                }
            }
            fechaEmision
            emitidoPor {
                contacto {
                alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            proyecto {
                id
                ref
            }
            cbteFechaEmision
            cbteFechaVencimiento
            cbteTipo {
                nemotecnico
            }
            cbteNro
            cbteDetalle
            cbteMoneda {
                id
                simbolo
            }
            cbteImporteFinal
            cbteImporteIva
            cbteImporteNeto
            cbteImpacto
            cotizacionPeso
        }
        movimientosAsociadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaAcreditacion
            fechaRegistro
            registradoPor {
                contacto {
                    alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                    alias
                }
            }
            fechaCancelacion
            canceladoPor {
                contacto {
                    alias
                }
            }
            fechaCobranza
            cobradoPor {
                contacto {
                    alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            moneda {
                id
                simbolo
            }
            formaPago{
                nombre
            }
            retencion{
                nombre
            }
            cuenta{
                nombre
                banco{
                    nombre
                }
                tipoCuenta{
                    nombre
                }
                moneda{
                    simbolo
                }
            }
            impacto
            importe
            cotizacionPeso
            cotizacionDolar
            detalle
            detalleCbtesAsociados
            chequeBanco{
                nombre
            }
            chequeFecha
            chequeNro
            chequeTitular
        }
    }
}
`;

function ComprobanteEdit() {
    let history = useHistory();
    let { id } = useParams();

    const [fechaRegistro, setFechaRegistro] = useState();
    const [fechaAprobacion, setFechaAprobacion] = useState();
    const [fechaEmision, setFechaEmision] = useState();

    const [cbteFechaEmision, setCbteFechaEmision] = useState();
    const [cbteFechaVencimiento, setCbteFechaVencimiento] = useState();

    const [proyectoLabel, setProyectoLabel] = useState();

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        COMPROBANTE_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const codigoMonedaTotales = data?.findComprobanteById?.cbteMoneda.simbolo+" ";
    
    useEffect(() => {
        if (!loading && !error && data?.findComprobanteById) {
            const comprobante = data?.findComprobanteById;
            //setDetalle(comprobante.detalle ? comprobante.detalle : null);
            if(comprobante.fechaRegistro){
                setFechaRegistro(new DateRfc(comprobante.fechaRegistro));
            }
            if(comprobante.fechaAprobacion){
                setFechaAprobacion(new DateRfc(comprobante.fechaAprobacion));
            }
            if(comprobante.fechaEmision){
                setFechaEmision(new DateRfc(comprobante.fechaEmision));
            }
            if(comprobante.cbteFechaEmision){
                setCbteFechaEmision(new DateRfc(comprobante.cbteFechaEmision));
            }
            if(comprobante.cbteFechaVencimiento){
                setCbteFechaVencimiento(new DateRfc(comprobante.cbteFechaVencimiento));
            }
            setProyectoLabel(data?.findComprobanteById?.proyecto?("["+data?.findComprobanteById?.proyecto?.id+"] - "+data?.findComprobanteById?.proyecto?.ref):"");
        }
        return () => { _isMounted.current = false };
    }, [
        loading,
        error,
        data?.findComprobanteById
    ])

    const monedaId = data?.findComprobanteById?.cbteMoneda?.id;
    
    let totalComprobantes = data?.findComprobanteById?.comprobantesRelacionadosList?.reduce((total, currVal) => {
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.cbteImporteFinal*cotizacionMoneda*currVal.cbteImpacto;
    }, 0);
    totalComprobantes += data?.findComprobanteById?.cbteImporteFinal*data?.findComprobanteById?.cbteImpacto;

    const totalMovimientos = data?.findComprobanteById?.movimientosAsociadosList?.reduce((total, currVal) => {
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.importe*cotizacionMoneda*currVal.impacto;
    }, 0);

    return (
        <>
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })} - <span className={"badge badge-"+data?.findComprobanteById?.estado?.color}>{data?.findComprobanteById?.estado?.nombre}</span></h3>
                    <div className="card-tools m-0">
                        <DownloadAdjunto filename={data?.findComprobanteById?.adjunto} entity="Comprobante" type="tool"/>
                        <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaRegistro}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.registradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaAprobacion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaAprobacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaAprobacion}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.aprobadoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-fechaEmision" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaEmision")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={fechaEmision}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.emitidoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="filter-cliente" className="parrish-label col-sm-2 col-xs-12">{t("comprobante.field.cliente")}</label>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={proyectoLabel}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("comprobante.label.datosComprobante")}</h4>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cbteFechaEmision" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaEmision")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker readOnly selected={cbteFechaEmision}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cbteFechaVencimiento" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaVencimiento")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker readOnly selected={cbteFechaVencimiento}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-comprobante" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.comprobante")}</label>
                                <div className="col-sm-6 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.cbteTipo?.nemotecnico}/>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.cbteNro}/>
                                </div>
                            </div>
                        </div>

                        { data?.findComprobanteById?.referencia &&
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="filter-comprobanteReferenciado" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.cbteReferencia")}</label>
                                    <div className="col-sm-3 col-xs-12 d-flex">
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.referencia?.cbteTipo.nemotecnico+" ["+data?.findComprobanteById?.referencia?.id+"]"}/>
                                        <button disabled={!data?.findComprobanteById?.referencia} className="btn btn-xs bg-info ml-1 mr-1 pl-2 pr-2" onClick={()=>{history.push("/private/comprobante/edit/"+data?.findComprobanteById?.referencia?.id)}}>
                                            <i className="far fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-xs-12 col-sm-8">
                            <div className="form-group row">
                                <label htmlFor="value-comentario-estimacion" className="parrish-label col-sm-3 col-xs-12">{t("comprobante.field.detalle")}</label>
                                <div className="col">
                                    <textarea name="value-comentario-estimacion" value={data?.findComprobanteById?.cbteDetalle} className="form-control form-control-sm" rows="3" disabled={true}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-moneda" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.cbteMoneda?.simbolo}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteImporteNeto" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.importeNeto")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale="true"
                                        value={data?.findComprobanteById?.cbteImporteNeto}
                                        className={"parrish-input text-right"}
                                        disabled
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12">
                                    {t("comprobante.field.cotizacion", { 1: "(U$D)", 0: data?.findComprobanteById?.cbteMoneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={data?.findComprobanteById?.cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        disabled
                                        />
                                </div>
        
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cotizacionArs" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.iva")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={data?.findComprobanteById?.cbteImporteIva}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>      
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12">
                                    {t("comprobante.field.cotizacion", { 1: "(AR$)", 0: data?.findComprobanteById?.cbteMoneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    name="value-cotizacionPeso"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={4}
                                    fixedDecimalScale="true"
                                    value={data?.findComprobanteById?.cotizacionPeso}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cotizacionArs" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.importeTotal")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={data?.findComprobanteById?.cbteImporteFinal}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase active"
                                id="custom-tabs-info-adicional-tab"
                                data-toggle="pill"
                                href="#custom-tabs-info-adicional"
                                role="tab"
                                aria-controls="custom-tabs-info-adicional"
                                aria-selected="true">
                                {t("comprobante.label.informacionAdicionalYResumenDelComprobante")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-info-adicional" role="tabpanel" aria-labelledby="custom-tabs-info-adicional-tab">
                            <div className="d-flex justify-content-center">
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalComprobantes")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                prefix={codigoMonedaTotales}
                                                value={totalComprobantes}/>
                                        </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalMovimientos")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                prefix={codigoMonedaTotales}
                                                value={totalMovimientos}/>
                                        </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalDiferencia")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                className={(totalComprobantes-totalMovimientos)===0?"text-success":"text-danger"}
                                                prefix={codigoMonedaTotales}
                                                value={totalMovimientos-totalComprobantes}/>
                                        </h2>
                                    </div>       
                                </div>
                            </div>
                            <SimpleDatatable
                                data={data?.findComprobanteById?.comprobantesRelacionadosList}
                                editUrl="/private/comprobante/edit/"
                                entityName="comprobante"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="11">
                                            <div>{t("comprobante.label.comprobantesRelacionados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className: "text-center",
                                        render: (element)=>{
                                            return(
                                                <DownloadAdjunto filename={element.adjunto} entity="Comprobante"/>
                                            )
                                        }
                                    },
                                    {
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoComprobante element={element}/>)
                                        }
                                    },
                                    {
                                        name: "proyecto",
                                        render: (element)=>{
                                            return(<p><Link className="text-dark" to={"/proyecto/edit/"+element.proyecto.id}>[{element.proyecto.id}] - {element.proyecto.ref}</Link></p>)
                                        }
                                    },
                                    {
                                        name: "cbteFechaEmision",
                                        type: "date"
                                    },
                                    {
                                        name: "cbteFechaVencimiento",
                                        type: "date"
                                    },
                                    {
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.cbteTipo.nemotecnico+(element.cbteNro?" ["+element.cbteNro+"]":"")}</p>
                                                <p>({element.cbteDetalle})</p>
                                            </>)
                                        }
                                    },
                                    {
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        render: (element)=>{
                                            return(element.cbteMoneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "cbteImporteNeto",
                                        className:"text-right",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeNeto = element.cbteImporteNeto*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeNeto}/>
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteIva",
                                        className:"text-right",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeIva = element.cbteImporteIva*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeIva}/>
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteFinal",
                                        className:"text-right",
                                        type: "money",
                                        render: (element)=>{
                                            const importeFinal = element.cbteImporteFinal*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeFinal}/>
                                            )
                                        }
                                    }
                                ]}
                            />
                            <SimpleDatatable
                                data={data?.findComprobanteById?.movimientosAsociadosList}
                                editUrl="/private/movimiento/edit/"
                                entityName="movimiento"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="11">
                                            <div>{t("comprobante.label.movimientosAsociados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className: "text-center",
                                        render: (element)=>{
                                            return(
                                                <DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>
                                            )
                                        }
                                    },
                                    {
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoMovimiento element={element}/>)
                                        }
                                    },
                                    {
                                        name: "cliente",
                                        render: (element)=>{
                                            return(element.cliente.contacto.alias)
                                        }
                                    },
                                    {
                                        name: "fechaAcreditacion",
                                        type: "date"
                                    },
                                    {
                                        
                                        name: "formaDePago",
                                        render: (element)=>{
                                            return(<>
                                                <p>{element.formaPago.nombre}</p>
                                                {element.retencion&&
                                                    <p className="text-bold">[{element.retencion.nombre}]</p>
                                                }
                                                {element.chequeNro&&
                                                    <p className="text-bold">[{element.chequeNro} | {element.chequeTitular} | {moment(element.chequeFecha).format('DD-MM-YYYY')}]</p>
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "cuenta",
                                        render: (element)=>{
                                            return(<>
                                                {
                                                    element.cuenta &&
                                                        element.cuenta?.nombre+" - ["+element.cuenta?.moneda?.simbolo+"]"
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.detalleCbtesAsociados}</p>
                                                <p>{element.detalle?"("+element.detalle+")":""}</p>
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        disableSort: true,
                                        render: (element)=>{
                                            return(element.moneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "importe",
                                        type: "money",
                                        render: (element)=>{
                                            const importe = element.importe*element.impacto;
                                            return(
                                                <CurrencyFormat value={importe}/>
                                            )
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComprobanteEdit