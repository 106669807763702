import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import SimpleDatatable from '../../../../../../components/SimpleDatatable';

const FIND_SITE_LIST_BY_EMPRESA = gql`
    query findSiteListByEmpresa(
        $idEmpresa: Int!
        $sortColumn: String
        $sortDirection: String
    ) {
        findSiteListByEmpresa(
            idEmpresa: $idEmpresa
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
        ){
            id
            empresa
            codigoPostal
            domicilio
            localidad
            nombre
            observaciones
            telefono
            pais{
                id
                nombre
            }
            provincia{
                id
                nombre
            }
        }
    }
`;

export default function SitesList(props) {
    const { idEmpresa, setMode, setEditId } = props;
    const [t] = useTranslation("global");

    const [sortOptions] = useState({
        sortColumn: "id",
        sortDirection: "ASC"
    });

    const { data } = useQuery(
        FIND_SITE_LIST_BY_EMPRESA,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                idEmpresa: idEmpresa,
            }
        }
    );

    return (

        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header">
                <h3 className="card-title">{t("common.label.listado")}</h3>
                <div className="card-tools m-0">
                    <button
                        className="btn btn-xs btn-info bg-info btn-tool ml-2"
                        title={t("common.button.agregar")}
                        onClick={()=>{setMode("create")}}>
                            <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="card-body">
                <SimpleDatatable
                    data={data?.findSiteListByEmpresa}
                    entityName="site"
                    onEdit={(element)=>{
                        setEditId(element.id);
                        setMode("edit");
                    }}
                    columnsDefinition={[
                        {
                            name: "id",
                            type: "id",
                            className: "text-center"
                        },
                        {
                            name: "nombre",
                            type: "text"
                        },
                        {
                            name: "telefono",
                            type: "text"
                        },
                        {
                            name: "domicilio",
                            type: "text"
                        },
                        {
                            name: "localidad",
                            type: "text"
                        },
                        {
                            name: "provincia",
                            type: "text",
                            render: (element)=>{
                                return(<p>{element.provincia?.nombre}</p>)
                            }
                        },
                        {
                            name: "pais",
                            type: "text",
                            render: (element)=>{
                                return(<p>{element.pais?.nombre}</p>)
                            }
                        }
                    ]}
                />
            </div>
        </div>        
    )
}
