import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, gql } from '@apollo/client';
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean';
import moment from 'moment';
import Moment from 'react-moment';
import PrivateLink from '../../../../../components/navigation/PrivateLink';
import FlagsPostulante from '../../../../../components/FlagsPostulante';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import SimpleDatatable from '../../../../../components/SimpleDatatable';
import SidebarContext from '../../../../../context/SidebarContext';
import EditarContactoDialog from '../dialogs/editarContactoDialog';
import AsociarProyectoDialog from '../../postulante/dialogs/asociarProyecto';
import EditarPlacementDialog from '../dialogs/editarPlacementDialog';
import ESTADO_PROYECTO from '../../../../../enums/EstadoProyecto';
import MenuIcon from '../../../../../components/MenuIcon';
const placementReq = `id
ultimaActividad
signOff
tipoPlacement
fechaOriginacion
experienciaLaboral{
    empresa{
        id
        contacto{alias}
    }
    cargo{id}
    pais{id}
    moneda{id}
    sueldoBrutoMensual
    areaEmpresa{id}
    posicion
    fechaInicio
    beneficios
    bonus
}
proyecto{
    id
    alias
    beneficios
    bonus
    fechaInicioBusqueda
    empresa{
        id
        contacto{
            id
            alias
        }
    }
    areaEmpresa{
        id
        nombre
    }
    cargo{
        id
        nombre
    }
    posicion
    pais{
        id
    }
    contacto{
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
    }
    acuerdoInicial{
        moneda{
            id
        }
        sueldoBrutoMensual
    }
}
estado{
    id
    desiste
    esFinal
    esInicial
    nombre
}
etapa{
    id
    orden
    nombre
}
postulante{
    id
    fechaActualizacion
    destacado
    fechaNacimientoEstimada
    incubadora
    incubadoraProspecto
    opportunity
    placement
    verificar
    offLimits
    appraisal
    emailFalso
    dni
    genero
    hijos
    qaInfo {
        indice
        recomendaciones
    }
    contacto{
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
        fechaNacimiento
        linkedin
        foto
        personaFisica
    }
    estadoCivil{
        nombre
    }
    responsable{
        contacto{
            alias
        }
    }
    adjuntoList{
        referencia
        fechaActualizacion
        contentType
        nombreOriginal
        nombreServidor
        responsable{
            contacto{
                alias
            }
        }
    }
    ultimoTrabajo{
        id
        ultimoTrabajo
        fechaInicio
        fechaFin
        empresa{
            contacto{
                alias
            }
        }
        posicion
    }
    ultimoSeguimiento{
        id
        fecha
        importante
        comentario
        accion{
            id
            nombre
            icon
            color
        }
        empresa{
            contacto{
                alias
            }
        }
        proyecto{
            id
            alias
        }
        responsable{
            id
            contacto{
                alias
            }
        }
    }
    proyectosActivos{
        id
        label
    }
    tags
}
prioridad{
    id
    nombre
    color
}
responsableList{
    id
    contacto{
        alias
    }
}`

const FIND_PLACEMENT_BY_PROYECTO = gql`
    query findPlacementByProyecto(
        $proyecto: Int!
    ) {
        findPlacementByProyecto(
            proyecto: $proyecto
    ){
        ${placementReq}
    }
}
`;

const FIND_DOUBLE_PLACEMENT_LIST_BY_PROYECTO = gql`
    query findDoublePlacementListByProyecto(
        $sortColumn: String
        $sortDirection: String
        $proyecto: Int!
    ) {
        findDoublePlacementListByProyecto(
            proyecto: $proyecto
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
    ){
        ${placementReq}
    }
}
`;

const CANCELAR_PLACEMENT_EN_PROYECTO_MUTATION = gql`
    mutation cancelarPlacementEnProyecto(
        $idProyectoPostulante: Int!
    ) {
        cancelarPlacementEnProyecto(
            idProyectoPostulante: $idProyectoPostulante
        ) {
            id
        }
    }
`;

export default function PlacementsTab(props) {
    const { setSideBarState, show } = useContext(SidebarContext);
    const [t] = useTranslation("global");
    const { proyecto, completeCancelPlacement, placementsCount, proyectoRefetch } = props;

    const [showProyectoDialog, setShowProyectoDialog] = useState(false);
    const [showEditPlacement, setShowEditPlacement] = useState(false);
    const [proyectoPostulanteSelected, setProyectoPostulanteSelected] = useState();

    const [sortOptions] = useState({
        sortColumn: "id",
        sortDirection: "DESC"
    });
    
    const {loading: loadingDoublePlacement, error: errorDoublePlacement, data: dataDoublePlacement, refetch: refetchDoublePlacement } = useQuery(
        FIND_DOUBLE_PLACEMENT_LIST_BY_PROYECTO,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                proyecto: proyecto.id
            }
        }
    );

    const {loading: loadingPlacement, error: errorPlacement, data: dataPlacement, refetch: refetchPlacement } = useQuery(
        FIND_PLACEMENT_BY_PROYECTO,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                proyecto: proyecto.id
            }
        }
    );
    
    useEffect(() => {
        const placementDataCount = dataPlacement?.findPlacementByProyecto?.length;
        const doublePlacementDataCount = dataDoublePlacement?.findDoublePlacementListByProyecto?.length;
        if(placementsCount!==(placementDataCount+doublePlacementDataCount)){
            if(proyectoRefetch){
                proyectoRefetch();
            }
            refetchDoublePlacement();
            refetchPlacement();
        }
    }, [dataDoublePlacement?.findDoublePlacementListByProyecto?.length, dataPlacement?.findPlacementByProyecto?.length, placementsCount, proyectoRefetch, refetchDoublePlacement, refetchPlacement]);

    const refetch = () =>{
        if(proyectoRefetch){
            proyectoRefetch();
        }
        refetchPlacement();
        refetchDoublePlacement();
        completeCancelPlacement();
    }

    const [cancelarPlacementMutation] = useMutation(CANCELAR_PLACEMENT_EN_PROYECTO_MUTATION,{
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
        }
    });

    const cancelarPlacement = (idProyectoPostulante) => {
        cancelarPlacementMutation({variables: {
            idProyectoPostulante: idProyectoPostulante
        }})
    }

    const isProspecto = ()=>{
        return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
    }

    const isActivo = ()=>{
        return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.ACTIVO;
    }

    const placementColumnsDef=[
        {
            name: "postulante",
            customSortKey: "postulante.contacto.alias",
            translationKey: "postulante.label.entity",
            className: "col-2",
            render: (element) =>{
                const postulante = element.postulante;
                return(
                    <>
                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <div className="text-center">
                                    <FlagsPostulante postulante={postulante} />
                                </div>
                                <div className="text-center">
                                    <ContactoAvatarImg id={postulante?.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                    {postulante.contacto?.fechaNacimiento&&
                                        <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                    }
                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                        <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                    <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                </PrivateLink>
                            </div>
                        </div>
                    </>
                );
            }
        },
        {
            name: "appraisalAndUltimoSeguimiento",
            disableSort: true,
            render: (element) =>{
                const postulante = element.postulante;
                const seguimiento = postulante.ultimoSeguimiento;
                return(
                    <>
                    {postulante?.appraisal&&
                        <div className="card card-outline seguimiento-card bg-light-gray">
                            <div className="card-body p-2">
                                <b>{t("postulante.field.appraisal")}: </b>{seguimiento.appraisal}
                            </div>
                        </div>
                    }
                    {postulante.tags&&
                        <div className="card card-outline seguimiento-card bg-light-gray">
                            <div className="card-body p-2 d-flex justify-content-start align-items-center">
                                <span className="m-1 p-1"><b>{t("postulante.field.tags")}: </b></span>
                                {postulante.tags?.split(";").map(element=>{
                                    return(<h5 className="m-0"><span className="badge badge-secondary m-1 p-1 font-weight-normal">{element}</span></h5>);
                                })}
                            </div>
                        </div>
                    }
                    {postulante?.ultimoSeguimiento&&
                    <>
                        <div className="card card-outline seguimiento-card bg-light-gray">
                            <div className="card-header p-1">
                                <div className="d-flex align-items-center">
                                    <div className="mr-2 rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: seguimiento.accion.color}}>
                                        <h6 className="align-self-center m-0">
                                            <i className={seguimiento?.accion?.icon+" text-white"} />
                                        </h6>
                                    </div>
                                    <b className={"mr-2 text-dark"}>{seguimiento?.accion?.nombre}</b>
                                    {seguimiento?.importante&&
                                        <>

                                        <span className="font-weight-bold text-danger mr-2 small float-right align-self-center">(<i className="fas fa-exclamation-triangle text-danger"/> {t("common.label.importante")})</span>
                                        </>
                                    }
                                    <span className="mr-auto"></span>
                                    <span className="mr-2 text-muted small float-right align-self-center">{seguimiento?.responsable?.contacto?.alias}</span>
                                    <span className="rounded p-1 bg-info"><Moment format={"DD MMM yyyy"}>{seguimiento?.fecha}</Moment></span>
                                </div>
                            </div>
                            <div className="card-body p-2">
                                {seguimiento?.comentario}
                            </div>
                            <div className="card-footer p-1 pr-2 pl-2">
                                <div className="row">
                                    <div className="col-12">
                                        {seguimiento?.proyecto&&
                                            <span title={seguimiento.proyecto?.alias} className="badge badge-secondary parrish-badge align-self-center mr-2">
                                                <PrivateLink className="text-light" to={"/proyecto/edit/"+seguimiento.proyecto?.id}>
                                                    <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/>{seguimiento.proyecto?.alias}
                                                </PrivateLink>
                                            </span>
                                        }
                                        {seguimiento?.empresa &&
                                            <span title={seguimiento.empresa?.contacto?.alias} className="badge badge-secondary parrish-badge align-self-center">
                                                <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{seguimiento.empresa?.contacto?.alias}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    </>
                );
            }
        },
        {
            name: "ultimoTrabajoRegistrado",
            disableSort: true,
            render: (element) =>{
                const postulante = element.postulante;
                const meses = postulante.ultimoTrabajo?moment().diff(postulante.ultimoTrabajo.fechaFin?postulante.ultimoTrabajo.fechaFin:postulante.ultimoTrabajo.fechaInicio, 'months',false):0;
                const classNamePeriod = (meses>25?"text-danger":(meses>13?"text-orange":"text-success"));
                return(postulante.ultimoTrabajo&&
                    <>
                        <p className={"font-weight-bold "+(!postulante.ultimoTrabajo?.fechaFin?"text-success":"text-danger")}>{postulante.ultimoTrabajo?.empresa.contacto.alias}</p>
                        <p>{postulante.ultimoTrabajo?.posicion}</p>
                        <p className={"text-nowrap "+classNamePeriod}>
                            <span><Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaInicio}</Moment></span>
                            {
                                postulante.ultimoTrabajo?.fechaFin?
                                    <> - <Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaFin}</Moment></>
                                :
                                    <span> - <span>{t("Actualidad")}</span></span>
                            }
                        </p>
                        <p className={classNamePeriod}>({t("common.label.hace")} {meses} {t("postulante.field.meses")})</p>
                    </>
                );
            },
        },
        {
            name: "responsableUltimaActividad",
            disableSort: true,
            render: (element) =>{
                const postulanteProyecto = element;
                return(<>
                    <p>{moment(postulanteProyecto.ultimaActividad).format("YYYY-MM-DD")}</p>
                    <p><span className="text-bold">{t("postulante.field.responsables")}: </span></p>
                    {postulanteProyecto.responsableList.map(element=>
                        <h5 key={element.contacto.alias} className='m-0' ><span className="badge badge-info m-0 p-1 font-weight-normal">{element.contacto.alias}</span></h5>
                    )}
                </>)
            }
        },
        {
            name: "prioridad",
            type: "text",
            disableSort: true,
            className: "text-center",
            rowSpan:2,
            headerClassname: "text-center sorting_disabled",
            customHeader: <div className="m-0 d-flex justify-content-center"><FlagButtonBoolean 
                    icon="fas fa-running"
                    onClass="bg-maroon"
                    title={t("postulante.field.prioridad")}
                    value={true}
                /></div>,
            render: (element)=>{
                return(<div className="d-flex justify-content-center">
                    <FlagButtonBoolean
                        icon="fas fa-running"
                        onClass={"bg-"+element.prioridad.color}
                        title={element.prioridad.nombre}
                        value={true}
                    />
                </div>);
            }
        },
        {
            name: "etapa",
            customSortKey:"etapa.id",
            translationKey: "postulante.field.etapa",
            type: "text",
            render: (element)=>{
                return(<>{element.etapa.nombre}</>);
            }
        },
        {
            name: "estadoProyecto",
            translationKey: "proyecto.field.estado",
            disableSort: true,
            type: "text",
            render: (element)=>{
                
                return(<><p className={element.estado.esFinal?"text-danger":"text-success"}>{element.estado.nombre}</p></>);
            }
        },
        {
            name: "acciones",
            disableSort: true,
            customClassHeader: "bg-info text-center pl-0 pr-0",
            className: "text-center",
            render: (element)=>{
                return(<>
                    {(isProspecto()||isActivo())&&
                        <div className="d-flex justify-content-center">
                            <FlagButtonBoolean
                                icon="fas fa-handshake-slash"
                                onClass="bg-danger m-1"
                                offClass="bg-danger m-1"
                                value={true}
                                setValue={()=>{cancelarPlacement(element.id)}}
                            />
                            <FlagButtonBoolean
                                icon=""
                                onClass="bg-transparent m-1"
                                offClass="bg-transparent m-1"
                                value={true}
                                setValue={()=>{}}
                            />
                        </div>
                    }
                    <div className="d-flex justify-content-center">
                        <button
                            type="button"
                            title={t("postulante.field.asociarProyecto")}
                            className={"btn btn-xs bg-info m-1"}
                            onClick={(e)=>{
                                e.preventDefault();
                                setProyectoPostulanteSelected(element);
                                setShowProyectoDialog(true);
                            }}>
                            <i className="fas fa-search-plus"></i>
                        </button>
                        <EditarContactoDialog contactoId={element.postulante?.contacto?.id} refetch={refetch}/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <FlagButtonBoolean
                            icon="fas fa-shoe-prints fa-rotate-270"
                            onClass="bg-yellow m-1"
                            offClass="bg-yellow m-1"
                            value={true}
                            setValue={()=>{
                                setSideBarState({
                                    mode: "seguimiento",
                                    labelPostulante: element.postulante?.contacto?.alias,
                                    labelProyecto: element.proyecto?.alias,
                                    postulante: element.postulante?.id,
                                    proyecto: element.proyecto?.id,
                                    empresa: null,
                                    labelEmpresa: null,
                                    responsable: null,
                                    accionSeguimiento: null,
                                })
                                show();
                            }}
                        />
                        <FlagButtonBoolean
                            icon="fas fa-user-secret"
                            onClass="bg-yellow m-1"
                            offClass="bg-yellow m-1"
                            value={true}
                            setValue={()=>{
                                setSideBarState({
                                    mode: "referencias",
                                    labelPostulante: element.postulante?.contacto?.alias,
                                    labelProyecto: element.proyecto?.alias,
                                    postulante: element.postulante?.id,
                                    proyecto: element.proyecto?.id,
                                    labelEmpresa: null,
                                    empresa: null
                                })
                                show();
                            }}
                        />
                    </div>
                </>)
            }
        }
    ];
    
    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                (loadingPlacement && loadingDoublePlacement &&
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-body pr-0 pl-0">
                {!loadingPlacement&&
                !errorPlacement&&
                dataPlacement?.findPlacementByProyecto&&
                    <>
                        <h4>{t("proyecto.label.placement")}</h4>
                        <SimpleDatatable
                            data={[dataPlacement?.findPlacementByProyecto]}
                            entityName="postulante"
                            headerTop={
                                <tr>
                                    <th colSpan="4" className="bg-info pr-2">
                                        <p></p>
                                    </th>
                                    <th colSpan="3" className="bg-info pr-2">
                                        <span>{t("postulante.label.avanceProyecto")}</span>
                                    </th>
                                    <th colSpan="1" className="bg-info pr-2">
                                        <p></p>
                                    </th>
                                </tr>
                            }
                            columnsDefinition={placementColumnsDef}
                            onEdit={(element)=>{
                                setProyectoPostulanteSelected(element);
                                setShowEditPlacement(true);
                            }}/>
                    </>
                }
                {!loadingDoublePlacement&&
                !errorDoublePlacement&&
                dataDoublePlacement?.findDoublePlacementListByProyecto?.length>0&&
                    <>
                        <h4>{t("proyecto.label.doublePlacements")}</h4>
                        <SimpleDatatable
                            data={dataDoublePlacement?.findDoublePlacementListByProyecto}
                            entityName="postulante"
                            headerTop={
                                <tr>
                                    <th colSpan="4" className="bg-info pr-2">
                                        <p></p>
                                    </th>
                                    <th colSpan="3" className="bg-info pr-2">
                                        <span>{t("postulante.label.avanceProyecto")}</span>
                                    </th>
                                    <th colSpan="1" className="bg-info pr-2">
                                        <p></p>
                                    </th>
                                </tr>
                            }
                            columnsDefinition={placementColumnsDef}
                            onEdit={(element)=>{
                                setProyectoPostulanteSelected(element);
                                setShowEditPlacement(true);
                            }}/>
                    </>
                }
                <EditarPlacementDialog proyectoPostulante={proyectoPostulanteSelected} refetch={()=>refetch()} show={showEditPlacement} setShow={setShowEditPlacement}/>
                <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={proyectoPostulanteSelected?.postulante?.id} aliasPostulante={proyectoPostulanteSelected?.postulante?.contacto?.alias} />
            </div>
        </div>
    )
}