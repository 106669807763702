import moment from 'moment';
import React, { useEffect } from 'react'
import $ from 'jquery'

export default function EstadoComprobante(props) {
    let {element} = props;
    element = element?.node?element?.node:element;

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip("dispose");
        $('[data-toggle="tooltip"]').tooltip();
        //$("#estado-comprobante-"+element.id).tooltip('dispose');
    }, [])
    
    const tooltipEstado = element.estado?`<div class="text-align-left text-nowrap text-bold m-0">${element.estado.nombre}</div>`:"";
    const tooltipRegistro = element.fechaRegistro?`<div class="text-nowrap m-0"><b>R</b> - ${moment(element.fechaRegistro).format('DD-MM-YYYY')} - ${element.registradoPor.contacto.alias}</div>`:"";
    const tooltipAprobacion = element.fechaAprobacion?`<div class="text-nowrap m-0"><b>A</b> - ${moment(element.fechaAprobacion).format('DD-MM-YYYY')} - ${element.aprobadoPor.contacto.alias}</div>`:"";
    const tooltipEmision = element.fechaEmision?`<div class="text-nowrap m-0"><b>E</b> - ${moment(element.fechaEmision).format('DD-MM-YYYY')} - ${element.emitidoPor.contacto.alias}</div>`:"";
    const tooltipConciliacion = element.fechaConciliacion?`<div class="text-nowrap m-0"><b>C</b> - ${moment(element.fechaConciliacion).format('DD-MM-YYYY')} - ${element.conciliadoPor.contacto.alias}</div>`:"";
    const tooltip = "<div class='text-align-left'>"+tooltipEstado+tooltipRegistro+tooltipAprobacion+tooltipEmision+tooltipConciliacion+"</div>";

    return(
        <span id={"estado-comprobante-"+element.id} className={"badge badge-"+element.estado?.color} data-toggle="tooltip" data-html="true" title={tooltip}>{element.estado?.nombre}</span>
    )
}