import React, {useEffect, useState} from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import CustomSelect from './CustomSelect';
import { useTranslation } from 'react-i18next';

export default function SelectEntity(props) {
    const [t] = useTranslation("global");

    const {
        entity,
        queryCompleteName,
        label,
        displayText,
        filterValue,
        filterName,
        filterBy,
        disabled,
        filterTypes,
        filterNames,
        filterValues,
        avoidList,
        filterType,
        sortDisable,
        placeholder,
        onChange,
        name,
        minWidth
    } = props;

    const [data, setData] = useState(null);

    let queryName = queryCompleteName?queryCompleteName:"find"+entity+"List";
    let sort='sortCriteria:{sortColumn:"id", sortDirection: "ASC"} ';
    let filter="";
    let filterDef="";
    
    if(filterName!==undefined&&filterValue!==undefined&&filterName!==null&&filterValue!==null){
        filterDef="($"+filterName+": "+(filterType?filterType:"Int")+")";
        if(filterBy||queryCompleteName){
            queryName+=filterBy?filterBy:"";
            filter = filterName+": $"+filterName;
            sort = "";
        }else{
            queryName+="ByFilter";
            filter = "filter:{"+filterName+": $"+filterName+"}";
        }
    }

    if(filterNames!==undefined&&filterValues!==undefined&&filterNames!==null&&filterValues!==null){
        filterDef="(";
        for (let index = 0; index < filterNames.length; index++) {
            const filterName = filterNames[index];  
            const filterType = filterTypes[index]?filterTypes[index]:"Int";
            filterDef+="$"+filterName+": "+filterType+" ";
        }
        filterDef+=")";

        if(filterBy){
            queryName+=filterBy;
            filterNames.forEach(filterName => {
                filter+=filterName+": $"+filterName+", ";
            });
            sort = "";
        }else{
            queryName+="ByFilter";
            filter="filter:{";
            filterNames.forEach(filterName => {
                filter+=filterName+": $"+filterName+", ";
            });
            filter+="}";
        }
    }

    if(sortDisable!=null && sortDisable){
        sort="";
        if(!queryCompleteName)
            filter="";
    }

    let sortAndFilter = "";
    if(sort || filter){
        sortAndFilter=`(
            ${sort}
            ${filter}
            )`;
    }

    //TODO Agregar label customs (con varios campos)
    const SELECT_LIST = gql`
        query ${queryName}${filterDef} {
        ${queryName}
            ${sortAndFilter}
        {
            id
            ${label}
        }
    }`;

    
    const [ loadData , { loading, error }] = useLazyQuery(SELECT_LIST, {
        onCompleted: (data) => {
            setData(data);
        }
    });

    const isDisabled = () =>{
        if(disabled)
            return true;
        if(filterName!==undefined&&filterName!==""&&filterName!==null){
            if(filterValue===undefined||filterValue===""||filterValue===null){
                return true;
            }
        }
        return false;
    }
    
    useEffect(() => {
        if(filterValue!=null){
            onChange({target:{value:""}})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue])

    useEffect(() => {
        if(filterBy){
            if(filterValue){
                loadData({ variables: {[filterName]: parseInt(filterValue) } })
            }else{
                setData([]);
            }
        }else{
            loadData({ variables: {[filterName]: parseInt(filterValue) } })
        }
    }, [filterBy, filterName, filterValue, loadData])

    const displayLabel = (element) =>{
        if(displayText){
            return (displayText(element));
        }
        return (element[label]);
    }

    const getOptions = () => {
        if (loading||error||!data)
            return [];

        return data[queryName]?.filter(element => !avoidList?.includes(parseInt(element.id))).map((element, index) => {
            return {
                label: displayLabel(element),
                value: element.id
            }
        })
    }

    const onChangeFunc = (value, action) =>{
        onChange({target:{value:value?.value}})
    }

    const getDefatulValue = () =>{
        if(props.value){
            const finded = getOptions()?.find(element =>{
                return parseInt(element.value)===parseInt(props.value);
            });
            return finded;
        }else{
            return null;
        }
    }

    return(
        <CustomSelect
            {...props}
            isClearable={false}
            minWidth={minWidth}
            placeholder={placeholder?placeholder: t("common.label.defaultSelectPlaceholder")}
            value={getDefatulValue()}
            isDisabled={isDisabled()}
            isLoading={false}
            isRtl={false}
            isSearchable={false}
            name={name}
            options={getOptions()}
            onChange={onChangeFunc}
        />
    );    
}
