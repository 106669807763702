import React, { useState, useRef,useEffect } from 'react'
import Form from './Form'
import { useTranslation } from "react-i18next"
import PrivateLink from './navigation/PrivateLink';

export default function CardCreateEntity(props) {
    const [submitButtonRef, setSubmitButtonRef] = useState('');
    const {entityName, columnsCount, handleSubmit, values, returnUrl, returnAction, className} = props;
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => { _isMounted.current = false };
    },[]);

    return (
        <div className={className?"card "+className:"card card-outline card-info"}>
            <div className="card-header">
                <h3 className="card-title">{t("common.label.detalle")}</h3>
                <div className="card-tools m-0">
                    <button type="button" className="btn btn-xs bg-info btn-tool" onClick={()=>{if(_isMounted.current){submitButtonRef?.click()}}}>
                        <i className="fas fa-save"></i>
                    </button>
                    {
                        returnUrl?
                            <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={returnUrl}>
                                <i className="fas fa-times"></i>
                            </PrivateLink>
                        :
                        null
                    }
                    {
                        returnAction?
                            <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={returnAction}>
                                <i className="fas fa-times"></i>
                            </button>
                        :
                        null
                    }
                </div>
            </div>
            <div className="card-body">
                <Form
                    entityName={entityName}
                    columnsCount={columnsCount}
                    handleSubmit={handleSubmit}
                    setSubmitButtonRef={setSubmitButtonRef}
                    values={values}
                    errorDivId="error-messages"
                >
                </Form>
            </div>
        </div>
    )
}
