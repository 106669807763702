import {useCallback, useEffect} from 'react';
import { useTranslation } from "react-i18next"
import Swal from 'admin-lte/plugins/sweetalert2/sweetalert2.all'
import $ from 'jquery'
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'

const ReactSwal = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: "bg-info",
    }
}))

export default function useAlertMessage() {   
    const [t] = useTranslation("global");
    const history = useHistory();
    const _defaultToastOptions = {
        toast: true,
        showConfirmButton: false,
        title: '<i class="icon fas fa-exclamation-triangle mr-2"></i> Panel de mensajes',
        showCloseButton: true,
        position: 'top',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' },
        customClass: {
            popup: "bg-warning p-0",
            content: "m-3",
            title: "",
            header: "bg-warning border-bottom border-dark p-1",
            confirmButton: "bg-info",
            closeButton: "text-dark"
        }
    };

    const ToastWarning = Swal.mixin({
        ..._defaultToastOptions,
    })

    const ToastInfo = Swal.mixin({
        ..._defaultToastOptions,
        title: '<i class="icon fas fa-info mr-2"></i> Panel de mensajes',
    })

    const ErrorPopup = Swal.mixin({
        allowOutsideClick: false,
        title: '<i class="icon fas fa-ban mr-2"></i> Panel de mensajes',
        customClass: {
            header: "bg-danger text-white border-bottom border-white mb-3",
            popup: "bg-danger text-white p-3",
            title: "text-white",
            content: "text-white",
            confirmButton: "btn-danger-2",
        }
    });

    /*const ToastDanger = Swal.mixin({
        ..._defaultToastOptions,
        title: '<i class="icon fas fa-bug mr-2"></i> Panel de mensajes',
        customClass: {
            ..._defaultToastOptions.customClass,
            popup: "bg-danger p-0",
            header: "bg-danger border-bottom border-dark p-1",
        },
    })*/
    
    const clearPopUps = useCallback(() =>{
        Swal.close();
    }, []);

    const showErrorToast = useCallback((errorList, onClose = ()=>{} ) =>{
        if(errorList==null||errorList.length===0)
            return;
        var errorHtmlList = "<ul class='m-0 pl-3 text-left'>";
        for (let index = 0; index < errorList.length; index++) {
            const errorHtml = errorList[index];
            errorHtmlList = errorHtmlList+"<li>"+errorHtml.message+"</li>";   
        }
        errorHtmlList = errorHtmlList.concat("</ul>")

        ToastWarning.fire({
            html: errorHtmlList,
            onClose: onClose
        })
    }, [ToastWarning]);

    const showMessage = useCallback((icon, message, onClose = ()=>{} ) =>{
        Swal.fire({
            icon: icon,
            text: message,
            onClose: onClose
        });
    }, []);

    const showReactMessage = useCallback((icon, message, onClose = ()=>{} ) =>{
        ReactSwal.fire({
            icon: icon,
            html: message,
            onClose: onClose
        });
    }, []);

    const showSuccess = useCallback((message, onClose = ()=>{}) =>{
        showMessage("success", message, onClose); 
    }, [showMessage]);

    const showErrorList = useCallback((errors, onClose = ()=>{}, mode = "client-error") =>{
        if(errors==null||errors.length===0)
            return;
        const translatedErrors = errors.map(function(error) {
            if(error.message){
                return error.message;
            }else{
                return t(error);
            }
        });
        var errorHtmlList = "<ul class='m-0 pl-3 text-left'>";
        translatedErrors.forEach(translatedError => {
            errorHtmlList = errorHtmlList+"<li>"+translatedError+"</li>";   
        });
        errorHtmlList = errorHtmlList.concat("</ul>")

        const data = {
            html: errorHtmlList,
            onClose: onClose
        };
        switch (mode) {
            case "server-error":
                ErrorPopup.fire(data);
                break;
            case "security-error":
                ToastWarning.fire(data)
                break;
            case "client-error":
            default:
                ToastInfo.fire(data)
                break;
        }
        
    }, [ErrorPopup, ToastInfo, ToastWarning, t]);

    const showError = useCallback((error, onClose = ()=>{}) =>{
        let message = "ERROR: "+error.message;
        if(error.graphQLErrors!=null && error.graphQLErrors.length>0){
            const code = error.graphQLErrors[0]?.extensions?.code;
            if(code!=null){
                message = t(code)
            }
        }
        //showMessage("error", message, onClose); 
        showErrorToast([{message: message}], onClose);
    }, [showErrorToast, t]);

    const showWarning = useCallback((message, onClose = ()=>{}) =>{
        showMessage("warning", message, onClose); 
    }, [showMessage]);

    const showInfo = useCallback((message, onClose = ()=>{}) =>{
        showMessage("info", message, onClose); 
    }, [showMessage]);

    const showQuestion = useCallback((message, onClose = ()=>{}) =>{
        showMessage("question", message, onClose); 
    }, [showMessage]);

    const showConfirmDeleteWithPin = (id, deleteFunction) => {
        Swal.fire({
            title: t("common.message.confirmation.delete.question", id),
            icon: 'warning',
            input: 'password',
            inputLabel: t("common.label.pin"),
            inputPlaceholder: t("common.label.placeholder.pin"),
            inputAttributes: {
                id:"pin-input",
                minLength: 4,
                maxlength: 4,
                inputmode: 'numeric',
                autocapitalize: 'off',
                autocorrect: 'off',
                "data-inputmask": "'mask': '9999'"
            },
            onOpen: function(el) {
                var container = $(el);
                container.find('#pin-input').inputmask();
            },
            showCancelButton: true,
            confirmButtonText: t('common.value.option.yes'),
            cancelButtonText: t('common.value.option.no'),
            validationMessage: t("common.message.invalid.pin"),
            customClass: {
                confirmButton: 'btn btn-success mr-2',
                cancelButton: 'btn btn-danger',
                input: 'centered pin'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: (pinCode) => {
                if(pinCode!=null && pinCode==="1234"){
                    deleteFunction(id);
                }else{
                    Swal.showValidationMessage(t("common.message.invalid.pin"))
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
    }

    const showConfirmDelete = (id, label, deleteFunction) => {
        Swal.fire({
            title: t("common.message.confirmation.delete.question", {0: label}),
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-success mr-2',
                cancelButton: 'btn btn-danger',
            },
            confirmButtonText: t('common.value.option.yes'),
            cancelButtonText: t('common.value.option.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFunction(id);
            }
        })
    }

    const showMessageWithTimerAndRedirect = (icon, message, timer, redirectUrl) => {
        Swal.fire({
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: timer,
        }).then((result) => {
        /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
                history.push(redirectUrl);
            }
        })
    }

    const showMessageWithTimerAndExecute = (icon, message, timer, finishAction) => {
        Swal.fire({
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: timer,
        }).then((result) => {
        /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                finishAction();
            }
        })
    }

    useEffect(() => {
        history?.listen((location, action) => {
            clearPopUps();
        });
    }, [clearPopUps, history])

    return {
        showSuccess,
        showError,
        showErrorList,
        showWarning,
        showInfo,
        showReactMessage,
        showQuestion,
        showMessage,
        showConfirmDeleteWithPin,
        showConfirmDelete,
        showMessageWithTimerAndRedirect,
        showMessageWithTimerAndExecute,
        showErrorToast,
        clearPopUps,
    }
}