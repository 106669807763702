import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import { useHistory, useParams } from 'react-router';
import AppStateContext from "../../../../context/AuthContext"

// TODO PGEN revisar campos del filtro y de la respuesta
const USUARIO_SEARCH = gql`
    query findUsuarioPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $perfil: Int
        $nombreCompleto: String
    ) {
    findUsuarioPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            perfil: $perfil
            nombreCompleto: $nombreCompleto
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                bloqueado
                nombre
                apellido
                email
                perfil{
                    id
                    nombre
                }
                contacto{
                    alias
                }
                activacionPendiente
            }
        }       
    }
}
`;

function UsuarioList(){
    // TODO PGEN seleccionar orden default
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "contacto.alias",
        sortDirection: "ASC"
    });

    const { mode, id } = useParams();
    const history = useHistory();

    const [pageMode] = useState(mode?mode:"list");

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [searchFilter, setSearchFilter] = useState({
        nombreCompleto: "",
        perfil: "",
    });

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        setPageNumber(0)
    }, [searchFilter]);

    const {loading, error, data, refetch } = useQuery(
        USUARIO_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                perfil: searchFilter.perfil?parseInt(searchFilter.perfil):null,
                nombreCompleto: searchFilter.nombreCompleto
            }
        }
    );

    useEffect(() => {
        if(!mode){
            history.replace("/private/usuario/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/usuario/"+mode+idParam);
    
            if(mode==="list"){
                refetch();
            }
        }
    }, [history, id, mode, pageMode, refetch])

    return (
        <>
            <CardFilter
                entityName="usuario"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "nombreCompleto",
                        type: "text",
                        translationKey: "usuario.label.textoFiltro"
                    },
                    {
                        type: "selectKeyValue",
                        name: "perfil",
                        entity:"Perfil"
                    }
                ]}
                columnsCount={1}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findUsuarioPageByFilter"
                entityName="usuario"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/usuario/edit/"
                createUrl="/usuario/create"
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id"
                    },
                    {
                        name: "nombreCompleto",
                        customSortKey: "contacto.alias",
                        render: (element)=>{
                            return(<p>{element.node.contacto.alias}</p>)
                        }
                    },
                    {
                        name: "email",
                        type: "text"
                    },
                    {
                        name: "perfil.nombre",
                        customSortKey:"perfil.nombre",
                        translationKey: "usuario.field.perfil",
                        type: "text"
                    },
                    {
                        name: "activacionPendiente",
                        type: "boolean",
                        trueClassname: "text-danger text-decoration-none",
                        falseLabel: " ",
                    },
                    {
                        name: "bloqueado",
                        type: "boolean",
                        trueClassname: "text-danger text-decoration-none",
                        falseLabel: " ",
                    }]}
                conditionalRowStyle="user-bloqued-row"
                conditionalRowValue={(element)=>{
                    return element.bloqueado;
                }}>
            </CardDatatable>
        </>
    );
}

export default UsuarioList;