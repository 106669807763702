import React, { useEffect, useState } from 'react'
import CardFilter from '../../../../components/CardFilter';
import ComprobanteList from "./comprobanteList"
import MovimientoList from "./movimientoList"
import { useTranslation } from "react-i18next"

function CtaCteClienteList(props){
    const {searchFilter, setSearchFilter} = props;
    const [t] = useTranslation("global");
    const [monedaLabel, setMonedaLabel] = useState("AR$");

    useEffect(() => {
    }, [])
    
    return (
        <>
            <CardFilter
                entityName="ctaCteCliente"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "autocomplete",
                        name: "cliente",
                        entity:"EmpresaCliente",
                        by: "Alias",
                        filterName: "alias",
                        componentClassName: "col-sm-4 col-12"
                    },
                    {
                        type: "select",
                        name: "proyecto",
                        entity:"ProyectoKeyValue",
                        label: "label",
                        filterValue: searchFilter.cliente,
                        filterName: "idEmpresa",
                        filterBy: "ByEmpresaRef",
                        componentClassName: "col-sm-4 col-12"
                    },
                    {
                        type: "selectKeyValue",
                        name: "moneda",
                        entity:"Moneda",
                        hideEmptyOption: true,
                        inputClassName:"col-2",
                        onChange: (e, label)=>{
                            const updatedSearchFilter = {
                                ...searchFilter
                            };
                            updatedSearchFilter.moneda = e.target.value;       
                            setSearchFilter(updatedSearchFilter);
                            setMonedaLabel(label)
                        },
                        componentClassName: "col-sm-4 col-12"
                    }
                ]}>
            </CardFilter>


            <div className={"card card-outline card-info"}>
                <div className="card-header pl-3 pt-2 pb-2">
                    <h3 className="card-title">{t("ctaCteCliente.label.menu")}</h3>
                </div>
                <div className="card-body">
                    <ComprobanteList searchFilter={searchFilter} monedaLabel={monedaLabel}/>
                    <MovimientoList searchFilter={searchFilter} monedaLabel={monedaLabel}/>
                </div>
            </div>
        </>
    );
}

export default CtaCteClienteList;