import React from 'react'
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../../../components/DatePicker';
import SelectEntity from '../../../../../components/SelectEntity';
import SelectKeyValue from '../../../../../components/SelectKeyValue';

export default function DatosPersonalesTab(props) {
    const [t] = useTranslation("global");
    const { state, setState } = props;

    const linkClick= (e,url) =>{
        e.preventDefault();
        if(url){
            // Check if the URL starts with "http://" or "https://"
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                // If not, prepend "http://"
                url = "https://" + url;
            }
            
            window.open(url,"_blank");
        }
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }
    
    return (
        <>
            <div className="parrish-form row ">

                <div className="col-xs-12 col-sm-6">

                    <div className="parrish-form row ">
                        <div className="col-12 text-center">
                            <h3>{t("postulante.label.datosPersonales")}</h3>
                        </div>
                    </div>
                    
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-genero" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.genero")}</label>
                                <div className="col">
                                    <select
                                        name="value-genero"
                                        value={state.genero}
                                        className="parrish-input"
                                        onChange={(e)=>handleChange("genero", e)}>
                                            <option value=""></option>
                                            <option value={t("common.label.femenino")}>{t("common.label.femenino")}</option>
                                            <option value={t("common.label.masculino")}>{t("common.label.masculino")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-fechaNacimiento" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.fechaNacimiento")}</label>
                                <div className="col-sm-4">
                                    <DatePicker
                                        name="value-fechaNacimiento"
                                        showMonthDropdown
                                        showYearDropdown
                                        className="border-danger"
                                        selected={state.fechaNacimiento}
                                        onChange={(date) => {
                                            console.log(date);
                                            handleChange("fechaNacimiento", {target: {value:date} })
                                        }}/>
                                </div>
                                <div className="col-sm-4">
                                    <input autoComplete="off"
                                        className="btn m-2"
                                        type="checkbox"
                                        checked={state.fechaNacimientoEstimada}
                                        onChange={(val) => {
                                            handleChange("fechaNacimientoEstimada", {target:{value: val.target.checked}})
                                        }} />
                                    <span>{t("postulante.field.fechaNacimientoEstimada")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-nacionalidad" className="parrish-label col-4 text-right font-weight-normal">{t("postulante.field.nacionalidad")}</label>
                                <div className="col-8">
                                    <SelectKeyValue
                                        entity="Pais"
                                        label="nombre"
                                        name={'value-nacionalidad'}
                                        value={state.nacionalidad}
                                        required
                                        onChange={(e) => {
                                            handleChange("nacionalidad", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-dni" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.dni")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-id-card"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="number"
                                            name={'value-dni'}
                                            className="parrish-input"
                                            value={state.dni}
                                            onChange={e => handleChange("dni", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-estadoCivil" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.estadoCivil")}</label>
                                <div className="col">
                                    <SelectEntity
                                        entity="EstadoCivil"
                                        label="nombre"
                                        name="value-estadoCivil"
                                        value={state.estadoCivil}
                                        required
                                        onChange={(e) => {
                                            handleChange("estadoCivil", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-hijos" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.hijos")}</label>
                                <div className="col">
                                    <input autoComplete="off" type="number"
                                        name={'value-hijos'}
                                        className="parrish-input"
                                        value={state.hijos}
                                        min="0"
                                        max="99"
                                        onChange={e => handleChange("hijos", e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-tel-fijos" className="parrish-label col-sm-4 col-xs-12 text-right">{t("contacto.field.telefonosFijos")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-phone"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-tel-fijos'}
                                            className="parrish-input"
                                            maxLength={150}
                                            value={state.telefonosFijos}
                                            onChange={e => handleChange("telefonosFijos", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-tel-moviles" className="parrish-label col-sm-4 col-xs-12 text-right">{t("contacto.field.telefonosMoviles")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{whatsappClick(e,state.telefonosMoviles)}}>
                                            <span className="input-group-text">
                                                <i className="fab fa-whatsapp"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-tel-moviles'}
                                            className="parrish-input"
                                            maxLength={150}
                                            value={state.telefonosMoviles}
                                            onChange={e => handleChange("telefonosMoviles", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="parrish-form row mt-4">
                        <div className="col-12 text-center">
                            <h3>{t("postulante.label.domicilio")}</h3>
                        </div>
                    </div>
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-domicilio" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.domicilio")}</label>
                                <div className="col">
                                    <input autoComplete="off" type="text"
                                        name={'value-domicilio'}
                                        className="parrish-input"
                                        value={state.direccionDomicilio}
                                        onChange={e => handleChange("direccionDomicilio", e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-localidad" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.localidad")}</label>
                                <div className="col">
                                    <input autoComplete="off" type="text"
                                        name={'value-localidad'}
                                        className="parrish-input"
                                        value={state.direccionLocalidad}
                                        onChange={e => handleChange("direccionLocalidad", e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 text-right font-weight-normal">{t("postulante.field.pais")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="Pais"
                                        label="nombre"
                                        name={'value-pais'}
                                        value={state.direccionPais}
                                        required
                                        onChange={(e) => {
                                            handleChange("direccionPais", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-provincia" className="parrish-label col-sm-4 col-xs-12 text-right font-weight-normal">{t("postulante.field.provincia")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="Provincia"
                                        by="PaisNombre"
                                        label="nombre"
                                        name={'value-provincia'}
                                        value={state.direccionProvincia}
                                        filterValue={state.direccionPais}
                                        filterName="idPais"
                                        onChange={(e) => {
                                            handleChange("direccionProvincia", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-codigoPostal" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.codigoPostal")}</label>
                                <div className="col-2">
                                    <input autoComplete="off" type="number"
                                        name={'value-codigoPostal'}
                                        className="parrish-input"
                                        value={state.direccionCodigoPostal}
                                        onChange={e => handleChange("direccionCodigoPostal", e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-6">
                    <div className="parrish-form row">
                        <div className="col-xs-12 col-sm-12">
                            <div className="parrish-form row ">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.observaciones")}</h3>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-12 col-xs-12">
                                    <textarea
                                        name="value-observaciones"
                                        className="form-control form-control-sm"
                                        rows="17"
                                        value={state.observaciones}
                                        onChange={(e)=>{handleChange("observaciones", e)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="parrish-form row ">
                        <div className="col-12 text-center">
                            <h3>{t("postulante.label.redesSociales")}</h3>
                        </div>
                    </div>
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-linkedin" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.linkedin")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.socialInfoLinkedin)}}>
                                            <span className="input-group-text">
                                                <i className="fab fa-linkedin-in"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-linkedin'}
                                            className="parrish-input"
                                            value={state.socialInfoLinkedin}
                                            maxLength={150}
                                            onChange={e => handleChange("socialInfoLinkedin", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-website" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.website")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.website)}}>
                                            <span className="input-group-text">
                                                <i className="fas fa-globe-americas"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-website'}
                                            className="parrish-input"
                                            value={state.website}
                                            maxLength={150}
                                            onChange={e => handleChange("website", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-skype" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.skype")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-skype"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-skype'}
                                            maxLength={150}
                                            className="parrish-input"
                                            value={state.socialInfoSkype}
                                            onChange={e => handleChange("socialInfoSkype", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-twitter" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.twitter")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-twitter"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-twitter'}
                                            className="parrish-input"
                                            value={state.socialInfoTwitter}
                                            maxLength={150}
                                            onChange={e => handleChange("socialInfoTwitter", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-facebook" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.facebook")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.socialInfoFacebook)}}>
                                            <span className="input-group-text">
                                                <i className="fab fa-facebook-square"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-facebook'}
                                            className="parrish-input"
                                            value={state.socialInfoFacebook}
                                            maxLength={150}
                                            onChange={e => handleChange("socialInfoFacebook", e)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}