import React, { useEffect, useState, useContext } from 'react'
import useAlertMessage from '../hooks/useAlertMessage.js';
import AuthContext from "./../context/AuthContext.js";
import * as Croppie from "croppie/croppie"
import "croppie/croppie.css"    
import { useMutation, gql } from '@apollo/client';
import ContactoAvatarImg from './ContactoAvatarImg';
import { useTranslation } from "react-i18next"

let croppie;
export default function AvatarUploadModal(props) {
    const {initialImageData, entity, id, contactoId, avatarClassName, foto, personaFisica, imgClassName} = props;
    const [t] = useTranslation("global");
    const [imageData, setImageData] = useState(initialImageData);   
    const [open, setOpen] = useState(false);
    const queryName = `update${entity}Avatar`;
    const idParam = `id${entity}`;
    const fileInput = React.createRef();

    const UPDATE_AVATAR_MUTATION = gql`
        mutation ${queryName}(
            $id: Int!
            $avatar: Upload!
        ) {
            ${queryName}(
                ${idParam}: $id
                avatar: $avatar
            ) {
                id
                contentType
                foto
            }
        }
    `;

    const deleteQueryName = `delete${entity}Avatar`;

    const DELETE_AVATAR_MUTATION = gql`
        mutation ${deleteQueryName}(
            $id: Int!
        ) {
            ${deleteQueryName}(
                ${idParam}: $id
            ){
                id
            }
        }
    `;
    
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    let { showErrorToast, clearPopUps } = useAlertMessage();

    const fileMaxSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.fwk.config.max.file.size"})?.valor;
    
    const handleChange = (file) => {
        clearPopUps();
        if(file.size/1024/1024 < (fileMaxSizeDefaultParam?parseInt(fileMaxSizeDefaultParam):20)){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setImageData(reader.result);
                setOpen(true);
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        }else{
            
            showErrorToast([{message: t("common.message.size-file-exceed")}]);
        }
    }

    const [updateAvatar] = useMutation(UPDATE_AVATAR_MUTATION,{
        onCompleted: (data) => {
            window.location.reload();
        },
        onError: (error) => {
        }
    });

    const uploadAvatar = (newAvatar) => {
        if(id){
            updateAvatar({variables: {
                id: id,
                avatar: newAvatar
            }})
        }
    }

    const [avatarCrop, setAvatarCrop] = useState()

    useEffect(() => {
        const croppieElement = document.getElementById('croppie');
        if (open && imageData && croppieElement && croppieElement.className.indexOf('croppie-container') < 0) {
            croppie = new Croppie(croppieElement, {
                viewport: {
                    width: 200,
                    height: 200,
                    type: 'square',
                    format: 'jpeg'
                },
            });
            croppie.bind({
                url: imageData,
                zoom: 0
            });
        }
    })

    const handleCrop = () =>{
        croppie.result({
            type:"base64",
            size:{
                width: 400,
                height: 400
            }
        }).then(base64 => {
            setAvatarCrop(base64);
        });
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type:mimeString});
    }

    const handleUploadCrop = () =>{
        const blobImage = dataURItoBlob(avatarCrop);
        uploadAvatar(blobImage);
    }

    const { showConfirmDelete } = useAlertMessage();

    const [deleteAvatarMutation] = useMutation(DELETE_AVATAR_MUTATION, {
        onCompleted: (data) => {
            window.location.reload();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const deleteAvatar = () => {
        showConfirmDelete(
            id,
            entity+" Avatar ["+id+"]",
            (id)=>{deleteAvatarMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    return (
        <>
            <div className={avatarClassName?avatarClassName:"text-center align-self-center m-4"}>
                <div className="avatar-container">
                    <label className="btn btn-xs btn-info remove-btn border-light position-absolute" onClick={()=>deleteAvatar()}>
                        <i className="fas fa-trash"></i>
                    </label>
                    <label className="btn btn-xs btn-info border-light position-absolute" forhtml="my-file-selector">
                        <input autoComplete="off" id="my-file-selector" type="file" className="d-none" onChange={({target: {files: [file]}}) => handleChange(file)} ref={fileInput}/>
                        <i className="fas fa-camera"></i>
                    </label>
                    <ContactoAvatarImg personaFisica={personaFisica} id={contactoId} foto={foto} className={imgClassName?imgClassName:"profile-user-img img-fluid img-circle border-info"} alt="User profile picture"/>
                </div>
            </div>
            {open &&
                <>
                    <div className="modal fade show d-block" id="modal-default" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Actualizar Avatar</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setOpen(false)}}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col mb-3">
                                        <div id="croppie" className="pb-4"></div>
                                    </div>
                                    <hr className="m-2"/>
                                    <div className="col">
                                        <button type="button" className="btn btn-info" onClick={()=>{handleCrop()}}>Cortar Avatar</button>
                                    </div>
                                    {
                                        avatarCrop &&
                                        (
                                            <>
                                                <hr className="m-2"/>
                                                <div className="col">
                                                    <h5 className="text-muted mb-3">Previsualización</h5>
                                                </div>
                                                <div className="col">
                                                    <div className="row d-flex flex-row justify-content-around">
                                                        <div className="col-5">
                                                            <img src={avatarCrop} alt="" className="img-fluid elevation-2"/>
                                                        </div>
                                                        <div className="col-5">
                                                            <img src={avatarCrop} alt="" className="img-circle img-fluid elevation-2"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }                               
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>{setOpen(false)}}>Cerrar</button>
                                    <button type="button" disabled={!avatarCrop} className="btn btn-info" onClick={()=>{handleUploadCrop(avatarCrop)}}>Guardar Avatar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
