import React from "react";
import ReactDatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

registerLocale('es', es)
setDefaultLocale('es')

const DatePicker = (props) => {
    return (
      <div className={"input-group input-group-sm h-fit-content"+(props.className?" "+props.className:"")}>
        <ReactDatePicker
          {...props}
          autoComplete="off"
          onChange={date => {
            if(date){
              date.setHours(0, 0, 0, 0);
            }
            props.onChange(date);
          }}
          className="form-control form-control-sm rounded-0 rounded-right"
          dateFormat={props.dateFormat?props.dateFormat:"dd-MM-yyyy"}/>
        <div className="input-group-append">
            <span className="input-group-text">
                <i className="far fa-calendar-alt"></i>
            </span>
        </div>
      </div>
    );
};

export default DatePicker
