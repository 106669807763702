import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const AREAEMPRESA_SEARCH_BY_ID = gql`
    query findAreaEmpresaById(
        $id: Int!
    ) {
    findAreaEmpresaById(
        id: $id
    ){
        id
        nombre
    }
}
`;

const UPDATE_AREAEMPRESA_MUTATION = gql`
    mutation updateAreaEmpresa(
        $id: ID!
        $nombre: String!
    ) {
        updateAreaEmpresa(entity:{
            id: $id
            nombre: $nombre
    }) {
            id
            nombre
        }
    }
`;

const DELETE_AREAEMPRESA_MUTATION = gql`
    mutation deleteAreaEmpresa(
        $id: Int!
    ) {
        deleteAreaEmpresa(id: $id)
    }
`;

function AreaEmpresaEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        AREAEMPRESA_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findAreaEmpresaById?.nombre);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findAreaEmpresaById?.nombre
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateAreaEmpresaMutation] = useMutation(UPDATE_AREAEMPRESA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/areaEmpresa";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteAreaEmpresaMutation] = useMutation(DELETE_AREAEMPRESA_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/areaEmpresa");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateAreaEmpresaMutation({
            variables:{
                id: id,
                nombre: nombre
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteAreaEmpresaMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="areaEmpresa"
                columnsCount={1}
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 100,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("areaEmpresa.field.nombre")})
                        }
                    }
                ]}
                returnUrl="/areaEmpresa"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default AreaEmpresaEdit