import React from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import CustomSelect from './CustomSelect';
import { useTranslation } from 'react-i18next';

export default function MultipleAutocomplete(props) {
    const [t] = useTranslation("global");
    const {entity, setValue, by, filterName, additionalFilter, disabled, placeholder, searchInFocus = false, value, defaultValue, onRemove, onSelect} = props;
    let queryName = "find"+entity+"KeyValueListBy"+by;
    let customFilter = additionalFilter?additionalFilter:"";

    const AUTOCOMPLETE_LIST = gql`
        query ${queryName}(
            $filter: String
        ) {
            ${queryName}(
                ${filterName}: $filter
                ${customFilter}
            ) {
                id
                label
            }
        }
    `;

    const [getFilteredSuggestions, { loading, data }] = useLazyQuery(AUTOCOMPLETE_LIST);

    function escapeRegex(string) {
        // eslint-disable-next-line no-useless-escape
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    const formatOptionLabel = ({ value, label},{context, inputValue}) => {
        if(context==="menu" && inputValue!==""){
            const reg = new RegExp('('+escapeRegex(inputValue.trim())+')', 'gi');
            const parts = label?.split(reg).map((part)=>{
                return {
                    text: part,
                    highlight: reg.test(part)
                }
            })
    
            return (
              <span className={'suggestion-content m-0'}>
                <span className="name">
                  {
                    parts.map((part, index) => {
                      return (
                        <span className={part.highlight?'highlight':''} key={index}>{part.text}</span>
                      );
                    })
                  }
                </span>
              </span>
            );
        }else{
            return(label);
        }
    };

    return(
        <CustomSelect
            isMulti           
            placeholder={placeholder?placeholder: t("common.label.defaultSelectPlaceholder")}
            isDisabled={disabled}
            options={
                data &&
                data[queryName] &&
                data[queryName].map(({ id, label }) => ({ value: id, label: label }))
            }
            onChange={(newValue, actionMeta) => {
                if(actionMeta.action==="select-option" || actionMeta.action==="remove-value" || actionMeta.action==="pop-value"){
                    if(setValue){
                        setValue(newValue.map((element)=>element.value), newValue);
                    }
                }

                if(actionMeta.action==="deselect-option" || actionMeta.action==="pop-value" || actionMeta.action==="remove-value"){
                    if(onRemove){
                        onRemove(actionMeta.removedValue);
                    }
                }

                if(actionMeta.action==="select-option"){
                    if(onSelect){
                        onSelect(actionMeta.option);
                    }
                }

            }}
            onFocus={() => {
                if(searchInFocus){
                    getFilteredSuggestions({ variables: { filter: "" }});
                }
            }}
            defaultValue={defaultValue}
            value={value}
            formatOptionLabel={formatOptionLabel}
            isLoading={loading}
            onInputChange={(newValue,actionMeta)=>{
                if(!disabled && actionMeta.action==="input-change"){
                    if(newValue){
                        getFilteredSuggestions({ variables: { filter: newValue }, skip: disabled});
                    }
                }
            }}
        />
    );
}
