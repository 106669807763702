import React, { useState } from 'react'
import SitesList from './sites'
import SiteCreate from './create'
import SiteEdit from './edit'

export default function SitesTab(props) {
    const { idEmpresa } = props;
    const [mode, setMode] = useState("list");
    const [editId, setEditId] = useState("list")

    return (
        <>
            {mode==="list"&&
                <SitesList setMode={setMode} idEmpresa={idEmpresa} setEditId={setEditId}/>
            }
            {mode==="create"&&
                <SiteCreate setMode={setMode} idEmpresa={idEmpresa}/>
            }
            {mode==="edit"&&
                <SiteEdit setMode={setMode} editId={editId}/>
            }
        </>
    )
}