import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import ConciliacionList from './list'
import { useHistory, useParams } from 'react-router';

function ConciliacionIndex(){
    const entityName = "conciliacion";
    const [searchFilter, setSearchFilter] = useState({
        cliente: "",
    });
    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <ConciliacionList
                        searchFilter={searchFilter}
                        setSearchFilter={setSearchFilter}
                    />
                :
                null
            }
        </div>
    );
}

export default ConciliacionIndex;