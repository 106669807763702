import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
// eslint-disable-next-line no-unused-vars
import AuthContext from '../../../../../context/AuthContext';
import FilterProyectoPostulante from './filterProyectoPostulante';
import Datatable from '../../../../../components/Datatable';
import Moment from 'react-moment';
import moment from 'moment';
import FlagsPostulante from '../../../../../components/FlagsPostulante';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import PrivateLink from '../../../../../components/navigation/PrivateLink';
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean';
import AsociarProyectoDialog from '../../postulante/dialogs/asociarProyecto';
import ControlEtapas from './etapa/controlEtapas';
import SidebarContext from '../../../../../context/SidebarContext';
import EditarContactoDialog from '../dialogs/editarContactoDialog';
import ControlEstado from './etapa/controlEstado';
import ESTADO_PROYECTO from '../../../../../enums/EstadoProyecto';
import MenuIcon from '../../../../../components/MenuIcon';
import DeleteEntityButton from '../../../../../components/DeleteEntityButton';

const PROYECTO_POSTULANTE_PAGE_EXCLUDING_PLACEMENTS_BY_FILTER = gql`
    query findProyectoPostulantePageExcludingPlacementsByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $empresa: Int
        $pais: Int
        $estado: Int
        $etapa: Int
        $postulante: Int
        $prioridad: Int
        $proyecto: Int!
        $responsable: Int
        $ultimaActividadDesde: LocalDate
        $ultimaActividadHasta: LocalDate
        $seguimientoAccion: Int
    ) {
        findProyectoPostulantePageExcludingPlacementsByFilter(
            filter:{
                empresa: $empresa,
                pais: $pais,
                estado: $estado,
                etapa: $etapa,
                postulante: $postulante,
                prioridad: $prioridad,
                proyecto: $proyecto,
                responsable: $responsable,
                ultimaActividadDesde: $ultimaActividadDesde,
                ultimaActividadHasta: $ultimaActividadHasta
                seguimientoAccion: $seguimientoAccion
            }
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                ultimaActividad
                signOff
                proyecto{
                    id
                    alias
                    beneficios
                    bonus
                    fechaInicioBusqueda
                    empresa{
                        id
                        contacto{
                            id
                            alias
                        }
                    }
                    areaEmpresa{
                        id
                        nombre
                    }
                    cargo{
                        id
                        nombre
                    }
                    posicion
                    pais{
                        id
                    }
                    contacto{
                        id
                        alias
                        email
                        telefonosFijos
                        telefonosMoviles
                    }
                    acuerdoInicial{
                        moneda{
                            id
                        }
                        sueldoBrutoMensual
                    }
                }
                estado{
                    id
                    desiste
                    esFinal
                    esInicial
                    nombre
                }
                etapa{
                    id
                    orden
                    nombre
                }
                postulante{
                    id
                    fechaActualizacion
                    destacado
                    fechaNacimientoEstimada
                    incubadora
                    incubadoraProspecto
                    opportunity
                    placement
                    verificar
                    offLimits
                    appraisal
                    emailFalso
                    dni
                    genero
                    hijos
                    qaInfo {
                        indice
                        recomendaciones
                    }
                    contacto{
                        id
                        alias
                        email
                        telefonosFijos
                        telefonosMoviles
                        fechaNacimiento
                        linkedin
                        foto
                        personaFisica
                    }
                    estadoCivil{
                        nombre
                    }
                    responsable{
                        contacto{
                            alias
                        }
                    }
                    adjuntoList{
                        referencia
                        fechaActualizacion
                        contentType
                        nombreOriginal
                        nombreServidor
                        responsable{
                            contacto{
                                alias
                            }
                        }
                    }
                    ultimoTrabajo{
                        id
                        ultimoTrabajo
                        fechaInicio
                        fechaFin
                        empresa{
                            contacto{
                                alias
                            }
                        }
                        posicion
                    }
                    ultimoSeguimiento{
                        id
                        fecha
                        importante
                        comentario
                        accion{
                            id
                            nombre
                            icon
                            color
                        }
                        empresa{
                            contacto{
                                alias
                            }
                        }
                        proyecto{
                            id
                            alias
                        }
                        responsable{
                            id
                            contacto{
                                alias
                            }
                        }
                    }
                    proyectosActivos{
                        id
                        label
                    }
                    tags
                }
                prioridad{
                    id
                    nombre
                    color
                }
                responsableList{
                    id
                    contacto{
                        alias
                    }
                }
            }
        }
    }
}
`;

export default function EtapaTab(props) {
    const [t] = useTranslation("global");
    const { setSideBarState, show } = useContext(SidebarContext);
    const { proyecto, postulanteFilter, postulanteLabel, completePlacement, refetch: proyectoRefetch, clearPostulanteFilter } = props;

    const [searchFilter, setSearchFilter] = useState({
        empresa: "",
        pais: "",
        estado: "",
        etapa: "",
        postulante: "",
        prioridad: "",
        responsable: "",
        ultimaActividadDesde: "",
        ultimaActividadHasta: "",
        seguimientoAccion: ""

    });

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "ultimaActividad",
        sortDirection: "DESC"
    });

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);
    const [showProyectoDialog, setShowProyectoDialog] = useState(false);
    const [idPostulante, setIdPostulante] = useState();
    const [aliasPostulante, setAliasPostulante] = useState();

    const [selectAll, setSelectAll] = useState(false);

    const [showControlEstado, setShowControlEstado] = useState(false);
    
    const [selectedPostulante, setSelectedPostulante] = useState({});
    const [columnsDef, setColumnsDef] = useState({});

    const {loading, error, data, refetch } = useQuery(
        PROYECTO_POSTULANTE_PAGE_EXCLUDING_PLACEMENTS_BY_FILTER,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                proyecto: parseInt(proyecto.id),
                empresa: searchFilter.empresa?searchFilter.empresa:null,
                pais: searchFilter.pais?searchFilter.pais:null,
                estado: searchFilter.estado?searchFilter.estado:null,
                etapa: searchFilter.etapa?searchFilter.etapa:null,
                postulante: searchFilter.postulante?searchFilter.postulante:null,
                prioridad: searchFilter.prioridad?searchFilter.prioridad:null,
                responsable: searchFilter.responsable?searchFilter.responsable:null,
                ultimaActividadDesde: searchFilter.ultimaActividadDesde?searchFilter.ultimaActividadDesde:null,
                ultimaActividadHasta: searchFilter.ultimaActividadHasta?searchFilter.ultimaActividadHasta:null,
                seguimientoAccion: searchFilter.seguimientoAccion?parseInt(searchFilter.seguimientoAccion):null
            }
        }
    );
    
    useEffect(() => {
        if(postulanteFilter||postulanteLabel){
            const postulanteFilterInt = parseInt(postulanteFilter);
            if(postulanteFilterInt!==searchFilter.postulante){
                setSearchFilter(oldSearchFilter=>({
                    ...oldSearchFilter,
                    postulante: postulanteFilterInt,
                    postulanteLabel: "["+postulanteFilterInt+"] - "+postulanteLabel
                }));
            }
            clearPostulanteFilter();
        }
    }, [clearPostulanteFilter, postulanteFilter, postulanteLabel, searchFilter.postulante])

    function setStateValue(name, value) {
        setSearchFilter({
            ...searchFilter,
            [name]: value
        });
    }

    const clearSelection = () =>{
        setSelectAll(false);
        setSelectedPostulante({});
    }

    const [selectMultipleProyectoPostulante, setSelectMultipleProyectoPostulante] = useState([]);
    const [selectPostulanteLabel, setSelectPostulanteLabel] = useState();

    useEffect(() => {
        if(!loading){
            clearSelection();
        }
    }, [loading]);

    useEffect(() => {
        const isProspecto = ()=>{
            return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
        }
    
        const isActivo = ()=>{
            return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.ACTIVO;
        }

        const isCanceladoSuspendidoOTerminado = ()=>{
            return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.CANCELADO ||
                parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.SUSPENDIDO ||
                parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.TERMINADO;
        }

        const defaultColumnsDef = [
            {
                name: "postulante",
                customSortKey: "postulante.contacto.alias",
                translationKey: "postulante.label.entity",
                className: "col-2",
                render: (element) =>{
                    const postulante = element.node.postulante;
                    return(
                        <>
                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <div className="text-center">
                                        <FlagsPostulante postulante={postulante} />
                                    </div>
                                    <div className="text-center">
                                        <ContactoAvatarImg id={postulante.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                        {postulante.contacto?.fechaNacimiento&&
                                            <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                        }
                                        <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                            <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                        <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                    </PrivateLink>
                                </div>
                            </div>
                        </>
                    );
                }
            },
            {
                name: "appraisalAndUltimoSeguimiento",
                disableSort: true,
                render: (element) =>{
                    const postulante = element.node.postulante;
                    const seguimiento = postulante.ultimoSeguimiento;
                    return(
                        <>
                        {postulante?.appraisal&&
                            <div className="card card-outline seguimiento-card bg-light-gray">
                                <div className="card-body p-2">
                                    <b>{t("postulante.field.appraisal")}: </b>{postulante.appraisal}
                                </div>
                            </div>
                        }
                        {element.node.postulante.tags&&
                            <div className="card card-outline seguimiento-card bg-light-gray">
                                <div className="card-body p-2 d-flex justify-content-start align-items-center">
                                    <span className="m-1 p-1"><b>{t("postulante.field.tags")}: </b></span>
                                    {element.node.postulante.tags?.split(";").map(element=>{
                                        return(<h5 className="m-0"><span className="badge badge-secondary m-1 p-1 font-weight-normal">{element}</span></h5>);
                                    })}
                                </div>
                            </div>
                        }
                        {postulante?.ultimoSeguimiento&&
                        <>
                            <div className="card card-outline seguimiento-card bg-light-gray">
                                <div className="card-header p-1 ">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2 rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: seguimiento.accion.color}}>
                                            <h6 className="align-self-center m-0">
                                                <i className={seguimiento?.accion?.icon+" text-white"} />
                                            </h6>
                                        </div>
                                        <b className={"mr-2 text-dark"}>{seguimiento?.accion?.nombre}</b>
                                        {seguimiento?.importante&&
                                            <>

                                            <span className="font-weight-bold text-danger mr-2 small float-right align-self-center">(<i className="fas fa-exclamation-triangle text-danger"/> {t("common.label.importante")})</span>
                                            </>
                                        }
                                        <span className="mr-auto"></span>
                                        <span className="mr-2 text-muted small float-right align-self-center">{seguimiento?.responsable?.contacto?.alias}</span>
                                        <span className="rounded p-1 bg-info text-nowrap"><Moment format={"DD MMM yyyy"}>{seguimiento?.fecha}</Moment></span>
                                    </div>
                                </div>
                                <div className="card-body p-2">
                                    {seguimiento?.comentario}
                                </div>
                                <div className="card-footer p-1 pr-2 pl-2">
                                    <div className="row">
                                        <div className="col-12">
                                            {seguimiento?.proyecto&&
                                                <span title={seguimiento.proyecto?.alias} className="badge badge-secondary align-self-center parrish-badge mr-2">
                                                    <PrivateLink className="text-light" to={"/proyecto/edit/"+seguimiento.proyecto?.id}>
                                                        <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/><span>{seguimiento.proyecto?.alias}</span>
                                                    </PrivateLink>
                                                </span>
                                            }
                                            {seguimiento?.empresa &&
                                                <span className="badge badge-secondary align-self-center parrish-badge">
                                                    <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{seguimiento.empresa?.contacto?.alias}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                        </>
                    );
                }
            },
            {
                name: "ultimoTrabajoRegistrado",
                disableSort: true,
                render: (element) =>{
                    const postulante = element.node.postulante;
                    const meses = postulante.ultimoTrabajo?moment().diff(postulante.ultimoTrabajo.fechaFin?postulante.ultimoTrabajo.fechaFin:postulante.ultimoTrabajo.fechaInicio, 'months',false):0;
                    const classNamePeriod = (meses>25?"text-danger":(meses>13?"text-orange":"text-success"));
                    return(postulante.ultimoTrabajo&&
                        <>
                            <p className={"font-weight-bold "+(!postulante.ultimoTrabajo?.fechaFin?"text-success":"text-danger")}>{postulante.ultimoTrabajo?.empresa.contacto.alias}</p>
                            <p>{postulante.ultimoTrabajo?.posicion}</p>
                            <p className={"text-nowrap "+classNamePeriod}>
                                <span><Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaInicio}</Moment></span>
                                {
                                    postulante.ultimoTrabajo?.fechaFin?
                                        <> - <Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaFin}</Moment></>
                                    :
                                        <span> - <span>{t("Actualidad")}</span></span>
                                }
                            </p>
                            <p className={classNamePeriod}>({t("common.label.hace")} {meses} {t("postulante.field.meses")})</p>
                        </>
                    );
                },
            },
            {
                name: "responsableUltimaActividad",
                customSortKey: "ultimaActividad",
                render: (element) =>{
                    const postulanteProyecto = element.node;
                    return(<>
                        <p>{moment(postulanteProyecto.ultimaActividad).format("YYYY-MM-DD")}</p>
                        <p><span className="text-bold">{t("postulante.field.responsables")}: </span></p>
                        {postulanteProyecto.responsableList.map(element=>
                            <h5 key={element.contacto.alias} className='m-0' ><span className="badge badge-info m-0 p-1 font-weight-normal">{element.contacto.alias}</span></h5>
                        )}
                    </>)
                }
            },
            {
                name: "prioridad",
                type: "text",
                translationKey: "postulante.field.quickWin",
                className: "text-center",
                headerClassname: "text-center sorting_disabled",
                render: (element)=>{
                    return(<div className="d-flex justify-content-center">
                        <FlagButtonBoolean
                            icon="fas fa-running"
                            onClass={"bg-"+element.node.prioridad.color}
                            title={element.node.prioridad.nombre}
                            value={true}
                        />
                    </div>);
                }
            },
            {
                name: "etapa",
                customSortKey:"etapa.id",
                translationKey: "postulante.field.etapa",
                type: "text",
                render: (element)=>{
                    return(<>{element.node.etapa.nombre}</>);
                }
            },
            {
                name: "estadoProyecto",
                translationKey: "postulante.field.estado",
                customSortKey:"estado.nombre",
                type: "text",
                render: (element)=>{
                    return(<><p className={element.node.estado.esFinal?"text-danger":"text-success"}>{element.node.estado.nombre}</p></>);
                }
            },
            {
                name: "acciones",
                disableSort: true,
                customClassHeader: "bg-info text-center pl-0 pr-0",
                className: "text-center",
                render: (element)=>{
                    return(!loading&&<>
                        <div className="d-flex justify-content-center">
                            {(isProspecto()||isActivo())&&
                                <ControlEtapas proyectoPostulante={element.node} completePlacement={completePlacement} refetch={()=>{refetch()}} proyectoRefetch={()=>{proyectoRefetch()}}  />
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                title={t("postulante.field.asociarProyecto")}
                                className={"btn btn-xs bg-info m-1"}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setAliasPostulante(element.node.postulante?.contacto?.alias);
                                    setIdPostulante(element.node.postulante?.id);
                                    setShowProyectoDialog(true);
                                }}>
                                <i className="fas fa-search-plus"></i>
                            </button>
                            <EditarContactoDialog contactoId={element.node.postulante?.contacto?.id} refetch={refetch} />
                        </div>
                        <div className="d-flex justify-content-center">
                            <FlagButtonBoolean
                                icon="fas fa-shoe-prints fa-rotate-270"
                                onClass="bg-yellow m-1"
                                offClass="bg-yellow m-1"
                                value={true}
                                setValue={()=>{
                                    setSideBarState({
                                        mode: "seguimiento",
                                        labelPostulante: element.node.postulante?.contacto?.alias,
                                        labelProyecto: element.node.proyecto?.alias,
                                        postulante: element.node.postulante?.id,
                                        proyecto: element.node.proyecto?.id,
                                        empresa: null,
                                        labelEmpresa: null,
                                        responsable: null,
                                        accionSeguimiento: null,
                                    })
                                    show();
                                }}
                            />
                            <FlagButtonBoolean
                                icon="fas fa-user-secret"
                                onClass="bg-yellow m-1"
                                offClass="bg-yellow m-1"
                                value={true}
                                setValue={()=>{
                                    setSideBarState({
                                        mode: "referencias",
                                        labelPostulante: element.node.postulante?.contacto?.alias,
                                        labelProyecto: element.node.proyecto?.alias,
                                        postulante: element.node.postulante?.id,
                                        proyecto: element.node.proyecto?.id,
                                        labelEmpresa: null,
                                        empresa: null
                                    })
                                    show();
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-start">
                            <DeleteEntityButton className="ml-2" entity="ProyectoPostulante" id={element.node.id} label={element.node.postulante?.contacto?.alias} onCompleted={()=>{
                                refetch()
                            }} />
                        </div>
                        
                    </>)
                }
            },
        ];

        

        const selectionRow = [{
            name: "selection",
            disableSort: true,
            customHeader: 
                <div className="custom-control custom-checkbox m-2">
                    <input className="custom-control-input custom-control-input-secondary" 
                        autoComplete="off"
                        type="checkbox"
                        id="postulante-select-all"
                        checked={selectAll}
                        onChange={(e) => {
                            if(e.target.checked){
                                let newEl = {};
                                for (const edge of data?.findProyectoPostulantePageExcludingPlacementsByFilter?.edges) {
                                    const node = edge.node;
                                    newEl[node.id] = node;
                                }
                                setSelectedPostulante(selectedPostulanteold => {
                                    return({...selectedPostulanteold,...newEl});
                                });
                            }else{
                                setSelectedPostulante({});
                            }
                            setSelectAll(e.target.checked);
                        }} />
                    <label className="custom-control-label bg-white" htmlFor="postulante-select-all"></label>
                </div>,
            customClassHeader: "bg-info text-center pl-0 pr-0",
            className: "text-center",
            render: (element)=>{
                return(
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input custom-control-input-info" 
                            autoComplete="off"
                            role="button"
                            type="checkbox"
                            id={"postulante-"+element.node.id}
                            checked={selectedPostulante[element.node.id]?true:false}
                            onChange={(e) => {
                                if(e.target.checked){
                                    let newEl = {};
                                    newEl[element.node.id] = element.node;
                                    setSelectedPostulante(selectedPostulanteold => {
                                        return({...selectedPostulanteold,...newEl});
                                    });
                                }else{
                                    setSelectedPostulante(selectedPostulanteold => {
                                        delete selectedPostulanteold[element.node.id];
                                        return({...selectedPostulanteold});
                                    });
                                }
                            }} />
                        <label className="custom-control-label" htmlFor={"postulante-"+element.node.id}></label>
                    </div>
                );
            }
        }];

        if(searchFilter.etapa&&!isCanceladoSuspendidoOTerminado()){
            setColumnsDef([...defaultColumnsDef, ...selectionRow])
        }else{
            setColumnsDef([...defaultColumnsDef])
        }

    }, [completePlacement, data?.findProyectoPostulantePageExcludingPlacementsByFilter?.edges, proyecto.estado?.id, proyectoRefetch, refetch, selectAll, selectedPostulante, setSideBarState, show, searchFilter.etapa, t, loading])

    const isProspecto = ()=>{
        return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
    }

    const isActivo = ()=>{
        return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.ACTIVO;
    }

    const isCanceladoSuspendidoOTerminado = ()=>{
        return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.CANCELADO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.SUSPENDIDO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.TERMINADO;
    }

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                (loading && !error) && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <ControlEstado show={showControlEstado} setShow={setShowControlEstado} proyectosPostulante={selectMultipleProyectoPostulante} postulanteLabel={selectPostulanteLabel} refetch={()=>{refetch(); clearSelection();}}/>
            <div className="card-header p-0 border-0">
                <FilterProyectoPostulante proyecto={proyecto.id} state={searchFilter} setState={setSearchFilter} setStateValue={setStateValue} />
            </div>
            <div className="card-body p-0">
                <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                    <div className="card-header pr-0 pl-0">
                        <h3 className="card-title">{t("common.label.listado")} <span className="badge badge-info ml-2 p-1 pr-2 pl-2">{t("common.label.encontrados",{0: data?.findProyectoPostulantePageExcludingPlacementsByFilter?.queryInfo?.totalElements})}</span></h3>
                        <div className="card-tools m-0">
                            {!isCanceladoSuspendidoOTerminado()&&
                                <button type="button" title={t("Cambiar Estado Postulantes")} className="btn btn-xs btn-info bg-info btn-tool" disabled={!Object.keys(selectedPostulante)?.length} onClick={()=>{
                                    setShowControlEstado(true);
                                    setSelectMultipleProyectoPostulante(selectedPostulante);
                                    

                                    const multipleLabels = Object.keys(selectedPostulante).reduce((label, key) =>{
                                        label=label?label+", ":label;
                                        return label+selectedPostulante[key].postulante?.contacto?.alias;
                                    }, "");

                                    setSelectPostulanteLabel(multipleLabels);
                                }}>
                                    Cambiar Estado Postulantes {Object.keys(selectedPostulante)?.length?("("+Object.keys(selectedPostulante)?.length+")"):""}
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findProyectoPostulantePageExcludingPlacementsByFilter"
                    entityName="postulante"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onEdit={(element)=>{
                        if(isProspecto()||isActivo()){
                            setShowControlEstado(true);
                            let selectedProyectoPostulante = {};
                            selectedProyectoPostulante[element.node.id] = element.node;
                            setSelectMultipleProyectoPostulante(selectedProyectoPostulante);
                            setSelectPostulanteLabel(element?.node?.postulante?.contacto?.alias);
                        }
                    }}
                    columnsDefinition={columnsDef}/>
                    <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={idPostulante} aliasPostulante={aliasPostulante} />
            </div>
        </div>
    )
}