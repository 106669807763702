import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useParams } from "react-router-dom";
import DatePicker from '../../../../components/DatePicker';
import $ from 'jquery'
import {} from "jquery-validation";
import PrivateLink from '../../../../components/navigation/PrivateLink';
import SelectKeyValue from '../../../../components/SelectKeyValue';

const CREATE_CONTACTO_MUTATION = gql`
    mutation createContacto(
        $nacionalidad: Int
        $pais: Int
        $provincia: Int
        $fechaNacimiento: LocalDate
        $personaFisica: Boolean!
        $apellido: String
        $codigoPostal: String
        $contentType: String
        $domicilio: String
        $email: String
        $facebook: String
        $linkedin: String
        $localidad: String
        $nombre: String!
        $observaciones: String
        $skype: String
        $telefonosFijos: String
        $telefonosMoviles: String
        $twitter: String
        $website: String
    ) {
        createContacto(entity:{
            nacionalidad: $nacionalidad
            pais: $pais
            provincia: $provincia           
            fechaNacimiento: $fechaNacimiento
            personaFisica: $personaFisica
            apellido: $apellido
            codigoPostal: $codigoPostal
            contentType: $contentType
            domicilio: $domicilio
            email: $email
            facebook: $facebook
            linkedin: $linkedin
            localidad: $localidad
            nombre: $nombre
            observaciones: $observaciones
            skype: $skype
            telefonosFijos: $telefonosFijos
            telefonosMoviles: $telefonosMoviles
            twitter: $twitter
            website: $website
        }) {
            id
            nacionalidad{
                nombre
            }
            pais{
                nombre
            }
            provincia{
                nombre
            }
            fechaActualizacion
            fechaNacimiento
            personaFisica
            alias
            apellido
            codigoPostal
            contentType
            domicilio
            email
            facebook
            foto
            linkedin
            localidad
            nombre
            observaciones
            skype
            telefonosFijos
            telefonosMoviles
            twitter
            website
        }
    }
`;

function ContactoEdit(props) {
    const { showMessageWithTimerAndRedirect, showErrorToast } = useAlertMessage();

    const [nacionalidad, setNacionalidad] = useState('');
    const [pais, setPais] = useState();
    const [provincia, setProvincia] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState();
    const [personaFisica, setPersonaFisica] = useState(true);
    const [apellido, setApellido] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [email, setEmail] = useState('');
    const [facebook, setFacebook] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [nombre, setNombre] = useState('');
    const [skype, setSkype] = useState('');
    const [telefonosFijos, setTelefonosFijos] = useState('');
    const [telefonosMoviles, setTelefonosMoviles] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');

    const [t] = useTranslation("global");
    const { id } = useParams();
    
    const ref = useRef();    

    const [createContactoMutation] = useMutation(CREATE_CONTACTO_MUTATION, {
        onCompleted: (data) => {
            console.log("complete!!!");
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.save.success"), 1500, "/private/agenda-contactos");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        console.log("send createContactoMutation!");
        createContactoMutation({
            variables:{
                nacionalidad: nacionalidad?parseInt(nacionalidad):null,
                pais: pais?parseInt(pais):null,
                provincia: provincia?parseInt(provincia):null,
                nombre: nombre,
                personaFisica: personaFisica,
                fechaNacimiento: fechaNacimiento?fechaNacimiento:null,               
                apellido: apellido?apellido:null,
                codigoPostal: codigoPostal?codigoPostal:null,
                domicilio: domicilio?domicilio:null,
                email: email?email:null,
                facebook: facebook?facebook:null,
                linkedin: linkedin?linkedin:null,
                localidad: localidad?localidad:null,
                skype: skype?skype:null,
                telefonosFijos: telefonosFijos?telefonosFijos:null,
                telefonosMoviles: telefonosMoviles?telefonosMoviles:null,
                twitter: twitter?twitter:null,
                website: website?website:null
            }
        })
    }
    
    useEffect(() => {
        const isPersonaFisica = id==="persona";
        setPersonaFisica(isPersonaFisica);
        let validateJson;
        if(isPersonaFisica===true){
            validateJson={
                rules:{
                    "value-nombre":{
                        required: true
                    },
                    "value-apellido":{
                        required: true
                    },
                    "value-email":{
                        required: true,
                        email: true
                    }
                },
                messages: {
                    "value-nombre": {
                        required: t("common.message.field.required", {0: t("contacto.field.nombre")})
                    },
                    "value-apellido": {
                        required: t("common.message.field.required", {0: t("contacto.field.apellido")})
                    },
                    "value-email": {
                        required: t("common.message.field.required", {0: t("contacto.field.email")}),
                        email: t("common.message.invalid.email.format")
                    }
                },
                errorElement: 'span',
                errorClass: "text-danger text-sm",
                onkeyup: false,
                onclick: false,
                onfocusout: false,
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                },
                showErrors: function(errorMap, errorList) {
                    showErrorToast(errorList);
                }
            }
        }else{
            validateJson={
                rules:{
                    "value-nombre":{
                        required: true
                    }
                },
                messages: {
                    "value-nombre": {
                        required: t("common.message.field.required", {0: t("contacto.field.nombre")})
                    }
                },
                errorElement: 'span',
                errorClass: "text-danger text-sm",
                onkeyup: false,
                onclick: false,
                onfocusout: false,
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                },
                showErrors: function(errorMap, errorList) {
                    showErrorToast(errorList);
                }
            }
        }
        $(ref.current).validate(validateJson);   
    },[personaFisica, showErrorToast, t, id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <form autoComplete="off" id={"create-"+(personaFisica?"persona":"empresa")} ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-outline card-info">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")}</h3>
                    <div className="card-tools">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to="/agenda-contactos">
                            <i className="fas fa-times"></i>
                        </PrivateLink>
                    </div>
                </div>
                <div className="card-body">

                    <div className="parrish-form row">
                        <div className="col-sm">
                            <div className="form-group row">
                                <label htmlFor="value-nombre" className="parrish-label col-3 text-right required">{t("Nombre")}</label>
                                <div className="col-8">
                                <input autoComplete="off"
                                    type="text"
                                    name={'value-nombre'}
                                    className="parrish-input"
                                    value={nombre}
                                    maxLength={150}
                                    onChange={(e) => setNombre(e.target.value)}/>
                                </div>
                            </div>
                            {personaFisica&&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="value-apellido" className="parrish-label col-3 text-right required">{t("Apellido")}</label>
                                    <div className="col-8">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-apellido'}
                                        className="parrish-input"
                                        value={apellido}
                                        maxLength={150}
                                        onChange={(e) => setApellido(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="value-fecha-nacimiento" className="parrish-label col-3 text-right">{t("F. Nacimiento")}</label>
                                    <div className="col-8">
                                        <DatePicker
                                            name="value-fecha-nacimiento"
                                            selected={fechaNacimiento}
                                            onChange={date => setFechaNacimiento(date)}/>
                                    </div>
                                </div>
                            </>
                            }
                            <div className="form-group row">
                                <label htmlFor="value-nacionalidad" className="parrish-label col-3 text-right">{t("Nacionalidad")}</label>
                                <div className="col-8">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-nacionalidad'}
                                    value={nacionalidad}
                                    onChange={(e) => {
                                        setNacionalidad(e.target.value);
                                    }}/>
                                </div>
                            </div>
                            <h5 className="text-center mt-4">REDES SOCIALES</h5>
                            <div className="form-group row">
                                <label htmlFor="value-linkedin" className="parrish-label col-3 text-right">{t("LinkedIn")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-linkedin-in"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-linkedin'}
                                            className="form-control"
                                            value={linkedin}
                                            maxLength={150}
                                            onChange={(e) => setLinkedin(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-website" className="parrish-label col-3 text-right">{t("Website")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-globe-americas"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-website'}
                                            className="parrish-input"
                                            value={website}
                                            maxLength={150}
                                            onChange={(e) => setWebsite(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-skype" className="parrish-label col-3 text-right">{t("Skype")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-skype"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-skype'}
                                            className="form-control"
                                            value={skype}
                                            maxLength={150}
                                            onChange={(e) => setSkype(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-twitter" className="parrish-label col-3 text-right">{t("Twitter")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-twitter"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-twitter'}
                                            className="form-control"
                                            value={twitter}
                                            maxLength={150}
                                            onChange={(e) => setTwitter(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-facebook" className="parrish-label col-3 text-right">{t("Facebook")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-facebook-f"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-facebook'}
                                            className="form-control"
                                            value={facebook}
                                            maxLength={150}
                                            onChange={(e) => setFacebook(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <h5 className="text-center">DATOS DE CONTACTO</h5>
                            <div className="form-group row">
                                <label htmlFor="value-email" className={"parrish-label col-3 text-right"+(personaFisica?" required":"")}>{t("Email")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-envelope"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-email'}
                                            className="parrish-input"
                                            value={email}
                                            maxLength={150}
                                            onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-telefono" className="parrish-label col-3 text-right">{t("Tel. Fijos")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-phone"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-telefono'}
                                            className="form-control"
                                            value={telefonosFijos}
                                            maxLength={150}
                                            onChange={(e) => setTelefonosFijos(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-celular" className="parrish-label col-3 text-right">{t("Tel. Móviles")}</label>
                                <div className="col-8">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fab fa-whatsapp"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-celular'}
                                            className="form-control"
                                            value={telefonosMoviles}
                                            maxLength={150}
                                            onChange={(e) => setTelefonosMoviles(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                            <h5 className="text-center mt-4">DOMICILIO</h5>
                            <div className="form-group row">
                                <label htmlFor="value-domicilio" className="parrish-label col-3 text-right">{t("Domicilio")}</label>
                                <div className="col-8">
                                <input autoComplete="off"
                                    type="text"
                                    name={'value-domicilio'}
                                    className="parrish-input"
                                    value={domicilio}
                                    maxLength={150}
                                    onChange={(e) => setDomicilio(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-localidad" className="parrish-label col-3 text-right">{t("Localidad")}</label>
                                <div className="col-8">
                                <input autoComplete="off"
                                    type="text"
                                    name={'value-localidad'}
                                    className="parrish-input"
                                    value={localidad}
                                    maxLength={150}
                                    onChange={(e) => setLocalidad(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-pais" className="parrish-label col-3 text-right">{t("País")}</label>
                                <div className="col-8">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-pais'}
                                    value={pais}
                                    onChange={(e) => {
                                        setPais(e.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-provincia" className="parrish-label col-3 text-right">{t("Provincia")}</label>
                                <div className="col-8">
                                <SelectKeyValue
                                    entity="Provincia"
                                    by="PaisNombre"
                                    label="nombre"
                                    name={'value-provincia'}
                                    value={provincia}
                                    filterValue={pais}
                                    filterName="idPais"
                                    onChange={(e) => {
                                        setProvincia(e.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-cp" className="parrish-label col-3 text-right">{t("Código Postal")}</label>
                                <div className="col-8">
                                <input autoComplete="off"
                                    type="text"
                                    name={'value-cp'}
                                    className="parrish-input"
                                    value={codigoPostal}
                                    maxLength={150}
                                    onChange={(e) => setCodigoPostal(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
    )
}

export default ContactoEdit