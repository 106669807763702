import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardFilter from '../../../../components/CardFilter';
import CardDatatable from '../../../../components/CardDatatable';
import ContactoAvatarImg from '../../../../components/ContactoAvatarImg';
import FlagsEmpresa from '../../../../components/FlagsEmpresa';
import AuthContext from '../../../../context/AuthContext';

const EMPRESA_SEARCH = gql`
    query findEmpresaPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $industria: Int
        $cliente: Boolean
        $alias: String
        $pais: Int
        $provincia: Int
        $localidad: String
    ) {
    findEmpresaPageByFilter(
        filter: {
            industria: $industria
            cliente: $cliente
            alias: $alias
            pais: $pais
            provincia: $provincia
            localidad: $localidad
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                cliente
                direccionFiscalDomicilio
                direccionFiscalLocalidad
                dominioEmails
                tieneProyectoActivo
                cliente
                contacto{
                    id
                    personaFisica
                    nombre
                    alias
                    domicilio
                    localidad
                    foto
                }
                industria{
                    nombre
                }
                adjuntoList{
                    referencia
                    fechaActualizacion
                    contentType
                    nombreOriginal
                    nombreServidor
                    responsable{
                        contacto{
                            alias
                        }
                    }
                }
            }
        }
    }
}
`;

function EmpresaList(props){
    const {searchFilter, setSearchFilter} = props;
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "contacto.alias",
        sortDirection: "ASC"
    });
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?pageSizeDefaultParam:10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    useEffect(() => {
        if(!searchFilter.pais){
            const updatedSearchFilter = {
                ...searchFilter
            };
            updatedSearchFilter.provincia = "";       
            setSearchFilter(updatedSearchFilter)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter.pais]);

    const {loading, error, data} = useQuery(
        EMPRESA_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                industria: searchFilter?.industria?searchFilter?.industria:null,
                cliente: searchFilter?.cliente?searchFilter?.cliente:null,
                alias: searchFilter?.alias?searchFilter?.alias:null,
                pais: searchFilter?.pais?parseInt(searchFilter?.pais):null,
                provincia: searchFilter?.provincia?parseInt(searchFilter?.provincia):null,
                localidad: searchFilter?.localidad?searchFilter?.localidad:null
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="empresa"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "alias",
                        type: "text",
                    },
                    {
                        name: "industria",
                        type: "select",
                        entity:"Industria",
                        label: "nombre"
                    },
                    {
                        name: "localidad",
                        type: "text",
                    },
                    {
                        name: "pais",
                        translationKey: "empresa.field.paisProvincia",
                        type: "selectKeyValue",
                        entity:"Pais",
                        componentClassName: "col-sm-4 col-xs-12",
                        labelClassName: "col-6",
                        inputClassName: "col-6"
                    },
                    {
                        type: "selectKeyValue",
                        name: "provincia",
                        entity:"Provincia",
                        by:"PaisNombre",
                        filterValue:searchFilter.pais,
                        filterName:"idPais",
                        componentClassName: "col-2",
                        labelClassName: "d-none",
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findEmpresaPageByFilter"
                entityName="empresa"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/empresa/edit/"
                createUrl="/empresa/create/"
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id",
                        className: "text-center"
                    },
                    {
                        name: "empresa",
                        customSortKey: "contacto.alias",
                        translationKey: "empresa.label.entity",
                        className: "col-2",
                        render: (element) =>{
                            return(
                                <>
                                    <div className="row">
                                        <div className="col d-flex justify-content-center">
                                            <div className="text-center d-flex align-items-center">
                                                <FlagsEmpresa empresa={element.node}/>
                                            </div>
                                            <div className="text-center">
                                                <ContactoAvatarImg personaFisica={element.node.contacto.personaFisica} id={element.node.contacto.id} foto={element.node.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center mt-1">
                                            <p>{element.node.contacto.alias}</p>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    },
                    {
                        name: "industria",
                        customSortKey: "industria.nombre",
                        type: "text",
                        render: (element) =>{
                            return(<p>{element.node.industria.nombre}</p>)
                        }
                    },
                    {
                        name: "dominioEmails",
                        type: "text",
                        disableSort: true
                    },
                    {
                        name: "domicilio",
                        type: "text",
                        disableSort: true,
                        render: (element) =>{
                            return(<p>{element.node.contacto.domicilio}</p>)
                        }
                    },
                    {
                        name: "localidad",
                        disableSort: true,
                        type: "text",
                        render: (element) =>{
                            return(<p>{element.node.contacto.localidad}</p>)
                        }
                    }
                ]}>
            </CardDatatable>
        </>
    );
}

export default EmpresaList;