import React, { useEffect, useState } from 'react'
import CardFilter from '../../../../components/CardFilter';
import ComprobanteList from "./comprobanteList"
import MovimientoList from "./movimientoList"
import { useTranslation } from "react-i18next"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import CurrencyFormat from '../../../../components/CurrencyFormat';

const MOVIMIENTO_ASOCIADOS_BY_COMPROBANTE = gql`
    query findMovimientoAsociadosListByComprobanteList(
        $comprobanteList: [Int!]!
    ) {
        findMovimientoAsociadosListByComprobanteList(
            comprobanteList: $comprobanteList
    ) {
            id
        }
    }
`;

const ASOCIAR_COMPROBANTE_LIST_AL_MOVIMIENTO = gql`
    mutation asociarComprobanteListAlMovimiento(
        $idMovimiento: Int!
        $comprobanteList: [Int!]!
    ) {
        asociarComprobanteListAlMovimiento(
            idMovimiento: $idMovimiento
            comprobanteList: $comprobanteList
    ) {
            id
        }
    }
`;

const DESASOCIAR_COMPROBANTE_LIST_AL_MOVIMIENTO = gql`
    mutation desasociarComprobanteListDelMovimiento(
        $idMovimiento: Int!
        $comprobanteList: [Int!]!
    ) {
        desasociarComprobanteListDelMovimiento(
            idMovimiento: $idMovimiento
            comprobanteList: $comprobanteList
    ) {
            id
        }
    }
`;

const ASOCIAR_MOVIMIENTO_LIST_AL_COMPROBANTE = gql`
    mutation asociarMovimientoListAlComprobante(
        $idComprobante: Int!
        $movimientoList: [Int!]!
    ) {
        asociarMovimientoListAlComprobante(
            idComprobante: $idComprobante
            movimientoList: $movimientoList
        )
    }
`;


const DESASOCIAR_MOVIMIENTO_LIST_DEL_COMPROBANTE = gql`
    mutation desasociarMovimientoListDelComprobante(
        $idComprobante: Int!
        $movimientoList: [Int!]!
    ) {
        desasociarMovimientoListDelComprobante(
            idComprobante: $idComprobante
            movimientoList: $movimientoList
        )
    }
`;

const CONCILIAR_COMPROBANTES = gql`
    mutation conciliarComprobantes(
        $comprobanteList: [Int!]!
        $movimientoList: [Int!]!
    ) {
    conciliarComprobantes(
        comprobanteList: $comprobanteList
        movimientoList: $movimientoList
    )
}
`;

function ConciliacionList(props){
    const {
        searchFilter,
        setSearchFilter
    } = props;

    const [t] = useTranslation("global");

    const [totalComprobantesSel, setTotalComprobantesSel] = useState(0)
    const [totalMovimientosSel, setTotalMovimientosSel] = useState(0)

    const [comprobantesSelId, setComprobantesSelId] = useState([])
    const [movimientosSelId, setMovimientosSelId] = useState([])

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setComprobantesSelId([]);
        setMovimientosSelId([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter])

    const simboloMonedaConciliacion = "AR$ ";

    const [getMovimientosAsociadosByComprobanteQuery] = useLazyQuery(MOVIMIENTO_ASOCIADOS_BY_COMPROBANTE, {
        onCompleted: (data) => {
            setMovimientosSelId([...new Set(data?.findMovimientoAsociadosListByComprobanteList.map(element=>parseInt(element.id)))]);
        }
    });    

    const getMovimientosAsociadosByComprobante = (comprobantes) => {
        getMovimientosAsociadosByComprobanteQuery({ 
            variables: {
                comprobanteList: comprobantes
            } 
        });
    };

    const [asociarComprobanteQuery] = useMutation(ASOCIAR_COMPROBANTE_LIST_AL_MOVIMIENTO, {
        onCompleted: (data) => {
            console.log("asociarComprobante:", data);
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const [desasociarComprobanteQuery] = useMutation(DESASOCIAR_COMPROBANTE_LIST_AL_MOVIMIENTO, {
        onCompleted: (data) => {           
            console.log("desasociarComprobante:", data);
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const [asociarMovimientoListAlComprobanteMutation] = useMutation(ASOCIAR_MOVIMIENTO_LIST_AL_COMPROBANTE, {
        onCompleted: (result) => {
            console.log("asociarMovimientoListAlComprobanteMutation:", result);
            if(result){
                getMovimientosAsociadosByComprobante(comprobantesSelId);
            }
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const asociarMovimientoListAlComprobante = (idComprobante, movimientos) => {
        asociarMovimientoListAlComprobanteMutation({ 
            variables: {
                idComprobante: parseInt(idComprobante),
                movimientoList: movimientos
            } 
        });
    };

    const [desasociarMovimientoListDelComprobanteMutation] = useMutation(DESASOCIAR_MOVIMIENTO_LIST_DEL_COMPROBANTE, {
        onCompleted: (result) => {           
            console.log("desasociarMovimientoListDelComprobante:", result);
            if(result){
                getMovimientosAsociadosByComprobante(comprobantesSelId);
            }
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const desasociarMovimientoListDelComprobante = (idComprobante, movimientos) => {
        desasociarMovimientoListDelComprobanteMutation({ 
            variables: {
                idComprobante: parseInt(idComprobante),
                movimientoList: movimientos
            } 
        });
    };

    const [conciliarComprobantesMutation] = useMutation(CONCILIAR_COMPROBANTES, {
        onCompleted: (data) => {
            setComprobantesSelId([]);
            setMovimientosSelId([]);
            setRefresh(true);
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    useEffect(() => {
        if(refresh){
            setRefresh(false);
        }
    }, [refresh]);

    const conciliarComprobantes = () => {
        conciliarComprobantesMutation({ 
            variables: {
                comprobanteList:  comprobantesSelId.map((value)=>{return parseInt(value)}),
                movimientoList: movimientosSelId.map((value)=>{return parseInt(value)})
            } 
        });
    };

    const asociarComprobante = (idMovimiento, comprobanteList) => {
        asociarComprobanteQuery({ 
            variables: {
                idMovimiento: idMovimiento,
                comprobanteList: comprobanteList
            } 
        });
    };

    const desasociarComprobante = (idMovimiento, comprobanteList) => {
        desasociarComprobanteQuery({ 
            variables: {
                idMovimiento: idMovimiento,
                comprobanteList: comprobanteList
            } 
        });
    };
    
    const toggleComprobanteId = (id) => {
        if(comprobantesSelId.includes(parseInt(id))){
            const comprobantesSeleccionados = comprobantesSelId.filter(element=>element!==parseInt(id));
            setComprobantesSelId(comprobantesSeleccionados);
            desasociarMovimientoListDelComprobante(parseInt(id), movimientosSelId);
            //getMovimientosAsociadosByComprobante(comprobantesSeleccionados);
        }else{
            const comprobantesSeleccionados = comprobantesSelId.concat(parseInt(id))
            setComprobantesSelId(comprobantesSeleccionados);
            asociarMovimientoListAlComprobante(parseInt(id), movimientosSelId);
            //getMovimientosAsociadosByComprobante(comprobantesSeleccionados);
        }
    }

    const [totalComp, setTotalComp] = useState(0);
    const [totalMov, setTotalMov] = useState(0);

    useEffect(() => {
        if(totalComprobantesSel){
            setTotalComp(totalComprobantesSel.filter(comprobante =>comprobantesSelId.includes(parseInt(comprobante.id))).reduce((total, currVal) => {           
                return total + currVal.cbteImporteFinal*currVal.cotizacionPeso*currVal.cbteImpacto
            }, 0));
        }
        if(totalMovimientosSel){
            setTotalMov(totalMovimientosSel.filter(movimiento => movimientosSelId.includes(parseInt(movimiento.id))).reduce((total, currVal) => {
                return total + currVal.importe*currVal.cotizacionPeso*currVal.impacto
            }, 0));
        }
    }, [comprobantesSelId, movimientosSelId, totalComprobantesSel, totalMovimientosSel])


    const diferenciaSeleccionados = totalComp-totalMov;

    const toggleMovimientoId = (id) => {
        const comprobantesSeleccionados = comprobantesSelId.map((element)=>{ return parseInt(element) });

        if(movimientosSelId.includes(parseInt(id))){
            const movimientosSeleccionados = movimientosSelId.filter(element=>element!==parseInt(id));
            setMovimientosSelId(movimientosSeleccionados);
            desasociarComprobante(parseInt(id), comprobantesSeleccionados);
        }else{
            const movimientosSeleccionados = movimientosSelId.concat(parseInt(id));
            setMovimientosSelId(movimientosSeleccionados);
            asociarComprobante(parseInt(id), comprobantesSeleccionados);
        }
    }

    return (
        <>
            <CardFilter
                entityName="conciliacion"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "autocomplete",
                        name: "cliente",
                        entity:"EmpresaCliente",
                        by: "Alias",
                        filterName: "alias"
                    }
                ]}>
            </CardFilter>

            <div className={"card card-outline card-info"}>
                <div className="card-header pl-3 pt-2 pb-2">
                    <h3 className="card-title">{t("conciliacion.label.menu")}</h3>
                    <div className="card-tools m-0">
                        <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{conciliarComprobantes()}} disabled={diferenciaSeleccionados!==0||!totalMov||!totalComp}>
                            Conciliar
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-center">
                        <div className="small-box text-center bg-white fit-content mr-3">
                            <div className="inner">
                                <h5>{t("conciliacion.label.totalComprobantesSeleccionados")}</h5>
                                <h2>
                                    <CurrencyFormat
                                        additionalClassName="justify-content-center"
                                        prefix={simboloMonedaConciliacion}
                                        value={totalComp}/>
                                </h2>
                            </div>       
                        </div>
                        <div className="small-box text-center bg-white fit-content mr-3">
                            <div className="inner">
                                <h5>{t("conciliacion.label.totalMovimientosSeleccionados")}</h5>
                                <h2>
                                    <CurrencyFormat
                                        additionalClassName="justify-content-center"
                                        prefix={simboloMonedaConciliacion}
                                        value={totalMov}/>
                                </h2>
                            </div>       
                        </div>
                        <div className="small-box text-center bg-white fit-content mr-3">
                            <div className="inner">
                                <h5>{t("conciliacion.label.diferenciaSeleccionados")}</h5>
                                <h2>
                                    <CurrencyFormat
                                        additionalClassName="justify-content-center"
                                        prefix={simboloMonedaConciliacion}
                                        value={diferenciaSeleccionados}
                                        className={(diferenciaSeleccionados===0?" text-success":" text-danger")}/>
                                </h2>
                            </div>       
                        </div>
                    </div>
                    {!refresh&&
                        <>
                            <ComprobanteList
                                searchFilter={searchFilter}
                                comprobantesSelId={comprobantesSelId}
                                toggleComprobanteId={toggleComprobanteId}
                                setTotalComprobantesSel={setTotalComprobantesSel}
                                />
                            <MovimientoList
                                searchFilter={searchFilter}
                                comprobantesSelId={comprobantesSelId}
                                movimientosSelId={movimientosSelId}
                                toggleMovimientoId={toggleMovimientoId}
                                setTotalMovimientosSel={setTotalMovimientosSel}
                                />
                        </>
                    }
                    
                </div>
            </div>
        </>
    );
}

export default ConciliacionList;