import { gql, useQuery } from '@apollo/client';

const HAS_PERMISO_ESPECIAL = gql`
    query hasPermisoEspecial($idPermiso: Int!){
        hasPermisoEspecial(idPermiso: $idPermiso)
    }
`;

export default function SecuredSection({ children, idPermiso }) {
    const {loading, error, data} = useQuery(HAS_PERMISO_ESPECIAL, {
        variables: {
            idPermiso: idPermiso
        }
    });

    return (!loading&&!error&&data?.hasPermisoEspecial&&
        children
    )
}
