import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardFilter from '../../../../components/CardFilter'
import PageHeader from '../../../../components/PageHeader';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import { useTranslation } from 'react-i18next';
import { DateRfc }  from '../../DateRfc';

const REPORTE_IVA_VENTAS_BY_ANIO = gql`
    query reporteIvaVentasByAnio(
        $anio: Int!
    ) {
    reporteIvaVentasByAnio(
        anio: $anio
    ){
        mes
        facturacion
        ivaDebito
        ivaCredito
    }
}
`;

export default function ReporteIvaVentasList() {
    const [t] = useTranslation("global");
    const currentYear = new DateRfc().getFullYear();
    const [searchFilter, setSearchFilter] = useState({
        anio: currentYear
    });

    const {data} = useQuery(
        REPORTE_IVA_VENTAS_BY_ANIO,
        {
            variables: {
                anio: parseInt(searchFilter?.anio)
            }
        }
    );

    const yearsOpt = (() => {
        let years = [];
        const firstYear = parseInt(currentYear)-6;
        for (let i = 0; i < 8; i++) {
            years.push(firstYear+i)
        }
        return years.map(element=>{
            return({id:element, label:element});
        });
    });

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName="ivaVentas" />
            <>
                <CardFilter
                    entityName="ivaVentas"
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    onSearchFilter={()=>{
                        setSearchFilter({
                            anio: currentYear
                        });
                    }}
                    filters={[
                        {
                            type: "options",
                            name: "anio",
                            emptyAllow: false,
                            options: yearsOpt()
                        }
                    ]}>
                </CardFilter>
                <div className="card card-outline card-info">
                    <div className="card-header pl-3 pt-2 pb-2">
                        <h3 className="card-title">{t("common.label.listado")}</h3>
                        <div className="card-tools m-0">
                            <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool" onClick={()=>{
                                setSearchFilter({
                                    anio: currentYear
                                });
                            }}>
                                <i className="fas fa-eraser"></i>
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <SimpleDatatable
                            data={data?.reporteIvaVentasByAnio.map(element=>{
                                return({
                                    ...element,
                                    saldo: element.ivaDebito-element.ivaCredito
                                })
                            })}
                            entityName="ivaVentas"
                            columnsDefinition={[
                                {
                                    name: "mes",
                                    type: "text",
                                    render: (element)=>{
                                        return(t("ivaVentas.label.mes."+element.mes))
                                    }
                                },
                                {
                                    name: "facturacion",
                                    type: "money",
                                    className: "text-right",
                                    symbol: "AR$"
                                },
                                {
                                    name: "ivaDebito",
                                    type: "money",
                                    className: "text-right",
                                    symbol: "AR$"
                                },
                                {
                                    name: "ivaCredito",
                                    type: "money",
                                    className: "text-right",
                                    symbol: "AR$"
                                },
                                {
                                    name: "saldo",
                                    type: "money",
                                    className: "text-right",
                                    symbol: "AR$"
                                },
                            ]}/>
                    </div>
                </div>

                
            </>
        </div>
    )
}
