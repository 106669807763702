import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ConocimientoFilter from '../../../../components/ConocimientoFilter';
import DatePicker from '../../../../components/DatePicker';
import MultipleAutocomplete from '../../../../components/MultipleAutocomplete';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import FilterStore from './filterStore';

export default function PostulanteFilter(props) {
    const [t] = useTranslation("global");
    const {searchFilterOriginal, setSearchFilterOriginal, excludeProyecto, className, refetch, displayFilterStore  = false} = props;
    const [searchFilter, setSearchFilter] = useState(searchFilterOriginal);
    const [clearFilter, setClearFilter] = useState(false);

    useEffect(() => {
        setSearchFilter(searchFilterOriginal);
    }, [searchFilterOriginal])

    const clearSearchFilters = () =>{
        const clearedFilter = {
            experienciaPais: "",
            emailFalso: "",
            edadDesde: "",
            edadHasta: "",
            nacionalidad: "",
            paisResidencia: "",
            provinciaList: [],
            genero: "",
            //Experiencia Laboral: 
            fechaExperienciaDesde: null,
            fechaExperienciaHasta: null,
            posicion: "",
            empresaList: [],
            industriaList: [],
            areaEmpresaList: [],
            cargoList: [],
            //Formacion Academica: 
            fechaFormacionDesde: null,
            fechaFormacionHasta: null,
            conocimientoList: [],
            titulo: "",
            institucionList: [],
            nivelEstudioList: [],
            areaEstudioList: [],
            //Informacion de Talenters: 
            proyectoList: [],
            responsable: "",
            tags: "",
            keywords: "",
            destacado: "",
            incubadora: "",
            incubadoraProspecto: "",
            opportunity: "",
            placement: "",
            verificar: "",
            excludeProyecto: excludeProyecto?excludeProyecto:null
        };
        setSearchFilterOriginal(clearedFilter);
        setSearchFilter(clearedFilter);
        setClearFilter(true);
    }

    const updateFilterValue = (name, value) =>{
        const updatedSearchFilter = {
            ...searchFilter
        };
        updatedSearchFilter[name] = value;
        setSearchFilter(updatedSearchFilter);
    }

    const updateOriginalFilterValue = (name, value) =>{
        const updatedSearchFilter = {
            ...searchFilterOriginal
        };
        updatedSearchFilter[name] = value;
        setSearchFilterOriginal(updatedSearchFilter);
        setSearchFilter(updatedSearchFilter);
    }

    const executeSearchFilter = () => {
        setSearchFilterOriginal(searchFilter);
    }

    

    return (
        <div className={className?className:"card card-outline card-info"}>
            <div className="card-header pl-3 pt-2 pb-2">
                <h3 className="card-title">{t("common.label.filtro")}</h3>
                {displayFilterStore?
                <div className="card-tools m-0 w-50">
                    <div className="d-flex justify-content-center align-items-start m-0">
                        <div className="d-flex align-items-center w-100">
                            {displayFilterStore &&
                                <>
                                    <FilterStore filter={searchFilter} setFilter={setSearchFilter} clearFilter={clearFilter} setClearFilter={setClearFilter} onFilterSelect={(filter)=>{
                                        setSearchFilterOriginal(filter);
                                    }} />
                                </>
                            }
                            <div className="text-nowrap ml-4">
                                <button type="button" title={t("common.button.buscar")} className="btn btn-xs btn-info bg-info btn-tool my-1 mr-2" onClick={()=>{
                                    if(refetch){
                                        refetch();
                                    }
                                }}>
                                    <i className="fas fa-search"></i>
                                </button>

                                <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool my-1" onClick={()=>clearSearchFilters()}>
                                    <i className="fas fa-eraser"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card-tools m-0">
                    <button type="button" title={t("common.button.buscar")} className="btn btn-xs btn-info bg-info btn-tool my-1 mr-2" onClick={()=>{
                        if(refetch){
                            refetch();
                        }
                    }}>
                        <i className="fas fa-search"></i>
                    </button>

                    <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool my-1" onClick={()=>clearSearchFilters()}>
                        <i className="fas fa-eraser"></i>
                    </button>
                </div>
                }
            </div>
            <div className="card-body pt-0 pb-0">
                <div className="parrish-form row">
                    <div className="col-xs-12 col-sm-6">

                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h5 className="text-uppercase m-2">{t("postulante.label.keywordsSearch")}</h5>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col">
                                <input autoComplete="off" name="value-keywords" className="form-control form-control-lg" maxLength="150"
                                    value={searchFilter.keywords}
                                    onChange={(e) => updateFilterValue("keywords", e.target.value)}
                                    onBlur={(e) => executeSearchFilter()}
                                    type="text"/>
                            </div>
                            <small className="col-sm-12">Busca por palabras claves en: <b>(1)</b> Comentarios de Seguimiento, <b>(2)</b> Comentarios de TPS Referencias, <b>(3)</b> Resumen de Experiencias Laborales, <b>(4)</b> Appraisal del Postulante, <b>(5)</b> Observaciones del Postulante. <b>IMPORTANTE: </b>Las palabras deberán ser <b>separadas por coma</b>. Ej: 'buena madera,emprendedor,mba'. El sistema devolvera todos aquellos resultados que contengan al menos una de las palabras claves buscadas.</small>
                        </div>

                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h5 className="text-uppercase m-2">{t("postulante.label.filter.datosContacto")}</h5>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-alias" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.nacionalidad")}</label>
                            <div className="col">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-pais'}
                                    required
                                    value={searchFilter.nacionalidad}
                                    onChange={(e) => {
                                        const updatedSearchFilter = {
                                            ...searchFilterOriginal
                                        };
                                        updatedSearchFilter["nacionalidad"] = e.target.value;
                                        setSearchFilterOriginal(updatedSearchFilter);
                                        setSearchFilter(updatedSearchFilter);
                                    }}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group row">
                                    <label htmlFor="value-genero" className="parrish-label col-sm-6 col-xs-12">{t("postulante.field.genero")}</label>
                                    <div className="col">
                                        <select
                                            name="value-genero"
                                            value={searchFilter.genero}
                                            className="parrish-input"
                                            onChange={(e) => updateOriginalFilterValue("genero", e.target.value)}>
                                                <option value=""></option>
                                                <option value={t("common.label.femenino")}>{t("common.label.femenino")}</option>
                                                <option value={t("common.label.masculino")}>{t("common.label.masculino")}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                                <div className="form-group row">
                                    <label htmlFor="value-edadDesde" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.edad")}</label>
                                    <div className="col-sm-3 col-xs-6">
                                        <input autoComplete="off" type="number"
                                            name={'value-edadDesde'}
                                            className="parrish-input form-control form-control-sm"
                                            max={searchFilter.edadHasta}
                                            value={searchFilter.edadDesde}
                                            onChange={e => updateFilterValue("edadDesde", e.target.value)}
                                            onBlur={(e) => executeSearchFilter()}/>
                                    </div>
                                    <div className="col-sm-3 col-xs-6">
                                        <input autoComplete="off" type="number"
                                            name={'value-edadHasta'}
                                            className="parrish-input form-control form-control-sm"
                                            min={searchFilter.edadDesde}
                                            value={searchFilter.edadHasta}
                                            onChange={e => updateFilterValue("edadHasta", e.target.value)}
                                            onBlur={(e) => executeSearchFilter()}/>
                                    </div>
                                </div>
                            </div>    
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-alias" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.paisResidencia")}</label>
                            <div className="col">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-pais'}
                                    required
                                    value={searchFilter.paisResidencia}
                                    onChange={(e) => {
                                        const updatedSearchFilter = {
                                            ...searchFilterOriginal
                                        };
                                        updatedSearchFilter["provinciaList"] = [];
                                        updatedSearchFilter["paisResidencia"] = e.target.value;
                                        setSearchFilterOriginal(updatedSearchFilter);
                                        setSearchFilter(updatedSearchFilter);
                                    }}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-provincia-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.provinciaList")}</label>
                            <div className={searchFilter.paisResidencia?"col":"col d-flex align-items-center"}>
                                <MultipleAutocomplete
                                    name="value-provincia-list"
                                    entity="Provincia"
                                    by="PaisNombre"
                                    filterName="nombre"
                                    additionalFilter={"idPais: "+(searchFilter?.paisResidencia?searchFilter?.paisResidencia:"0")}
                                    disabled={searchFilter.paisResidencia?false:true}
                                    value={searchFilter.provinciaList}
                                    setValue={(value, selectedObj)=> updateOriginalFilterValue("provinciaList", selectedObj) }
                                />
                            </div>
                        </div>

                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h5 className="text-uppercase m-2">{t("postulante.label.filter.informacionTalenters")}</h5>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-tags" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.tags")}</label>
                            <div className="col">
                                <input autoComplete="off" name="value-tags" className="parrish-input" maxLength="150"
                                    value={searchFilter.tags}
                                    onChange={(e) => updateFilterValue("tags", e.target.value)}
                                    onBlur={(e) => executeSearchFilter()}
                                    type="text"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-proyecto-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.proyectoList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-proyectos-list"
                                    entity="Proyecto"
                                    by="Alias"
                                    filterName="alias"
                                    value={searchFilter.proyectoList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("proyectoList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-responsable" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.responsable")}</label>
                            <div className="col">
                                <SelectKeyValue    
                                    entity="Usuario"
                                    label="label"
                                    name="value-responsable"
                                    sortDisable={true}
                                    value={searchFilter.responsable}
                                    onChange={(e) => updateOriginalFilterValue("responsable", e.target.value)}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-xs-12 col-sm-6">

                                <div className="form-group row">
                                    <label htmlFor="value-destacado" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.destacado")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-destacado"
                                                value={searchFilter.destacado}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("destacado", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="value-incubadora" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.incubadora")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa-custom-x1">
                                                        <img src="/icons/incubadora_44_dark.png" alt="logo-icon"/>
                                                    </i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-incubadora"
                                                value={searchFilter.incubadora}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("incubadora", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="value-placement" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.placement")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa-custom-x1">
                                                        <img src="/icons/cliente_24_dark.png" alt="logo-icon"/>
                                                    </i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-placement"
                                                value={searchFilter.placement}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("placement", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="value-emailFalso" className="parrish-label col-sm-6 col-xs-12">{t("postulante.field.emailFalso")}</label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-emailFalso"
                                                value={searchFilter.emailFalso}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("emailFalso", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="value-opportunity" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.oportunidad")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa-custom-x1">
                                                        <img src="/icons/opportunity_24_dark.png" alt="logo-icon"/>
                                                    </i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-opportunity"
                                                value={searchFilter.opportunity}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("opportunity", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="value-incubadora-prospecto" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.incubadoraProspecto")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa-custom-x1">
                                                        <img src="/icons/incubadora_44_dark.png" alt="logo-icon"/>
                                                    </i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-incubadora-prospecto"
                                                value={searchFilter.incubadoraProspecto}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("incubadoraProspecto", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="value-verificar" className="parrish-label col-sm-6 col-xs-12">
                                        {t("postulante.field.verificar")}
                                    </label>
                                    <div className="col">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-exclamation-triangle"></i>
                                                </span>
                                            </div>
                                            <select
                                                name="value-verificar"
                                                value={searchFilter.verificar}
                                                className="parrish-input"
                                                onChange={(e) => updateOriginalFilterValue("verificar", e.target.value)}>
                                                    <option value=""></option>
                                                    <option value={true}>{t("common.value.option.yes")}</option>
                                                    <option value={false}>{t("common.value.option.no")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h5 className="text-uppercase m-2">{t("postulante.label.filter.experienciaLaboral")}</h5>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-experiencia-pais" className="parrish-label col-sm-3 col-xs-12">{t("experienciaLaboral.field.pais")}</label>
                            <div className="col">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-experiencia-pais'}
                                    required
                                    value={searchFilter.experienciaPais}
                                    onChange={(e) => {
                                        const updatedSearchFilter = {
                                            ...searchFilterOriginal
                                        };
                                        updatedSearchFilter["experienciaPais"] = e.target.value;
                                        setSearchFilterOriginal(updatedSearchFilter);
                                        setSearchFilter(updatedSearchFilter);
                                    }}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-periodo-exp-inicio" className="parrish-label col-sm-3 col-xs-12">{t("experienciaLaboral.field.periodo")}</label>
                            <div className="col h-fit-content">
                                <div className="row">
                                    <DatePicker
                                        name={'value-periodo-exp-inicio'}
                                        selected={searchFilter.fechaExperienciaDesde}
                                        onChange={(date) => {
                                            updateOriginalFilterValue("fechaExperienciaDesde", date)
                                        }}
                                        className="col-sm-5 col"
                                        selectsStart
                                        startDate={searchFilter.fechaExperienciaDesde}
                                        endDate={searchFilter.fechaExperienciaHasta} />
                                    <DatePicker
                                        name={'value-periodo-exp-fin'}
                                        selected={searchFilter.fechaExperienciaHasta}
                                        onChange={(date) => {
                                            updateOriginalFilterValue("fechaExperienciaHasta", date)
                                        }}
                                        className="col-sm-5 col"
                                        selectsEnd
                                        startDate={searchFilter.fechaExperienciaDesde}
                                        endDate={searchFilter.fechaExperienciaHasta}
                                        minDate={searchFilter.fechaExperienciaDesde} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-empresa-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.empresaList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-empresa-list"
                                    entity="Empresa"
                                    by="Alias"
                                    filterName="alias"
                                    value={searchFilter.empresaList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("empresaList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-industria-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.industriaList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-industria-list"
                                    entity="Industria"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.industriaList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("industriaList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-area-empresa-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.areaEmpresaList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-area-empresa-list"
                                    entity="AreaEmpresa"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.areaEmpresaList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("areaEmpresaList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-cargo-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.cargoList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-cargo-list"
                                    entity="Cargo"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.cargoList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("cargoList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-posicion" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.posicion")}</label>
                            <div className="col">
                                <input autoComplete="off" name="value-posicion" className="parrish-input" maxLength="150"
                                    value={searchFilter.posicion}
                                    onChange={(e) => updateOriginalFilterValue("posicion", e.target.value)}
                                    onBlur={(e) => executeSearchFilter()}
                                    type="text"/>
                            </div>
                        </div>

                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h5 className="text-uppercase m-2">{t("postulante.label.filter.formacionAcademica")}</h5>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-formacion-pais" className="parrish-label col-sm-3 col-xs-12">{t("experienciaLaboral.field.pais")}</label>
                            <div className="col">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-formacion-pais'}
                                    required
                                    value={searchFilter.formacionPais}
                                    onChange={(e) => {
                                        const updatedSearchFilter = {
                                            ...searchFilterOriginal
                                        };
                                        updatedSearchFilter["formacionPais"] = e.target.value;
                                        setSearchFilterOriginal(updatedSearchFilter);
                                        setSearchFilter(updatedSearchFilter);
                                    }}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.periodoFormacion")}</label>
                            <div className="col h-fit-content">
                                <div className="row">
                                    <DatePicker
                                        name={'value-periodo-inicio'}
                                        selected={searchFilter.fechaFormacionDesde}
                                        onChange={(date) => {
                                            updateOriginalFilterValue("fechaFormacionDesde", date)
                                        }}
                                        className="col-sm-5 col"
                                        selectsStart
                                        startDate={searchFilter.fechaFormacionDesde}
                                        endDate={searchFilter.fechaFormacionHasta} />
                                    <DatePicker
                                        name={'value-periodo-fin'}
                                        selected={searchFilter.fechaFormacionHasta}
                                        onChange={(date) => {
                                            updateOriginalFilterValue("fechaFormacionHasta", date)
                                        }}
                                        className="col-sm-5 col"
                                        selectsEnd
                                        startDate={searchFilter.fechaFormacionDesde}
                                        endDate={searchFilter.fechaFormacionHasta}
                                        minDate={searchFilter.fechaFormacionDesde} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-institucion-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.institucionList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-institucion-list"
                                    entity="Institucion"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.institucionList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("institucionList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-area-estudio-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.areaEstudioList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-area-estudio-list"
                                    entity="AreaEstudio"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.areaEstudioList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("areaEstudioList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-nivel-estudio-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.nivelEstudioList")}</label>
                            <div className="col">
                                <MultipleAutocomplete
                                    name="value-nivel-estudio-list"
                                    entity="NivelEstudio"
                                    by="Nombre"
                                    filterName="nombre"
                                    value={searchFilter.nivelEstudioList}
                                    setValue={(value, selectedObj) => updateOriginalFilterValue("nivelEstudioList", selectedObj)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-titulo" className="parrish-label col-sm-3 col-xs-12">{t("formacionAcademica.field.titulo")}</label>
                            <div className="col">
                                <input autoComplete="off" name="value-titulo" className="parrish-input" maxLength="150"
                                    value={searchFilter.titulo}
                                    onChange={(e) => updateFilterValue("titulo", e.target.value)}
                                    onBlur={(e) => executeSearchFilter()}
                                    type="text"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="value-conocimiento-list" className="parrish-label col-sm-3 col-xs-12">{t("postulante.field.conocimientoList")}</label>
                            <div className="col-sm-8 col-xs-12">
                                <ConocimientoFilter setConocimientoListFunction={(value)=>{
                                    updateOriginalFilterValue("conocimientoList", value)
                                }}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
