import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import DatePicker from '../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import moment from 'moment';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import ESTADO_ADMINISTRATIVO from '../../../../enums/EstadoAdministrativo'
import SelectEntity from '../../../../components/SelectEntity';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import EstadoMovimiento from'../estados/EstadoMovimiento';
import EstadoComprobante from'../estados/EstadoComprobante';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import TipoComprobanteHelper from '../../../../helpers/TipoComprobanteHelper';
import useCotizacionHelper from '../../../../helpers/useCotizacionHelper';
import MONEDA from '../../../../enums/Moneda';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import AuthContext from "../../../../context/AuthContext.js";
import { DateRfc }  from '../../DateRfc';

const COMPROBANTE_SEARCH_BY_ID = gql`
    query findComprobanteById(
        $id: Int!
    ) {
    findComprobanteById(
        id: $id
    ){
        id
        cbteFechaEmision
        cbteFechaVencimiento
        fechaAprobacion
        fechaConciliacion
        fechaEmision
        fechaRegistro
        cbteImporteFinal
        cbteImporteIva
        cbteImporteNeto
        cbteImpacto
        cotizacionDolar
        cotizacionPeso
        adjunto
        cbteDetalle
        cbteNro
        registradoPor{
            contacto{
                alias
            }
        }
        aprobadoPor{
            contacto{
                alias
            }
        }
        emitidoPor{
            contacto{
                alias
            }
        }
        proyecto{
            id
            ref
        }
        cbteTipo{
            id
            letra
            nemotecnico
            esNotaCredito
            esNotaDebito
        }
        cbteMoneda{
            id
            simbolo
        }
        estado{
            id
            nombre
            color
        }
        referencia{
            id
            cbteNro
            cbteTipo{
                nemotecnico
            }
        }
        cliente {
            id
            cuit
            observacionesFacturacion
            contacto{
                alias
            }
        }
        comprobantesRelacionadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaRegistro
            registradoPor {
                contacto {
                alias
                }
            }
            fechaAprobacion
            aprobadoPor {
                contacto {
                alias
                }
            }
            fechaEmision
            emitidoPor {
                contacto {
                alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                alias
                }
            }
            proyecto {
                id
                ref
            }
            cbteFechaEmision
            cbteFechaVencimiento
            cbteTipo {
                nemotecnico
            }
            cbteNro
            cbteDetalle
            cbteMoneda {
                id
                simbolo
            }
            cbteImporteFinal
            cbteImporteIva
            cbteImporteNeto
            cbteImpacto
            cotizacionPeso
        }
        movimientosAsociadosList{
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaAcreditacion
            fechaRegistro
            registradoPor {
                contacto {
                    alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                    alias
                }
            }
            fechaCancelacion
            canceladoPor {
                contacto {
                    alias
                }
            }
            fechaCobranza
            cobradoPor {
                contacto {
                    alias
                }
            }
            cliente {
                id
                contacto{
                    alias
                }
            }
            moneda {
                id
                simbolo
            }
            formaPago{
                nombre
            }
            retencion{
                nombre
            }
            cuenta{
                nombre
                banco{
                    nombre
                }
                tipoCuenta{
                    nombre
                }
                moneda{
                    simbolo
                }
            }
            impacto
            importe
            cotizacionPeso
            detalle
            detalleCbtesAsociados
            chequeBanco{
                nombre
            }
            chequeFecha
            chequeNro
            chequeTitular
        }
    }
}
`;

const UPDATE_COMPROBANTE_ADJUNTO_MUTATION = gql`
  mutation uploadComprobanteAdjunto(
    $id: Int!
    $file: Upload!
  ) {
    uploadComprobanteAdjunto(
        id: $id
        file: $file
    ) {
        id
    }
  }
`;

const DELETE_COMPROBANTE_ADJUNTO_MUTATION = gql`
  mutation deleteComprobanteAdjunto(
    $filename: String!
  ) {
    deleteComprobanteAdjunto(
        filename: $filename
    ) {
        id
    }
  }
`;

const UPDATE_EMPRESA_DATOS_ADMINISTRATIVOS = gql`
  mutation updateEmpresaDatosAdministrativos(
    $idEmpresa: Int!
    $cuit: String
    $observacionesFacturacion: String
  ) {
    updateEmpresaDatosAdministrativos(
        idEmpresa: $idEmpresa
        cuit: $cuit
        observacionesFacturacion: $observacionesFacturacion
    ) {
        id
    }
  }
`;

const EMITIR_COMPROBANTE_MUTATION = gql`
    mutation emitirComprobante(
        $id: ID!
        $cbteTipo: Int!
        $cbteFechaEmision: LocalDate!
        $cbteFechaVencimiento: LocalDate!
        $cotizacionDolar: Float!
        $cotizacionPeso: Float!
        $cbteDetalle: String!
        $cbteNro: String!
    ) {
        emitirComprobante(
            entity:{
                id: $id
                cbteTipo: $cbteTipo
                cbteFechaEmision: $cbteFechaEmision
                cbteFechaVencimiento: $cbteFechaVencimiento
                cotizacionDolar: $cotizacionDolar
                cotizacionPeso: $cotizacionPeso
                cbteDetalle: $cbteDetalle
                cbteNro: $cbteNro
            }
        ) {
            id
        }
    }
`;

function FacturaEdit() {
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const fileMaxSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.fwk.config.max.file.size"})?.valor;


    let { showConfirmDelete, showErrorToast, clearPopUps } = useAlertMessage();
    const history = useHistory();
    let { id } = useParams();

    const ref = useRef();

    const [cbteFechaEmision, setCbteFechaEmision] = useState("");
    const [cbteFechaVencimiento, setCbteFechaVencimiento] = useState("");

    const [cbteTipo, setCbteTipo] = useState("");
    const [cbteDetalle, setCbteDetalle] = useState("");
    const [cbteNro, setCbteNro] = useState("")

    const [cotizacionPeso, setCotizacionPeso] = useState("");
    const [cotizacionDolar, setCotizacionDolar] = useState("");

    const [cbteImporteNeto, setCbteImporteNeto] = useState("");
    const [cbteImporteIva, setCbteImporteIva] = useState("");
    const [cbteImporteFinal, setCbteImporteFinal] = useState("");

    const [clienteCuit, setClienteCuit] = useState("");
    const [clienteObservaciones, setClienteObservaciones] = useState("");

    const cotizacionHelper = useCotizacionHelper({
        setCotizacionPeso: (cotizacion)=>{
            setCotizacionPeso(cotizacion);
        },
        setCotizacionDolar: (cotizacion)=>{
            setCotizacionDolar(cotizacion);
        }
    });

    const fileInput = React.createRef();
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data, refetch } = useQuery(
        COMPROBANTE_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const codigoMonedaTotales="AR$ ";

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type:mimeString});
    }

    const updateEmpresaDatosAdministrativos = () => {
        updateEmpresaDatosAdministrativosMutation({variables: {idEmpresa: data?.findComprobanteById?.cliente.id, cuit: clienteCuit, observacionesFacturacion: clienteObservaciones}})
    }

    const [updateEmpresaDatosAdministrativosMutation] = useMutation(UPDATE_EMPRESA_DATOS_ADMINISTRATIVOS,{
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {

        }
    });

    const [updateComprobanteAdjunto] = useMutation(UPDATE_COMPROBANTE_ADJUNTO_MUTATION,{
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {

        }
    });

    const [deleteComprobanteAdjunto] = useMutation(DELETE_COMPROBANTE_ADJUNTO_MUTATION,{
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {

        }
    });

    const deleteAdjuntoAction = () => {
        showConfirmDelete(
            id,
            data?.findComprobanteById?.adjunto,
            (id)=>{deleteComprobanteAdjunto({
                variables:{
                    filename: data?.findComprobanteById?.adjunto
                }
            })}
        );
    }
    
    const handleFileChange = (file) => {
        clearPopUps();
        if(file.size/1024/1024 < (fileMaxSizeDefaultParam?parseInt(fileMaxSizeDefaultParam):20)){
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                console.log("Uploading...")
                const dataFile = dataURItoBlob(reader.result);
                updateComprobanteAdjunto({variables: {id: id, file: dataFile}})
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        }else{
            showErrorToast([{message: t("common.message.size-file-exceed")}]);
        }
    }

    const isEstadoAprobado = () => {
        return parseInt(data?.findComprobanteById?.estado?.id) === ESTADO_ADMINISTRATIVO.APROBADO;
    }

    const [emitirComprobanteMutation] = useMutation(EMITIR_COMPROBANTE_MUTATION,{
        onCompleted: (data) => {
            window.location.reload();
        },
        onError: (error) => {

        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        emitirComprobanteMutation({
            variables: {
                id: parseInt(id),
                cbteTipo: parseInt(cbteTipo),
                cbteFechaEmision: cbteFechaEmision,
                cbteFechaVencimiento: cbteFechaVencimiento,
                cotizacionDolar: cotizacionDolar,
                cotizacionPeso: cotizacionPeso,
                cbteDetalle: cbteDetalle,
                cbteNro: cbteNro
            }
        });
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-cotizacionDolar": "required",
                "value-cotizacionPeso": "required",
                "value-cbteFechaEmision": "required",
                "value-cbteFechaVencimiento": "required",
                "value-cbteTipo": "required",
                "value-cbteNro": "required",
            },
            messages: {
                "value-cotizacionDolar": {
                    required: t("common.message.field.required", {0: t("comprobante.field.cotizacionArs")})
                },
                "value-cotizacionPeso": {
                    required: t("common.message.field.required", {0: t("comprobante.field.cotizacionPeso")})
                },
                "value-cbteFechaEmision": {
                    required: t("common.message.field.required", {0: t("comprobante.field.cbteFechaEmision")})
                },
                "value-cbteFechaVencimiento": {
                    required: t("common.message.field.required", {0: t("comprobante.field.cbteFechaVencimiento")})
                },
                "value-cbteTipo": {
                    required: t("common.message.field.required", {0: t("comprobante.field.comprobante")})
                },
                "value-cbteNro": {
                    required: t("common.message.field.required", {0: t("comprobante.field.comprobante")})
                },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    useEffect(() => {
        if (!loading && !error && data?.findComprobanteById) {
            const comprobante = data?.findComprobanteById;
            setCbteImporteNeto(comprobante.cbteImporteNeto);
            setCbteImporteIva(comprobante.cbteImporteIva);
            setCbteImporteFinal(comprobante.cbteImporteFinal);
        }
    }, [data?.findComprobanteById, error, loading])

    useEffect(() => {
        if (!loading && !error && data?.findComprobanteById) {
            const comprobante = data?.findComprobanteById;
            setClienteCuit(comprobante?.cliente?.cuit);
            setClienteObservaciones(comprobante?.cliente?.observacionesFacturacion);
        }
    }, [data?.findComprobanteById, error, loading])

    

    useEffect(() => {
        if (!loading && !error && data?.findComprobanteById) {
            const comprobante = data?.findComprobanteById;

            setCbteTipo(comprobante.cbteTipo.id ? comprobante.cbteTipo.id : "");
            setCbteDetalle(comprobante.cbteDetalle ? comprobante.cbteDetalle : "");
            setCbteNro(comprobante.cbteNro ? comprobante.cbteNro : "");

            setCotizacionDolar(comprobante.cotizacionDolar ? comprobante.cotizacionDolar : "");
            setCotizacionPeso(comprobante.cotizacionPeso ? comprobante.cotizacionPeso : "");

            if(comprobante.cbteFechaEmision){
                let date = new DateRfc(comprobante.cbteFechaEmision)
                date.setHours(0, 0, 0, 0);
                setCbteFechaEmision(date);
            }
            if(comprobante.cbteFechaVencimiento){
                let date = new DateRfc(comprobante.cbteFechaVencimiento)
                date.setHours(0, 0, 0, 0);
                setCbteFechaVencimiento(date);
            }
        }
        return () => { _isMounted.current = false };
    }, [
        loading,
        error,
        data?.findComprobanteById
    ])

    const CALCULATE_IMPORTE_QUERY = gql`
        query calculateImportesByTipoComprobante(
            $tipoComprobante: Int!
            $importeFinal: Float!
        ) {
        calculateImportesByTipoComprobante(
            tipoComprobante: $tipoComprobante
            importeFinal: $importeFinal
        ){
            importeFinal
            importeIva
            importeNeto
        }
    }
    `;

    const [calculateImporteQuery] = useLazyQuery(CALCULATE_IMPORTE_QUERY, {
        onCompleted: (data) => {
            setCbteImporteIva(data?.calculateImportesByTipoComprobante?.importeIva)
            setCbteImporteNeto(data?.calculateImportesByTipoComprobante?.importeNeto)
        }
    });

    const calculateImporte = (tipoComprobante) => {
        calculateImporteQuery({ variables: {
            tipoComprobante: tipoComprobante,
            importeFinal: data?.findComprobanteById?.cbteImporteFinal
        }})
    };

    const monedaId = data?.findComprobanteById?.cbteMoneda?.id;
    
    let totalComprobantes = data?.findComprobanteById?.comprobantesRelacionadosList?.reduce((total, currVal) => {
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.cbteImporteFinal*cotizacionMoneda*currVal.cbteImpacto;
    }, 0);
    totalComprobantes += data?.findComprobanteById?.cbteImporteFinal*data?.findComprobanteById?.cbteImpacto;

    const totalMovimientos = data?.findComprobanteById?.movimientosAsociadosList?.reduce((total, currVal) => {
        const cotizacionMoneda = (parseInt(monedaId) === MONEDA.PESOS) ? currVal.cotizacionPeso: (parseInt(monedaId) === MONEDA.DOLARES)? currVal.cotizacionDolar:null;
        return total + currVal.importe*cotizacionMoneda*currVal.impacto;
    }, 0);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })} - <span className={"badge badge-"+data?.findComprobanteById?.estado?.color}>{data?.findComprobanteById?.estado?.nombre}</span></h3>
                    <div className="card-tools m-0">
                        {parseInt(data?.findComprobanteById?.estado?.id) === ESTADO_ADMINISTRATIVO.EMITIDO &&
                        <>
                            <label className="btn btn-xs bg-info btn-tool ml-2" forhtml="my-file-selector">
                                <input autoComplete="off" id="my-file-selector" type="file" accept="application/pdf" className="d-none" onChange={({target: {files: [file]}}) => handleFileChange(file)} ref={fileInput}/>
                                <span className="font-weight-normal">
                                    <i className="fas fa-file-upload mr-2"></i>
                                    Upload Attachment
                                </span>
                            </label>
                            {/*<DeleteEntityButton />*/}
                        </>
                        }
                        
                        {data?.findComprobanteById?.adjunto&&
                            <>
                                <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{deleteAdjuntoAction()}}>
                                    <i className="fas fa-trash"></i>
                                    Delete Attachment
                                </button>
                                <DownloadAdjunto filename={data?.findComprobanteById?.adjunto} entity="Comprobante" type="tool"/>
                            </> 
                        }
                        {parseInt(data?.findComprobanteById?.estado?.id) === ESTADO_ADMINISTRATIVO.APROBADO &&
                            <button type="submit" title={t("facturacion.button.emitir")} className="btn btn-xs bg-info btn-tool ml-2">
                                {t("facturacion.button.emitir")}
                            </button>
                        }
                        {parseInt(data?.findComprobanteById?.estado?.id) === ESTADO_ADMINISTRATIVO.EMITIDO &&
                            !(data?.findComprobanteById?.cbteTipo?.esNotaCredito || data?.findComprobanteById?.cbteTipo?.esNotaDebito) &&
                            <>
                                <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/facturacion/create/nota-credito/"+id}>
                                    Generar Nota Crédito
                                </PrivateLink>
                                <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/facturacion/create/nota-debito/"+id}>
                                    Generar Nota Débito
                                </PrivateLink>
                            </>
                        }
                        <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={data?.findComprobanteById?.fechaRegistro?new DateRfc(data?.findComprobanteById?.fechaRegistro):null}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.registradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaAprobacion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaAprobacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={data?.findComprobanteById?.fechaAprobacion?new DateRfc(data?.findComprobanteById?.fechaAprobacion):null}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.aprobadoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaEmision" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaEmision")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaEmision" readOnly selected={data?.findComprobanteById?.fechaEmision?new DateRfc(data?.findComprobanteById?.fechaEmision):null} />
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.emitidoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-proyecto" className="parrish-label col-sm-2 col-xs-12">{t("comprobante.field.proyecto")}</label>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.proyecto?("["+data?.findComprobanteById?.proyecto?.id+"] - "+data?.findComprobanteById?.proyecto?.ref):""}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("comprobante.label.datosComprobante")}</h4>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteFechaEmision" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaEmision")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker readOnly={!isEstadoAprobado()} name="value-cbteFechaEmision" selected={cbteFechaEmision} maxDate={new DateRfc()} onChange={date => {
                                        cotizacionHelper.execute(date, data?.findComprobanteById?.cbteMoneda?.id);
                                        setCbteFechaEmision(date);
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteFechaVencimiento" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaVencimiento")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker
                                        readOnly={!isEstadoAprobado()}
                                        name="value-cbteFechaVencimiento"
                                        selected={cbteFechaVencimiento}
                                        minDate={cbteFechaEmision}
                                        onChange={date => {
                                            setCbteFechaVencimiento(date);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteTipo" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.comprobante")}</label>
                                <div className="col-sm-6 col-xs-12">
                                    {(!isEstadoAprobado())?
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.cbteTipo?.nemotecnico}/>
                                    :
                                        <SelectEntity
                                            hideEmptyOption={true}
                                            entity="TipoComprobante"
                                            label="tipo, letra"
                                            displayText={(element)=>{
                                                return(element.tipo+" "+element.letra)
                                            }}
                                            name={'value-cbteTipo'}
                                            value={cbteTipo}
                                            required
                                            onChange={(e) => {
                                                calculateImporte(e.target.value);
                                                setCbteTipo(e.target.value);
                                            }}
                                            disabled={TipoComprobanteHelper.isNotaCreditoDebito(cbteTipo)}
                                            filterNames={TipoComprobanteHelper.isNotaCreditoDebito(cbteTipo)?null:["esNotaCredito", "esNotaDebito"]}
                                            filterTypes={TipoComprobanteHelper.isNotaCreditoDebito(cbteTipo)?null:["Boolean","Boolean"]}
                                            filterValues={TipoComprobanteHelper.isNotaCreditoDebito(cbteTipo)?null:[false, false]}/>
                                    }
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" type="text" name={'value-cbteNro'} disabled={!isEstadoAprobado()} className="parrish-input" value={cbteNro} onChange={e => setCbteNro(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        { data?.findComprobanteById?.referencia &&
                            <div className="col-xs-12 col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="filter-comprobanteReferenciado" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.cbteReferencia")}</label>
                                    <div className="col-sm-3 col-xs-12 d-flex">
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.referencia?.cbteTipo.nemotecnico+" ["+data?.findComprobanteById?.referencia?.cbteNro+"]"}/>
                                        <button disabled={!data?.findComprobanteById?.referencia} className="btn btn-xs bg-info ml-1 mr-1 pl-2 pr-2" onClick={()=>{history.push("/private/comprobante/edit/"+data?.findComprobanteById?.referencia?.id)}}>
                                            <i className="far fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-xs-12 col-sm-8">
                            <div className="form-group row">
                                <label htmlFor="value-comentario-estimacion" className="parrish-label col-sm-3 col-xs-12">{t("comprobante.field.detalle")}</label>
                                <div className="col">
                                    <textarea name="value-comentario-estimacion" className="form-control form-control-sm" rows="3" disabled={!isEstadoAprobado()} value={cbteDetalle} onChange={e => setCbteDetalle(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-moneda" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={data?.findComprobanteById?.cbteMoneda?.simbolo}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-importeNeto" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.importeNeto")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale="true"
                                        value={cbteImporteNeto}
                                        className={"parrish-input text-right"}
                                        disabled
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("comprobante.field.cotizacion", { 0: "U$D", 1: "("+data?.findComprobanteById?.cbteMoneda?.simbolo+")" })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        disabled={!isEstadoAprobado() || (parseInt(data?.findComprobanteById?.cbteMoneda?.id)===MONEDA.DOLARES)}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionDolar(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-importeIva" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.iva")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={cbteImporteIva}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>      
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("comprobante.field.cotizacion", { 0: "AR$", 1: "("+data?.findComprobanteById?.cbteMoneda?.simbolo+")" })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionPeso"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionPeso}
                                        className={"parrish-input text-right"}
                                        disabled={!isEstadoAprobado() || (parseInt(data?.findComprobanteById?.cbteMoneda?.id)===MONEDA.PESOS)}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionPeso(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-cotizacionArs" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.importeTotal")+" ("+(data?.findComprobanteById?.cbteMoneda?.simbolo?data?.findComprobanteById?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={cbteImporteFinal}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase active"
                                id="custom-tabs-info-adicional-tab"
                                data-toggle="pill"
                                href="#custom-tabs-info-adicional"
                                role="tab"
                                aria-controls="custom-tabs-info-adicional"
                                aria-selected="true">
                                {t("comprobante.label.informacionAdicionalYResumenDelComprobante")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase"
                                id="custom-tabs-datos-cliente-tab"
                                data-toggle="pill"
                                href="#custom-tabs-datos-cliente"
                                role="tab"
                                aria-controls="custom-tabs-datos-cliente">
                                {t("comprobante.label.datosCliente")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-info-adicional" role="tabpanel" aria-labelledby="custom-tabs-info-adicional-tab">
                            <div className="d-flex justify-content-center">
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalComprobantes")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                additionalClassName="justify-content-center"
                                                prefix={codigoMonedaTotales}
                                                value={totalComprobantes} />
                                        </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalMovimientos")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                additionalClassName="justify-content-center"
                                                prefix={codigoMonedaTotales}
                                                value={totalMovimientos} />
                                        </h2>
                                    </div>       
                                </div>
                                <div className="small-box text-center bg-white fit-content mr-3">
                                    <div className="inner">
                                        <h5>{t("comprobante.label.totalDiferencia")}</h5>
                                        <h2>
                                            <CurrencyFormat
                                                prefix={codigoMonedaTotales}
                                                value={totalMovimientos}
                                                additionalClassName="justify-content-center"
                                                className={(totalMovimientos-totalComprobantes!==0?"text-danger":"text-success")}/>
                                        </h2>
                                    </div>       
                                </div>
                            </div>
                            <SimpleDatatable
                                data={data?.findComprobanteById?.comprobantesRelacionadosList}
                                editUrl="/private/comprobante/edit/"
                                entityName="comprobante"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="11">
                                            <div>{t("comprobante.label.comprobantesRelacionados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className: "text-center",
                                        render: (element)=>{
                                            return(
                                                <DownloadAdjunto filename={element.adjunto} entity="Comprobante"/>
                                            )
                                        }
                                    },
                                    {
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoComprobante element={element}/>)
                                        }
                                    },
                                    {
                                        name: "proyecto",
                                        render: (element)=>{
                                            return(<p><Link className="text-dark" to={"/proyecto/edit/"+element.proyecto.id}>[{element.proyecto.id}] - {element.proyecto.ref}</Link></p>)
                                        }
                                    },
                                    {
                                        name: "cbteFechaEmision",
                                        type: "date"
                                    },
                                    {
                                        name: "cbteFechaVencimiento",
                                        type: "date"
                                    },
                                    {
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.cbteTipo.nemotecnico+(element.cbteNro?" ["+element.cbteNro+"]":"")}</p>
                                                <p>({element.cbteDetalle})</p>
                                            </>)
                                        }
                                    },
                                    {
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        render: (element)=>{
                                            return(element.cbteMoneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "cbteImporteNeto",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeNeto = element.cbteImporteNeto*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeNeto} />
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteIva",
                                        type: "money",
                                        disableSort: true,
                                        render: (element)=>{
                                            const importeIva = element.cbteImporteIva*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeIva} />
                                            )
                                        }
                                    },
                                    {  
                                        name: "cbteImporteFinal",
                                        type: "money",
                                        render: (element)=>{
                                            const importeFinal = element.cbteImporteFinal*element.cbteImpacto;
                                            return(
                                                <CurrencyFormat value={importeFinal} />
                                            )
                                        }
                                    }
                                ]}
                            />
                            <SimpleDatatable
                                data={data?.findComprobanteById?.movimientosAsociadosList}
                                editUrl="/private/movimiento/edit/"
                                entityName="movimiento"
                                headerTop={
                                    <tr>
                                        <th className="bg-info text-left" colSpan="11">
                                            <div>{t("comprobante.label.movimientosAsociados")}</div>
                                        </th>
                                    </tr>
                                }
                                columnsDefinition={[
                                    {
                                        name: "id",
                                        type: "id"
                                    },
                                    {
                                        
                                        name: "adjunto",
                                        customHeader: (<i className="fas fa-paperclip"></i>),
                                        customClassHeader: "bg-info text-center pl-0 pr-0",
                                        disableSort: true,
                                        className: "text-center",
                                        render: (element)=>{
                                            return(
                                                <DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>
                                            )
                                        }
                                    },
                                    {
                                        
                                        name: "estado",
                                        className:"text-align-center",
                                        render: (element)=>{
                                            return(<EstadoMovimiento element={element}/>)
                                        }
                                    },
                                    {
                                        
                                        name: "cliente",
                                        render: (element)=>{
                                            return(element.cliente.contacto.alias)
                                        }
                                    },
                                    {
                                        name: "fechaAcreditacion",
                                        type: "date"
                                    },
                                    {
                                        
                                        name: "formaDePago",
                                        render: (element)=>{
                                            return(<>
                                                <p>{element.formaPago.nombre}</p>
                                                {element.retencion&&
                                                    <p className="text-bold">[{element.retencion.nombre}]</p>
                                                }
                                                {element.chequeNro&&
                                                    <p className="text-bold">[{element.chequeNro} | {element.chequeTitular} | {moment(element.chequeFecha).format('DD-MM-YYYY')}]</p>
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "cuenta",
                                        render: (element)=>{
                                            return(<>
                                                {
                                                    element.cuenta &&
                                                        element.cuenta?.nombre+" - ["+element.cuenta?.moneda?.simbolo+"]"
                                                }
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "detalle",
                                        render: (element)=>{
                                            return(<>
                                                <p className="text-bold">{element.detalleCbtesAsociados}</p>
                                                <p>{element.detalle?"("+element.detalle+")":""}</p>
                                            </>)
                                        }
                                    },
                                    {
                                        
                                        name: "moneda",
                                        className:"text-align-center",
                                        customHeader: (<></>),
                                        disableSort: true,
                                        render: (element)=>{
                                            return(element.moneda?.simbolo)
                                        }
                                    },
                                    {  
                                        name: "importe",
                                        type: "money",
                                        render: (element)=>{
                                            const importe = element.importe*element.impacto;
                                            return(
                                                <CurrencyFormat value={importe} />
                                            )
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-datos-cliente" role="tabpanel" aria-labelledby="custom-tabs-datos-cliente-tab">
                            <div className="row">
                                <div className="col-xs-12 col-sm-8">
                                    <div className="form-group row">
                                        <label htmlFor="value-cuit" className="col-sm-12 col-xs-12">{t("comprobante.field.clienteForm.cuit")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" name="value-cuit" className="parrish-input" value={clienteCuit} onChange={(e)=>{ setClienteCuit(e.target.value) }} onBlur={()=>{updateEmpresaDatosAdministrativos()}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-observaciones-facturacion" className="col-sm-12 col-xs-12">{t("comprobante.field.clienteForm.observaciones")}</label>
                                        <div className="col-sm-12 col-xs-12">
                                            <textarea name="value-observaciones-facturacion" value={clienteObservaciones} onChange={(e)=>{ setClienteObservaciones(e.target.value) }} onBlur={()=>{updateEmpresaDatosAdministrativos()}} className="form-control form-control-sm" rows="20"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default FacturaEdit