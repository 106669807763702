import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_ESTADOPOSTULANTE_MUTATION = gql`
    mutation createEstadoPostulante(
        $etapaProyecto: Int
        $desiste: Boolean!
        $esFinal: Boolean!
        $esInicial: Boolean!
        $nombre: String!
    ) {
        createEstadoPostulante(entity:{
            etapaProyecto: $etapaProyecto
            desiste: $desiste
            esFinal: $esFinal
            esInicial: $esInicial
            nombre: $nombre
        }) {
            etapaProyecto {
                nombre
            }
            id
            desiste
            esFinal
            esInicial
            nombre
        }
    }
`;

function EstadoPostulanteEdit() {
    const history = useHistory();

    const [etapaProyecto, setEtapaProyecto] = useState('');
    const [desiste, setDesiste] = useState('');
    const [esFinal, setEsFinal] = useState('');
    const [esInicial, setEsInicial] = useState('');
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createEstadoPostulanteMutation] = useMutation(CREATE_ESTADOPOSTULANTE_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/estadoPostulante";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createEstadoPostulanteMutation({
            variables:{
                etapaProyecto: etapaProyecto?parseInt(etapaProyecto):null,
                desiste: desiste,
                esFinal: esFinal,
                esInicial: esInicial,
                nombre: nombre
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardCreateEntity
                entityName="estadoPostulante"
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 60,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.nombre")})
                        }
                    },
                    {
                        name: "etapaProyecto",
                        type: "select",
                        entity: "EtapaProyecto",
                        label: "nombre",
                        value: etapaProyecto,
                        setValue: setEtapaProyecto,
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.etapaProyecto")})
                        }
                    },
                    {
                        name: "desiste",
                        type: "boolean",
                        value: desiste,
                        setValue: setDesiste,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.desiste")})
                        }
                    },
                    {
                        name: "esFinal",
                        type: "boolean",
                        value: esFinal,
                        setValue: setEsFinal,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.esFinal")})
                        }
                    },
                    {
                        name: "esInicial",
                        type: "boolean",
                        value: esInicial,
                        setValue: setEsInicial,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.esInicial")})
                        }
                    },
                ]}
                returnUrl="/estadoPostulante"
                >
            </CardCreateEntity>
        </>
    )
}

export default EstadoPostulanteEdit