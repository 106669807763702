import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import {} from "jquery-validation";
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import DatePicker from '../../../../../../components/DatePicker';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Autocomplete from '../../../../../../components/Autocomplete';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';

const CREATE_EXPERIENCIA_LABORAL_MUTATION = gql`
    mutation createExperienciaLaboral(
        $areaEmpresa: Int!
        $cargo: Int!
        $empresa: Int!
        $pais: Int!
        $postulante: Int!
        $fechaInicio: LocalDate!
        $fechasEstimadas: Boolean!
        $moneda: Int
        $fechaFin: LocalDate
        $sueldoBrutoMensual: Int
        $beneficios: String
        $bonus: String
        $posicion: String
        $resumen: String
    ) {
        createExperienciaLaboral(entity:{
            areaEmpresa: $areaEmpresa
            cargo: $cargo
            empresa: $empresa
            moneda: $moneda
            pais: $pais
            postulante: $postulante
            fechaFin: $fechaFin
            fechaInicio: $fechaInicio
            fechasEstimadas: $fechasEstimadas
            sueldoBrutoMensual: $sueldoBrutoMensual
            beneficios: $beneficios
            bonus: $bonus
            posicion: $posicion
            resumen: $resumen
        }) {
            id
        }
    }
`;

export default function CreateExperienciaLaboral(props) {
    const { setMode } = props;

    const [t] = useTranslation("global");
    const ref = useRef();

    const {id} = useParams();

    const [state, setState] = useState({
        empresa: "",
        areaEmpresa: "",
        cargo: "",
        posicion: "",
        pais: "",
        fechaInicio: "",
        fechaFin: "",
        fechasEstimadas: false,
        moneda: "",
        sueldoBrutoMensual: "",
        resumen: "",
        bonus: "",
        beneficios: ""
    });

    let { showErrorToast } = useAlertMessage();

    const [createExperienciaLaboralMutation] = useMutation(CREATE_EXPERIENCIA_LABORAL_MUTATION, {
        onCompleted: (data) => {
            setMode("list");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        createExperienciaLaboralMutation({
            variables: {
                postulante: parseInt(id),
                areaEmpresa: state.areaEmpresa,
                cargo: state.cargo,
                empresa: state.empresa,
                pais: state.pais,
                fechaInicio: state.fechaInicio,
                fechasEstimadas: state.fechasEstimadas,
                moneda: state.moneda?state.moneda:null,
                fechaFin: state.fechaFin?state.fechaFin:null,
                sueldoBrutoMensual: state.sueldoBrutoMensual?state.sueldoBrutoMensual:null,
                beneficios: state.beneficios?state.beneficios:null,
                bonus: state.bonus?state.bonus:null,
                posicion: state.posicion?state.posicion:null,
                resumen: state.resumen?state.resumen:null,
            }
        });
    }

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-empresa": "required",
                "value-areaEmpresa": "required",
                "value-cargo": "required",
                "value-pais": "required",
                "value-periodo-inicio": "required",
            },
            messages: {
                "value-empresa":  { required: t("common.message.field.required", {0: t("postulante.field.empresa")}) },
                "value-areaEmpresa":  { required: t("common.message.field.required", {0: t("postulante.field.areaEmpresa")}) },
                "value-cargo":  { required: t("common.message.field.required", {0: t("postulante.field.cargo")}) },
                "value-pais":  { required: t("common.message.field.required", {0: t("postulante.field.pais")}) },
                "value-periodo-inicio": { required: t("common.message.field.required", {0: t("postulante.field.periodo")}) },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{setMode("list")}}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    
                    <div className="parrish-form row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-empresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.empresa")}</label>
                                <div className="col">
                                    <Autocomplete
                                        name="value-empresa"
                                        entity="Empresa"
                                        by="Alias"
                                        filterName="alias"
                                        value={state.empresa}
                                        setValue={(val)=>handleChange("empresa", {target:{value:val}})} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-areaEmpresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.areaEmpresa")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="AreaEmpresa"
                                        by="Nombre"
                                        name="value-areaEmpresa"
                                        value={state.areaEmpresa}
                                        required
                                        onChange={(e) => {
                                            handleChange("areaEmpresa", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cargo" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.cargo")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="Cargo"
                                        by="Nombre"
                                        name="value-cargo"
                                        value={state.cargo}
                                        required
                                        onChange={(e) => {
                                            handleChange("cargo", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-posicion" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.posicion")}</label>
                                <div className="col">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-posicion'}
                                        className="parrish-input"
                                        value={state.posicion}
                                        maxLength={150}
                                        onChange={(e) => {
                                            handleChange("posicion", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.periodo")}</label>
                                <div className="col h-fit-content">
                                    <div className="row">
                                        <DatePicker
                                            name={'value-periodo-inicio'}
                                            selected={state.fechaInicio}
                                            onChange={(date) => {
                                                handleChange("fechaInicio", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsStart
                                            startDate={state.fechaInicio}
                                            endDate={state.fechaFin}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker />
                                        <DatePicker
                                            name={'value-periodo-fin'}
                                            selected={state.fechaFin}
                                            onChange={(date) => {
                                                handleChange("fechaFin", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsEnd
                                            startDate={state.fechaInicio}
                                            endDate={state.fechaFin}
                                            minDate={state.fechaInicio}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker />
                                        <div className="d-flex col-sm-4">
                                            <input autoComplete="off"
                                                className="btn m-2"
                                                type="checkbox"
                                                checked={state.fechasEstimadas}
                                                onChange={(val) => {
                                                    handleChange("fechasEstimadas", {target:{value: val.target.checked}})
                                                }} />
                                            <span className="text-nowrap">{t("postulante.field.fechasEstimadas")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.pais")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="Pais"
                                        name={'value-pais'}
                                        value={state.pais}
                                        required
                                        onChange={(e) => {
                                            handleChange("pais", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-sueldo-bruto" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.sueldoBrutoMensual")}</label>
                                <div className="col-sm-8 col-xs-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <SelectKeyValue
                                                entity="Moneda"
                                                name="value-moneda"
                                                value={state.moneda}
                                                required
                                                onChange={(e) => {
                                                    handleChange("moneda", e)
                                                }}/>
                                        </div>

                                        <div className="col-9">
                                            
                                            <NumberFormat
                                                name="value-sueldo-bruto"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                value={state.sueldoBrutoMensual}
                                                className={"parrish-input text-right"}
                                                onChange={(e) => {
                                                    const val = e.target?.value;
                                                    if(val){
                                                        handleChange("sueldoBrutoMensual", {target:{value:parseInt(val.replaceAll(".","").replaceAll(",","."))}});
                                                    }
                                                }}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="parrish-form row">

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.resumen")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-resumen"
                                        value={state.resumen}
                                        onChange={(e)=>handleChange("resumen", e)}
                                        className="form-control form-control-sm" rows="11"/>   
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.bonus")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-bonus"
                                        value={state.bonus}
                                        onChange={(e)=>handleChange("bonus", e)}
                                        className="form-control form-control-sm" rows="4"/>   
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.beneficios")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-beneficios"
                                        value={state.beneficios}
                                        onChange={(e)=>handleChange("beneficios", e)}
                                        className="form-control form-control-sm pt-0" rows="4"/>   
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
}
