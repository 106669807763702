/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SelectEntity from '../../../../../../../components/SelectEntity';
import SelectKeyValue from '../../../../../../../components/SelectKeyValue';
import CustomSelect from '../../../../../../../components/CustomSelect';
import CurrencyFormat from '../../../../../../../components/CurrencyFormat';
import DatePicker from '../../../../../../../components/DatePicker';
import MoneyInput from '../../../../../../../components/MoneyInput';
import TIPO_ACUERDO_COMERCIAL from '../../../../../../../enums/TipoAcuerdoComercial';
import { DateRfc }  from '../../../../../DateRfc';

const UPDATE_ACUERDO_COMERCIAL = gql`
  mutation updateAcuerdoComercial(
    $id: ID!
    $moneda: Int!
    $tipo: Int!
    $fechaOriginacion: LocalDate!
    $beneficiosAdicionales: Int!
    $bonus: Int!
    $hiringBonus: Int!
    $mesesAnualizacion: Int!
    $retainer: Int!
    $sueldoBrutoMensual: Int!
    $feeStandard: Float!
    $feePlusB: Float!
    $tipoBonus: Int!
    $tipoHiringBonus: Int!
    $comentario: String
  ) {
    updateAcuerdoComercial(
        entity:{
            id: $id
            moneda: $moneda
            tipo: $tipo
            fechaOriginacion: $fechaOriginacion
            beneficiosAdicionales: $beneficiosAdicionales
            bonus: $bonus
            hiringBonus: $hiringBonus
            mesesAnualizacion: $mesesAnualizacion
            retainer: $retainer
            sueldoBrutoMensual: $sueldoBrutoMensual
            feeStandard: $feeStandard
            feePlusB: $feePlusB
            tipoBonus: $tipoBonus
            tipoHiringBonus: $tipoHiringBonus
            comentario: $comentario
        }
    ) {
        id
    }
  }
`;

export default function AcuerdoComercialCard(props) {
    const { proyecto, acuerdoComercial: acuerdoComercialData, refetch } = props;
    const [t] = useTranslation("global");
    const [acuerdoComercial, setAcuerdoComercial] = useState({...acuerdoComercialData});
    const [lastUpdate, setLastUpdate] = useState({
        id: acuerdoComercialData?.id,
        moneda: parseInt(acuerdoComercialData?.moneda?.id),
        tipo: parseInt(acuerdoComercialData?.tipo?.id),
        fechaOriginacion: acuerdoComercialData?.fechaOriginacion?acuerdoComercialData?.fechaOriginacion:null,
        beneficiosAdicionales: parseInt(acuerdoComercialData?.beneficiosAdicionales),
        bonus: parseInt(acuerdoComercialData?.bonus),
        hiringBonus: parseInt(acuerdoComercialData?.hiringBonus),
        mesesAnualizacion: parseInt(acuerdoComercialData?.mesesAnualizacion),
        retainer: parseInt(acuerdoComercialData?.retainer),
        sueldoBrutoMensual: parseInt(acuerdoComercialData?.sueldoBrutoMensual),
        feeStandard: parseFloat(acuerdoComercialData?.feeStandard),
        feePlusB: parseFloat(acuerdoComercialData?.feePlusB),
        tipoBonus: parseInt(acuerdoComercialData?.tipoBonus),
        tipoHiringBonus: parseInt(acuerdoComercialData?.tipoHiringBonus),
        comentario: acuerdoComercialData?.comentario?acuerdoComercialData?.comentario:null,
    });
    const [executeUpdate, setExecuteUpdate] = useState(false);

    const [updateAcuerdoComercialMutation] = useMutation(UPDATE_ACUERDO_COMERCIAL,{
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {

        }
    });

    const shallowEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (let key of keys1) {
          if (object1[key] !== object2[key]) {
            return false;
          }
        }
      
        return true;
      }

    useEffect(() => {
        setTipoBonusOptions([
            {value: 1, label: t("acuerdoComercial.field.sueldos")},
            {value: 2, label: "%"},
            {value: 3, label: acuerdoComercial?.moneda?.simbolo},
        ]);
    }, [acuerdoComercial?.moneda?.simbolo, t])

    useEffect(() => {
        setAcuerdoComercial({
            ...acuerdoComercialData,
            moneda:{
                ...acuerdoComercialData.moneda,
                id: parseInt(acuerdoComercialData.moneda.id)
            },
            tipo:{
                ...acuerdoComercialData.tipo,
                id: parseInt(acuerdoComercialData.tipo.id)
            }
        });
    }, [acuerdoComercialData]);

    useEffect(() => {
        if(executeUpdate){
            setExecuteUpdate(false);
            const updateData = {
                id: acuerdoComercial?.id,
                moneda: parseInt(acuerdoComercial?.moneda?.id),
                tipo: parseInt(acuerdoComercial?.tipo?.id),
                fechaOriginacion: acuerdoComercial?.fechaOriginacion?acuerdoComercial?.fechaOriginacion:null,
                beneficiosAdicionales: parseInt(acuerdoComercial?.beneficiosAdicionales),
                bonus: parseInt(acuerdoComercial?.bonus),
                hiringBonus: parseInt(acuerdoComercial?.hiringBonus),
                mesesAnualizacion: parseInt(acuerdoComercial?.mesesAnualizacion),
                retainer: parseInt(acuerdoComercial?.retainer),
                sueldoBrutoMensual: parseInt(acuerdoComercial?.sueldoBrutoMensual),
                feeStandard: parseFloat(acuerdoComercial?.feeStandard),
                feePlusB: parseFloat(acuerdoComercial?.feePlusB),
                tipoBonus: parseInt(acuerdoComercial?.tipoBonus),
                tipoHiringBonus: parseInt(acuerdoComercial?.tipoHiringBonus),
                comentario: acuerdoComercial?.comentario?acuerdoComercial?.comentario:null,
            };

            if(!shallowEqual(lastUpdate, updateData)){
                setLastUpdate(updateData);
                updateAcuerdoComercialMutation({
                    variables:{
                        id: acuerdoComercial?.id,
                        moneda: parseInt(acuerdoComercial?.moneda?.id),
                        tipo: parseInt(acuerdoComercial?.tipo?.id),
                        fechaOriginacion: acuerdoComercial?.fechaOriginacion?acuerdoComercial?.fechaOriginacion:null,
                        beneficiosAdicionales: parseInt(acuerdoComercial?.beneficiosAdicionales),
                        bonus: parseInt(acuerdoComercial?.bonus),
                        hiringBonus: parseInt(acuerdoComercial?.hiringBonus),
                        mesesAnualizacion: parseInt(acuerdoComercial?.mesesAnualizacion),
                        retainer: parseInt(acuerdoComercial?.retainer),
                        sueldoBrutoMensual: parseInt(acuerdoComercial?.sueldoBrutoMensual),
                        feeStandard: parseFloat(acuerdoComercial?.feeStandard),
                        feePlusB: parseFloat(acuerdoComercial?.feePlusB),
                        tipoBonus: parseInt(acuerdoComercial?.tipoBonus),
                        tipoHiringBonus: parseInt(acuerdoComercial?.tipoHiringBonus),
                        comentario: acuerdoComercial?.comentario?acuerdoComercial?.comentario:null,
                    }
                })
            }
        }
    }, [acuerdoComercial, executeUpdate, lastUpdate, updateAcuerdoComercialMutation])
    

    function handleChange(name, value) {
        setAcuerdoComercial({
            ...acuerdoComercial,
            [name]: value
        });
    }

    const [tipoBonusOptions, setTipoBonusOptions] = useState([
        {value: 1, label: t("acuerdoComercial.field.sueldos")},
        {value: 2, label: "%"},
        {value: 3, label: acuerdoComercial?.moneda?.simbolo},
    ]);

    const isAcuerdoInicial = () =>{
        return parseInt(acuerdoComercial?.modo)===1;
    }

    const isPlacement = () =>{
        return parseInt(acuerdoComercial?.modo)===2;
    }

    const isDoublePlacement = () =>{
        return parseInt(acuerdoComercial?.modo)===3;
    }

    return (
        <div className="card card-info card-outline bg-transparent mt-2 mb-0">
            <div className="card-header">
                {isAcuerdoInicial()&&
                    <h3 className="card-title pl-3">{t("acuerdoComercial.label.acuerdoComercialConElCliente")}</h3>
                }
                {isPlacement()&&
                    <h3 className="card-title pl-3">{t("acuerdoComercial.label.placement")} - {acuerdoComercial?.postulante?.contacto?.alias}</h3>
                }
                {isDoublePlacement()&&
                    <h3 className="card-title pl-3">{t("acuerdoComercial.label.doublePlacement")} - {acuerdoComercial?.postulante?.contacto?.alias}</h3>
                }
            </div>
            <div className="card-body ml-2 mr-2">

                <div className="row">
                    <div className="col-lg-12 col-xl-8">

                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label required">{t("acuerdoComercial.field.tipoAcuerdo")}</label>
                                            <div className="col-5">
                                                <SelectEntity
                                                    disabled={proyecto?.fechaCierreAdministrativo||isPlacement()}
                                                    entity="TipoAcuerdoComercial"
                                                    label="nombre"
                                                    name={'value-tipo-acuerdo'}
                                                    value={parseInt(acuerdoComercial?.tipo?.id)}
                                                    required
                                                    onChange={(e) => {
                                                        setExecuteUpdate(true);
                                                        handleChange("tipo", {...acuerdoComercial?.tipo, id:e.target.value});
                                                    }} />
                                            </div>
                                            <div className="col-4">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {TIPO_ACUERDO_COMERCIAL.RETAINER===parseInt(acuerdoComercial?.tipo?.id)&&
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.retainer")}</label>
                                                <div className="col-5">
                                                    <span>
                                                        <SelectKeyValue
                                                            minWidth={100}
                                                            entity="Moneda"
                                                            isClearable={false}
                                                            label="simbolo"
                                                            name="value-moneda"
                                                            value={acuerdoComercial?.moneda?.id}
                                                            placeholder={" "}
                                                            required
                                                            disabled={!isAcuerdoInicial()}
                                                            onChange={(e, label) => {
                                                                setExecuteUpdate(true);
                                                                handleChange("moneda", {
                                                                    id:e.target.value,
                                                                    simbolo: label
                                                                });
                                                            }}/>
                                                    </span>
                                                    <MoneyInput value={acuerdoComercial?.retainer} className={"parrish-input ml-2"} disabled={proyecto?.fechaCierreAdministrativo}
                                                    onChange={(e)=>{
                                                        handleChange("retainer", e?.target?.value);
                                                    }}
                                                    onBlur={(e)=>{
                                                        setExecuteUpdate(true);
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {TIPO_ACUERDO_COMERCIAL.FEE_SOBRE_SBA === parseInt(acuerdoComercial?.tipo?.id)&&
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.sueldoBrutoMensual")}</label>
                                                    <div className="col-5">
                                                        <span>
                                                            <SelectKeyValue
                                                                isClearable={false}
                                                                minWidth={100}
                                                                entity="Moneda"
                                                                label="simbolo"
                                                                name="value-moneda"
                                                                value={acuerdoComercial?.moneda?.id}
                                                                disabled={proyecto?.fechaCierreAdministrativo||isPlacement()||isDoublePlacement()}
                                                                placeholder={" "}
                                                                required
                                                                onChange={(e, label) => {
                                                                    setExecuteUpdate(true);
                                                                    handleChange("moneda", {
                                                                        id:e.target.value,
                                                                        simbolo: label
                                                                    })
                                                                }}/>
                                                        </span>
                                                        <input type="number" value={acuerdoComercial?.sueldoBrutoMensual} className={"parrish-input text-right ml-2"} disabled={proyecto?.fechaCierreAdministrativo} 
                                                            onChange={(e)=>{
                                                                handleChange("sueldoBrutoMensual", e?.target?.value);
                                                            }}
                                                            onBlur={(e)=>{
                                                                    setExecuteUpdate(true);
                                                            }}/>
                                                    
                                                        <span className="p-1">x</span>
                                                        <input type="number" autoComplete="off" className="parrish-input-small" disabled={proyecto?.fechaCierreAdministrativo} value={acuerdoComercial?.mesesAnualizacion} 
                                                            onChange={(e)=>{
                                                                handleChange("mesesAnualizacion", e?.target?.value);
                                                            }}
                                                            onBlur={(e)=>{
                                                                setExecuteUpdate(true);
                                                            }}/>
                                                    </div>
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center">
                                                        <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                        <span>
                                                            <CurrencyFormat value={acuerdoComercial?.totales?.sueldoBrutoTotal?acuerdoComercial?.totales?.sueldoBrutoTotal:0}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tipo-bonus" className="col-3 parrish-label  required">{t("acuerdoComercial.field.bonus")}</label>
                                                    <div className="col-5">
                                                        <span>
                                                            <CustomSelect
                                                                isClearable={false}
                                                                minWidth={100}
                                                                isDisabled={proyecto?.fechaCierreAdministrativo}
                                                                name="value-tipo-bonus"
                                                                value={tipoBonusOptions.find(element=>element.value===acuerdoComercial?.tipoBonus)}
                                                                onChange={(selected)=>{
                                                                    setExecuteUpdate(true);
                                                                    handleChange("tipoBonus", selected?.value)
                                                                }}
                                                                options={tipoBonusOptions}
                                                            />
                                                        </span>
                                                        <input type="number" className="parrish-input parrish-input-max-150 text-right ml-2" value={acuerdoComercial?.bonus} disabled={proyecto?.fechaCierreAdministrativo} 
                                                            onChange={(e)=>{
                                                                handleChange("bonus", e?.target?.value);
                                                            }}
                                                            onBlur={(e)=>{
                                                                setExecuteUpdate(true);
                                                            }}/>
                                                    </div>
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center">
                                                        <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                        <span>
                                                            <CurrencyFormat value={acuerdoComercial?.totales?.bonusTotal?acuerdoComercial?.totales?.bonusTotal:0}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.hiringBonus")}</label>
                                                    <div className="col-5">
                                                        <span>
                                                            <CustomSelect
                                                                minWidth={100}
                                                                isClearable={false}
                                                                isDisabled={proyecto?.fechaCierreAdministrativo}
                                                                name="value-prioridad"
                                                                value={tipoBonusOptions.find(element=>element.value===acuerdoComercial?.tipoHiringBonus)}
                                                                onChange={(selected)=>{
                                                                    setExecuteUpdate(true);
                                                                    handleChange("tipoHiringBonus", selected?.value)
                                                                }}
                                                                options={tipoBonusOptions}
                                                            />
                                                        </span>
                                                        <input type="number" className="parrish-input parrish-input-max-150 text-right ml-2" value={acuerdoComercial?.hiringBonus} disabled={proyecto?.fechaCierreAdministrativo} 
                                                            onChange={(e)=>{
                                                                handleChange("hiringBonus", e?.target?.value);
                                                            }}
                                                            onBlur={(e)=>{
                                                                setExecuteUpdate(true);
                                                            }}/>
                                                    </div>
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center">
                                                        <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                        <span>
                                                            <CurrencyFormat value={acuerdoComercial?.totales?.hiringBonusTotal?acuerdoComercial?.totales?.hiringBonusTotal:0}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.beneficiosAdicionales")}</label>
                                                    <div className="col-5">
                                                        <span>
                                                            <input autoComplete="off" disabled={true} className="parrish-input small-input" value={acuerdoComercial?.moneda?.simbolo}/>
                                                        </span>
                                                        <input type="number" className="parrish-input parrish-input-max-150 text-right ml-2" value={acuerdoComercial?.beneficiosAdicionales} disabled={proyecto?.fechaCierreAdministrativo} 
                                                            onChange={(e)=>{
                                                                handleChange("beneficiosAdicionales", e?.target?.value);
                                                            }}
                                                            onBlur={(e)=>{
                                                                setExecuteUpdate(true);
                                                            }}/>
                                                    </div>
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center">
                                                        <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                        <span>
                                                            <CurrencyFormat value={acuerdoComercial?.beneficiosAdicionales?acuerdoComercial?.beneficiosAdicionales:0}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {parseInt(acuerdoComercial?.modo)!==1&&
                                            <div className="row mt-4">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.remuneracionTotal")}</label>
                                                        <div className="col-5">
                                                            <span>
                                                                <input autoComplete="off" disabled={true} className="parrish-input small-input" value={acuerdoComercial?.moneda?.simbolo}/>
                                                            </span>
                                                            <input type="number" className="parrish-input parrish-input-max-150 text-right ml-2" value={acuerdoComercial?.beneficiosAdicionales} disabled={proyecto?.fechaCierreAdministrativo} 
                                                                onChange={(e)=>{
                                                                    handleChange("beneficiosAdicionales", e?.target?.value);
                                                                }}
                                                                onBlur={(e)=>{
                                                                    setExecuteUpdate(true);
                                                                }}/>
                                                        </div>
                                                        <div className="col-1">
                                                        </div>
                                                        <div className="col-3 d-flex justify-content-between align-items-center">
                                                            <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                            <span>
                                                                <CurrencyFormat value={acuerdoComercial?.totales?.remuneracionTotal?acuerdoComercial?.totales?.remuneracionTotal:0}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!isDoublePlacement()&&
                                            <div className="row mt-4">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.remuneracionAcuerdo")}</label>                                       
                                                        <div className="col-6">
                                                        </div>
                                                        <div className="col-3 d-flex justify-content-between align-items-center">
                                                            <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                            <span>
                                                                <CurrencyFormat value={acuerdoComercial?.totales?.remuneracionInicialAcordada?acuerdoComercial?.totales?.remuneracionInicialAcordada:0}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>                                               
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.feeStandard")}</label>
                                                    <div className="col-5">
                                                        <span className="d-flex align-items-center">
                                                            <input autoComplete="off" disabled={proyecto?.fechaCierreAdministrativo||isPlacement()} className="parrish-input small-input text-right" value={acuerdoComercial?.feeStandard} 
                                                                onChange={(e)=>{
                                                                    handleChange("feeStandard", e?.target?.value);
                                                                }}
                                                                onBlur={(e)=>{
                                                                    setExecuteUpdate(true);
                                                                }}/>
                                                            <h6 className="m-0 pl-2">%</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3 d-flex justify-content-between align-items-center">
                                                        <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                        <span>
                                                            <CurrencyFormat value={acuerdoComercial?.totales?.honorarioStandard?acuerdoComercial?.totales?.honorarioStandard:0}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!isDoublePlacement()&&parseInt(acuerdoComercial?.modo)!==1&&
                                            <div className="row mt-4">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.diferenciaPlusB")}</label>
                                                        <div className="col-6">
                                                        </div>
                                                        <div className="col-3 d-flex justify-content-between align-items-center">
                                                            <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                            <span>
                                                                <CurrencyFormat value={acuerdoComercial?.totales?.totalDiferenciaPlusB?acuerdoComercial?.totales?.totalDiferenciaPlusB:0}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!isDoublePlacement()&&
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="value-tipo-acuerdo" className="col-3 parrish-label  required">{t("acuerdoComercial.field.feePlusB")}</label>
                                                        <div className="col-5">
                                                            <span className="d-flex align-items-center">
                                                                <input autoComplete="off" disabled={proyecto?.fechaCierreAdministrativo||isPlacement()||isDoublePlacement()} className="parrish-input small-input text-right" value={acuerdoComercial?.feePlusB} 
                                                                    onChange={(e)=>{
                                                                        handleChange("feePlusB", e?.target?.value);
                                                                    }}
                                                                    onBlur={(e)=>{
                                                                        setExecuteUpdate(true);
                                                                    }}/>
                                                                <h6 className="m-0 pl-2">%</h6>
                                                            </span>
                                                        </div>
                                                        {(isPlacement())&&
                                                            <>
                                                                <div className="col-1">
                                                                </div>
                                                                <div className="col-3 d-flex justify-content-between align-items-center">
                                                                    <span>{acuerdoComercial?.moneda?.simbolo}</span>
                                                                    <span>
                                                                        <CurrencyFormat value={acuerdoComercial?.totales?.honorarioPlusB}/>
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-12 col-xl-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group d-flex">
                                        <label htmlFor="value-fecha-originacion" className="parrish-label pr-4 required">{t("acuerdoComercial.field.fechaOriginacion")}</label>
                                        <div className="">
                                            <span>
                                                <DatePicker
                                                    name="value-fecha-originacion"
                                                    className="border-danger"
                                                    selected={acuerdoComercial?.fechaOriginacion?new DateRfc(acuerdoComercial?.fechaOriginacion):null}
                                                    disabled={proyecto?.fechaCierreAdministrativo}
                                                    onChange={(date) => {
                                                        setExecuteUpdate(true);
                                                        handleChange("fechaOriginacion", date);
                                                    }}/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-nombre" className="parrish-label pr-4 pl-2 required">{t("acuerdoComercial.field.observaciones")}</label>
                                        <div className="col-12">
                                            <textarea
                                                name="value-detalle"
                                                disabled={proyecto?.fechaCierreAdministrativo}
                                                value={acuerdoComercial?.comentario?acuerdoComercial?.comentario:""} 
                                                className="form-control form-control-sm"
                                                rows="10"
                                                onChange={(e)=>{
                                                    handleChange("comentario", e?.target?.value);
                                                }}
                                                onBlur={(e)=>{
                                                    setExecuteUpdate(true);
                                                }}>
                                                </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
