import React from 'react'
import { useTranslation } from 'react-i18next';
import AccionesSeguimiendoSelect from '../../../../../components/AccionesSeguimiendoSelect';
import Autocomplete from '../../../../../components/Autocomplete';
import DatePicker from '../../../../../components/DatePicker';
import SelectKeyValue from '../../../../../components/SelectKeyValue';

export default function FilterProyectoPostulante(props) {
    const [t] = useTranslation("global");
    const {proyecto, state, setState, setStateValue} = props;
    return (
        <>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                <div className="card-header pl-3 pt-0 pb-2">
                    <h3 className="card-title">{t("common.label.filtro")}</h3>
                    <div className="card-tools m-0">
                        <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool" onClick={()=>setState({
                                empresa: "",
                                pais: "",
                                estado: "",
                                etapa: "",
                                postulante: "",
                                prioridad: "",
                                responsable: "",
                                ultimaActividadDesde: "",
                                ultimaActividadHasta: "",
                        })}>
                            <i className="fas fa-eraser"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="parrish-form row mt-2 mb-2">
                <div className="col-xs-12 col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="value-postulante" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.postulante")}</label>
                        <div className="col">
                        <Autocomplete
                                name="value-postulante"
                                entity="Postulante"
                                by="Alias"
                                filterName="alias"
                                value={state.postulante}
                                initialLabel={state.postulanteLabel}
                                setValue={(val)=>setStateValue("postulante", val)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-empresa" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.empresa")}</label>
                        <div className="col">
                        <SelectKeyValue   
                            entity="Empresa"
                            by="Proyecto"
                            name="value-empresa"
                            filterName="idProyecto"
                            filterValue={proyecto}
                            filterType="Int!"
                            value={state.empresa}
                            sortDisable={true}
                            onChange={(e) => setStateValue("empresa", e.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-responsable" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.responsable")}</label>
                        <div className="col">
                        <SelectKeyValue   
                            entity="Usuario"
                            name="value-responsable"
                            value={state.responsable}
                            sortDisable={true}
                            onChange={(e) => setStateValue("responsable", e.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-pais" className="parrish-label col-sm-2 col-xs-12">{t("experienciaLaboral.field.pais")}</label>
                        <div className="col">
                            <SelectKeyValue
                                entity="Pais"
                                label="nombre"
                                name={'value-pais'}
                                value={state.pais}
                                sortDisable={true}
                                onChange={(e) => setStateValue("pais", e.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.fechaUltimaActividad")}</label>
                        <div className="col h-fit-content">
                            <div className="row">
                                <DatePicker
                                    name={'value-periodo-inicio'}
                                    selected={state.ultimaActividadDesde}
                                    onChange={(date) => {
                                        setStateValue("ultimaActividadDesde", date)
                                    }}
                                    className="col-sm-5 col"
                                    selectsStart
                                    startDate={state.ultimaActividadDesde}
                                    endDate={state.ultimaActividadHasta} />
                                <DatePicker
                                    name={'value-periodo-fin'}
                                    selected={state.ultimaActividadHasta}
                                    onChange={(date) => {
                                        setStateValue("ultimaActividadHasta", date)
                                    }}
                                    className="col-sm-5 col"
                                    selectsEnd
                                    startDate={state.ultimaActividadDesde}
                                    endDate={state.ultimaActividadHasta}
                                    minDate={state.ultimaActividadDesde} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="value-etapa" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.etapa")}</label>
                        <div className="col">
                        <SelectKeyValue
                            entity="EtapaProyecto"
                            name="value-etapa"
                            value={state.etapa}
                            sortDisable={true}
                            onChange={(e) => setStateValue("etapa", e.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-estado" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.estado")}</label>
                        <div className="col">
                            <SelectKeyValue
                                entity="EstadoPostulante"
                                name="value-estado"
                                value={state.estado}
                                sortDisable={true}
                                onChange={(e) => setStateValue("estado", e.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.prioridad")}</label>
                        <div className="col">
                        <SelectKeyValue
                            entity="Prioridad"
                            name="value-prioridad"
                            value={state.prioridad}
                            onChange={(e) => setStateValue("prioridad", e.target.value)}/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="value-seguimiento" className="parrish-label col-sm-2 col-xs-12">{t("proyecto.field.seguimientoAccion")}</label>
                        <div className="col">
                            <AccionesSeguimiendoSelect name="value-seguimiento" value={state.seguimientoAccion} setValue={(value) => setStateValue("seguimientoAccion", value)}/>
                        </div>
                    </div>

                    
                </div>
            </div>
        </>
    )
}
