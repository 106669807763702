import React from 'react'
import { useTranslation } from "react-i18next"
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router';

const ASIGNAR_FUNCION_PERFIL_MUTATION = gql`
    mutation asignarFuncionAlPerfil(
        $idFuncion: Int!
        $idPerfil: Int!
    ) {
        asignarFuncionAlPerfil(
            idFuncion: $idFuncion
            idPerfil: $idPerfil
        ){
            id
        }
    }
`;

const QUITAR_FUNCION_PERFIL_MUTATION = gql`
    mutation quitarFuncionAlPerfil(
        $idFuncion: Int!
        $idPerfil: Int!
    ) {
        quitarFuncionAlPerfil(
            idFuncion: $idFuncion
            idPerfil: $idPerfil
        ){
            id
        }
    }
`;

export default function PermisosTab(props) {
    const { funcionList, refetch } = props;
    let { id } = useParams();
    const [t] = useTranslation("global");

    const [asignarFuncionAlPerfil] = useMutation(ASIGNAR_FUNCION_PERFIL_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            refetch();
            console.error(error);
        }
    }, []);

    const [quitarFuncionAlPerfil] = useMutation(QUITAR_FUNCION_PERFIL_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            refetch();
            console.error(error);
        }
    }, []);

    const changePermiso = (idFuncion, grantedToPerfil) =>{
        if(grantedToPerfil){
            quitarFuncion(idFuncion)
        }else{
            asignarFuncion(idFuncion)
        }
    }

    const asignarFuncion = (idFuncion) =>{
        asignarFuncionAlPerfil({
            variables:{
                idFuncion: parseInt(idFuncion),
                idPerfil: parseInt(id)
            }
        })
    }

    const quitarFuncion = (idFuncion) =>{
        quitarFuncionAlPerfil({
            variables:{
                idFuncion: parseInt(idFuncion),
                idPerfil: parseInt(id)
            }
        })
    }

    const getData = ()=>{
        const data = [];
        if(!funcionList)
            return data;
        
        funcionList.sort((a, b) => (a.orden > b.orden) ? 1 : -1)
        funcionList.forEach(val => data.push(Object.assign({}, val)));
    
        data.splice(0, 1);

        let treeRoot = [];
        let currentLevel = 0;
        let lastParent = null;
        let lastElement = null;

        for(let spliceElement = data.splice(0, 1); spliceElement.length>0; spliceElement = data.splice(0, 1)){
            spliceElement = spliceElement[0];
            let level = spliceElement.nivel-1;
            if(level === 0){
                lastParent = spliceElement;
                treeRoot.push(spliceElement);
            }
            else
            {
                if(level>currentLevel){//Sube
                    currentLevel = level;
                    lastParent = lastElement;
                }else if(level<currentLevel){//Baja
                    currentLevel = level;               
                }
                spliceElement.parent = lastParent;
                if(lastParent.childs===undefined){
                    lastParent.childs = [];
                }
                lastParent.childs.push(spliceElement);
            }
            lastElement = spliceElement;
        }

        return treeRoot;
    }

    const MenuTree = (props) =>{
        return(
            props.items.map((funcion)=>{
                const calloutClass = funcion.grantedToPerfil?" callout-success":" callout-danger";
                return(
                    <>
                    <div key={funcion.id} className={"callout"+calloutClass+" p-2 d-flex flex-row justify-content-between mb-1 mt-0 nivel-"+(funcion.nivel)}>
                        <p className="m-0"><i className={'nav-icon mr-2 '+funcion.imagen} />{t(funcion.nombre)} </p>
                        {
                            !funcion.obligatorio&&
                            !funcion.childs&&
                            <div className="">
                                <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success d-flex justify-content-center">
                                    <input autoComplete="off" type="checkbox" className="custom-control-input" defaultChecked={funcion.grantedToPerfil} id={"customSwitch"+funcion.id} onChange={()=>{changePermiso(funcion.id, funcion.grantedToPerfil)}}/>
                                    <label className="custom-control-label" htmlFor={"customSwitch"+funcion.id}></label>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        funcion.childs&&
                        <MenuTree items={funcion.childs}/>
                    }
                    </>
                )
            })
        )
    }

    return (
        <div className="col-8">
            <MenuTree items={getData()} />
            {/*
                getData().map((funcion)=>{
                    const calloutClass = funcion.grantedToPerfil?" callout-success":" callout-danger";
                    return (
                        <div key={funcion.id} className={"callout"+calloutClass+" p-2 d-flex flex-row justify-content-between mb-1 mt-0 nivel-"+(funcion.nivel)}>
                            <p className="m-0"><i className={'nav-icon mr-2 '+funcion.imagen} />{t(funcion.nombre)} </p>
                            {
                                !funcion.obligatorio&&
                                <div className="">
                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success d-flex justify-content-center">
                                        <input autoComplete="off" type="checkbox" className="custom-control-input" defaultChecked={funcion.grantedToPerfil} id={"customSwitch"+funcion.id} onChange={()=>{changePermiso(funcion.id, funcion.grantedToPerfil)}}/>
                                        <label className="custom-control-label" htmlFor={"customSwitch"+funcion.id}></label>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                })
            */}
        </div>
    )
}
