import React, { useContext, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import FlagsPostulante from '../../../../../components/FlagsPostulante';
import Moment from 'react-moment';
import PrivateLink from '../../../../../components/navigation/PrivateLink';
import moment from 'moment';
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean';
// eslint-disable-next-line no-unused-vars
import AuthContext from '../../../../../context/AuthContext';
import SimpleDatatable from '../../../../../components/SimpleDatatable';
import SidebarContext from '../../../../../context/SidebarContext';
import EditarPostulanteQa from '../dialogs/editarPostulanteQa';
import SignOffPostulanteQa from '../dialogs/signOffPostulanteQa';
import MenuIcon from '../../../../../components/MenuIcon';

const postulanteProyectoReq = `id
ultimaActividad
signOff
proyecto{
    id
    alias
    fechaInicioBusqueda
    empresa{
        id
        contacto{
            id
            alias
        }
    }
    areaEmpresa{
        id
        nombre
    }
    cargo{
        id
        nombre
    }
    posicion
    pais{
        id
    }
    contacto{
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
    }
    acuerdoInicial{
        moneda{
            id
        }
        sueldoBrutoMensual
    }
}
estado{
    id
    desiste
    esFinal
    esInicial
    nombre
}
etapa{
    id
    orden
    nombre
}
postulante{
    id
    fechaActualizacion
    destacado
    fechaNacimientoEstimada
    incubadora
    incubadoraProspecto
    opportunity
    placement
    verificar
    offLimits
    appraisal
    emailFalso
    dni
    genero
    hijos
    qaInfo {
        indice
        recomendaciones
    }
    contacto{
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
        fechaNacimiento
        linkedin
        foto
        personaFisica
    }
    estadoCivil{
        nombre
    }
    responsable{
        contacto{
            alias
        }
    }
    adjuntoList{
        referencia
        fechaActualizacion
        contentType
        nombreOriginal
        nombreServidor
        responsable{
            contacto{
                alias
            }
        }
    }
    ultimoTrabajo{
        id
        ultimoTrabajo
        fechaInicio
        fechaFin
        empresa{
            contacto{
                alias
            }
        }
        posicion
    }
    ultimoSeguimiento{
        id
        fecha
        importante
        comentario
        accion{
            id
            nombre
            icon
            color
        }
        empresa{
            contacto{
                alias
            }
        }
        proyecto{
            id
            alias
        }
        responsable{
            id
            contacto{
                alias
            }
        }
    }
    proyectosActivos{
        id
        label
    }
}
prioridad{
    id
    nombre
    color
}
responsableList{
    contacto{
        alias
    }
}`

const FIND_PROYECTO_QA_CHECK_LIST_BY_PROYECTO = gql`
    query findProyectoQACheckListByProyecto($proyecto: Int!) {
        findProyectoQACheckListByProyecto(
            idProyecto: $proyecto
        ){
            id
            fecha
            controlado
            descripcion
            nombre
            usuario{
                contacto{
                    alias
                }
            }
        }
    }
`;
const FIND_PROYECTO_QA_SIGN_OFF_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQASignOffPendienteListByProyecto($proyecto: Int!) {
        findProyectoQASignOffPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;
const FIND_PROYECTO_QA_PLACEMENTS_SEGUIMIENTO_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQAPlacementsSeguimientoPendienteListByProyecto($proyecto: Int!) {
        findProyectoQAPlacementsSeguimientoPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;
const FIND_PROYECTO_QA_PLACEMENTS_DATOS_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQAPlacementsDatosPendienteListByProyecto($proyecto: Int!) {
        findProyectoQAPlacementsDatosPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;
const FIND_PROYECTO_QA_CANDIDATOS_SEGUIMIENTO_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQACandidatosSeguimientoPendienteListByProyecto($proyecto: Int!) {
        findProyectoQACandidatosSeguimientoPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;
const FIND_PROYECTO_QA_CANDIDATOS_DATOS_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQACandidatosDatosPendienteListByProyecto($proyecto: Int!) {
        findProyectoQACandidatosDatosPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;
const FIND_PROYECTO_QA_PROSPECTOS_DATOS_PENDIENTE_LIST_BY_PROYECTO = gql`
    query findProyectoQAProspectosDatosPendienteListByProyecto($proyecto: Int!) {
        findProyectoQAProspectosDatosPendienteListByProyecto(
            proyecto: $proyecto
        ){
            ${postulanteProyectoReq}
        }
    }
`;

const UPDATE_PROYECTO_QA_CHECK_MUTATION = gql`
    mutation updateProyectoQACheck(
        $proyectoQACheck: Int!
        $controlado: Boolean!
    ) {
        updateProyectoQACheck(
            proyectoQACheck: $proyectoQACheck
            controlado: $controlado
        ) {
            id
        }
    }
`;

const CERRAR_PROYECTO_QA = gql`
    mutation cerrarProyectoQA(
        $idProyecto: Int!
    ) {
        cerrarProyectoQA(
            idProyecto: $idProyecto
        ) {
            id
        }
    }
`;

export default function QaTab(props) {
    const { setSideBarState, show, isShow } = useContext(SidebarContext);
    const {idProyecto, fechaCierreQa, refetch} = props;
    const [t] = useTranslation("global");

    const { data: dataQaCheck, refetch: refetchQaCheck } = useQuery(FIND_PROYECTO_QA_CHECK_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataSignOffPendiente, refetch: refetchSignOffPendiente } = useQuery(FIND_PROYECTO_QA_SIGN_OFF_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataQaPlacementsSeguimientoPendiente, refetch: refetchQaPlacementsSeguimientoPendiente } = useQuery(FIND_PROYECTO_QA_PLACEMENTS_SEGUIMIENTO_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataQaPlacementsDatosPendientes, refetch: refetchQaPlacementsDatosPendientes } = useQuery(FIND_PROYECTO_QA_PLACEMENTS_DATOS_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataQaCandidatosSeguimiendoPendiente, refetch: refetchQaCandidatosSeguimiendoPendiente } = useQuery(FIND_PROYECTO_QA_CANDIDATOS_SEGUIMIENTO_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataQaCandidatosnDatosPendientes, refetch: refetchQaCandidatosnDatosPendientes } = useQuery(FIND_PROYECTO_QA_CANDIDATOS_DATOS_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    const { data: dataQaProspectoDatosPendientes, refetch: refetchQaProspectoDatosPendientes } = useQuery(FIND_PROYECTO_QA_PROSPECTOS_DATOS_PENDIENTE_LIST_BY_PROYECTO,
        { variables: { proyecto: idProyecto}}
    );
    
    const [updateProyectoQaCheckMutation] = useMutation(UPDATE_PROYECTO_QA_CHECK_MUTATION,{
        onCompleted: (data) => {
            refetchQaCheck();
            refetch();
        },
        onError: (error) => {
        }
    });

    const proyectoCheck = (proyectoQACheck, controlado) =>{
        updateProyectoQaCheckMutation({
            variables:{
                proyectoQACheck: parseInt(proyectoQACheck),
                controlado: controlado,
            }
        });
    }

    const [cerrarProyectoQaMutation] = useMutation(CERRAR_PROYECTO_QA,{
        onCompleted: (data) => {
            refetch();           
        },
        onError: (error) => {
        }
    });

    const cerrarProyectoQa = () =>{
        cerrarProyectoQaMutation({
            variables:{
                idProyecto: parseInt(idProyecto),
            }
        });
    }

    useEffect(() => {
        if(!isShow){
            refetchQaPlacementsSeguimientoPendiente();
            refetchQaCandidatosSeguimiendoPendiente();
        }
    }, [isShow, refetchQaCandidatosSeguimiendoPendiente, refetchQaPlacementsSeguimientoPendiente])

    const columnsDef = [
        {
            name: "postulante",
            customSortKey: "postulante.contacto.alias",
            translationKey: "postulante.label.entity",
            className: "col-2",
            render: (element) =>{
                const postulante = element.postulante;
                return(
                    <>
                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <div className="text-center">
                                    <FlagsPostulante postulante={postulante} />
                                </div>
                                <div className="text-center">
                                    <ContactoAvatarImg id={postulante?.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                    {postulante.contacto?.fechaNacimiento&&
                                        <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                    }
                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                        <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                    <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                </PrivateLink>
                            </div>
                        </div>
                    </>
                );
            }
        },
        {
            name: "appraisalAndUltimoSeguimiento",
            disableSort: true,
            render: (element) =>{
                const postulante = element.postulante;
                const seguimiento = postulante.ultimoSeguimiento;
                return(
                    <>
                    {postulante?.appraisal&&
                        <div className="card card-outline seguimiento-card bg-light-gray">
                            <div className="card-body p-2">
                                <b>{t("postulante.field.appraisal")}: </b>{postulante.appraisal}
                            </div>
                        </div>
                    }
                    {postulante?.ultimoSeguimiento&&
                    <>
                        <div className="card card-outline seguimiento-card bg-light-gray">
                            <div className="card-header p-1 ">
                                <div className="d-flex align-items-center">
                                    <div className="mr-2 rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: seguimiento.accion.color}}>
                                        <h6 className="align-self-center m-0">
                                            <i className={seguimiento?.accion?.icon+" text-white"} />
                                        </h6>
                                    </div>
                                    <b className={"mr-2 text-dark"}>{seguimiento?.accion?.nombre}</b>
                                    {seguimiento?.importante&&
                                        <>

                                        <span className="font-weight-bold text-danger mr-2 small float-right align-self-center">(<i className="fas fa-exclamation-triangle text-danger"/> {t("common.label.importante")})</span>
                                        </>
                                    }
                                    <span className="mr-auto"></span>
                                    <span className="mr-2 text-muted small float-right align-self-center">{seguimiento?.responsable?.contacto?.alias}</span>
                                    <span className="rounded p-1 bg-info"><Moment format={"DD MMM yyyy"}>{seguimiento?.fecha}</Moment></span>
                                </div>
                            </div>
                            <div className="card-body p-2">
                                {seguimiento?.comentario}
                            </div>
                            <div className="card-footer p-1 pr-2 pl-2">
                                <div className="row">
                                    <div className="col-12">
                                        {seguimiento?.proyecto&&
                                            <span title={seguimiento.proyecto?.alias} className="badge badge-secondary align-self-center parrish-badge mr-2">
                                                <PrivateLink className="text-light" to={"/proyecto/edit/"+seguimiento.proyecto?.id}>
                                                    <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/><span>{seguimiento.proyecto?.alias}</span>
                                                </PrivateLink>
                                            </span>
                                        }
                                        {seguimiento?.empresa &&
                                            <span className="badge badge-secondary align-self-center">
                                                <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{seguimiento.empresa?.contacto?.alias}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    </>
                );
            }
        },
        {
            name: "ultimoTrabajoRegistrado",
            disableSort: true,
            render: (element) =>{
                const postulante = element.postulante;
                const meses = postulante.ultimoTrabajo?moment().diff(postulante.ultimoTrabajo.fechaFin?postulante.ultimoTrabajo.fechaFin:postulante.ultimoTrabajo.fechaInicio, 'months',false):0;
                const classNamePeriod = (meses>25?"text-danger":(meses>13?"text-orange":"text-success"));
                return(postulante.ultimoTrabajo&&
                    <>
                        <p className={"font-weight-bold "+(!postulante.ultimoTrabajo?.fechaFin?"text-success":"text-danger")}>{postulante.ultimoTrabajo?.empresa.contacto.alias}</p>
                        <p>{postulante.ultimoTrabajo?.posicion}</p>
                        <p className={"text-nowrap "+classNamePeriod}>
                            <span><Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaInicio}</Moment></span>
                            {
                                postulante.ultimoTrabajo?.fechaFin?
                                    <> - <Moment className="text-uppercase" format={"MMM yyyy"}>{postulante.ultimoTrabajo?.fechaFin}</Moment></>
                                :
                                    <span> - <span>{t("Actualidad")}</span></span>
                            }
                        </p>
                        <p className={classNamePeriod}>({t("common.label.hace")} {meses} {t("postulante.field.meses")})</p>
                    </>
                );
            },
        },
        {
            name: "responsableUltimaActividad",
            customSortKey: "ultimaActividad",
            render: (element) =>{
                const postulanteProyecto = element;
                return(<>
                    <p>{moment(postulanteProyecto.ultimaActividad).format("YYYY-MM-DD")}</p>
                    <p><span className="text-bold">{t("postulante.field.responsables")}: </span></p>
                        {postulanteProyecto.responsableList.map(element=>
                            <h5 key={element.contacto.alias} className='m-0' ><span className="badge badge-info m-0 p-1 font-weight-normal">{element.contacto.alias}</span></h5>
                        )}
                </>)
            }
        },
        {
            name: "prioridad",
            type: "text",
            translationKey: "postulante.field.quickWin",
            className: "text-center",
            headerClassname: "text-center sorting_disabled",
            render: (element)=>{
                return(<div className="d-flex justify-content-center">
                    <FlagButtonBoolean
                        icon="fas fa-running"
                        onClass={"bg-"+element.prioridad.color}
                        title={element.prioridad.nombre}
                        value={true}
                    />
                </div>);
            }
        },
        {
            name: "etapa",
            customSortKey:"etapa.id",
            translationKey: "postulante.field.etapa",
            type: "text",
            render: (element)=>{
                return(<>{element.etapa.nombre}</>);
            }
        },
        {
            name: "estadoProyecto",
            translationKey: "postulante.field.estado",
            customSortKey:"estado.nombre",
            type: "text",
            render: (element)=>{
                return(<><p className={element.estado.esFinal?"text-danger":"text-success"}>{element.estado.nombre}</p></>);
            }
        }
    ]

    return (

        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            <div className="card-header pl-3 pt-2 pb-2">
                <div className="card-tools m-0">
                    <button className="btn btn-xs btn-info bg-info btn-tool" onClick={()=>{cerrarProyectoQa();}} disabled={fechaCierreQa}>
                        Cerrar QA
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="parrish-form row">
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <div className="col-sm-12 col-xs-12">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingOne">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <span>{t("proyecto.label.qaCheck")}</span>
                                                    {dataQaCheck?.findProyectoQACheckListByProyecto.filter(check => !check.controlado).length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaCheck?.findProyectoQACheckListByProyecto.filter(check => !check.controlado).length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="p-2">
                                            
                                            
                                                {dataQaCheck?.findProyectoQACheckListByProyecto.map(element=>{
                                                    return(
                                                        <div key={"ProyectoQACheckList-"+element.id}>
                                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                                <div className={element.controlado?"text-success":"text-danger"}>
                                                                    <h5 className="m-0">{element.nombre}</h5>
                                                                    <p className={"small m-0 "+(element.controlado?"text-success":"text-danger")}>{element.descripcion}</p>
                                                                </div>
                                                                <div className="d-flex justify-content-end align-items-center m-0">
                                                                    <div className="mr-4">
                                                                        {element.usuario?.contacto&&
                                                                            <div className="text-muted small align-self-center mr-2"><i className="fas fa-user mr-1"/>{element.usuario?.contacto?.alias}</div>
                                                                        }
                                                                        {element.fecha&&
                                                                            <div className="text-muted small align-self-center mr-2"><i className="fas fa-clock mr-1"/>{moment(element.fecha).format('DD-MM-YYYY')}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="custom-control custom-checkbox" role="button">
                                                                        <input autoComplete="off"  className={"custom-control-input  custom-control-input-info"} type="checkbox" id={"check-"+element.id} checked={element.controlado}
                                                                        onChange={(e) => {
                                                                            proyectoCheck(element.id, e.target.checked);
                                                                        }}/>
                                                                        <label htmlFor={"check-"+element.id} className="custom-control-label" role="button"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-1 mb-1"/>
                                                        </div>
                                                        
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <span>{t("proyecto.label.qaSignOffPendiente")}</span>
                                                    {dataSignOffPendiente?.findProyectoQASignOffPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataSignOffPendiente?.findProyectoQASignOffPendienteListByProyecto?.length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className={dataSignOffPendiente?.findProyectoQASignOffPendienteListByProyecto?.length>0?"collapse":"d-none"} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataSignOffPendiente?.findProyectoQASignOffPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={
                                                        [
                                                            ...columnsDef,
                                                            {
                                                                name: "acciones",
                                                                disableSort: true,
                                                                customClassHeader: "bg-info text-center p-2",
                                                                className: "text-center",
                                                                render: (element)=>{
                                                                    return(<SignOffPostulanteQa idProyectoPostulante={element.id} refetch={refetchSignOffPendiente}/>)
                                                                }
                                                            }
                                                        ]
                                                    }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingThree">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <span>{t("proyecto.label.qaPlacementsSeguimientoPendiente")}</span>
                                                    {dataQaPlacementsSeguimientoPendiente?.findProyectoQAPlacementsSeguimientoPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaPlacementsSeguimientoPendiente?.findProyectoQAPlacementsSeguimientoPendienteListByProyecto?.length})Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className={dataQaPlacementsSeguimientoPendiente?.findProyectoQAPlacementsSeguimientoPendienteListByProyecto?.length>0?"collapse":"d-none"} aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataQaPlacementsSeguimientoPendiente?.findProyectoQAPlacementsSeguimientoPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={
                                                        [
                                                            ...columnsDef,
                                                            {
                                                                name: "acciones",
                                                                disableSort: true,
                                                                customClassHeader: "bg-info text-center p-2",
                                                                className: "text-center",
                                                                render: (element)=>{
                                                                    return(<>
                                                                        <button className="btn btn-warning p-1" title={t("proyecto.button.signOff")} onClick={()=>{
                                                                            setSideBarState({
                                                                                mode: "seguimiento",
                                                                                labelPostulante: element.postulante?.contacto?.alias,
                                                                                labelProyecto: element.proyecto?.alias,
                                                                                postulante: element.postulante?.id,
                                                                                proyecto: element.proyecto?.id,
                                                                                empresa: null,
                                                                                labelEmpresa: null,
                                                                                responsable: null,
                                                                                accionSeguimiento: null,
                                                                            })
                                                                            show();
                                                                        }}>
                                                                            <i className="fas fa-shoe-prints fa-rotate-270 fa-2x"></i>
                                                                        </button>
                                                                    </>)
                                                                }
                                                            }
                                                        ]
                                                }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingFour">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <span>{t("proyecto.label.qaPlacementsDatosPendiente")}</span>
                                                    {dataQaPlacementsDatosPendientes?.findProyectoQAPlacementsDatosPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaPlacementsDatosPendientes?.findProyectoQAPlacementsDatosPendienteListByProyecto?.length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className={dataQaPlacementsDatosPendientes?.findProyectoQAPlacementsDatosPendienteListByProyecto?.length>0?"collapse":"d-none"} aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataQaPlacementsDatosPendientes?.findProyectoQAPlacementsDatosPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={[
                                                        ...columnsDef,
                                                        {
                                                            name: "acciones",
                                                            disableSort: true,
                                                            customClassHeader: "bg-info text-center p-2",
                                                            className: "text-center",
                                                            render: (element)=>{
                                                                return(<EditarPostulanteQa idProyectoPostulante={element.id} refetch={refetchQaPlacementsDatosPendientes}/>)
                                                            }
                                                        }
                                                    ]}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingFive">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    <span>{t("proyecto.label.qaCandidatosSeguimientoPendiente")}</span>
                                                    {dataQaCandidatosSeguimiendoPendiente?.findProyectoQACandidatosSeguimientoPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaCandidatosSeguimiendoPendiente?.findProyectoQACandidatosSeguimientoPendienteListByProyecto?.length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className={dataQaCandidatosSeguimiendoPendiente?.findProyectoQACandidatosSeguimientoPendienteListByProyecto?.length>0?"collapse":"d-none"} aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataQaCandidatosSeguimiendoPendiente?.findProyectoQACandidatosSeguimientoPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={
                                                        [
                                                            ...columnsDef,
                                                            {
                                                                name: "acciones",
                                                                disableSort: true,
                                                                customClassHeader: "bg-info text-center p-2",
                                                                className: "text-center",
                                                                render: (element)=>{
                                                                    return(<>
                                                                        <button className="btn btn-warning p-1" title={t("proyecto.button.signOff")} onClick={()=>{
                                                                            setSideBarState({
                                                                                mode: "seguimiento",
                                                                                labelPostulante: element.postulante?.contacto?.alias,
                                                                                labelProyecto: element.proyecto?.alias,
                                                                                postulante: element.postulante?.id,
                                                                                proyecto: element.proyecto?.id,
                                                                                empresa: null,
                                                                                labelEmpresa: null,
                                                                                responsable: null,
                                                                                accionSeguimiento: null,
                                                                            })
                                                                            show();
                                                                        }}>
                                                                            <i className="fas fa-shoe-prints fa-rotate-270 fa-2x"></i>
                                                                        </button>
                                                                    </>)
                                                                }
                                                            }
                                                        ]
                                                    }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingSix">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    <span>{t("proyecto.label.qaCandidatosDatosPendiente")}</span>
                                                    {dataQaCandidatosnDatosPendientes?.findProyectoQACandidatosDatosPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaCandidatosnDatosPendientes?.findProyectoQACandidatosDatosPendienteListByProyecto?.length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSix" className={dataQaCandidatosnDatosPendientes?.findProyectoQACandidatosDatosPendienteListByProyecto.length>0?"collapse":"d-none"} aria-labelledby="headingSix" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataQaCandidatosnDatosPendientes?.findProyectoQACandidatosDatosPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={[
                                                        ...columnsDef,
                                                        {
                                                            name: "acciones",
                                                            disableSort: true,
                                                            customClassHeader: "bg-info text-center p-2",
                                                            className: "text-center",
                                                            render: (element)=>{
                                                                return(<EditarPostulanteQa idProyectoPostulante={element.id} refetch={refetchQaCandidatosnDatosPendientes}/>)
                                                            }
                                                        }
                                                    ]}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className={"card-header p-0"} id="headingSeven">
                                            <h2 className="mb-0">
                                                <button className={"btn btn-block text-left m-0 border-top-4 d-flex justify-content-between align-items-center"} type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    <span>{t("proyecto.label.qaProspectoDatosPendientes")}</span>
                                                    {dataQaProspectoDatosPendientes?.findProyectoQAProspectosDatosPendienteListByProyecto?.length>0?
                                                        <span className="badge badge-danger ml-2 elevation-2">({dataQaProspectoDatosPendientes?.findProyectoQAProspectosDatosPendienteListByProyecto?.length}) Pendientes</span>
                                                    :
                                                        <span className="badge badge-success ml-2 elevation-2">Completo</span>
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSeven" className={dataQaProspectoDatosPendientes?.findProyectoQAProspectosDatosPendienteListByProyecto?.length>0?"collapse":"d-none"} aria-labelledby="headingSeven" data-parent="#accordionExample">
                                            <div className="p-2">
                                                <SimpleDatatable
                                                    data={dataQaProspectoDatosPendientes?.findProyectoQAProspectosDatosPendienteListByProyecto}
                                                    entityName="postulante"
                                                    columnsDefinition={[
                                                        ...columnsDef,
                                                        {
                                                            name: "acciones",
                                                            disableSort: true,
                                                            customClassHeader: "bg-info text-center p-2",
                                                            className: "text-center",
                                                            render: (element)=>{
                                                                return(<EditarPostulanteQa idProyectoPostulante={element.id} refetch={refetchQaProspectoDatosPendientes}/>)
                                                            }
                                                        }
                                                    ]}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}