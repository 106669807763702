import React, { useState, useEffect } from 'react'
import ExperienciaLaboralList from './list'
import ExperienciaLaboralCreate from './create'
import ExperienciaLaboralEdit from './edit'

export default function ExperienciaLaboralTab(props) {
    const { idPostulante, refetchPostulante } = props;
    const [mode, setMode] = useState("list")
    const [editId, setEditId] = useState("list")

    useEffect(() => {
       if(mode==="list"){
        refetchPostulante();
       }
    },[mode, refetchPostulante])

    return (
        <>
            {mode==="list"&&
                <ExperienciaLaboralList setMode={setMode} idPostulante={idPostulante} setEditId={setEditId} refetchPostulante={refetchPostulante}/>
            }
            {mode==="create"&&
                <ExperienciaLaboralCreate setMode={setMode} idPostulante={idPostulante} refetchPostulante={refetchPostulante}/>
            }
            {mode==="edit"&&
                <ExperienciaLaboralEdit setMode={setMode} editId={editId} refetchPostulante={refetchPostulante}/>
            }
        </>
    )
}