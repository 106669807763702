import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { gql, useMutation, useQuery} from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useTranslation } from 'react-i18next';
import DetalleTab from './tabs/detalle';
import ProyectosTab from './tabs/proyectos';
import EmpleadosTab from './tabs/empleados';
import AdjuntosTab from './tabs/adjuntos';
import SitesTab from './tabs/sites/index';
import {useCallback} from 'react';
import SidebarContext from '../../../../context/SidebarContext';
import AvatarUploadModal from '../../../../components/AvatarUploadModal';
import CardFooterRecordData from '../../../../components/CardFooterRecordData';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import FlagsEmpresa from '../../../../components/FlagsEmpresa';

const EMPRESA_SEARCH_BY_ID = gql`
    query findEmpresaById(
        $id: Int!
    ) {
    findEmpresaById(
        id: $id
    ){
        id
        razonSocial
        industria{id}
        fechaActualizacion
        cliente
        tieneProyectoActivo
        diasCobroEfectivo
        cuit
        dominioEmails
        observacionesFacturacion
        direccionFiscalDomicilio
        direccionFiscalLocalidad
        direccionFiscalPais{id}
        direccionFiscalProvincia{id}
        direccionFiscalCP
        condicionIva{
            id
            nombre
        }
        contacto{
            id
            nombre
            alias
            personaFisica
            domicilio
            localidad
            pais{id}
            provincia{id}
            codigoPostal
            telefonosFijos
            website
            linkedin
            observaciones
            foto
        }
        responsable{
            contacto{
                alias
            }
        }
        adjuntoList{
            referencia
            fechaActualizacion
            contentType
            nombreOriginal
            nombreServidor
            responsable{
                contacto{
                    alias
                }
            }
        }
    }
}
`;

const UPDATE_EMPRESA_MUTATION = gql`
    mutation updateEmpresa(
        $id: ID!
        $condicionIva: Int
        $pais: Int
        $provincia: Int
        $localidad: String
        $domicilio: String
        $codigoPostal: String
        $direccionFiscalPais: Int
        $direccionFiscalProvincia: Int
        $direccionFiscalLocalidad: String
        $direccionFiscalDomicilio: String
        $direccionFiscalCodigoPostal: String
        $linkedin: String
        $industria: Int!
        $diasCobroEfectivo: Int
        $nombre: String!
        $cuit: String
        $dominioEmails: String
        $observacionesFacturacion: String
        $razonSocial: String
        $telefonosFijos: String
        $website: String
        $observaciones: String
    ) {
        updateEmpresa(entity:{
            id: $id
            condicionIva: $condicionIva
            direccionFiscal:{
                pais: $direccionFiscalPais
                provincia: $direccionFiscalProvincia
                localidad: $direccionFiscalLocalidad
                domicilio: $direccionFiscalDomicilio
                codigoPostal: $direccionFiscalCodigoPostal
            } 
            direccion: {
                pais: $pais
                provincia: $provincia
                localidad: $localidad
                domicilio: $domicilio
                codigoPostal: $codigoPostal
            }
            socialInfo: {
                linkedin: $linkedin
            }
            industria: $industria
            diasCobroEfectivo: $diasCobroEfectivo
            nombre: $nombre
            cuit: $cuit
            dominioEmails: $dominioEmails
            observacionesFacturacion: $observacionesFacturacion
            razonSocial: $razonSocial
            telefonosFijos: $telefonosFijos
            website: $website
            observaciones: $observaciones
        }) {
            id
        }
    }
`;

function EmpresaEdit() {
    const history = useHistory();
    let { id } = useParams();
    const [t] = useTranslation("global");

    const { setSideBarState, show } = useContext(SidebarContext);

    const {loading, error, data, refetch } = useQuery(
        EMPRESA_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [state, setState] = useState({
        nombre: "",
        razonSocial: "",
        industria: "",
        telefonosFijos: "",
        website: "",
        linkedin: "",
        dominioEmails: "",
        cuit: "",
        condicionIva: "",
        diasCobroEfectivo: "",
        domicilio: "",
        localidad: "",
        pais: "",
        provincia: "",
        codigoPostal: "",
        direccionFiscalDomicilio: "",
        direccionFiscalLocalidad: "",
        direccionFiscalPais: "",
        direccionFiscalProvincia: "",
        direccionFiscalCodigoPostal: "",
        observaciones: "",
        observacionesFacturacion: ""
    });

    let { showErrorToast } = useAlertMessage();

    const ref = useRef();

    const setStateValue = useCallback((name, value) =>{
        setState({
            ...state,
            [name]: value
        });
        console.log(name, value, state);
    }, [state]);

    const mergeState = useCallback((stateData) =>{
        setState({
            ...state,
            ...stateData
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleChange(name, evt) {
        const value = evt.target.value;
        setStateValue(name, value);
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-nombre": "required",
                "value-industria": "required",
            },
            messages: {
                "value-nombre": {
                    required: t("common.message.field.required", {0: t("empresa.field.nombre")})
                },
                "value-industria": {
                    required: t("common.message.field.required", {0: t("empresa.field.industria")})
                },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    useEffect(() => {
        if(!loading && !error && data?.findEmpresaById){
            const empresa = data?.findEmpresaById;
            let stateData = {};

            stateData.industria = empresa.industria?parseInt(empresa.industria.id):"";
            stateData.nombre = empresa.contacto.nombre?empresa.contacto.nombre:"";
            //Datos contacto
            stateData.telefonosFijos = empresa.contacto.telefonosFijos?empresa.contacto.telefonosFijos:"";
            stateData.website = empresa.contacto.website?empresa.contacto.website:"";
            stateData.linkedin = empresa.contacto.linkedin?empresa.contacto.linkedin:"";
            stateData.dominioEmails = empresa.dominioEmails?empresa.dominioEmails:"";
            
            //Datos facturacion
            stateData.razonSocial = empresa.razonSocial?empresa.razonSocial:"";
            stateData.cuit = empresa.cuit?empresa.cuit:"";
            stateData.condicionIva = empresa.condicionIva?parseInt(empresa.condicionIva.id):"";
            stateData.diasCobroEfectivo = empresa.diasCobroEfectivo?empresa.diasCobroEfectivo:"";

            //Domicilio
            stateData.domicilio = empresa.contacto.domicilio?empresa.contacto.domicilio:"";
            stateData.localidad = empresa.contacto.localidad?empresa.contacto.localidad:"";
            stateData.pais = empresa.contacto.pais?parseInt(empresa.contacto.pais.id):"";
            stateData.provincia = empresa.contacto.provincia?parseInt(empresa.contacto.provincia.id):"";
            stateData.codigoPostal = empresa.contacto.codigoPostal?empresa.contacto.codigoPostal:"";
            stateData.observaciones = empresa.contacto.observaciones?empresa.contacto.observaciones:"";

            //DomicilioFiscal
            stateData.direccionFiscalDomicilio = empresa.direccionFiscalDomicilio?empresa.direccionFiscalDomicilio:"";
            stateData.direccionFiscalLocalidad = empresa.direccionFiscalLocalidad?empresa.direccionFiscalLocalidad:"";
            stateData.direccionFiscalPais = empresa.direccionFiscalPais?parseInt(empresa.direccionFiscalPais.id):"";
            stateData.direccionFiscalProvincia = empresa.direccionFiscalProvincia?parseInt(empresa.direccionFiscalProvincia.id):"";
            stateData.direccionFiscalCodigoPostal = empresa.direccionFiscalCP?empresa.direccionFiscalCP:"";
            stateData.observacionesFacturacion = empresa.observacionesFacturacion?empresa.observacionesFacturacion:"";

            //setState
            mergeState(stateData);
        }
    }, [data?.findEmpresaById, error, loading, mergeState]);

    const [updateEmpresaMutation] = useMutation(UPDATE_EMPRESA_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateEmpresaMutation({
            variables:{
                id: id,
                nombre: state.nombre,
                condicionIva: state.condicionIva?state.condicionIva:null,
                pais: state.pais?parseInt(state.pais):null,
                provincia: state.provincia?parseInt(state.provincia):null,
                localidad: state.localidad?state.localidad:null,
                domicilio: state.domicilio?state.domicilio:null,
                codigoPostal: state.codigoPostal?state.codigoPostal:null,
                direccionFiscalPais: state.direccionFiscalPais?parseInt(state.direccionFiscalPais):null,
                direccionFiscalProvincia: state.direccionFiscalProvincia?parseInt(state.direccionFiscalProvincia):null,
                direccionFiscalLocalidad: state.direccionFiscalLocalidad?state.direccionFiscalLocalidad:null,
                direccionFiscalDomicilio: state.direccionFiscalDomicilio?state.direccionFiscalDomicilio:null,
                direccionFiscalCodigoPostal: state.direccionFiscalCodigoPostal?state.direccionFiscalCodigoPostal:null,
                linkedin: state.linkedin?state.linkedin:null,
                industria: state.industria?state.industria:null,
                diasCobroEfectivo: state.diasCobroEfectivo?state.diasCobroEfectivo:null,
                cuit: state.cuit?state.cuit:null,
                dominioEmails: state.dominioEmails?state.dominioEmails:null,
                observacionesFacturacion: state.observacionesFacturacion?state.observacionesFacturacion:null,
                razonSocial: state.razonSocial?state.razonSocial:null,
                telefonosFijos: state.telefonosFijos?state.telefonosFijos:null,
                website: state.website?state.website:null,
                observaciones: state.observaciones?state.observaciones:null
           }
        })
    }

    return (
        <>
            <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">


                <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 pl-0 d-flex align-items-stretch">
                                <div className="card card-info card-outline w-100">
                                    <div className="card-body box-profile">
                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                            <div className="row">
                                                <div className="text-center">
                                                    <h4>{data?.findEmpresaById?.contacto?.alias}</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col d-flex justify-content-center">
                                                    <div className="text-center">
                                                        {data?.findEmpresaById?.contacto&&
                                                            <AvatarUploadModal entity="Empresa" foto={data?.findEmpresaById?.contacto.foto} personaFisica={data?.findEmpresaById?.contacto?.personaFisica} contactoId={data?.findEmpresaById?.contacto.id} id={data?.findEmpresaById?.id}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col d-flex justify-content-center">
                                                    <div className="text-center">
                                                        {data?.findEmpresaById&&
                                                            <FlagsEmpresa empresa={data?.findEmpresaById}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 pr-0 d-flex flex-column align-items-stretch">
                                <div className={"card card-outline card-info h-100"}>
                                    {/*
                                        loading &&
                                        !error && (
                                            <div className="overlay">
                                                <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                            </div>
                                        )
                                    */}
                                    <div className="card-header">
                                        <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })}</h3>
                                        <div className="card-tools m-0">
                                            <button type="button" className="btn btn-xs bg-yellow btn-tool" onClick={()=>{
                                                setSideBarState({
                                                    mode: "seguimiento",
                                                    empresa: id,
                                                    labelEmpresa: data?.findEmpresaById?.contacto?.nombre,
                                                    labelPostulante: null,
                                                    labelProyecto: null,
                                                    postulante: null,
                                                    proyecto: null,
                                                    responsable: null,
                                                    accionSeguimiento: null,
                                                })
                                                show();
                                            }}>
                                                <i className="fas fa-shoe-prints fa-rotate-270"></i>
                                            </button>

                                            <button type="button" className="btn btn-xs bg-yellow btn-tool ml-2" onClick={()=>{
                                                setSideBarState({
                                                    mode: "referencias",
                                                    labelProyecto: null,
                                                    proyecto: null,
                                                    labelPostulante: null,
                                                    postulante: null,
                                                    labelEmpresa: data?.findEmpresaById?.contacto?.nombre,
                                                    empresa: id
                                                })
                                                show();
                                            }}>
                                                <i className="fas fa-user-secret"></i>
                                            </button>
                                            <button type="submit" className="btn btn-xs bg-info btn-tool ml-2">
                                                <i className="fas fa-save"></i>
                                            </button>
                                            <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div className="parrish-form row ">                            

                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-nombre" className="parrish-label col-sm-2 col-xs-12 required">{t("empresa.field.nombre")}</label>
                                                    <div className="col">
                                                        <input autoComplete="off" type="text"
                                                            name={'value-nombre'}
                                                            className="parrish-input"
                                                            value={state.nombre}
                                                            onChange={e => handleChange("nombre", e)}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-0">
                                                    <label htmlFor="value-industria" className="parrish-label col-sm-2 col-xs-12 required">{t("empresa.field.industria")}</label>
                                                    <div className="col">
                                                        <SelectKeyValue
                                                            entity="Industria"
                                                            name={'value-industria'}
                                                            by="Nombre"
                                                            value={state.industria}
                                                            onChange={e => handleChange("industria", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CardFooterRecordData record={data?.findEmpresaById}/>
                                </div>
                            </div>
                        </div>
                    </div>
            </form>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase active"
                                id="custom-tabs-info-adicional-tab"
                                data-toggle="pill"
                                href="#custom-tabs-info-adicional"
                                role="tab"
                                aria-controls="custom-tabs-info-adicional"
                                aria-selected="true">
                                {t("empresa.label.detalleEmpresa")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase"
                                id="custom-tabs-proyectos-tab"
                                href="#custom-tabs-proyectos"
                                aria-controls="custom-tabs-proyectos"
                                data-toggle="pill"
                                role="tab">
                                {t("empresa.label.proyectos")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase"
                                id="custom-tabs-empleados-tab"
                                href="#custom-tabs-empleados"
                                aria-controls="custom-tabs-empleados"
                                data-toggle="pill"
                                role="tab">
                                {t("empresa.label.empleados")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase"
                                id="custom-tabs-sites-tab"
                                href="#custom-tabs-sites"
                                aria-controls="custom-tabs-sites"
                                data-toggle="pill"
                                role="tab">
                                {t("empresa.label.sites")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link-muted text-uppercase"
                                id="custom-tabs-adjuntos-tab"
                                href="#custom-tabs-adjuntos"
                                aria-controls="custom-tabs-adjuntos"
                                data-toggle="pill"
                                role="tab">
                                {t("empresa.label.adjuntos")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-info-adicional" role="tabpanel" aria-labelledby="custom-tabs-info-adicional-tab">
                            <DetalleTab state={state} setState={setState}/>
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-proyectos" role="tabpanel" aria-labelledby="custom-tabs-proyectos-tab">
                            <ProyectosTab />
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-empleados" role="tabpanel" aria-labelledby="custom-tabs-empleados-tab">
                            <EmpleadosTab />
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-sites" role="tabpanel" aria-labelledby="custom-tabs-sites-tab">
                            <SitesTab idEmpresa={id}/>
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-adjuntos" role="tabpanel" aria-labelledby="custom-tabs-adjuntos-tab">
                            <AdjuntosTab idEmpresa={id}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpresaEdit