import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import AuthContext from '../../../context/AuthContext';
import SidebarContext from '../../../context/SidebarContext';
import OverlayScrollbars from 'admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars';
import Autocomplete from '../../Autocomplete';
import $ from "jquery"
import moment from 'moment';
import DeleteEntityButton from '../../DeleteEntityButton';
import Paginator from '../../Paginator';
import EditReferenciaModal from './modals/EditReferenciaModal';
import CreateReferenciaModal from './modals/CreateReferenciaModal';
import MenuIcon from '../../MenuIcon';

const FIND_TPS_REFERENCIA_PAGE_BY_FILTER = gql`
    query findTpsReferenciaPageByFilter(
        $empresa: Int
        $postulante: Int
        $proyecto: Int
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findTpsReferenciaPageByFilter(
            filter:{
                empresa: $empresa
                postulante: $postulante
                proyecto: $proyecto
            }
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fechaRegistro
                    observaciones
                    relacion
                    contacto{
                        alias
                    }
                    empresa{
                        contacto{
                            alias
                        }
                    }
                    postulante{
                        contacto{
                            alias
                        }
                    }
                    proyecto{
                        alias
                        ref
                    }
                    responsable{
                        id
                        contacto{
                            alias
                        }
                    }
                }
            }
        }
    }
`;

export default function ReferenciaSidebar(props) {
    const { hide } = useContext(SidebarContext);
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    Moment.globalLocale = 'es';

    const [labelEmpresa, setLabelEmpresa] = useState(props.labelEmpresa);
    const [labelPostulante, setLabelPostulante] = useState(props.labelPostulante);
    const [labelProyecto, setLabelProyecto] = useState(props.labelProyecto);
    
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState();

    const [empresa, setEmpresa] = useState(props.empresa);
    const [postulante, setPostulante] = useState(props.postulante);
    const [proyecto, setProyecto] = useState(props.proyecto);
    
    const [t] = useTranslation("global");

    const [sortOptions] = useState({
        sortColumn: "fechaRegistro",
        sortDirection: "DESC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        setPageNumber(0)
    }, [empresa, postulante, proyecto]);
    const [dataList, setDataList] = useState([]);

    const [searchSeguimientos, {data}] = useLazyQuery(FIND_TPS_REFERENCIA_PAGE_BY_FILTER, {
        onCompleted: (data) => {
            setDataList(data?.findTpsReferenciaPageByFilter?.edges);
        }
    });
    
    useEffect(() => {
        if(
            empresa||
            postulante||
            proyecto
        ){
            searchSeguimientos({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    empresa: empresa?empresa:null,
                    postulante: postulante?postulante:null,
                    proyecto: proyecto?proyecto:null
                }
            })
        }else{
            setDataList([]);
            setPageNumber(0);
        }
    }, [empresa, pageNumber, pageSize, postulante, proyecto, searchSeguimientos, sortOptions.sortColumn, sortOptions.sortDirection]);

    const refetch = function() {
        if(
            empresa||
            postulante||
            proyecto
        ){
            searchSeguimientos({
                variables: {
                    sortColumn: sortOptions.sortColumn,
                    sortDirection: sortOptions.sortDirection,
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                    empresa: empresa?empresa:null,
                    postulante: postulante?postulante:null,
                    proyecto: proyecto?proyecto:null
                }
            })
        }else{
            setDataList([]);
            setPageNumber(0);
        }
    };

    useEffect(() => {
        $(".control-sidebar").css({
            bottom: $(".main-footer").outerHeight(),
            top: $(".main-header").outerHeight()
        });
        OverlayScrollbars(document.querySelectorAll('#scroll-ref-body'), { });
    }, []);

    const clearFilter = () =>{
        setPostulante(null);
        setEmpresa(null);
        setProyecto(null);
        setDataList([]);
    }

    const ReferenciasTitle = (props) => {
        const {
            empresa,
            setEmpresa,
            postulante,
            setPostulate,
            proyecto,
            setProyecto,
            labelEmpresa,
            labelPostulante,
            labelProyecto
        } = props;
        
        return(
            <div className="parrish-form row">
                <div className="col-xs-12 col-sm-12">
                    <div className="parrish-form row p-2">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-postulante"
                                        entity="Postulante"
                                        by="Alias"
                                        placeholder={t("postulante.label.entity")}
                                        filterName="alias"
                                        value={postulante}
                                        initialLabel={labelPostulante}
                                        setValue={(val, label)=>{setPostulate(val); setLabelPostulante(label)}} />
                                </div>
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-proyecto"
                                        entity="Proyecto"
                                        by="Alias"
                                        placeholder={t("postulante.field.proyecto")}
                                        filterName="alias"
                                        value={proyecto}
                                        initialLabel={labelProyecto}
                                        setValue={(val, label)=>{setProyecto(val); setLabelProyecto(label)}} />
                                </div>
                                <div className="col-4">
                                    <Autocomplete
                                        name="value-empresa"
                                        entity="Empresa"
                                        by="Alias"
                                        placeholder={t("postulante.field.empresa")}
                                        filterName="alias"
                                        value={empresa}
                                        initialLabel={labelEmpresa}
                                        setValue={(val, label)=>{setEmpresa(val); setLabelEmpresa(label)}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="card card-info card-outline card-outline-tabs bg-light shadow-none mb-4 bg-light border rounded-0 h-100">
                <div className="card-header d-flex align-items-center p-2">
                    <h3 className="card-title text-dark font-weight-bold mr-auto"><i className="fas fas fa-user-secret mr-2"></i>{t("tpsReferencia.label.entities")}</h3>
                    <div className="card-tools m-0">
                        <button
                            className="btn btn-xs btn-info bg-info btn-tool ml-2"
                            title={t("common.button.agregar")}
                            onClick={()=>{setShowCreate(true)}}>
                                <i className="fas fa-plus"></i>
                        </button>
                        <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool ml-2" onClick={()=>clearFilter()}>
                            <i className="fas fa-eraser"></i>
                        </button>
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{
                                hide();
                            }}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="border-bottom shadow-sm">
                    <ReferenciasTitle                       
                        empresa={empresa}
                        setEmpresa={setEmpresa}
                        labelEmpresa={labelEmpresa}
                        postulante={postulante}
                        setPostulate={setPostulante}
                        labelPostulante={labelPostulante}
                        proyecto={proyecto}
                        setProyecto={setProyecto}
                        labelProyecto={labelProyecto}
                    />
                </div>
                <div id="scroll-ref-body" className="card-body">
                    {dataList&&dataList.length>0?
                        <div className="parrish-form row ">
                            {data?.findTpsReferenciaPageByFilter?.edges?.map(edge=>{
                                const referencia = edge.node;
                                return(
                                    <div className="card card-outline w-100" key={"ref-"+referencia.id}>
                                        <div className="card-header p-2 ">
                                            <b className={"mr-auto text-dark"}>{referencia.contacto?.alias}</b><span className="badge badge-warning ml-2">{referencia.relacion}</span>
                                            <div className="float-right d-flex align-items-center">
                                                <span className="text-muted small align-self-center mr-2">{referencia.responsable.contacto.alias}</span>
                                                <span className="text-muted small align-self-center mr-2"><i className="fas fa-clock"/>{moment(referencia.fechaRegistro).format('DD-MM-YYYY')}</span>
                                                <button type="button" title={t("common.button.editar")} className="btn btn-xs bg-info ml-auto mt-0 mb-0" onClick={()=>{
                                                    setShowEdit(true);
                                                    setEditId(referencia.id);
                                                }}>
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                <DeleteEntityButton className="ml-2" entity="TpsReferencia" id={referencia.id} label={referencia.contacto?.alias} onCompleted={()=>{
                                                    refetch()
                                                }} />        
                                            </div>
                                        </div>
                                        <div className="card-body p-2">
                                            {referencia.observaciones}
                                        </div>
                                        <div className="card-footer bg-white p-1 pr-2 pl-2">
                                            <div className="row">
                                                <div className="col-12">
                                                    {referencia.postulante && !postulante &&
                                                        <span title={referencia.postulante?.contacto?.alias} className="badge label-quicksearch-POSTULANTE text-left mr-2 mb-1 mt-1">
                                                            <MenuIcon keyMenu="postulante.label.menu" className="mr-2"/>{referencia.postulante?.contacto?.alias}
                                                        </span>
                                                    }
                                                    {referencia.proyecto && !proyecto &&
                                                        <span title={referencia.proyecto?.alias} className="badge label-quicksearch-PROYECTO text-left mr-2 mb-1 mt-1">
                                                            <MenuIcon keyMenu="proyecto.label.menu" className="mr-2"/>{referencia.proyecto?.alias}
                                                        </span>
                                                    }
                                                    {referencia.empresa && !empresa&&
                                                        <span title={referencia.empresa?.contacto?.alias} className="badge label-quicksearch-EMPRESA text-left mr-2 mb-1 mt-1">
                                                            <MenuIcon keyMenu="empresa.label.menu" className="mr-2"/>{referencia.empresa?.contacto?.alias}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                            {data?.findTpsReferenciaPageByFilter?.edges&&data?.findTpsReferenciaPageByFilter?.edges.length>pageSize&&
                                <div className="parrish-form row justify-content-end w-100">
                                    <Paginator data={data?.findTpsReferenciaPageByFilter} setPageSize={setPageSize} setPageNumber={setPageNumber} pageSize={pageSize} pageNumber={pageNumber} />
                                </div>
                            }
                        </div>
                        :
                        <div className="d-flex justify-content-center">
                            <div className="alert alert-info alert-dismissible mt-1 mb-3 p-3 fit-content">
                                <h5 className="m-0 p-0">{t("tpsReferencia.message.emptyList")}</h5>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <CreateReferenciaModal
                show={showCreate} 
                setShow={setShowCreate} 
                idEmpresa={empresa} 
                idPostulante={postulante} 
                idProyecto={proyecto}
                labelEmpresa={labelEmpresa}
                labelPostulante={labelPostulante}
                labelProyecto={labelProyecto}
                refetch={refetch}/>
            {editId&&
                <EditReferenciaModal
                    id={editId}
                    show={showEdit}
                    setShow={setShowEdit}
                    refetch={refetch}
                    idEmpresa={empresa} 
                    idPostulante={postulante} 
                    idProyecto={proyecto}
                    labelEmpresa={labelEmpresa}
                    labelPostulante={labelPostulante}
                    labelProyecto={labelProyecto}/>
            }
        </>
    )
}