export class DateRfc extends Date {
    constructor(year, monthIndex, day, hours, minutes, seconds, milliseconds){
        // console.log(year, monthIndex, day, hours, minutes, seconds, milliseconds);

        if(year === undefined &&
            monthIndex === undefined &&
            day === undefined &&
            hours === undefined &&
            minutes === undefined &&
            seconds === undefined &&
            milliseconds === undefined){
                super();
        }

        if(year !== undefined &&
            monthIndex === undefined &&
            day === undefined &&
            hours === undefined &&
            minutes === undefined &&
            seconds === undefined &&
            milliseconds === undefined){

                if(new RegExp(/^[1-9]\d{3}-\d{2}-\d{2}$/g).test(year)){
                    super(year+"T00:00:00")
                }else{
                    super(year);
                }
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day === undefined &&
            hours === undefined &&
            minutes === undefined &&
            seconds === undefined &&
            milliseconds === undefined){
                super(year, monthIndex);
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day !== undefined &&
            hours === undefined &&
            minutes === undefined &&
            seconds === undefined &&
            milliseconds === undefined){
                super(year, monthIndex, day);
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day !== undefined &&
            hours !== undefined &&
            minutes === undefined &&
            seconds === undefined &&
            milliseconds === undefined){
                super(year, monthIndex, day, hours);
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day !== undefined &&
            hours !== undefined &&
            minutes !== undefined &&
            seconds === undefined &&
            milliseconds === undefined){
                super(year, monthIndex, day, hours, minutes);
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day !== undefined &&
            hours !== undefined &&
            minutes !== undefined &&
            seconds !== undefined &&
            milliseconds === undefined){
                super(year, monthIndex, day, hours, minutes, seconds);
        }

        if(year !== undefined &&
            monthIndex !== undefined &&
            day !== undefined &&
            hours !== undefined &&
            minutes !== undefined &&
            seconds !== undefined &&
            milliseconds !== undefined){
                super(year, monthIndex, day, hours, minutes, seconds, milliseconds);
        }
    }
}