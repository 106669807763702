import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_TIPOPROYECTO_MUTATION = gql`
    mutation createTipoProyecto(
        $tipoAcuerdoComercial: Int!
        $nombre: String!
        $nombreCorto: String!
    ) {
        createTipoProyecto(entity:{
            tipoAcuerdoComercial: $tipoAcuerdoComercial
            nombre: $nombre
            nombreCorto: $nombreCorto
        }) {
            id
            tipoAcuerdoComercial {
                id
                nombre
            }
            nombre
            nombreCorto
        }
    }
`;

function TipoProyectoEdit() {
    const history = useHistory();

    const [tipoAcuerdoComercial, setTipoAcuerdoComercial] = useState('');
    const [nombre, setNombre] = useState('');
    const [nombreCorto, setNombreCorto] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createTipoProyectoMutation] = useMutation(CREATE_TIPOPROYECTO_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/tipoProyecto";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createTipoProyectoMutation({
            variables:{
                tipoAcuerdoComercial: parseInt(tipoAcuerdoComercial),
                nombre: nombre,
                nombreCorto: nombreCorto
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardCreateEntity
                entityName="tipoProyecto"
                columnsCount={1}
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 50,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.nombre")})
                        }
                    },
                    {
                        name: "nombreCorto",
                        type: "text",
                        maxLength: 4,
                        value: nombreCorto,
                        setValue: setNombreCorto,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.nombreCorto")})
                        }
                    },
                    {
                        name: "tipoAcuerdoComercial",
                        type: "select",
                        entity:"TipoAcuerdoComercial",
                        label: "nombre",
                        value: tipoAcuerdoComercial,
                        setValue: setTipoAcuerdoComercial,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.tipoAcuerdoComercial")})
                        }
                    }
                ]}
                returnUrl="/tipoProyecto"
                >
            </CardCreateEntity>
        </>
    )
}

export default TipoProyectoEdit