import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import { DateRfc }  from '../../DateRfc';

const USUARIO_SEARCH_BY_ID = gql`
    query findUsuarioById(
        $id: Int!
    ) {
    findUsuarioById(
        id: $id
    ){
        id
        contacto{
            id
            nombre
        }
        idioma{
            id
            nombre
        }
        perfil{
            id
            nombre
        }
        permisoEspecialList{
            id
            nombre
            descripcion
            granted
            funcion{
                id
                nombre
            }
        }
        fechaAlta
        fechaExpiracion
        bloqueado
        apellido
        email
        nombre
        activacionPendiente
    }
}
`;

const QUITAR_PERMISO_ESPECIAL_MUTATION = gql`
    mutation quitarPermisoEspecialByUsuario(
        $idPermiso: Int!
        $idUsuario: Int!
    ) {
        quitarPermisoEspecialByUsuario(
            idPermiso: $idPermiso
            idUsuario: $idUsuario
        ) {
            id
            nombre
            funcion{
                id
                nombre
            }
            granted
        }
    }
`;

const OTORGAR_PERMISO_ESPECIAL_MUTATION = gql`
    mutation otorgarPermisoEspecialByUsuario(
        $idPermiso: Int!
        $idUsuario: Int!
    ) {
        otorgarPermisoEspecialByUsuario(
            idPermiso: $idPermiso
            idUsuario: $idUsuario
        ) {
            id
            nombre
            funcion{
                id
                nombre
            }
            granted
        }
    }
`;

const UPDATE_USUARIO_MUTATION = gql`
    mutation updateUsuario(
        $id: ID!
        $perfil: Int!
        $apellido: String!
        $email: String!
        $nombre: String!
    ) {
        updateUsuario(entity:{
            id: $id
            perfil: $perfil
            apellido: $apellido
            email: $email
            nombre: $nombre
        }) {
            contacto{
                id
                nombre
            }
            idioma{
                id
                nombre
            }
            id
            perfil{
                id
                nombre
            }
            permisoEspecialList{
                id
                nombre
                descripcion
                granted
                funcion{
                    id
                    nombre
                }
            }
            fechaAlta
            fechaExpiracion
            bloqueado
            apellido
            email
            nombre
        }
    }
`;

const DELETE_USUARIO_MUTATION = gql`
    mutation deleteUsuario(
        $id: Int!
    ) {
        deleteUsuario(id: $id)
    }
`;

const BLOQUEAR_USUARIO_MUTATION = gql`
    mutation bloquearUsuario(
        $id: Int!
    ) {
        bloquearUsuario(id:$id) {
            id
                nombre
        }
    }
`;

const DESBLOQUEAR_USUARIO_MUTATION = gql`
    mutation desbloquearUsuario(
        $id: Int!
    ) {
        desbloquearUsuario(id:$id) {
            id
                nombre
        }
    }
`;

function UsuarioEdit() {
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect, showMessageWithTimerAndExecute } = useAlertMessage();

    const [perfil, setPerfil] = useState('');
    const [, setFechaAlta] = useState('');
    const [bloqueado, setBloqueado] = useState('');
    const [activacionPendiente, setActivacionPendiente] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(false);

    const {loading, error, data, refetch } = useQuery(
        USUARIO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        _isMounted.current = true;
        if (!loading && !error){
            setPerfil(data?.findUsuarioById?.perfil?.id?data?.findUsuarioById?.perfil?.id:null);
            setFechaAlta(new DateRfc());
            setBloqueado(data?.findUsuarioById?.bloqueado);
            setActivacionPendiente(data?.findUsuarioById?.activacionPendiente);
            setApellido(data?.findUsuarioById?.apellido);
            setEmail(data?.findUsuarioById?.email);
            setNombre(data?.findUsuarioById?.nombre);
        }
        return () => { _isMounted.current = false };
    }, [loading, error, data?.findUsuarioById?.perfil?.id, data?.findUsuarioById?.bloqueado, data?.findUsuarioById?.apellido, data?.findUsuarioById?.email, data?.findUsuarioById?.nombre, data?.findUsuarioById?.activacionPendiente])
    

    let query = new URLSearchParams(useLocation().search);
    let returnUrl = "/private/usuario";
    if(query!=null && (query.get("returnUrl"))){
        returnUrl = query.get("returnUrl");
    }

    const [updateUsuarioMutation] = useMutation(UPDATE_USUARIO_MUTATION, {
        onCompleted: (data) => {
            console.info("saved...")
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [quitarPermisoEspecialUsuarioMutation] = useMutation(QUITAR_PERMISO_ESPECIAL_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1000, ()=>{refetch();});
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [otorgarPermisoEspecialUsuarioMutation] = useMutation(OTORGAR_PERMISO_ESPECIAL_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1000, ()=>{refetch();});
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteUsuarioMutation] = useMutation(DELETE_USUARIO_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.save.success"), 1000, returnUrl);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [bloquearUsuarioMutation] = useMutation(BLOQUEAR_USUARIO_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1000, ()=>{refetch();});
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [desbloquearUsuarioMutation] = useMutation(DESBLOQUEAR_USUARIO_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1000, ()=>{refetch();});
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateUsuarioMutation({
            variables:{
                id: id,
                perfil: parseInt(perfil),
                apellido: apellido,
                email: email,
                nombre: nombre
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteUsuarioMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    const changePermiso = (idPermiso, granted) => {
        const variables = {
            variables:{
                idPermiso: idPermiso,
                idUsuario: parseInt(id)
            }
        }
        if(granted){
            quitarPermisoEspecialUsuarioMutation(variables);
        }else{
            otorgarPermisoEspecialUsuarioMutation(variables);
        }
    }

    const bloquearUsuario = () => {
        bloquearUsuarioMutation({
            variables:{
                id: id
            }
        })
    }

    const desbloquearUsuario = () => {
        desbloquearUsuarioMutation({
            variables:{
                id: id
            }
        })
    }

    const Tabs = () => {
        return(
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted active"
                                id="custom-tabs-one-home-tab"
                                data-toggle="pill"
                                href="#custom-tabs-one-home"
                                role="tab"
                                aria-controls="custom-tabs-one-home"
                                aria-selected="true">
                                Permisos Especiales
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                            <div className="pl-4 pr-4">
                                <table className="table parrish-table dataTable table-bordered table-striped">
                                    <thead className="text-align-center">
                                        <tr>
                                            <th className="bg-info sorting_disabled">
                                                <div>{t("usuario.field.nombre")}</div>
                                            </th>
                                            <th className="bg-info sorting_disabled">
                                                <div>{t("usuario.field.tienePermiso")}</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.findUsuarioById?.permisoEspecialList?.sort(function(a, b){
                                                if(a.nombre < b.nombre) { return -1; }
                                                if(a.nombre > b.nombre) { return 1; }
                                                return 0;
                                            }).map((element, index) => 
                                            {
                                                return(
                                                    <tr key={index}>
                                                        <td className="p-2">
                                                            {t(element.nombre)}
                                                        </td>
                                                        <td className="p-2">
                                                            <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success d-flex justify-content-center">
                                                                <input autoComplete="off" type="checkbox" className="custom-control-input" defaultChecked={element.granted} id={"customSwitch"+element.id} onChange={()=>{changePermiso(element.id, element.granted)}}/>
                                                                <label className="custom-control-label" htmlFor={"customSwitch"+element.id}></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <CardEditEntity
                entityName="usuario"
                handleSubmit={handleSubmit}
                record={data?.findUsuarioById}
                customHeader={<>
                    {bloqueado&&<span className="badge badge-danger text-uppercase ml-2">{t("usuario.field.bloqueado")}</span>}
                    {activacionPendiente&&<span className="badge badge-warning text-uppercase ml-2">{t("usuario.label.activacionPendiente")}</span>}
                </>}
                customTools={
                    <>
                        {bloqueado?
                            <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{desbloquearUsuario()}}>
                                <i className="fas fa-unlock"></i>
                            </button>
                        :
                            <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{bloquearUsuario()}}>
                                <i className="fas fa-lock"></i>
                            </button>
                        }
                    </>
                }
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 50,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("usuario.field.nombre")})
                        },
                        componentClassName: "col-sm-6 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-3",
                        inputClassName: "col-sm-8 col-xs-9"
                    },
                    {
                        name: "apellido",
                        type: "text",
                        maxLength: 50,
                        value: apellido,
                        setValue: setApellido,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("usuario.field.apellido")})
                        },
                        componentClassName: "col-sm-6 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-3",
                        inputClassName: "col-sm-8 col-xs-9"
                    },
                    {
                        name: "email",
                        type: "text",
                        maxLength: 50,
                        value: email,
                        setValue: setEmail,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("usuario.field.email")})
                        },
                        componentClassName: "col-sm-6 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-3",
                        inputClassName: "col-sm-8 col-xs-9"
                    },
                    {
                        name: "perfil",
                        type: "select",
                        entity:"Perfil",
                        label:"nombre",
                        value: perfil,
                        setValue: setPerfil,
                        translationKey: "usuario.field.perfil",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("usuario.field.perfil")})
                        },
                        componentClassName: "col-sm-6 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-3",
                        inputClassName: "col-sm-8 col-xs-9"
                    }
                ]}
                returnUrl="/usuario"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
            <Tabs/>
        </>
    )
}

export default UsuarioEdit