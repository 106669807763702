import React from 'react'
import { gql, useMutation } from '@apollo/client';
import CurrencyFormat from '../../../../../../../components/CurrencyFormat';
import DownloadAdjunto from '../../../../../../../components/DownloadAdjunto';
import SimpleDatatable from '../../../../../../../components/SimpleDatatable';
import EstadoComprobante from '../../../../../admin/estados/EstadoComprobante';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ESTADO_ADMINISTRATIVO from '../../../../../../../enums/EstadoAdministrativo';
import useAlertMessage from '../../../../../../../hooks/useAlertMessage';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

const APROBAR_COMPROBANTE_MUTATION = gql`
    mutation aprobarComprobante(
        $idComprobante: Int!
    ) {
        aprobarComprobante(
            idComprobante: $idComprobante
        ) {
            id
        }
    }
`;

const CANCELAR_APROBACION_COMPROBANTE_MUTATION = gql`
    mutation cancelarAprobacionComprobante(
        $idComprobante: Int!
    ) {
        cancelarAprobacionComprobante(
            idComprobante: $idComprobante
        ) {
            id
        }
    }
`;

const DELETE_COMPROBANTE_MUTATION = gql`
    mutation deleteComprobante(
        $id: Int!
    ) {
        deleteComprobante(
            id: $id
        )
    }
`;

export default function ComprobantesList(props) {
    const [t] = useTranslation("global");
    const history = useHistory();
    const { data, setComprobanteSelected, refetch, fechaCierreAdministrativo } = props;
    const { showConfirmDelete } = useAlertMessage();

    const [aprobarComprobanteMutation] = useMutation(APROBAR_COMPROBANTE_MUTATION, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [cancelarAprobacionComprobanteMutation] = useMutation(CANCELAR_APROBACION_COMPROBANTE_MUTATION, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteComprobanteMutation] = useMutation(DELETE_COMPROBANTE_MUTATION, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteComprobanteMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const totalImporteNeto = data?data["findComprobanteListByProyecto"]?.reduce((total, currVal) =>{
        return total + currVal.cbteImporteNeto*currVal.cbteImpacto
    } , 0):0;

    const totalImporteIva = data?data["findComprobanteListByProyecto"]?.reduce((total, currVal) =>{
        return total + currVal.cbteImporteIva*currVal.cbteImpacto
    }, 0):0;

    const totalImporteFinal = data?data["findComprobanteListByProyecto"]?.reduce((total, currVal) =>{
        return total + currVal.cbteImporteFinal*currVal.cbteImpacto
    }, 0):0;

    const accionesColumn = {
        name: "acciones",
        className: "text-center",
        render: (element)=>{
            return(
                <>
                    {parseInt(element?.estado?.id) === ESTADO_ADMINISTRATIVO.REGISTRADO ?
                        <>
                            <button type="button" className="btn btn-xs bg-info btn-tool mr-2" onClick={()=>{
                                aprobarComprobanteMutation({variables:{idComprobante: element.id}})
                            }}>
                                <i className="fas fa-check"></i>
                            </button>
                            <button type="button" className="btn btn-xs bg-info btn-tool mr-2" onClick={()=>{
                                setComprobanteSelected(element);
                            }}>
                                <i className="fas fa-edit"></i>
                            </button>
                            <button type="button" className="btn btn-xs bg-info btn-tool" onClick={()=>{
                                deleteAction(element.id);
                            }}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </>
                        :
                        <button type="button" className="btn btn-xs bg-info btn-tool mr-2" onClick={()=>{
                            cancelarAprobacionComprobanteMutation({variables:{idComprobante: element.id}})
                        }}>
                            <i className="fas fa-times"></i>
                        </button>
                        
                    }
                </>
            )
        },
        rowSpan: 2
    };

    const getAccionesColumn=()=>{
        return fechaCierreAdministrativo?[]:[accionesColumn];
    }

    return (
        <SimpleDatatable
            data={data?.findComprobanteListByProyecto}
            onEdit={(element)=>{
                history.push("/private/comprobante/edit/"+element.id);
            }}
            entityName="comprobante"
            headerTop={
                <tr>
                    <td className="bg-info text-left p-1 pl-3 text-bold border-bottom-0" colSpan="12">
                        <div>{t("comprobante.label.entities")}</div>
                    </td>
                </tr>
            }
            headerBottom={
                <tr>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        {data?
                         data["findComprobanteListByProyecto"]?
                         data["findComprobanteListByProyecto"][0]?
                         data["findComprobanteListByProyecto"][0].cbteMoneda?.simbolo:"":"":""}
                    </td>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        <CurrencyFormat
                            additionalClassName="float-right"
                            value={totalImporteNeto}/>
                    </td>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        <CurrencyFormat
                            additionalClassName="float-right"
                            value={totalImporteIva}/>
                    </td>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        <CurrencyFormat
                            additionalClassName="float-right"
                            value={totalImporteFinal}/>
                    </td>
                </tr>
            }
            columnsDefinition={[
                {
                    name: "id",
                    type: "id",
                    rowSpan: 2
                },
                {
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-center pl-0 pr-0",
                    disableSort: true,
                    className: "text-center",
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoComprobante element={element}/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "fechaAprobacion",
                    className:"text-center",
                    render: (element)=>{
                        return(element.fechaAprobacion&&
                            <>
                                <p>{moment(element.fechaAprobacion).format('DD-MM-YYYY')}</p>
                                <p>{element?.aprobadoPor?.contacto?.alias}</p>
                            </>
                        )
                    },
                    rowSpan: 2
                },
                {
                    name: "cbteFechaEmision",
                    type: "date",
                    render: (element)=>{
                        const from = moment();
                        const to = element?.cbteFechaEmision?moment(element?.cbteFechaEmision):moment();
                        const days = to.diff(from, 'days');
                        const dayLabel = Math.abs(days)===1?t("common.label.dia"):t("common.label.dias");
                        const textDesde = days?"["+(days<0?(t("common.label.hace")+" "):"")+Math.abs(days)+" "+dayLabel+"]":"";
                        return(<>
                            <Moment className="date-centered" format={'DD-MM-YYYY'}>{to}</Moment>
                            <p className={"text-center "+(days>0?"":"text-danger")}>{textDesde}</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "cbteFechaVencimiento",
                    type: "date",
                    rowSpan: 2
                },
                {
                    name: "detalle",
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.cbteTipo.nemotecnico+(element.cbteNro?" ["+element.cbteNro+"]":"")}</p>
                            <p>({element.cbteDetalle})</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "moneda",
                    customClassHeader: "bg-info p-1 border-bottom-0",
                    tdHeader: true,
                    customHeader: (<></>),
                    className: "text-center",
                    render: (element)=>{
                        return(element.cbteMoneda?.simbolo)
                    }
                },
                {  
                    name: "cbteImporteNeto",
                    customClassHeader: "bg-info p-1 text-bold border-bottom-0",
                    tdHeader: true,
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeNeto = element.cbteImporteNeto*element.cbteImpacto;
                        return(
                            <CurrencyFormat
                                additionalClassName="float-right"
                                value={importeNeto}/>
                        )
                    }
                },
                {  
                    name: "cbteImporteIva",
                    customClassHeader: "bg-info p-1 text-bold border-bottom-0",
                    tdHeader: true,
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeIva = element.cbteImporteIva*element.cbteImpacto;
                        return(
                            <CurrencyFormat
                                additionalClassName="float-right"
                                value={importeIva}/>
                        )
                    }
                },
                {  
                    name: "cbteImporteFinal",
                    customClassHeader: "bg-info p-1 text-bold border-bottom-0",
                    tdHeader: true,
                    type: "money",
                    render: (element)=>{
                        const importeFinal = element.cbteImporteFinal*element.cbteImpacto;
                        return(
                            <CurrencyFormat
                                additionalClassName="float-right"
                                value={importeFinal}/>
                        )
                    }
                },
                ...getAccionesColumn()
            ]}
        />
    )
}
