import React, { useCallback, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import SimpleDatatable from '../../../../components/SimpleDatatable';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import "popper.js/dist/popper";
import EstadoMovimiento from '../estados/EstadoMovimiento';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const CTA_CTE_MOVIMIENTO_SEARCH = gql`
    query findMovimientoConciliacionListByCliente(
        $cliente: Int
    )  {
        findMovimientoConciliacionListByCliente(
            idCliente: $cliente
        ) {
            id
            adjunto
            estado {
                id
                color
                nombre
            }
            fechaAcreditacion
            fechaRegistro
            registradoPor {
                contacto {
                    alias
                }
            }
            fechaConciliacion
            conciliadoPor {
                contacto {
                    alias
                }
            }
            fechaCancelacion
            canceladoPor {
                contacto {
                    alias
                }
            }
            fechaCobranza
            cobradoPor {
                contacto {
                    alias
                }
            }
            cliente {
                id
                contacto {
                    alias
                }
            }
            moneda {
                id
                simbolo
            }
            formaPago{
                nombre
            }
            retencion{
                nombre
            }
            cuenta{
                nombre
                banco{
                    nombre
                }
                tipoCuenta{
                    nombre
                }
                moneda{
                    simbolo
                }
            }
            impacto
            importe
            detalle
            detalleCbtesAsociados
            chequeBanco{
                nombre
            }
            chequeFecha
            chequeNro
            chequeTitular
            cotizacionPeso
            cotizacionDolar
        }
    }
`;

function MovimientoList(props){
    const {searchFilter, comprobantesSelId, movimientosSelId, toggleMovimientoId, setTotalMovimientosSel} = props;
    const [t] = useTranslation("global");

    const {loading, error, data, refetch} = useQuery(
        CTA_CTE_MOVIMIENTO_SEARCH,
        {
            variables: {
                cliente: searchFilter.cliente?parseInt(searchFilter.cliente):null,
            }
        }
    );

    useEffect(() => {
        if(!loading && !error && data ){
            setTotalMovimientosSel(data?.findMovimientoConciliacionListByCliente);
        }
    }, [data, error, loading, setTotalMovimientosSel])

    const onMovimientoSwitchChange= (id, e)=>{
        toggleMovimientoId(id);
        setTimeout(() => {
            refetch();
        }, 100);
    }

    useEffect(() => {
        if(movimientosSelId){
            setTimeout(() => {
                refetch();
            }, 100);
        }
    }, [movimientosSelId, refetch])

    const isSelected= useCallback((id) =>{
        return movimientosSelId.includes(parseInt(id));
    }, [movimientosSelId]);

    return (<>
        <h4>{t("")}</h4>
        <SimpleDatatable
            data={data?.findMovimientoConciliacionListByCliente}
            editUrl="/private/movimiento/edit/"
            entityName="movimiento"
            columnsDefinition={[
                {
                    name: "id",
                    type: "id"
                },
                {
                    
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-center pl-0 pr-0",
                    className:"text-center",
                    disableSort: true,
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                    }
                },
                {
                    
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoMovimiento element={element}/>)
                    }
                },
                {
                    
                    name: "cliente",
                    render: (element)=>{
                        return(<p><PrivateLink className="parrish-link" to={"/empresa/edit/"+element.cliente.id}>{element.cliente.contacto.alias}</PrivateLink></p>)
                    }
                },
                {
                    name: "fechaAcreditacion",
                    type: "date"
                },
                {
                    name: "formaDePago",
                    render: (element)=>{
                        return(<>
                            <p>{element.formaPago.nombre}</p>
                            {element.retencion&&
                                <p className="text-bold">[{element.retencion.nombre}]</p>
                            }
                            {element.chequeNro&&
                                <p className="text-bold">[{element.chequeNro}]</p>
                            }
                        </>)
                    }
                },
                {
                    name: "cuenta",
                    render: (element)=>{
                        return(<>
                            {
                                element.cuenta &&
                                    element.cuenta?.nombre+" - ["+element.cuenta?.moneda?.simbolo+"]"
                            }
                        </>)
                    }
                },
                {
                    name: "detalle",
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.detalleCbtesAsociados}</p>
                            <p>{element.detalle?"("+element.detalle+")":""}</p>
                        </>)
                    }
                },
                {
                    name: "moneda",
                    className:"text-align-center",
                    customHeader: (<></>),
                    disableSort: true,
                    render: (element)=>{
                        return(element.moneda?.simbolo)
                    }
                },
                {  
                    name: "importe",
                    type: "money",
                    render: (element)=>{
                        const importe = element.importe*element.impacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importe}/>
                        )
                    }
                },
                {
                    name: "movimientosConciliacionSel",
                    className: (searchFilter.cliente?"m-3 text-center":"d-none"),
                    customClassHeader: (searchFilter.cliente?"":"d-none"),
                    customHeader: (<></>),
                    render: (element)=>{
                        if(searchFilter.cliente)
                            return(
                                <div className="custom-control custom-checkbox">
                                    <input className="custom-control-input custom-control-input-info" 
                                        autoComplete="off"
                                        type="checkbox"
                                        id={"movimiento-check-"+element.id}
                                        disabled={!(comprobantesSelId && comprobantesSelId.length>0)}
                                        checked={isSelected(element.id)}
                                        onChange={(e) => {onMovimientoSwitchChange(element.id, e)}} />
                                    <label className="custom-control-label ml-2" htmlFor={"movimiento-check-"+element.id}></label>
                                </div>
                            )
                    }
                }
            ]}
        />
    </>);
}

export default MovimientoList;