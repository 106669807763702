import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_AREAEMPRESA_MUTATION = gql`
    mutation createAreaEmpresa(
        $nombre: String!
    ) {
        createAreaEmpresa(entity:{
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

function AreaEmpresaEdit() {
    const history = useHistory();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createAreaEmpresaMutation] = useMutation(CREATE_AREAEMPRESA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/areaEmpresa";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createAreaEmpresaMutation({
            variables:{
                nombre: nombre
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardCreateEntity
            entityName="areaEmpresa"
            columnsCount={1}
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "nombre",
                    type: "text",
                    maxLength: 100,
                    value: nombre,
                    setValue: setNombre,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("areaEmpresa.field.nombre")})
                    }
                }
            ]}
            returnUrl="/areaEmpresa"
            >
        </CardCreateEntity>
    )
}

export default AreaEmpresaEdit