import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import OffLimitList from './list'
import OffLimitCreate from './create'
import OffLimitEdit from './edit'
import { useHistory, useParams } from 'react-router';

function OffLimitIndex(){
    const entityName = "offLimit";
    const [searchFilter, setSearchFilter] = useState({
        postulante: "",
        estado: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/offLimit/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/offLimit/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <OffLimitList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></OffLimitList>
                :
                null
            }
            {
                mode==='create'?
                    <OffLimitCreate></OffLimitCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <OffLimitEdit></OffLimitEdit>
                :
                null
            }
        </div>
    );
}

export default OffLimitIndex;