import React, { useContext, useEffect, useRef, useState } from 'react'
import "./jquery.loadingModal.css"
import $ from "jquery"
import ProfilerContext from '../../context/ProfilerContext';
import { DateRfc }  from '../../pages/app/DateRfc';

export default function GlobalBlockingLoading(props) {
    const { numPendingQueries, numPendingMutations } = props;
    const [show, setShow] = useState(false);
    const { setOperationsStack, setTime } = useContext(ProfilerContext);

    const [timer, setTimer] = useState(null);

    const showRef = useRef(show);
    showRef.current = show;

    const timerRef = useRef(timer);
    timerRef.current = timer;
    
    useEffect(() => {
        if(numPendingQueries > 0 || numPendingMutations > 0){
            setShow(true);
            if(timer===null){
                setTimer(new DateRfc().getTime());
            }
        }else{
            setShow(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPendingQueries, numPendingMutations]);

    useEffect(() => {
        setOperationsStack(window.sessionStorage.getItem("operations")?JSON.parse(window.sessionStorage.getItem("operations")):[]);
    }, [setOperationsStack, numPendingQueries, numPendingMutations])

    useEffect(() => {
        if(!showRef.current){
            //$(".loading-section").fadeOut("fast");
            setTimeout(() => {
                if(!showRef.current){
                    $(".loading-section").fadeOut("fast");
                }
                if(!showRef.current && timerRef.current!==null && timerRef.current!==undefined){
                    const time = new DateRfc().getTime();
                    const elapsedTime = time-timerRef.current;
                    setTimer(null);
                    setTime(elapsedTime)
                }
            }, 100);
        }else{
            $(".loading-section").fadeIn("fast");
        }
    }, [setTime, showRef]);

    return (
        <section className="loading-section position-fixed">
            <div className="sk-wave">
                <div className="sk-rect sk-rect1"></div>
                <div className="sk-rect sk-rect2"></div>
                <div className="sk-rect sk-rect3"></div>
                <div className="sk-rect sk-rect4"></div>
                <div className="sk-rect sk-rect5"></div>
            </div>
            <p>Cargando...</p>
            {/*
            <p className="m-0 p-0"><small>{numPendingQueries} consultas pendientes...</small></p>
            <p className="m-0 p-0"><small>{numPendingMutations} operaciones pendientes...</small></p>
            */}
        </section>
    )
}