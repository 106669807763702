import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import EmpresaList from './list'
import EmpresaEdit from './edit'
import EmpresaCreate from './create'
import { useHistory, useParams } from 'react-router';

function EmpresaIndex(){
    const entityName = "empresa";
    const [searchFilter, setSearchFilter] = useState({
        alias: "",
        localidad: "",
        industria: "",
        cliente: "",
        pais: "",
        provincia: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <EmpresaList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></EmpresaList>
                :
                null
            }
            {
                mode==='edit'?
                    <EmpresaEdit></EmpresaEdit>
                :
                null
            }
            {
                mode==='create'?
                    <EmpresaCreate></EmpresaCreate>
                :
                null
            }
        </div>
    );
}

export default EmpresaIndex;