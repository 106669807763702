import React, { useContext, useEffect } from 'react'
import PageHeader from '../components/PageHeader';
import { useParams } from 'react-router';
import AuthContext from '../context/AuthContext';

export default function Home() {
    let { jwt } = useParams();
    const { appSetLogin } = useContext(AuthContext);

    useEffect(() => {
        console.log("jwt",jwt);
        if(jwt){
            appSetLogin(jwt);
        }
    }, [appSetLogin, jwt])
    
    return (
        <div className="content-wrapper px-3 background-home" style={{background:"url('/logo-home.png')"}}>
                <PageHeader completeEntityName={"common.menu.home"} completeEntityDesc={""}/>
                <section className="content">
                    <div className="container-fluid">
                    </div>
                </section>
            </div>
    )
}