import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import FacturacionList from './list'
import FacturacionEdit from './edit'
import FacturacionCreate from './create'
import { useHistory, useParams } from 'react-router';

function FacturacionIndex(){
    const entityName = "facturacion";
    const [searchFilter, setSearchFilter] = useState({
        id: "",
        cliente: "",
        estado: "",
        proyecto: "",
        cbteFechaEmisionDesde: "",
        cbteFechaEmisionHasta: "",
        cbteFechaVencimientoDesde: "",
        cbteFechaVencimientoHasta: "",
        cbteTipo: "",
        cbteNro: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <FacturacionList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></FacturacionList>
                :
                null
            }
            {
                mode==='edit'?
                    <FacturacionEdit></FacturacionEdit>
                :
                null
            }
            {
                mode==='create'?
                    <FacturacionCreate></FacturacionCreate>
                :
                null
            }
        </div>
    );
}

export default FacturacionIndex;