
const ESTADO_PROYECTO = {
    PROSPECTO: 1,
    ACTIVO: 2,
    SUSPENDIDO: 3,
    CANCELADO: 4,
    TERMINADO: 5
}
Object.freeze(ESTADO_PROYECTO)

export default ESTADO_PROYECTO