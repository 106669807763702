/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
const translationFiles = [
    "areaEmpresa",
    "areaEstudio",
    "banco",
    "cargo",
    "common",
    "conocimiento",
    "contacto",
    "cuenta",
    "estadoCivil",
    "estadoPostulante",
    "idea",
    "industria",
    "institucion",
    "layout",
    "login",
    "miperfil",
    "nivelConocimiento",
    "nivelEstudio",
    "pais",
    "perfil",
    "retencion",
    "seguimientoAccion",
    "soporte",
    "tipoConocimiento",
    "tipoCuenta",
    "tipoProyecto",
    "usuario",
    "ctaCteCliente",
    "comprobante",
    "movimiento",
    "facturacion",
    "conciliacion",
    "cobranza",
    "offLimit",
    "empresa",
    "postulante",
    "adjunto",
    "site",
    "seguimiento",
    "proyecto",
    "experienciaLaboral",
    "formacionAcademica",
    "conexion",
    "tpsReferencia",
    "acuerdoComercial",
    "qaCheck",
    "misAlertas",
    "novedadesApp",
    "reporteIvaVentas",
    "provincia"
]
export default function () {
    var rtn = {};
    translationFiles.forEach(element => {
        rtn = Object.assign({}, rtn, require("./"+element+".json"));    
    });
    return rtn;
}
