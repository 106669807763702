import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Autocomplete from './Autocomplete'
import $ from 'jquery'
import useAlertMessage from '../hooks/useAlertMessage';
import SelectKeyValue from './SelectKeyValue';

const CREATE_EMPRESA_MUTATION = gql`
    mutation createEmpresa(
        $nombre: String!
        $industria: Int!
    ) {
        createEmpresa(
            entity:{
                nombre: $nombre
                industria: $industria
            }
        ) {
            id
            contacto{
                alias
            }
        }
    }
`;

const FIND_EMPRESA_BY_ID = gql`
    query findEmpresaById(
        $id: Int!
    ) {
    findEmpresaById(
        id: $id
    ){
        id
        contacto{
            alias
        }
    }
}
`;

export default function EmpresaSelector(props) {
    const [t] = useTranslation("global");
    const ref = useRef();
    const {name, empresa, defaultLabel, setEmpresa, disabled, disabledCreate} = props;
    const [initialLabel, setInitialLabel] = useState(defaultLabel?defaultLabel:"")

    const [show, setShow] = useState(false);

    const [nombre, setNombre] = useState("");
    const [industria, setIndustria] = useState("")

    let { showErrorToast, clearPopUps } = useAlertMessage();

    useEffect(() => {
        if(defaultLabel){
            setInitialLabel(defaultLabel);
        }
    }, [defaultLabel]);

    const [createContactoMutation] = useMutation(CREATE_EMPRESA_MUTATION, {
        onCompleted: (data) => {
            clearPopUps();
            setEmpresa(data.createEmpresa.id);
            setInitialLabel(data.createEmpresa.contacto.alias);
            setShow(false);
        },
        onError: (error) => {
            clearPopUps();
            console.error(error);
        }
    }, []);

    const [searchEmpresaById] = useLazyQuery(FIND_EMPRESA_BY_ID, {
        onCompleted: (data) => {
            const empresa = data?.findEmpresaById;
            setEmpresa(empresa?.id);
        }
    });

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!nombre){
            errorList.push({message: t("common.message.field.required", {0: t("empresa.field.nombre")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const handleSubmit = () => {
        if(validate()){
            createContactoMutation({
                variables:{
                    nombre: nombre,
                    industria: industria,
                }
            });       
        }
    }

    useEffect(() => {
        $(ref.current).validate({
            rules:{
                    "value-nombre":{
                        required: true
                    },
                    "value-industria":{
                        required: true
                    }
                },
                messages: {
                    "value-nombre": {
                        required: t("common.message.field.required", {0: t("empresa.field.nombre")})
                    },
                    "value-industria": {
                        required: t("common.message.field.required", {0: t("empresa.field.industria")})
                    }
                },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    const close = () => {
        clearPopUps();
        setShow(false);
    }

    useEffect(() => {
        if(show){
            setNombre("");
            setIndustria("");
        }
    }, [show])

    return (
        <>
            <div className="d-flex w-100">
                <Autocomplete
                    disabled={disabled}
                    name={name}
                    entity="Empresa"
                    by="Alias"
                    filterName="alias"
                    initialLabel={disabled?defaultLabel:initialLabel}
                    value={empresa}
                    setValue={(value)=>{
                        if(setEmpresa&&value){
                            searchEmpresaById({ variables: { id: value } });
                        }
                    }} />
                <button type="button" className="btn btn-xs bg-info ml-1" disabled={disabled||disabledCreate} onClick={()=>{setShow(true)}}>
                    <i className="fas fa-plus"></i>
                </button>
            </div>
            <div className={`modal fade ${show?'show d-flex align-items-center':''}`} id="modal-default" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered w-100">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("empresa.label.entity")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{close()}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-nombre" className="parrish-label col-4 text-right required">{t("empresa.field.nombre")}</label>
                                        <div className="col-8">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-nombre'}
                                                className="parrish-input"
                                                value={nombre}
                                                maxLength={150}
                                                onChange={(e) => setNombre(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-nombre" className="parrish-label col-4 text-right required">{t("empresa.field.industria")}</label>
                                        <div className="col-8">
                                            <SelectKeyValue
                                                entity="Industria"
                                                name={'value-industria'}
                                                by="Nombre"
                                                value={industria}
                                                onChange={e => setIndustria(e.target?.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSubmit()}}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
