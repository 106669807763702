import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext";
import CurrencyFormat from '../../../../components/CurrencyFormat';

const CUENTA_SEARCH = gql`
    query findCuentaPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $nombre: String
        $moneda: Int) {
    findCuentaPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            nombre: $nombre
            moneda: $moneda
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                nombre
                moneda{
                    id
                    simbolo
                }
                titular
                banco{
                    id
                    nombre
                }
                sucursal
                tipoCuenta{
                    id
                    nombre
                }
                nroCuenta
                cbu
                fechaSaldo
                saldo
            }
        }       
    }
}
`;

function CuentaList(props){
    const {searchFilter, setSearchFilter} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "nombre",
        sortDirection: "ASC",
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data } = useQuery(
        CUENTA_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                nombre: searchFilter.nombre,
                moneda: searchFilter.moneda?searchFilter.moneda:null
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="cuenta"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "text",
                        name: "nombre",
                        maxLength: 50
                    },
                    {
                        type: "selectKeyValue",
                        name: "moneda",
                        entity:"Moneda"
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findCuentaPageByFilter"
                entityName="cuenta"
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id"
                    },
                    {
                        name: "nombre",
                        render: (element) =>{
                            return(element.node.nombre+" ["+element.node.moneda.simbolo+"] ")
                        }
                    },
                    {
                        name: "titular",
                        type: "text"
                    },
                    {
                        name: "bancoysucursal",
                        customSortKey: "banco",
                        render: (element) =>{
                            return(
                                <div className="d-flex flex-column">
                                    {element.node.banco &&
                                        (<p><span className="font-weight-bold">Banco: </span>{element.node.banco?.nombre}</p>)
                                    }
                                    {element.node.sucursal &&
                                        (<p><span className="font-weight-bold">Sucursal: </span>{element.node.sucursal}</p>)
                                    }
                                </div>
                            )
                        }
                    },
                    {
                        name: "tipoNroCbu",
                        customSortKey: "tipoCuenta",
                        render: (element) =>{
                            return(
                                <div className="d-flex flex-column">
                                    {element.node.tipoCuenta &&
                                        (<p>
                                            <span className="font-weight-bold">{element.node.tipoCuenta.nombre}</span>
                                            {element.node.nroCuenta &&
                                                ("- ["+element.node.nroCuenta+"]")
                                            }
                                             
                                        </p>)
                                    }
                                    {element.node.cbu &&
                                        (<p><span className="font-weight-bold">CBU: </span>{element.node.cbu}</p>)
                                    }
                                </div>
                            )
                        }
                    },
                    {
                        name: "fechaSaldo",
                        type: "date",
                        translationKey: "cuenta.field.saldo",
                        customSortKey: "saldo",
                        colSpan: 2
                    },
                    {
                        name: "saldo",
                        className: "text-right",
                        render: (element) =>{
                            return(
                                <CurrencyFormat
                                    prefix={element.node.moneda.simbolo}
                                    value={element.node.saldo} />
                            )
                        }
                    },
                ]}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/cuenta/edit/"
                createUrl="/cuenta/create">
            </CardDatatable>
        </>
    );
}

export default CuentaList;