import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import Home from './pages/Home';

import BancoIndex from './pages/app/common/banco/index';
import EstadoCivilIndex from './pages/app/common/estadoCivil/index';
import PaisIndex from './pages/app/common/pais/index';
import SoporteTecnico  from './pages/app/common/soporteTecnico/index';

import CuentaIndex from './pages/app/admin/cuenta/index';
import RetencionIndex from './pages/app/admin/retencion/index';
import TipoCuentaIndex from './pages/app/admin/tipoCuenta/index';

import AreaEmpresaIndex from './pages/app/rrhh/areaEmpresa/index';
import AreaEstudioIndex from './pages/app/rrhh/areaEstudio/index';
import CargoIndex from './pages/app/rrhh/cargo/index';
import ConocimientoIndex from './pages/app/rrhh/conocimiento/index';
import EstadoPostulanteIndex from './pages/app/rrhh/estadoPostulante/index';
import IndustriaIndex from './pages/app/rrhh/industria/index';
import InstitucionIndex from './pages/app/rrhh/institucion/index';
import NivelConocimientoIndex from './pages/app/rrhh/nivelConocimiento/index';
import NivelEstudioIndex from './pages/app/rrhh/nivelEstudio/index';
import SeguimientoAccionIndex from './pages/app/rrhh/seguimientoAccion/index';
import TipoConocimientoIndex from './pages/app/rrhh/tipoConocimiento/index';
import TipoProyectoIndex from './pages/app/rrhh/tipoProyecto/index';
import ContactoIndex from './pages/app/rrhh/contacto/index';
import QaCheckIndex from './pages/app/rrhh/qaCheck/index';

import CobranzaIndex from './pages/app/admin/cobranza/index';
import FacturacionIndex from './pages/app/admin/facturacion/index';
import CtaCteClienteIndex from './pages/app/admin/ctaCteCliente/index';
import ComprobanteIndex from './pages/app/admin/comprobante/index';
import MovimientoIndex from './pages/app/admin/movimiento/index';
import ConciliacionIndex from './pages/app/admin/conciliacion/index';
import OffLimitIndex from './pages/app/gestion/offLimit/index';
import PostulanteIndex from './pages/app/gestion/postulante/index';
import EmpresaIndex from './pages/app/gestion/empresa/index';
import ProyectoIndex from './pages/app/gestion/proyecto/index';

import ReporteIvaVentas from './pages/app/common/reporteIvaVentas/list';

import IdeaIndex from './pages/app/core/idea/index';
import UsuarioIndex from './pages/app/core/usuario/index';
import PerfilIndex from './pages/app/core/perfil/index';
import MisDatos from './pages/app/core/miPerfil/edit';
import useUser from './hooks/useUser';
import ConfigData from './config.json';
import { Redirect } from 'react-router-dom';
import NotFound from './pages/NotFound';

export default function AuthorizedRoutes() {
    function Logout(){
        const { logout } = useUser();
        useEffect(() => {
          logout();
        }, [logout])
        return(<>Cerrando sesion...</>);
    }
    
    return (
        <Switch>
          <Route exact path={`${ConfigData.URL_PREFIX}/home`} component={Home}/>

          <Route exact path={`${ConfigData.URL_PREFIX}/banco/:mode?/:id?`} component={BancoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/estadoCivil/:mode?/:id?`} component={EstadoCivilIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/cuenta/:mode?/:id?`} component={CuentaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/retencion/:mode?/:id?`} component={RetencionIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/tipoCuenta/:mode?/:id?`} component={TipoCuentaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/areaEmpresa/:mode?/:id?`} component={AreaEmpresaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/areaEstudio/:mode?/:id?`} component={AreaEstudioIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/cargo/:mode?/:id?`} component={CargoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/conocimiento/:mode?/:id?`} component={ConocimientoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/estadoPostulante/:mode?/:id?`} component={EstadoPostulanteIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/industria/:mode?/:id?`} component={IndustriaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/institucion/:mode?/:id?`} component={InstitucionIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/nivelConocimiento/:mode?/:id?`} component={NivelConocimientoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/nivelEstudio/:mode?/:id?`} component={NivelEstudioIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/pais/:mode?/:id?`} component={PaisIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/seguimientoAccion/:mode?/:id?`} component={SeguimientoAccionIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/tipoConocimiento/:mode?/:id?`} component={TipoConocimientoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/tipoProyecto/:mode?/:id?`} component={TipoProyectoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/idea/:mode?/:id?`} component={IdeaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/usuario/:mode?/:id?`} component={UsuarioIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/perfil/:mode?/:id?`} component={PerfilIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/agenda-contactos/:mode?/:id?`} component={ContactoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/comprobante/:mode?/:id?`} component={ComprobanteIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/movimiento/:mode?/:id?`} component={MovimientoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/ctaCteCliente/:mode?/:id?`} component={CtaCteClienteIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/facturacion/:mode?/:id?/:comprobanteId?`} component={FacturacionIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/cobranza/:mode?/:id?`} component={CobranzaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/conciliacion/:mode?/:id?`} component={ConciliacionIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/offLimit/:mode?/:id?`} component={OffLimitIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/postulante/:mode?/:id?`} component={PostulanteIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/empresa/:mode?/:id?`} component={EmpresaIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/proyecto/:mode?/:id?`} component={ProyectoIndex}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/qaCheck/:mode?/:id?`} component={QaCheckIndex}/>

          <Route exact path={`${ConfigData.URL_PREFIX}/reporteIvaVentas`} component={ReporteIvaVentas}/>

          

          <Route exact path={`${ConfigData.URL_PREFIX}/mi-perfil`} component={MisDatos}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/soporte-tecnico/`} component={SoporteTecnico}/>
          <Route exact path={`${ConfigData.URL_PREFIX}/logout`} component={Logout}/>
          <Route exact path={`/404`} component={NotFound}/>
          <Route exact path={`/`}>
            <Redirect to={`${ConfigData.URL_PREFIX}/home`} />
          </Route>
          <Redirect to={`/404`} />

        </Switch>
    )
}