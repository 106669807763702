import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery, gql, useMutation } from '@apollo/client';
import AccionesSeguimiendoSelect from '../../../AccionesSeguimiendoSelect'
import Autocomplete from '../../../Autocomplete';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import $ from 'jquery'

const SEGUIMIENTO_SEARCH_BY_ID = gql`
    query findSeguimientoById(
        $id: Int!
    ) {
        findSeguimientoById(
        id: $id
    ){
        id
        accion{
            id
        }
        postulante{
            id
            contacto{
                alias
            }
        }
        empresa{
            id
            contacto{
                alias
            }
        }
        proyecto{
            id
            ref
            alias
            contacto{
                alias
            }
        }
        comentario
        importante
        contacto{
            id
            alias
        }
    }
}
`;

const UPDATE_SEGUIMIENTO_MUTATION = gql`
    mutation updateSeguimiento(
        $id: ID!
        $accion: Int!
        $contacto: Int
        $importante: Boolean!
        $comentario: String
    ) {
        updateSeguimiento(entity:{
            id: $id
            accion: $accion
            contacto: $contacto
            importante: $importante
            comentario: $comentario
        }) {
            id
        }
    }
`;

export default function EditSeguimientoModal(props) {
    const {id, show, setShow, refetch} = props;
    const ref = useRef();
    const [accionSeguimiento, setAccionSeguimiento] = useState(1);
    const [contacto, setContacto] = useState()
    const [importante, setImportante] = useState(false);
    const [comentario, setComentario] = useState("");
    const [postulante, setPostulante] = useState("");
    const [proyecto, setProyecto] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [t] = useTranslation("global");
    let { showErrorToast, clearPopUps } = useAlertMessage();

    const { loading, error, data } = useQuery(
        SEGUIMIENTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [updateSeguimientoMutation] = useMutation(UPDATE_SEGUIMIENTO_MUTATION, {
        onCompleted: (data) => {
            refetch();
            setShow(false);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!accionSeguimiento){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.accion")})});
        }       
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const saveAction = () =>{
        if(validate()){
            updateSeguimientoMutation({
                variables:{
                    id: id?id:null,
                    accion: accionSeguimiento?accionSeguimiento:null,
                    contacto: contacto?contacto:null,
                    importante: importante,
                    comentario: comentario?comentario:null
                }
            })
        }
    }

    useEffect(() => {
        if(!loading && !error){
            const seguimiento = data?.findSeguimientoById;
            setAccionSeguimiento(seguimiento?.accion?.id);
            setContacto(seguimiento?.contacto?.id);
            setImportante(seguimiento?.importante);
            setComentario(seguimiento?.comentario);
            setEmpresa(seguimiento?.empresa?.id);
            setPostulante(seguimiento?.postulante?.id);
            setProyecto(seguimiento?.proyecto?.id);
        }
    }, [data?.findSeguimientoById, error, loading])

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide')
    }, [show])

    return(
        <div ref={ref} className="modal fade" id="modal-edit-seguimiento" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header pl-3 p-2">
                        <h5 className="modal-title text-dark">{t("common.label.detalle")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShow(false)}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body parrish-form pt-2 pb-2">

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.label.entity")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-postulante"
                                            entity="Postulante"
                                            by="Alias"
                                            placeholder={t("postulante.label.entity")}
                                            filterName="alias"
                                            disabled={true}
                                            value={postulante}
                                            initialLabel={data?.findSeguimientoById?.postulante?.contacto?.alias}
                                            setValue={(val)=>setPostulante(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.proyecto")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-proyecto"
                                            entity="Proyecto"
                                            by="Alias"
                                            placeholder={t("postulante.field.proyecto")}
                                            filterName="alias"
                                            disabled={true}
                                            value={proyecto}
                                            initialLabel={data?.findSeguimientoById?.proyecto?.ref}
                                            setValue={(val)=>setProyecto(val)} /> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.empresa")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-empresa"
                                            entity="Empresa"
                                            by="Alias"
                                            placeholder={t("postulante.field.empresa")}
                                            filterName="alias"
                                            disabled={true}
                                            value={empresa}
                                            initialLabel={data?.findSeguimientoById?.empresa?.contacto?.alias}
                                            setValue={(val)=>setEmpresa(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("seguimiento.field.contacto")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-contacto"
                                            entity="Contacto"
                                            by="PersonaFisicaAlias"
                                            filterName="alias"
                                            disabled={true}
                                            additionalFilter="personaFisica: true"
                                            value={contacto}
                                            initialLabel={data?.findSeguimientoById?.contacto?.alias}
                                            setValue={(value)=>setContacto(value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2 required">{t("seguimiento.field.accion")}</label>
                                    <div className="col-10">
                                        <AccionesSeguimiendoSelect value={accionSeguimiento} setValue={setAccionSeguimiento}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="row mt-2">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="col-12 text-left font-weight-normal">{t("seguimiento.field.comentario")}</label>
                                    <div className="col-12">
                                        <textarea name="value-detalle" value={comentario} onChange={(e)=>{setComentario(e.target.value)}} className="form-control form-control-sm" rows="10"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm text-center">
                                <div className="custom-control custom-checkbox">
                                    <input className="custom-control-input custom-control-input-info" 
                                        autoComplete="off"
                                        type="checkbox"
                                        id="importante-check"
                                        checked={importante}
                                        onChange={(e) => {setImportante(e.target.checked)}} />
                                    <label className="custom-control-label" htmlFor="importante-check">{t("seguimiento.field.importante")}</label>
                                </div>
                            </div>
                        </div>                       

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn bg-info ml-1" onClick={()=>{saveAction()}}>
                            <i className="fas fa-save mr-2"></i>Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
