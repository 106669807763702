import React from 'react'
import Datatable from './Datatable';
import { useTranslation } from "react-i18next"
import PrivateLink from './navigation/PrivateLink';

export default function CardDatatable(props) {
    const {
        data,
        loading,
        error,
        sortOptions,
        dataWrapper,
        entityName,
        columns,
        customCellDefinition,
        setPageSize,
        setPageNumber,
        setSortOptions,
        pageSize,
        pageNumber,
        editUrl,
        createUrl,
        columnsDefinition,
        conditionalRowStyle,
        conditionalRowValue,
        className,
        headerTop,
        headerBottom
    } = props;

    const [t] = useTranslation("global");
    
    return (
        <>
        {
            data &&
            data[dataWrapper] &&
            (
                <div className={className?"card "+className:"card card-outline card-info"}>
                    {/*
                        loading &&
                        !error && (
                            <div className="overlay">
                                <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                            </div>
                        )
                    */}
                    <div className="card-header pl-3 pt-2 pb-2">
                        <h3 className="card-title">{t("common.label.listado")} <span className="badge badge-info ml-2 p-1 pr-2 pl-2">{t("common.label.encontrados",{0: data[dataWrapper]?.queryInfo?.totalElements})}</span></h3>
                        <div className="card-tools m-0">
                            {createUrl&&
                                <PrivateLink className="btn btn-xs btn-info bg-info btn-tool mr-2" to={createUrl} title={t("common.button.agregar")}>
                                    <i className="fas fa-plus"></i>
                                </PrivateLink>
                            }
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <button className="dropdown-item btn btn-info buttons-pdf buttons-html5">{t("common.label.exportar-pdf")}</button>
                                <button className="dropdown-item btn btn-info buttons-csv buttons-html5">{t("common.label.exportar-csv")}</button>
                                <button className="dropdown-item btn btn-info buttons-excel buttons-html5">{t("common.label.exportar-xls")}</button>
                                <button className="dropdown-item btn btn-info buttons-print">{t("common.label.exportar-impresora")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Datatable
                            data={data}
                            loading={loading}
                            error={error}
                            sortOptions={sortOptions}
                            dataWrapper={dataWrapper}
                            entityName={entityName}
                            columns={columns}
                            customCellDefinition={customCellDefinition}
                            columnsDefinition={columnsDefinition}
                            setPageSize={setPageSize}
                            setPageNumber={setPageNumber}
                            setSortOptions={setSortOptions}
                            pageSize={pageSize}
                            pageNumber={pageNumber}
                            editUrl={editUrl}
                            createUrl={createUrl}
                            conditionalRowStyle={conditionalRowStyle}
                            conditionalRowValue={conditionalRowValue}
                            headerTop={headerTop}
                            headerBottom={headerBottom}>
                        </Datatable>
                    </div>
                </div>
            )
        }
        </>
    )
}
