import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Datatable from '../../../../../components/Datatable';
import AuthContext from '../../../../../context/AuthContext';
import Moment from 'react-moment';
import moment from 'moment'
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import FlagsProyecto from '../../../../../components/FlagsProyecto';
import { useHistory } from 'react-router-dom';

const PROYECTOS_LIST = gql`
    query findProyectoPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int
    ) {
        findProyectoPageByFilter(
        filter: {
            empresa: $id
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                fechaInicioBusqueda
                ref
                fechaCierreAdministrativo
                fechaCierreQa
                fechaCierreBusqueda
                fechaOriginacionPlacement
                areaEmpresa{
                    nombre
                }
                cargo{
                    id
                    nombre
                }
                posicion
                estado{
                    id
                    nombre
                    color
                }
                empresa{
                    contacto{
                        id
                        foto
                        personaFisica
                        alias
                        email
                        telefonosMoviles
                    }
                }
                contacto{
                    id
                    foto
                    personaFisica
                    alias
                    email
                    telefonosMoviles
                }
                qaInfo{
                    indice
                    recomendaciones
                }
                adjuntoList{
                    referencia
                    fechaActualizacion
                    contentType
                    nombreOriginal
                    nombreServidor
                    responsable{
                        contacto{
                            alias
                        }
                    }
                }
                placementList{
                    id
                    label
                }
            }
        }
    }
}
`;

export default function ProyectosTab() {
    const history = useHistory();
    let { id } = useParams();
    const [t] = useTranslation("global");
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaInicioBusqueda",
        sortDirection: "DESC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if(id){
            setPageNumber(0)
        }
    }, [id]);

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const {loading, error, data} = useQuery(
        PROYECTOS_LIST,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: id
            }
        }
    );

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-body pr-0 pl-0">
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findProyectoPageByFilter"
                    entityName="proyecto"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onEdit={(element)=>{
                        history.push("/private/proyecto/edit/"+element.node.id);
                    }}
                    columnsDefinition={[
                        {
                            name: "id",
                            type: "id",
                            className: "text-center"
                        },
                        {
                            name: "proyecto",
                            customSortKey: "contacto.alias",
                            translationKey:"proyecto.field.proyecto",
                            className: "col-2",
                            render: (element) =>{
                                return(
                                    <>
                                        <div className="row">
                                            <div className="col d-flex align-items-center justify-content-center pr-3 pl-3">
                                                <div className="text-center d-flex flex-column mt-3 mb-3">
                                                    <div className="text-center d-flex flex-row align-items-center justify-content-center flex-fill">
                                                        <FlagsProyecto proyecto={element.node}/>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <p className={"badge mt-2 text-uppercase badge-"+element.node.estado.color+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.bus")}: </span>
                                                            {element.node.estado.nombre}
                                                        </p>
                                                        <p className={"badge text-uppercase badge-"+(element.node.fechaCierreAdministrativo ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.adm")}: </span>
                                                            {element.node.fechaCierreAdministrativo ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    
                                                        <p className={"badge text-uppercase badge-"+(element.node.fechaCierreQa ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.qa")}: </span>
                                                            {element.node.fechaCierreQa ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-center flex-fill">
                                                    <ContactoAvatarImg personaFisica={element.node.empresa.contacto.personaFisica} id={element.node.empresa.contacto.id} foto={element.node.empresa.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={element.node.qaInfo?.recomendaciones?element.node.qaInfo?.recomendaciones:null}>
                                                        <span className="h5 text-nowrap">{t("common.label.qa")} {element.node.qaInfo.indice}%</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h5>{element.node.ref}</h5>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        },
                        {
                            name: "inicioPlacement",
                            customSortKey:"fechaInicioBusqueda",
                            className:"text-align-center",
                            render: (element)=>{
                                const from = moment(element.node.fechaInicioBusqueda);
                                const to = element.node.fechaOriginacionPlacement?moment(element.node.fechaOriginacionPlacement):moment();
                                const days = to.diff(from, 'days');
                                const dayLabel = Math.abs(days)===1?t("common.label.dia"):t("common.label.dias");
                                const textDesde = (days<0?(t("common.label.hace")+" "):"")+Math.abs(days)+" "+dayLabel;
                                return(<>
                                    {element.node.fechaInicioBusqueda&&
                                        <Moment format={"DD-MM-yyyy"}>{element.node.fechaInicioBusqueda}</Moment>
                                    }
                                    {element.node.fechaOriginacionPlacement&&
                                        <span>
                                            &nbsp;-&nbsp;
                                            <Moment format={"DD-MM-yyyy"}>{element.node.fechaOriginacionPlacement}</Moment>
                                        </span>
                                    }
                                    <p className="text-align-center">{textDesde}</p>
                                </>)
                            }
                        },
                        {
                            name: "ref",
                            translationKey:"proyecto.field.busquedaPerfil",
                            disableSort: true,
                            type: "text",
                            render: (element)=>{
                                return(<>
                                    <p><span className="text-bold">{t("proyecto.field.areaEmpresa")}: </span>{element.node.areaEmpresa.nombre}</p>
                                    <p><span className="text-bold">{t("proyecto.field.cargo")}: </span>{element.node.cargo.nombre}</p>
                                    <p><span className="text-bold">{t("proyecto.field.posicion")}: </span>{element.node.posicion}</p>
                                </>)
                            }
                        },
                        {
                            name: "placements",
                            translationKey:"proyecto.field.placements",
                            disableSort: true,
                            type: "text",
                            render: (element)=>{
                                return(<>
                                    {element.node.placementList.map(element=>
                                        <p key={element.label}>{element.label}</p>
                                    )}
                                </>)
                            }
                        },
                        {
                            name: "contacto",
                            type: "text",
                            disableSort: true,
                            render: (element)=>{
                                return(<>
                                    <p className="text-bold">{element.node.contacto?.alias}</p>
                                    {element.node.contacto?.email&&
                                        <div role="link" class="parrish-link-light" onClick={(e)=>{emailClick(e,element.node.contacto?.email)}}>
                                            <i className="fas fa-envelope mr-1"></i>
                                            {element.node.contacto?.email}
                                        </div>
                                    }
                                    {element.node.contacto?.telefonosFijos&&
                                        <p><i className="far fas fa-phone mr-1"></i>{element.node.contacto?.telefonosFijos}</p>
                                    }
                                    {element.node.contacto?.telefonosMoviles&&
                                        <div role="link" class="parrish-link-light" onClick={(e)=>{whatsappClick(e,element.node.contacto?.telefonosMoviles)}}>
                                            <i className="fab fa-whatsapp mr-1"></i>
                                            {element.node.contacto?.telefonosMoviles}
                                        </div>
                                    }
                                </>);
                            }
                        }
                    ]}>
                    </Datatable>
            </div>
        </div>
    )
}