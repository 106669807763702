import React from 'react'
import { useMutation, gql } from '@apollo/client';
import ESTADO_PROYECTO from '../enums/EstadoProyecto';

const ACTIVAR_PROYECTO = gql`
    mutation activarProyecto(
        $idProyecto: Int!
    ) {
        activarProyecto(idProyecto: $idProyecto){
            id
        }
    }
`;

const SUSPENDER_PROYECTO = gql`
    mutation suspenderProyecto(
        $idProyecto: Int!
    ) {
        suspenderProyecto(idProyecto: $idProyecto){
            id
        }
    }
`;

const CANCELAR_PROYECTO = gql`
    mutation cancelarProyecto(
        $idProyecto: Int!
    ) {
        cancelarProyecto(idProyecto: $idProyecto){
            id
        }
    }
`;

const TERMINAR_PROYECTO = gql`
    mutation terminarProyecto(
        $idProyecto: Int!
    ) {
        terminarProyecto(idProyecto: $idProyecto){
            id
        }
    }
`;

const REABRIR_PROYECTO = gql`
    mutation reabrirProyecto(
        $idProyecto: Int!
    ) {
        reabrirProyecto(idProyecto: $idProyecto){
            id
        }
    }
`;

export default function ProyectoStatusControl(props) {
    const {proyecto, refetch} = props;

    const isActivo = ()=>{
        return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.ACTIVO;
    }

    const isProspecto = ()=>{
        return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
    }

    const isCanceladoSuspendidoOTerminado = ()=>{
        return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.CANCELADO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.SUSPENDIDO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.TERMINADO;
    }

    const [activarProyectoMutation] = useMutation(ACTIVAR_PROYECTO, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);
    const [suspenderProyectoMutation] = useMutation(SUSPENDER_PROYECTO, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);
    const [cancelarProyectoMutation] = useMutation(CANCELAR_PROYECTO, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);
    const [terminarProyectoMutation] = useMutation(TERMINAR_PROYECTO, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);
    const [reabrirProyectoMutation] = useMutation(REABRIR_PROYECTO, {
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);
    
    return (
        <>
            {isProspecto()&&
                <span>
                    <button type="button"  className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{activarProyectoMutation({variables:{idProyecto:proyecto?.id}})}}>
                        ACTIVAR
                    </button>
                </span>
            }
            {isActivo()&&
                <span>
                    <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{terminarProyectoMutation({variables:{idProyecto:proyecto?.id}})}}>
                        TERMINAR
                    </button>
                </span>
            }
            {isActivo()&&
                <span>
                    <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{suspenderProyectoMutation({variables:{idProyecto:proyecto?.id}})}}>
                        SUSPENDER
                    </button>
                </span>
            }
            {(isActivo()||isProspecto())&&
                <span>
                    <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{cancelarProyectoMutation({variables:{idProyecto:proyecto?.id}})}}>
                        CANCELAR
                    </button>
                </span>
            }
            {isCanceladoSuspendidoOTerminado()&&
                <span>
                    <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{reabrirProyectoMutation({variables:{idProyecto:proyecto?.id}})}}>
                        REABRIR
                    </button>
                </span>
            }
        </>
    )
}
