import React, { useState, useRef } from 'react'
import Form from './Form'
import { useTranslation } from "react-i18next"
import CardFooterRecordData from './CardFooterRecordData';
import { useHistory } from 'react-router-dom';

export default function CardEditEntity(props) {
    const [submitButtonRef, setSubmitButtonRef] = useState('');
    const history = useHistory();
    const {entityName, columnsCount, handleSubmit, values, returnUrl, returnAction, id, label, deleteAction, /*loading, error,*/ className, customTools, customHeader, disableDelete, disableSave, record} = props;
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    
    return (
        <div className={className?"card "+className:"card card-outline card-info"}>
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header">
                <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", {0: id})}{customHeader}</h3>
                <div className="card-tools m-0">
                    {
                        customTools
                    }
                    {!disableSave&&
                        <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{if(_isMounted.current){submitButtonRef?.click()}}}>
                            <i className="fas fa-save"></i>
                        </button>
                    }
                    {!disableDelete&&
                        <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>deleteAction(id, label)}>
                            <i className="fas fa-trash"></i>
                        </button>
                    }
                    {
                        returnUrl?
                            <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                                <i className="fas fa-times"></i>
                            </button>
                        :
                        null
                    }
                    {
                        returnAction?
                            <button className="btn btn-xs bg-info btn-tool ml-2" onClick={returnAction}>
                                <i className="fas fa-times"></i>
                            </button>
                        :
                        null
                    }
                </div>
            </div>
            <div className="card-body">
                <Form
                    entityName={entityName}
                    columnsCount={columnsCount}
                    handleSubmit={handleSubmit}
                    setSubmitButtonRef={setSubmitButtonRef}
                    values={values}
                >
                </Form>
            </div>
            {props.children}
            {record &&
                <CardFooterRecordData record={record} />
            }
        </div>
    )
}
