import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import ProyectoList from './list'
import ProyectoEdit from './edit'
import ProyectoCreate from './create'
import { useHistory, useParams } from 'react-router';

function ProyectoIndex(){
    const entityName = "proyecto";
    const [searchFilter, setSearchFilter] = useState({
        alias: "",
        localidad: "",
        industria: "",
        pais: "",
        provincia: "",
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <ProyectoList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></ProyectoList>
                :
                null
            }
            {
                mode==='edit'?
                    <ProyectoEdit></ProyectoEdit>
                :
                null
            }
            {
                mode==='create'?
                    <ProyectoCreate></ProyectoCreate>
                :
                null
            }
        </div>
    );
}

export default ProyectoIndex;