import React from 'react'
import CurrencyFormat from '../../../../../../../components/CurrencyFormat'
import DownloadAdjunto from '../../../../../../../components/DownloadAdjunto'
import SimpleDatatable from '../../../../../../../components/SimpleDatatable'
import EstadoMovimiento from '../../../../../admin/estados/EstadoMovimiento'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import Moment from 'react-moment'
export default function MovimientosList(props) {
    const [t] = useTranslation("global");
    const { data } = props;

    
    const totalImporte = data?data["findMovimientoListByProyecto"]?.reduce((total, currVal) =>{
        return total + currVal.importe*currVal.impacto
    }, 0):0;

    return (
        <SimpleDatatable
            data={data?.findMovimientoListByProyecto}
            editUrl="/private/movimiento/edit/"
            entityName="movimiento"
            headerTop={
                <tr>
                    <td className="bg-info text-left p-1 pl-3 text-bold border-0" colSpan="10">
                        <div>{t("movimiento.label.entities")}</div>
                    </td>
                </tr>
            }
            headerBottom={
                <tr>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        {data?
                         data["findMovimientoListByProyecto"]?
                         data["findMovimientoListByProyecto"][0]?
                         data["findMovimientoListByProyecto"][0].moneda?.simbolo:"":"":""}
                    </td>
                    <td className="bg-white p-1 text-bold border-bottom-0">
                        <CurrencyFormat
                            additionalClassName="float-right"
                            value={totalImporte}/>
                    </td>
                </tr>
            }
            columnsDefinition={[
                {
                    name: "id",
                    type: "id",
                    rowSpan: 2
                },
                {
                    
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-center pl-0 pr-0",
                    disableSort: true,
                    className: "text-center",
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.adjunto} entity="Movimiento"/>)
                    },
                    rowSpan: 2
                },
                {
                    
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoMovimiento element={element}/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "fechaAcreditacion",
                    className:"text-center",
                    type: "date",
                    render: (element)=>{
                        const from = moment();
                        const to = element?.fechaAcreditacion?moment(element?.fechaAcreditacion):moment();
                        const days = to.diff(from, 'days');
                        const dayLabel = Math.abs(days)===1?t("common.label.dia"):t("common.label.dias");
                        const textDesde = days?"["+(days<0?(t("common.label.hace")+" "):"")+Math.abs(days)+" "+dayLabel+"]":"";
                        return(<>
                            <Moment className="date-centered" format={'DD-MM-YYYY'}>{to}</Moment>
                            <p className={"text-center "+(days>0?"":"text-danger")}>{textDesde}</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    
                    name: "formaPago",
                    render: (element)=>{
                        return(<>
                            <p>{element.formaPago.nombre}</p>
                            {element.retencion&&
                                <p className="text-bold">[{element.retencion.nombre}]</p>
                            }
                            {element.chequeNro&&
                                <p className="text-bold">[{element.chequeNro}]</p>
                            }
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    
                    name: "cuenta",
                    render: (element)=>{
                        const tipoCuentaNombre = element.cuenta?.tipoCuenta?.nombre.split(" ").reduce((initials, currVal) => initials + currVal[0].toUpperCase(), "");
                        return(<>
                            {
                                element.cuenta &&
                                    element.cuenta?.banco?.nombre+" - "+tipoCuentaNombre+" ["+element.cuenta?.moneda?.simbolo+"]"
                            }
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    
                    name: "detalle",
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.detalleCbtesAsociados}</p>
                            <p>{element.detalle?"("+element.detalle+")":""}</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    
                    name: "moneda",
                    customClassHeader: "bg-info p-1 text-bold border-bottom-0",
                    tdHeader: true,
                    customHeader: (<></>),
                    className: "text-center",
                    disableSort: true,
                    render: (element)=>{
                        return(element.moneda?.simbolo)
                    }
                },
                {  
                    name: "importe",
                    customClassHeader: "bg-info p-1 text-bold border-bottom-0",
                    tdHeader: true,
                    type: "money",
                    render: (element)=>{
                        const importe = element.importe*element.impacto;
                        return(
                            <CurrencyFormat
                                additionalClassName="float-right"
                                value={importe}/>
                        )
                    }
                }
            ]}
        />
    )
}
