import React, { useEffect, useRef } from 'react'
import $ from 'jquery'
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import SelectEntity from './SelectEntity';
import DatePicker from './DatePicker';
import useAlertMessage from '../hooks/useAlertMessage';
import Autocomplete from './Autocomplete';
import SelectKeyValue from './SelectKeyValue';
import { DateRfc }  from '../pages/app/DateRfc';

export default function Form(props) {
    const {entityName, columnsCount, handleSubmit, setSubmitButtonRef, values, errorDivId, validationNotIgnore = true} = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let { showErrorToast } = useAlertMessage();

    useEffect(() => {
        if(_isMounted.current){
            const ignoreProp = validationNotIgnore?{
                ignore: "",
            }:{};
            let validationJson = {
                ...ignoreProp,
                rules:{},
                messages: {},
                errorElement: 'span',
                errorClass: "text-danger text-sm",
                onkeyup: false,
                onclick: false,
                onfocusout: false,
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                    $("#" + errorDivId).find("li#error-"+element.name).remove();
                },
                showErrors: function(errorMap, errorList) {
                    showErrorToast(errorList);
                }
            };

            if(errorDivId){
                validationJson.errorPlacement = function (error, element) {
                    const nameElement = element.attr("name");
                    if($("#" + errorDivId).find("li#error-"+nameElement).length<=0){
                        $("#" + errorDivId).append('<li id="error-'+nameElement+'"></li>');
                    }
                    $("#" + errorDivId).find("li#error-"+nameElement).html(error);
                };
            }

            values.forEach(function(value) {
                if(value.type!=="dateRange"){
                    validationJson.rules['value-'+value.name]={};
                    validationJson.messages['value-'+value.name]={};
                }else{
                    validationJson.rules['value-'+value.name+'-start']={};
                    validationJson.messages['value-'+value.name+'-end']={};
                    validationJson.rules['value-'+value.name+'-start']={};
                    validationJson.messages['value-'+value.name+'-end']={};
                }

                if(value.validationRules){
                    if(value.type!=="dateRange"){
                        validationJson.rules['value-'+value.name]=value.validationRules;
                    }else{
                        validationJson.rules['value-'+value.name+'-start']=value.validationRulesStart?value.validationRulesStart:value.validationRules;
                        validationJson.rules['value-'+value.name+'-end']=value.validationRulesEnd?value.validationRulesEnd:value.validationRules;
                    }
                }
                
                if(value.type!=="dateRange"){
                    if(value.validationMessages){
                        validationJson.messages['value-'+value.name]=value.validationMessages;
                    }
                }else{
                    if(value.validationMessagesStart){
                        validationJson.messages['value-'+value.name+'-start']=value.validationMessagesStart;
                    }
                    if(value.validationMessagesEnd){
                        validationJson.messages['value-'+value.name+'-end']=value.validationMessagesEnd;
                    }
                }

                if(value.validationJson){
                    validationJson.rules = Object.assign({}, validationJson.rules, value.validationJson.rules);
                    validationJson.messages = Object.assign({}, validationJson.messages, value.validationJson.messages);
                }
            });
            console.log(validationJson);
            $(ref.current).validate(validationJson);   
        }
        return () => {
            _isMounted.current = false;
        };
    },[values, errorDivId, showErrorToast, validationNotIgnore]);

    const renderColumn = (value) =>{
        const labelClassName = value.labelClassName ? value.labelClassName : "col-sm-4 col-xs-12";
        const inputClassName = value.inputClassName ? value.inputClassName : "col";
        const required = value.validationRules?.required?value.validationRules?.required:false;
        const translationKey = value.translationKey?value.translationKey:entityName+".field."+value.name;
        return(
            <div className="form-group row">
                <label htmlFor={'value-'+value.name} className={`parrish-label ${labelClassName} ${required?"required":""} ${value.type==="textarea"?"align-self-start":""} `}>{t(translationKey)}</label>
                <div className={inputClassName}>
                    {renderFilterComponent(value)}
                </div>
            </div>
        );
    }

    //TODO CHRIS forzar maxlength en los inputs de texto
    //ya sean input type=text o textarea
    const renderFilterComponent = (value) =>{
        switch(value.type) {
            case 'text':
                if(value.iconClass || value.iconText){
                    const iconRight = value?.iconPosition === "right";
                    const inputGroupClassname = iconRight?"input-group-append":"input-group-prepend";
                    const inputIcon = (
                        <div className={inputGroupClassname}>
                            <span className="input-group-text">
                                { value.iconClass&&
                                    <i className={value.iconClass}></i>
                                }
                                {value.iconText?value.iconText:""}
                            </span>
                        </div>
                    );
                    const right = iconRight ? inputIcon:null;
                    const left = !iconRight ? inputIcon:null;
                    return(
                        <div className="input-group input-group-sm">
                            {left}
                            <input autoComplete="off"
                                type="text"
                                name={'value-'+value.name}
                                className="parrish-input"
                                id={'value-'+value.name}
                                value={value.value}
                                maxLength={value.maxLength?value.maxLength:150}
                                onChange={(e) => value.setValue(e.target.value)}
                                disabled={value.disabled}/>
                            {right}
                        </div>
                    )
                }else{
                    return (
                        <input autoComplete="off"
                            type="text"
                            name={'value-'+value.name}
                            className="parrish-input"
                            id={'value-'+value.name}
                            value={value.value}
                            maxLength={value.maxLength?value.maxLength:150}
                            onChange={(e) => value.setValue(e.target.value)}
                            disabled={value.disabled}/>
                    );
                }
            case 'textarea':
                return(
                    <textarea
                        id={'value-'+value.name}
                        name={'value-'+value.name}
                        value={value.value}
                        onChange={(e) => value.setValue(e.target.value)}
                        disabled={value.disabled}
                        className="form-control form-control-sm"
                        rows="3"/>
                )
            case 'selectKeyValue':
                return (
                    <SelectKeyValue
                        {...value}
                        name={'value-'+value.name}
                        onChange={(e) => { value.setValue(e.target.value) }} />
                )
            case 'select':
                return (
                    <SelectEntity
                        entity={value.entity}
                        label={value.label}
                        name={'value-'+value.name}
                        value={value.value}
                        onChange={(e) => value.setValue(e.target.value)}
                        disabled={value.disabled}
                        required/>
                );
            case 'date':
                let selectedDate = new DateRfc(value.value);
                selectedDate = selectedDate instanceof Date && !isNaN(selectedDate)?selectedDate:null;
                return (
                    <DatePicker dateFormat={value.format}
                        selected={selectedDate}
                        onChange={date => value.setValue(date)}
                        disabled={value.disabled}/>
                );
            case 'dateRange':
                return (
                    <div className="row">
                        <DatePicker
                            name={'value-'+value.name+'-start'}
                            dateFormat={value.format}
                            selected={value.startDate}
                            onChange={date => value.setStartDate(date)}
                            className="col-sm-6 col"
                            selectsStart
                            startDate={value.startDate}
                            endDate={value.endDate}
                            disabled={value.disabled}/>
                        <DatePicker
                            name={'value-'+value.name+'-end'}
                            dateFormat={value.format}
                            selected={value.endDate}
                            onChange={date => value.setEndDate(date)}
                            className="col-sm-6 col"
                            selectsEnd
                            startDate={value.startDate}
                            endDate={value.endDate}
                            minDate={value.startDate}
                            disabled={value.disabled}/>
                    </div>
                    );
            case 'boolean':
                return(
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input custom-control-input-info" 
                            autoComplete="off"
                            type="checkbox"
                            id={"comprobante-check-"+value.name}
                            checked={value.value}
                            onChange={(e) => value.setValue(e.target.checked)} />
                        <label className="custom-control-label ml-2" htmlFor={"comprobante-check-"+value.name}></label>
                    </div>
                );
            case 'autocomplete':
                return (
                    <Autocomplete
                        name={'value-'+value.name}
                        entity={value.entity}
                        by={value.by}
                        filterName={value.filterName}
                        value={value.value}
                        setValue={(val)=>value.setValue(val)} />);
            case 'custom':
                return value.componentRender?value.componentRender():null;
            default:
                return (
                    <p className="text-danger">Type not implemented</p>
                );
        }
    }

    const rowColsClass = columnsCount ? "row-cols-"+columnsCount : "";

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={`parrish-form row ${rowColsClass}`}>
            {
                values?.map((value)=>{
                    const componentClassName = value.componentClassName ? value.componentClassName : "col-xs-12 col-sm-6";
                    if(value.render!=null){
                        return (
                            <div key={value.name} className={componentClassName}>
                                {value.render()}
                            </div>
                        );
                    }
                    return (
                        <div key={value.name} className={componentClassName}>
                            {renderColumn(value)}
                        </div>
                    );
                })
            }
            </div>
            <button ref={input => setSubmitButtonRef(input)} type="submit" className="d-none" />
        </form>
    )
}