import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import AppStateContext from "../../../../context/AuthContext"
import { useTranslation } from 'react-i18next';
import EstadoMovimiento from '../estados/EstadoMovimiento';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const MOVIMIENTO_SEARCH = gql`
    query findMovimientoPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int
        $cliente: Int
        $cuenta: Int
        $formaPago: Int
        $retencion: Int
        $fechaAcreditacionDesde: LocalDate
        $fechaAcreditacionHasta: LocalDate
        $estado: Int
        $proyecto: Int
    ) {
    findMovimientoPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            id: $id
            cliente: $cliente
            cuenta: $cuenta
            formaPago: $formaPago
            retencion: $retencion
            fechaAcreditacionDesde: $fechaAcreditacionDesde
            fechaAcreditacionHasta: $fechaAcreditacionHasta
            estado: $estado
            proyecto: $proyecto
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                adjunto
                estado {
                    id
                    color
                    nombre
                }
                fechaAcreditacion
                fechaRegistro
                registradoPor {
                    contacto {
                        alias
                    }
                }
                fechaConciliacion
                conciliadoPor {
                    contacto {
                        alias
                    }
                }
                fechaCancelacion
                canceladoPor {
                    contacto {
                        alias
                    }
                }
                fechaCobranza
                cobradoPor {
                    contacto {
                        alias
                    }
                }
                cliente {
                    id
                    contacto{
                        alias
                    }
                }
                moneda {
                    id
                    simbolo
                }
                formaPago{
                    nombre
                }
                retencion{
                    nombre
                }
                cuenta{
                    nombre
                    banco{
                        nombre
                    }
                    tipoCuenta{
                        nombre
                    }
                    moneda{
                        simbolo
                    }
                }
                impacto
                importe
                cotizacionPeso
                detalle
                detalleCbtesAsociados
                chequeBanco{
                    nombre
                }
                chequeFecha
                chequeNro
                chequeTitular
            }
        }       
    }
}
`;

function MovimientoList(props){
    const [t] = useTranslation("global");
    const {searchFilter, setSearchFilter} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaAcreditacion",
        sortDirection: "DESC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const codigoMonedaTotales="AR$";

    const {loading, error, data} = useQuery(
        MOVIMIENTO_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: searchFilter?.id?parseInt(searchFilter?.id):null,
                cliente: searchFilter?.cliente?parseInt(searchFilter?.cliente):null,
                cuenta: searchFilter?.cuenta?parseInt(searchFilter?.cuenta):null,
                formaPago: searchFilter?.formaPago?parseInt(searchFilter?.formaPago):null,
                retencion: searchFilter?.retencion?parseInt(searchFilter?.retencion):null,
                fechaAcreditacionDesde: searchFilter?.fechaAcreditacionDesde?searchFilter?.fechaAcreditacionDesde:null,
                fechaAcreditacionHasta: searchFilter?.fechaAcreditacionHasta?searchFilter?.fechaAcreditacionHasta:null,
                estado: searchFilter?.estado?parseInt(searchFilter?.estado):null,
                proyecto: searchFilter?.proyecto?parseInt(searchFilter?.proyecto):null
            }
        }
    );

    const totalImporte  = data?data["findMovimientoPageByFilter"]?.edges.reduce((total, currVal) => {
        return total + currVal.node.importe*currVal.node.cotizacionPeso*currVal.node.impacto;
    }, 0):0;

    return (
        <>
            <CardFilter
                entityName="movimiento"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "id",
                        type: "number",
                    },
                    {
                        type: "select",
                        name: "estado",
                        entity:"EstadoAdministrativo",
                        label: "nombre"
                    },
                    {
                        type: "autocomplete",
                        name: "cliente",
                        entity:"EmpresaCliente",
                        by: "Alias",
                        filterName: "alias"
                    },
                    {
                        type: "autocomplete",
                        name: "proyecto",
                        entity:"Proyecto",
                        by: "EmpresaRef",
                        filterName: "ref"
                    },
                    {
                        type: "select",
                        name: "cuenta",
                        entity:"Cuenta",
                        label:"nombre,moneda{simbolo}",
                        displayText:(element)=>{
                            return (element.nombre+" ["+element.moneda.simbolo+"]");
                        }
                    },
                    {
                        type: "select",
                        name: "formaPago",
                        entity:"FormaPago",
                        label: "nombre"
                    },
                    {
                        type: "select",
                        name: "retencion",
                        entity:"Retencion",
                        label: "nombre"
                    },
                    {
                        type: "dateRange",
                        name: "fechaAcreditacion",
                        startDateName: "fechaAcreditacionDesde",
                        endDateName: "fechaAcreditacionHasta",
                        translationKey: "movimiento.field.fechaAcreditacion"
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findMovimientoPageByFilter"
                entityName="movimiento"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/movimiento/edit/"
                headerBottom={
                    <tr>
                        <th className="bg-white pr-2">
                            <div>{codigoMonedaTotales}</div>
                        </th>
                        <th className="text-right bg-white pr-2">
                            <div>
                                <CurrencyFormat value={totalImporte}/>
                            </div>
                        </th>
                    </tr>
                }
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id",
                        rowSpan: 2
                    },
                    {
                        
                        name: "adjunto",
                        customHeader: (<i className="fas fa-paperclip"></i>),
                        customClassHeader: "bg-info text-center pl-0 pr-0",
                        className:"text-center",
                        disableSort: true,
                        render: (element)=>{
                            return(<DownloadAdjunto filename={element.node.adjunto} entity="Movimiento"/>)
                        },
                        rowSpan: 2
                    },
                    {
                        
                        name: "estado",
                        className: "text-align-center",
                        render: (element)=>{
                            return(<EstadoMovimiento element={element}/>)
                        },
                        rowSpan: 2
                    },
                    {
                        
                        name: "cliente",
                        render: (element)=>{
                            return(<p><PrivateLink className="parrish-link" to={"/empresa/edit/"+element.node.cliente.id}>{element.node.cliente.contacto.alias}</PrivateLink></p>)
                        },
                        rowSpan: 2
                    },
                    {
                        name: "fechaAcreditacion",
                        type: "date",
                        rowSpan: 2
                    },
                    {
                        
                        name: "formaPago",
                        render: (element)=>{
                            return(<>
                                <p>{element.node.formaPago.nombre}</p>
                                {element.node?.retencion&&
                                    <p className="text-bold">[{element.node.retencion.nombre}]</p>
                                }
                                {element.node?.chequeNro&&
                                    <p className="text-bold">[{element.node.chequeNro}]</p>
                                }
                            </>)
                        },
                        rowSpan: 2
                    },
                    {
                        
                        name: "cuenta",
                        render: (element)=>{
                            return(<>
                                {
                                    element.node.cuenta &&
                                        element.node.cuenta?.nombre+" - ["+element.node.cuenta?.moneda?.simbolo+"]"
                                }
                            </>)
                        },
                        rowSpan: 2
                    },
                    {
                        
                        name: "detalle",
                        render: (element)=>{
                            return(<>
                                <p className="text-bold">{element.node.detalleCbtesAsociados}</p>
                                <p>{element.node.detalle?"("+element.node.detalle+")":""}</p>
                            </>)
                        },
                        rowSpan: 2
                    },
                    {
                        
                        name: "moneda",
                        customHeader: " ",
                        customClassHeader: "bg-info",
                        className:"text-align-center",
                        disableSort: true,
                        render: (element)=>{
                            return(element.node.moneda?.simbolo)
                        }
                    },
                    {  
                        name: "importe",
                        type: "money",
                        customHeader: t("movimiento.field.importe"),
                        customClassHeader: "bg-info",
                        render: (element)=>{
                            const importe = element.node.importe*element.node.impacto;
                            return(
                                <CurrencyFormat additionalClassName="float-right" value={importe}/>
                            )
                        }
                    }
                ]}>
            </CardDatatable>
        </>
    );
}

export default MovimientoList;