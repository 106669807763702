import React from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

export default function CustomSelect(props) {
    const {minWidth} = props;
    const [t] = useTranslation("global");
    const customStyles = {
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? '#00a09b' : isFocused ? '#e9ecef' : null,
            color: isSelected ? 'white' : 'inherit',
            ':active': {
              ...base[':active'],
              backgroundColor: isSelected ? '#00a09b' : '#e2e6ea'
            }
        }),
        container: (base, state) => ({
            ...base,
            flexGrow: 1,
            minWidth: minWidth?minWidth:'120px'
        }),
        control: (base, state) => ({
          ...base,
          minHeight: '30px',
          backgroundColor: props.isDisabled?'#eaedf0':'inherit',
          borderColor: props.isDisabled?'#ced4da':'#ccc',
          boxShadow: state.isFocused ? '1px #00a09b' : state.isActive ? '1px #00a09b' :'none',
          '&:hover': {
            borderColor: state.isDisabled ? '#ced4da' : state.isFocused ? '#00a09b' : '#999'
          },
          '&:focus': {
            borderColor: state.isDisabled ? '#ced4da' : '#00a09b',
            boxShadow: state.isDisabled ? null : '1px #00a09b'
            },
            '&:focus-within': {
            borderColor: state.isDisabled ? '#ced4da' : '#00a09b',
            boxShadow: state.isDisabled ? null : '1px #00a09b'
            }
        }),
        menu: (base, state) => ({
            ...base,
            zIndex: 99999
        }),
        valueContainer: (base, state) => ({
            ...base,
            minHeight: '29px',
            padding: '0 5px'
        }),
        singleValue: (base, state) => ({
            ...base,
            fontSize: '14px',
            color: props.isDisabled?'#495057':'inherit'
        }),
        indicatorsContainer: (base, state) => ({
            ...base,
            height: '29px',
            minHeight: '29px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: props.isDisabled?'#495057':'inherit'
        }),
        input: (base, state) => ({
            ...base,
            margin: 0
        }),
    };

    const placeholder = props.placeholder?props.placeholder:t("common.label.defaultSelectPlaceholder");
    let isClearable;
    if(props.isClearable===undefined){
        isClearable = {isClearable:true};
    }
    return (
        <Select {...props} {...isClearable} placeholder={placeholder} styles={customStyles}/>
    )
}
