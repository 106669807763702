import React, { useEffect, useRef, useState } from 'react'
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean'
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import SelectKeyValue from '../../../../../components/SelectKeyValue';

const CONTACTO_SEARCH_BY_ID = gql`
    query findContactoById(
        $id: Int!
    ) {
    findContactoById(
        id: $id
    ){
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
        domicilio
        localidad
        pais{
            id
            nombre
        }
        provincia{
            id
            nombre
        }
        codigoPostal

        nacionalidad{
            id
            nombre
        }
        labelList{
            id
            nombre
        }
        linkedin
        website
        skype
        twitter
        facebook
    }
}
`;



const UPDATE_CONTACTO_MUTATION = gql`
    mutation updateDatosContacto(
        $id: ID!
        $email: String!
        $telefonosFijos: String
        $telefonosMoviles: String
        $domicilio: String
        $localidad: String
        $pais: Int
        $provincia: Int
        $codigoPostal: String
        $website: String
        $socialInfoFacebook: String
        $socialInfoLinkedin: String
        $socialInfoTwitter: String
        $socialInfoSkype: String
    ) {
        updateDatosContacto(
            entity:{
                id: $id
                email: $email
                telefonosFijos: $telefonosFijos
                telefonosMoviles: $telefonosMoviles
                website: $website
                addressInfo:{
                    pais: $pais
                    provincia: $provincia
                    localidad: $localidad
                    domicilio: $domicilio
                    codigoPostal: $codigoPostal
                }
                socialInfo:{
                    facebook: $socialInfoFacebook
                    linkedin: $socialInfoLinkedin
                    twitter: $socialInfoTwitter
                    skype: $socialInfoSkype
                }
            }
        ) {
            id
        }
    }
`;

export default function EditarContactoDialog(props) {
    const { contactoId, refetch } = props;
    const [t] = useTranslation("global");
    const [show, setShow] = useState(false);
    let { showErrorToast, clearPopUps } = useAlertMessage();

    const [searchContactoById] = useLazyQuery(CONTACTO_SEARCH_BY_ID, {
        onCompleted: (data) => {
            const contacto = data?.findContactoById;
            setState({
                alias: contacto.alias?contacto.alias:"",
                email: contacto.email?contacto.email:"",
                telefonosFijos: contacto.telefonosFijos?contacto.telefonosFijos:"",
                telefonosMoviles: contacto.telefonosMoviles?contacto.telefonosMoviles:"",
                direccionDomicilio: contacto.domicilio?contacto.domicilio:"",
                direccionLocalidad: contacto.localidad?contacto.localidad:"",
                direccionPais: contacto.pais?parseInt(contacto.pais.id):"",
                direccionProvincia: contacto.provincia?parseInt(contacto.provincia.id):"",
                direccionCodigoPostal: contacto.codigoPostal?contacto.codigoPostal:"",
                socialInfoLinkedin: contacto.linkedin?contacto.linkedin:"",
                website: contacto.website?contacto.website:"",
                socialInfoSkype: contacto.skype?contacto.skype:"",
                socialInfoTwitter: contacto.twitter?contacto.twitter:"",
                socialInfoFacebook: contacto.facebook?contacto.facebook:""
            })
        }
    });

    const [state, setState] = useState({
        alias: "",
        email: "",
        telefonosFijos: "",
        telefonosMoviles: "",
        direccionDomicilio: "",
        direccionLocalidad: "",
        direccionPais: "",
        direccionProvincia: "",
        direccionCodigoPostal: "",
        socialInfoLinkedin: "",
        website: "",
        socialInfoSkype: "",
        socialInfoTwitter: "",
        socialInfoFacebook: ""
    });

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!state.email){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.email")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const ref = useRef();

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            searchContactoById({
                variables:{
                    id: contactoId
                }
            })
        }
    }, [contactoId, searchContactoById, show]);

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const linkClick= (e,url) =>{
        e.preventDefault();
        if(url){
            // Check if the URL starts with "http://" or "https://"
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                // If not, prepend "http://"
                url = "https://" + url;
            }
            
            window.open(url,"_blank");
        }
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const [updateContactoMutation] = useMutation(UPDATE_CONTACTO_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch();
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });   

    const handleSave = () =>{
        if(validate()){
            updateContactoMutation({
                variables:{
                    id: contactoId,
                    email: state.email,
                    telefonosFijos: state.telefonosFijos?state.telefonosFijos:null,
                    telefonosMoviles: state.telefonosMoviles?state.telefonosMoviles:null,
                    pais: state.direccionPais?parseInt(state.direccionPais):null,
                    provincia: state.direccionProvincia?parseInt(state.direccionProvincia):null,
                    localidad: state.direccionLocalidad?state.direccionLocalidad:null,
                    domicilio: state.direccionDomicilio?state.direccionDomicilio:null,
                    codigoPostal: state.direccionCodigoPostal?state.direccionCodigoPostal:null,
                    website: state.website?state.website:null,
                    socialInfoFacebook: state.socialInfoFacebook?state.socialInfoFacebook:null,
                    socialInfoLinkedin: state.socialInfoLinkedin?state.socialInfoLinkedin:null,
                    socialInfoTwitter: state.socialInfoTwitter?state.socialInfoTwitter:null,
                    socialInfoSkype: state.socialInfoSkype?state.socialInfoSkype:null,
                }
            });
        }
    }

    return (
        <>
            <FlagButtonBoolean
                icon="fas fa-edit"
                onClass="bg-info m-1"
                offClass="bg-info m-1"
                title={t("contacto.label.editarDatosDialog")}
                value={true}
                setValue={()=>{setShow(true)}}
            />
            <div ref={ref} className="modal" role="dialog">
                {show&&
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header pl-3 p-2">
                                <h5 className="modal-title">{t("contacto.label.editarDatosDialog")}: {state.alias}</h5>
                                <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body parrish-form">
                                <div className="row">

                                    <div className="col-xs-12 col-sm-6">
                                        <div className="parrish-form row">
                                            <div className="col-12 text-center">
                                                <h3>{t("postulante.label.datosContacto")}</h3>
                                            </div>
                                        </div>
                                        <div className="parrish-form row ">
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-email" className="parrish-label col-sm-4 col-xs-12 text-right">{t("Email")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{emailClick(e,state.email)}}>
                                                                <span className="input-group-text">
                                                                    <i className="fas fa-envelope"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-email'}
                                                                className="parrish-input"
                                                                maxLength={150}
                                                                value={state.email}
                                                                onChange={e => handleChange("email", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tel-fijos" className="parrish-label col-sm-4 col-xs-12 text-right">{t("contacto.field.telefonosFijos")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fas fa-phone"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-tel-fijos'}
                                                                className="parrish-input"
                                                                maxLength={150}
                                                                value={state.telefonosFijos}
                                                                onChange={e => handleChange("telefonosFijos", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-tel-moviles" className="parrish-label col-sm-4 col-xs-12 text-right">{t("contacto.field.telefonosMoviles")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{whatsappClick(e,state.telefonosMoviles)}}>
                                                                <span className="input-group-text">
                                                                    <i className="fab fa-whatsapp"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-tel-moviles'}
                                                                className="parrish-input"
                                                                maxLength={150}
                                                                value={state.telefonosMoviles}
                                                                onChange={e => handleChange("telefonosMoviles", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="parrish-form row mt-4">
                                            <div className="col-12 text-center">
                                                <h3>{t("postulante.label.domicilio")}</h3>
                                            </div>
                                        </div>
                                        <div className="parrish-form row ">
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-domicilio" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.domicilio")}</label>
                                                    <div className="col">
                                                        <input autoComplete="off" type="text"
                                                            name={'value-domicilio'}
                                                            className="parrish-input"
                                                            value={state.direccionDomicilio}
                                                            onChange={e => handleChange("direccionDomicilio", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-localidad" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.localidad")}</label>
                                                    <div className="col">
                                                        <input autoComplete="off" type="text"
                                                            name={'value-localidad'}
                                                            className="parrish-input"
                                                            value={state.direccionLocalidad}
                                                            onChange={e => handleChange("direccionLocalidad", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 text-right font-weight-normal">{t("postulante.field.pais")}</label>
                                                    <div className="col">
                                                        <SelectKeyValue
                                                            entity="Pais"
                                                            label="nombre"
                                                            name={'value-pais'}
                                                            value={state.direccionPais}
                                                            required
                                                            onChange={(e) => {
                                                                handleChange("direccionPais", e)
                                                            }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-provincia" className="parrish-label col-sm-4 col-xs-12 text-right font-weight-normal">{t("postulante.field.provincia")}</label>
                                                    <div className="col">
                                                        <SelectKeyValue
                                                            entity="Provincia"
                                                            by="PaisNombre"
                                                            label="nombre"
                                                            name={'value-provincia'}
                                                            value={state.direccionProvincia}
                                                            filterValue={state.direccionPais}
                                                            filterName="idPais"
                                                            onChange={(e) => {
                                                                handleChange("direccionProvincia", e)
                                                            }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-codigoPostal" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.codigoPostal")}</label>
                                                    <div className="col-2">
                                                        <input autoComplete="off" type="number"
                                                            name={'value-codigoPostal'}
                                                            className="parrish-input"
                                                            value={state.direccionCodigoPostal}
                                                            onChange={e => handleChange("direccionCodigoPostal", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h3>{t("postulante.label.redesSociales")}</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-linkedin" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.linkedin")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.socialInfoLinkedin)}}>
                                                                <span className="input-group-text">
                                                                    <i className="fab fa-linkedin-in"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off" type="text"
                                                                name={'value-linkedin'}
                                                                className="parrish-input"
                                                                value={state.socialInfoLinkedin}
                                                                maxLength={150}
                                                                onChange={e => handleChange("socialInfoLinkedin", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-website" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.website")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.website)}}>
                                                                <span className="input-group-text">
                                                                    <i className="fas fa-globe-americas"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off" type="text"
                                                                name={'value-website'}
                                                                className="parrish-input"
                                                                value={state.website}
                                                                maxLength={150}
                                                                onChange={e => handleChange("website", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-skype" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.skype")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fab fa-skype"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off" type="text"
                                                                name={'value-skype'}
                                                                maxLength={150}
                                                                className="parrish-input"
                                                                value={state.socialInfoSkype}
                                                                onChange={e => handleChange("socialInfoSkype", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-twitter" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.twitter")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fab fa-twitter"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off" type="text"
                                                                name={'value-twitter'}
                                                                className="parrish-input"
                                                                value={state.socialInfoTwitter}
                                                                maxLength={150}
                                                                onChange={e => handleChange("socialInfoTwitter", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12">
                                                <div className="form-group row">
                                                    <label htmlFor="value-facebook" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.facebook")}</label>
                                                    <div className="col">
                                                        <div className="input-group input-group-sm">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="fab fa-facebook-square"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete="off" type="text"
                                                                name={'value-facebook'}
                                                                className="parrish-input"
                                                                value={state.socialInfoFacebook}
                                                                maxLength={150}
                                                                onChange={e => handleChange("socialInfoFacebook", e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                    {t("common.button.guardar")}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
