import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useLocation } from 'react-router-dom';

import $ from "jquery";
import {} from "jquery-validation";
import { CirclePicker } from 'react-color'
import PrivateLink from '../../../../components/navigation/PrivateLink';
import ReactIconPicker from '../../../../components/ReactIconPicker';


const SEGUIMIENTOACCION_SEARCH_BY_ID = gql`
    query findSeguimientoAccionById(
        $id: Int!
    ) {
    findSeguimientoAccionById(
        id: $id
    ){
        id
        nombre
        color
        icon
    }
}
`;

const UPDATE_SEGUIMIENTOACCION_MUTATION = gql`
    mutation updateSeguimientoAccion(
        $id: ID!
        $nombre: String!
        $icon: String!
        $color: String!
    ) {
        updateSeguimientoAccion(entity:{
            id: $id
            nombre: $nombre
            icon: $icon
            color: $color
    }) {
            id
            nombre
            color
            icon
        }
    }
`;

const DELETE_SEGUIMIENTOACCION_MUTATION = gql`
    mutation deleteSeguimientoAccion(
        $id: Int!
    ) {
        deleteSeguimientoAccion(id: $id)
    }
`;

function SeguimientoAccionEdit() {
    const history = useHistory();
    const ref = useRef();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect, showErrorToast } = useAlertMessage();

    const [nombre, setNombre] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        SEGUIMIENTOACCION_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findSeguimientoAccionById?.nombre);
            setColor(data?.findSeguimientoAccionById?.color);
            const icon = data?.findSeguimientoAccionById?.icon;
            const pickedIcon = {
                prefix: icon.split(" fa-")[0],
                iconName: icon.split(" fa-")[1]
            }
            setIcon(pickedIcon);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findSeguimientoAccionById
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateSeguimientoAccionMutation] = useMutation(UPDATE_SEGUIMIENTOACCION_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/seguimientoAccion";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteSeguimientoAccionMutation] = useMutation(DELETE_SEGUIMIENTOACCION_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/seguimientoAccion");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const getIcon = () =>{
        let iconParam = null;
        if(icon && icon.prefix && icon.iconName){
            iconParam = icon.prefix + ' fa-' + icon.iconName;
        }
        return iconParam;
    }

    const saveAction = () =>{
        updateSeguimientoAccionMutation({
            variables:{
                id: id,
                nombre: nombre,
                icon: getIcon(),
                color: color
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteSeguimientoAccionMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-nombre": "required",
                "value-industria": "required",
            },
            messages: {
                "value-nombre": {
                    required: t("common.message.field.required", {0: t("empresa.field.nombre")})
                },
                "value-industria": {
                    required: t("common.message.field.required", {0: t("empresa.field.industria")})
                },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <>
            <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                <div className={"card card-outline card-info"}>
                    {/*
                        loading &&
                        !error && (
                            <div className="overlay">
                                <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                            </div>
                        )
                    */}
                    <div className="card-header">
                        <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })}</h3>
                        <div className="card-tools m-0">
                            <button type="submit" className="btn btn-xs bg-info btn-tool">
                                <i className="fas fa-save"></i>
                            </button>
                            <button type="button" className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>deleteAction(id)}>
                                <i className="fas fa-trash"></i>
                            </button>
                            <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/seguimientoAccion"}>
                                <i className="fas fa-times"></i>
                            </PrivateLink>
                        </div>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-nombre" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.nombre")}</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <input autoComplete="off" type="text" name={'value-chequeTitular'} className="parrish-input" value={nombre} onChange={e => setNombre(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-color" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.color")}</label>
                                            <div className="col-sm-8 col-xs-12 parrish-picker">
                                            <CirclePicker color={color} onChange={(color)=>{setColor(color.hex)}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-icon" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.icon")}</label>
                                            <div className="col-sm-8 col-xs-12 parrish-picker">
                                                <ReactIconPicker pickedIcon={icon} pickIcon={(icon)=>{setIcon(icon)}} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 d-flex justify-content-center align-items-center">
                                <div className="rounded-circle rounded-parrish-img d-flex justify-content-center shadow float-right" style={{backgroundColor: color}}>
                                    <h1 className="align-self-center">
                                        <i className={getIcon()+" text-white"} />
                                    </h1>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SeguimientoAccionEdit