import React, { useContext, useState } from 'react'
import { useHistory } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import DatePicker from '../../../../components/DatePicker';
import AuthContext from '../../../../context/AuthContext';
import { DateRfc }  from '../../DateRfc';

const CREATE_EMPRESA_MUTATION = gql`
    mutation createEmpresa(
        $nombre: String!
        $industria: Int!
    ) {
        createEmpresa(
            entity:{
                nombre: $nombre
                industria: $industria
            }
        ) {
            id
        }
    }
`;

function EmpresaEdit() {
    const history = useHistory();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const [nombre, setNombre] = useState("");
    const [industria, setIndustria] = useState();

    const [t] = useTranslation("global");
    
    const [createEmpresaMutation] = useMutation(CREATE_EMPRESA_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/empresa/edit/"+data.createEmpresa.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        createEmpresaMutation({
            variables: {
                nombre: nombre,
                industria: industria
            }
        });
    }

    return (
        <CardCreateEntity
            entityName="empresa"
            columnsCount={1}
            handleSubmit={handleSubmit}
            returnUrl="/empresa"
            values={[
                {  
                    name: "custom",
                    type: "text",
                    componentClassName: "col-sm-12 col-xs-12",
                    render: ()=>{
                        return(
                            <div className="form-group row">
                                <label htmlFor="value-fechaRegistracion" className="parrish-label col-sm-2 col-xs-12">{t("empresa.field.ultimaActualizacion")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <DatePicker readOnly selected={new DateRfc()}/>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={userData?.usuario?.contacto?.alias}/>
                                </div>
                            </div>
                        );
                    }
                },
                {  
                    name: "alias",
                    type: "text",
                    maxLength: 50,
                    value: nombre,
                    setValue: setNombre,
                    componentClassName: "col-sm-6 col-xs-12",
                    labelClassName: "col-sm-4 col-xs-12",
                    inputClassName: "col",
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("empresa.field.nombre")}),
                    }
                },
                {  
                    name: "industria",
                    type: "select",
                    entity:"Industria",
                    label:"nombre",
                    value: industria,
                    setValue: setIndustria,
                    componentClassName: "col-sm-6 col-xs-12",
                    labelClassName: "col-sm-4 col-xs-12",
                    inputClassName: "col",
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("empresa.field.industria")}),
                    }
                }
            ]}
        />
    )
}

export default EmpresaEdit