import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import CurrencyFormat from '../../../../../../components/CurrencyFormat';
import Datatable from '../../../../../../components/Datatable';
import AuthContext from '../../../../../../context/AuthContext';

const FIND_EXPERIENCIA_LABORAL_BY_POSTULANTE = gql`
    query findExperienciaLaboralPageByPostulante(
        $idPostulante: Int!
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findExperienciaLaboralPageByPostulante(
            idPostulante: $idPostulante
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fechaInicio
                    fechaFin
                    pais{
                        nombre
                    }
                    empresa{
                        contacto{
                            alias
                        }
                        industria{
                            nombre
                        }
                    }
                    posicion
                    cargo{
                        nombre
                    }
                    areaEmpresa{
                        nombre
                    }
                    moneda{
                        simbolo
                    }
                    sueldoBrutoMensual
                }
            }  
        }
    }
`;

export default function ExperienciaLaboralsList(props) {
    const { setMode, setEditId } = props;
    const [t] = useTranslation("global");
    const {id} = useParams();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaInicio",
        sortDirection: "DESC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(id){
            setPageNumber(0)
        }
    }, [id]);

    const { loading, error, data } = useQuery(
        FIND_EXPERIENCIA_LABORAL_BY_POSTULANTE,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                idPostulante: id,
            }
        }
    );

    return (

        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header pr-0 pl-0">
                <h3 className="card-title">{t("common.label.listado")}</h3>
                <div className="card-tools m-0">
                    <button
                        className="btn btn-xs btn-info bg-info btn-tool ml-2"
                        title={t("common.button.agregar")}
                        onClick={()=>{setMode("create")}}>
                            <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="card-body pr-0 pl-0">
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findExperienciaLaboralPageByPostulante"
                    entityName="experienciaLaboral"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onEdit={(element)=>{
                        setEditId(element.node.id);
                        setMode("edit");
                    }}
                    columnsDefinition={[
                        {
                            name: "id",
                            type: "id",
                            className: "text-center"
                        },
                        {
                            name: "trabajoActual",
                            customHeader: <span className="p-1" title={t("Experiencia laboral actual")}><i className="fas fa-laptop"></i></span>,
                            disableSort: true,
                            customClassHeader: "bg-info text-center pl-0 pr-0",
                            className: "text-center",
                            render: (element)=>{
                                return(
                                    !element.node.fechaFin&&
                                        <p title={t("Experiencia laboral actual")} className={"badge badge-info align-self-center"}>
                                            <i className="fas fa-laptop"></i>
                                        </p>
                                );
                            }
                        },
                        {
                            name: "periodo",
                            translationKey: "postulante.field.periodo",
                            colSpan: 2,
                            type: "text",
                            customSortKey: "fechaInicio",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaInicio &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaInicio}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "periodoFin",
                            translationKey: "postulante.field.periodo",
                            type: "text",
                            customSortKey: "fechaFin",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaFin &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaFin}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "pais",
                            customSortKey: "pais.nombre",
                            render: (element)=>{
                                return(<p>{element.node.pais.nombre}</p>);
                            }
                        },
                        {
                            name: "empresa",
                            customSortKey: "empresa.contacto.alias",
                            render: (element)=>{
                                return(<>
                                <p>{element.node.empresa.contacto.alias}</p>
                                <p><b>{t("experienciaLaboral.field.industria")}:</b> {element.node.empresa.industria.nombre}</p>
                                </>);
                            }
                        },
                        {
                            name: "posicion",
                            type: "text"
                        },
                        {
                            name: "cargo",
                            customSortKey: "cargo.nombre",
                            render: (element)=>{
                                return(<p>{element.node.cargo.nombre}</p>);
                            }
                        },
                        {
                            name: "areaEmpresa",
                            customSortKey: "areaEmpresa.nombre",
                            render: (element)=>{
                                return(<p>{element.node.areaEmpresa.nombre}</p>);
                            }
                        },
                        {
                            name: "sueldoBrutoMensual",
                            className: "text-right",
                            render: (element)=>{
                                return(element.node.sueldoBrutoMensual&&
                                    <CurrencyFormat prefixClassName="mr-2" additionalClassName="justify-content-end"  prefix={element.node.moneda?.simbolo}  value={element.node.sueldoBrutoMensual}/>
                                );
                            }
                        }
                    ]}
            />
            </div>
        </div>        
    )
}
