import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from '../../../../context/AuthContext';
import Moment from 'react-moment';
import EstadoMejora from '../enums/EstadoMejora';
import Prioridad from '../enums/Prioridad';

// TODO PGEN revisar campos del filtro y de la respuesta
const IDEA_SEARCH = gql`
    query findIdeaPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $estado: String
        $prioridad: String
    ) {
    findIdeaPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            estado: $estado
            prioridad: $prioridad
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                aprobadoPor{
                    id
                    contacto{
                        alias
                    }
                }
                id
                solicitadoPor{
                    id
                    contacto{
                        alias
                    }
                }
                fechaAprobacion
                fechaEstimacion
                fechaFinalizacion
                fechaSolicitud
                comentarioAprobacion
                comentarioEstimacion
                descripcion
                esfuerzoEstimado
                estado
                prioridad
                titulo
            }
        }       
    }
}
`;

function IdeaList(){
    // TODO PGEN seleccionar orden default
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "id",
        sortDirection: "DESC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    
    const [searchFilter, setSearchFilter] = useState({
        estado: "",
        prioridad: ""
    });

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);


    const {loading, error, data } = useQuery(
        IDEA_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                estado: searchFilter.estado?searchFilter.estado:null,
                prioridad: searchFilter.prioridad?searchFilter.prioridad:null
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="idea"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "options",
                        name: "prioridad",
                        options: Object.keys(Prioridad).map(key=>{
                            return({
                                value: Prioridad[key],
                                label: Prioridad[key]
                            })
                        })
                    },
                    {
                        type: "options",
                        name: "estado",
                        options: Object.keys(EstadoMejora).map(key=>{
                            return({
                                value: EstadoMejora[key],
                                label: EstadoMejora[key]
                            })
                        })
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findIdeaPageByFilter"
                entityName="idea"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/idea/edit/"
                createUrl="/idea/create"
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id"
                    },
                    {
                        name: "estado",
                        className:"text-align-center",
                        render: (element) =>{
                            return(<span className={"badge ideas-status-"+(element.node.estado?element.node.estado.toLowerCase():"")}>{element.node.estado}</span>)
                        }
                    },
                    {
                        name: "solicitadoPor",
                        customSortKey: "fechaSolicitud",
                        className:"text-center",
                        render: (element) =>{
                            return(
                                <>
                                    <Moment className="date-centered mb-2" format="DD-MM-yyyy">{element.node.fechaSolicitud}</Moment>
                                    <p>{element.node.solicitadoPor.contacto.alias}</p>
                                </>
                            )
                        }
                    },
                    {
                        name: "prioridad",
                        className:"text-center",
                        type: "text"
                    },
                    {
                        name: "titulo",
                        type: "text"
                    },
                    {
                        name: "descripcion",
                        disableSort: true,
                        type: "text"
                    },
                    {
                        name: "fechaEstimacion",
                        className:"text-center",
                        render: (element) =>{
                            return(element.node.fechaEstimacion&&
                                <>
                                    <Moment className="date-centered mb-2" format="DD-MM-yyyy">{element.node.fechaEstimacion}</Moment>
                                    <p>({element.node.esfuerzoEstimado} hrs)</p>
                                </>
                            )
                        }
                    },
                    {
                        name: "fechaAprobacion",
                        className:"text-center",
                        render: (element) =>{
                            return(element.node.fechaAprobacion&&
                                <>
                                    <Moment className="date-centered mb-2" format="DD-MM-yyyy">{element.node.fechaAprobacion}</Moment>
                                    <p>{element.node.aprobadoPor.contacto.alias}</p>
                                </>
                            )
                        }
                    },
                    {
                        name: "fechaFinalizacion",
                        type: "date"
                    }]}>
            </CardDatatable>
        </>
    );
}

export default IdeaList;