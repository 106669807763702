import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { gql, useMutation, useQuery} from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useTranslation } from 'react-i18next';
import ProyectosTab from './tabs/proyectos';
import DatosPersonalesTab from './tabs/datosPersonales';
import AvatarUploadModal from '../../../../components/AvatarUploadModal';
import FormacionAcademicaTab from './tabs/formacionAcademica';
import ExperienciaLaboralTab from './tabs/experienciaLaboral';
import ConocimientoPostulanteTab from './tabs/conocimientos';
import SidebarContext from '../../../../context/SidebarContext';
import ConexionesTab from './tabs/conexiones';
import AdjuntosTab from './tabs/adjuntos';
import FlagsPostulante from '../../../../components/FlagsPostulante';
import moment from 'moment';
import FlagButtonBoolean from '../../../../components/FlagButtonBoolean';
import CardFooterRecordData from '../../../../components/CardFooterRecordData';
import AsociarProyectoDialog from './dialogs/asociarProyecto';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { DateRfc }  from '../../DateRfc';

const POSTULANTE_SEARCH_BY_ID = gql`
    query findPostulanteById(
        $id: Int!
    ) {
    findPostulanteById(
        id: $id
    ){
        id
        fechaActualizacion
        destacado
        fechaNacimientoEstimada
        incubadora
        incubadoraProspecto
        opportunity
        placement
        verificar
        appraisal
        dni
        genero
        hijos
        offLimits
        contacto{
            id
            foto
            alias
            personaFisica
            nombre
            apellido
            email
            fechaNacimiento
            nacionalidad{
                id
            }
            telefonosFijos
            telefonosMoviles
            observaciones
            pais{
                id
            }
            provincia{
                id
            }
            localidad
            domicilio
            codigoPostal
            website
            skype
            linkedin
            twitter
            facebook
        }
        estadoCivil{
            id
            nombre
        }
        responsable{
            contacto{
                alias
            }
        }
        qaInfo{
            indice
            recomendaciones
        }
        tags
    }
}
`;

const UPDATE_POSTULANTE_ADJUNTO_MUTATION = gql`
    mutation updatePostulante(
        $id: ID!
        $estadoCivil: Int
        $destacado: Boolean!
        $fechaNacimientoEstimada: Boolean!
        $incubadora: Boolean!
        $incubadoraProspecto: Boolean!
        $opportunity: Boolean!
        $verificar: Boolean!
        $appraisal: String
        $dni: String
        $genero: String!
        $hijos: String
        $nombre: String!
        $apellido: String!
        $email: String!
        $fechaNacimiento: LocalDate
        $nacionalidad: Int
        $telefonosFijos: String
        $telefonosMoviles: String
        $website: String
        $observaciones: String
        $direccionPais: Int
        $direccionProvincia: Int
        $direccionLocalidad: String
        $direccionDomicilio: String
        $direccionCodigoPostal: String
        $socialInfoFacebook: String
        $socialInfoLinkedin: String
        $socialInfoTwitter: String
        $socialInfoSkype: String
        $tags: String
    ) {
        updatePostulante(entity:{
            id: $id
            nombre: $nombre
            apellido: $apellido
            email: $email
            fechaNacimiento: $fechaNacimiento
            fechaNacimientoEstimada: $fechaNacimientoEstimada
            opportunity: $opportunity
            verificar: $verificar
            destacado: $destacado
            incubadora: $incubadora
            incubadoraProspecto: $incubadoraProspecto
            estadoCivil: $estadoCivil 
            appraisal: $appraisal 
            dni: $dni 
            genero: $genero 
            hijos: $hijos 
            nacionalidad: $nacionalidad 
            telefonosFijos: $telefonosFijos 
            telefonosMoviles: $telefonosMoviles 
            website: $website 
            observaciones: $observaciones
            tags: $tags
            direccion: {
                pais: $direccionPais
                provincia: $direccionProvincia
                localidad: $direccionLocalidad
                domicilio: $direccionDomicilio
                codigoPostal: $direccionCodigoPostal
            }
            socialInfo: {
                facebook: $socialInfoFacebook
                linkedin: $socialInfoLinkedin
                twitter: $socialInfoTwitter
                skype: $socialInfoSkype
            }
        }) {
            id
        }
    }
`;

function PostulanteEdit() {
    const [activeTab, setActiveTab] = useState('custom-tabs-datos-cliente-tab');
    const selectTab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    const history = useHistory();
    const [t] = useTranslation("global");
    const [rerender, setRerender] = useState(false);

    let { showErrorToast } = useAlertMessage();
    let { id } = useParams();

    const [showProyectoDialog, setShowProyectoDialog] = useState();

    const { setSideBarState, show } = useContext(SidebarContext);

    const ref = useRef();

    const [state, setState] = useState({
        estadoCivil: "",
        fechaNacimientoEstimada: false,
        opportunity: false,
        verificar: false,
        destacado: false,
        incubadora: false,
        incubadoraProspecto: false,
        appraisal: "",
        dni: "",
        genero: "",
        hijos: "",
        nombre: "",
        apellido: "",
        email: "",
        fechaNacimiento: null,
        nacionalidad: "",
        telefonosFijos: "",
        telefonosMoviles: "",
        website: "",
        observaciones: "",
        direccionPais: "",
        direccionProvincia: "",
        direccionLocalidad: "",
        direccionDomicilio: "",
        direccionCodigoPostal: "",
        socialInfoFacebook: "",
        socialInfoLinkedin: "",
        socialInfoTwitter: "",
        socialInfoSkype: "",
        tags: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePostulanteMutation({
            variables:{
                id: id,
                estadoCivil: state.estadoCivil?state.estadoCivil:null,
                destacado: state.destacado,
                fechaNacimientoEstimada: state?.fechaNacimientoEstimada,
                incubadora: state.incubadora,
                incubadoraProspecto: state.incubadoraProspecto,
                opportunity: state.opportunity,
                verificar: state.verificar,
                appraisal: state.appraisal?state.appraisal:"",
                dni: state.dni?state.dni:"",
                genero: state.genero?state.genero:"",
                hijos: state.hijos?parseInt(state.hijos):"",
                nombre: state.nombre?state.nombre:"",
                apellido: state.apellido?state.apellido:"",
                email: state.email?state.email:"",
                fechaNacimiento: state.fechaNacimiento?state.fechaNacimiento:null,
                nacionalidad: state.nacionalidad?parseInt(state.nacionalidad):null,
                telefonosFijos: state.telefonosFijos?state.telefonosFijos:"",
                telefonosMoviles: state.telefonosMoviles?state.telefonosMoviles:"",
                website: state.website?state.website:"",
                observaciones: state.observaciones?state.observaciones:"",
                direccionPais: state.direccionPais?parseInt(state.direccionPais):null,
                direccionProvincia: state.direccionProvincia?parseInt(state.direccionProvincia):null,
                direccionLocalidad: state.direccionLocalidad?state.direccionLocalidad:"",
                direccionDomicilio: state.direccionDomicilio?state.direccionDomicilio:"",
                direccionCodigoPostal: state.direccionCodigoPostal?state.direccionCodigoPostal:"",
                socialInfoFacebook: state.socialInfoFacebook?state.socialInfoFacebook:"",
                socialInfoLinkedin: state.socialInfoLinkedin?state.socialInfoLinkedin:"",
                socialInfoTwitter: state.socialInfoTwitter?state.socialInfoTwitter:"",
                socialInfoSkype: state.socialInfoSkype?state.socialInfoSkype:"",
                tags: state.tags?state.tags:"",
            }
        })
    }

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip("dispose");
        $('[data-toggle="tooltip"]').tooltip();
    }, [])

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-nombre": "required",
                "value-apellido": "required",
                "value-genero": "required",
                "value-email": {
                    required: true,
                    email: true
                }
            },
            messages: {
                "value-nombre": { required: t("common.message.field.required", {0: t("postulante.field.nombre")}) },
                "value-apellido": { required: t("common.message.field.required", {0: t("postulante.field.apellido")}) },
                "value-genero": { required: t("common.message.field.required", {0: t("postulante.field.genero")}) },
                "value-email": {
                    required: t("common.message.field.required", {0: t("postulante.field.email")}),
                    email: t("common.message.invalid.email.format")
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    const {loading, error, data, refetch } = useQuery(
        POSTULANTE_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [updatePostulanteMutation] = useMutation(UPDATE_POSTULANTE_ADJUNTO_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const mergeState = useCallback((stateData) =>{
        setState({
            ...state,
            ...stateData
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!loading && !error && data?.findPostulanteById){
            const postulante = data?.findPostulanteById;
            let stateData = {};

            stateData.id = postulante.id;
            stateData.nombre = postulante.contacto.nombre?postulante.contacto.nombre:"";
            stateData.apellido = postulante.contacto.apellido?postulante.contacto.apellido:"";
            stateData.email = postulante.contacto.email?postulante.contacto.email:"";
            stateData.opportunity = postulante.opportunity;
            stateData.verificar = postulante.verificar;
            stateData.destacado = postulante.destacado;
            stateData.incubadora = postulante.incubadora;
            stateData.incubadoraProspecto = postulante.incubadoraProspecto;
            stateData.appraisal = postulante.appraisal?postulante.appraisal:"";

            stateData.genero = postulante.genero?postulante.genero:"";
            stateData.fechaNacimiento = postulante.contacto.fechaNacimiento?new DateRfc(postulante.contacto.fechaNacimiento):null;
            stateData.fechaNacimientoEstimada = postulante.fechaNacimientoEstimada;
            stateData.nacionalidad = postulante.contacto?.nacionalidad?.id?parseInt(postulante.contacto.nacionalidad.id):"";
            stateData.dni = postulante.dni?postulante.dni:"";
            stateData.estadoCivil = postulante.estadoCivil?postulante.estadoCivil.id:""; 
            stateData.hijos = postulante.hijos?postulante.hijos:"";
            stateData.telefonosFijos = postulante.contacto.telefonosFijos?postulante.contacto.telefonosFijos:"";
            stateData.telefonosMoviles = postulante.contacto.telefonosMoviles?postulante.contacto.telefonosMoviles:"";

            stateData.observaciones = postulante.contacto.observaciones?postulante.contacto.observaciones:"";

            stateData.direccionPais = postulante.contacto?.pais?.id?parseInt(postulante.contacto.pais.id):"";
            stateData.direccionProvincia = postulante.contacto?.provincia?.id?parseInt(postulante.contacto.provincia.id):"";
            stateData.direccionLocalidad = postulante.contacto?.localidad?postulante.contacto.localidad:"";
            stateData.direccionDomicilio = postulante.contacto?.domicilio?postulante.contacto.domicilio:"";
            stateData.direccionCodigoPostal = postulante.contacto?.codigoPostal?postulante.contacto.codigoPostal:"";

            stateData.website = postulante.contacto?.website?postulante.contacto.website:"";
            stateData.socialInfoFacebook = postulante.contacto?.skype?postulante.contacto.skype:"";
            stateData.socialInfoLinkedin = postulante.contacto?.linkedin?postulante.contacto.linkedin:"";
            stateData.socialInfoTwitter = postulante.contacto?.twitter?postulante.contacto.twitter:"";
            stateData.socialInfoSkype = postulante.contacto?.facebook?postulante.contacto.facebook:"";
            stateData.tags = postulante.tags?postulante.tags:"";

            stateData.fechaActualizacion = postulante.fechaActualizacion?postulante.fechaActualizacion:"";
            //setState
            mergeState(stateData);
            setRerender(true);
        }
    }, [data?.findPostulanteById, error, loading, mergeState]);

    useEffect(() => {
        if(rerender){
            setRerender(false);
        }
    }, [rerender])

    return (!rerender&&
        <>
            <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 pl-0 d-flex align-items-stretch">
                            <div className="card card-info card-outline w-100">
                                <div className="card-body box-profile">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="row">
                                            <div className="text-center">
                                                <h4>{data?.findPostulanteById?.contacto?.alias}</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-center">
                                                <div className="text-center">
                                                    {data?.findPostulanteById?.contacto&&
                                                        <AvatarUploadModal id={data?.findPostulanteById?.id} personaFisica={data?.findPostulanteById?.contacto?.personaFisica} foto={data?.findPostulanteById?.contacto.foto} avatarClassName="text-center align-self-center" entity="Postulante" contactoId={data?.findPostulanteById?.contacto.id}/>
                                                    }
                                                    {data?.findPostulanteById?.contacto?.fechaNacimiento&&
                                                        <p className={"m-0 "+(data?.findPostulanteById?.fechaNacimientoEstimada?" text-danger":"")}>({data?.findPostulanteById?.contacto?.fechaNacimiento? moment().diff(data?.findPostulanteById?.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                                    }
                                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={data?.findPostulanteById?.qaInfo?.recomendaciones?data?.findPostulanteById?.qaInfo?.recomendaciones:null}><span className="h3 m-0">{t("common.label.qa")} {data?.findPostulanteById?.qaInfo.indice}%</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-center">
                                                <div className="text-center">
                                                    {data?.findPostulanteById&&
                                                        <FlagsPostulante postulante={data?.findPostulanteById} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 pr-0 d-flex flex-column align-items-stretch">
                            <div className={"card card-outline card-info"}>
                                {/*
                                    loading &&
                                    !error && (
                                        <div className="overlay">
                                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                        </div>
                                    )
                                */}
                                <div className="card-header">
                                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })}</h3>
                                    <div className="card-tools m-0">
                                        <button type="button" className="btn btn-xs bg-yellow btn-tool" onClick={()=>{
                                            setSideBarState({
                                                mode: "seguimiento",
                                                postulante: id,
                                                labelPostulante: data?.findPostulanteById?.contacto?.alias,
                                                labelEmpresa: null,
                                                labelProyecto: null,
                                                empresa: null,
                                                proyecto: null,
                                                responsable: null,
                                                accionSeguimiento: null,
                                            })
                                            show();
                                        }}>
                                            <i className="fas fa-shoe-prints fa-rotate-270"></i>
                                        </button>
                                        <button type="button" className="btn btn-xs bg-yellow btn-tool ml-2" onClick={()=>{
                                            setSideBarState({
                                                mode: "referencias",
                                                labelProyecto: null,
                                                proyecto: null,
                                                postulante: id,
                                                labelPostulante: data?.findPostulanteById?.contacto?.alias,
                                                labelEmpresa: null,
                                                empresa: null
                                            })
                                            show();
                                        }}>
                                            <i className="fas fa-user-secret"></i>
                                        </button>
                                        <button
                                            type="button"
                                            title={t("postulante.field.asociarProyecto")}
                                            className={"btn btn-xs bg-info ml-2"}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                setShowProyectoDialog(true);
                                            }}>
                                            <i className="fas fa-search-plus"></i>
                                        </button>
                                        <button type="submit" title={t("common.button.guardar")} className="btn btn-xs bg-info btn-tool ml-2">
                                            <i className="fas fa-save"></i>
                                        </button>
                                        <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="parrish-form row ">
                                        <div className="col-xs-12 col-sm-6">

                                            <div className="parrish-form row ">                            
                                                <div className="col-xs-12 col-md-12">
                                                    <div className="form-group row">
                                                        <label htmlFor="value-nombre" className="parrish-label col-sm-2 col-xs-12 required">{t("postulante.field.nombre")}</label>
                                                        <div className="col">
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-nombre'}
                                                                className="parrish-input"
                                                                value={state.nombre}
                                                                maxLength={150}
                                                                onChange={(e) => {
                                                                    handleChange("nombre", e)
                                                                }}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="value-apellido" className="parrish-label col-sm-2 col-xs-12 required">{t("postulante.field.apellido")}</label>
                                                        <div className="col">
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-apellido'}
                                                                className="parrish-input"
                                                                value={state.apellido}
                                                                maxLength={150}
                                                                onChange={(e) => {
                                                                    handleChange("apellido", e)
                                                                }}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="value-email" className="parrish-label col-sm-2 col-xs-12 required">{t("postulante.field.email")}</label>
                                                        <div className="col">
                                                            <div className="input-group input-group-sm">
                                                                <div className="input-group-prepend cursor-pointer" onClick={(e)=>{emailClick(e,state.email)}}>
                                                                    <span className="input-group-text">
                                                                        <i className="fas fa-envelope fa-fw"></i>
                                                                    </span>
                                                                </div>
                                                                <input autoComplete="off" type="text"
                                                                    name={'value-email'}
                                                                    className="parrish-input"
                                                                    value={state.email}
                                                                    onChange={(e) => {
                                                                        handleChange("email", e)
                                                                    }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="value-appraisal" className="parrish-label col-sm-2 col-xs-12 align-self-start">{t("postulante.field.appraisal")}</label>
                                                        <div className="col">
                                                            <textarea
                                                                name="value-appraisal"
                                                                value={state.appraisal}
                                                                onChange={(e)=>handleChange("appraisal", e)}
                                                                className="form-control form-control-sm" rows="6"/>   
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-xs-12 col-sm-6">

                                            <div className="parrish-form row ">                            
                                                <div className="col-xs-12 col-md-12">

                                                    <div className="form-group row">
                                                        <span className="parrish-label col-sm-2 col-xs-12"></span>
                                                        <div className="col d-flex flex-column align-items-start">
                                                                <FlagButtonBoolean
                                                                    onClass="bg-danger d-inline-block"
                                                                    offClass="bg-info d-inline-block"
                                                                    value={state.verificar}
                                                                    label={t("postulante.field.verificar")}
                                                                    setValue={(val) => {
                                                                        handleChange("verificar", {target:{value: val}})
                                                                    }}
                                                                    icon="fas fa-exclamation-triangle" />
                                                                <FlagButtonBoolean
                                                                    onClass="bg-blue"
                                                                    offClass="bg-info"
                                                                    value={state.opportunity}
                                                                    setValue={(val) => {
                                                                        handleChange("opportunity", {target:{value: val}})
                                                                    }}
                                                                    label={t("postulante.field.oportunidad")}
                                                                    iconImg="/icons/opportunity_24.png" />
                                                                <FlagButtonBoolean
                                                                    onClass="bg-warning"
                                                                    offClass="bg-info"
                                                                    value={state.destacado}
                                                                    setValue={(val) => {
                                                                        handleChange("destacado", {target:{value: val}})
                                                                    }}
                                                                    label={t("postulante.field.destacado")}
                                                                    icon="fas fa-star" />
                                                                <FlagButtonBoolean
                                                                    onClass="bg-teal"
                                                                    offClass="bg-info"
                                                                    value={state.incubadora}
                                                                    setValue={(val) => {
                                                                        setState({
                                                                            ...state,
                                                                            incubadora: val,
                                                                            incubadoraProspecto: val?false:state.incubadoraProspecto
                                                                        });
                                                                    }}
                                                                    label={t("postulante.field.incubadora")}
                                                                    iconImg="/icons/incubadora_44.png" />
                                                                <FlagButtonBoolean
                                                                    onClass="bg-orange"
                                                                    offClass="bg-info"
                                                                    value={state.incubadoraProspecto}
                                                                    setValue={(val) => {
                                                                        setState({
                                                                            ...state,
                                                                            incubadoraProspecto: val,
                                                                            incubadora: val?false:state.incubadora
                                                                        });
                                                                    }}
                                                                    label={t("postulante.field.incubadoraProspecto")}
                                                                    iconImg="/icons/incubadora_44.png" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row pt-1 mt-2">
                                                        <label htmlFor="value-tags" className="parrish-label align-self-start col-sm-2 col-xs-12">{t("postulante.field.tags")}</label>
                                                        <div className="col">
                                                            <textarea
                                                                name="value-tags"
                                                                value={state.tags}
                                                                onChange={(e)=>handleChange("tags", e)}
                                                                className="form-control form-control-sm" rows="4"/>   
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <CardFooterRecordData record={data?.findPostulanteById}/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none m-0">
                <div className="card-header p-0 border-bottom-0 ">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-datos-cliente-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-datos-cliente-tab'); }}>
                                {t("postulante.label.datosPersonales")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-formacion-academica-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-formacion-academica-tab'); }}>
                                {t("postulante.label.formacionAcademica")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-experiencia-laboral-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-experiencia-laboral-tab'); }}>
                                {t("postulante.label.experienciaLaboral")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-conocimiento-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-conocimiento-tab'); }}>
                                {t("postulante.label.conocimiento")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-conexiones-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-conexiones-tab'); }}>
                                {t("postulante.label.conexiones")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-proyectos-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-proyectos-tab'); }}>
                                {t("postulante.label.proyectos")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                role="button"
                                className={classnames({ active: activeTab === 'custom-tabs-adjuntos-tab' })+" link-muted text-uppercase"}
                                onClick={() => { selectTab('custom-tabs-adjuntos-tab'); }}>
                                {t("postulante.label.adjuntos")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="custom-tabs-datos-cliente-tab">
                            {activeTab==="custom-tabs-datos-cliente-tab"&&!loading && !error &&
                                <DatosPersonalesTab state={state} setState={setState}/>
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-formacion-academica-tab">
                            {activeTab==="custom-tabs-formacion-academica-tab"&&
                                <FormacionAcademicaTab refetchPostulante={refetch} />
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-experiencia-laboral-tab">
                            {activeTab==="custom-tabs-experiencia-laboral-tab"&&
                                <ExperienciaLaboralTab refetchPostulante={refetch} />
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-conocimiento-tab">
                            {activeTab==="custom-tabs-conocimiento-tab"&&
                                <ConocimientoPostulanteTab idPostulante={id}/>
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-conexiones-tab">
                            {activeTab==="custom-tabs-conexiones-tab"&&
                                <ConexionesTab />
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-proyectos-tab">
                            {activeTab==="custom-tabs-proyectos-tab"&&
                                <ProyectosTab />
                            }
                        </TabPane>
                        <TabPane tabId="custom-tabs-adjuntos-tab">
                            {activeTab==="custom-tabs-adjuntos-tab"&&
                                <AdjuntosTab idPostulante={id}/>
                            }
                        </TabPane>
                    
                    </TabContent>
                </div>
            </div>                        
            <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={id} aliasPostulante={data?.findPostulanteById?.contacto?.alias} />
        </>
    )
}

export default PostulanteEdit