import React from 'react'
import { useMutation, gql } from '@apollo/client';
import useAlertMessage from '../hooks/useAlertMessage';
import { useTranslation } from 'react-i18next';

export default function DeleteEntityButton(props) {
    const {entity, id, label, onCompleted, className} = props;
    const [t] = useTranslation("global");

    const DELETE_ENTITY_MUTATION = gql`
        mutation deleteEntity(
            $id: Int!
        ) {
            delete${entity}(id: $id)
        }
    `;

    const [deleteEntityMutation] = useMutation(DELETE_ENTITY_MUTATION, {
        onCompleted: (data) => {
            if(onCompleted){
                onCompleted(data);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const { showConfirmDelete } = useAlertMessage();

    const deleteAction = () => {
        showConfirmDelete(
            id,
            label,
            (id)=>{deleteEntityMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    return (
        <button type="button" title={t("common.button.eliminar")} className={"btn btn-xs bg-info "+className} onClick={()=>{deleteAction()}}>
            <i className="fas fa-trash"></i>
        </button>   
    )
}