import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import TipoProyectoList from './list'
import TipoProyectoCreate from './create'
import TipoProyectoEdit from './edit'
import { useHistory, useParams } from 'react-router';

function TipoProyectoIndex(){
    const entityName = "tipoProyecto";
    const [searchFilter, setSearchFilter] = useState({
        nombre: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <TipoProyectoList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></TipoProyectoList>
                :
                null
            }
            {
                mode==='create'?
                    <TipoProyectoCreate></TipoProyectoCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <TipoProyectoEdit></TipoProyectoEdit>
                :
                null
            }
        </div>
    );
}

export default TipoProyectoIndex;