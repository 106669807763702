import React, {useEffect, useState} from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import Autosuggest from 'react-autosuggest';

export default function Autocomplete(props) {
    const {entity, setValue, value, name, by, filterName, additionalFilter, initialLabel, disabled, queryNameComplete, placeholder} = props;

    let queryName = queryNameComplete?queryNameComplete:("find"+entity+"KeyValueListBy"+by);
    let customFilter = additionalFilter?additionalFilter:"";

    const AUTOCOMPLETE_LIST = gql`
        query ${queryName}(
            $filter: String
        ) {
            ${queryName}(
                ${filterName}: $filter
                ${customFilter}
            ) {
                id
                label
            }
        }
    `;

    const [suggestions, setSuggestions] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [labelSelected, setLabelSelected] = useState("");

    useEffect(() => {
        if(value&&!selectedValue&&!labelSelected){
            //LOAD INICIAL DEL ALIAS DEL ENTITY
            if(initialLabel){
                setLabelSelected(initialLabel);
                setSelectedValue(initialLabel);
            }
        }
    }, [initialLabel, labelSelected, selectedValue, value])

    useEffect(() => {
        if(initialLabel){
            setLabelSelected(initialLabel);
            setSelectedValue(initialLabel);
        }
    }, [initialLabel])

    useEffect(() => {
        if(!value){
            setLabelSelected("");
            setSelectedValue("");
        }
    }, [value]);
    
    const [getSuggestions] = useLazyQuery(AUTOCOMPLETE_LIST, {
        onCompleted: (data) => {
            setSuggestions(data[queryName]);
        }
    });

    const onBlur = (event, { highlightedSuggestion }) => {
        if(!highlightedSuggestion){
            setSelectedValue(labelSelected);
        }
    }

    const onChange = (event, { newValue, method }) => {
        if(newValue!==""){
            setSelectedValue(newValue);
        }else{
            setSelectedValue("");
            setValue("", "");
            setLabelSelected("");
        }
    };
    
    const onSuggestionsFetchRequested = ({ value }) => {       
        getSuggestions({ variables: { filter: value } })
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (selectedValue) => {
        return `${selectedValue.label}`;
    };

    const inputProps = {
        name: name,
        value: selectedValue,
        onChange: onChange,
        onBlur: onBlur,
        disabled: disabled,
        placeholder: placeholder
    };

    const onSuggestionSelected = (event,{ suggestion }) => {
        setSelectedValue(suggestion.label);
        setLabelSelected(suggestion.label)
        setValue(suggestion.id, suggestion.label);
    };

    function shouldRenderSuggestions(value, reason) {
        return value.trim().length > 2;
    }

    function escapeRegex(string) {
        // eslint-disable-next-line no-useless-escape
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    
    const renderSuggestion = (suggestion, { query }) => {
        const reg = new RegExp('('+escapeRegex(query)+')', 'gi');
        const parts = suggestion?.label?.split(reg).map((part)=>{
            return {
                text: part,
                highlight: reg.test(part)
            }
        })

        return (
          <span className={'suggestion-content m-0'}>
            <span className="name">
              {
                parts.map((part, index) => {
                  return (
                    <span className={part.highlight?'highlight':''} key={index}>{part.text}</span>
                  );
                })
              }
            </span>
          </span>
        );
    }

    const theme = {
        container:                'w-100 react-autosuggest__container',
        containerOpen:            'w-100 react-autosuggest__container--open',
        input:                    'react-autosuggest__input',
        inputOpen:                'react-autosuggest__input--open',
        inputFocused:             'react-autosuggest__input--focused',
        suggestionsContainer:     'react-autosuggest__suggestions-container',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList:          'react-autosuggest__suggestions-list',
        suggestion:               'react-autosuggest__suggestion',
        suggestionFirst:          'react-autosuggest__suggestion--first',
        suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
        sectionContainer:         'react-autosuggest__section-container',
        sectionContainerFirst:    'react-autosuggest__section-container--first',
        sectionTitle:             'react-autosuggest__section-title'
      }

    return(
        <Autosuggest 
            theme={theme}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            shouldRenderSuggestions={shouldRenderSuggestions}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={onSuggestionSelected}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}/>
    );
}
