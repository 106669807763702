
const ETAPA_PROYECTO = {
    SOURCE: 1,
    IDENTIFICADO: 2,
    PROSPECTO: 3,
    CANDIDATO: 4,
    PLACEMENT: 5
}
Object.freeze(ETAPA_PROYECTO)

export default ETAPA_PROYECTO