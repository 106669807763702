import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import SimpleDatatable from '../../../../../components/SimpleDatatable';

const FIND_PROVINCIA_LIST_BY_FILTER = gql`
    query findProvinciaListByFilter(
        $pais: Int
        $sortColumn: String
        $sortDirection: String
    ) {
        findProvinciaListByFilter(
            filter: {
                pais: $pais
            }
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
        ) {
            id
            nombre
        }
    }
`;

function ProvinciaList(props) {
    const [t] = useTranslation("global");
    const { idPais, setCrudMode, setEditId} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "id",
        sortDirection: "DESC"
    });

    const {loading, error, data} = useQuery(
        FIND_PROVINCIA_LIST_BY_FILTER,
        {
            variables: {
                pais: parseInt(idPais),
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
            }
        }
    );

    const editProvincia = (id) => {
        setEditId(id);
        setCrudMode("edit");
    }

    return (
        <>
        <div className="card-header pl-3 pt-2 pb-2">
            <h3 className="card-title">{t("common.label.listado")} <span className="badge badge-info ml-2 p-1 pr-2 pl-2">{t("common.label.encontrados",{0: data?.findProvinciaListByFilter.length})}</span></h3>
            <div className="card-tools m-0">
                <button className="btn btn-xs btn-info bg-info float-right mb-2" onClick={()=>{setCrudMode("create")}} title={t("common.button.agregar")}>
                    <i className="fas fa-plus"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <button className="dropdown-item btn btn-info buttons-pdf buttons-html5">{t("common.label.exportar-pdf")}</button>
                    <button className="dropdown-item btn btn-info buttons-csv buttons-html5">{t("common.label.exportar-csv")}</button>
                    <button className="dropdown-item btn btn-info buttons-excel buttons-html5">{t("common.label.exportar-xls")}</button>
                    <button className="dropdown-item btn btn-info buttons-print">{t("common.label.exportar-impresora")}</button>
                </div>
            </div>
        </div>
        {!loading && !error &&
            <SimpleDatatable
                data={data?.findProvinciaListByFilter}
                entityName="provincia"
                onEdit={(element)=>{editProvincia(element?.id)}}
                sortOptions={sortOptions}
                setSortOptions={setSortOptions}
                columnsDefinition={[
                {
                    name: "id",
                    customSortKey:  "id",
                    type: "id"
                },
                {
                    name: "nombre",
                    customSortKey:  "nombre",
                    type: "text"
                }]}
            />
        }
        {/*
            provincias &&
            provincias.length > 0 ?
            (
                <table className="table parrish-table dataTable table-bordered table-striped table-hover">
                    <thead className="text-align-center">
                        <tr>
                        {
                            getProvinciaColumns().map(key => {
                                return(
                                    <th className="bg-info" key={key}>
                                        <div>{t("provincia.field."+key)}</div>
                                    </th>
                                )
                            })
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            provincias.map((element, index) => (
                                <tr key={element.id} onDoubleClick={()=>editProvincia(element.id)}>
                                {
                                    getProvinciaColumns().map(key => {
                                        let output = key==="id"?"# "+element[key]:element[key];
                                        return(
                                            <td className="p-2" key={key}>{output}</td>
                                        )
                                    })
                                }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )
            :
            (
                <p>No hay provincias</p>
            )
        */}
        </>
    )
}

export default ProvinciaList