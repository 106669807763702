import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import DatePicker from '../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import SelectEntity from '../../../../components/SelectEntity';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import MONEDA from '../../../../enums/Moneda';
import useCotizacionHelper from '../../../../helpers/useCotizacionHelper';
import { DateRfc }  from '../../DateRfc';

const CREATE_COMPROBANTE_NCND_MUTATION = gql`
    mutation createComprobanteNCND(
        $cbteReferencia: Int!
        $cbteTipo: Int!
        $cbteFechaEmision: LocalDate!
        $cbteFechaVencimiento: LocalDate!
        $cbteImporteFinal: Float!
        $cbteImporteIva: Float!
        $cotizacionDolar: Float!
        $cotizacionPeso: Float!
        $cbteDetalle: String!
        $cbteNro: String
    ) {
        createComprobanteNCND(entity:{
            cbteReferencia: $cbteReferencia
            cbteTipo: $cbteTipo
            cbteFechaEmision: $cbteFechaEmision
            cbteFechaVencimiento: $cbteFechaVencimiento
            cbteImporteFinal: $cbteImporteFinal
            cbteImporteIva: $cbteImporteIva
            cotizacionDolar: $cotizacionDolar
            cotizacionPeso: $cotizacionPeso
            cbteDetalle: $cbteDetalle
            cbteNro: $cbteNro
        }) {
            id
        }
    }
`;

const COMPROBANTE_INIT_NC_BY_ID = gql`
    query initComprobanteNCForComprobante(
        $id: Int!
    ) {
        initComprobanteNCForComprobante(
        id: $id
    ){
        fechaAprobacion
        fechaConciliacion
        fechaEmision
        cbteImporteFinal
        cbteImporteIva
        cbteImporteNeto
        cbteImpacto
        cotizacionDolar
        cotizacionPeso
        adjunto
        cbteDetalle
        cbteNro
        aprobadoPor {
            contacto {
                alias
            }
        }
        emitidoPor {
            contacto {
                alias
            }
        }
        proyecto {
            id
            ref
        }
        cbteTipo {
            id
            letra
            nemotecnico
            discriminaIva
        }
        cbteMoneda {
            id
            simbolo
        }
        referencia{
            id
            cbteNro
            cbteTipo{
                nemotecnico
            }
        }
    }
}
`;

const COMPROBANTE_INIT_ND_BY_ID = gql`
    query initComprobanteNDForComprobante(
        $id: Int!
    ) {
        initComprobanteNDForComprobante(
        id: $id
    ){
        fechaAprobacion
        fechaConciliacion
        fechaEmision
        cbteImporteFinal
        cbteImporteIva
        cbteImporteNeto
        cbteImpacto
        cotizacionDolar
        cotizacionPeso
        adjunto
        cbteDetalle
        cbteNro
        proyecto {
            id
            ref
        }
        cbteTipo {
            id
            letra
            nemotecnico
        }
        cbteMoneda {
            id
            simbolo
        }
        referencia{
            id
            cbteNro
            cbteTipo{
                nemotecnico
            }
        }
    }
}
`;

function FacturaEdit() {
    const history = useHistory();
    let { showErrorToast } = useAlertMessage();
    let { id, comprobanteId } = useParams();   
    const [comprobanteData, setComprobanteData] = useState();
    const ref = useRef();
    const [t] = useTranslation("global");

    const [monedaId, setMonedaId] = useState();
    
    const [initNotaCredito] = useLazyQuery(COMPROBANTE_INIT_NC_BY_ID, {
        onCompleted: (data) => {
            console.log("COMPROBANTE_INIT_NC_BY_ID", data)
            setDataInit(data?.initComprobanteNCForComprobante);
            changeMoneda(parseInt(data?.initComprobanteNCForComprobante?.cbteMoneda?.id));
            setMonedaId(parseInt(data?.initComprobanteNCForComprobante?.cbteMoneda?.id));
        }
    });
    
    const [initNotaDebito] = useLazyQuery(COMPROBANTE_INIT_ND_BY_ID, {
        onCompleted: (data) => {
            console.log("COMPROBANTE_INIT_ND_BY_ID", data)
            setDataInit(data?.initComprobanteNDForComprobante);
            changeMoneda(parseInt(data?.initComprobanteNDForComprobante?.cbteMoneda?.id));
            setMonedaId(parseInt(data?.initComprobanteNDForComprobante?.cbteMoneda?.id));
        }
    });
    
    const setDataInit = (comprobante) => {
        setComprobanteData(comprobante);
        if(comprobante){
            setCbteNro(comprobante.cbteNro ? comprobante.cbteNro : "");
            setCotizacionDolar(comprobante.cotizacionDolar ? comprobante.cotizacionDolar : "");
            setCotizacionPeso(comprobante.cotizacionPeso ? comprobante.cotizacionPeso : "");
            setCbteDetalle(comprobante.cbteDetalle ? comprobante.cbteDetalle : "");
            setReferenciaLabel(comprobante.referencia?.cbteTipo.nemotecnico+" ["+comprobante.referencia?.cbteNro+"]");
            if(comprobante.cbteFechaEmision){
                setCbteFechaEmision(new DateRfc(comprobante.cbteFechaEmision));
            }
            if(comprobante.cbteFechaVencimiento){
                setCbteFechaVencimiento(new DateRfc(comprobante.cbteFechaVencimiento));
            }
            if(comprobante.cbteTipo.discriminaIva){
                setPorcentajeIva(21);
            }
        }
    }
    
    const [porcentajeIva, setPorcentajeIva] = useState(0);
    
    const [importeNeto, setImporteNeto] = useState(0);
    const [cbteImporteIva, setCbteImporteIva] = useState(0);
    const [cbteImporteFinal, setCbteImporteFinal] = useState(0);
    
    const [cbteFechaEmision, setCbteFechaEmision] = useState();
    const [cbteFechaVencimiento, setCbteFechaVencimiento] = useState();
    
    const [cbteDetalle, setCbteDetalle] = useState();
    const [cbteNro, setCbteNro] = useState("")
    
    const [cotizacionPeso, setCotizacionPeso] = useState();
    const [cotizacionDolar, setCotizacionDolar] = useState();
    
    const [cotizacionPesoDisabled, setCotizacionPesoDisabled] = useState(false);
    const [cotizacionDolarDisabled, setCotizacionDolarDisabled] = useState(false);

    const [referenciaLabel, setReferenciaLabel] = useState("")

    const cotizacionHelper = useCotizacionHelper({
        setCotizacionPeso: (cotizacion)=>{
            setCotizacionPeso(cotizacion);
        },
        setCotizacionDolar: (cotizacion)=>{
            setCotizacionDolar(cotizacion);
        }
    });
    
    const [createComprobanteMutation] = useMutation(CREATE_COMPROBANTE_NCND_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/facturacion/edit/"+data.createComprobanteNCND.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        createComprobanteMutation({
            variables: {
                cbteReferencia: parseInt(comprobanteId),
                cbteTipo: parseInt(comprobanteData.cbteTipo.id),
                cbteFechaEmision: cbteFechaEmision,
                cbteFechaVencimiento: cbteFechaVencimiento,
                cbteImporteFinal: cbteImporteFinal,
                cbteImporteIva: cbteImporteIva,
                cotizacionDolar: cotizacionDolar,
                cotizacionPeso: cotizacionPeso,
                cbteDetalle: cbteDetalle,
                cbteNro: cbteNro
            }
        });
    }

    
    const calculateTotal = (neto) => {
        neto = parseFloat(neto.replace(".", "").replace(",", "."));
        const importeIva = neto*(porcentajeIva/100);
        setCbteImporteFinal(neto+importeIva)
        setCbteImporteIva(importeIva);
    }
    
    const calculateNeto = (total) => {
        total = total.replace(".", "").replace(",", ".");
        const importeIva = porcentajeIva*total/(100+porcentajeIva)
        setImporteNeto(total-importeIva)
        setCbteImporteIva(importeIva);
    }
    
    const changeMoneda = (moneda) => {
        setCotizacionPesoDisabled(moneda===MONEDA.PESOS);
        setCotizacionDolarDisabled(moneda===MONEDA.DOLARES);
    }
    
    useEffect(() => {
        if(id!=null && id==="nota-credito"){
            initNotaCredito({ variables: { id: parseInt(comprobanteId) } })
        }
        if(id!=null && id==="nota-debito"){
            initNotaDebito({ variables: { id: parseInt(comprobanteId) } })
        }
    },[comprobanteId, id, initNotaCredito, initNotaDebito]);
    
    useEffect(() => {
        $.validator.addMethod("moneyRequired", function(value, element) {
            return parseFloat(value.replace(".", "").replace(",", "."))>0 ;
        }, "El valor debe ser mayor a 0");
        $(ref.current).validate({
            rules: {
                "value-cbteFechaEmision": "required",
                "value-cbteFechaVencimiento": "required",
                "value-cbteNro": "required",
                "value-cotizacionDolar": "moneyRequired",
                "value-cotizacionPeso": "moneyRequired",
                "value-importeNeto": "moneyRequired",
                "value-importeTotal": "moneyRequired"
            },
            messages: {
                "value-cbteFechaEmision":{
                    required: t("common.message.field.required", {0: t("comprobante.field.cbteFechaEmision")}),
                },
                "value-cbteFechaVencimiento":{
                    required: t("common.message.field.required", {0: t("comprobante.field.cbteFechaVencimiento")}),
                },
                "value-cbteNro":{
                    required: t("common.message.field.required", {0: t("comprobante.field.comprobante")}),
                },
                "value-cotizacionDolar":{
                    required: t("common.message.field.required", {0: t("comprobante.field.cotizacionDolar")}),
                },
                "value-cotizacionPeso":{
                    required: t("common.message.field.required", {0: t("comprobante.field.cotizacionPeso")}),
                },
                "value-importeNeto":{
                    moneyRequired: t("common.message.field.required", {0: t("comprobante.field.importeNeto")}),
                },
                "value-importeTotal":{
                    moneyRequired: t("common.message.field.required", {0: t("comprobante.field.importeTotal")}),
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={"card card-outline card-info"}>
                <div className="card-header">
                    <h3 className="card-title">
                        {t("common.label.creacion")}
                        &nbsp;
                        {(id!=null && id==="nota-credito")&&   
                            t("facturacion.label.notaCredito")
                        }
                        {(id!=null && id==="nota-debito")&&
                            t("facturacion.label.notaDebito")
                        }
                    </h3>
                    <div className="card-tools m-0">
                        <button type="submit" title={t("common.button.guardar")} className="btn btn-xs bg-info btn-tool ml-2">
                            {t("common.button.guardar")}
                        </button>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/facturacion"}>
                            <i className="fas fa-times"></i>
                        </PrivateLink>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={comprobanteData?.fechaRegistro?new DateRfc(comprobanteData?.fechaRegistro):null}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={comprobanteData?.registradoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaAprobacion" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaAprobacion")}</label>
                                <div className="col">
                                    <DatePicker readOnly selected={comprobanteData?.fechaAprobacion?new DateRfc(comprobanteData?.fechaAprobacion):null}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={comprobanteData?.aprobadoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaEmision" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.fechaEmision")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaEmision" readOnly selected={comprobanteData?.fechaEmision?new DateRfc(comprobanteData?.fechaEmision):null} />
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={comprobanteData?.emitidoPor?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-proyecto" className="parrish-label col-sm-2 col-xs-12">{t("comprobante.field.proyecto")}</label>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={comprobanteData?.proyecto?("["+comprobanteData?.proyecto?.id+"] - "+comprobanteData?.proyecto?.ref):""}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("comprobante.label.datosComprobante")}</h4>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteFechaEmision" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaEmision")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker name="value-cbteFechaEmision" maxDate={cbteFechaVencimiento} selected={cbteFechaEmision} onChange={date => {
                                        setCbteFechaEmision(date);                                       
                                        cotizacionHelper.execute(date, monedaId);
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteFechaVencimiento" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaVencimiento")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker name="value-cbteFechaVencimiento" minDate={cbteFechaEmision} selected={cbteFechaVencimiento} onChange={date => setCbteFechaVencimiento(date)}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteTipo" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.comprobante")}</label>
                                <div className="col-sm-6 col-xs-12">
                                    <SelectEntity
                                        hideEmptyOption={true}
                                        entity="TipoComprobante"
                                        label="tipo, letra"
                                        displayText={(element)=>{
                                            return(element.tipo+" "+element.letra)
                                        }}
                                        name={'value-cbteTipo'}
                                        value={comprobanteData?.cbteTipo.id}
                                        required
                                        disabled/>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" type="text" name={'value-cbteNro'} className="parrish-input" value={cbteNro} onChange={e => setCbteNro(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cbteReferencia" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.cbteReferencia")}</label>
                                <div className="col-sm-2">
                                    <input autoComplete="off" type="text" name={'value-cbteReferencia'} disabled className="parrish-input" defaultValue={referenciaLabel} />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-comentario-estimacion" className="parrish-label col-sm-2 col-xs-12">{t("comprobante.field.detalle")}</label>
                                <div className="col-sm-8 col-xs-12">
                                    <textarea name="value-comentario-estimacion" className="form-control form-control-sm" rows="3" value={cbteDetalle} onChange={e => setCbteDetalle(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-moneda" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={comprobanteData?.cbteMoneda?.simbolo}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-importeNeto" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.importeNeto")+" ("+(comprobanteData?.cbteMoneda?.simbolo?comprobanteData?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-importeNeto"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale="true"
                                        value={importeNeto}
                                        className={"parrish-input text-right"}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setImporteNeto(parseFloat(val.replaceAll(".","").replaceAll(",",".")));
                                                calculateTotal(val);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("comprobante.field.cotizacion", { 1: "(U$D)", 0: comprobanteData?.cbteMoneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        disabled={cotizacionDolarDisabled}
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionDolar(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
        
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="filter-importeIva" className="parrish-label col-sm-4 col-xs-12">{t("comprobante.field.iva")+" ("+(comprobanteData?.cbteMoneda?.simbolo?comprobanteData?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={cbteImporteIva}
                                    className={"parrish-input text-right"}
                                    disabled
                                    />
                                </div>      
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("comprobante.field.cotizacion", { 1: "(AR$)", 0: comprobanteData?.cbteMoneda?.simbolo })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        disabled={cotizacionPesoDisabled}
                                        name="value-cotizacionPeso"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionPeso}
                                        className={"parrish-input text-right"}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionPeso(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-importeTotal" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.importeTotal")+" ("+(comprobanteData?.cbteMoneda?.simbolo?comprobanteData?.cbteMoneda?.simbolo:"")+")"}</label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-importeTotal"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale="true"
                                        value={cbteImporteFinal}
                                        className={"parrish-input text-right"}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCbteImporteFinal(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                                calculateNeto(val);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    )
}

export default FacturaEdit