import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function CardFooterRecordData(props) {
    const [t] = useTranslation("global");
    const {record, dateLabel, datePropName} = props;
    
    const getDate = ()=>{
        if(record?.fechaActualizacion){
            return(record?.fechaActualizacion);
        }
        if(record?.fechaAlta){
            return(record?.fechaAlta);
        }
    }
    
    const date = (datePropName && record)?(record[datePropName]):getDate();
    
    const getLabelKey = ()=>{
        if(dateLabel){
            return(dateLabel);
        }
        if(record?.fechaActualizacion){
            return("common.label.actualizadoEl");
        }
        if(record?.fechaAlta){
            return("common.label.creadoEl");
        }
    }

    return (
        <div className="card-footer p-0 pr-3">
            <span className="float-right text-muted small">
                {date&&
                    <>
                    {t(getLabelKey())} <b>{moment(date).format('DD-MM-YYYY')}</b>
                    </>
                }
                {record?.responsable?.contacto &&
                    <> {t(date?"common.label.por":"common.label.actualizadoPor")} <b>{record?.responsable?.contacto?.alias}</b></>
                }
            </span>
        </div>
    )
}
