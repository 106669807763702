import React, { useEffect, useContext } from 'react'
import Header from './layout/Header'
import Menu from './layout/Menu'
import Footer from './layout/Footer';

import Login from './pages/app/security/Login';
import ForgotPassword from './pages/app/security/ForgotPassword';
import PasswordRecovery from './pages/app/security/PasswordRecovery';
import EmailConfirmation from './pages/app/security/EmailConfirmation';
import ExpiredPassword from './pages/app/security/ExpiredPassword';

import { Switch, Route, Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import AppStateContext from "./context/AuthContext.js";
import { useTranslation } from "react-i18next"
import useAlertMessage from './hooks/useAlertMessage';
import AuthorizedRoutes from './AuthorizedRoutes';
import SidebarStateProvider from './provider/SidebarStateProvider';
import NovedadesApp from './components/NovedadesApp';
import AuthContext from './context/AuthContext.js';
import SecurityPageWrapper from './pages/app/security/SecurityPageWrapper';

export default function SecureWeb() {
  const { appSetLogin } = useContext(AppStateContext);
  const { isLogged, isExpired } = useContext(AppStateContext);
  const [t] = useTranslation("global");
  const history = useHistory();
  let { clearPopUps } = useAlertMessage();
  const location = useLocation();

  const sessionStorage_transfer = function(event) {
    console.log("sessionStorage_transfer");
    const reqUrl = location.pathname;
    if(!event) { event = window.event; } // ie suq
    if(!event.newValue) return;          // do nothing if no value to work with
    if (event.key === 'getSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
      appSetLogin(window.sessionStorage.getItem("jwt"));
      history.replace(reqUrl);
      window.location.reload();
    }
  };
  
  if(window.addEventListener) {
    window.addEventListener("storage", sessionStorage_transfer, false);
  } else {
    window.attachEvent("onstorage", sessionStorage_transfer);
  };
  
  useEffect(() => {
    document.title = t("layout.value.project.title")+" - "+t("layout.value.project.slogan");
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage', 'foobar');
    };
    return history.listen((location) => { 
      clearPopUps();
      //console.log("location change", location);
    })
  },[clearPopUps, history, t])

  const RefreshToken = () => {
    let { jwt } = useParams();
    const { appSetLogin } = useContext(AuthContext);

    useEffect(() => {
      console.log("jwt",jwt);
      if(jwt){
        appSetLogin(jwt);
        history.replace("/");
        window.location.reload();
      }
    }, [appSetLogin, jwt])

    return(<SecurityPageWrapper />)
  }

  if(isLogged())
    return (
      <SidebarStateProvider>
          <NovedadesApp />
          <Header/>
          <Menu/>
          <AuthorizedRoutes/>
          <Footer/>
      </SidebarStateProvider>
    );
  else
    return (
      <Switch>
        <Route path="/jwt/:jwt">
          <RefreshToken/>
        </Route>
        <Route path="/login/:token?" component={Login}>
        {
          isExpired()?
          <Redirect to="/expired-password" />
          :null
        }
        </Route>
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/password-recovery/:token" component={PasswordRecovery}/>
        <Route path="/email-confirmation/:token" component={EmailConfirmation}/>
        <Route path="/expired-password" component={ExpiredPassword}>
        {
          !isExpired()?
          <Redirect to="/login" />
          :null
        }
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
}