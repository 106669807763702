import React from 'react'
import NumberFormat from 'react-number-format';

export default function MoneyInput(props) {
    const {value, name, className, onChange, onBlur, disabled} = props;
    return (
        <NumberFormat
            name={name}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale="true"
            disabled={disabled}
            value={value}
            className={"parrish-input text-right "+className}
            onChange={(e) => {
                const val = e.target?.value;
                const moneyValue = parseFloat(val.replaceAll(".","").replaceAll(",","."))
                if(val){
                    if(onChange){
                        onChange({...e, target:{...e.target, value: moneyValue}})
                    }
                }
            }}
            onBlur={(e) => {
                const value = e.target?.value;
                const moneyValue = parseFloat(value.replaceAll(".","").replaceAll(",","."))
                if(value){
                    if(onBlur){
                        onBlur({...e, target:{...e.target, value: moneyValue}})
                    }
                }
            }}
            />
    )
}
