import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import TotalCard from './components/totalCard';
import AcuerdoComercialCard from './components/acuerdoComercialCard';
import MovimientosList from './components/movimientosList';
import ComprobantesList from './components/comprobantesList';
import ComprobanteDialog from './components/comprobanteDialog';

const FIND_RESUMEN_ADMINISTRATIVO_BY_PROYECTO = gql`
    query findResumenAdministrativoByProyecto(
        $idProyecto: Int!
    ) {
        findResumenAdministrativoByProyecto(
            idProyecto: $idProyecto
        ){
            honorariosTotalesFinal
            honorariosTotalesIVA
            honorariosTotalesNeto
            pendienteRegistracionFinal
            pendienteRegistracionIVA
            pendienteRegistracionNeto
            pendienteAprobacionFinal
            pendienteAprobacionIVA
            pendienteAprobacionNeto
            pendienteEmisionFinal
            pendienteEmisionIVA
            pendienteEmisionNeto
            pendienteCobranzaFinal
            pendienteConciliacionFinal
            pendienteConciliacionIVA
            pendienteConciliacionNeto
        }
    }
`;

const ACUERDO_RESPONSE = `id
fechaOriginacion
beneficiosAdicionales
bonus
hiringBonus
mesesAnualizacion
modo
retainer
sueldoBrutoMensual
feeStandard
feePlusB
tipoBonus
tipoHiringBonus
comentario
tipo{
    id
    nombre
}
moneda{
    id
    simbolo
}
postulante{
    contacto{
        alias
    }
}
totales{
    sueldoBrutoTotal
    bonusTotal
    hiringBonusTotal
    remuneracionTotal
    remuneracionInicialAcordada
    diferenciaPlusB
    honorarioStandard
    honorarioPlusB
    honorarioFinal
}`

const FIND_ACUERDO_PLACEMENT_LIST_BY_PROYECTO = gql`
    query findAcuerdoPlacementByProyecto(
        $idProyecto: Int!
    ) {
        findAcuerdoPlacementByProyecto(
            idProyecto: $idProyecto
    ) {
        ${ACUERDO_RESPONSE}
    }
}
`;

const FIND_ACUERDO_DOUBLE_PLACEMENT_LIST_BY_PROYECTO = gql`
    query findAcuerdoDoublePlacementListByProyecto(
        $idProyecto: Int!
    ) {
        findAcuerdoDoublePlacementListByProyecto(
            idProyecto: $idProyecto
    ) {
        ${ACUERDO_RESPONSE}
    }
}
`;

const FIND_COMPROBANTE_LIST_BY_PROYECTO = gql`
    query findComprobanteListByProyecto(
        $idProyecto: Int!
        $sortColumn: String
        $sortDirection: String
    ) {
        findComprobanteListByProyecto(
            idProyecto: $idProyecto
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
    ) {
        id
        adjunto
        estado {
            id
            color
            nombre
        }
        fechaRegistro
        registradoPor {
            contacto {
            alias
            }
        }
        fechaAprobacion
        aprobadoPor {
            contacto {
            alias
            }
        }
        fechaEmision
        emitidoPor {
            contacto {
            alias
            }
        }
        fechaConciliacion
        conciliadoPor {
            contacto {
            alias
            }
        }
        cliente {
            id
            contacto{
                alias
            }
        }
        proyecto {
            id
            ref
        }
        cbteFechaEmision
        cbteFechaVencimiento
        cbteTipo {
            nemotecnico
        }
        cbteNro
        cbteDetalle
        cbteMoneda {
            id
            simbolo
        }
        cbteImporteFinal
        cbteImporteIva
        cbteImporteNeto
        cbteImpacto
        cotizacionPeso
        cotizacionDolar
    }
}
`;

const FIND_MOVIMIENTO_LIST_BY_PROYECTO = gql`
    query findMovimientoListByProyecto(
        $idProyecto: Int!
        $sortColumn: String
        $sortDirection: String
    ) {
        findMovimientoListByProyecto(
            idProyecto: $idProyecto
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
    ) {
        id
        adjunto
        estado {
            id
            color
            nombre
        }
        fechaAcreditacion
        fechaRegistro
        registradoPor {
            contacto {
                alias
            }
        }
        fechaConciliacion
        conciliadoPor {
            contacto {
                alias
            }
        }
        fechaCancelacion
        canceladoPor {
            contacto {
                alias
            }
        }
        fechaCobranza
        cobradoPor {
            contacto {
                alias
            }
        }
        cliente {
            id
            contacto{
                alias
            }
        }
        moneda {
            id
            simbolo
        }
        formaPago{
            nombre
        }
        retencion{
            nombre
        }
        cuenta{
            banco{
                nombre
            }
            tipoCuenta{
                nombre
            }
            moneda{
                simbolo
            }
        }
        impacto
        importe
        cotizacionPeso
        cotizacionDolar
        detalle
        detalleCbtesAsociados
        chequeBanco{
            nombre
        }
        chequeFecha
        chequeNro
        chequeTitular
    }
}
`;

const CERRAR_PROYECTO_ADM = gql`
    mutation cerrarProyectoAdministrativo(
        $idProyecto: Int!
    ) {
        cerrarProyectoAdministrativo(
            idProyecto: $idProyecto
        ) {
            id
        }
    }
`;
    

export default function ComercialAdministrativoTab(props) {
    const { proyecto, refetch } = props;
    const [t] = useTranslation("global");
    const [acuerdoComercial, setAcuerdoComercial] = useState(proyecto.acuerdoInicial);

    const [comprobanteSelected, setComprobanteSelected] = useState();

    useEffect(() => {
        setAcuerdoComercial(proyecto.acuerdoInicial);
    }, [proyecto.acuerdoInicial])

    const [cerrarProyectoAdmMutation] = useMutation(CERRAR_PROYECTO_ADM,{
        onCompleted: () => {
            refetch();           
        },
        onError: () => {
        }
    });

    const {loading: loadingResumen , error: errorResumen, data: dataResumen, refetch: refetchResumen } = useQuery(
        FIND_RESUMEN_ADMINISTRATIVO_BY_PROYECTO,
        {
            variables: {
                idProyecto: parseInt(proyecto.id)
            }
        }
    );

    const {loading: loadingPlacement, error: errorPlacement, data: dataPlacements, refetch: refetchPlacements} = useQuery(
        FIND_ACUERDO_PLACEMENT_LIST_BY_PROYECTO,
        {
            variables: {
                idProyecto: parseInt(proyecto.id)
            }
        }
    );

    const {loading: loadingDoublePlacement, error: errorDoublePlacement, data: dataDoublePlacement, refetch: refetchDoublePlacements} = useQuery(
        FIND_ACUERDO_DOUBLE_PLACEMENT_LIST_BY_PROYECTO,
        {
            variables: {
                idProyecto: parseInt(proyecto.id)
            }
        }
    );

    const {loading: loadingComprobantes, error: errorComprobantes, data: dataComprobantes, refetch: refetchComprobantes} = useQuery(
        FIND_COMPROBANTE_LIST_BY_PROYECTO,
        {
            variables: {
                idProyecto: parseInt(proyecto.id)
            }
        }
    );

    const {loading: loadingMovimientos, error: errorMovimientos, data: dataMovimientos, refetch: refetchMovimientos} = useQuery(
        FIND_MOVIMIENTO_LIST_BY_PROYECTO,
        {
            variables: {
                idProyecto: parseInt(proyecto.id)
            }
        }
    );

    return (
        <div className="parrish-form row ">
            <div className="col-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="card card-info card-outline bg-transparent mt-0 mb-0">
                            <div className="card-header">
                                <h3 className="card-title pl-3">{t("proyecto.label.resumenEstadoAdministrativos")}</h3>
                                <div className="card-tools m-0">
                                    <button
                                        type="button"
                                        title={t("common.button.limpiar")}
                                        className="btn btn-xs btn-info bg-info btn-tool mr-3"
                                        disabled={proyecto.fechaCierreAdministrativo}
                                        onClick={()=>{
                                        cerrarProyectoAdmMutation({
                                            variables:{ idProyecto: proyecto.id }
                                        })
                                    }}>
                                        {t("acuerdoComercial.button.cierreAdministrativo")}
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                {!loadingResumen&&!errorResumen&&
                                    <div className="col-12 d-flex justify-content-center flex-wrap">
                                        <TotalCard
                                            title={t("acuerdoComercial.label.totalHonorarios")}
                                            totalClassName="text-dark"
                                            totalZeroClassName="text-dark"
                                            total={dataResumen?.findResumenAdministrativoByProyecto?.honorariosTotalesFinal}
                                            iva={dataResumen?.findResumenAdministrativoByProyecto?.honorariosTotalesIVA}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.honorariosTotalesNeto}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                        <TotalCard
                                            title={t("acuerdoComercial.label.registracion")}
                                            total={dataResumen?.findResumenAdministrativoByProyecto?.pendienteRegistracionFinal}
                                            iva={dataResumen?.findResumenAdministrativoByProyecto?.pendienteRegistracionIVA}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.pendienteRegistracionNeto}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                        <TotalCard
                                            title={t("acuerdoComercial.label.aprobacion")}
                                            total={dataResumen?.findResumenAdministrativoByProyecto?.pendienteAprobacionFinal}
                                            iva={dataResumen?.findResumenAdministrativoByProyecto?.pendienteAprobacionIVA}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.pendienteAprobacionNeto}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                        <TotalCard
                                            title={t("acuerdoComercial.label.emision")}
                                            total={dataResumen?.findResumenAdministrativoByProyecto?.pendienteEmisionFinal}
                                            iva={dataResumen?.findResumenAdministrativoByProyecto?.pendienteEmisionIVA}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.pendienteEmisionNeto}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                        <TotalCard
                                            title={t("acuerdoComercial.label.cobranza")}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.pendienteCobranzaFinal}
                                            totalLabel={t("acuerdoComercial.label.pendienteTotal")}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                        <TotalCard
                                            title={t("acuerdoComercial.label.conciliacion")}
                                            total={dataResumen?.findResumenAdministrativoByProyecto?.pendienteConciliacionFinal}
                                            iva={dataResumen?.findResumenAdministrativoByProyecto?.pendienteConciliacionIVA}
                                            value={dataResumen?.findResumenAdministrativoByProyecto?.pendienteConciliacionNeto}
                                            monedaLabel={proyecto?.acuerdoInicial?.moneda?.simbolo}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 pt-1 pb-2">
                        <AcuerdoComercialCard proyecto={proyecto} acuerdoComercial={acuerdoComercial} refetch={()=>{refetch(); refetchResumen(); refetchPlacements(); refetchDoublePlacements();}} />
                    </div>
                    {!loadingPlacement&&!errorPlacement&&dataPlacements?.findAcuerdoPlacementByProyecto&&
                        <div className="col-12 pt-1 pb-2">
                            <AcuerdoComercialCard proyecto={proyecto} acuerdoComercial={dataPlacements?.findAcuerdoPlacementByProyecto} refetch={()=>{refetchPlacements(); refetchResumen();}} />
                        </div>
                    }
                    {!loadingDoublePlacement&&!errorDoublePlacement&&
                        dataDoublePlacement?.findAcuerdoDoublePlacementListByProyecto?.map(acuerdoComercialDoublePlacement => {
                            return(
                                <div className="col-12 pt-1 pb-1" key={"AcuerdoDoublePlacementList-"+acuerdoComercialDoublePlacement.id}>
                                    <AcuerdoComercialCard proyecto={proyecto} acuerdoComercial={acuerdoComercialDoublePlacement} refetch={()=>{refetchDoublePlacements(); refetchResumen();}} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="card card-info card-outline bg-transparent mt-3 mb-0">
                    <div className="card-header">
                        <h3 className="card-title pl-3">{t("acuerdoComercial.label.gestionAdministrativa")}</h3>
                        <div className="card-tools m-0">
                            {!loadingResumen&&!errorResumen&&dataResumen?.findResumenAdministrativoByProyecto&&
                                !loadingComprobantes&&!errorComprobantes&&dataComprobantes&&
                                <ComprobanteDialog proyecto={proyecto} acuerdoComercial={acuerdoComercial} comprobanteSelected={comprobanteSelected} setComprobanteSelected={setComprobanteSelected} comprobantesCount={dataComprobantes?.findComprobanteListByProyecto?.length?dataComprobantes?.findComprobanteListByProyecto?.length:0} pendienteRegistracion={dataResumen?.findResumenAdministrativoByProyecto} refetch={()=>{refetchComprobantes(); refetchResumen();}}/>
                            }
                        </div>
                    </div>
                    <div className="card-body p-0">
                        {!loadingComprobantes&&!errorComprobantes&&dataComprobantes&&
                            <ComprobantesList data={dataComprobantes} fechaCierreAdministrativo={proyecto.fechaCierreAdministrativo} refetch={()=>{refetchComprobantes(); refetchResumen();}} comprobanteSelected={comprobanteSelected} setComprobanteSelected={setComprobanteSelected}/>
                        }
                        {!loadingMovimientos&&!errorMovimientos&&dataMovimientos&&
                            <MovimientosList data={dataMovimientos} refetch={()=>{refetchMovimientos(); refetchResumen();}}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}