import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import PrivateLink from '../../../../components/navigation/PrivateLink';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import DatePicker from '../../../../components/DatePicker';
import SelectEntity from '../../../../components/SelectEntity';
import NumberFormat from 'react-number-format';
import FlagButtonBoolean from '../../../../components/FlagButtonBoolean';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import EmpresaSelector from '../../../../components/EmpresaSelector';

const CREATE_POSTULANTE_MUTATION = gql`
    mutation createPostulante(
        $destacado: Boolean!
        $fechaNacimientoEstimada: Boolean!
        $incubadora: Boolean!
        $incubadoraProspecto: Boolean!
        $opportunity: Boolean!
        $verificar: Boolean!
        $genero: String!
        $nombre: String!
        $apellido: String!
        $email: String!
        $fechaNacimiento: LocalDate
        $experienciaLaboralAreaEmpresa: Int!
        $experienciaLaboralCargo: Int!
        $experienciaLaboralEmpresa: Int!
        $experienciaLaboralPais: Int!
        $experienciaLaboralFechaInicio: LocalDate!
        $experienciaLaboralFechasEstimadas: Boolean!
        $estadoCivil: Int
        $appraisal: String
        $experienciaLaboralFechaFin: LocalDate
        $experienciaLaboralMoneda: Int
        $experienciaLaboralSueldoBrutoMensual: Int
        $experienciaLaboralBeneficios: String
        $experienciaLaboralBonus: String
        $experienciaLaboralPosicion: String
        $experienciaLaboralResumen: String
    ) {
        createPostulante(entity:{
            fechaNacimientoEstimada: $fechaNacimientoEstimada
            genero: $genero
            nombre: $nombre
            apellido: $apellido
            email: $email
            fechaNacimiento: $fechaNacimiento
            destacado: $destacado
            incubadora: $incubadora
            incubadoraProspecto: $incubadoraProspecto
            opportunity: $opportunity
            verificar: $verificar
            estadoCivil: $estadoCivil
            appraisal: $appraisal
            experienciaLaboralAreaEmpresa: $experienciaLaboralAreaEmpresa
            experienciaLaboralCargo: $experienciaLaboralCargo
            experienciaLaboralEmpresa: $experienciaLaboralEmpresa
            experienciaLaboralPais: $experienciaLaboralPais
            experienciaLaboralFechaInicio: $experienciaLaboralFechaInicio
            experienciaLaboralFechaFin: $experienciaLaboralFechaFin
            experienciaLaboralFechasEstimadas: $experienciaLaboralFechasEstimadas
            experienciaLaboralMoneda: $experienciaLaboralMoneda
            experienciaLaboralSueldoBrutoMensual: $experienciaLaboralSueldoBrutoMensual
            experienciaLaboralBeneficios: $experienciaLaboralBeneficios
            experienciaLaboralBonus: $experienciaLaboralBonus
            experienciaLaboralPosicion: $experienciaLaboralPosicion
            experienciaLaboralResumen: $experienciaLaboralResumen
        }) {
            id
        }
    }
`;

function PostulanteEdit() {
    const history = useHistory();
    let { showErrorToast } = useAlertMessage();

    const ref = useRef();
    const [t] = useTranslation("global");
    
    const [createPostulanteMutation] = useMutation(CREATE_POSTULANTE_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/postulante/edit/"+data.createPostulante.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [state, setState] = useState({
        fechaNacimientoEstimada: false,
        genero: "",
        nombre: "",
        apellido: "",
        email: "",
        fechaNacimiento: "",
        destacado: false,
        incubadora: false,
        incubadoraProspecto: false,
        opportunity: false,
        verificar: false,
        appraisal: "",
        experienciaLaboralAreaEmpresa: "",
        experienciaLaboralCargo: "",
        experienciaLaboralEmpresa: "",
        experienciaLaboralPais: "",
        experienciaLaboralFechaInicio: "",
        experienciaLaboralFechaFin: "",
        experienciaLaboralFechasEstimadas: false,
        experienciaLaboralMoneda: "",
        experienciaLaboralSueldoBrutoMensual: "",
        experienciaLaboralBeneficios: "",
        experienciaLaboralBonus: "",
        experienciaLaboralPosicion: "",
        experienciaLaboralResumen: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        createPostulanteMutation({
            variables: {
                fechaNacimientoEstimada: state.fechaNacimientoEstimada, 
                genero: state.genero, 
                nombre: state.nombre, 
                apellido: state.apellido, 
                email: state.email, 
                fechaNacimiento: state?.fechaNacimiento?state?.fechaNacimiento:null, 
                destacado: state.destacado, 
                incubadora: state.incubadora, 
                incubadoraProspecto: state.incubadoraProspecto, 
                opportunity: state.opportunity, 
                verificar: state.verificar,
                appraisal: state.appraisal,
                experienciaLaboralAreaEmpresa: parseInt(state.experienciaLaboralAreaEmpresa),
                experienciaLaboralCargo: parseInt(state.experienciaLaboralCargo), 
                experienciaLaboralEmpresa: parseInt(state.experienciaLaboralEmpresa),
                experienciaLaboralPais: parseInt(state.experienciaLaboralPais),
                experienciaLaboralFechaInicio: state.experienciaLaboralFechaInicio,
                experienciaLaboralFechasEstimadas: state.experienciaLaboralFechasEstimadas,
                experienciaLaboralFechaFin: state.experienciaLaboralFechaFin?state.experienciaLaboralFechaFin:null,
                experienciaLaboralMoneda: state.experienciaLaboralMoneda?parseInt(state.experienciaLaboralMoneda):null,
                experienciaLaboralSueldoBrutoMensual: state.experienciaLaboralSueldoBrutoMensual?state.experienciaLaboralSueldoBrutoMensual:null,
                experienciaLaboralBeneficios: state.experienciaLaboralBeneficios?state.experienciaLaboralBeneficios:null,
                experienciaLaboralBonus: state.experienciaLaboralBonus?state.experienciaLaboralBonus:null,
                experienciaLaboralPosicion: state.experienciaLaboralPosicion?state.experienciaLaboralPosicion:null,
                experienciaLaboralResumen: state.experienciaLaboralResumen?state.experienciaLaboralResumen:null
            }
        });
    }

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    /*const setIncubadora = (value)=>{
        if(value){
            setState({
                ...state,
                "incubadora": value,
                "incubadoraProspecto": false,
            });
        }else{
            setState({
                ...state,
                "incubadora": value,
            });
        }
    }

    const setProspectoIncubadora = (value)=>{
        if(value){
            setState({
                ...state,
                "incubadoraProspecto": value,
                "incubadora": false,
            });
        }else{
            setState({
                ...state,
                "incubadoraProspecto": value,
            });
        }
    }*/

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-nombre": "required",
                "value-apellido": "required",
                "value-genero": "required",
                "value-empresa": "required",
                "value-areaEmpresa": "required",
                "value-cargo": "required",
                "value-pais": "required",
                "value-periodo-inicio": "required",
                "value-email": {
                    required: true,
                    email: true
                }
            },
            messages: {
                "value-nombre": { required: t("common.message.field.required", {0: t("postulante.field.nombre")}) },
                "value-apellido": { required: t("common.message.field.required", {0: t("postulante.field.apellido")}) },
                "value-genero": { required: t("common.message.field.required", {0: t("postulante.field.genero")}) },
                "value-email": {
                    required: t("common.message.field.required", {0: t("postulante.field.email")}),
                    email: t("common.message.invalid.email.format")
                },
                "value-empresa":  { required: t("common.message.field.required", {0: t("postulante.field.empresa")}) },
                "value-areaEmpresa":  { required: t("common.message.field.required", {0: t("postulante.field.areaEmpresa")}) },
                "value-cargo":  { required: t("common.message.field.required", {0: t("postulante.field.cargo")}) },
                "value-pais":  { required: t("common.message.field.required", {0: t("postulante.field.pais")}) },
                "value-periodo-inicio": { required: t("common.message.field.required", {0: t("postulante.field.periodo")}) },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={"card card-outline card-info"}>
                <div className="card-header">
                    <h3 className="card-title">
                        {t("common.label.creacion")}
                    </h3>
                    <div className="card-tools m-0">
                        <button type="submit" title={t("common.button.guardar")} className="btn btn-xs bg-info btn-tool ml-2">
                            <i className="fas fa-save"></i>
                        </button>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/postulante"}>
                            <i className="fas fa-times"></i>
                        </PrivateLink>
                    </div>
                </div>

                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-nombre" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.nombre")}</label>
                                <div className="col">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-nombre'}
                                        className="parrish-input"
                                        value={state.nombre}
                                        maxLength={150}
                                        onChange={(e) => {
                                            handleChange("nombre", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-apellido" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.apellido")}</label>
                                <div className="col">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-apellido'}
                                        className="parrish-input"
                                        value={state.apellido}
                                        maxLength={150}
                                        onChange={(e) => {
                                            handleChange("apellido", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-email" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.email")}</label>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-envelope fa-fw"></i>
                                            </span>
                                        </div>
                                        <input autoComplete="off" type="text"
                                            name={'value-email'}
                                            className="parrish-input"
                                            value={state.email}
                                            onChange={(e) => {
                                                handleChange("email", e)
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaNacimiento" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.fechaNacimiento")}</label>
                                <div className="col-sm-4">
                                    <DatePicker
                                        name="value-fechaNacimiento"
                                        showMonthDropdown
                                        showYearDropdown
                                        className="border-danger"
                                        selected={state.fechaNacimiento}
                                        onChange={(date) => {
                                            handleChange("fechaNacimiento", {target: {value:date} })
                                        }}/>
                                </div>
                                <div className="col-sm-4">
                                    <input autoComplete="off"
                                        className="btn m-2"
                                        type="checkbox"
                                        checked={state.fechaNacimientoEstimada}
                                        onChange={(val) => {
                                            handleChange("fechaNacimientoEstimada", {target:{value: val.target.checked}})
                                        }} />
                                    <span>{t("postulante.field.fechaNacimientoEstimada")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-genero" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.genero")}</label>
                                <div className="col">
                                    <select
                                        name="value-genero"
                                        value={state.genero}
                                        className="parrish-input"
                                        onChange={(e)=>handleChange("genero", e)}>
                                            <option value=""></option>
                                            <option value={t("common.label.femenino")}>{t("common.label.femenino")}</option>
                                            <option value={t("common.label.masculino")}>{t("common.label.masculino")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-estadoCivil" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.estadoCivil")}</label>
                                <div className="col">
                                    <SelectEntity
                                        entity="EstadoCivil"
                                        label="nombre"
                                        name="value-estadoCivil"
                                        value={state.estadoCivil}
                                        required
                                        onChange={(e) => {
                                            handleChange("estadoCivil", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label className="parrish-label col-sm-4 col-xs-12"></label>
                                <div className="col">

                                    <div className="form-group row">
                                        <div className="col d-flex flex-column align-items-start">
                                            <FlagButtonBoolean
                                                onClass="bg-danger d-inline-block"
                                                offClass="bg-info d-inline-block"
                                                value={state.verificar}
                                                label={t("postulante.field.verificar")}
                                                setValue={(val) => {
                                                    handleChange("verificar", {target:{value: val}})
                                                }}
                                                icon="fas fa-exclamation-triangle" />
                                            <FlagButtonBoolean
                                                onClass="bg-blue"
                                                offClass="bg-info"
                                                value={state.opportunity}
                                                setValue={(val) => {
                                                    handleChange("opportunity", {target:{value: val}})
                                                }}
                                                label={t("postulante.field.oportunidad")}
                                                iconImg="/icons/opportunity_24.png" />
                                            <FlagButtonBoolean
                                                onClass="bg-warning"
                                                offClass="bg-info"
                                                value={state.destacado}
                                                setValue={(val) => {
                                                    handleChange("destacado", {target:{value: val}})
                                                }}
                                                label={t("postulante.field.destacado")}
                                                icon="fas fa-star" />
                                            <FlagButtonBoolean
                                                onClass="bg-teal"
                                                offClass="bg-info"
                                                value={state.incubadora}
                                                setValue={(val) => {
                                                    setState({
                                                        ...state,
                                                        incubadora: val,
                                                        incubadoraProspecto: val?false:state.incubadoraProspecto
                                                    });
                                                }}
                                                label={t("postulante.field.incubadora")}
                                                iconImg="/icons/incubadora_44.png" />
                                            <FlagButtonBoolean
                                                onClass="bg-orange"
                                                offClass="bg-info"
                                                value={state.incubadoraProspecto}
                                                setValue={(val) => {
                                                    setState({
                                                        ...state,
                                                        incubadoraProspecto: val,
                                                        incubadora: val?false:state.incubadora
                                                    });
                                                }}
                                                label={t("postulante.field.incubadoraProspecto")}
                                                iconImg="/icons/incubadora_44.png" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-appraisal" className="parrish-label col-sm-4 col-xs-12 align-self-start">{t("postulante.field.appraisal")}</label>
                                <div className="col">
                                    <textarea
                                        name="value-appraisal"
                                        value={state.appraisal}
                                        onChange={(e)=>handleChange("appraisal", e)}
                                        className="form-control form-control-sm" rows="6"/>   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="parrish-form row">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.label.experienciaLaboral")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="parrish-form row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-empresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.empresa")}</label>
                                <div className="col">
                                    <EmpresaSelector
                                        name="value-empresa"
                                        empresa={state.experienciaLaboralEmpresa}
                                        setEmpresa={(empresa)=>{
                                            handleChange("experienciaLaboralEmpresa", {target:{value:empresa}});
                                        }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-areaEmpresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.areaEmpresa")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="AreaEmpresa"
                                        by="Nombre"
                                        name="value-areaEmpresa"
                                        value={state.experienciaLaboralAreaEmpresa}
                                        required
                                        onChange={(e) => {
                                            handleChange("experienciaLaboralAreaEmpresa", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cargo" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.cargo")}</label>
                                <div className="col">
                                    <SelectEntity
                                        entity="Cargo"
                                        label="nombre"
                                        name="value-cargo"
                                        value={state.experienciaLaboralCargo}
                                        required
                                        onChange={(e) => {
                                            handleChange("experienciaLaboralCargo", e);
                                        }}/> 
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-posicion" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.posicion")}</label>
                                <div className="col">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-posicion'}
                                        className="parrish-input"
                                        value={state.experienciaLaboralPosicion}
                                        maxLength={150}
                                        onChange={(e) => {
                                            handleChange("experienciaLaboralPosicion", e)
                                        }}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.periodo")}</label>
                                <div className="col h-fit-content">
                                    <div className="row">
                                        <DatePicker
                                            name={'value-periodo-inicio'}
                                            selected={state.experienciaLaboralFechaInicio}
                                            onChange={(date) => {
                                                handleChange("experienciaLaboralFechaInicio", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsStart
                                            startDate={state.experienciaLaboralFechaInicio}
                                            endDate={state.experienciaLaboralFechaFin}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker/>
                                        <DatePicker
                                            name={'value-periodo-fin'}
                                            selected={state.experienciaLaboralFechaFin}
                                            onChange={(date) => {
                                                handleChange("experienciaLaboralFechaFin", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsEnd
                                            startDate={state.experienciaLaboralFechaInicio}
                                            endDate={state.experienciaLaboralFechaFin}
                                            minDate={state.experienciaLaboralFechaInicio}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker/>
                                        <div className="col-sm-4 d-flex">
                                            <input autoComplete="off"
                                                className="btn m-2"
                                                type="checkbox"
                                                checked={state.experienciaLaboralFechasEstimadas}
                                                onChange={(val) => {
                                                    handleChange("experienciaLaboralFechasEstimadas", {target:{value: val.target.checked}})
                                                }} />
                                            <span className="text-nowrap">{t("postulante.field.fechasEstimadas")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.pais")}</label>
                                <div className="col">
                                    <SelectKeyValue
                                        entity="Pais"
                                        label="nombre"
                                        name={'value-pais'}
                                        value={state.experienciaLaboralPais}
                                        required
                                        onChange={(e) => {
                                            handleChange("experienciaLaboralPais", e)
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-sueldo-bruto" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.sueldoBrutoMensual")}</label>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-12 d-flex">
                                            <span>
                                                <SelectEntity
                                                    entity="Moneda"
                                                    label="simbolo"
                                                    name="value-moneda"
                                                    className="mr-2"
                                                    value={state.experienciaLaboralMoneda}
                                                    required
                                                    onChange={(e) => {
                                                        handleChange("experienciaLaboralMoneda", e)
                                                    }}/>
                                            </span>
                                            <span>
                                                <NumberFormat
                                                    name="value-sueldo-bruto"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    value={state.experienciaLaboralSueldoBrutoMensual}
                                                    className={"parrish-input"}
                                                    onChange={(e) => {
                                                        const val = e.target?.value;
                                                        if(val){
                                                            handleChange("experienciaLaboralSueldoBrutoMensual", {target:{value:parseInt(val.replaceAll(".","").replaceAll(",","."))}});
                                                        }
                                                    }}
                                                    />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="parrish-form row">

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.resumen")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-resumen"
                                        value={state.experienciaLaboralResumen}
                                        onChange={(e)=>handleChange("experienciaLaboralResumen", e)}
                                        className="form-control form-control-sm" rows="11"/>   
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.bonus")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-bonus"
                                        value={state.experienciaLaboralBonus}
                                        onChange={(e)=>handleChange("experienciaLaboralBonus", e)}
                                        className="form-control form-control-sm" rows="4"/>   
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 text-center">
                                    <h3>{t("postulante.field.beneficios")}</h3>
                                </div>
                                <div className="col-12">
                                    <textarea
                                        name="value-beneficios"
                                        value={state.experienciaLaboralBeneficios}
                                        onChange={(e)=>handleChange("experienciaLaboralBeneficios", e)}
                                        className="form-control form-control-sm pt-0" rows="4"/>   
                                </div>
                            </div>
                        </div>
                    </div>


                    

                </div>
            </div>
        </form>
    )
}

export default PostulanteEdit