import React, { useState, useEffect, useRef } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from '../../../../components/DatePicker';
import $ from 'jquery'
import {} from "jquery-validation";
import PrivateLink from '../../../../components/navigation/PrivateLink';
import CardFooterRecordData from '../../../../components/CardFooterRecordData';
import ContactoAvatarImg from '../../../../components/ContactoAvatarImg';
import AvatarUploadModal from '../../../../components/AvatarUploadModal';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import DeleteEntityButton from '../../../../components/DeleteEntityButton';
import { DateRfc }  from '../../DateRfc';

const CONTACTO_SEARCH_BY_ID = gql`
    query findContactoById(
        $id: Int!
    ) {
    findContactoById(
        id: $id
    ){
        id
        nacionalidad{
            id
            nombre
        }
        pais{
            id
            nombre
        }
        provincia{
            id
            nombre
        }
        labelList{
            id
            nombre
        }
        fechaActualizacion
        fechaNacimiento
        personaFisica
        apellido
        codigoPostal
        domicilio
        email
        facebook
        foto
        linkedin
        localidad
        nombre
        observaciones
        skype
        telefonosFijos
        telefonosMoviles
        twitter
        website
        alias
        bloqueado
    }
}
`;

const UPDATE_CONTACTO_MUTATION = gql`
    mutation updateContacto(
        $id: ID!
        $nacionalidad: Int
        $pais: Int
        $provincia: Int
        $fechaNacimiento: LocalDate
        $personaFisica: Boolean!
        $apellido: String
        $codigoPostal: String
        $contentType: String
        $domicilio: String
        $email: String
        $facebook: String
        $foto: String
        $linkedin: String
        $localidad: String
        $nombre: String!
        $observaciones: String
        $skype: String
        $telefonosFijos: String
        $telefonosMoviles: String
        $twitter: String
        $website: String
    ) {
        updateContacto(entity:{
            id: $id
            nacionalidad: $nacionalidad
            pais: $pais
            provincia: $provincia
            fechaNacimiento: $fechaNacimiento
            personaFisica: $personaFisica
            apellido: $apellido
            codigoPostal: $codigoPostal
            contentType: $contentType
            domicilio: $domicilio
            email: $email
            facebook: $facebook
            foto: $foto
            linkedin: $linkedin
            localidad: $localidad
            nombre: $nombre
            observaciones: $observaciones
            skype: $skype
            telefonosFijos: $telefonosFijos
            telefonosMoviles: $telefonosMoviles
            twitter: $twitter
            website: $website
        }) {
            id
            nacionalidad{
                id
                nombre
            }
            pais{
                id
                nombre
            }
            provincia{
                id
                nombre
            }
            fechaNacimiento
            personaFisica
            alias
            apellido
            codigoPostal
            contentType
            domicilio
            email
            facebook
            foto
            linkedin
            localidad
            nombre
            observaciones
            skype
            telefonosFijos
            telefonosMoviles
            twitter
            website
        }
    }
`;

function ContactoEdit(props) {
    let history = useHistory();
    let { id } = useParams();
    let { showErrorToast, showMessageWithTimerAndExecute } = useAlertMessage();

    const [nacionalidad, setNacionalidad] = useState('');
    const [pais, setPais] = useState('');
    const [provincia, setProvincia] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState();
    const [personaFisica, setPersonaFisica] = useState(true);
    const [apellido, setApellido] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [email, setEmail] = useState('');
    const [facebook, setFacebook] = useState('');
    const [foto, setFoto] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [nombre, setNombre] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [skype, setSkype] = useState('');
    const [telefonosFijos, setTelefonosFijos] = useState('');
    const [telefonosMoviles, setTelefonosMoviles] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(false);
    const ref = useRef();

    const linkClick= (e,url) =>{
        e.preventDefault();
        if(url){
            // Check if the URL starts with "http://" or "https://"
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                // If not, prepend "http://"
                url = "https://" + url;
            }
            
            window.open(url,"_blank");
        }
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const {loading, error, data } = useQuery(
        CONTACTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    useEffect(() => {
        _isMounted.current = true;
        if (!loading && !error){
            const contacto = data?.findContactoById;
            setNacionalidad(contacto?.nacionalidad?.id);
            setPais(contacto?.pais?.id);
            setProvincia(contacto?.provincia?.id);
            if(contacto?.fechaNacimiento){
                setFechaNacimiento(new DateRfc(data.findContactoById.fechaNacimiento));
            }
            setPersonaFisica(contacto?.personaFisica);
            setApellido(contacto?.apellido?contacto?.apellido:'');
            setCodigoPostal(contacto?.codigoPostal?contacto?.codigoPostal:'');
            setDomicilio(contacto?.domicilio?contacto?.domicilio:'');
            setEmail(contacto?.email?contacto?.email:'');
            setFacebook(contacto?.facebook?contacto?.facebook:'');
            setFoto(contacto?.foto?contacto?.foto:'');
            setLinkedin(contacto?.linkedin?contacto?.linkedin:'');
            setLocalidad(contacto?.localidad?contacto?.localidad:'');
            setNombre(contacto?.nombre?contacto?.nombre:'');
            setObservaciones(contacto?.observaciones?contacto?.observaciones:'');
            setSkype(contacto?.skype?contacto?.skype:'');
            setTelefonosFijos(contacto?.telefonosFijos?contacto?.telefonosFijos:'');
            setTelefonosMoviles(contacto?.telefonosMoviles?contacto?.telefonosMoviles:'');
            setTwitter(contacto?.twitter?contacto?.twitter:'');
            setWebsite(contacto?.website?contacto?.website:'');
        }
        return () => { _isMounted.current = false };
    }, [data?.findContactoById, error, loading])

    useEffect(() => {
        if(_isMounted.current){
            let validateJson;
            if(data?.findContactoById?.personaFisica){
                validateJson={
                    rules:{
                        "value-nombre":{
                            required: true
                        },
                        "value-apellido":{
                            required: true
                        },
                        "value-email":{
                            required: true,
                            email: true
                        }
                    },
                    messages: {
                        "value-nombre": {
                            required: t("common.message.field.required", {0: t("contacto.field.nombre")})
                        },
                        "value-apellido": {
                            required: t("common.message.field.required", {0: t("contacto.field.apellido")})
                        },
                        "value-email": {
                            required: t("common.message.field.required", {0: t("contacto.field.email")}),
                            email: t("common.message.invalid.email.format")
                        }
                    },
                    errorElement: 'span',
                    errorClass: "text-danger text-sm",
                    onkeyup: false,
                    onclick: false,
                    onfocusout: false,
                    highlight: function (element, errorClass, validClass) {
                        $(element).addClass('is-invalid');
                    },
                    unhighlight: function (element, errorClass, validClass) {
                        $(element).removeClass('is-invalid');
                    },
                    showErrors: function(errorMap, errorList) {
                        showErrorToast(errorList);
                    }
                }
            }else{
                validateJson={
                    rules:{
                        "value-nombre":{
                            required: true
                        }
                    },
                    messages: {
                        "value-nombre": {
                            required: t("common.message.field.required", {0: t("contacto.field.nombre")})
                        }
                    },
                    errorElement: 'span',
                    errorClass: "text-danger text-sm",
                    onkeyup: false,
                    onclick: false,
                    onfocusout: false,
                    highlight: function (element, errorClass, validClass) {
                        $(element).addClass('is-invalid');
                    },
                    unhighlight: function (element, errorClass, validClass) {
                        $(element).removeClass('is-invalid');
                    },
                    showErrors: function(errorMap, errorList) {
                        showErrorToast(errorList);
                    }
                }
            }
            $(ref.current).unbind('validate');
            //$(ref.current).data('validator', null);
            $(ref.current).validate(validateJson);
        }
    }, [data?.findContactoById?.personaFisica, showErrorToast, t])

    const [updateContactoMutation] = useMutation(UPDATE_CONTACTO_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1500, ()=>{});
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateContactoMutation({
            variables:{
                id: parseInt(id),
                nacionalidad: parseInt(nacionalidad),
                pais: parseInt(pais),
                provincia: parseInt(provincia),
                fechaNacimiento: fechaNacimiento,
                personaFisica: personaFisica,
                apellido: apellido,
                codigoPostal: codigoPostal,
                domicilio: domicilio,
                email: email,
                facebook: facebook,
                foto: foto,
                linkedin: linkedin,
                localidad: localidad,
                nombre: nombre,
                observaciones: observaciones,
                skype: skype,
                telefonosFijos: telefonosFijos,
                telefonosMoviles: telefonosMoviles,
                twitter: twitter,
                website: website
           }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <div className="container-fluid">
                <div className="parrish-form row">
                    <div className="col-md-2 pl-0 d-flex align-items-stretch">
                        <div className="card card-info card-outline w-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        {!data?.findContactoById?.bloqueado?
                                            <AvatarUploadModal imgClassName="profile-user-img img-fluid img-circle border-info float-right shadow rounded-parrish-img" personaFisica={personaFisica} entity="Contacto" foto={data?.findContactoById?.foto} contactoId={data?.findContactoById?.id} id={data?.findContactoById?.id}/>
                                            :
                                            <ContactoAvatarImg id={data?.findContactoById?.id} foto={data?.findContactoById?.foto} personaFisica={personaFisica} className="profile-user-img img-fluid img-circle border-info float-right shadow rounded-parrish-img m-4" alt="User profile picture"/>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center text-center">
                                        <h4>{data?.findContactoById?.alias}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 pr-0 d-flex flex-column align-items-stretch">
                        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                            <div className="card card-outline card-info w-100">
                                {/*
                                    loading &&
                                    !error && (
                                        <div className="overlay">
                                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                        </div>
                                    )
                                */}
                                <div className="card-header">
                                    <h3 className="card-title">
                                        {t("common.label.detalle")} {t("common.label.id", {0: id})}
                                        {
                                            data?.findContactoById?.labelList?.map((element, index) => 
                                            {
                                                return(<span key={index} className={'badge ml-2 label-color-'+element.id}>{element.nombre}</span>)
                                            })
                                        }
                                    </h3>
                                    <div className="card-tools">
                                        {!data?.findContactoById?.bloqueado&&
                                            <>
                                                <button type="submit" className="btn btn-xs bg-info btn-tool">
                                                    <i className="fas fa-save"></i>
                                                </button>
                                                <DeleteEntityButton className="ml-2" entity="Contacto" id={id} label={data?.findContactoById?.alias} onCompleted={()=>{
                                                    history.push("/private/agenda-contactos")
                                                }} />
                                            </>
                                        }
                                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to="/agenda-contactos">
                                            <i className="fas fa-times"></i>
                                        </PrivateLink>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="parrish-form row">
                                        <div className="col-sm">
                                            <>
                                                <div className="form-group row">
                                                    <label htmlFor="value-nombre" className="parrish-label col-3 text-right required">{t("Nombre")}</label>
                                                    <div className="col-8">
                                                    <input autoComplete="off"
                                                        type="text"
                                                        name={'value-nombre'}
                                                        className="parrish-input"
                                                        value={nombre}
                                                        maxLength={150}
                                                        onChange={(e) => setNombre(e.target.value)}/>
                                                    </div>
                                                </div>
                                                {data?.findContactoById?.personaFisica&&
                                                    <>
                                                        <div className="form-group row">
                                                            <label htmlFor="value-apellido" className="parrish-label col-3 text-right required">{t("Apellido")}</label>
                                                            <div className="col-8">
                                                            <input autoComplete="off"
                                                                type="text"
                                                                name={'value-apellido'}
                                                                className="parrish-input"
                                                                value={apellido}
                                                                maxLength={150}
                                                                onChange={(e) => setApellido(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="value-fecha-nacimiento" className="parrish-label col-3">{t("F. Nacimiento")}</label>
                                                            <div className="col-8">
                                                                <DatePicker
                                                                    name="value-fecha-nacimiento"
                                                                    selected={fechaNacimiento}
                                                                    onChange={date => setFechaNacimiento(date)}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            
                                            <div className="form-group row">
                                                <label htmlFor="value-apellido" className="parrish-label col-3">{t("Nacionalidad")}</label>
                                                <div className="col-8">
                                                <SelectKeyValue
                                                    entity="Pais"
                                                    label="nombre"
                                                    name={'value-nacionalidad'}
                                                    value={nacionalidad}
                                                    required
                                                    onChange={(e) => {
                                                        setNacionalidad(e.target.value);
                                                    }}/>
                                                </div>
                                            </div>
                                            <h5 className="text-center mt-4">REDES SOCIALES</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-linkedin" className="parrish-label col-3">{t("LinkedIn")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,linkedin)}}>
                                                            <span className="input-group-text">
                                                                <i className="fab fa-linkedin-in"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-linkedin'}
                                                            className="form-control"
                                                            value={linkedin}
                                                            maxLength={150}
                                                            onChange={(e) => setLinkedin(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-website" className="parrish-label col-3">{t("Website")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,website)}}>
                                                            <span className="input-group-text">
                                                                <i className="fas fa-globe-americas"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-website'}
                                                            className="parrish-input"
                                                            value={website}
                                                            maxLength={150}
                                                            onChange={(e) => setWebsite(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-skype" className="parrish-label col-3">{t("Skype")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-skype"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-skype'}
                                                            className="form-control"
                                                            value={skype}
                                                            maxLength={150}
                                                            onChange={(e) => setSkype(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-twitter" className="parrish-label col-3">{t("Twitter")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-twitter"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-twitter'}
                                                            className="form-control"
                                                            value={twitter}
                                                            maxLength={150}
                                                            onChange={(e) => setTwitter(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-facebook" className="parrish-label col-3">{t("Facebook")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,facebook)}}>
                                                            <span className="input-group-text">
                                                                <i className="fab fa-facebook-f"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-facebook'}
                                                            className="form-control"
                                                            value={facebook}
                                                            maxLength={150}
                                                            onChange={(e) => setFacebook(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <h5 className="text-center">DATOS DE CONTACTO</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-email" className={"parrish-label col-3 text-right"+(personaFisica?" required":"")}>{t("Email")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{emailClick(e,email)}}>
                                                            <span className="input-group-text">
                                                                <i className="fas fa-envelope"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-email'}
                                                            className="parrish-input"
                                                            value={email}
                                                            maxLength={150}
                                                            onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-telefono" className="parrish-label col-3">{t("Tel. Fijos")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fas fa-phone"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-telefono'}
                                                            className="form-control"
                                                            value={telefonosFijos}
                                                            maxLength={150}
                                                            onChange={(e) => setTelefonosFijos(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-celular" className="parrish-label col-3">{t("Tel. Móviles")}</label>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend cursor-pointer" onClick={(e)=>{whatsappClick(e,telefonosMoviles)}}>
                                                            <span className="input-group-text">
                                                                <i className="fab fa-whatsapp"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-celular'}
                                                            className="form-control"
                                                            value={telefonosMoviles}
                                                            maxLength={150}
                                                            onChange={(e) => setTelefonosMoviles(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <h5 className="text-center mt-4">DOMICILIO</h5>
                                            <div className="form-group row">
                                                <label htmlFor="value-domicilio" className="parrish-label col-3">{t("Domicilio")}</label>
                                                <div className="col-8">
                                                <input autoComplete="off"
                                                    type="text"
                                                    name={'value-domicilio'}
                                                    className="parrish-input"
                                                    value={domicilio}
                                                    maxLength={150}
                                                    onChange={(e) => setDomicilio(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-localidad" className="parrish-label col-3">{t("Localidad")}</label>
                                                <div className="col-8">
                                                <input autoComplete="off"
                                                    type="text"
                                                    name={'value-localidad'}
                                                    className="parrish-input"
                                                    value={localidad}
                                                    maxLength={150}
                                                    onChange={(e) => setLocalidad(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-pais" className="parrish-label col-3">{t("País")}</label>
                                                <div className="col-8">
                                                <SelectKeyValue
                                                    entity="Pais"
                                                    label="nombre"
                                                    name={'value-pais'}
                                                    value={pais}
                                                    required
                                                    onChange={(e) => {
                                                        setPais(e.target.value);
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-provincia" className="parrish-label col-3">{t("Provincia")}</label>
                                                <div className="col-8">
                                                <SelectKeyValue
                                                    entity="Provincia"
                                                    by="PaisNombre"
                                                    label="nombre"
                                                    name={'value-provincia'}
                                                    value={provincia}
                                                    filterValue={pais}
                                                    filterName="idPais"
                                                    onChange={(e) => {
                                                        setProvincia(e.target.value);
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-cp" className="parrish-label col-3">{t("Código Postal")}</label>
                                                <div className="col-8">
                                                <input autoComplete="off"
                                                    type="text"
                                                    name={'value-cp'}
                                                    className="parrish-input"
                                                    value={codigoPostal}
                                                    maxLength={150}
                                                    onChange={(e) => setCodigoPostal(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {data?.findContactoById?.responsable?.contacto.alias}
                                <CardFooterRecordData record={data?.findContactoById}/>
                            </div>
                        </form>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default ContactoEdit