import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const INDUSTRIA_SEARCH_BY_ID = gql`
    query findIndustriaById(
        $id: Int!
    ) {
    findIndustriaById(
        id: $id
    ){
        id
        nombre
    }
}
`;

const UPDATE_INDUSTRIA_MUTATION = gql`
    mutation updateIndustria(
        $id: ID!
        $nombre: String!
    ) {
        updateIndustria(entity:{ id: $id, nombre: $nombre }) {
            id
            nombre
        }
    }
`;

const DELETE_INDUSTRIA_MUTATION = gql`
    mutation deleteIndustria(
        $id: Int!
    ) {
        deleteIndustria(id: $id)
    }
`;

function IndustriaEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { /*showConfirmDeleteWithPin, */showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();
    const [nombre, setNombre] = useState('');
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        INDUSTRIA_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findIndustriaById?.nombre);
        }
        return () => { _isMounted.current = true };
    }, [loading, error, data?.findIndustriaById?.nombre])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateIndustriaMutation] = useMutation(UPDATE_INDUSTRIA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/industria";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteIndustriaMutation] = useMutation(DELETE_INDUSTRIA_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/Industria");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateIndustriaMutation({
            variables:{
                id: id,
                nombre: nombre 
            }
        })
    }

    const deleteAction = (id, label) => {
        /*showConfirmDeleteWithPin(
            id,
            (id)=>{deleteIndustriaMutation({
                variables:{
                    id: id
                }
            })}
        );*/

        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteIndustriaMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="industria"
                handleSubmit={handleSubmit}
                values={[
                    {  
                        name: "nombre",
                        type: "text",
                        maxLength: 100,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("industria.field.nombre")}),
                        }
                    }
                ]}
                returnUrl="/Industria"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default IndustriaEdit