import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import Datatable from '../../../../../../components/Datatable';
import AuthContext from '../../../../../../context/AuthContext';

const FIND_FORMACION_ACADEMICA_BY_POSTULANTE = gql`
    query findFormacionAcademicaPageByPostulante(
        $idPostulante: Int!
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findFormacionAcademicaPageByPostulante(
            idPostulante: $idPostulante
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    fechaInicio
                    fechaFin
                    areaEstudio{
                        nombre
                    }
                    institucion{
                        nombre
                    }
                    nivelEstudio{
                        nombre
                    }
                    pais{
                        nombre
                    }
                    tituloObtenido
                    promedio
                }
            } 
        }
    }
`;

export default function FormacionAcademicasList(props) {
    const { setMode, setEditId } = props;
    const [t] = useTranslation("global");
    const {id} = useParams();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaInicio",
        sortDirection: "DESC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(id){
            setPageNumber(0)
        }
    }, [id]);
    
    const { loading, error, data } = useQuery(
        FIND_FORMACION_ACADEMICA_BY_POSTULANTE,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                idPostulante: id,
            }
        }
    );

    return (

        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header pr-0 pl-0">
                <h3 className="card-title">{t("common.label.listado")}</h3>
                <div className="card-tools m-0">
                    <button
                        className="btn btn-xs btn-info bg-info btn-tool ml-2"
                        title={t("common.button.agregar")}
                        onClick={()=>{setMode("create")}}>
                            <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="card-body pr-0 pl-0">
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findFormacionAcademicaPageByPostulante"
                    entityName="formacionAcademica"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    colSpanSkipDisabled={true}
                    onEdit={(element)=>{
                        setEditId(element.node.id);
                        setMode("edit");
                    }}
                    columnsDefinition={[
                        {
                            name: "id",
                            type: "id",
                            className: "text-center"
                        },
                        {
                            name: "periodo",
                            translationKey: "postulante.field.periodo",
                            colSpan: 2,
                            type: "text",
                            customSortKey: "fechaInicio",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaInicio &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaInicio}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "periodoFin",
                            translationKey: "postulante.field.periodo",
                            type: "text",
                            customSortKey: "fechaFin",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaFin &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaFin}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "nivelEstudio.nombre",
                            customSortKey: "nivelEstudio.nombre",
                            translationKey: "formacionAcademica.field.nivelEstudio",
                            type: "text",
                        },
                        {
                            name: "institucion.nombre",
                            customSortKey: "institucion.nombre",
                            translationKey: "formacionAcademica.field.institucion",
                            type: "text",
                        },
                        {
                            name: "areaEstudio.nombre",
                            customSortKey: "areaEstudio.nombre",
                            translationKey: "formacionAcademica.field.areaEstudio",
                            type: "text",
                        },
                        {
                            name: "pais.nombre",
                            customSortKey: "pais.nombre",
                            translationKey: "formacionAcademica.field.pais",
                            type: "text",
                        },
                        {
                            name: "tituloObtenido",
                            type: "text",
                        },
                        {
                            name: "promedio",
                            type: "text",
                        }
                    ]}
                />
            </div>
        </div>        
    )
}
