import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import SelectEntity from '../../../../../components/SelectEntity';
import DatePicker from '../../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import Autocomplete from '../../../../../components/Autocomplete';
import SelectKeyValue from '../../../../../components/SelectKeyValue';
import { DateRfc }  from '../../../DateRfc';

const AGREGAR_DOUBLE_PLACEMENT_AL_PROYECTO = gql`
    mutation agregarDoublePlacementAlProyecto(
        $idProyecto: Int!
        $idPostulante: Int!
        $idResponsable: Int!
        $fechaOriginacion: LocalDate!
        $areaEmpresa: Int!
        $cargo: Int!
        $pais: Int!
        $fechaInicio: LocalDate!
        $moneda: Int!
        $sueldoBrutoMensual: Int!
        $beneficios: String
        $bonus: String
        $posicion: String
    ) {
        agregarDoublePlacementAlProyecto(
            placement:{
                idProyecto: $idProyecto
                idPostulante: $idPostulante
                idResponsable: $idResponsable
                fechaOriginacion: $fechaOriginacion
                areaEmpresa: $areaEmpresa
                cargo: $cargo
                pais: $pais
                fechaInicio: $fechaInicio
                moneda: $moneda
                sueldoBrutoMensual: $sueldoBrutoMensual
                beneficios: $beneficios
                bonus: $bonus
                posicion: $posicion
            }
        ) {
            id
        }
    }
`;

export default function DoublePlacementDialog(props) {
    const { proyecto, refetch } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const [show, setShow] = useState(false);
    const { showErrorToast, clearPopUps } = useAlertMessage();
    const [state, setState] = useState({
        idProyecto: "",
        idPostulante: "",
        idResponsable: "",
        fechaOriginacion: new DateRfc(),
        areaEmpresa: "",
        cargo: "",
        pais: "",
        fechaInicio: "",
        moneda: parseInt(proyecto?.acuerdoInicial?.moneda?.id),
        sueldoBrutoMensual: "",
        beneficios: "",
        bonus: "",
        posicion: ""
    });

    const [createDoublePlacementMutation] = useMutation(AGREGAR_DOUBLE_PLACEMENT_AL_PROYECTO,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!state.idPostulante){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.postulante")})});
        }
        if(!state.idResponsable){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.responsable")})});
        }
        if(!state.fechaOriginacion){
            errorList.push({message: t("common.message.field.required", {0: t("acuerdoComercial.field.fechaOriginacion")})});
        }
        if(!state.cargo){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.cargo")})});
        }
        if(!state.pais){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.pais")})});
        }
        if(!state.moneda){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.moneda")})});
        }
        if(!state.sueldoBrutoMensual){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.sueldoBrutoMensual")})});
        }
        if(!state.areaEmpresa){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.areaEmpresa")})});
        }
        if(!state.fechaInicio){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.fechaInicio")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const setStateValue = (name, value) =>{
        setState({
            ...state,
            [name]: value
        });
    }

    const handleSave = () =>{
        if(validate()){
            createDoublePlacementMutation({
                variables:{
                    idProyecto: parseInt(proyecto.id),
                    idPostulante: state.idPostulante?parseInt(state.idPostulante):null,
                    idResponsable: state.idResponsable?parseInt(state.idResponsable):null,
                    fechaOriginacion: state.fechaOriginacion?new DateRfc(state.fechaOriginacion):null,
                    moneda: state.moneda?parseInt(state.moneda):null,
                    areaEmpresa: state.areaEmpresa?parseInt(state.areaEmpresa):null,
                    cargo: state.cargo?parseInt(state.cargo):null,
                    pais: state.pais?parseInt(state.pais):null,
                    fechaInicio: state.fechaInicio?state.fechaInicio:null,
                    sueldoBrutoMensual: state.sueldoBrutoMensual?state.sueldoBrutoMensual:null,
                    beneficios: state.beneficios?state.beneficios:null,
                    bonus: state.bonus?state.bonus:null,
                    posicion: state.posicion?state.posicion:null,
                    tipoPlacement: state.tipoPlacement?parseInt(state.tipoPlacement):null,
                }
            });
        }
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            setState({
                idProyecto: parseInt(proyecto?.id),
                idPostulante: "",
                idResponsable: "",
                fechaOriginacion: new DateRfc(),
                areaEmpresa: "",
                cargo: "",
                pais: "",
                fechaInicio: "",
                moneda: parseInt(proyecto?.acuerdoInicial?.moneda?.id),
                sueldoBrutoMensual: "",
                beneficios: "",
                bonus: "",
                posicion: ""
            });
        }
    }, [proyecto?.acuerdoInicial?.moneda?.id, proyecto?.id, show]);

    return (
        <>
            <button type="button" className="btn btn-xs bg-info btn-tool ml-2 text-nowrap" title={t("proyecto.button.doublePlacement")} onClick={()=>setShow(true)}>
                {t("proyecto.button.doublePlacement")}
            </button>
            <div ref={ref} className="modal" role="dialog">
            {show&&
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("proyecto.label.nuevoDoublePlacement")}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-fechaOriginacion" className="parrish-label col-sm-4 col-xs-12 required">{t("acuerdoComercial.field.fechaOriginacion")}</label>
                                        <div className="col">
                                            <DatePicker
                                                name="value-fechaOriginacion"
                                                selected={state.fechaOriginacion}
                                                onChange={date => setStateValue("fechaOriginacion", date)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-postulante" className="parrish-label col-sm-4 col-xs-12 required">{t("experienciaLaboral.field.postulante")}</label>
                                        <div className="col">
                                            <Autocomplete
                                                name="value-postulante"
                                                entity="Postulante"
                                                by="Alias"
                                                filterName="alias"
                                                value={state.idPostulante}
                                                setValue={(val)=>{setStateValue("idPostulante", val)}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-responsable" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.responsable")}</label>
                                        <div className="col">
                                            <SelectEntity
                                                queryCompleteName="findUsuarioActivoKeyValueListByAlias"
                                                label="label"
                                                name="value-responsable"
                                                value={state.idResponsable}
                                                sortDisable={true}
                                                onChange={(e) => {
                                                    setStateValue("idResponsable", e.target.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="parrish-form row mt-4 mb-1">
                                <div className="col-12">
                                    {t("acuerdoComercial.label.datosSobreExpLabolarPlacement")}
                                </div>
                            </div>
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-areaEmpresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.areaEmpresa")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="AreaEmpresa"
                                                by="Nombre"
                                                name="value-areaEmpresa"
                                                value={state.areaEmpresa}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("areaEmpresa", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-cargo" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.cargo")}</label>
                                        <div className="col">
                                            <SelectEntity
                                                entity="Cargo"
                                                label="nombre"
                                                name="value-cargo"
                                                value={state.cargo}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("cargo", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-posicion" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.posicion")}</label>
                                        <div className="col">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-posicion'}
                                                className="parrish-input"
                                                value={state.posicion}
                                                maxLength={150}
                                                onChange={(e) => {
                                                    setStateValue("posicion", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.pais")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="Pais"
                                                label="nombre"
                                                name={'value-pais'}
                                                value={state.pais}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("pais", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.fechaInicio")}</label>
                                            <div className="col-8">
                                                <DatePicker
                                                    name={'value-periodo-inicio'}
                                                    selected={state.fechaInicio}
                                                    onChange={(date) => {
                                                        setStateValue("fechaInicio", date)
                                                    }}
                                                    selectsStart
                                                    startDate={state.fechaInicio}
                                                    endDate={state.fechaFin} />
                                            </div>
                                    </div>
                                </div>
                                
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-sueldo-bruto" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.sueldoBrutoMensual")}</label>
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="row">
                                                <div className="col-5">
                                                    <SelectEntity
                                                        entity="Moneda"
                                                        label="simbolo"
                                                        name="value-moneda"
                                                        value={state.moneda}
                                                        required
                                                        disabled
                                                        onChange={(e) => {
                                                            setStateValue("moneda", e?.target?.value);
                                                        }}/>
                                                </div>

                                                <div className="col-7">
                                                    <NumberFormat
                                                        name="value-sueldo-bruto"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        value={state.sueldoBrutoMensual}
                                                        className={"parrish-input"}
                                                        onChange={(e) => {
                                                            const val = e.target?.value;
                                                            if(val){
                                                                setStateValue("sueldoBrutoMensual", parseInt(val.replaceAll(".","").replaceAll(",",".")));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-beneficios" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.beneficios")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-beneficios" value={state.beneficios} onChange={(e)=>{setStateValue("beneficios", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-bonus" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.bonus")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-bonus" value={state.bonus} onChange={(e)=>{setStateValue("bonus", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                {t("proyecto.button.guardarPlacement")}
                            </button>
                        </div>
                    </div>
                </div>
            }
            </div>
        </>
    )
    
}
