import React, { useEffect, useRef } from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import CustomSelect from './CustomSelect';

const SELECT_LIST = gql`
    query findSeguimientoAccionListByFilter($deSistema: Boolean) {
        findSeguimientoAccionListByFilter(
        filter:{
            deSistema: $deSistema
        }
        sortCriteria: {
            sortColumn: "nombre",
            sortDirection: "ASC"
        }
    ){
        id
        nombre
        icon
        color
        deSistema
    }
}`;

export default function AccionesSeguimiendoSelect(props) {
    const {setValue, value, all} = props;
    const [ loadData , { loading, error, data}] = useLazyQuery(SELECT_LIST);
    const componentIsMounted = useRef(true)
    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, [])

    useEffect(() => {
        if(componentIsMounted.current){
            loadData({ variables: {
                deSistema: all?null:false
            }});
        }
    }, [all, loadData])
    
    const formatOptionLabel = ({ value, label, seguimiento}) => (
        <div className="d-flex align-items-center">
            <span>
                <div className="rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: seguimiento.color}}>
                    <h6 className="align-self-center m-0">
                        <i className={seguimiento.icon+" text-white"} />
                    </h6>
                </div>
            </span>
            <span className="pl-2">{seguimiento.nombre}</span>
        </div>
    );

    const options = data?.findSeguimientoAccionListByFilter?.map((seguimiento)=>{
        return({ value: seguimiento.id, label: seguimiento.nombre, seguimiento: seguimiento });
    });
    const defaultValue = options?.filter((e)=>e.value === value)[0];

    return (!loading && !error && data ?
        <CustomSelect
            isClearable={true}
            value={defaultValue}
            formatOptionLabel={formatOptionLabel}
            options={options}
            onChange={(accionSelected)=>{
                setValue(accionSelected?.value);
            }}
        />
        :
        <></>
    )
}
