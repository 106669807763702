import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from "react-i18next"
import Datatable from '../../../../components/Datatable';
import "popper.js/dist/popper";
import EstadoMovimiento from '../estados/EstadoMovimiento';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const CTA_CTE_MOVIMIENTO_SEARCH = gql`
    query findCtaCteMovimientoPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $cliente:Int
        $proyecto:Int
    )  {
        findCtaCteMovimientoPageByFilter(
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageSize: $pageSize
                pageNumber: $pageNumber
            }
            filter:{
                cliente: $cliente
                proyecto: $proyecto
            }
        ) {
            queryInfo {
                totalElements
                totalPages
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    adjunto
                    estado {
                        id
                        color
                        nombre
                    }
                    fechaAcreditacion
                    fechaRegistro
                    registradoPor {
                        contacto {
                            alias
                        }
                    }
                    fechaConciliacion
                    conciliadoPor {
                        contacto {
                            alias
                        }
                    }
                    fechaCancelacion
                    canceladoPor {
                        contacto {
                            alias
                        }
                    }
                    fechaCobranza
                    cobradoPor {
                        contacto {
                            alias
                        }
                    }
                    cliente {
                        id
                        contacto{
                            alias
                        }
                    }
                    moneda {
                        id
                        simbolo
                    }
                    formaPago{
                        nombre
                    }
                    retencion{
                        nombre
                    }
                    cuenta{
                        nombre
                        banco{
                            nombre
                        }
                        tipoCuenta{
                            nombre
                        }
                        moneda{
                            simbolo
                        }
                    }
                    impacto
                    importe
                    cotizacionPeso
                    detalle
                    detalleCbtesAsociados
                    chequeBanco{
                        nombre
                    }
                    chequeFecha
                    chequeNro
                    chequeTitular
                }
            }
        }
    }
`;

function MovimientoList(props){
    const [t] = useTranslation("global");
    const {searchFilter, monedaLabel} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "fechaAcreditacion",
        sortDirection: "DESC"
    });

    //const { appGetUserData } = useContext(AppStateContext);
    //const userData = appGetUserData();

    const pageSizeDefaultParam = 10;//userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        CTA_CTE_MOVIMIENTO_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                cliente: searchFilter.cliente?parseInt(searchFilter.cliente):null,
                proyecto: searchFilter.proyecto?parseInt(searchFilter.proyecto):null
            }
        }
    );

    const totalImporte  = data?data["findCtaCteMovimientoPageByFilter"]?.edges.reduce((total, currVal) => total + currVal.node.importe*currVal.node.cotizacionPeso, 0):0;

    return (
        <Datatable
            data={data}
            loading={loading}
            error={error}
            sortOptions={sortOptions}
            dataWrapper="findCtaCteMovimientoPageByFilter"
            entityName="movimiento"
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            setSortOptions={setSortOptions}
            pageSize={pageSize}
            pageNumber={pageNumber}
            editUrl="/private/movimiento/edit/"
            headerBottom={
                <tr>
                    <th className="bg-white pr-2">
                        <div>{monedaLabel}</div>
                    </th>
                    <th className="text-right  bg-white pr-2">
                        <div>
                            <CurrencyFormat value={totalImporte}/>
                        </div>
                    </th>
                </tr>
            }
            headerTop={
                <tr>
                    <th className="bg-info text-left" colSpan="11">
                        <div>{t("movimientosHistoricos.tableTitle")}</div>
                    </th>
                </tr>
            }
            columnsDefinition={[
                {
                    name: "id",
                    type: "id",
                    rowSpan: 2
                },
                {
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-center pl-0 pr-0",
                    className:"text-center",
                    disableSort: true,
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.node.adjunto} entity="Movimiento"/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoMovimiento element={element}/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "cliente",
                    render: (element)=>{
                        return(<PrivateLink className="parrish-link" to={"/empresa/edit/"+element.node.cliente.id}>{element.node.cliente.contacto.alias}</PrivateLink>)
                    },
                    rowSpan: 2
                },
                {
                    name: "fechaAcreditacion",
                    type: "date",
                    rowSpan: 2
                },
                {
                    name: "formaPago",
                    render: (element)=>{
                        return(<>
                            <p>{element.node.formaPago.nombre}</p>
                            {element.node?.retencion&&
                                <p className="text-bold">[{element.node.retencion.nombre}]</p>
                            }
                            {element.node?.chequeNro&&
                                <p className="text-bold">[{element.node.chequeNro}]</p>
                            }
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "cuenta",
                    render: (element)=>{
                        return(<>
                            {
                                element.node.cuenta &&
                                    element.node.cuenta?.nombre+" - ["+element.node.cuenta?.moneda?.simbolo+"]"
                            }
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "detalle",
                    disableSort: true,
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.node.detalleCbtesAsociados}</p>
                            <p>{element.node.detalle?"("+element.node.detalle+")":""}</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "moneda",
                    className:"text-align-center",
                    customHeader: (<></>),
                    disableSort: true,
                    render: (element)=>{
                        return(element.node.moneda?.simbolo)
                    }
                },
                {  
                    name: "importe",
                    className:"text-right",
                    type: "money",
                    render: (element)=>{
                        const importe = element.node.importe*element.node.impacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importe} />
                        )
                    }
                }
            ]}>
        </Datatable>
    );
}

export default MovimientoList;