import React, { useContext, useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import ConfigData from "../config.json"
import ProfilerContext from '../context/ProfilerContext';
import packageJson from "../../package.json"

const SERVER_HANDSHAKE = gql`
    query serverHandshake{
        serverHandshake{
            appName
            appVersion
        }
    }
`;

export default function Footer() {
    const { operationsStack, time } = useContext(ProfilerContext);
    const [mostHeavyOp, setMostHeavyOp] = useState();

    const [serverInfo, setServerInfo] = useState(null);

    const [serverHandshake] = useLazyQuery(SERVER_HANDSHAKE, {
        onCompleted: (data) => {
            setServerInfo(data?.serverHandshake);
        },
        onError: (error) => {
            setServerInfo({
                appName: "",
                appVersion: ""
            })
        }
    });

    useEffect(() => {
        if(!serverInfo){
            serverHandshake();
        }
    }, [serverHandshake, serverInfo])
    
    useEffect(() => {
        let mostHeavyOperation = operationsStack[0];
        for (const operation of operationsStack) {
            if(mostHeavyOperation.time<operation.time){
                mostHeavyOperation = operation;
            }
        }
        setMostHeavyOp(mostHeavyOperation);
        //console.log("ops", getOperationsStack());
    }, [operationsStack]);
    
    const greenOperationDuration = 1;
    const yellowOperationDuration = 2.5;

    return (
        <footer className="main-footer text-sm">
            <strong>{ConfigData.APP_CLIENT_NAME}</strong> is powered by <a className="parrish-link-light" href="https://www.parrish.com.ar">Parrish Software Factory</a>.
            <div className="float-right d-none d-sm-inline-block">
                {time&&
                    <span className={"mr-2 badge "+(time/1000<greenOperationDuration?"badge-success":time/1000<yellowOperationDuration?"badge-warning":"badge-danger")}>{time/1000} segs.</span>
                }
                {mostHeavyOp&&
                    <span className="dropdown" role="button">
                        <span id="dropdownProfileButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={"mr-2 badge badge-info"}><i className="fas fa-tasks"></i></span>
                        <div className="dropdown-menu p-2" aria-labelledby="dropdownProfileButton">
                            {operationsStack?.reverse().map((operation, index)=>{
                                return(<div className="d-flex justify-content-between" key={(operation.operationName?operation.operationName:"S/N")+"-"+index}>
                                    <span className={"small m-0 "+(operation.operationName?"":"text-danger")}>{operation.operationName?operation.operationName:"S/N"}</span>
                                    <span className="border-bottom flex-grow-1 pl-4 pr-4"></span>
                                    <span className={"mb-1 p-small small badge "+(operation.time/1000<greenOperationDuration?"badge-success":operation.time/1000<yellowOperationDuration?"badge-warning":"badge-danger")}>{operation.time/1000} segs.</span>
                                </div>);
                            })}
                        </div>
                    </span>

                }
                <span className="mr-2"><b>app-version: </b><small>v{packageJson?.version}</small></span>
                <span><b>api-version: </b><small>v{serverInfo?.appVersion}</small></span>
            </div>
        </footer>
    )
}
