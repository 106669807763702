import React from 'react'

export default function Blockquote(props) {
    const text = props.text;
    if(text){
        const newText = text.split('\n').map(str => <p>{str}</p>);
        return newText;
    }else{
        return(<></>);
    }
}
