import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Datatable from '../../../../../../components/Datatable';
import AuthContext from '../../../../../../context/AuthContext';

const FIND_EXPERIENCIA_LABORAL_BY_POSTULANTE = gql`
    query findConocimientoPostulantePageByFilter(
        $postulante: Int
        $sortColumn: String
        $sortDirection: String
        $pageNumber: Int!
        $pageSize: Int
    ) {
        findConocimientoPostulantePageByFilter(
            filter:{
                postulante: $postulante
            }
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageNumber: $pageNumber
                pageSize: $pageSize
            }
        ){
            queryInfo{
                totalElements
                totalPages
            }
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            edges{
                node{
                    id
                    conocimiento{
                        id
                        nombre
                        tipoConocimiento{
                            id
                            nombre
                        }
                    }
                    nivel{
                        id
                        nombre
                    }
                }
            }  
        }
    }
`;

export default function ConocimientoPostulantesList(props) {
    const { setMode, setEditId } = props;
    const [t] = useTranslation("global");
    const {id} = useParams();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "conocimiento.nombre",
        sortDirection: "ASC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(id){
            setPageNumber(0)
        }
    }, [id]);

    const { loading, error, data } = useQuery(
        FIND_EXPERIENCIA_LABORAL_BY_POSTULANTE,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                postulante: id,
            }
        }
    );

    return (

        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header pr-0 pl-0">
                <h3 className="card-title">{t("common.label.listado")}</h3>
                <div className="card-tools m-0">
                    <button
                        className="btn btn-xs btn-info bg-info btn-tool ml-2"
                        title={t("common.button.agregar")}
                        onClick={()=>{setMode("create")}}>
                            <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="card-body pr-0 pl-0">
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findConocimientoPostulantePageByFilter"
                    entityName="experienciaLaboral"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onEdit={(element)=>{
                        setEditId(element.node.id);
                        setMode("edit");
                    }}
                    columnsDefinition={[
                        {
                            name: "id",
                            type: "id",
                            className: "text-center"
                        },
                        {
                            name: "conocimiento.tipoConocimiento.nombre",
                            customSortKey: "conocimiento.tipoConocimiento.nombre",
                            type: "text",
                            translationKey:"conocimiento.field.tipo"
                        },
                        {
                            name: "conocimiento.nombre",
                            customSortKey: "conocimiento.nombre",
                            type: "text",
                            translationKey:"conocimiento.field.conocimiento"
                        },
                        {
                            name: "nivel.nombre",
                            customSortKey: "nivel.nombre",
                            type: "text",
                            translationKey:"conocimiento.field.nivel"
                        }
                    ]}
            />
            </div>
        </div>        
    )
}
