import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const CUENTA_SEARCH_BY_ID = gql`
    query findCuentaById(
        $id: Int!
    ) {
    findCuentaById(
        id: $id
    ){
        id
        nombre
        fechaAlta
        moneda{
            id
            simbolo
        }
        titular
        banco{
            id
            nombre
        }
        sucursal
        tipoCuenta{
            id
            nombre
        }
        nroCuenta
        cbu
        fechaSaldo
        saldo
        descubiertoPermitido
    }
}
`;

const UPDATE_CUENTA_MUTATION = gql`
    mutation updateCuenta(
        $id: ID!
        $banco: Int
        $moneda: Int!
        $tipoCuenta: Int!
        $descubiertoPermitido: Float!
        $cbu: String
        $nombre: String!
        $nroCuenta: String
        $sucursal: String
        $titular: String
    ) {
        updateCuenta(entity:{
            id: $id
            banco: $banco
            moneda: $moneda
            tipoCuenta: $tipoCuenta
            descubiertoPermitido: $descubiertoPermitido
            cbu: $cbu
            nombre: $nombre
            nroCuenta: $nroCuenta
            sucursal: $sucursal
            titular: $titular
        }) {
            id
            nombre
        }
    }
`;

const DELETE_CUENTA_MUTATION = gql`
    mutation deleteCuenta(
        $id: Int!
    ) {
        deleteCuenta(id: $id)
    }
`;

function CuentaEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();
    const [nombre, setNombre] = useState('');
    const [banco, setBanco] = useState('');
    const [sucursal, setSucursal] = useState('');
    const [titular, setTitular] = useState('');
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [nroCuenta, setNroCuenta] = useState('');
    const [cbu, setCbu] = useState('');
    const [saldo, setSaldo] = useState('');
    const [moneda, setMoneda] = useState('');
    const [descubierto, setDescubierto] = useState('0');
    const [fechaAlta, setFechaAlta] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        CUENTA_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findCuentaById?.nombre);
            setBanco(data?.findCuentaById?.banco?.id);
            setSucursal(data?.findCuentaById?.sucursal?data.findCuentaById.sucursal:"");
            setTitular(data?.findCuentaById?.titular?data?.findCuentaById?.titular:"");
            setTipoCuenta(data?.findCuentaById?.tipoCuenta?.id?data?.findCuentaById?.tipoCuenta?.id:"");
            setNroCuenta(data?.findCuentaById?.nroCuenta?data?.findCuentaById?.nroCuenta:"");
            setCbu(data?.findCuentaById?.cbu?data?.findCuentaById?.cbu:"");
            setSaldo(data?.findCuentaById?.saldo?data?.findCuentaById?.saldo:"");
            setMoneda(data?.findCuentaById?.moneda.simbolo?data?.findCuentaById?.moneda.simbolo:"");
            setDescubierto(data?.findCuentaById?.descubiertoPermitido?data?.findCuentaById?.descubiertoPermitido:"");
            setFechaAlta(data?.findCuentaById?.fechaAlta?data?.findCuentaById?.fechaAlta:"");
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findCuentaById?.nombre,
        data?.findCuentaById?.banco?.id,
        data?.findCuentaById?.sucursal,
        data?.findCuentaById?.titular,
        data?.findCuentaById?.tipoCuenta?.id,
        data?.findCuentaById?.nroCuenta,
        data?.findCuentaById?.cbu,
        data?.findCuentaById?.saldo,
        data?.findCuentaById?.moneda?.simbolo,
        data?.findCuentaById?.descubiertoPermitido,
        data?.findCuentaById?.fechaAlta
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateCuentaMutation] = useMutation(UPDATE_CUENTA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/cuenta";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteCuentaMutation] = useMutation(DELETE_CUENTA_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/cuenta");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateCuentaMutation({
            variables:{
                id: id,
                banco: parseInt(banco),
                moneda: parseInt(data?.findCuentaById?.moneda?.id),
                tipoCuenta: parseInt(tipoCuenta),
                descubiertoPermitido: descubierto?parseFloat(descubierto):0,
                cbu: cbu,
                nombre: nombre,
                nroCuenta: nroCuenta,
                sucursal: sucursal,
                titular: titular
            }
        })
    }

    const deleteAction = (id, label) => {
        /*showConfirmDeleteWithPin(
            id,
            (id)=>{deleteCuentaMutation({
                variables:{
                    id: id
                }
            })}
        );*/

        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteCuentaMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="cuenta"
                handleSubmit={handleSubmit}
                values={[
                    {  
                        name: "nombre",
                        type: "text",
                        maxLength: 50,
                        value: nombre,
                        setValue: setNombre,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("cuenta.field.nombre")}),
                        }
                    },
                    {  
                        name: "fechaalta",
                        type: "date",
                        value: fechaAlta,
                        setValue: setFechaAlta,
                        componentClassName: "col-sm-5 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                        disabled: true
                    },
                    {  
                        name: "banco",
                        type: "select",
                        entity:"Banco",
                        label: "nombre",
                        value: banco,
                        setValue: setBanco,
                        translationKey: "cuenta.field.banco.nombre",
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "sucursal",
                        type: "text",
                        maxLength: 50,
                        value: sucursal,
                        setValue: setSucursal,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "titular",
                        type: "text",
                        maxLength: 100,
                        value: titular,
                        setValue: setTitular,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "cuenta",
                        type: "select",
                        entity:"TipoCuenta",
                        label: "nombre",
                        value: tipoCuenta,
                        setValue: setTipoCuenta,
                        translationKey: "cuenta.field.tipoCuenta.nombre",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("cuenta.field.tipoCuenta.nombre")}),
                        },
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "nroCuenta",
                        type: "text",
                        maxLength: 50,
                        value: nroCuenta,
                        setValue: setNroCuenta,
                        translationKey: "cuenta.field.tipoCuenta.nroCuenta",
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "cbu",
                        type: "text",
                        maxLength: 50,
                        value: cbu,
                        setValue: setCbu,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {
                        name: "saldo",
                        componentClassName: "col-sm-7 col-xs-12",
                        render: () =>{
                            return(
                                <div className="form-group row">
                                    <label htmlFor="value-moneda" className="parrish-label col-sm-4 col-xs-12 required">{t("cuenta.field.saldo")}</label>
                                    <div className="col-4 col-sm-2 col-xl-2">
                                        <input autoComplete="off"
                                            type="text"
                                            name="value-moneda"
                                            className="parrish-input text-center"
                                            id="value-moneda"
                                            value={moneda}
                                            disabled
                                            />
                                    </div>
                                    <div className="col-8 col-sm-6 col-xl-6">
                                        <NumberFormat
                                            className="parrish-input text-right"
                                            name="value-saldo"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale="true"
                                            value={saldo}
                                            disabled
                                            />
                                    </div>
                                </div>
                            );
                        }
                    },
                    {  
                        name: "descubiertoPermitido",
                        componentClassName: "col-sm-7 col-xs-12",
                        render: () =>{
                            return(
                                <div className="form-group row">
                                    <label htmlFor="value-moneda-descubierto" className="parrish-label col-sm-4 col-xs-12">{t("cuenta.field.descubierto")}</label>
                                    <div className="col-4 col-sm-2 col-xl-2">
                                        <input autoComplete="off"
                                            type="text"
                                            name="value-moneda-descubierto"
                                            className="parrish-input text-center"
                                            id="value-moneda-descubierto"
                                            value={moneda}
                                            disabled
                                            />
                                    </div>
                                    <div className="col-8 col-sm-6 col-xl-6">
                                        <NumberFormat
                                            className="parrish-input text-right"
                                            name="value-descubierto-permitido"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale="true"
                                            value={descubierto}
                                            onChange ={(e) => {
                                                const val = e.target?.value;
                                                if(val){
                                                    setDescubierto(parseFloat(val.replaceAll(".","").replaceAll(",",".")));
                                                }
                                            }}/>
                                    </div>
                                </div>
                            );
                        }
                    }
                ]}
                returnUrl="/cuenta"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default CuentaEdit