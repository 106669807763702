import React from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import DownloadAdjunto from './DownloadAdjunto';
import SimpleDatatable from './SimpleDatatable';

export default function DownloadAdjuntosDialog(props) {
    const {entity, show, setShow, adjuntosList} = props;
    const displayClass = show?"show d-flex align-items-center":"";
    const [t] = useTranslation("global");

    return (
        <div className={`modal fade ${displayClass}`} id="modal-default" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered max-width-none">
                <div className="modal-content">
                    <div className="modal-header pl-3 p-2">
                        <h5 className="modal-title">{t("empresa.label.adjuntos")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShow(false)}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SimpleDatatable
                            data={adjuntosList}
                            entityName="adjunto"
                            columnsDefinition={[
                                {
                                    name: "nombreOriginal",
                                    type: "text"
                                },
                                {
                                    name: "responsable",
                                    className: "text-nowrap",
                                    render: (element)=>{
                                        return(<p><Moment format={"DD-MM-yyyy HH:mm"}>{element.fechaActualizacion}</Moment> [{element.responsable.contacto.alias}]</p>)
                                    }
                                },
                                {
                                    name: "descargar",
                                    customHeader: <i className="fas fa-download" />,
                                    customClassHeader: "bg-info text-center pl-0 pr-0",
                                    className: "text-center pr-2 pl-2",
                                    render: (element)=>{
                                        return(<DownloadAdjunto entity={entity} filename={element.nombreServidor} contentType={element.contentType} originalFilename={element.nombreOriginal} downloadMode={true} />)
                                    }
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
