import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useContext } from 'react'
import useAlertMessage from '../hooks/useAlertMessage.js';
import AuthContext from "./../context/AuthContext.js";
import { useTranslation } from "react-i18next"

const UPLOAD_EMPRESA_ADJUNTO = gql`
  mutation uploadEmpresaAdjunto(
    $idEmpresa: Int!
    $originalFilename: String!
    $file: Upload!
  ) {
    uploadEmpresaAdjunto(
        idEmpresa: $idEmpresa
        originalFilename: $originalFilename
        file: $file
    ) {
        referencia
    }
  }
`;

export default function UploadEmpresaAdjunto(props) {
    const { idEmpresa, onCompleted, label, icon, className } = props;
    const fileInput = React.createRef();

    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    let { showErrorToast, clearPopUps } = useAlertMessage();
    const [t] = useTranslation("global");

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type:mimeString});
    }

    const [uploadEmpresaAdjunto] = useMutation(UPLOAD_EMPRESA_ADJUNTO,{
        onCompleted: (data) => {
            onCompleted(data);
        },
        onError: (error) => {

        }
    });

    const fileMaxSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.fwk.config.max.file.size"})?.valor;

    const handleFileChange = (file) => {
        clearPopUps();
        if(file.size/1024/1024 < (fileMaxSizeDefaultParam?parseInt(fileMaxSizeDefaultParam):20)){
            
        }else{
            showErrorToast([{message: t("common.message.size-file-exceed")}]);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            console.log("Uploading...")
            const dataFile = dataURItoBlob(reader.result);
            uploadEmpresaAdjunto({variables: {idEmpresa: idEmpresa, originalFilename: file.name, file: dataFile}})
        };
        reader.onerror = function(error) {
            console.log("Error: ", error);
        };
    }

    const iconClassName = icon?icon:"fas fa-cloud-upload-alt";

    return (
        <label title={label?label:"Upload Attachment"} className={`btn btn-xs bg-info btn-tool ml-2 ${className}`} forhtml="file-empresa-selector">
            <input autoComplete="off" id="file-empresa-selector" type="file" accept="application/pdf" className="d-none" onChange={({target: {files: [file]}}) => handleFileChange(file)} ref={fileInput}/>
            <span>
                <i className={`${iconClassName}`}></i>
            </span>
        </label>
    )
}
