import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import SelectEntity from './SelectEntity';
import Autocomplete from '../components/Autocomplete';
import DatePicker from './DatePicker';
import SelectKeyValue from './SelectKeyValue';
import { useDebounce } from 'use-debounce';
import CustomSelect from './CustomSelect';
import { DateRfc }  from '../pages/app/DateRfc';

export default function CardFilter(props) {
    const {entityName, filters, searchFilter, setSearchFilter, columnsCount, className, cleanFilterHide=false, onSearchFilter} = props;
    const [t] = useTranslation("global");
    const [searchFilterDelayed, setSearchFilterDelayed] = useState(searchFilter);
    const [debounceSearchFilter] = useDebounce(searchFilterDelayed, 500);

    useEffect(() => {
        setSearchFilter(debounceSearchFilter);
    }, [debounceSearchFilter, setSearchFilter])

    const renderColumn = (filter) =>{
        const labelClassName = filter.labelClassName ? filter.labelClassName : "col-sm-4 col-xs-12";
        const inputClassName = filter.inputClassName ? filter.inputClassName : "col";
        const translationKey = filter.translationKey?filter.translationKey:entityName+".field."+filter.name;
        return(
            <div className="form-group row">
                <label htmlFor={'filter-'+filter.name} className={`parrish-label ${labelClassName}`}>{t(translationKey)}</label>
                <div className={inputClassName}>
                    {renderFilterComponent(filter)}
                </div>
            </div>
        );
    }

    const updateFilterValue = (name, value) =>{
        const updatedSearchFilter = {
            ...searchFilter
        };
        updatedSearchFilter[name] = value;       
        setSearchFilter(updatedSearchFilter);
    }

    const updateDelayedFilterValue = (name, value) =>{
        const updatedSearchFilter = {
            ...searchFilter
        };
        updatedSearchFilter[name] = value;       
        setSearchFilterDelayed(updatedSearchFilter);
    }

    const onChange = (onChangeFunction, filterName, e, label) => {
        updateFilterValue(filterName, e.target.value)
        if(onChangeFunction){
            onChangeFunction(e, label);
        }
    }

    const onDelayedChange = (onChangeFunction, filterName, e, label) => {
        updateDelayedFilterValue(filterName, e.target.value)
        if(onChangeFunction){
            onChangeFunction(e, label);
        }
    }

    const renderFilterComponent = (filter) =>{
        switch(filter.type) {
            case 'text':
            case 'number':
                const event = filter.event ? filter.event : "change";
                let eventParams;

                if(event==="change"){
                    eventParams = {
                        onChange: (e) => onDelayedChange(filter.onChange, filter.name, e)
                    }
                }
                if(event==="blur"){
                    eventParams = {
                        onBlur: (e) => onChange(filter.onChange, filter.name, e)
                    }
                }
                
                return (
                    <input autoComplete="off"
                        type="text"
                        inputMode={filter.type==="number"?"numeric":"text"}
                        name={'filter-'+filter.name}
                        className="parrish-input"
                        maxLength={filter.maxLength?filter.maxLength:150}
                        {
                            ...eventParams
                        }
                        value={searchFilterDelayed[filter.name]}
                        //onChange={(e) => onChange(filter.onChange, filter.name, e)}
                        //onBlur={(e) => onChange(filter.onChange, filter.name, e)}
                         />
                );
            case 'selectKeyValue':
                return (
                    <SelectKeyValue
                        {...filter}
                        value={searchFilter[filter.name]}
                        onChange={(e, label) => {
                            onChange(filter.onChange, filter.name, e, label)
                        }} />
                );
            case 'select':
                return (
                    <SelectEntity
                        entity={filter.entity}
                        queryCompleteName={filter.queryCompleteName}
                        sortDisable={filter.queryCompleteName}
                        placeholder={filter.placeholder}
                        label={filter.label}
                        filterValue={filter.filterValue}
                        filterName={filter.filterName}
                        filterBy={filter.filterBy}
                        name={'filter-'+filter.name}
                        value={searchFilter[filter.name]}
                        displayText={filter.displayText}
                        hideEmptyOption={filter.hideEmptyOption}
                        onChange={(e) => onChange(filter.onChange, filter.name, e)} />
                );
            case 'options':
                const emptyAllow = filter.emptyAllow!==undefined?filter.emptyAllow:true;
                return(<>
                    <CustomSelect
                        isClearable={emptyAllow}
                        name={'filter-'+filter.name}
                        defaultValue={searchFilter[filter.name]}
                        options={filter.options}
                        onChange={(value)=>{
                            onChange(filter.onChange, filter.name, {target:{value:value?.value}}, value?.label)
                        }}
                    />
                </>);
            case 'autocomplete':
                return (
                    <Autocomplete
                        entity={filter.entity}
                        by={filter.by}
                        filterName={filter.filterName}
                        value={searchFilter[filter.name]}
                        setValue={(value)=>updateFilterValue(filter.name, value)} />
                );
            case 'date':
                let selectedDate = new DateRfc(searchFilter[filter.name]?searchFilter[filter.name]:null);
                selectedDate = selectedDate instanceof Date && !isNaN(selectedDate)?selectedDate:null;
                return (
                    <DatePicker dateFormat={filter.format} selected={selectedDate} onChange={date => updateFilterValue(filter.name, date)} disabled={filter.disabled}/>
                );
            case 'dateRange':
                return (
                    <div className="row">
                      <DatePicker
                        dateFormat={filter.format}
                        selected={searchFilter[filter.startDateName]}
                        onChange={date => updateFilterValue(filter.startDateName, date)}
                        className="col-sm-6 col"
                        selectsStart
                        startDate={searchFilter[filter.startDateName]}
                        endDate={searchFilter[filter.endDateName]}
                        disabled={filter.disabled}
                      />
                      <DatePicker
                        dateFormat={filter.format}
                        selected={searchFilter[filter.endDateName]}
                        onChange={date => updateFilterValue(filter.endDateName, date)}
                        className="col-sm-6 col"
                        selectsEnd
                        startDate={searchFilter[filter.startDateName]}
                        endDate={searchFilter[filter.endDateName]}
                        minDate={searchFilter[filter.startDateName]}
                        disabled={filter.disabled}
                      />
                    </div>
                  );
            case 'custom':
                return filter.componentRender?filter.componentRender():null;
            default:
                return (
                    <p className="text-danger">Type not implemented</p>
                );
        }
    }

    const clearSearchFilters = () =>{
        const clearedSearchFilter = {
            ...searchFilter
        };
        for (let key of Object.keys(clearedSearchFilter)) {
            clearedSearchFilter[key] = "";
        }
        setSearchFilter(clearedSearchFilter);
    }

    const rowColsClass = columnsCount ? "row-cols-"+columnsCount : "";

    return (
        <div className={className?"card "+className:"card card-outline card-info"}>
            <div className="card-header pl-3 pt-2 pb-2">
                <h3 className="card-title">{t("common.label.filtro")}</h3>
                <div className="card-tools m-0">
                    {!cleanFilterHide&&
                        <button type="button" title={t("common.button.limpiar")} className="btn btn-xs btn-info bg-info btn-tool" onClick={()=>{
                            if(onSearchFilter)
                                onSearchFilter();
                            else
                                clearSearchFilters();
                        }}>
                            <i className="fas fa-eraser"></i>
                        </button>
                    }
                </div>
            </div>
            <div className="card-body">

                <div className={`parrish-form row ${rowColsClass}`}>
                {
                    filters?.map((filter)=>{
                        const componentClassName = filter.componentClassName ? filter.componentClassName : "col-xs-12 col-sm-6";
                        if(filter.render!=null){
                            return (
                                <div key={filter.name} className={componentClassName}>
                                    {filter.render()}
                                </div>
                            );
                        }
                        return (
                            <div key={filter.name} className={componentClassName}>
                                {renderColumn(filter)}
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </div>
    )
}


