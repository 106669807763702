import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

const QUICK_SEARCH = gql`
    query quickSearch(
        $termino: String!
    ) {
    quickSearch(
        termino: $termino
    ) {
        id
        tipo
        alias
    }
}
`;

export default function QuickSearch() {
    const [t] = useTranslation("global");
    const history = useHistory();
    const [value, setValue] = useState();
    const [suggestions, setSuggestions] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");

    const [textValue, setTextValue] = useState("");
    const debouncedGetSuggestions = useDebouncedCallback(
        // function
        (value) => {
            if(textValue!==value){
                setTextValue(value);
                getSuggestions({ variables: { termino: value } })
            }
        },
        // delay in ms
        500
      );

    const [selectedRow, setSelectedRow] = useState("");

    useEffect(() => {
        if(!value){
            setSelectedValue("");
        }
    }, [value]);
    
    const [getSuggestions, {loading}] = useLazyQuery(QUICK_SEARCH, {
        onCompleted: (data) => {
            setSuggestions(data?.quickSearch);
        }
    });

    const onBlur = (event, { highlightedSuggestion }) => {
        setSelectedValue("");
        setValue("", "");
    }

    const onChange = (event, { newValue, method }) => {
        if(method==="down"||method==="up"){
            setSelectedRow(newValue.tipo+newValue.id);
        }else{
            if(newValue!==""){    
                setSelectedValue(newValue);
            }else{
                setSelectedValue("");
                setValue("", "");
            }
        }
    };
    
    const onSuggestionsFetchRequested = ({ value }) => {
        debouncedGetSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (selectedValue) => {
        return selectedValue;
    };

    const inputProps = {
        name: "quicksearch-value",
        value: selectedValue,
        onBlur: onBlur,
        onChange: onChange,
        placeholder: t("common.label.quicksearch")
    };

    const onSuggestionSelected = (event,{ suggestion }) => {
        setSelectedValue("");
        setValue("", "");
        history.push("/private/"+suggestion.tipo.toLowerCase()+"/edit/"+suggestion.id);
    };

    function shouldRenderSuggestions(value, reason) {
        if(typeof value === 'string'){
            return value?.trim().length > 0 && !loading;
        }else{
            return false;
        }
    }

    function escapeRegex(string) {
        // eslint-disable-next-line no-useless-escape
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    const renderSuggestion = (suggestion, { query }) => {
        // Split the query into individual words
        const searchWords = query.trim().split(/\s+/);

        // Create a regex pattern for each word
        const reg = new RegExp('(' + searchWords.map(escapeRegex).join('|') + ')', 'gi');

        const parts = suggestion?.alias?.split(reg).map((part)=>{
            return {
                text: part,
                highlight: reg.test(part)
            }
        })

        return (
            <div className={"list-group sidebar-search-results"}>
                <div id={"suggestion-"+suggestion.tipo+suggestion.id} className={"list-group-item text-nowrap text-dark w-100 border-0 p-2 w-100"+((suggestion.tipo+suggestion.id)===selectedRow?" bg-light-gray active-element":"")} role="button" onMouseEnter={()=>{setSelectedRow(suggestion.tipo+suggestion.id)}}>
                    <span>
                    {parts.map((part, index) => {
                        return (
                            <span className={part.highlight?'highlight':''} key={index}>{part.text}</span>
                        );
                    })}
                    </span>
                    <span key={suggestion.id+suggestion.tipo} className={'badge ml-1 label-quicksearch-'+suggestion.tipo}>{suggestion.tipo}</span>
                </div>
            </div>
        );
    }

    const theme = {
        container:                'w-100 react-autosuggest__container',
        containerOpen:            'w-100 react-autosuggest__container--open',
        input:                    'form-control rounded',
        inputOpen:                'react-autosuggest__input--open',
        inputFocused:             'react-autosuggest__input--focused',
        suggestionsContainer:     'react-autosuggest__suggestions-container w-auto',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList:          'react-autosuggest__suggestions-list ',
        suggestion:               'm-0 border-0',
        suggestionFirst:          'react-autosuggest__suggestion--first',
        suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
        sectionContainer:         'react-autosuggest__section-container border-primary',
        sectionContainerFirst:    'react-autosuggest__section-container--first',
        sectionTitle:             'react-autosuggest__section-title'
    }

    return(
        <div className="form-inline ml-3 sidebar-search-open">
            <div id="quick-search-container" className="input-group input-group-sm">
                <Autosuggest
                    theme={theme}
                    suggestions={suggestions}
                    renderSuggestion={renderSuggestion}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    shouldRenderSuggestions={shouldRenderSuggestions}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={onSuggestionSelected}
                    focusInputOnSuggestionClick={false}
                    inputProps={inputProps}/>
            </div>
        </div>
    );
}