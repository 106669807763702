import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import Datatable from '../../../../components/Datatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EstadoComprobante from '../estados/EstadoComprobante';
import MONEDA from '../../../../enums/Moneda';
import ESTADO_ADMINISTRATIVO from '../../../../enums/EstadoAdministrativo';
import Moment from 'react-moment';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const COMPROBANTE_SEARCH = gql`
    query findComprobanteFacturacionPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int
        $cliente: Int
        $estado: Int
        $proyecto: Int
        $cbteFechaEmisionDesde: LocalDate
        $cbteFechaEmisionHasta: LocalDate
        $cbteFechaVencimientoDesde: LocalDate
        $cbteFechaVencimientoHasta: LocalDate
        $cbteTipo: Int
        $cbteNro: String
    ) {
    findComprobanteFacturacionPageByFilter(
        filter: {
            id: $id
            cliente: $cliente
            estado: $estado
            proyecto: $proyecto
            cbteFechaEmisionDesde: $cbteFechaEmisionDesde
            cbteFechaEmisionHasta: $cbteFechaEmisionHasta
            cbteFechaVencimientoDesde: $cbteFechaVencimientoDesde
            cbteFechaVencimientoHasta: $cbteFechaVencimientoHasta
            cbteTipo: $cbteTipo
            cbteNro: $cbteNro
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                adjunto
                estado {
                id
                color
                nombre
                }
                fechaRegistro
                registradoPor {
                contacto {
                    alias
                }
                }
                fechaAprobacion
                aprobadoPor {
                contacto {
                    alias
                }
                }
                fechaEmision
                emitidoPor {
                contacto {
                    alias
                }
                }
                fechaConciliacion
                conciliadoPor {
                    contacto {
                        alias
                    }
                }
                cliente {
                    id
                    contacto{
                        alias
                    }
                }
                proyecto {
                    id
                    ref
                }
                cbteFechaEmision
                cbteFechaVencimiento
                cbteTipo {
                    nemotecnico
                }
                cbteNro
                cbteDetalle
                cbteMoneda {
                id
                simbolo
                }
                cbteImporteFinal
                cbteImporteIva
                cbteImporteNeto
                cotizacionPeso
                cbteImpacto
            }
        }
    }
}
`;

const COMPROBANTE_TOTALES = gql`
    query findComprobanteImportesTotalesByMonedaEstado(
        $moneda: Int!
        $estados: [Int!]!
    ) {
        findComprobanteImportesTotalesByMonedaEstado(
            moneda: $moneda
            estados: $estados
        ) {
            referencia
            importeFinal
            importeIva
            importeNeto
        }
    }
`;

function FacturacionList(props){
    const [t] = useTranslation("global");
    const {searchFilter, setSearchFilter} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "estado",
        sortDirection: "ASC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const codigoMonedaTotales="AR$";

    const {loading, error, data} = useQuery(
        COMPROBANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: searchFilter?.id?parseInt(searchFilter.id):null,
                cliente: searchFilter?.cliente?parseInt(searchFilter.cliente):null,
                estado: searchFilter?.estado?parseInt(searchFilter.estado):null,
                proyecto: searchFilter?.proyecto?parseInt(searchFilter.proyecto):null,
                cbteFechaEmisionDesde: searchFilter?.cbteFechaEmisionDesde?moment(searchFilter?.cbteFechaEmisionDesde).format("YYYY-MM-DD"):null,
                cbteFechaEmisionHasta: searchFilter?.cbteFechaEmisionHasta?moment(searchFilter?.cbteFechaEmisionHasta).format("YYYY-MM-DD"):null,
                cbteFechaVencimientoDesde: searchFilter?.cbteFechaVencimientoDesde?moment(searchFilter?.cbteFechaVencimientoDesde).format("YYYY-MM-DD"):null,
                cbteFechaVencimientoHasta: searchFilter?.cbteFechaVencimientoHasta?moment(searchFilter?.cbteFechaVencimientoHasta).format("YYYY-MM-DD"):null,                
                cbteTipo: searchFilter?.cbteTipo?searchFilter?.cbteTipo:null,
                cbteNro: searchFilter?.cbteNr?searchFilter?.cbteNro:null
            }
        }
    );

    const totalImporteNeto = data?data["findComprobanteFacturacionPageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.cbteImporteNeto*currVal.node.cotizacionPeso*currVal.node.cbteImpacto
    } , 0):0;

    const totalImporteIva = data?data["findComprobanteFacturacionPageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.cbteImporteIva*currVal.node.cotizacionPeso*currVal.node.cbteImpacto
    }, 0):0;

    const totalImporteFinal = data?data["findComprobanteFacturacionPageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.cbteImporteFinal*currVal.node.cotizacionPeso*currVal.node.cbteImpacto
    }, 0):0;

    const {loading: loadingTotalComprobante, error: errorTotalComprobante, data: dataTotalComprobante} = useQuery(
        COMPROBANTE_TOTALES,
        {
            variables: {
                moneda: MONEDA.PESOS,
                estados: [ESTADO_ADMINISTRATIVO.REGISTRADO, ESTADO_ADMINISTRATIVO.APROBADO]
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="comprobante"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "id",
                        type: "number",
                    },
                    {
                        type: "select",
                        name: "estado",
                        entity:"EstadoAdministrativo",
                        label: "nombre"
                    },
                    {
                        type: "autocomplete",
                        name: "cliente",
                        entity:"EmpresaCliente",
                        by: "Alias",
                        filterName: "alias"
                    },
                    {
                        type: "autocomplete",
                        name: "proyecto",
                        entity:"Proyecto",
                        by: "EmpresaRef",
                        filterName: "ref"
                    },
                    {
                        type: "dateRange",
                        name: "fechaEmision",
                        startDateName: "cbteFechaEmisionDesde",
                        endDateName: "cbteFechaEmisionHasta",
                        translationKey: "comprobante.field.fechaEmision"
                    },
                    {
                        type: "dateRange",
                        name: "fechaVencimiento",
                        startDateName: "cbteFechaVencimientoDesde",
                        endDateName: "cbteFechaVencimientoHasta",
                        translationKey: "comprobante.field.fechaVencimiento"
                    },
                    {
                        type: "select",
                        name: "cbteTipo",
                        translationKey: "comprobante.field.comprobante",
                        entity:"TipoComprobante",
                        label: "letra,tipo",
                        displayText: (element)=>{
                            return element.letra?(element.tipo+" - "+element.letra):element.tipo;
                        },
                        labelClassName:"col-6",
                        inputClassName:"col-6",
                        componentClassName:"col-4"
                    },
                    {
                        type: "text",
                        name: "cbteNro",
                        maxLength: 50,
                        labelClassName:"d-none",
                        inputClassName:"col-2"
                    },
                ]}>
            </CardFilter>
            <div className={"card card-outline card-info"}>
                {/*
                    loading &&
                    !error && (
                        <div className="overlay">
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    )
                */}
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.listado")}</h3>
                    <div className="card-tools m-0">
                    </div>
                </div>
                <div className="card-body">
                {
                    !loadingTotalComprobante &&
                    !errorTotalComprobante &&
                    <div className="d-flex justify-content-center">
                            <div className="small-box text-center bg-white fit-content mr-3">
                                <div className="inner">
                                    <h5>{t("facturacion.label.totalRegistrado")}</h5>
                                    <h2 className="d-flex justify-content-center m-1">
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeFinal}/>
                                    </h2>
                                    <small className="d-flex justify-content-center m-1">
                                        <b>IVA: </b>
                                        <CurrencyFormat                                           
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeIva}/>
                                        <b className="ml-1">NETO: </b>
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[0].importeNeto}/>
                                    </small>
                                </div>       
                            </div>
                            <div className="small-box text-center bg-white fit-content mr-3">
                                <div className="inner">
                                    <h5>{t("facturacion.label.totalAprobado")}</h5>
                                    <h2 className="d-flex justify-content-center m-1">
                                        <CurrencyFormat
                                            prefix={codigoMonedaTotales}
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[1].importeFinal}/>
                                    </h2>
                                    <small className="d-flex justify-content-center m-1">
                                        <b>IVA: </b>
                                        <CurrencyFormat
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[1].importeIva}
                                            prefix={codigoMonedaTotales}/>
                                        <b className="ml-1">NETO: </b>
                                        <CurrencyFormat
                                            value={dataTotalComprobante?.findComprobanteImportesTotalesByMonedaEstado[1].importeNeto}
                                            prefix={codigoMonedaTotales}/>
                                    </small>
                                </div>       
                            </div>
                        </div>
                }
                    <Datatable
                        data={data}
                        loading={loading}
                        error={error}
                        sortOptions={sortOptions}
                        dataWrapper="findComprobanteFacturacionPageByFilter"
                        entityName="comprobante"
                        setPageSize={setPageSize}
                        setPageNumber={setPageNumber}
                        setSortOptions={setSortOptions}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        editUrl="/private/facturacion/edit/"
                        headerBottom={
                            <tr>
                                <th className="bg-white p-0 text-center">
                                    {codigoMonedaTotales}
                                </th>
                                <th className="bg-white pr-1">
                                    <CurrencyFormat
                                        additionalClassName="float-right"
                                        value={totalImporteNeto}/>
                                </th>
                                <th className="bg-white pr-1">
                                    <CurrencyFormat
                                        additionalClassName="float-right"
                                        value={totalImporteIva}/>
                                </th>
                                <th className="bg-white pr-1">
                                    <CurrencyFormat
                                        additionalClassName="float-right"
                                        value={totalImporteFinal}/>
                                </th>
                            </tr>
                        }
                        columnsDefinition={[
                            {
                                name: "id",
                                type: "id",
                                rowSpan: 2
                            },
                            {
                                name: "adjunto",
                                className:"text-center",
                                customHeader: (<i className="fas fa-paperclip"></i>),
                                customClassHeader: "bg-info text-center pl-0 pr-0",
                                disableSort: true,
                                render: (element)=>{
                                    return(<DownloadAdjunto filename={element.node.adjunto} entity="Comprobante"/>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "estado",
                                className:"text-center",
                                render: (element)=>{
                                    return(<EstadoComprobante element={element}/>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "clienteProyecto",
                                customSortKey: "cliente",
                                render: (element)=>{
                                    return(<div className="d-flex flex-column">
                                        <PrivateLink className="parrish-link fit-content" to={"/empresa/edit/"+element.node.cliente.id}>{element.node.cliente.contacto.alias}</PrivateLink>
                                        <PrivateLink className="parrish-link fit-content" to={"/proyecto/edit/"+element.node.proyecto.id}>[{element.node.proyecto.id}] - {element.node.proyecto.ref}</PrivateLink>
                                    </div>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "fechaAprobacion",
                                render: (element)=>{
                                    const days = moment().diff(moment(element.node.fechaAprobacion), 'days');
                                    const textDesde = (days>1)?"[Hace "+days+"d]":"[Hoy]"
                                    return(element.node.fechaAprobacion&&<>
                                        <Moment className="date-centered" format={"DD-MM-yyyy"}>{element.node.fechaAprobacion}</Moment>
                                        <p className="text-align-center text-danger">{textDesde}</p>
                                        <p className="text-align-center">{element.node.aprobadoPor.contacto.alias}</p>
                                    </>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "cbteFechaEmision",
                                type: "date",
                                rowSpan: 2
                            },
                            {
                                
                                name: "cbteFechaVencimiento",
                                type: "date",
                                rowSpan: 2
                            },
                            {
                                name: "detalle",
                                render: (element)=>{
                                    return(<>
                                        <p className="text-bold">{element.node.cbteTipo.nemotecnico+(element.node.cbteNro?" ["+element.node.cbteNro+"]":"")}</p>
                                        <p>({element.node.cbteDetalle})</p>
                                    </>)
                                },
                                rowSpan: 2
                            },
                            {
                                name: "moneda",
                                className:"text-align-center",
                                customHeader: " ",
                                customClassHeader: "bg-info pr-0 pl-0 border-with-1",
                                render: (element)=>{
                                    return(element.node.cbteMoneda?.simbolo)
                                }
                            },
                            {  
                                name: "cbteImporteNeto",
                                type: "money",
                                disableSort: true,
                                render: (element)=>{
                                    const importeNeto = element.node.cbteImporteNeto*element.node.cbteImpacto;
                                    return(
                                        <CurrencyFormat
                                            additionalClassName="float-right"
                                            value={importeNeto}/>
                                    )
                                }
                            },
                            {  
                                name: "cbteImporteIva",
                                type: "money",
                                disableSort: true,
                                render: (element)=>{
                                    const importeIva = element.node.cbteImporteIva*element.node.cbteImpacto;
                                    return(
                                        <CurrencyFormat
                                            additionalClassName="float-right"
                                            value={importeIva}/>
                                    )
                                }
                            },
                            {  
                                name: "cbteImporteFinal",
                                type: "money",
                                render: (element)=>{
                                    const importeFinal = element.node.cbteImporteFinal*element.node.cbteImpacto;
                                    return(
                                        <CurrencyFormat
                                            additionalClassName="float-right"
                                            value={importeFinal}/>
                                    )
                                }
                            }
                        ]}>
                    </Datatable>
                </div>
            </div>
        </>
    );
}

export default FacturacionList;