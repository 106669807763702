import React, { useState, useEffect, useRef } from 'react'
import ConfigData from "../../../config.json"
import { Link } from 'react-router-dom';
import logoImg from '../../../assets/images/logo.png'
import { useMutation, gql, useQuery } from '@apollo/client';
import Swal from 'admin-lte/plugins/sweetalert2/sweetalert2.all'
import { useHistory, useParams } from 'react-router-dom';
import $ from 'jquery'
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../hooks/useAlertMessage';
import SecurityPageWrapper from './SecurityPageWrapper';

//const FWK_CONFIG_SECURITY_PASSWORD_EXPIRATION_DAYS = "parametro.value.fwk.config.security.password.expiration.days";
//const FWK_CONFIG_SECURITY_PASSWORD_EXPIRES = "parametro.value.fwk.config.security.password.expires";
const FWK_CONFIG_SECURITY_PASSWORD_MAX_LENGTH = "parametro.value.fwk.config.security.password.max.length";
const FWK_CONFIG_SECURITY_PASSWORD_MIN_LENGTH = "parametro.value.fwk.config.security.password.min.length";
const FWK_CONFIG_SECURITY_PASSWORD_SYMBOLS_LIST = "parametro.value.fwk.config.security.password.symbols.list";
const FWK_CONFIG_SECURITY_PASSWORD_WITH_BOTH_CASES = "parametro.value.fwk.config.security.password.with.both.cases";
const FWK_CONFIG_SECURITY_PASSWORD_WITH_NUMBERS = "parametro.value.fwk.config.security.password.with.numbers";
const FWK_CONFIG_SECURITY_PASSWORD_WITH_SYMBOLS = "parametro.value.fwk.config.security.password.with.symbols";

const PASSWORD_RECOVERY_MUTATION = gql`
    mutation PasswordRecoveryMutation(
        $token: String!
        $newPassword: String!
    ) {
        passwordRecovery(input:{
            token: $token
            newPassword: $newPassword
            confirmationPassword: $newPassword
        }){
            infoCode
            infoMessage
        }
    }
`;

const INIT_PASSWORD_RECOVERY_TOKEN = gql`
    query initToken(
        $token: String!
    ) {
        initToken(
            token: $token
        )
    }
`;

const FIND_PASSWORD_POLICY = gql`
    query findPasswordPolicy{ findPasswordPolicy{
        id
        nombre
        valor
    }}
`;

export default function PasswordRecovery() {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const history = useHistory();
    const ref = useRef();
    const [t] = useTranslation("global");
    let { token } = useParams();
    let { showSuccess } = useAlertMessage();
    //ValidationParams
    const [maxLength, setMaxLength] = useState();
    const [minLength, setMinLength] = useState();
    const [withBothCases, setWithBothCases] = useState();
    const [withNumbers, setWithNumbers] = useState();
    const [withSymbols, setWithSymbols] = useState();
    const [symbolsList, setSymbolsList] = useState();
    //ValidationStatus
    const [validLength, setValidLength] = useState(true);
    const [validBothCases, setValidBothCases] = useState(true);
    const [validNumbers, setValidNumbers] = useState(true);
    const [validSymbols, setValidSymbols] = useState(true);

    const [
        passwordRecoveryMutation
    ] = useMutation(PASSWORD_RECOVERY_MUTATION, {
        onCompleted: (data) => {
            console.log("Recovery Password OK", data);
            showSuccess('Ya podés ingresar con tu nueva contraseña.', () => {
                history.push('/');
            });
        },
        onError: (error) => {
            console.error(error);
        }
    });

    const { data: username } = useQuery(
        INIT_PASSWORD_RECOVERY_TOKEN,
        {
            variables: {
                token: token
            }
        }
    );

    const { loading: loadingPasswordPolicy } = useQuery(
        FIND_PASSWORD_POLICY,
        {
            onCompleted: (data) => {
                setMaxLength(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_MAX_LENGTH).valor);
                setMinLength(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_MIN_LENGTH).valor);
                setWithBothCases(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_BOTH_CASES).valor?JSON.parse(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_BOTH_CASES).valor):null);
                setWithNumbers(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_NUMBERS).valor?JSON.parse(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_NUMBERS).valor):null);
                setWithSymbols(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_SYMBOLS).valor?JSON.parse(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_WITH_SYMBOLS).valor):null);
                setSymbolsList(data.findPasswordPolicy.find(parametro=>parametro.nombre.toLowerCase() === FWK_CONFIG_SECURITY_PASSWORD_SYMBOLS_LIST).valor);
            }
        }
    );

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                password: {
                    required: true,
                    minlength : 5,
                },
                passwordConfirm : {
                    required: true,
                }
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
              error.addClass('invalid-feedback');
            },
            highlight: function (element, errorClass, validClass) {
              $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass('is-invalid');
            }
        });
    }, []);

    const validatePassword = () => {
        let validPassword = true;

        setValidLength(password.length>=minLength && password.length<=maxLength);
		validPassword = validPassword && validLength;
		
		if(withSymbols) {
			let validSymbols = false;

            for (let i = 0; i < symbolsList.length; i++) {
                validSymbols = validSymbols || password.includes(symbolsList.charAt(i));
            }
            setValidSymbols(validSymbols);
			validPassword = validPassword && validSymbols;
		}
		
		if(withNumbers||withBothCases) {
			let findUppercases = false;
			let findLowercases = false;
			let validNumbers = false;

            for (let i = 0; i < password.length; i++) {
                findUppercases = findUppercases || /^[A-Z]+$/.test(password.charAt(i));
                findLowercases = findLowercases || /^[a-z]+$/.test(password.charAt(i));
                validNumbers = validNumbers || /^\d+$/.test(password.charAt(i));
            }

			if(withBothCases) {
                setValidBothCases(findUppercases && findLowercases);
				validPassword = validPassword && validBothCases;
			}
            
			if(withNumbers) {
                setValidNumbers(validNumbers);
				validPassword = validPassword && validNumbers;
			}
		}

        return validPassword;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validatePassword()){
            if(password !== passwordConfirm){
                Swal.fire({
                    icon: 'error',
                    text: t("login.message.invalid.password.confirmation"),
                });
            }else{
                passwordRecoveryMutation({variables: { token: token, newPassword: password } });
            }
        }
    }

    const PasswordPolicies = () => {

        return(
            <>
                <p className="small strong font-weight-bold">{t("login.label.cambio.password-policies",{0: username})}</p>
                <ul className="small">
                    <li id="lengthValidationMessage" className={validLength?'':'text-danger'} dangerouslySetInnerHTML={{ __html: t("login.message.password.length",{0: minLength, 1: maxLength}) }}></li>
                    {withBothCases &&
                        <li id="withBothCasesMessage" className={validBothCases?'':'text-danger'} dangerouslySetInnerHTML={{ __html: t("login.message.password.with.cases") }}></li>
                    }
                    {withNumbers &&
                        <li id="withNumbersMessage" className={validNumbers?'':'text-danger'} dangerouslySetInnerHTML={{ __html: t("login.message.password.with.numbers") }}></li>
                    }
                    {withSymbols &&
                        <li id="withSymbolsMessage" className={validSymbols?'':'text-danger'} dangerouslySetInnerHTML={{ __html: t("login.message.password.with.simbols",{0: symbolsList}) }}></li>
                    }
                </ul>
            </>
        );
    }

    return (!loadingPasswordPolicy&&
        <SecurityPageWrapper>
            <div className="">
                <div className="card">
                    <div className="card-header text-align-center mt-3">
                        <img src={logoImg} alt={ConfigData.APP_TITLE_NAME}/>
                    </div>
                    <div className="card-body login-card-body">
                        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                        <p className="login-box-msg">Estas a punto de reestablecer tu contraseña.</p>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group mb-3">
                                        <input autoComplete="off" className="form-control"
                                            name="password"
                                            type="password"
                                            placeholder={t("login.label.placeholders.password")}
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input autoComplete="off" className="form-control"
                                            name="passwordConfirm"
                                            type="password"
                                            placeholder={t("login.label.placeholders.password-confirm")}
                                            onChange={(e) => setPasswordConfirm(e.target.value)}
                                            value={passwordConfirm} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <PasswordPolicies/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-info btn-block">{t("common.button.confirmar")}</button>
                                    <Link className="btn btn-info btn-block" to="/">{t("common.button.cancelar")}</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer text-align-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-align-center">powered by <a className="text-info" href="http://www.parrish.com.ar/">Parrish Software Factory</a></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </SecurityPageWrapper>
    )
}
