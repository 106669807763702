import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"

// TODO CHRIS ver como hacer para ordenar por nombre de etapa proyecto

const ESTADOPOSTULANTE_SEARCH = gql`
    query findEstadoPostulantePageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $etapaProyecto: Int
    ) {
    findEstadoPostulantePageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            etapaProyecto: $etapaProyecto
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                etapaProyecto{
                    nombre
                }
                id
                desiste
                esFinal
                esInicial
                nombre
            }
        }       
    }
}
`;

function EstadoPostulanteList(props){
    const {searchFilter, setSearchFilter} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "etapaProyecto",
        sortDirection: "ASC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        ESTADOPOSTULANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                etapaProyecto: searchFilter.etapaProyecto?searchFilter.etapaProyecto:null
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="estadoPostulante"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "etapaProyecto",
                        type: "selectKeyValue",
                        entity: "EtapaProyecto"
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findEstadoPostulantePageByFilter"
                entityName="estadoPostulante"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id"
                    },
                    {
                        name: "etapaProyecto",
                        type: "text",
                        render: (element) =>{
                            return(element.node.etapaProyecto?.nombre)
                        }
                    },
                    {
                        name: "nombre",
                        type: "text"
                    },
                    {
                        name: "esFinal",
                        type: "boolean",
                        falseLabel:" "
                    },
                    {
                        name: "esInicial",
                        type: "boolean",
                        falseLabel:" "
                    },
                    {
                        name: "desiste",
                        type: "boolean",
                        falseLabel:" "
                    }
                ]}
                editUrl="/private/estadoPostulante/edit/"
                createUrl="/estadoPostulante/create">
            </CardDatatable>
        </>
    );
}

export default EstadoPostulanteList;