import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import Autocomplete from '../../../../../components/Autocomplete';
import ContactoSelector from '../../../../../components/ContactoSelector';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';
import SelectEntity from '../../../../../components/SelectEntity';
import ESTADO_PROYECTO from '../../../../../enums/EstadoProyecto';
import SelectKeyValue from '../../../../../components/SelectKeyValue';

const AGREGAR_ASOCIADO = gql`
    mutation agregarAsociadoAlProyecto(
        $idProyecto: Int!
        $idAsociado: Int!
    ) {
        agregarAsociadoAlProyecto(
            idProyecto: $idProyecto
            idAsociado: $idAsociado
        )
    }
`;

const DELETE_ASOCIADO = gql`
    mutation eliminarAsociadoDelProyecto(
        $idProyecto: Int!
        $idAsociado: Int!
    ) {
        eliminarAsociadoDelProyecto(
            idProyecto: $idProyecto
            idAsociado: $idAsociado
        )
    }
`;

export default function DetalleTab(props) {
    const [t] = useTranslation("global");
    const { proyecto, state, setState } = props;

    const [datosContacto, setDatosContacto] = useState({
        alias: "",
        email: "",
        telefonosFijos: "",
        telefonosMoviles: ""
    })

    useEffect(() => {
        setDatosContacto({
            alias: proyecto?.contacto?.alias?proyecto?.contacto?.alias:"",
            email: proyecto?.contacto?.email?proyecto?.contacto?.email:"",
            telefonosFijos: proyecto?.contacto?.telefonosFijos?proyecto?.contacto?.telefonosFijos:"",
            telefonosMoviles: proyecto?.contacto?.telefonosMoviles?proyecto?.contacto?.telefonosMoviles:""
        });
    }, [proyecto?.contacto])

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const [agregarAsociadoMutation] = useMutation(AGREGAR_ASOCIADO);

    const agregarAsociado = (newAvatar) => {
        if(newAvatar.value){
            agregarAsociadoMutation({variables: {
                idProyecto: proyecto?.id,
                idAsociado: parseInt(newAvatar.value)
            }});
        }
    }
    const [eliminarAsociadoMutation] = useMutation(DELETE_ASOCIADO);

    const eliminarAsociado = (newAvatar) => {
        if(newAvatar.value){
            eliminarAsociadoMutation({variables: {
                idProyecto: proyecto?.id,
                idAsociado: parseInt(newAvatar.value)
            }});
        }
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const isProspecto = ()=>{
        return parseInt(proyecto.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
    }

    const isCanceladoSuspendidoOTerminado = ()=>{
        return parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.CANCELADO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.SUSPENDIDO ||
            parseInt(proyecto?.estado?.id) === ESTADO_PROYECTO.TERMINADO;
    }

    return (
        <div className="parrish-form row pr-2 pl-2">

            
            <div className="col-xs-12 col-sm-6">
                <div className="form-group row">
                    <label htmlFor="value-empresa" className="parrish-label col-2 required">{t("proyecto.field.empresa")}</label>
                    <div className="col-8">
                    
                        <Autocomplete
                            name="value-empresa"
                            entity="Empresa"
                            by="Alias"
                            filterName="alias"
                            value={state.empresa}
                            initialLabel={proyecto?.empresa?.contacto?.alias}
                            setValue={(value)=>handleChange("empresa", {target:{value:value}})} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-area-empresa" className="parrish-label col-2 required">{t("proyecto.field.areaEmpresa")}</label>
                    <div className="col-8">
                        <SelectKeyValue
                            entity="AreaEmpresa"
                            by="Nombre"
                            name={'value-areaEmpresa'}
                            value={state.areaEmpresa}
                            required
                            onChange={(e) => {
                                handleChange("areaEmprea", e);
                            }}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-cargo" className="parrish-label col-2 required">{t("proyecto.field.cargo")}</label>
                    <div className="col-8">
                        <SelectKeyValue
                            entity="Cargo"
                            by="Nombre"
                            name={'value-cargo'}
                            value={state.cargo}
                            required
                            onChange={(e) => {
                                handleChange("cargo", e);
                            }}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-posicion" className="parrish-label col-2 required">{t("proyecto.field.posicion")}</label>
                    <div className="col-8">
                        <input autoComplete="off" type="text"
                            name={'value-posicion'}
                            className="parrish-input"
                            value={state.posicion}
                            onChange={(e) => handleChange("posicion", e)}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-pais" className="parrish-label col-2 required">{t("proyecto.field.pais")}</label>
                    <div className="col-8">
                        <SelectKeyValue
                            entity="Pais"
                            label="nombre"
                            name={'value-pais'}
                            value={state.pais}
                            required
                            onChange={(e) => {
                                handleChange("pais", e);
                            }}/>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-6">
                <div className="form-group row">
                    <label htmlFor="value-contacto" className="parrish-label col-2 required">{t("proyecto.field.contacto")}</label>
                    <div className="col-8">
                        <ContactoSelector
                            cliente={state.contacto}
                            setCliente={(value)=>handleChange("contacto", {target:{value:value}})}
                            disabledCreate={isCanceladoSuspendidoOTerminado()}
                            setContacto={(contacto)=>{
                                setDatosContacto({
                                    alias: contacto?.alias?contacto?.alias:"",
                                    email: contacto?.email?contacto?.email:"",
                                    telefonosFijos: contacto?.telefonosFijos?contacto?.telefonosFijos:"",
                                    telefonosMoviles: contacto?.telefonosMoviles?contacto?.telefonosMoviles:""
                                });
                            }}
                            defaultLabel={proyecto?.contacto?.alias} />
                    </div>
                </div>
                
                <div className="form-group row">
                    <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.email")}</label>
                    <div className="col-8">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{emailClick(e,datosContacto.email)}}>
                                <span className="input-group-text">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.email} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.telefonosFijos")}</label>
                    <div className="col-8">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fas fa-phone"></i>
                                </span>
                            </div>
                            <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.telefonosFijos} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-contacto" className="parrish-label col-2">{t("contacto.field.telefonosMoviles")}</label>
                    <div className="col-8">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend cursor-pointer" onClick={(e)=>{whatsappClick(e,datosContacto.telefonosMoviles)}}>
                                <span className="input-group-text">
                                    <i className="fab fa-whatsapp"></i>
                                </span>
                            </div>
                            <input autoComplete="off" type="text" className="parrish-input" disabled={true} value={datosContacto.telefonosMoviles} />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="value-responsable" className="parrish-label col-2 required">{t("proyecto.field.responsable")}</label>
                    <div className="col-8">
                        <SelectKeyValue   
                            entity="Usuario"
                            placeholder={t("postulante.field.responsable")}
                            name="value-responsable"
                            value={state.socia}
                            onChange={(e) => handleChange("socia", e)}/>
                    </div>
                </div>
            </div>
                                

            <div className="col-xs-12 col-sm-6">
                <div className="form-group row">
                    <label htmlFor="value-descripcion" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.descripcion")}</label>
                    <div className="col-sm-12 col-xs-12">
                        <textarea name="value-descripcion" value={state?.descripcion?state?.descripcion:""} onChange={(e)=>{handleChange("descripcion", e)}} className="form-control form-control-sm" rows="19"></textarea>
                    </div>
                </div>
            </div>

            <div className="col-xs-12 col-sm-6">
                <div className="row">
                    <label htmlFor="value-asociados-list" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.asociados")}</label>
                    <div className="col-12">
                        <MultipleAutocomplete
                            name="value-asociados-list"
                            entity="UsuarioActivo"
                            by="Alias"
                            filterName="alias"
                            searchInFocus={true}
                            defaultValue={
                                proyecto.asociadoList.map((element)=>{
                                    return ({
                                        label: element?.contacto?.alias,
                                        value: element?.id
                                    })
                                })
                            }
                            onRemove={(value)=>{
                                eliminarAsociado(value);
                            }}
                            onSelect={(value)=>{
                                agregarAsociado(value);
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className="col-12 d-flex">
                        <label htmlFor="value-sueldo-bruto" className="parrish-label text-nowrap mr-3 required">{t("proyecto.field.sueldoBruto")}</label>
                        <SelectEntity
                            entity="Moneda"
                            label="simbolo"
                            name="value-moneda"
                            value={state.moneda}
                            placeholder={" "}
                            required
                            disabled={!isProspecto()}
                            onChange={(e) => {
                                handleChange("moneda", e)
                            }}/>
                        <NumberFormat
                            name="value-sueldo-bruto"
                            thousandSeparator="."
                            decimalSeparator=","
                            disabled={!isProspecto()}
                            value={state.sueldoBrutoMensual}
                            className={"parrish-input text-right ml-2"}
                            onChange={(e) => {
                                const val = e.target?.value;
                                if(val){
                                    handleChange("sueldoBrutoMensual", {target:{value:parseInt(val.replaceAll(".","").replaceAll(",","."))}});
                                }
                            }}
                            />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="value-beneficios" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.beneficios")}</label>
                    <div className="col-sm-12 col-xs-12">
                        <textarea name="value-beneficios" value={state?.beneficios?state?.beneficios:""} onChange={(e)=>{handleChange("beneficios", e)}} className="form-control form-control-sm" rows="5"></textarea>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="value-bonus" className="parrish-label-left col-sm-12 col-xs-12 required">{t("proyecto.field.bonus")}</label>
                    <div className="col-sm-12 col-xs-12">
                        <textarea name="value-bonus" value={state?.bonus?state?.bonus:""} onChange={(e)=>{handleChange("bonus", e)}} className="form-control form-control-sm" rows="5"></textarea>
                    </div>
                </div>
                
                
            </div>
        </div>
    )
}