import React, { useState } from 'react'
import CloseIcon from '../assets/close-icon';
import SelectKeyValue from './SelectKeyValue';

export default function ConocimientoFilter(props) {
    const {setConocimientoListFunction} = props;
    const [conocimiento, setConocimiento] = useState({id:"",label:null});
    const [nivelConocimiento, setNivelConocimiento] = useState({id:"",label:null});
    const [conocimientoList, setConocimientoList] = useState([]);

    return (
        <div className="form-group row">
            <div className="col-sm-12 col-xs-12">
                <div className="d-flex">
                
                    <div className="flex-grow-1">
                        <SelectKeyValue
                            entity="Conocimiento"
                            by="Nombre"
                            name="value-conocimiento-list"
                            value={conocimiento.id}
                            required
                            onChange={(e, label) => {
                                setConocimiento({
                                    id: e.target?.value ? parseInt(e.target.value) : "",
                                    label: label
                                });
                            }}/>
                    </div>
                    <div className="flex-grow-1 ml-2">
                        <SelectKeyValue
                            entity="NivelConocimiento"
                            name={'value-nivel-conocimiento'}
                            value={nivelConocimiento.id}
                            required
                            onChange={(e, label) => {
                                setNivelConocimiento({
                                    id: e.target?.value ? parseInt(e.target.value) : "",
                                    label: label
                                });
                            }} />
                    </div>
                    <div className="ml-2">
                        <button className="btn btn-xs btn-info bg-info btn-tool"
                            disabled={!nivelConocimiento.id || !conocimiento.id}
                            onClick={() => {
                                let exist = false;
                                for (const conocimientoObj of conocimientoList) {
                                    if (conocimientoObj.conocimiento.id === conocimiento.id &&
                                        conocimientoObj.nivel.id === nivelConocimiento.id) {
                                        exist = true;
                                    }
                                }
                                if (!exist) {
                                    const updatedConocimientoList = conocimientoList.concat({
                                        nivel: {
                                            id: nivelConocimiento.id,
                                            label: nivelConocimiento.label
                                        },
                                        conocimiento: {
                                            id: conocimiento.id,
                                            label: conocimiento.label
                                        }
                                    }); 
                                    setConocimientoList(updatedConocimientoList);
                                    setConocimientoListFunction(updatedConocimientoList.map(element=>{
                                        return ({
                                            conocimiento: element.conocimiento.id,
                                            nivel: element.nivel.id
                                        });
                                    }));
                                }
                                setConocimiento({ id: "", label: null });
                                setNivelConocimiento({ id: "", label: null });
                            }}>
                            <i className="fas fa-plus" />
                        </button>
                    </div>
                </div>
                <div className="d-flex">
                    {conocimientoList?.length !== 0 &&
                    <div className="border rounded w-100 mt-1 pt-1 pl-2 pr-2 d-flex flex-wrap">    
                        {conocimientoList.map(element => {
                            return (
                                <div className="d-flex fit-content multi-value mb-1 mr-1">
                                    <div className="multi-value-label-containter">{element.nivel.label} - {element.conocimiento.label}</div>
                                    <div role="button" className="multi-value-close-containter" onClick={(e) => {
                                        const updatedConocimientoList = conocimientoList.filter(conocimientoObj => {
                                            return !(conocimientoObj.conocimiento.id === element.conocimiento.id &&
                                                conocimientoObj.nivel.id === element.nivel.id);
                                        });
                                        setConocimientoList(updatedConocimientoList);
                                        setConocimientoListFunction(updatedConocimientoList.map(element=>{
                                            return ({
                                                conocimiento: element.conocimiento.id,
                                                nivel: element.nivel.id
                                            });
                                        }));
                                    }}>
                                        <CloseIcon />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
