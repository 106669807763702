import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import { DateRfc }  from '../../DateRfc';

const OFF_LIMIT_SEARCH_BY_ID = gql`
    query findOffLimitById(
        $id: Int!
    ) {
    findOffLimitById(
        id: $id
    ){
        id
        postulante{
            id
            contacto{
                alias
            }
        }       
        responsable{
            contacto{
                alias
            }
        }
        fechaFin
        fechaInicio
        observaciones
        estado
    }
}
`;

const UPDATE_OFF_LIMIT_MUTATION = gql`
    mutation updateOffLimit(
        $id: ID!
        $postulante: Int!
        $fechaFin: LocalDate
        $fechaInicio: LocalDate!
        $observaciones: String
    ) {
        updateOffLimit(entity:{
            id: $id
            postulante: $postulante
            fechaFin: $fechaFin
            fechaInicio: $fechaInicio
            observaciones: $observaciones
    }) {
            id
        }
    }
`;

const DELETE_OFF_LIMIT_MUTATION = gql`
    mutation deleteOffLimit(
        $id: Int!
    ) {
        deleteOffLimit(id: $id)
    }
`;

function OffLimitEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [observaciones, setObservaciones] = useState('');
    const [postulanteLabel, setPostulanteLabel] = useState("")

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        OFF_LIMIT_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            if(data?.findOffLimitById?.fechaInicio){
                setStartDate(new DateRfc(data?.findOffLimitById?.fechaInicio));
            }
            if(data?.findOffLimitById?.fechaFin){
                setEndDate(new DateRfc(data?.findOffLimitById?.fechaFin));
            }
            setPostulanteLabel(`[${data?.findOffLimitById?.postulante.id}] - ${data?.findOffLimitById?.postulante.contacto.alias}`);
            setObservaciones(data?.findOffLimitById?.observaciones);
        }
        return () => { _isMounted.current = true };
    }, [data?.findOffLimitById, error, loading])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateOffLimitMutation] = useMutation(UPDATE_OFF_LIMIT_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/offLimit";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteOffLimitMutation] = useMutation(DELETE_OFF_LIMIT_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/offLimit");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateOffLimitMutation({
            variables:{
                id: id,
                postulante: data?.findOffLimitById?.postulante?.id,
                fechaFin: endDate,
                fechaInicio: startDate,
                observaciones: observaciones
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteOffLimitMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                id={id}
                customHeader={
                    data?.findOffLimitById?.estado?
                        <span className={"badge ml-2 badge-"+(data?.findOffLimitById?.estado==="ACTIVO"?"success":"danger")}>{data?.findOffLimitById?.estado}</span>
                    :
                        null
                }
                entityName="offLimit"
                handleSubmit={handleSubmit}
                columnsCount="1"
                record={data?.findOffLimitById}
                values={[
                    {
                        name: "periodo",
                        type: "dateRange",
                        maxLength: 100,
                        setStartDate: setStartDate,
                        startDate: startDate,
                        setEndDate: setEndDate,
                        endDate: endDate,
                        validationRulesStart:{
                            required: true
                        },
                        validationMessagesStart:{
                            required: t("common.message.field.required", {0: t("offLimit.field.periodoDesde")}),
                        },
                        validationMessagesEnd:{
                            required: t("common.message.field.required", {0: t("offLimit.field.periodoHasta")}),
                        },
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-5 col-md-3 col-xs-12",
                    },
                    {
                        type: "custom",
                        name: "postulante",
                        render: ()=>{
                            return(
                                <div className="form-group row">
                                    <label htmlFor="filter-cliente" className="parrish-label col-sm-4 col-xs-12">{t("offLimit.field.postulante")}</label>
                                    <div className="col">
                                        <input autoComplete="off" disabled className="parrish-input" defaultValue={postulanteLabel}/>
                                    </div>
                                </div>
                            );
                        }
                    },
                    {
                        type: "textarea",
                        name: "observaciones",
                        value: observaciones,
                        setValue: setObservaciones,
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-4 col-xs-12"
                    }
                ]}
                deleteAction={deleteAction}
                returnUrl="/offLimit"
                >
            </CardEditEntity>
        </>
    )
}

export default OffLimitEdit