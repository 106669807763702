import React, { useEffect } from 'react'
import ConfigData from "../../../config.json"
import logoImg from '../../../assets/images/logo.png'
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import SecurityPageWrapper from './SecurityPageWrapper';

const EMAIL_CONFIRMATION = gql`
    mutation emailConfirmation(
        $token: String!
    ){
        emailConfirmation(token: $token){
            infoCode
            infoMessage
        }
    }
`;

export default function ExpiredPassword() {
    const [t] = useTranslation("global");

    const [emailConfirmationMutation,{data, error, loading}] = useMutation(EMAIL_CONFIRMATION,{
        variables:{
            token: "test"
        },
        onCompleted: (data) => {
        },
        onError: (error) => {
        }
    });

    useEffect(() => {
        emailConfirmationMutation();
    }, [emailConfirmationMutation])

    return (!loading &&
        <SecurityPageWrapper>
            <div className="">
                <div className="card">
                    <div className="card-header text-align-center mt-3">
                        <img src={logoImg} alt={ConfigData.APP_TITLE_NAME}/>
                    </div>
                    <div className="card-body login-card-body">
                        {!loading&&
                            <>
                                <>
                                    
                                </>
                                <>
                                {!error&&data&&
                                    <div className="alert alert-success alert-dismissible">
                                        <h5><i className="icon fas fa-check"></i> {data.emailConfirmation.successResponse.infoMessage}</h5>
                                    </div>
                                }
                                </>
                            </>
                        }
                        <hr/>
                        <div className="row">
                            <div className="col-12">
                                <Link className="btn btn-info btn-block" to="/login" onClick={()=>{}}>{t("common.button.aceptar")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-align-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-align-center">powered by <a className="text-info" href="http://www.parrish.com.ar/">Parrish Software Factory</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </SecurityPageWrapper>
    )
}
