import {  gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import $ from "jquery"

const FIND_PROYECTO_POSTULANTE_LIST_BY_PROYECTO_ALIAS = gql`
    query findProyectoPostulanteListByProyectoAlias(
        $proyecto: Int!
        $alias: String!
    ) {
        findProyectoPostulanteListByProyectoAlias(
            proyecto: $proyecto
            alias: $alias
    ) {
        id
        ultimaActividad
        signOff
        proyecto{
            id
            alias
        }
        estado{
            id
            desiste
            esFinal
            esInicial
            nombre
        }
        etapa{
            id
            orden
            nombre
        }
        postulante{
            id                   
            contacto{
                id
                alias
                email
                telefonosFijos
                telefonosMoviles
                fechaNacimiento
                linkedin
                foto
                personaFisica
            }
        }
        prioridad{
            id
            nombre
            color
        }
        responsableList{
            contacto{
                alias
            }
        }
    }
}
`;

export default function SearchAndGo(props) {
    const {proyectoId, go} = props;
    
    const [t] = useTranslation("global");
    const [value, setValue] = useState();
    const [suggestions, setSuggestions] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");

    const [selectedRow, setSelectedRow] = useState("");

    useEffect(() => {
        if(!value){
            setSelectedValue("");
        }
    }, [value]);
    
    const [getSuggestions] = useLazyQuery(FIND_PROYECTO_POSTULANTE_LIST_BY_PROYECTO_ALIAS, {
        onCompleted: (data) => {
            setSuggestions(data?.findProyectoPostulanteListByProyectoAlias);
        }
    });

    const onBlur = (event, { highlightedSuggestion }) => {
        setSelectedValue("");
        setValue("", "");
    }

    const onChange = (event, { newValue, method }) => {
        
        if(method==="down"||method==="up"){
            setSelectedRow(newValue.id);
        }else{
            if(newValue!==""){
                setSelectedValue(newValue);
            }else{
                setSelectedValue("");
                setValue("", "");
            }
        }

    };
    
    const onSuggestionsFetchRequested = ({ value }) => {
        console.log("onSuggestionsFetchRequested");
        getSuggestions({ variables: {
            alias: value,
            proyecto: proyectoId
        }})
    };

    const onSuggestionsClearRequested = () => {
        console.log("onSuggestionsClearRequested");
        setSuggestions([]);
    };

    const getSuggestionValue = (selectedValue) => {
        return selectedValue;
    };

    const inputProps = {
        name: "quicksearch-value",
        value: selectedValue,
        onBlur: onBlur,
        onChange: onChange,
        placeholder: t("proyecto.label.searchAndGo")
    };

    const onSuggestionSelected = (event,{ suggestion }) => {
        console.log("onSuggestionSelected");
        setSelectedValue("");
        setValue("", "");
        go(suggestion);
    };

    function shouldRenderSuggestions(value, reason) {
        if(typeof value === 'string'){
            return value?.trim().length > 2;
        }else{
            return false;
        }
    }

    function escapeRegex(string) {
        // eslint-disable-next-line no-useless-escape
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    useEffect(() => {
        if(selectedRow){
            $("#search-and-go .active-element")[0]?.scrollIntoView(false);
        }
    }, [selectedRow])
    
    
    const renderSuggestion = (suggestion, { query }) => {
        const reg = new RegExp('('+escapeRegex(query)+')', 'gi');
        const postulante = suggestion?.postulante?.contacto?.alias;
        const etapa = suggestion?.etapa?.nombre;
        const parts = postulante?.split(reg).map((part)=>{
            return {
                text: part,
                highlight: reg.test(part)
            }
        })
        return (
            <div className={"suggestion-content w-100"}>
                <div className={"text-dark text-nowrap border-0 p-2 w-100 "+((suggestion.id)===selectedRow?" bg-light-gray active-element":"")} role="button" onMouseEnter={()=>{setSelectedRow(suggestion.id)}}>
                    <span>[{suggestion.postulante.id}] - {
                parts.map((part, index) => {
                  return (
                    <span className={part.highlight?'highlight':''} key={index}>{part.text}</span>
                  );
                })
              }</span><span key={suggestion.id} className={'badge ml-1 bg-info'}>{etapa}</span>
                </div>
            </div>
        );
    }

    const theme = {
        container:                'w-100 react-autosuggest__container',
        containerOpen:            'w-100 react-autosuggest__container--open',
        input:                    'parrish-input',
        inputOpen:                'react-autosuggest__input--open',
        inputFocused:             'react-autosuggest__input--focused',
        suggestionsContainer:     'react-autosuggest__suggestions-container w-auto',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList:          'react-autosuggest__suggestions-list ',
        suggestion:               'm-0 border-0',
        suggestionFirst:          'react-autosuggest__suggestion--first',
        suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
        sectionContainer:         'react-autosuggest__section-container border-primary',
        sectionContainerFirst:    'react-autosuggest__section-container--first',
        sectionTitle:             'react-autosuggest__section-title'
    }

    return(
        <Autosuggest
            theme={theme}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            shouldRenderSuggestions={shouldRenderSuggestions}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={onSuggestionSelected}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}/>
    );
}
