import React from 'react'
import background from "../../../assets/images/background.jpg";

export default function SecurityPageWrapper({children}) {
    return (
        <div className="login-page" style={{backgroundImage: `url(${background})`}}>
            {children}
        </div>
    )
}
