import React, { useContext } from 'react'
import { useQuery, gql } from '@apollo/client';
import AppStateContext from "./../context/AuthContext.js";

const GET_AVATAR = gql`
    query findContactoAvatar($idContacto: Int!, $avatarSize: Int!){
        findContactoAvatar(
            idContacto: $idContacto
            avatarSize: $avatarSize
        )
    }
`;

export default function UserAvatarImg(props) {
    const { size } = props;
    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();
    
    const {loading, error, data } = useQuery(GET_AVATAR,
    {
        variables: {
            idContacto: userData?.usuario?.contacto?.id,
            avatarSize: size?size:200
        },
        skip: userData?.usuario?.contacto?.id==null
    });
 
    return (
        !loading&&
            <img {...props} src={!error&&data?.findContactoAvatar?data.findContactoAvatar:'/img/unknown-user.png'} alt={props.alt?props.alt:"graphqlImage"}/>
    )
}
