import React, { useState, useEffect, useRef } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../../components/CardEditEntity';

const PROVINCIA_SEARCH_BY_ID = gql`
    query findProvinciaById(
        $id: Int!
    ) {
    findProvinciaById(
        id: $id
    ){
        id
        nombre
        pais{
            id
            nombre
        }
    }
}
`;

const UPDATE_PROVINCIA_MUTATION = gql`
    mutation updateProvincia(
        $id: ID!
        $pais: Int!
        $nombre: String!
    ) {
        updateProvincia(entity:{
            id: $id
            pais: $pais
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

const DELETE_PROVINCIA_MUTATION = gql`
    mutation deleteProvincia(
        $id: Int!
    ) {
        deleteProvincia(id: $id)
    }
`;

function ProvinciaEdit(props) {
    const { setCrudMode, editId } = props;
    let { showConfirmDelete, showMessageWithTimerAndExecute } = useAlertMessage();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    
    const {loading, error, data } = useQuery(
        PROVINCIA_SEARCH_BY_ID,
        {
            variables: {
                id: editId
            }
        }
    );

    //const returnUrl = "/pais/edit/"+data?.findProvinciaById?.pais.id;
        
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findProvinciaById?.nombre?data.findProvinciaById.nombre:"");
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findProvinciaById?.codigo,
        data?.findProvinciaById?.nombre
    ])

    const [updateProvinciaMutation] = useMutation(UPDATE_PROVINCIA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1500, ()=>{setCrudMode("list")});
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteProvinciaMutation] = useMutation(DELETE_PROVINCIA_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1500, ()=>{setCrudMode("list")});
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateProvinciaMutation({
            variables:{
                id: editId,
                pais: data?.findProvinciaById?.pais.id,
                nombre: nombre
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteProvinciaMutation({
                variables:{
                    id: parseInt(id)
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardEditEntity
            entityName="provincia"
            className="m-0 card-no-shadow"
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "nombre",
                    type: "text",
                    maxLength: 50,
                    value: nombre,
                    setValue: setNombre,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("provincia.field.nombre")})
                    }
                },
                {
                    name: "pais",
                    translationKey: "provincia.field.pais",
                    value: data?.findProvinciaById?.pais.nombre,
                    type: "text",
                    disabled: true
                }
            ]}
            returnAction={()=>{setCrudMode("list")}}
            id={editId}
            label={nombre}
            deleteAction={deleteAction}
            loading={loading}
            error={error}
            >
        </CardEditEntity>
    )
}

export default ProvinciaEdit