import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { gql, useMutation, useQuery} from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import useAlertMessage from '../../../../hooks/useAlertMessage';
import { useTranslation } from 'react-i18next';
import DetalleTab from './tabs/detalle';
import {useCallback} from 'react';
import SidebarContext from '../../../../context/SidebarContext';
import CardFooterRecordData from '../../../../components/CardFooterRecordData';
import AdjuntosTab from './tabs/adjuntos';
import ComercialAdministrativoTab from './tabs/comercialAdministrativo/comercialAdminitrativo';
import QaTab from './tabs/qa';
import FollowTab from './tabs/follow';
import EtapaTab from './tabs/etapa';
import PlacementsTab from './tabs/placements';
import DatePicker from '../../../../components/DatePicker';
import BuscarPostulanteTab from './tabs/buscarPostulante';
import FlagsProyecto from '../../../../components/FlagsProyecto';
import ContactoAvatarImg from '../../../../components/ContactoAvatarImg';
import moment from 'moment';
import SearchAndGo from './searchAndGo/searchAndGo';
import ProyectoStatusControl from '../../../../components/ProyectoStatusControl';
import ESTADO_PROYECTO from '../../../../enums/EstadoProyecto';
import {} from 'admin-lte/plugins/bootstrap/js/bootstrap';
import ETAPA_PROYECTO from '../../../../enums/EtapaProyecto';
import DoublePlacementDialog from './dialogs/doublePlacementDialog';
import SecuredSection from '../../../../components/SecuredSection';
import PERMISOS_ESPECIALES from '../../../../enums/PermisosEspeciales';
import { TabContent, TabPane, NavLink, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import { DateRfc }  from '../../DateRfc';

const PROYECTO_SEARCH_BY_ID = gql`
    query findProyectoById(
        $id: Int!
    ) {
    findProyectoById(
        id: $id
    ){
        id
        alias
        fechaCierreAdministrativo
        fechaCierreBusqueda
        fechaCierreQa
        fechaInicioBusqueda
        fechaIngresoPlacement
        beneficios
        bonus
        descripcion
        filtro
        posicion
        ref
        acuerdoInicial{
            id
            fechaOriginacion
            beneficiosAdicionales
            bonus
            hiringBonus
            mesesAnualizacion
            modo
            retainer
            sueldoBrutoMensual
            feeStandard
            feePlusB
            tipoBonus
            tipoHiringBonus
            comentario
            tipo{
                id
                nombre
            }
            moneda{
                id
                simbolo
            }
            totales{
                sueldoBrutoTotal
                bonusTotal
                hiringBonusTotal
                remuneracionTotal
                remuneracionInicialAcordada
                diferenciaPlusB
                honorarioStandard
                honorarioPlusB
                honorarioFinal
            }
            postulante{
                id
                contacto{
                    alias
                }
            }
        }
        placementList{
            id
            label
        }
        fechaOriginacionPlacement
        empresa{
            id
            contacto{
                id
                alias
                foto
                personaFisica
            }
        }
        asociadoList{
            id
            contacto{
                id
                alias
            }
        }
        socia{
            id
            contacto{
                id
                alias
            }
        }
        tipoProyecto{
            id
        }
        pais{
            id
        }
        cargo{
            id
            nombre
        }
        areaEmpresa{
            id
            nombre
        }
        estado{
            id
            nombre
            color
        }
        contacto{
            id
            alias
            email
            telefonosFijos
            telefonosMoviles
        }
        qaInfo{
            indice
            recomendaciones
        }
        followUp{
            source{
                etapa
                label
                promedio
                descartados
                activos
                porcentageDescartados
                porcentageActivos
            }
            identificado{
                etapa
                label
                promedio
                descartados
                activos
                porcentageDescartados
                porcentageActivos
            }
            prospecto{
                etapa
                label
                promedio
                descartados
                activos
                porcentageDescartados
                porcentageActivos
            }
            candidato{
                etapa
                label
                promedio
                descartados
                activos
                porcentageDescartados
                porcentageActivos
            }
        }
        adjuntoList{
            referencia
            fechaActualizacion
            contentType
            nombreOriginal
            nombreServidor
            responsable{
                contacto{
                    alias
                }
            }
        }
    }
}
`;

const UPDATE_PROYECTO_MUTATION = gql`
    mutation updateProyecto(
        $id: ID!
        $tipoProyecto: Int!
        $empresa: Int!
        $areaEmpresa: Int!
        $cargo: Int!
        $posicion: String!
        $contacto: Int!
        $pais: Int!
        $socia: Int!
        $fechaInicioBusqueda: LocalDate!
        $moneda: Int!
        $sueldoBrutoMensual: Int!
        $beneficios: String
        $bonus: String
        $descripcion: String
        $filtro: String
    ) {
        updateProyecto(entity:{
            id: $id
            tipoProyecto: $tipoProyecto
            empresa: $empresa
            areaEmpresa: $areaEmpresa
            cargo: $cargo
            posicion: $posicion
            contacto: $contacto
            pais: $pais
            socia: $socia
            fechaInicioBusqueda: $fechaInicioBusqueda
            moneda: $moneda
            sueldoBrutoMensual: $sueldoBrutoMensual
            beneficios: $beneficios
            bonus: $bonus
            descripcion: $descripcion
            filtro: $filtro
        }) {
            id
        }
    }
`;

function ProyectoEdit() {
    const [activeTab, setActiveTab] = useState('custom-tabs-info-adicional-tab');

    const selectTab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    const history = useHistory();
    let { id } = useParams();
    let location = useLocation();
    
    const [t] = useTranslation("global");

    const { setSideBarState, show } = useContext(SidebarContext);

    const [postulanteFilter, setPostulanteFilter] = useState();
    const [postulanteLabel, setPostulanteLabel] = useState();
    
    const clearPostulanteFilter = ()=>{
        setPostulanteLabel();
        setPostulanteFilter();
    }

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if(refresh){
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        if(id){
            selectTab('custom-tabs-info-adicional-tab');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const {loading, error, data, refetch } = useQuery(
        PROYECTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [state, setState] = useState({
        id: "",
        tipoProyecto: "",
        empresa: "",
        areaEmpresa: "",
        cargo: "",
        posicion: "",
        contacto: "",
        pais: "",
        socia: "",
        fechaInicioBusqueda: "",
        fechaIngreso: "",
        moneda: "",
        sueldoBrutoMensual: "",
        beneficios: "",
        bonus: "",
        descripcion: "",
        filtro: ""
    });

    let { showErrorToast } = useAlertMessage();

    const ref = useRef();

    const setStateValue = useCallback((name, value) =>{
        setState({
            ...state,
            [name]: value
        });
        console.log(name, value, state);
    }, [state]);

    const mergeState = useCallback((stateData) =>{
        setState({
            ...state,
            ...stateData
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleChange(name, evt) {
        const value = evt.target.value;
        setStateValue(name, value);
    }

    const isActivo = ()=>{
        return parseInt(data?.findProyectoById?.estado?.id) === ESTADO_PROYECTO.ACTIVO;
    }

    const isProspecto = ()=>{
        return parseInt(data?.findProyectoById?.estado?.id) === ESTADO_PROYECTO.PROSPECTO;
    }

    const isCanceladoSuspendidoOTerminado = ()=>{
        return parseInt(data?.findProyectoById?.estado?.id) === ESTADO_PROYECTO.CANCELADO ||
            parseInt(data?.findProyectoById?.estado?.id) === ESTADO_PROYECTO.SUSPENDIDO ||
            parseInt(data?.findProyectoById?.estado?.id) === ESTADO_PROYECTO.TERMINADO;
    }

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-tipoProyecto": "required",
                "value-fecha-inicio": "required",
                "value-socia": "required",
                "value-contacto": "required",
                "value-empresa": "required",
                "value-area-empresa": "required",
                "value-cargo": "required",
                "value-posicion": "required",
                "value-pais": "required",
                "value-beneficios": "required",
                "value-bonus": "required",
                "value-asociados-list": "required"
            },
            messages: {
                "value-tipoProyecto": {
                    required: t("common.message.field.required", {0: t("proyecto.field.tipoProyecto")})
                },
                "value-fecha-inicio": {
                    required: t("common.message.field.required", {0: t("proyecto.field.fechaInicioBusqueda")})
                },
                "value-socia": {
                    required: t("common.message.field.required", {0: t("proyecto.field.socia")})
                },
                "value-contacto": {
                    required: t("common.message.field.required", {0: t("proyecto.field.contacto")})
                },
                "value-empresa": {
                    required: t("common.message.field.required", {0: t("proyecto.field.empresa")})
                },
                "value-area-empresa": {
                    required: t("common.message.field.required", {0: t("proyecto.field.areaEmpresa")})
                },
                "value-cargo": {
                    required: t("common.message.field.required", {0: t("proyecto.field.cargo")})
                },
                "value-posicion": {
                    required: t("common.message.field.required", {0: t("proyecto.field.posicion")})
                },
                "value-pais": {
                    required: t("common.message.field.required", {0: t("proyecto.field.pais")})
                },
                "value-beneficios": {
                    required: t("common.message.field.required", {0: t("proyecto.field.beneficios")})
                },
                "value-bonus": {
                    required: t("common.message.field.required", {0: t("proyecto.field.bonus")})
                },
                "value-asociados-list": {
                    required: t("common.message.field.required", {0: t("proyecto.field.asociadoList")})
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    useEffect(() => {
        if(!loading && !error && data?.findProyectoById){
            const proyecto = data?.findProyectoById;
            let stateData = {};
            stateData.fechaPlacement = proyecto.fechaOriginacionPlacement?new DateRfc(proyecto.fechaOriginacionPlacement):null;
            stateData.nombre = proyecto.contacto.nombre?proyecto.contacto.nombre:"";
            stateData.tipoProyecto = proyecto.tipoProyecto?parseInt(proyecto.tipoProyecto?.id):null;
            stateData.empresa = proyecto.empresa?parseInt(proyecto.empresa?.id):null;
            stateData.areaEmpresa = proyecto.areaEmpresa?parseInt(proyecto.areaEmpresa?.id):null;
            stateData.cargo = proyecto.cargo?parseInt(proyecto.cargo?.id):null;
            stateData.posicion = proyecto.posicion?proyecto.posicion:null;
            stateData.contacto = proyecto.contacto?parseInt(proyecto.contacto.id):null;
            stateData.pais = proyecto.pais?parseInt(proyecto.pais.id):null;
            stateData.socia = proyecto.socia?parseInt(proyecto.socia?.id):null;
            stateData.fechaInicioBusqueda = proyecto.fechaInicioBusqueda?new DateRfc(proyecto.fechaInicioBusqueda):null;
            stateData.fechaCierreAdministrativo = proyecto.fechaCierreAdministrativo?new DateRfc(proyecto.fechaCierreAdministrativo):null;
            stateData.fechaCierreBusqueda= proyecto.fechaCierreBusqueda?new DateRfc(proyecto.fechaCierreBusqueda):null;
            stateData.fechaCierreQa= proyecto.fechaCierreQa?new DateRfc(proyecto.fechaCierreQa):null;
            stateData.fechaIngresoPlacement = proyecto.fechaIngresoPlacement?new DateRfc(proyecto.fechaIngresoPlacement):null;
            stateData.moneda = proyecto.acuerdoInicial?.moneda?parseInt(proyecto.acuerdoInicial?.moneda?.id):null;
            stateData.sueldoBrutoMensual = proyecto.acuerdoInicial?.sueldoBrutoMensual?parseFloat(proyecto.acuerdoInicial?.sueldoBrutoMensual):null;
            stateData.beneficios = proyecto.beneficios?proyecto.beneficios:null;
            stateData.bonus = proyecto.bonus?proyecto.bonus:null;
            stateData.descripcion = proyecto.descripcion?proyecto.descripcion:null;
            stateData.filtro = proyecto.filtro?proyecto.filtro:null;
            mergeState(stateData);
        }
    }, [data?.findProyectoById, error, loading, mergeState]);

    const [updateProyectoMutation] = useMutation(UPDATE_PROYECTO_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateProyectoMutation({
            variables:{
                id: id,
                tipoProyecto: state.tipoProyecto?state.tipoProyecto:null,
                empresa: state.empresa?state.empresa:null,
                areaEmpresa: state.areaEmpresa?state.areaEmpresa:null,
                cargo: state.cargo?state.cargo:null,
                posicion: state.posicion?state.posicion:null,
                contacto: state.contacto?state.contacto:null,
                pais: state.pais?state.pais:null,
                socia: state.socia?state.socia:null,
                fechaInicioBusqueda: state.fechaInicioBusqueda?state.fechaInicioBusqueda:null,
                moneda: state.moneda?state.moneda:null,
                sueldoBrutoMensual: state.sueldoBrutoMensual?state.sueldoBrutoMensual:null,
                beneficios: state.beneficios?state.beneficios:null,
                bonus: state.bonus?state.bonus:null,
                descripcion: state.descripcion?state.descripcion:null,
                filtro: state.filtro?state.filtro:null
           }
        })
    }

    const from = moment(data?.findProyectoById?.fechaInicioBusqueda);
    const to = data?.findProyectoById?.fechaOriginacionPlacement?moment(data?.findProyectoById?.fechaOriginacionPlacement):moment();
    const days = to.diff(from, 'days');
    const dayLabel = Math.abs(days)===1?t("common.label.dia"):t("common.label.dias");
    const textDesde = (days<0?(t("common.label.hace")+" "):"")+Math.abs(days)+" "+dayLabel;

    useEffect(() => {
        let selectedTab = location?.hash?(location?.hash.replace("#","")+"-tab"):null;
        if(selectedTab){
            if(activeTab !== selectedTab) setActiveTab(selectedTab);
        }
    }, [activeTab, location?.hash]);

    const completePlacement = ()=>{
        setTimeout(() => {    
            selectTab("custom-tabs-placements-tab");
        }, 100);
    }

    const completeCancelPlacement = ()=>{
        setTimeout(() => {
            refetch();
            selectTab("custom-tabs-etapa-tab");
        }, 100);
    }
    
    return (!loading && !error && data?.findProyectoById?
        <>
            <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                <input type="hidden" name="value-empresa" value={state.empresa} />
                <input type="hidden" name="value-area-empresa" value={state.areaEmpresa} />
                <input type="hidden" name="value-cargo" value={state.cargo} />
                <input type="hidden" name="value-posicion" value={state.posicion} />
                <input type="hidden" name="value-pais" value={state.pais} />
                <input type="hidden" name="value-beneficios" value={state.beneficios} />
                <input type="hidden" name="value-bonus" value={state.bonus} />
                <input type="hidden" name="value-asociados-list" value={data?.findProyectoById?.asociadoList} />
                {JSON.stringify(state.asociadoList)}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 pl-0 d-flex align-items-stretch">
                            <div className="card card-info card-outline w-100">
                                <div className="card-body box-profile d-flex align-items-center flex-fill">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="row">
                                            <div className="col d-flex align-items-center justify-content-center flex-wrap">
                                                <div className="text-center d-flex flex-column mt-3 mb-3 m-2">
                                                    <div className="text-center d-flex flex-row align-items-center justify-content-center flex-fill">
                                                        <FlagsProyecto proyecto={data?.findProyectoById}/>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <p className={"badge mt-2 text-uppercase badge-"+data?.findProyectoById?.estado.color+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.bus")}: </span>
                                                            {data?.findProyectoById?.estado.nombre}
                                                        </p>
                                                        <p className={"badge mt-1 text-uppercase badge-"+(data?.findProyectoById?.fechaCierreAdministrativo ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.adm")}: </span>
                                                            {data?.findProyectoById?.fechaCierreAdministrativo ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    
                                                        <p className={"badge mt-1 text-uppercase badge-"+(data?.findProyectoById?.fechaCierreQa ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.qa")}: </span>
                                                            {data?.findProyectoById?.fechaCierreQa ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-center flex-fill m-2">
                                                    <ContactoAvatarImg
                                                        personaFisica={data?.findProyectoById?.empresa?.contacto?.personaFisica}
                                                        id={data?.findProyectoById?.empresa.contacto.id}
                                                        foto={data?.findProyectoById?.empresa.contacto.foto}
                                                        className="profile-user-img postulante-table-avatar img-fluid img-circle border-info"
                                                        alt="User profile picture"/>
                                                        <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={data?.findProyectoById?.qaInfo?.recomendaciones?data?.findProyectoById?.qaInfo?.recomendaciones:null}>
                                                            <span className="h5 text-nowrap">{t("common.label.qa")} {data?.findProyectoById?.qaInfo.indice}%</span>
                                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h5>{data?.findProyectoById?.ref}</h5>
                                            </div>
                                                
                                        </div>
                                        <ul className="list-group list-group-unbordered mt-2 mb-3 w-100">
                                            <li className="list-group-item p-1">
                                                <b>{t("proyecto.field.duracion")}</b>
                                                <span className="float-right">
                                                    {textDesde}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 pr-0 d-flex flex-column align-items-stretch">

                            <div className={"card card-outline card-info flex-fill"}>
                                {/*
                                    loading &&
                                    !error && (
                                        <div className="overlay">
                                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                                        </div>
                                    )
                                */}
                                <div className="card-header">
                                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: id })}
                                    {data?.findProyectoById?.cliente &&
                                        <div className="rounded p-1 d-flex justify-content-center align-items-center shadow float-right bg-info ml-2">
                                            <img src="/icons/cliente_24.png" alt={t("proyecto.field.cliente")} height="18" width="18" className="brand-image ml-1 mr-1" />
                                        </div>
                                    }
                                    {data?.findProyectoById?.tieneProyectoActivo &&
                                        <div className="rounded p-1 d-flex justify-content-center align-items-center shadow float-right bg-info ml-2">
                                            <i className="fab fa-searchengin"></i>
                                        </div>
                                    }
                                    </h3>
                                    <div className="card-tools m-0">
                                        <div className="d-flex justify-content-center align-items-start m-0">
                                            <div>
                                                <SearchAndGo proyectoId={data?.findProyectoById?.id} go={(suggestion)=>{                
                                                    if(suggestion?.etapa && parseInt(suggestion.etapa.id) === ETAPA_PROYECTO.PLACEMENT){
                                                        //$('#edit-proyecto-tab a[href="#custom-tabs-placements"]').tab('show');
                                                        selectTab("custom-tabs-placements-tab");
                                                    }else{
                                                        setPostulanteFilter(suggestion?.postulante?.id)
                                                        setPostulanteLabel(suggestion?.postulante?.contacto?.alias)
                                                        //$('#edit-proyecto-tab a[href="#custom-tabs-etapa"]').tab('show');
                                                        selectTab("custom-tabs-etapa-tab");
                                                    }
                                                }} />
                                            </div>
                                            <div>
                                                {isCanceladoSuspendidoOTerminado()&&
                                                    <DoublePlacementDialog proyecto={data?.findProyectoById} refetch={()=>{
                                                        console.log("refetch....")
                                                        refetch();
                                                        selectTab("custom-tabs-placements-tab");
                                                        //$('#edit-proyecto-tab a[href="#custom-tabs-placements"]').tab('show');
                                                    }} />
                                                }
                                                <ProyectoStatusControl proyecto={data?.findProyectoById} refetch={()=>{refetch(); setRefresh(true); /*refreshSelectTab();*/}}/>
                                                <button type="button" className="btn btn-xs bg-yellow btn-tool ml-2" onClick={()=>{
                                                    setSideBarState({
                                                        mode: "seguimiento",
                                                        proyecto: id,
                                                        labelProyecto: data?.findProyectoById?.alias,
                                                        labelPostulante: null,
                                                        labelEmpresa: null,
                                                        postulante: null,
                                                        empresa: null,
                                                        responsable: null,
                                                        accionSeguimiento: null,
                                                    })
                                                    show();
                                                }}>
                                                    <i className="fas fa-shoe-prints fa-rotate-270"></i>
                                                </button>
                                                <button type="button" className="btn btn-xs bg-yellow btn-tool ml-2" onClick={()=>{
                                                    setSideBarState({
                                                        mode: "referencias",
                                                        labelProyecto: data?.findProyectoById?.alias,
                                                        proyecto: id,
                                                        labelPostulante: null,
                                                        postulante: null,
                                                        labelEmpresa: null,
                                                        empresa: null
                                                    })
                                                    show();
                                                }}>
                                                    <i className="fas fa-user-secret"></i>
                                                </button>
                                                {(isProspecto() || isActivo())&&
                                                    <button type="submit" className="btn btn-xs bg-info btn-tool ml-2">
                                                        <i className="fas fa-save"></i>
                                                    </button>
                                                }
                                                <button className="btn btn-xs bg-info btn-tool ml-2" type="button" onClick={()=>{history.goBack()}}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="parrish-form row">

                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="value-tipoProyecto" className="parrish-label col-2 required">{t("proyecto.field.tipoProyecto")}</label>
                                                <div className="col-10">
                                                    <SelectKeyValue
                                                        entity="TipoProyecto"
                                                        label="nombre"
                                                        name={'value-tipoProyecto'}
                                                        value={state.tipoProyecto}
                                                        required
                                                        onChange={(e) => {
                                                            handleChange("tipoProyecto", e);
                                                        }}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-fecha-inicio" className="parrish-label col-2 required">{t("proyecto.field.fechaInicioBusqueda")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        name="value-fecha-inicio"
                                                        selected={state.fechaInicioBusqueda}
                                                        onChange={date => handleChange("fechaInicioBusqueda", {target:{value:date}})}/>
                                                </div>
                                                <label htmlFor="value-fecha-cierre-busqueda" className="parrish-label col-2">{t("proyecto.field.fechaCierreBusqueda")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        disabled={true}
                                                        name="value-fecha-cierre-busqueda"
                                                        selected={state.fechaCierreBusqueda}
                                                        onChange={date => handleChange("fechaCierreBusqueda", {target:{value:date}})}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-fecha-placement" className="parrish-label col-2">{t("proyecto.field.fechaPlacement")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        disabled
                                                        name="value-fecha-placement"
                                                        selected={state.fechaPlacement}/>
                                                </div>
                                                <label htmlFor="value-fecha-ingeso" className="parrish-label col-2">{t("proyecto.field.fechaIngreso")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        disabled={true}
                                                        name="value-fecha-ingeso"
                                                        selected={state.fechaIngresoPlacement}
                                                        onChange={date => handleChange("fechaIngresoPlacement", {target:{value:date}})}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="value-fecha-cierre-adm" className="parrish-label col-2">{t("proyecto.field.fechaCierreAdministrativo")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        disabled={true}
                                                        name="value-fecha-cierre-adm"
                                                        selected={state.fechaCierreAdministrativo}
                                                        onChange={date => handleChange("fechaCierreAdministrativo", {target:{value:date}})}/>
                                                </div>
                                                <label htmlFor="value-fecha-cierre-qa" className="parrish-label col-2">{t("proyecto.field.fechaCierreQa")}</label>
                                                <div className="col-4">
                                                    <DatePicker
                                                        disabled={true}
                                                        name="value-fecha-cierre-qa"
                                                        selected={state.fechaCierreQa}
                                                        onChange={date => handleChange("fechaCierreQa", {target:{value:date}})}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <FollowTab followUp={data?.findProyectoById?.followUp}/>
                                        </div>
                                    </div>
                                </div>
                                <CardFooterRecordData record={data?.findProyectoById}/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {!refresh&&
                <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                    <div className="card-header p-0 border-bottom-0 ">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    role="button"
                                    className={classnames({ active: activeTab === 'custom-tabs-info-adicional-tab' })+" link-muted text-uppercase"}
                                    onClick={() => { selectTab('custom-tabs-info-adicional-tab'); }}>
                                    {t("proyecto.label.detalle")}
                                </NavLink>
                            </NavItem>
                            <SecuredSection idPermiso={PERMISOS_ESPECIALES.ACCESO_COMERCIAL_ADMINISTRATIVO}>
                                <NavItem>
                                    <NavLink
                                        role="button"
                                        className={classnames({ active: activeTab === 'custom-tabs-comercial-administrativo-tab' })+" link-muted text-uppercase"}
                                        onClick={() => { selectTab('custom-tabs-comercial-administrativo-tab'); }}>
                                        {t("proyecto.label.comercialAdministrativo")}
                                    </NavLink>
                                </NavItem>
                            </SecuredSection>
                            {(isActivo()||isProspecto())&&
                                <NavItem>
                                    <NavLink
                                        role="button"
                                        className={classnames({ active: activeTab === 'custom-tabs-buscar-tab' })+" link-muted text-uppercase"}
                                        onClick={() => { selectTab('custom-tabs-buscar-tab'); }}>
                                        {t("proyecto.label.buscar")}
                                    </NavLink>
                                </NavItem>
                            }
                            <NavItem>
                                <NavLink
                                    role="button"
                                    className={classnames({ active: activeTab === 'custom-tabs-etapa-tab' })+" link-muted text-uppercase"}
                                    onClick={() => { selectTab('custom-tabs-etapa-tab'); }}>
                                    {t("proyecto.label.etapa")}
                                </NavLink>
                            </NavItem>
                            { data?.findProyectoById?.placementList?.length>0 &&
                                <NavItem>
                                    <NavLink
                                        role="button"
                                        className={classnames({ active: activeTab === 'custom-tabs-placements-tab' })+" link-muted text-uppercase"}
                                        onClick={() => { selectTab('custom-tabs-placements-tab'); }}>
                                        {t("proyecto.label.placements")}
                                    </NavLink>
                                </NavItem>
                            }
                            <NavItem>
                                <NavLink
                                    role="button"
                                    className={classnames({ active: activeTab === 'custom-tabs-qa-tab' })+" link-muted text-uppercase"}
                                    onClick={() => { selectTab('custom-tabs-qa-tab'); }}>
                                    {t("proyecto.label.qa")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    role="button"
                                    className={classnames({ active: activeTab === 'custom-tabs-adjuntos-tab' })+" link-muted text-uppercase"}
                                    onClick={() => { selectTab('custom-tabs-adjuntos-tab'); }}>
                                    {t("proyecto.label.adjuntos")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="card-body bg-white card-shadow-radius">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="custom-tabs-info-adicional-tab">
                                {activeTab==="custom-tabs-info-adicional-tab"&&
                                    <DetalleTab proyecto={data?.findProyectoById} state={state} setState={setState}/>
                                }
                            </TabPane>
                            <SecuredSection idPermiso={PERMISOS_ESPECIALES.ACCESO_COMERCIAL_ADMINISTRATIVO}>
                                <TabPane tabId="custom-tabs-comercial-administrativo-tab">
                                {activeTab==="custom-tabs-comercial-administrativo-tab"&&
                                    <ComercialAdministrativoTab proyecto={data?.findProyectoById} refetch={refetch}/>
                                }
                                </TabPane>
                            </SecuredSection>
                            {(isActivo()||isProspecto())&&
                                <TabPane tabId="custom-tabs-buscar-tab">
                                    {activeTab==="custom-tabs-buscar-tab"&&
                                    <BuscarPostulanteTab idProyecto={id} isActivo={()=>isActivo()}/>
                                }
                                </TabPane>
                            }
                            <TabPane tabId="custom-tabs-etapa-tab">
                            {activeTab==="custom-tabs-etapa-tab"&&
                                <EtapaTab proyecto={data?.findProyectoById} postulanteFilter={postulanteFilter} postulanteLabel={postulanteLabel} clearPostulanteFilter={clearPostulanteFilter} completePlacement={()=>{completePlacement()}} refetch={refetch}/>
                            }
                            </TabPane>
                            <TabPane tabId="custom-tabs-placements-tab">
                            {activeTab==="custom-tabs-placements-tab"&&
                                <PlacementsTab proyecto={data?.findProyectoById} completeCancelPlacement={()=>{completeCancelPlacement()}} placementsCount={data?.findProyectoById?.placementList?.length} refetch={refetch}/>
                            }
                            </TabPane>
                            <TabPane tabId="custom-tabs-qa-tab">
                            {activeTab==="custom-tabs-qa-tab"&&
                                <QaTab idProyecto={id} fechaCierreQa={data?.findProyectoById?.fechaCierreQa} refetch={refetch}/>
                            }    
                            </TabPane>
                            <TabPane tabId="custom-tabs-adjuntos-tab">
                            {activeTab==="custom-tabs-adjuntos-tab"&&
                                <AdjuntosTab idProyecto={id}/>
                            }
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            }
        </>:
        null
    )
}

export default ProyectoEdit