import React from 'react'
import { BrowserRouter } from "react-router-dom";
import SecureWeb from './SecureWeb';

export default function App() {
  return (
    <BrowserRouter>
      <SecureWeb/>
    </BrowserRouter>
  )
}