import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AccionesSeguimiendoSelect from '../../../AccionesSeguimiendoSelect'
import Autocomplete from '../../../Autocomplete';
import { gql, useMutation } from '@apollo/client';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import $ from 'jquery'

const CREATE_SEGUIMIENTO_MUTATION = gql`
    mutation createSeguimiento(
        $empresa: Int
        $postulante: Int
        $proyecto: Int
        $accion: Int!
        $contacto: Int
        $importante: Boolean!
        $comentario: String
    ) {
        createSeguimiento(
            entity:{
                empresa: $empresa
                postulante: $postulante
                proyecto: $proyecto
                accion: $accion
                contacto: $contacto
                importante: $importante
                comentario: $comentario
            }
        ) {
            id
        }
    }
`;

export default function CreateSeguimientoModal(props) {
    const {show, setShow, labelPostulante, labelProyecto, labelEmpresa, idEmpresa, idPostulante, idProyecto, refetch} = props;
    const ref = useRef();
    const [accionSeguimiento, setAccionSeguimiento] = useState();
    const [contacto, setContacto] = useState()
    const [importante, setImportante] = useState(false);
    const [comentario, setComentario] = useState("");
    const [postulante, setPostulante] = useState(idPostulante);
    const [proyecto, setProyecto] = useState(idProyecto);
    const [empresa, setEmpresa] = useState(idEmpresa);

    const [t] = useTranslation("global");
    let { showErrorToast, clearPopUps } = useAlertMessage();

    useEffect(() => {
        if(show){
            setPostulante(idPostulante?idPostulante:"");
            setProyecto(idProyecto?idProyecto:"");
            setEmpresa(idEmpresa?idEmpresa:"");
            setContacto("");
            setAccionSeguimiento("");
            setImportante(false);
            setComentario("");
        }
    }, [idEmpresa, idPostulante, idProyecto, show])

    const [createSeguimientoEmpresaMutation] = useMutation(CREATE_SEGUIMIENTO_MUTATION, {
        onCompleted: (data) => {
            refetch();
            setShow(false);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!accionSeguimiento){
            errorList.push({message: t("common.message.field.required", {0: t("seguimiento.field.accion")})});
        }       
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const saveAction = () =>{
        if(validate()){
            if(!idEmpresa || !idPostulante || !idProyecto){
                createSeguimientoEmpresaMutation({
                    variables:{
                        empresa: empresa?empresa:null,
                        postulante: postulante?postulante:null,
                        proyecto: proyecto?proyecto:null,
                        accion: accionSeguimiento?accionSeguimiento:null,
                        contacto: contacto?contacto:null,
                        importante: importante,
                        comentario: comentario?comentario:null
                    }
                });
            }
        }
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide')
    }, [show])

    return(
        <div ref={ref} className="modal fade" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-3 p-2">
                        <h5 className="modal-title text-dark">{t("common.label.detalle")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShow(false)}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body parrish-form pt-2 pb-2">
                        

                        

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.label.entity")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-postulante"
                                            entity="Postulante"
                                            by="Alias"
                                            placeholder={t("postulante.label.entity")}
                                            filterName="alias"
                                            value={postulante}
                                            initialLabel={labelPostulante}
                                            setValue={(val)=>setPostulante(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.proyecto")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-proyecto"
                                            entity="Proyecto"
                                            by="Alias"
                                            placeholder={t("postulante.field.proyecto")}
                                            filterName="alias"
                                            value={proyecto}
                                            initialLabel={labelProyecto}
                                            setValue={(val)=>setProyecto(val)} /> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.empresa")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-empresa"
                                            entity="Empresa"
                                            by="Alias"
                                            placeholder={t("postulante.field.empresa")}
                                            filterName="alias"
                                            value={empresa}
                                            initialLabel={labelEmpresa}
                                            setValue={(val)=>setEmpresa(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("seguimiento.field.contacto")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-contacto"
                                            entity="Contacto"
                                            by="PersonaFisicaAlias"
                                            filterName="alias"
                                            additionalFilter="personaFisica: true"
                                            value={contacto}
                                            setValue={(value)=>setContacto(value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2 required">{t("seguimiento.field.accion")}</label>
                                    <div className="col-10">
                                        <AccionesSeguimiendoSelect value={accionSeguimiento} setValue={setAccionSeguimiento}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="row mt-2">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="col-12 text-left font-weight-normal">{t("seguimiento.field.comentario")}</label>
                                    <div className="col-12">
                                        <textarea name="value-detalle" value={comentario} onChange={(e)=>{setComentario(e.target.value)}} className="form-control form-control-sm" rows="10"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm text-center">
                                <div className="custom-control custom-checkbox">
                                    <input className="custom-control-input custom-control-input-info" 
                                        type="checkbox" 
                                        id="importante"
                                        checked={importante}
                                        onChange={(e) => setImportante(e.target.checked)} />
                                    <label className="custom-control-label" htmlFor="importante">{t("seguimiento.field.importante")}</label>
                                </div>
                            </div>
                        </div>                       

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn bg-info ml-1" onClick={()=>{saveAction()}}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
