import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import TipoCuentaList from './list'
import TipoCuentaCreate from './create'
import TipoCuentaEdit from './edit'
import { useHistory, useParams } from 'react-router';

function TipoCuentaIndex(){
    const entityName = "tipoCuenta";
    const [searchFilter, setSearchFilter] = useState({
        nombre: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <TipoCuentaList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></TipoCuentaList>
                :
                null
            }
            {
                mode==='create'?
                    <TipoCuentaCreate></TipoCuentaCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <TipoCuentaEdit></TipoCuentaEdit>
                :
                null
            }
        </div>
    );
}

export default TipoCuentaIndex;