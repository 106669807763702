
const ESTADO_ADMINISTRATIVO = {
    REGISTRADO: 1,
    APROBADO: 2,
    EMITIDO: 3,
    IMPACTADO: 4,
    CONCILIADO: 5,
    CANCELADO: 6
}
Object.freeze(ESTADO_ADMINISTRATIVO)

export default ESTADO_ADMINISTRATIVO