import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom";
import { CirclePicker } from 'react-color';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import ReactIconPicker from '../../../../components/ReactIconPicker';

const CREATE_SEGUIMIENTOACCION_MUTATION = gql`
    mutation createSeguimientoAccion(
        $nombre: String!
        $icon: String!
        $color: String!
    ) {
        createSeguimientoAccion(entity:{
            nombre: $nombre
            icon: $icon
            color: $color
        }) {
            id
            nombre
        }
    }
`;

function SeguimientoAccionCreate() {
    const history = useHistory();
    const ref = useRef();

    const [nombre, setNombre] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createSeguimientoAccionMutation] = useMutation(CREATE_SEGUIMIENTOACCION_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/seguimientoAccion";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const getIcon = () =>{
        let iconParam = null;
        if(icon && icon.prefix && icon.iconName){
            iconParam = icon.prefix + ' fa-' + icon.iconName;
        }
        return iconParam;
    }

    const saveAction = () =>{
        createSeguimientoAccionMutation({
            variables:{
                nombre: nombre,
                icon: getIcon(),
                color: color,
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                <div className={"card card-outline card-info"}>
                    <div className="card-header">
                        <h3 className="card-title">{t("common.label.detalle")}</h3>
                        <div className="card-tools m-0">
                            <button type="submit" className="btn btn-xs bg-info btn-tool">
                                <i className="fas fa-save"></i>
                            </button>
                            <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/seguimientoAccion"}>
                                <i className="fas fa-times"></i>
                            </PrivateLink>
                        </div>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-nombre" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.nombre")}</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <input autoComplete="off" type="text" name={'value-chequeTitular'} className="parrish-input" value={nombre} onChange={e => setNombre(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-color" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.color")}</label>
                                            <div className="col-sm-8 col-xs-12 parrish-picker">
                                            <CirclePicker color={color} onChange={(color)=>{setColor(color.hex)}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-icon" className="parrish-label col-sm-4 col-xs-12 required">{t("seguimientoAccion.field.icon")}</label>
                                            <div className="col-sm-8 col-xs-12 parrish-picker">
                                                <ReactIconPicker pickedIcon={icon} pickIcon={(icon)=>{setIcon(icon)}} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 d-flex justify-content-center align-items-center">
                                <div className="rounded-circle rounded-parrish-img d-flex justify-content-center shadow float-right" style={{backgroundColor: color}}>
                                    <h1 className="align-self-center">
                                        <i className={getIcon()+" text-white"} />
                                    </h1>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SeguimientoAccionCreate