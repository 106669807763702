import React, { useState, useEffect, useRef, useContext } from 'react'
import { useMutation, gql } from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import DatePicker from '../../../../components/DatePicker';
import Autocomplete from '../../../../components/Autocomplete';
import NumberFormat from 'react-number-format';
import SelectEntity from '../../../../components/SelectEntity';
import AuthContext from '../../../../context/AuthContext';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import FORMA_PAGO from '../../../../enums/FormaPago';
import MONEDA from '../../../../enums/Moneda';
import useCotizacionHelper from '../../../../helpers/useCotizacionHelper';
import { DateRfc }  from '../../DateRfc';

const CREATE_MOVIMIENTO_MUTATION = gql`
    mutation createMovimiento(
        $cliente: Int!
        $fechaAcreditacion: LocalDate!
        $detalle: String
        $cotizacionDolar: Float!
        $cotizacionPeso: Float!
        $moneda: Int!
        $importe: Float!
        $formaPago: Int!
        $cuenta: Int!
        $chequeBanco: Int
        $chequeNro: String
        $chequeTitular: String
        $chequeFecha: LocalDate
    ) {
        createMovimiento(entity:{
            cliente: $cliente
            fechaAcreditacion: $fechaAcreditacion
            detalle: $detalle
            cotizacionDolar: $cotizacionDolar
            cotizacionPeso: $cotizacionPeso
            moneda: $moneda
            importe: $importe
            formaPago: $formaPago
            cuenta: $cuenta
            chequeBanco: $chequeBanco
            chequeNro: $chequeNro
            chequeTitular: $chequeTitular
            chequeFecha: $chequeFecha
        }) {
            id
        }
    }
`;

function CobranzaCreate() {
    const history = useHistory();
    let { showErrorToast } = useAlertMessage();
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const ref = useRef();

    const [cliente, setCliente] = useState("");
    const [fechaAcreditacion, setFechaAcreditacion] = useState();
    const [detalle, setDetalle] = useState("");
    const [cotizacionDolar, setCotizacionDolar] = useState("");
    const [cotizacionPeso, setCotizacionPeso] = useState("");
    const [moneda, setMoneda] = useState(MONEDA.PESOS);
    const [importe, setImporte] = useState("");
    const [formaPago, setFormaPago] = useState("");
    const [cuenta, setCuenta] = useState("");

    const [chequeFecha, setChequeFecha] = useState("");
    const [chequeBanco, setChequeBanco] = useState("");
    const [chequeTitular, setChequeTitular] = useState("");
    const [chequeNro, setChequeNro] = useState("");

    const [monedaLabel, setMonedaLabel] = useState("AR$");

    const cotizacionHelper = useCotizacionHelper({
        setCotizacionPeso: (cotizacion)=>{
            setCotizacionPeso(cotizacion);
        },
        setCotizacionDolar: (cotizacion)=>{
            setCotizacionDolar(cotizacion);
        }
    });

    const [t] = useTranslation("global");
    
    const [createMovimientoMutation] = useMutation(CREATE_MOVIMIENTO_MUTATION, {
        onCompleted: (data) => {
            history.replace("/private/cobranza/edit/"+data.createMovimiento.id);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createMovimientoMutation({
            variables:{
                cliente: cliente?parseInt(cliente):null,
                fechaAcreditacion: fechaAcreditacion?fechaAcreditacion:null,
                detalle: detalle?detalle:null,
                cotizacionDolar: cotizacionDolar?cotizacionDolar:null,
                cotizacionPeso: cotizacionPeso?cotizacionPeso:null,
                moneda: moneda?parseInt(moneda):null,
                importe: importe?importe:null,
                formaPago: formaPago?parseInt(formaPago):null,
                cuenta: cuenta?parseInt(cuenta):null,
                chequeBanco: chequeBanco?parseInt(chequeBanco):null,
                chequeFecha: chequeFecha?chequeFecha:null,
                chequeNro: chequeNro?chequeNro:null,
                chequeTitular: chequeTitular?chequeTitular:null
            }
        })
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-cliente": "required",
                "value-fechaAcreditacion": "required",
                "value-cotizacionArsEnUsd": "required",
                "value-cotizacionArs": "required",
                "value-moneda": "required",
                "value-importe": "required",
                "value-formaPago": "required",
                "value-cuenta": "required",
                "value-chequeFecha": "required",
                "value-chequeBanco": "required",
                "value-chequeTitular": "required",
                "value-chequeNro": "required"
            },
            messages: {
                "value-cliente": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cliente")})
                },
                "value-fechaAcreditacion": {
                    required: t("common.message.field.required", {0: t("movimiento.field.fechaAcreditacion")})
                },
                "value-cotizacionArsEnUsd": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cotizacionArsEnUsd")})
                },
                "value-cotizacionArs": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cotizacionArs")})
                },
                "value-moneda": {
                    required: t("common.message.field.required", {0: t("movimiento.field.moneda")})
                },
                "value-importe": {
                    required: t("common.message.field.required", {0: t("movimiento.field.importe")})
                },
                "value-formaPago": {
                    required: t("common.message.field.required", {0: t("movimiento.field.formaPago")})
                },
                "value-cuenta": {
                    required: t("common.message.field.required", {0: t("movimiento.field.cuenta")})
                },
                "value-chequeFecha": {
                    required: t("common.message.field.required", {0: t("movimiento.field.chequeFecha")})
                },
                "value-chequeBanco": {
                    required: t("common.message.field.required", {0: t("movimiento.field.chequeBanco")})
                },
                "value-chequeTitular": {
                    required: t("common.message.field.required", {0: t("movimiento.field.chequeTitular")})
                },
                "value-chequeNro": {
                    required: t("common.message.field.required", {0: t("movimiento.field.chequeNro")})
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[formaPago, showErrorToast, t]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className={"card card-outline card-info"}>
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.creacion")}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to="/cobranza">
                            <i className="fas fa-times"></i>
                        </PrivateLink>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaRegistracion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaRegistracion")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaRegistracion" readOnly selected={new DateRfc()}/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" defaultValue={userData?.usuario?.contacto?.alias}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaCobranza" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCobranza")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaCobranza" readOnly />
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaConciliacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaConciliacion")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaCancelacion" readOnly/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaCancelacion" className="parrish-label col-sm-4 col-xs-12">{t("movimiento.field.fechaCancelacion")}</label>
                                <div className="col">
                                    <DatePicker name="value-fechaCancelacion" readOnly/>
                                </div>
                                <div className="col">
                                    <input autoComplete="off" disabled className="parrish-input"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-cliente" className="parrish-label col-sm-2 col-xs-12 required">{t("movimiento.field.cliente")}</label>
                                <div className="col">
                                    <Autocomplete
                                        name="value-cliente"
                                        entity="EmpresaCliente"
                                        by="Alias"
                                        filterName="alias"
                                        value={cliente}
                                        setValue={(value)=>setCliente(value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 text-align-center text-uppercase">
                            <h4>{t("movimiento.label.datosCobranza")}</h4>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-fechaAcreditacion" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.fechaAcreditacion")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <DatePicker
                                        name="value-fechaAcreditacion"
                                        selected={fechaAcreditacion}
                                        maxDate={new DateRfc()}
                                        onChange={date => {
                                            setFechaAcreditacion(date);
                                            cotizacionHelper.execute(date, moneda);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group row">
                                <label htmlFor="value-detalle" className="parrish-label col-sm-2 col-xs-12">{t("movimiento.field.detalle")}</label>
                                <div className="col-sm-10 col-xs-12">
                                    <textarea name="value-detalle" value={detalle} onChange={(e)=>{setDetalle(e.target.value)}} className="form-control form-control-sm" rows="3"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionDolar" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("movimiento.field.cotizacion", { 1: "(U$D)", 0: monedaLabel })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionDolar"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionDolar}
                                        className={"parrish-input text-right"}
                                        disabled={parseInt(moneda)===MONEDA.DOLARES}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionDolar(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cotizacionPeso" className="parrish-label col-sm-4 col-xs-12 required">
                                    {t("movimiento.field.cotizacion", { 1: "(AR$)", 0: monedaLabel })}
                                </label>
                                <div className="col-sm-3 col-xs-12">
                                    <NumberFormat
                                        name="value-cotizacionPeso"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale="true"
                                        value={cotizacionPeso}
                                        className={"parrish-input text-right"}
                                        disabled={parseInt(moneda)===MONEDA.PESOS}
                                        onChange ={(e) => {
                                            const val = e.target?.value;
                                            if(val){
                                                setCotizacionPeso(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-moneda" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.moneda")}</label>
                                <div className="col-sm-2 col-xs-12">
                                    <SelectEntity
                                        entity="Moneda"
                                        label="simbolo"
                                        name="value-moneda"
                                        value={moneda}
                                        required
                                        onChange={(e) => {
                                            setMonedaLabel(e.target.options[e.target.selectedIndex].innerHTML);
                                            setMoneda(e.target.value);
                                            cotizacionHelper.execute(fechaAcreditacion, e.target.value);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-importe" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.importe")}</label>
                                <div className="col-sm-4 col-xs-12">
                                <NumberFormat
                                    name="value-importe"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale="true"
                                    value={importe}
                                    className={"parrish-input text-right"}
                                    onChange ={(e) => {
                                        const val = e.target?.value;
                                        if(val){
                                            setImporte(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                        }
                                    }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-formaPago" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.formaPago")}</label>
                                <div className="col-sm-3 col-xs-12">
                                    <SelectEntity
                                        entity="FormaPago"
                                        label="nombre"
                                        name="value-formaPago"
                                        value={formaPago}
                                        required
                                        avoidList={[FORMA_PAGO.RETENCION]}
                                        onChange={(e) => {
                                            setFormaPago(e.target.value);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        {
                            parseInt(formaPago) === FORMA_PAGO.CHEQUE &&
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-chequeFecha" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.chequeFecha")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <DatePicker name="value-chequeFecha" selected={chequeFecha} onChange={date => setChequeFecha(date)}/>
                                        </div>
                                    </div>
                                </div>
                        }

                        {parseInt(formaPago) !== FORMA_PAGO.CHEQUE &&
                            <div className="col-xs-12 col-sm-6"></div>
                        }

                        <div className="col-xs-12 col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="value-cuenta" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.cuenta")}</label>
                                <div className="col-sm-4 col-xs-12">
                                    <SelectEntity
                                        entity="Cuenta"
                                        name="value-cuenta"
                                        value={cuenta}
                                        label="nombre,moneda{simbolo}"
                                        displayText={(element)=>{
                                            return (element.nombre+" ["+element.moneda.simbolo+"]");
                                        }}
                                        required
                                        onChange={(e) => {
                                            setCuenta(e.target.value);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        {
                            parseInt(formaPago) === FORMA_PAGO.CHEQUE &&
                            <>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-chequeBanco" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.chequeBanco")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <SelectEntity
                                                entity="Banco"
                                                label="nombre"
                                                name={'value-chequeBanco'}
                                                value={chequeBanco}
                                                required
                                                onChange={(e) => {
                                                    setChequeBanco(e.target.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6"></div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-chequeTitular" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.chequeTitular")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" type="text" name={'value-chequeTitular'} className="parrish-input" value={chequeTitular} onChange={e => setChequeTitular(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6"></div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-chequeNro" className="parrish-label col-sm-4 col-xs-12 required">{t("movimiento.field.chequeNro")}</label>
                                        <div className="col-sm-4 col-xs-12">
                                            <input autoComplete="off" type="text" name={'value-chequeNro'} className="parrish-input" value={chequeNro} onChange={e => setChequeNro(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CobranzaCreate