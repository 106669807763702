import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import MONEDA from '../enums/Moneda';

const COTIZACION_BY_FECHA = gql`
    query findCotizacionListByFecha(
        $fecha: LocalDate!
        $deMoneda: Int!
    ) {
        findCotizacionListByFecha(
            fecha: $fecha,
            deMoneda: $deMoneda,
        ){
            enMoneda
            {
                id
            }
            cotizacion
        }
    }
`;

export default function useCotizacionHelper(props) {
    const {setCotizacionPeso} = props;
    const {setCotizacionDolar} = props;

    const [cotizacionByFechaQuery] = useLazyQuery(COTIZACION_BY_FECHA, {
        onCompleted: (data) => {
            data?.findCotizacionListByFecha.forEach(element => {
                if(parseInt(element.enMoneda.id)===MONEDA.PESOS){
                    setCotizacionPeso(element.cotizacion);
                }
                if(parseInt(element.enMoneda.id)===MONEDA.DOLARES){
                    setCotizacionDolar(element.cotizacion);
                }
            });
        }
    });

    const execute = (fecha, deMoneda) => {
        if(fecha && deMoneda){
            cotizacionByFechaQuery({
                variables: {
                    fecha: fecha,
                    deMoneda: deMoneda
                }
            });
        }
    }

    return{
        execute
    }
}
