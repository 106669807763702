import React from 'react'

export default function FlagButtonBoolean(props) {
    const {value, setValue, icon, iconImg, onClass, offClass, title, label} = props;
    const className=(!onClass&&!offClass)?"bg-info":"";
    return (
        <div className="d-flex" role="button" onClick={setValue?()=>{setValue(!value)}:null}>
            <div className={"small-flag "+className+" "+(value===true?(" on "+onClass?onClass:""):" off "+(offClass?offClass:""))}
                data-toggle="tooltip"
                data-html="true"
                title={title}
                >
                {icon&&
                    <i className={icon}></i>
                }
                {iconImg &&
                    <i className="fa-custom-x1">
                        <img src={iconImg} alt="logo-icon"/>
                    </i>
                }   
            </div>
            {label &&
                <span className="ml-2">
                    {label}
                </span>
            }
        </div>
    )
}
