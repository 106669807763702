import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Autocomplete from './Autocomplete'
import $ from 'jquery'
import useAlertMessage from '../hooks/useAlertMessage';

const CREATE_CONTACTO_MUTATION = gql`
    mutation createContacto(
        $nombre: String!
        $apellido: String
        $email: String
        $telefonosFijos: String
        $telefonosMoviles: String
    ) {
        createContacto(
            entity:{
                personaFisica: true
                nombre: $nombre
                apellido: $apellido
                email: $email
                telefonosFijos: $telefonosFijos
                telefonosMoviles: $telefonosMoviles
            }
        ) {
            id
            alias
            email
            telefonosFijos
            telefonosMoviles
        }
    }
`;

const FIND_CONTACTO_BY_ID = gql`
    query findContactoById(
        $id: Int!
    ) {
    findContactoById(
        id: $id
    ){
        id
        alias
        email
        telefonosFijos
        telefonosMoviles
    }
}
`;

export default function ContactoSelector(props) {
    const [t] = useTranslation("global");
    const ref = useRef();
    const {cliente, defaultLabel, setCliente, disabled, setContacto, disabledCreate} = props;
    const [initialLabel, setInitialLabel] = useState(defaultLabel?defaultLabel:"")

    const [show, setShow] = useState(false);

    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [email, setEmail] = useState("");
    const [telFijos, setTelFijos] = useState("");
    const [telMoviles, setTelMoviles] = useState("");

    let { showErrorToast, clearPopUps } = useAlertMessage();

    useEffect(() => {
        if(defaultLabel){
            setInitialLabel(defaultLabel);
        }
    }, [defaultLabel]);

    const [createContactoMutation] = useMutation(CREATE_CONTACTO_MUTATION, {
        onCompleted: (data) => {
            clearPopUps();
            setCliente(data.createContacto.id);
            setInitialLabel(data.createContacto.alias);
            if(setContacto){
                setContacto({
                    id: data.createContacto.id,
                    alias: data.createContacto.alias,
                    email: data.createContacto.email,
                    telefonosFijos: data.createContacto.telefonosFijos,
                    telefonosMoviles: data.createContacto.telefonosMoviles
                });
            }
            setShow(false);
        },
        onError: (error) => {
            clearPopUps();
            console.error(error);
        }
    }, []);

    const [searchContactoById] = useLazyQuery(FIND_CONTACTO_BY_ID, {
        onCompleted: (data) => {
            const contacto = data?.findContactoById;
            setContacto({
                id: contacto.id,
                alias: contacto.alias,
                email: contacto.email,
                telefonosFijos: contacto.telefonosFijos,
                telefonosMoviles: contacto.telefonosMoviles
            });
        }
    });

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!nombre){
            
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.nombre")})});
        }
        if(!apellido){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.apellido")})});
        }
        if(!email){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.email")})});
        }else{
            if(!validateEmail(email)){
                errorList.push({message: t("common.message.invalid.email.format")});
            }
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const handleSubmit = () => {
        if(validate()){
            createContactoMutation({
                variables:{
                    nombre: nombre,
                    apellido: apellido?apellido:null,
                    email: email?email:null,
                    telefonosFijos: telFijos?telFijos:null,
                    telefonosMoviles: telMoviles?telMoviles:null
                }
            });       
        }
    }

    useEffect(() => {
        $(ref.current).validate({
            rules:{
                    "value-nombre":{
                        required: true
                    },
                    "value-apellido":{
                        required: true
                    },
                    "value-email":{
                        required: true,
                        email: true
                    }
                },
                messages: {
                    "value-nombre": {
                        required: t("common.message.field.required", {0: t("contacto.field.nombre")})
                    },
                    "value-apellido": {
                        required: t("common.message.field.required", {0: t("contacto.field.apellido")})
                    },
                    "value-email": {
                        required: t("common.message.field.required", {0: t("contacto.field.email")}),
                        email: t("common.message.invalid.email.format")
                    }
                },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    const close = () => {
        clearPopUps();
        setShow(false);
    }

    useEffect(() => {
        if(show){
            setNombre("");
            setApellido("");
            setEmail("");
            setTelFijos("");
            setTelMoviles("");
        }
    }, [show])

    return (
        <>
            <div className="d-flex w-100">
                <Autocomplete
                    disabled={disabled}
                    name="value-contacto"
                    entity="Contacto"
                    by="PersonaFisicaAlias"
                    filterName="alias"
                    initialLabel={disabled?defaultLabel:initialLabel}
                    additionalFilter="personaFisica: true"
                    value={cliente}
                    setValue={(value)=>{
                        if(setContacto&&value){
                            searchContactoById({ variables: { id: value } });
                        }
                        setCliente(value);
                    }} />
                <button type="button" className="btn btn-xs bg-info ml-1" disabled={disabled||disabledCreate} onClick={()=>{setShow(true)}}>
                    <i className="fas fa-plus"></i>
                </button>
            </div>
            <div className={`modal fade ${show?'show d-flex align-items-center':''}`} id="modal-default" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered w-100">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("contacto.label.entity")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{close()}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-nombre" className="parrish-label col-4 text-right required">{t("contacto.field.nombre")}</label>
                                        <div className="col-8">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-nombre'}
                                                className="parrish-input"
                                                value={nombre}
                                                maxLength={150}
                                                onChange={(e) => setNombre(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-apellido" className="parrish-label col-4 text-right required">{t("contacto.field.apellido")}</label>
                                        <div className="col-8">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-apellido'}
                                                className="parrish-input"
                                                value={apellido}
                                                maxLength={150}
                                                onChange={(e) => setApellido(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-email" className="parrish-label col-4 text-right required">{t("contacto.field.email")}</label>
                                        <div className="col-8">
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-envelope fa-fw"></i>
                                                    </span>
                                                </div>
                                                <input autoComplete="off" type="text"
                                                    name={'value-email'}
                                                    className="parrish-input"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-tel-fijos" className="parrish-label col-4 text-right">{t("contacto.field.telefonosFijos")}</label>
                                        <div className="col-8">
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-phone fa-fw"></i>
                                                    </span>
                                                </div>
                                                <input autoComplete="off"
                                                    type="text"
                                                    name={'value-tel-fijos'}
                                                    className="parrish-input"
                                                    value={telFijos}
                                                    maxLength={150}
                                                    onChange={(e) => setTelFijos(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group row">
                                        <label htmlFor="value-tel-moviles" className="parrish-label col-4 text-right">{t("contacto.field.telefonosMoviles")}</label>
                                        <div className="col-8">
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fab fa-whatsapp fa-fw"></i>
                                                    </span>
                                                </div>
                                                <input autoComplete="off"
                                                    type="text"
                                                    name={'value-tel-moviles'}
                                                    className="parrish-input"
                                                    value={telMoviles}
                                                    maxLength={150}
                                                    onChange={(e) => setTelMoviles(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSubmit()}}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
