import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const ESTADOPOSTULANTE_SEARCH_BY_ID = gql`
    query findEstadoPostulanteById(
        $id: Int!
    ) {
    findEstadoPostulanteById(
        id: $id
    ){
        id
        nombre
        etapaProyecto {
            nombre
        }
        desiste
        esFinal
        esInicial
    }
}
`;

const UPDATE_ESTADOPOSTULANTE_MUTATION = gql`
    mutation updateEstadoPostulante(
        $id: ID!
        $etapaProyecto: Int
        $desiste: Boolean!
        $esFinal: Boolean!
        $esInicial: Boolean!
        $nombre: String!
    ) {
        updateEstadoPostulante(entity:{
            etapaProyecto: $etapaProyecto
            id: $id
            desiste: $desiste
            esFinal: $esFinal
            esInicial: $esInicial
            nombre: $nombre
    }) {
            etapaProyecto {
                nombre
            }
            id
            desiste
            esFinal
            esInicial
            nombre
        }
    }
`;

const DELETE_ESTADOPOSTULANTE_MUTATION = gql`
    mutation deleteEstadoPostulante(
        $id: Int!
    ) {
        deleteEstadoPostulante(id: $id)
    }
`;

function EstadoPostulanteEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [etapaProyecto, setEtapaProyecto] = useState('');
    const [desiste, setDesiste] = useState('');
    const [esFinal, setEsFinal] = useState('');
    const [esInicial, setEsInicial] = useState('');
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        ESTADOPOSTULANTE_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setEtapaProyecto(data?.findEstadoPostulanteById?.etapaProyecto?.id?data?.findEstadoPostulanteById?.etapaProyecto?.id:"");
            setDesiste(data?.findEstadoPostulanteById?.desiste);
            setEsFinal(data?.findEstadoPostulanteById?.esFinal);
            setEsInicial(data?.findEstadoPostulanteById?.esInicial);
            setNombre(data?.findEstadoPostulanteById?.nombre);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findEstadoPostulanteById?.etapaProyecto?.id,
        data?.findEstadoPostulanteById?.desiste,
        data?.findEstadoPostulanteById?.esFinal,
        data?.findEstadoPostulanteById?.esInicial,
        data?.findEstadoPostulanteById?.nombre
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateEstadoPostulanteMutation] = useMutation(UPDATE_ESTADOPOSTULANTE_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/estadoPostulante";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteEstadoPostulanteMutation] = useMutation(DELETE_ESTADOPOSTULANTE_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/estadoPostulante");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateEstadoPostulanteMutation({
            variables:{
                etapaProyecto: parseInt(etapaProyecto),
                id: id,
                desiste: desiste,
                esFinal: esFinal,
                esInicial: esInicial,
                nombre: nombre
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteEstadoPostulanteMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="estadoPostulante"
                columnsCount={1}
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 60,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.nombre")})
                        }
                    },
                    {
                        name: "etapaProyecto",
                        type: "select",
                        entity: "EtapaProyecto",
                        label: "nombre",
                        value: etapaProyecto,
                        setValue: setEtapaProyecto,
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.etapaProyecto")})
                        }
                    },
                    {
                        name: "desiste",
                        type: "boolean",
                        value: desiste,
                        setValue: setDesiste,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.desiste")})
                        }
                    },
                    {
                        name: "esFinal",
                        type: "boolean",
                        value: esFinal,
                        setValue: setEsFinal,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.esFinal")})
                        }
                    },
                    {
                        name: "esInicial",
                        type: "boolean",
                        value: esInicial,
                        setValue: setEsInicial,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoPostulante.field.esInicial")})
                        }
                    },
                ]}
                returnUrl="/estadoPostulante"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default EstadoPostulanteEdit