import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";

const CREATE_ESTADOCIVIL_MUTATION = gql`
    mutation createEstadoCivil(
        $nombre: String!
    ) {
        createEstadoCivil(entity:{
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

function EstadoCivilEdit() {
    const history = useHistory();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createEstadoCivilMutation] = useMutation(CREATE_ESTADOCIVIL_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/estadoCivil";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createEstadoCivilMutation({
            variables:{
                nombre: nombre
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardCreateEntity
                entityName="estadoCivil"
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 20,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("estadoCivil.field.nombre")})
                        }
                    }
                ]}
                returnUrl="/estadoCivil"
                >
            </CardCreateEntity>
        </>
    )
}

export default EstadoCivilEdit