import { useContext } from 'react';
import ReferenciaSidebar from '../components/sidebar/Referencias/ReferenciaSidebar';
import SeguimientosTimeLine from '../components/sidebar/SeguimientosTimeLine/SeguimientosTimeLine';
import SidebarContext from '../context/SidebarContext';

export default function Sidebar(props) {
    const { sideBarState, isShow } = useContext(SidebarContext);
    return (
        <>
            {isShow()&&
                <>
                    {sideBarState.mode==="seguimiento"&&
                        <SeguimientosTimeLine
                            idEmpresa={sideBarState.idEmpresa}
                            idPostulante={sideBarState.idPostulante}
                            empresa={sideBarState.empresa}
                            postulante={sideBarState.postulante}
                            proyecto={sideBarState.proyecto}
                            responsable={sideBarState.responsable}
                            accionSeguimiento={sideBarState.accionSeguimiento}
                            labelEmpresa={sideBarState.labelEmpresa}
                            labelPostulante={sideBarState.labelPostulante}
                            labelProyecto={sideBarState.labelProyecto}
                        />
                    }
                    {sideBarState.mode==="referencias"&&
                        <ReferenciaSidebar
                            empresa={sideBarState.empresa}
                            postulante={sideBarState.postulante}
                            proyecto={sideBarState.proyecto}
                            labelEmpresa={sideBarState.labelEmpresa}
                            labelPostulante={sideBarState.labelPostulante}
                            labelProyecto={sideBarState.labelProyecto}/>
                    }
                    {!sideBarState.mode&&
                        <p>Default sidebar</p>
                    }
                </>
            }
        </>
    )
}