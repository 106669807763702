import React from 'react'
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../../../../../../../components/CurrencyFormat';

export default function TotalCard(props) {
    const {title, value, iva, total, monedaLabel, totalLabel, totalClassName="text-danger", totalZeroClassName = "text-success", displayMonedaInFooter = false} = props;
    const [t] = useTranslation("global");

    return (
        <div className="small-box text-center bg-white fit-content mr-3">
            <div className="inner">
                <h5>{title}</h5>
                <h2 className={(value===0?(totalZeroClassName?totalZeroClassName:totalClassName):totalClassName)}>
                    <CurrencyFormat
                        prefix={monedaLabel}
                        value={value}/>
                </h2>
                <small className="d-block">
                    {totalLabel?
                        <span>{totalLabel}</span>
                    :
                    <div className="d-flex justify-content-center">
                        <b>{t("acuerdoComercial.label.iva")}</b>
                        <CurrencyFormat                                           
                            prefix={displayMonedaInFooter?monedaLabel:null}
                            value={iva}/>
                        <b className="ml-1">{t("acuerdoComercial.label.final")}</b>
                        <CurrencyFormat
                            prefix={displayMonedaInFooter?monedaLabel:null}
                            value={total}/>
                    </div>
                    }
                </small>
            </div>       
        </div>
    )
}
