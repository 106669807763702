import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardFilter from '../../../../components/CardFilter';
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom';
import PrivateLink from '../../../../components/navigation/PrivateLink';
import Paginator from '../../../../components/Paginator';
import ContactoAvatarImg from '../../../../components/ContactoAvatarImg';

// TODO PGEN revisar campos del filtro y de la respuesta
const CONTACTO_SEARCH = gql`
    query findContactoPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $etiqueta: Int
        $texto: String
    ) {
    findContactoPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            etiqueta: $etiqueta
            texto: $texto
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                fechaActualizacion
                fechaNacimiento
                personaFisica
                alias
                apellido
                codigoPostal
                contentType
                domicilio
                email
                facebook
                foto
                linkedin
                localidad
                nombre
                observaciones
                skype
                telefonosFijos
                telefonosMoviles
                twitter
                website
                bloqueado
                labelList{
                    id
                    nombre
                }
            }
        }       
    }
}
`;

function ContactoList(props){
    const {searchFilter, setSearchFilter} = props;
    const [t] = useTranslation("global");
    // TODO PGEN seleccionar orden default
    const [sortOptions] = useState({
        sortColumn: "alias",
        sortDirection: "ASC"
    });

    const history = useHistory();

    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data } = useQuery(
        CONTACTO_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                etiqueta: parseInt(searchFilter.etiqueta),
                texto: searchFilter.texto,
            }
        }
    );

    const addhttp =(url)=>{
        // eslint-disable-next-line no-useless-escape
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = "http://" + url;
        }
        return url;
    }

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const editContact =(id)=>{
        history.replace("/private/agenda-contactos/edit/"+id);
    }

    const ContactCard = (contactData) => {
        return(
            <div className="col-12 col-sm-6 col-md-4">
                <div
                    className={"card contact-card bg-light"}
                    onDoubleClick={()=>{editContact(contactData.id)}}>
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-7">
                                <h2 className="lead"><b>{contactData.alias}</b></h2>
                                <ul className="ml-4 mb-0 fa-ul text-muted">
                                    {
                                        contactData.email?
                                            <li className="small mb-1 text-nowrap">
                                                <div role="link" class="parrish-link-light" onClick={(e)=>{emailClick(e,contactData.email)}}>
                                                    <span className="fa-li">
                                                        <i className="fas fa-lg fa-envelope"></i>
                                                    </span>
                                                    {contactData.email}
                                                </div>
                                            </li>
                                        :
                                            null
                                    }
                                    {
                                        contactData.telefonosFijos?
                                            <li className="small mb-1">
                                                <span className="fa-li">
                                                    <i className="fas fa-lg fa-phone"></i>
                                                </span>
                                                {contactData.telefonosFijos}
                                            </li>
                                        :
                                            null
                                    }
                                    {
                                        contactData.telefonosMoviles?
                                            <li className="small mb-1">
                                                <div role="link" class="parrish-link-light" onClick={(e)=>{whatsappClick(e,contactData.telefonosMoviles)}}>
                                                    <span className="fa-li">
                                                        <i className="fab fa-lg fa-whatsapp"></i>
                                                    </span>
                                                    {contactData.telefonosMoviles}
                                                </div>
                                            </li>
                                        :
                                            null
                                    }
                                </ul>
                                {
                                    contactData.labelList.map((element, index) => 
                                    {
                                        return(<span key={index} className={'badge mr-1 label-color-'+element.id}>{element.nombre}</span>)
                                    })
                                }
                            </div>
                            <div className="col-5 text-center">
                                <ContactoAvatarImg id={contactData.id} foto={contactData.foto} personaFisica={contactData.personaFisica} className="profile-user-img img-fluid img-circle border-info float-right shadow rounded-parrish-img" alt="User profile picture"/>
                                
                                {/*
                                    <img src="/img/unknown-user.png" alt="" className="img-circle img-fluid"/>
                                <div className="rounded-circle rounded-parrish-img d-flex justify-content-center shadow float-right">
                                    <h1 className="align-self-center">
                                        <i className="fas fa-lg fa-user"></i>
                                    </h1>
                                </div>
                                    */}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="text-right">
                            {
                                contactData.linkedin?
                                    <a className="parrish-link-light mr-2" href={addhttp(contactData.linkedin)} target="blank">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                :
                                    null
                            }
                            {
                                contactData.domicilio?
                                    <a className="parrish-link-light mr-2" href={"https://www.google.com/maps/place/"+contactData.domicilio+" "+contactData.localidad}>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </a>
                                :
                                    null
                            }
                            {
                                contactData.skype?
                                    <a className="parrish-link-light mr-2" href={addhttp(contactData.skype)}>
                                        <i className="fab fa-skype"></i>
                                    </a>
                                :
                                    null
                            }
                            {
                                contactData.facebook?
                                    <a className="parrish-link-light mr-2" href={addhttp(contactData.facebook)} target="blank">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                :
                                    null
                            }
                            {
                                contactData.twitter?
                                    <a className="parrish-link-light mr-2" href={addhttp(contactData.twitter)}>
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                :
                                    null
                            }
                            {
                                contactData.website?
                                    <a target="blank" className="parrish-link-light mr-2" href={addhttp(contactData.website)}>
                                        <i className="fas fa-globe-americas"></i>
                                    </a>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <CardFilter
                entityName="contacto"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        type: "text",
                        name: "texto",
                        translationKey: "contacto.label.textoFiltro"
                    },
                    {
                        type: "selectKeyValue",
                        name: "etiqueta",
                        entity:"Label",
                        translationKey: "contacto.label.etiqueta"
                    },
                ]}>
            </CardFilter>
            <div className="card card-outline card-info">
                <div className="card-header pl-3 pt-2 pb-2">
                    <h3 className="card-title">{t("common.label.listado")} <span className="badge badge-info ml-2 p-1 pr-2 pl-2">{t("common.label.encontrados",{0: data?.findContactoPageByFilter?.queryInfo?.totalElements})}</span></h3>
                    <div className="card-tools">
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/agenda-contactos/create/persona"}>
                            <i className="fas fa-user"></i>
                        </PrivateLink>
                        <PrivateLink className="btn btn-xs bg-info btn-tool ml-2" to={"/agenda-contactos/create/empresa"}>
                            <i className="fas fa-industry"></i>
                        </PrivateLink>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item btn btn-info buttons-pdf buttons-html5">{t("common.label.exportar-pdf")}</button>
                            <button className="dropdown-item btn btn-info buttons-csv buttons-html5">{t("common.label.exportar-csv")}</button>
                            <button className="dropdown-item btn btn-info buttons-excel buttons-html5">{t("common.label.exportar-xls")}</button>
                            <button className="dropdown-item btn btn-info buttons-print">{t("common.label.exportar-impresora")}</button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="row d-flex align-items-stretch">
                        {
                            !loading && !error &&
                            data?.findContactoPageByFilter?.edges.map((element) => {
                                return(
                                    <ContactCard key={element.node.id} {...element.node}></ContactCard>
                                )
                            })
                        }
                    </div>
                    <div className="fit-content float-right">
                        <Paginator data={data?.findContactoPageByFilter} setPageSize={setPageSize} setPageNumber={setPageNumber} pageSize={pageSize} pageNumber={pageNumber} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactoList;