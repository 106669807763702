import React, { useEffect, useRef, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../../../hooks/useAlertMessage';
import MoneyInput from '../../../../../../../components/MoneyInput';
import DatePicker from '../../../../../../../components/DatePicker';
import { DateRfc } from '../../../../../DateRfc';

const FIND_COMPROBANTE_BY_ID = gql`
    query findComprobanteById(
        $id: Int!
    ) {
    findComprobanteById(
        id: $id
    ){
        id
        cbteFechaVencimiento
        cbteImporteFinal
        cbteDetalle
    }
}
`;

const REGISTRAR_COMPROBANTE_MUTATION = gql`
    mutation registrarComprobante(
        $proyecto: Int!
        $cbteFechaVencimiento: LocalDate!
        $cbteImporteFinal: Float!
        $cbteDetalle: String!
    ) {
        registrarComprobante(
            entity:{
                proyecto: $proyecto
                cbteFechaVencimiento: $cbteFechaVencimiento
                cbteImporteFinal: $cbteImporteFinal
                cbteDetalle: $cbteDetalle
            }
        ) {
            id
        }
    }
`;

const UPDATE_COMPROBANTE_MUTATION = gql`
    mutation updateComprobante(
        $id: ID!
        $cbteFechaVencimiento: LocalDate!
        $cbteImporteFinal: Float!
        $cbteDetalle: String!
    ) {
        updateComprobante(
            entity:{
                id: $id
                cbteFechaVencimiento: $cbteFechaVencimiento
                cbteImporteFinal: $cbteImporteFinal
                cbteDetalle: $cbteDetalle
            }
        ) {
            id
        }
    }
`;

export default function ComprobanteDialog(props) {
    const { proyecto, acuerdoComercial, comprobanteSelected, setComprobanteSelected, comprobantesCount, pendienteRegistracion , refetch } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const [show, setShow] = useState(false);
    const { showErrorToast, clearPopUps } = useAlertMessage();

    const [neto, setNeto] = useState(pendienteRegistracion.pendienteRegistracionNeto);
    const [iva, setIva] = useState(pendienteRegistracion.pendienteRegistracionIVA);

    let fechaVencimientoSuggested = new DateRfc();
    const [cbteFechaVencimiento, setCbteFechaVencimiento] = useState(fechaVencimientoSuggested.setMonth(fechaVencimientoSuggested.getMonth()+1));
    const [cbteImporteFinal, setCbteImporteFinal] = useState(pendienteRegistracion.pendienteRegistracionFinal);
    const [cbteDetalle, setCbteDetalle] = useState(t("comprobante.field.initialDetalle")+(comprobantesCount+1));

    const [findComprobante] = useLazyQuery(FIND_COMPROBANTE_BY_ID, {
        onCompleted: (data) => {
            const comprobante = data.findComprobanteById;
            console.log(comprobante);
            setCbteFechaVencimiento(comprobante.cbteFechaVencimiento?new DateRfc(comprobante.cbteFechaVencimiento):null);
            setCbteImporteFinal(comprobante.cbteImporteFinal);
            calculateImporteFinal(comprobante.cbteImporteFinal);
            setCbteDetalle(comprobante.cbteDetalle);
        }
    });

    const [registrarComprobanteMutaion] = useMutation(REGISTRAR_COMPROBANTE_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setComprobanteSelected(null);
            setShow(false);
        },
        onError: (error) => {
        }
    });

    const [updateComprobanteMutation] = useMutation(UPDATE_COMPROBANTE_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setComprobanteSelected(null);
            setShow(false);
        },
        onError: (error) => {
        }
    });
    
    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!cbteFechaVencimiento){
            errorList.push({message: t("common.message.field.required", {0: t("comprobante.field.cbteFechaVencimiento")})});
        }
        if(!cbteImporteFinal){
            errorList.push({message: t("common.message.field.required", {0: t("comprobante.field.cbteImporteFinal")})});
        }
        if(!cbteDetalle){
            errorList.push({message: t("common.message.field.required", {0: t("comprobante.field.detalle")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const handleSave = () =>{
        if(validate()){
            const variables = {
                cbteFechaVencimiento: cbteFechaVencimiento?new DateRfc(cbteFechaVencimiento):null,
                cbteImporteFinal: cbteImporteFinal?cbteImporteFinal:null,
                cbteDetalle: cbteDetalle?cbteDetalle:null
            }
            if(comprobanteSelected){
                updateComprobanteMutation({
                    variables:{
                        id: parseInt(comprobanteSelected.id),
                        ...variables
                    }
                });
            }else{
                registrarComprobanteMutaion({
                    variables:{
                        proyecto: parseInt(proyecto.id),
                        ...variables
                    }
                });
            }
        }
    }

    const calculateImporteNeto = (importeNeto) => {
        const importeIva = importeNeto*(21/100);
        setCbteImporteFinal(importeNeto+importeIva)
        setIva(importeIva);
    }
    
    const calculateImporteFinal = (importeFinal) => {
        const importeIva = 21*importeFinal/(100+21)
        setNeto(importeFinal-importeIva)
        setIva(importeIva);
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(!show){
            setComprobanteSelected(null);
        }
    }, [setComprobanteSelected, show]);


    useEffect(() => {
        if(comprobanteSelected){
            setShow(true);
            findComprobante({ variables: { id: comprobanteSelected.id } });
        }else{
            setShow(false);
        }
    }, [comprobanteSelected, findComprobante]);

    return (
        <>
            <button type="button" className="btn btn-xs btn-info bg-info btn-tool mr-3" disabled={proyecto?.fechaCierreAdministrativo} onClick={()=>{
                setCbteFechaVencimiento(fechaVencimientoSuggested.setMonth(fechaVencimientoSuggested.getMonth()+1));
                setCbteImporteFinal(pendienteRegistracion.pendienteRegistracionFinal);
                calculateImporteFinal(pendienteRegistracion.pendienteRegistracionFinal);
                setCbteDetalle(t("comprobante.field.initialDetalle")+(comprobantesCount+1));
                setShow(true);
            }}>
                {t("acuerdoComercial.button.agregar")}
            </button>
            <div ref={ref} className="modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    {show&&
                        <div className="modal-content">
                            <div className="modal-header pl-3 p-2">
                                <h5 className="modal-title">{t("acuerdoComercial.label.registrarComprobante")} {comprobanteSelected&&t("common.label.id", {0: comprobanteSelected?.id})}</h5>
                                <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body parrish-form">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="value-cbteFechaVencimiento" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.cbteFechaVencimiento")}</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <DatePicker
                                                    name="value-cbteFechaVencimiento"
                                                    selected={cbteFechaVencimiento}
                                                    onChange={date => setCbteFechaVencimiento(date)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="value-importeNeto" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.importeNeto")} ({acuerdoComercial?.moneda?.simbolo})</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <MoneyInput 
                                                    name="value-importeNeto"
                                                    value={neto}
                                                    onChange={e => setNeto(e.target.value)}
                                                    onBlur={e => calculateImporteNeto(e.target.value)}
                                                    />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="value-iva" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.iva")} ({acuerdoComercial?.moneda?.simbolo})</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <MoneyInput 
                                                    disabled={true}
                                                    name="value-iva"
                                                    value={iva}
                                                    onChange={e => setIva(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="value-importeTotal" className="parrish-label col-sm-4 col-xs-12 required">{t("comprobante.field.importeTotal")} ({acuerdoComercial?.moneda?.simbolo})</label>
                                            <div className="col-sm-8 col-xs-12">
                                                <MoneyInput 
                                                    name="value-importeTotal"
                                                    value={cbteImporteFinal}
                                                    onChange={e => setCbteImporteFinal(e.target.value)}
                                                    onBlur={e => calculateImporteFinal(e.target.value)}
                                                    />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="value-detalle" className="parrish-label-left col-sm-12 col-xs-12 required">{t("comprobante.field.detalle")}</label>
                                            <div className="col-sm-12 col-xs-12">
                                                <textarea
                                                    name="value-detalle"
                                                    value={cbteDetalle}
                                                    onChange={e => setCbteDetalle(e.target.value)}
                                                    className="form-control form-control-sm" rows="6"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                    {t("common.button.guardar")}
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
