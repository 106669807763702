import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import { useParams } from "react-router-dom";
import CardCreateEntity from '../../../../../components/CardCreateEntity';

const CREATE_PROVINCIA_MUTATION = gql`
    mutation createProvincia(
        $pais: Int!
        $nombre: String!
    ) {
        createProvincia(entity:{
            pais: $pais
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

const PAIS_SEARCH_BY_ID = gql`
    query findPaisById(
        $id: Int!
    ) {
    findPaisById(
        id: $id
    ){
        id
        nombre
    }
}
`;

function ProvinciaCreate(props) {
    const { setCrudMode } = props;
    let { id } = useParams();
    const { showMessageWithTimerAndExecute } = useAlertMessage();

    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const { data } = useQuery(
        PAIS_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const [createProvinciaMutation] = useMutation(CREATE_PROVINCIA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showMessageWithTimerAndExecute('success', t("common.message.confirmation.save.success"), 1500, ()=>{setCrudMode("list")});
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createProvinciaMutation({
            variables:{
                pais: id,
                nombre: nombre
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardCreateEntity
            entityName="provincia"
            className="m-0 card-no-shadow"
            columnsCount={2}
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "nombre",
                    type: "text",
                    maxLength: 50,
                    value: nombre,
                    setValue: setNombre,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("provincia.field.nombre")})
                    }
                },
                {
                    name: "pais",
                    translationKey: "provincia.field.pais",
                    value: data?.findPaisById?.nombre,
                    type: "text",
                    disabled: true
                }
            ]}
            returnAction={()=>{setCrudMode("list")}}
            >
        </CardCreateEntity>
    )
}

export default ProvinciaCreate