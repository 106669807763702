import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from 'react-number-format';
import SelectKeyValue from '../../../../components/SelectKeyValue';

const CREATE_CUENTA_MUTATION = gql`
    mutation createCuenta(
        $banco: Int
        $moneda: Int!
        $tipoCuenta: Int!
        $descubiertoPermitido: Float!
        $saldo: Float!
        $cbu: String
        $nombre: String!
        $nroCuenta: String
        $sucursal: String
        $titular: String
    ) {
        createCuenta(entity:{
            banco: $banco
            moneda: $moneda
            tipoCuenta: $tipoCuenta
            descubiertoPermitido: $descubiertoPermitido
            saldo: $saldo
            cbu: $cbu
            nombre: $nombre
            nroCuenta: $nroCuenta
            sucursal: $sucursal
            titular: $titular
        }) {
            id
            nombre
        }
    }
`;

function CuentaEdit() {
    const history = useHistory();
    const [nombre, setNombre] = useState('');
    const [banco, setBanco] = useState('');
    const [sucursal, setSucursal] = useState('');
    const [titular, setTitular] = useState('');
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [nroCuenta, setNroCuenta] = useState('');
    const [cbu, setCbu] = useState('');
    const [saldo, setSaldo] = useState('0');
    const [moneda, setMoneda] = useState('');
    const [monedaLabel, setMonedaLabel] = useState('');
    const [descubierto, setDescubierto] = useState('0');
    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createCuentaMutation] = useMutation(CREATE_CUENTA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/cuenta";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createCuentaMutation({
            variables:{
                banco: parseInt(banco),
                moneda: parseInt(moneda),
                tipoCuenta: parseInt(tipoCuenta),
                descubiertoPermitido: descubierto?parseFloat(descubierto):0,
                saldo: saldo?parseFloat(saldo):0,
                cbu: cbu,
                nombre: nombre,
                nroCuenta: nroCuenta,
                sucursal: sucursal,
                titular: titular
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardCreateEntity
                entityName="cuenta"
                columnsCount={1}
                handleSubmit={handleSubmit}
                values={[
                    {  
                        name: "nombre",
                        type: "text",
                        maxLength: 50,
                        value: nombre,
                        setValue: setNombre,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("cuenta.field.nombre")}),
                        }
                    },
                    {  
                        name: "banco",
                        type: "select",
                        entity:"Banco",
                        label: "nombre",
                        translationKey: "cuenta.field.banco.nombre",
                        value: banco,
                        setValue: setBanco,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "sucursal",
                        type: "text",
                        maxLength: 50,
                        value: sucursal,
                        setValue: setSucursal,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "titular",
                        type: "text",
                        maxLength: 100,
                        value: titular,
                        setValue: setTitular,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "tipoCuenta",
                        type: "select",
                        entity:"TipoCuenta",
                        label: "nombre",
                        value: tipoCuenta,
                        setValue: setTipoCuenta,
                        translationKey: "cuenta.field.tipoCuenta.nombre",
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("cuenta.field.tipoCuenta.nombre")}),
                        },
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "nroCuenta",
                        type: "text",
                        maxLength: 50,
                        value: nroCuenta,
                        setValue: setNroCuenta,
                        translationKey: "cuenta.field.tipoCuenta.nroCuenta",
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {  
                        name: "cbu",
                        type: "text",
                        maxLength: 50,
                        value: cbu,
                        setValue: setCbu,
                        componentClassName: "col-sm-7 col-xs-12",
                        labelClassName: "col-sm-4 col-xs-12",
                        inputClassName: "col-sm-8 col-xs-12",
                    },
                    {
                        name: "saldo",
                        componentClassName: "col-sm-7 col-xs-12",
                        validationJson:{
                            rules: {
                                "value-monedas":{
                                    required: true
                                },
                                "value-saldo":{
                                    required: true
                                }
                            },
                            messages: {
                                "value-monedas":{
                                    required: t("common.message.field.required", {0: t("cuenta.field.moneda")}),
                                },
                                "value-saldo":{
                                    required: t("common.message.field.required", {0: t("cuenta.field.saldo")}),
                                }
                            }
                        },
                        render: () =>{
                            return(
                                <div className="form-group row">
                                    <label htmlFor="value-moneda" className="parrish-label col-sm-4 col-xs-12 required">{t("cuenta.field.saldo")}</label>
                                    <div className="col-4 col-sm-2 col-xl-2">
                                        <SelectKeyValue
                                            entity="Moneda"
                                            name={'value-monedas'}
                                            value={moneda}
                                            required
                                            onChange={(e, label) => {
                                                setMonedaLabel(label);
                                                setMoneda(e.target.value);
                                            }}/>
                                    </div>
                                    <div className="col-8 col-sm-6 col-xl-6">
                                        <NumberFormat
                                            className="parrish-input text-right"
                                            name="value-saldo"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale="true"
                                            value={saldo}
                                            onChange ={(e) => {
                                                const val = e.target?.value;
                                                if(val){
                                                    setSaldo(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                                }
                                            }}/>
                                    </div>
                                </div>
                            );
                        }
                    },
                    {  
                        name: "descubiertoPermitido",
                        componentClassName: "col-sm-7 col-xs-12",
                        render: () =>{
                            return(
                                <div className="form-group row">
                                    <label htmlFor="value-moneda-descubierto" className="parrish-label col-sm-4 col-xs-12 required">{t("cuenta.field.descubierto")}</label>
                                    <div className="col-4 col-sm-2 col-xl-2">
                                        <input autoComplete="off"
                                            type="text"
                                            name="value-moneda-descubierto"
                                            className="parrish-input text-center"
                                            id="value-moneda-descubierto"
                                            value={monedaLabel}
                                            disabled
                                            />
                                    </div>
                                    <div className="col-8 col-sm-6 col-xl-6">
                                        <NumberFormat
                                            className="parrish-input text-right"
                                            name="value-descubierto-permitido"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale="true"
                                            value={descubierto}
                                            onChange ={(e) => {
                                                const val = e.target?.value;
                                                if(val){
                                                    setDescubierto(parseFloat(e.target.value.replaceAll(".","").replaceAll(",",".")));
                                                }
                                            }}/>
                                    </div>
                                </div>
                            );
                        }
                    }
                ]}
                returnUrl="/cuenta"
                >
            </CardCreateEntity>
        </>
    )
}

export default CuentaEdit