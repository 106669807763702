import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import $ from "jquery";
import {} from "jquery-validation";
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import DeleteEntityButton from '../../../../../../components/DeleteEntityButton';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';

const FIND_SITE_BY_ID = gql`
    query findSiteById(
        $id: Int!
    ) {
        findSiteById(
        id: $id
    ){
        id
        nombre
        observaciones
        telefono
        pais{
            id
        }
        provincia{
            id
        }
        localidad
        domicilio
    }
}
`;

const UPDATE_SITE = gql`
    mutation updateSite(
        $id: ID!
        $pais: Int
        $provincia: Int
        $localidad: String
        $domicilio: String
        $nombre: String!
        $observaciones: String
        $telefono: String
    ) {
        updateSite(
            entity:{
                id: $id
                nombre: $nombre
                observaciones: $observaciones
                telefono: $telefono
                direccion:{
                    pais: $pais
                    provincia: $provincia
                    localidad: $localidad
                    domicilio: $domicilio
                }
            }
        ) {
            id
        }
    }
`;

export default function EditSite(props) {
    const { editId, setMode } = props;

    const [t] = useTranslation("global");
    const ref = useRef();

    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [domicilio, setDomicilio] = useState("");
    const [localidad, setLocalidad] = useState("");
    const [pais, setPais] = useState("");
    const [provincia, setProvincia] = useState("");
    const [observaciones, setObservaciones] = useState("");

    let { showErrorToast } = useAlertMessage();

    const [updateSiteMutation] = useMutation(UPDATE_SITE, {
        onCompleted: (data) => {
            setMode("list");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const { loading, error, data } = useQuery(
        FIND_SITE_BY_ID,
        {
            variables: {
                id: parseInt(editId)
            }
        }
    );

    useEffect(() => {
        if (!loading && !error){
            const dataSite = data?.findSiteById;
            setNombre(dataSite.nombre?dataSite.nombre:"");
            setTelefono(dataSite.telefono?dataSite.telefono:"");
            setDomicilio(dataSite.domicilio?dataSite.domicilio:"");
            setLocalidad(dataSite.localidad?dataSite.localidad:"");
            setPais(dataSite.pais?dataSite.pais.id:"");
            setProvincia(dataSite.provincia?dataSite.provincia.id:"");
            setObservaciones(dataSite.observaciones?dataSite.observaciones:"");
        }
    }, [data?.findSiteById, error, loading]);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateSiteMutation({
            variables:{
                id: editId,
                pais: pais?pais:null,
                provincia: provincia?provincia:null,
                localidad: localidad?localidad:null,
                domicilio: domicilio?domicilio:null,
                nombre: nombre?nombre:null,
                observaciones: observaciones?observaciones:null,
                telefono: telefono?telefono:null
            }
        });
    }

    useEffect(() => {
        $(ref.current).validate({
            rules: {
                "value-nombre": "required"
            },
            messages: {
                "value-nombre":{
                    required: t("common.message.field.required", {0: t("site.field.nombre")}),
                },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: editId })}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <DeleteEntityButton className="ml-2" entity="Site" id={editId} label={nombre} onCompleted={()=>{setMode("list")}} />
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{setMode("list")}}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    
                    <div className="parrish-form row ">

                        <div className="col-xs-12 col-sm-6">

                            <div className="parrish-form row mt-4">
                                
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-nombre" className="parrish-label col-sm-4 col-xs-12 required">{t("site.field.nombre")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <input autoComplete="off" type="text"
                                                name={'value-nombre'}
                                                className="parrish-input"
                                                value={nombre}
                                                onChange={(e)=>{
                                                    setNombre(e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-telefono" className="parrish-label col-sm-4 col-xs-12">{t("site.field.telefono")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <input autoComplete="off" type="text"
                                                name={'value-telefono'}
                                                className="parrish-input"
                                                value={telefono}
                                                onChange={(e)=>{
                                                    setTelefono(e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="parrish-form row ">
                                <div className="col-12 text-center">
                                    <h3>{t("site.label.domicilio")}</h3>
                                </div>
                            </div>

                            <div className="parrish-form row ">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-domicilio" className="parrish-label col-sm-4 col-xs-12">{t("site.field.domicilio")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <input autoComplete="off" type="text"
                                                name={'value-domicilio'}
                                                className="parrish-input"
                                                value={domicilio}
                                                onChange={e => setDomicilio(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-localidad" className="parrish-label col-sm-4 col-xs-12">{t("site.field.localidad")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <input autoComplete="off" type="text"
                                                name={'value-localidad'}
                                                className="parrish-input"
                                                value={localidad}
                                                onChange={e => setLocalidad(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-pais" className="parrish-label col-4 text-right font-weight-normal">{t("site.field.pais")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <SelectKeyValue
                                                entity="Pais"
                                                label="nombre"
                                                name={'value-pais'}
                                                value={pais}
                                                required
                                                onChange={(e) => {
                                                    setPais(e.target.value)
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-provincia" className="parrish-label col-4 text-right font-weight-normal">{t("site.field.provincia")}</label>
                                        <div className="col-sm-6 col-xs-12">
                                            <SelectKeyValue
                                                entity="Provincia"
                                                by="PaisNombre"
                                                label="nombre"
                                                name={'value-provincia'}
                                                value={provincia}
                                                filterValue={pais}
                                                filterName="idPais"
                                                onChange={(e) => {
                                                    setProvincia(e.target.value)
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xs-12 col-sm-6">
                            
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="parrish-form row ">
                                        <div className="col-12 text-center">
                                            <h3>{t("site.field.observaciones")}</h3>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-xs-12">
                                            <textarea
                                                name="value-observacionesFacturacion"
                                                className="form-control form-control-sm"
                                                rows="15"
                                                value={observaciones}
                                                onChange={(e) => setObservaciones(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                </div>
            </div>
        </form>
    )
}
