import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext";

const PAIS_SEARCH = gql`
    query findPaisPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $nombre: String
    ) {
    findPaisPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            nombre: $nombre
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                nombre
                codigo
            }
        }       
    }
}
`;

function PaisList(props){
    const {searchFilter, setSearchFilter} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "nombre",
        sortDirection: "ASC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data } = useQuery(
        PAIS_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                nombre: searchFilter.nombre
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="pais"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "nombre",
                        type: "text"
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findPaisPageByFilter"
                entityName="pais"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/pais/edit/"
                createUrl="/pais/create">
            </CardDatatable>
        </>
    );
}

export default PaisList;