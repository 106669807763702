import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"

const SEGUIMIENTOACCION_SEARCH = gql`
    query findSeguimientoAccionPageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $nombre: String
    ) {
    findSeguimientoAccionPageByFilter(
        pageCriteria:{
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
        filter:{
            nombre: $nombre
        }
    ){
        queryInfo{
            totalElements
            totalPages
        }
        pageInfo{
            hasPreviousPage
            hasNextPage
        }
        edges{
            node{
                id
                nombre
                color
                icon
            }
        }       
    }
}
`;

function SeguimientoAccionList(props){
    const {searchFilter, setSearchFilter} = props;

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "nombre",
        sortDirection: "ASC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        SEGUIMIENTOACCION_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                nombre: searchFilter.nombre
            }
        }
    );

    return (
        <>
            <CardFilter
                entityName="seguimientoAccion"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "nombre",
                        type: "text"
                    }
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findSeguimientoAccionPageByFilter"
                entityName="seguimientoAccion"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/seguimientoAccion/edit/"
                createUrl="/seguimientoAccion/create"
                columnsDefinition={[
                    {
                        name: "id",
                        className: "text-center align-middle",
                        type: "id"
                    },
                    {
                        name: "icon",
                        disableSort: true,
                        type: "custom",
                        className: "d-flex justify-content-center",
                        render:(element)=>{
                            return(
                                <div className="rounded-circle rounded-parrish-img-small d-flex justify-content-center align-items-center shadow" style={{backgroundColor: element.node.color}}>
                                    <h6 className="align-self-center m-0">
                                        <i className={element.node.icon+" text-white"} />
                                    </h6>
                                </div>
                            )
                        }
                    },
                    {
                        name: "nombre",
                        type: "text",
                        className: "align-middle",
                    }

                ]}>
            </CardDatatable>
        </>
    );
}

export default SeguimientoAccionList;