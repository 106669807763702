import React, { useContext, useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import CardDatatable from '../../../../components/CardDatatable';
import CardFilter from '../../../../components/CardFilter';
import AppStateContext from "../../../../context/AuthContext"
import moment from 'moment'
import EstadoComprobante from '../estados/EstadoComprobante';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const COMPROBANTE_SEARCH = gql`
    query findComprobantePageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int
        $cliente: Int
        $estado: Int
        $proyecto: Int
        $cbteFechaEmisionDesde: LocalDate
        $cbteFechaEmisionHasta: LocalDate
        $cbteFechaVencimientoDesde: LocalDate
        $cbteFechaVencimientoHasta: LocalDate
        $cbteTipo: Int
        $cbteNro: String
    ) {
    findComprobantePageByFilter(
        filter: {
            id: $id
            cliente: $cliente
            estado: $estado
            proyecto: $proyecto
            cbteFechaEmisionDesde: $cbteFechaEmisionDesde
            cbteFechaEmisionHasta: $cbteFechaEmisionHasta
            cbteFechaVencimientoDesde: $cbteFechaVencimientoDesde
            cbteFechaVencimientoHasta: $cbteFechaVencimientoHasta
            cbteTipo: $cbteTipo
            cbteNro: $cbteNro
        }
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                adjunto
                estado {
                id
                color
                nombre
                }
                fechaRegistro
                registradoPor {
                contacto {
                    alias
                }
                }
                fechaAprobacion
                aprobadoPor {
                contacto {
                    alias
                }
                }
                fechaEmision
                emitidoPor {
                contacto {
                    alias
                }
                }
                fechaConciliacion
                conciliadoPor {
                    contacto {
                        alias
                    }
                }
                cliente {
                    id
                    contacto{
                        alias
                    }
                }
                proyecto {
                    id
                    ref
                }
                cbteFechaEmision
                cbteFechaVencimiento
                cbteTipo {
                    nemotecnico
                }
                cbteNro
                cbteDetalle
                cbteMoneda {
                    id
                    simbolo
                }
                cbteImporteFinal
                cbteImporteIva
                cbteImporteNeto
                cotizacionPeso
                cbteImpacto
            }
        }
    }
}
`;

function ComprobanteList(props){
    const {searchFilter, setSearchFilter} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "cbteFechaVencimiento",
        sortDirection: "DESC"
    });

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        COMPROBANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: searchFilter?.id?parseInt(searchFilter.id):null,
                cliente: searchFilter?.cliente?parseInt(searchFilter.cliente):null,
                estado: searchFilter?.estado?parseInt(searchFilter.estado):null,
                proyecto: searchFilter?.proyecto?parseInt(searchFilter.proyecto):null,
                cbteFechaEmisionDesde: searchFilter?.cbteFechaEmisionDesde?moment(searchFilter?.cbteFechaEmisionDesde).format("YYYY-MM-DD"):null,
                cbteFechaEmisionHasta: searchFilter?.cbteFechaEmisionHasta?moment(searchFilter?.cbteFechaEmisionHasta).format("YYYY-MM-DD"):null,
                cbteFechaVencimientoDesde: searchFilter?.cbteFechaVencimientoDesde?moment(searchFilter?.cbteFechaVencimientoDesde).format("YYYY-MM-DD"):null,
                cbteFechaVencimientoHasta: searchFilter?.cbteFechaVencimientoHasta?moment(searchFilter?.cbteFechaVencimientoHasta).format("YYYY-MM-DD"):null,                
                cbteTipo: searchFilter?.cbteTipo?searchFilter?.cbteTipo:null,
                cbteNro: searchFilter?.cbteNro?searchFilter?.cbteNro:null
            }
        }
    );

    const codigoMonedaTotales="AR$";
    let dataAux = []
    const totalImporteNeto = data?data["findComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        dataAux.push({
            cbteImporteNeto: currVal.node.cbteImporteNeto,
            cotizacionPeso: currVal.node.cotizacionPeso,
            cbteImpacto: currVal.node.cbteImpacto
        })
        return total + currVal.node.cbteImporteNeto*currVal.node.cotizacionPeso*currVal.node.cbteImpacto;
    } , 0):0;

    const totalImporteIva = data?data["findComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.cbteImporteIva*currVal.node.cotizacionPeso*currVal.node.cbteImpacto
    }, 0):0;
    
    const totalImporteFinal = data?data["findComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        return total + currVal.node.cbteImporteFinal*currVal.node.cotizacionPeso*currVal.node.cbteImpacto
    } , 0):0;

    return (
        <>
            <CardFilter
                entityName="comprobante"
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                filters={[
                    {
                        name: "id",
                        type: "number",
                    },
                    {
                        type: "selectKeyValue",
                        name: "estado",
                        entity:"EstadoAdministrativo",
                    },
                    {
                        type: "autocomplete",
                        name: "cliente",
                        entity:"EmpresaCliente",
                        by: "Alias",
                        filterName: "alias"
                    },
                    {
                        type: "autocomplete",
                        name: "proyecto",
                        entity:"Proyecto",
                        by: "EmpresaRef",
                        filterName: "ref"
                    },
                    {
                        type: "dateRange",
                        name: "fechaEmision",
                        startDateName: "cbteFechaEmisionDesde",
                        endDateName: "cbteFechaEmisionHasta",
                        translationKey: "comprobante.field.fechaEmision"
                    },
                    {
                        type: "dateRange",
                        name: "fechaVencimiento",
                        startDateName: "cbteFechaVencimientoDesde",
                        endDateName: "cbteFechaVencimientoHasta",
                        translationKey: "comprobante.field.fechaVencimiento"
                    },
                    {
                        type: "select",
                        name: "cbteTipo",
                        translationKey: "comprobante.field.comprobante",
                        entity:"TipoComprobante",
                        label: "letra,tipo",
                        displayText: (element)=>{
                            return element.letra?(element.tipo+" - "+element.letra):element.tipo;
                        },
                        labelClassName:"col-6",
                        inputClassName:"col-6",
                        componentClassName:"col-4"
                    },
                    {
                        type: "text",
                        name: "cbteNro",
                        maxLength: 50,
                        labelClassName:"d-none",
                        inputClassName:"col-2"
                    },
                ]}>
            </CardFilter>
            <CardDatatable
                data={data}
                loading={loading}
                error={error}
                sortOptions={sortOptions}
                dataWrapper="findComprobantePageByFilter"
                entityName="comprobante"
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                setSortOptions={setSortOptions}
                pageSize={pageSize}
                pageNumber={pageNumber}
                editUrl="/private/comprobante/edit/"
                headerBottom={
                    <tr>
                        <th className="bg-white pr-2">
                            <div>{codigoMonedaTotales}</div>
                        </th>
                        <th className="bg-white pr-2">
                            <div>
                                <CurrencyFormat value={totalImporteNeto}/>
                            </div>
                        </th>
                        <th className="bg-white pr-2">
                            <div>
                                <CurrencyFormat value={totalImporteIva}/>
                            </div>
                        </th>
                        <th className="bg-white pr-2">
                            <div>
                                <CurrencyFormat value={totalImporteFinal}/>
                            </div>
                        </th>
                    </tr>
                }
                columnsDefinition={[
                    {
                        name: "id",
                        type: "id",
                        rowSpan: 2
                    },
                    {
                        name: "adjunto",
                        customHeader: (<i className="fas fa-paperclip"></i>),
                        customClassHeader: "bg-info text-center pl-0 pr-0",
                        disableSort: true,
                        className: "text-center",
                        render: (element)=>{
                            if(element.node.adjunto){
                                return(
                                    <DownloadAdjunto filename={element.node.adjunto} entity="Comprobante"/>
                                )
                            }
                        },
                        rowSpan: 2
                    },
                    {
                        name: "estado",
                        className:"text-center",
                        render: (element)=>{
                            return(<EstadoComprobante element={element}/>)
                        },
                        rowSpan: 2
                    },
                    {
                        name: "clienteProyecto",
                        customSortKey: "cliente",
                        render: (element)=>{
                            return(<>
                                <PrivateLink className="parrish-link" to={"/empresa/edit/"+element.node.cliente.id}><p>{element.node.cliente.contacto.alias}</p></PrivateLink>
                                <PrivateLink className="parrish-link" to={"/proyecto/edit/"+element.node.proyecto.id}>[{element.node.proyecto.id}] - {element.node.proyecto.ref}</PrivateLink>
                            </>)
                        },
                        rowSpan: 2
                    },
                    {
                        name: "cbteFechaEmision",
                        type: "date",
                        rowSpan: 2
                    },
                    {
                        
                        name: "cbteFechaVencimiento",
                        type: "date",
                        rowSpan: 2
                    },
                    {
                        name: "detalle",
                        render: (element)=>{
                            return(<>
                                <p className="text-bold">{element.node.cbteTipo.nemotecnico+(element.node.cbteNro?" ["+element.node.cbteNro+"]":"")}</p>
                                <p>({element.node.cbteDetalle})</p>
                            </>)
                        },
                        rowSpan: 2
                    },
                    {
                        name: "moneda",
                        className:"text-align-center",
                        customHeader: " ",
                        customClassHeader: "bg-info pr-0 pl-0 border-with-1",
                        render: (element)=>{
                            return(element.node.cbteMoneda?.simbolo)
                        }
                    },
                    {  
                        name: "cbteImporteNeto",
                        type: "money",
                        disableSort: true,
                        customClassHeader: "bg-info pr-0 pl-0 border-with-1",
                        render: (element)=>{
                            const importeNeto = element.node.cbteImporteNeto*element.node.cbteImpacto;
                            return(
                                <CurrencyFormat additionalClassName="float-right" value={importeNeto}/>
                            )
                        }
                    },
                    {  
                        name: "cbteImporteIva",
                        type: "money",
                        disableSort: true,
                        customClassHeader: "bg-info pr-0 pl-0 border-with-1",
                        render: (element)=>{
                            const importeIva = element.node.cbteImporteIva*element.node.cbteImpacto;
                            return(
                                <CurrencyFormat additionalClassName="float-right" value={importeIva}/>
                            )
                        }
                    },
                    {  
                        name: "cbteImporteFinal",
                        type: "money",
                        customClassHeader: "bg-info pr-0 pl-0 border-with-1",
                        render: (element)=>{
                            const importeFinal = element.node.cbteImporteFinal*element.node.cbteImpacto;
                            return(
                                <CurrencyFormat additionalClassName="float-right" value={importeFinal}/>
                            )
                        }
                    }
                ]}>
            </CardDatatable>
        </>
    );
}

export default ComprobanteList;