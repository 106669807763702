import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import {} from "jquery-validation";
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import DeleteEntityButton from '../../../../../../components/DeleteEntityButton';
import DatePicker from '../../../../../../components/DatePicker';
import SelectEntity from '../../../../../../components/SelectEntity';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';
import Autocomplete from '../../../../../../components/Autocomplete';
import { DateRfc }  from '../../../../DateRfc';

const FORMACION_ACADEMICA_SEARCH_BY_ID = gql`
    query findFormacionAcademicaById(
        $id: Int!
    ) {
        findFormacionAcademicaById(
        id: $id
    ){
        id
        areaEstudio{
            id
        }
        institucion{
            id
        }
        nivelEstudio{
            id
        }
        pais{
            id
        }
        fechaInicio
        fechaFin
        fechasEstimadas
        promedio
        tituloObtenido
        reconocimientos
    }
}
`;

const UPDATE_FORMACION_ACADEMICA_MUTATION = gql`
    mutation updateFormacionAcademica(
        $id: ID!
        $areaEstudio: Int
        $institucion: Int!
        $nivelEstudio: Int!
        $pais: Int!
        $fechaFin: LocalDate
        $fechaInicio: LocalDate
        $fechasEstimadas: Boolean!
        $promedio: String
        $reconocimientos: String
        $tituloObtenido: String
    ) {
        updateFormacionAcademica(
            entity:{
                id: $id,
                areaEstudio: $areaEstudio,
                institucion: $institucion,
                nivelEstudio: $nivelEstudio,
                pais: $pais,
                fechaFin: $fechaFin,
                fechaInicio: $fechaInicio,
                fechasEstimadas: $fechasEstimadas,
                promedio: $promedio,
                reconocimientos: $reconocimientos,
                tituloObtenido: $tituloObtenido,
            }
        ) {
            id
        }
    }
`;

export default function EditFormacionAcademica(props) {
    const { editId, setMode } = props;
    const [t] = useTranslation("global");
    const ref = useRef();
    

    const [state, setState] = useState({
        fechaFin: null,
        fechaInicio: null,
        fechasEstimadas: false,
        nivelEstudio: "",
        institucion: "",
        areaEstudio: "",
        pais: "",
        tituloObtenido: "",
        promedio: "",
        reconocimientos: ""
    });

    let { showErrorToast, clearPopUps } = useAlertMessage();

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if((state.fechaFin==null&&state.fechaInicio!=null) || (state.fechaFin!=null&&state.fechaInicio==null)){
            errorList.push({message: t("common.message.field.invalid.period")});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const { loading, error, data } = useQuery(
        FORMACION_ACADEMICA_SEARCH_BY_ID,
        {
            variables: {
                id: editId
            }
        }
    );

    useEffect(() => {
        if (!loading && !error){
            const dataFormacionAcademica = data?.findFormacionAcademicaById;
            setState({
                fechaFin: dataFormacionAcademica.fechaFin?new DateRfc(dataFormacionAcademica.fechaFin):null,
                fechaInicio: dataFormacionAcademica.fechaInicio?new DateRfc(dataFormacionAcademica.fechaInicio):null,
                fechasEstimadas: dataFormacionAcademica.fechasEstimadas,
                areaEstudio: dataFormacionAcademica.areaEstudio?parseInt(dataFormacionAcademica.areaEstudio.id):null,
                nivelEstudio: parseInt(dataFormacionAcademica.nivelEstudio.id),
                institucion: parseInt(dataFormacionAcademica.institucion.id),
                pais: parseInt(dataFormacionAcademica.pais.id),
                tituloObtenido: dataFormacionAcademica.tituloObtenido?dataFormacionAcademica.tituloObtenido:null,
                promedio: dataFormacionAcademica.promedio?dataFormacionAcademica.promedio:null,
                reconocimientos: dataFormacionAcademica.reconocimientos?dataFormacionAcademica.reconocimientos:null
            });
        }
    }, [data?.findFormacionAcademicaById, error, loading]);

    const [updateFormacionAcademicaMutation] = useMutation(UPDATE_FORMACION_ACADEMICA_MUTATION, {
        onCompleted: (data) => {
            setMode("list");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validate()){
            updateFormacionAcademicaMutation({
                variables: {
                    id: editId,
                    fechaFin:state.fechaFin?state.fechaFin:null,
                    fechaInicio:state.fechaInicio?state.fechaInicio:null,
                    fechasEstimadas:state.fechasEstimadas,
                    nivelEstudio:state.nivelEstudio?parseInt(state.nivelEstudio):null,
                    institucion:state.institucion?parseInt(state.institucion):null,
                    areaEstudio:state.areaEstudio?parseInt(state.areaEstudio):null,
                    pais:state.pais?parseInt(state.pais):null,
                    tituloObtenido:state.tituloObtenido?state.tituloObtenido:null,
                    promedio:state.promedio?state.promedio:null,
                    reconocimientos:state.reconocimientos?state.reconocimientos:null
                }
            });
        }
    }

    

    useEffect(() => {
        $(ref.current).validate({
            ignore: "",
            rules: {
                "value-institucion": "required",
                "value-nivel-estudio": "required",
                "value-pais": "required",
            },
            messages: {
                "value-institucion":{
                    required: t("common.message.field.required", {0: t("formacionAcademica.field.institucion")}),
                },
                "value-nivel-estudio":{
                    required: t("common.message.field.required", {0: t("formacionAcademica.field.nivelEstudio")}),
                },
                "value-pais":{
                    required: t("common.message.field.required", {0: t("formacionAcademica.field.pais")}),
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: editId })}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <DeleteEntityButton className="ml-2" entity="FormacionAcademica" label={t("formacionAcademica.label.entity")} id={editId} onCompleted={()=>{setMode("list")}} />
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{setMode("list")}}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="parrish-form row ">

                        <div className="col-xs-12 col-sm-6">

                            <div className="form-group row">
                                <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.periodo")}</label>
                                <div className="col h-fit-content">
                                    <div className="row">
                                        <DatePicker
                                            name={'value-periodo-inicio'}
                                            selected={state.fechaInicio}
                                            onChange={(date) => {
                                                handleChange("fechaInicio", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsStart
                                            startDate={state.fechaInicio}
                                            endDate={state.fechaFin}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker />
                                        <DatePicker
                                            name={'value-periodo-fin'}
                                            selected={state.fechaFin}
                                            onChange={(date) => {
                                                handleChange("fechaFin", {target:{value: date}})
                                            }}
                                            className="col-sm-4 col"
                                            selectsEnd
                                            startDate={state.fechaInicio}
                                            endDate={state.fechaFin}
                                            minDate={state.fechaInicio}
                                            dateFormat="MM-yyyy"
                                            showMonthYearPicker />
                                        <div className="col-sm-4">
                                            <input autoComplete="off"
                                                className="btn m-2"
                                                type="checkbox"
                                                checked={state.fechasEstimadas}
                                                onChange={(val) => {
                                                    handleChange("fechasEstimadas", {target:{value: val.target.checked}})
                                                }} />
                                            <span>{t("postulante.field.fechasEstimadas")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="parrish-form row ">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-nivel-estudio" className="parrish-label col-2 text-right required">{t("formacionAcademica.field.nivelEstudio")}</label>
                                        <div className="col-10">
                                            <SelectKeyValue
                                                entity="NivelEstudio"
                                                by="Nombre"
                                                value={state.nivelEstudio}
                                                required
                                                onChange={(e) => {
                                                    handleChange("nivelEstudio", e)
                                                }}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="value-institucion" className="parrish-label col-2 text-right required">{t("formacionAcademica.field.institucion")}</label>
                                        <div className="col-10">
                                            <Autocomplete
                                                name={'value-institucion'}
                                                entity="Institucion"
                                                by="Nombre"
                                                filterName="nombre"
                                                value={state.institucion}
                                                setValue={(val)=>handleChange("institucion", {target:{value: val}})} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="value-pais" className="parrish-label col-2 text-right required">{t("formacionAcademica.field.pais")}</label>
                                        <div className="col-10">
                                            <SelectKeyValue
                                                entity="Pais"
                                                label="nombre"
                                                name={'value-pais'}
                                                value={state.pais}
                                                required
                                                onChange={(e) => {
                                                    handleChange("pais", e)
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-nivelEstudio" className="parrish-label col-2 text-right">{t("formacionAcademica.field.areaEstudio")}</label>
                                <div className="col-10">
                                    <SelectEntity
                                        entity="AreaEstudio"
                                        label="nombre"
                                        name={'value-area-estudio'}
                                        value={state.areaEstudio}
                                        required
                                        onChange={(e) => {
                                            handleChange("areaEstudio", e)
                                        }}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value-titulo-obtenido" className="parrish-label col-2 text-right">{t("formacionAcademica.field.tituloObtenido")}</label>
                                <div className="col-10">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-titulo-obtenido'}
                                        className="parrish-input"
                                        value={state.tituloObtenido}
                                        maxLength={150}
                                        onChange={(e) => handleChange("tituloObtenido", e)}/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="value-promedio" className="parrish-label col-2 text-right">{t("formacionAcademica.field.promedio")}</label>
                                <div className="col-10">
                                    <input autoComplete="off"
                                        type="text"
                                        name={'value-promedio'}
                                        className="parrish-input"
                                        value={state.promedio}
                                        maxLength={150}
                                        onChange={(e) => handleChange("promedio", e)}/>
                                </div>
                            </div>
                            </div>

                            <div className="col-xs-12 col-sm-6">
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="parrish-form row ">
                                        <div className="col-12 text-center">
                                            <h3>{t("formacionAcademica.field.reconocimiento")}</h3>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12 col-xs-12">
                                            <textarea
                                                name="value-reconocimientos"
                                                className="form-control form-control-sm"
                                                rows="17"
                                                value={state.reconocimientos}
                                                onChange={(e)=>{handleChange("reconocimientos", e)}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                    </div>
                </div>
            </div>
        </form>
    )
}
