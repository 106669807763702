
const TIPO_COMPROBANTE = {
    N_A_0:1,	
    RECIBO_1:2,	
    FACTURA_A:3,
    FACTURA_B:6,
    FACTURA_E:12,
    NOTA_CREDITO_A:13,
    NOTA_DEBITO_A:14,
    NOTA_CREDITO_B:15,
    NOTA_DEBITO_B:16,
    NOTA_CREDITO_E:17,
    NOTA_DEBITO_E:18,
    FACTURA_CEA:19,
    NOTA_CREDITO_CEA:20,
    NOTA_DEBITO_CEA:21,
    FACTURA_CEB:22,
    NOTA_CREDITO_CEB:23,
    NOTA_DEBITO_CEB:24
}
Object.freeze(TIPO_COMPROBANTE)

export default TIPO_COMPROBANTE