import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import Autocomplete from '../../../Autocomplete';
import useAlertMessage from '../../../../hooks/useAlertMessage';
import $ from 'jquery'
import ContactoSelector from '../../../ContactoSelector';

const CREATE_TPS_REFERENCIA_MUTATION = gql`
    mutation createTpsReferencia(
        $empresa: Int
        $postulante: Int
        $proyecto: Int
        $contacto: Int!
        $observaciones: String!
        $relacion: String!
        $telefonosFijos: String
        $telefonosMoviles: String
    ) {
        createTpsReferencia(entity:{
            empresa: $empresa,
            postulante: $postulante,
            proyecto: $proyecto,
            contacto: $contacto,
            observaciones: $observaciones,
            relacion: $relacion,
            telefonosFijos: $telefonosFijos,
            telefonosMoviles: $telefonosMoviles
        }) {
            id
        }
    }
`;

export default function CreateReferenciaModal(props) {
    const {show, setShow, labelPostulante, labelProyecto, labelEmpresa, idEmpresa, idPostulante, idProyecto, refetch} = props;
    const ref = useRef();
    
    const [postulante, setPostulante] = useState(idPostulante);
    const [proyecto, setProyecto] = useState(idProyecto);
    const [empresa, setEmpresa] = useState(idEmpresa);

    const [contacto, setContacto] = useState("");
    const [relacion, setRelacion] = useState("");
    const [email, setEmail] = useState("");
    const [telefonosFijos, setTelefonosFijos] = useState("");
    const [telefonosMoviles, setTelefonosMoviles] = useState("");
    const [observaciones, setObservaciones] = useState("");
    
    const [t] = useTranslation("global");
    let { showErrorToast, clearPopUps } = useAlertMessage();


    const [createReferenciaMutation] = useMutation(CREATE_TPS_REFERENCIA_MUTATION, {
        onCompleted: (data) => {
            refetch();
            setShow(false);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!contacto){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.contacto")})});
        }  
        if(!observaciones){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.observaciones")})});
        } 
        if(!relacion){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.relacion")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const saveAction = () =>{
        if(validate()){
            createReferenciaMutation({
                variables:{
                    empresa: empresa?parseInt(empresa):null,
                    postulante: postulante?parseInt(postulante):null,
                    proyecto: proyecto?parseInt(proyecto):null,
                    contacto: contacto,
                    observaciones: observaciones,
                    relacion: relacion,
                    telefonosFijos: telefonosFijos?telefonosFijos:null,
                    telefonosMoviles: telefonosMoviles?telefonosMoviles:null
                }
            })
        }
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide')
    }, [show])

    return(
        <div ref={ref} className="modal fade" id="modal-edit-referencia" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header pl-3 p-2">
                        <h5 className="modal-title text-dark">{t("common.label.detalle")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShow(false)}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body parrish-form pt-2 pb-2">

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.label.entity")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-postulante"
                                            entity="Postulante"
                                            by="Alias"
                                            placeholder={t("postulante.label.entity")}
                                            filterName="alias"
                                            value={postulante}
                                            initialLabel={labelPostulante}
                                            setValue={(val)=>setPostulante(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.proyecto")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-proyecto"
                                            entity="Proyecto"
                                            by="Alias"
                                            placeholder={t("postulante.field.proyecto")}
                                            filterName="alias"
                                            value={proyecto}
                                            initialLabel={labelProyecto}
                                            setValue={(val)=>setProyecto(val)} /> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2">{t("postulante.field.empresa")}</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            name="value-empresa"
                                            entity="Empresa"
                                            by="Alias"
                                            placeholder={t("postulante.field.empresa")}
                                            filterName="alias"
                                            value={empresa}
                                            initialLabel={labelEmpresa}
                                            setValue={(val)=>setEmpresa(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-1 mb-2"/>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="parrish-label col-2 required">{t("tpsReferencia.field.contacto")}</label>
                                    <div className="col-10">
                                        <ContactoSelector cliente={contacto} setCliente={setContacto} setContacto={(contacto)=>{
                                            setEmail(contacto.email);
                                            setTelefonosFijos(contacto.telefonosFijos);
                                            setTelefonosMoviles(contacto.telefonosMoviles);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-apellido" className="parrish-label col-2 text-right">{t("contacto.field.email")}</label>
                                    <div className="col-10">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                            <input autoComplete="off" type="text"
                                                name={'value-email'}
                                                className="parrish-input"
                                                value={email}
                                                disabled/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-tel-fijos" className="parrish-label col-2 text-right">{t("contacto.field.telefonosFijos")}</label>
                                    <div className="col-10">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-phone"></i>
                                                </span>
                                            </div>
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-tel-fijos'}
                                                className="parrish-input"
                                                value={telefonosFijos}
                                                maxLength={150}
                                                onChange={(e) => setTelefonosFijos(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-tel-moviles" className="parrish-label col-2 text-right">{t("contacto.field.telefonosMoviles")}</label>
                                    <div className="col-10">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fab fa-whatsapp"></i>
                                                </span>
                                            </div>
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-tel-moviles'}
                                                className="parrish-input"
                                                value={telefonosMoviles}
                                                maxLength={150}
                                                onChange={(e) => setTelefonosMoviles(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-1 mb-2"/>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-relacion" className="parrish-label col-2 text-right required">{t("contacto.field.relacion")}</label>
                                    <div className="col-10">
                                        <input autoComplete="off"
                                            type="text"
                                            name={'value-relacion'}
                                            className="parrish-input"
                                            value={relacion}
                                            maxLength={150}
                                            onChange={(e) => setRelacion(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-sm">
                                <div className="form-group row">
                                    <label htmlFor="value-nombre" className="col-12 text-left required">{t("contacto.field.observaciones")}</label>
                                    <div className="col-12">
                                        <textarea name="value-detalle" value={observaciones} onChange={(e)=>{setObservaciones(e.target.value)}} className="form-control form-control-sm" rows="10"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn bg-info ml-1" onClick={()=>{saveAction()}}>
                            <i className="fas fa-save mr-2"></i>Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
