import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import PermisosTab from './permisos/tab'

const PERFIL_SEARCH_BY_ID = gql`
    query findPerfilById(
        $id: Int!
    ) {
    findPerfilById(
        id: $id
    ){
        id
        nombre
    }
}
`;

const FIND_FUNCIONES_DISPONIBLES_BY_PERFIL = gql`
    query findFuncionesDisponiblesByPerfil(
        $idPerfil: Int!
    ) {
    findFuncionesDisponiblesByPerfil(
        idPerfil: $idPerfil
    ){
        id
        nombre
        orden
        nivel
        accion
        imagen
        grantedToPerfil
    }
}
`;

const UPDATE_PERFIL_MUTATION = gql`
    mutation updatePerfil(
        $id: ID!
        $nombre: String!
    ) {
        updatePerfil(entity:{
            id: $id
            nombre: $nombre
        }) {
            id
            nombre
        }
    }
`;

const DELETE_PERFIL_MUTATION = gql`
    mutation deletePerfil(
        $id: Int!
    ) {
        deletePerfil(id: $id)
    }
`;

function PerfilEdit() {
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(false);

    const {loading, error, data} = useQuery(
        PERFIL_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );

    const {data: funcionesData, refetch: refetchFunctiones } = useQuery(
        FIND_FUNCIONES_DISPONIBLES_BY_PERFIL,
        {
            variables: {
                idPerfil: id
            }
        }
    );
    
    useEffect(() => {
        _isMounted.current = true;
        if (!loading && !error){
            setNombre(data?.findPerfilById?.nombre);
        }
        return () => { _isMounted.current = false };
    }, [loading, error, data?.findPerfilById?.nombre])
    

    let query = new URLSearchParams(useLocation().search);
    let returnUrl = "/private/perfil";
    if(query!=null && (query.get("returnUrl"))){
        returnUrl = query.get("returnUrl");
    }

    const [updatePerfilMutation] = useMutation(UPDATE_PERFIL_MUTATION, {
        onCompleted: (data) => {
            
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deletePerfilMutation] = useMutation(DELETE_PERFIL_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.save.success"), 1000, returnUrl);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updatePerfilMutation({
            variables:{
                id: id,
                nombre: nombre
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deletePerfilMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    const Tabs = () => {
        return(
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none">
                <div className="card-header p-0 border-bottom-0 ">
                    <ul className="nav nav-tabs card-margin-right" id="custom-tabs-one-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link link-muted active"
                                id="custom-tabs-one-home-tab"
                                data-toggle="pill"
                                href="#custom-tabs-one-home"
                                role="tab"
                                aria-controls="custom-tabs-one-home"
                                aria-selected="true">
                                Permisos de Menu
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body bg-white card-shadow-radius">
                    <div className="tab-content" id="custom-tabs-one-tabContent">
                        <div className="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                            <PermisosTab funcionList={funcionesData?.findFuncionesDisponiblesByPerfil} refetch={refetchFunctiones}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <CardEditEntity
                entityName="perfil"
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        value: nombre,
                        setValue: setNombre
                    }
                ]}
                returnUrl="/perfil"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
            <Tabs/>
        </>
    )
}

export default PerfilEdit