import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from "react-i18next"
import Datatable from '../../../../components/Datatable';
import "popper.js/dist/popper";
import EstadoComprobante from '../estados/EstadoComprobante';
import DownloadAdjunto from '../../../../components/DownloadAdjunto';
import MONEDA from '../../../../enums/Moneda';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PrivateLink from '../../../../components/navigation/PrivateLink';

const CTA_CTE_COMPROBANTE_SEARCH = gql`
    query findCtaCteComprobantePageByFilter(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $cliente:Int
        $proyecto:Int
    )  {
        findCtaCteComprobantePageByFilter(
            pageCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                pageSize: $pageSize
                pageNumber: $pageNumber
            }
            filter: {
                cliente: $cliente
                proyecto: $proyecto
            }
        ) {
            queryInfo {
                totalElements
                totalPages
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    adjunto
                    estado {
                        id
                        color
                        nombre
                    }
                    fechaRegistro
                    registradoPor {
                        contacto {
                        alias
                        }
                    }
                    fechaAprobacion
                    aprobadoPor {
                        contacto {
                        alias
                        }
                    }
                    fechaEmision
                    emitidoPor {
                        contacto {
                        alias
                        }
                    }
                    fechaConciliacion
                    conciliadoPor {
                        contacto {
                        alias
                        }
                    }
                    cliente {
                        id
                        contacto{
                            alias
                        }
                    }
                    proyecto {
                        id
                        ref
                    }
                    cbteFechaEmision
                    cbteFechaVencimiento
                    cbteTipo {
                        nemotecnico
                    }
                    cbteNro
                    cbteDetalle
                    cbteMoneda {
                        id
                        simbolo
                    }
                    cbteImporteFinal
                    cbteImporteIva
                    cbteImporteNeto
                    cbteImpacto
                    cotizacionPeso
                    cotizacionDolar
                }
            }       
        }
    }
`;

function ComprobanteList(props){
    const [t] = useTranslation("global");
    const {searchFilter, monedaLabel} = props;
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "cbteFechaEmision",
        sortDirection: "DESC"
    });

    //const { appGetUserData } = useContext(AppStateContext);
    //const userData = appGetUserData();

    const pageSizeDefaultParam = 10;//userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;

    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        if(searchFilter){
            setPageNumber(0)
        }
    }, [searchFilter]);

    const {loading, error, data} = useQuery(
        CTA_CTE_COMPROBANTE_SEARCH,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                cliente: searchFilter.cliente?parseInt(searchFilter.cliente):null,
                proyecto: searchFilter.proyecto?parseInt(searchFilter.proyecto):null
            }
        }
    );

    const totalImporteNeto = data?data["findCtaCteComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        const cotizacionMoneda = (parseInt(searchFilter.moneda) === MONEDA.PESOS) ? currVal.node.cotizacionPeso: (parseInt(searchFilter.moneda) === MONEDA.DOLARES)? currVal.node.cotizacionDolar:null;
        return total + currVal.node.cbteImporteNeto*cotizacionMoneda*currVal.node.cbteImpacto;

    }, 0):0;
    const totalImporteIva = data?data["findCtaCteComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        const cotizacionMoneda = (parseInt(searchFilter.moneda) === MONEDA.PESOS) ? currVal.node.cotizacionPeso: (parseInt(searchFilter.moneda) === MONEDA.DOLARES)? currVal.node.cotizacionDolar:null;
        return total + currVal.node.cbteImporteIva*cotizacionMoneda*currVal.node.cbteImpacto;
    } , 0):0;
    const totalImporteFinal = data?data["findCtaCteComprobantePageByFilter"]?.edges.reduce((total, currVal) =>{
        const cotizacionMoneda = (parseInt(searchFilter.moneda) === MONEDA.PESOS) ? currVal.node.cotizacionPeso: (parseInt(searchFilter.moneda) === MONEDA.DOLARES)? currVal.node.cotizacionDolar:null;
        return total + currVal.node.cbteImporteFinal*cotizacionMoneda*currVal.node.cbteImpacto;
    } , 0):0;

    return (
        <Datatable
            data={data}
            loading={loading}
            error={error}
            sortOptions={sortOptions}
            dataWrapper="findCtaCteComprobantePageByFilter"
            entityName="comprobante"
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            setSortOptions={setSortOptions}
            pageSize={pageSize}
            pageNumber={pageNumber}
            editUrl="/private/comprobante/edit/"
            headerBottom={
                <tr>
                    <th className="bg-white pr-2">
                        <div>{monedaLabel}</div>
                    </th>
                    <th className="text-right bg-white pr-2">
                        <div>
                            <CurrencyFormat value={totalImporteNeto}/>
                        </div>
                    </th>
                    <th className="text-right bg-white pr-2">
                        <div>
                            <CurrencyFormat value={totalImporteIva}/>
                        </div>
                    </th>
                    <th className="text-right bg-white pr-2">
                        <div>
                            <CurrencyFormat value={totalImporteFinal}/>
                        </div>
                    </th>
                </tr>
            }
            headerTop={
                <tr>
                    <th className="bg-info text-left" colSpan="11">
                        <div>{t("comprobantesHistoricos.tableTitle")}</div>
                    </th>
                </tr>
            }
            columnsDefinition={[
                {
                    name: "id",
                    type: "id",
                    rowSpan: 2
                },
                {
                    name: "adjunto",
                    customHeader: (<i className="fas fa-paperclip"></i>),
                    customClassHeader: "bg-info text-center pl-0 pr-0",
                    className:"text-center",
                    disableSort: true,
                    render: (element)=>{
                        return(<DownloadAdjunto filename={element.node.adjunto} entity="Comprobante"/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "estado",
                    className:"text-center",
                    render: (element)=>{
                        return(<EstadoComprobante element={element}/>)
                    },
                    rowSpan: 2
                },
                {
                    name: "proyecto",
                    render: (element)=>{
                        return(<PrivateLink className="parrish-link" to={"/proyecto/edit/"+element.node.proyecto.id}>[{element.node.proyecto.id}] - {element.node.proyecto.ref}</PrivateLink>)
                    },
                    rowSpan: 2
                },
                {
                    name: "cbteFechaEmision",
                    type: "date",
                    rowSpan: 2
                },
                {
                    
                    name: "cbteFechaVencimiento",
                    type: "date",
                    rowSpan: 2
                },
                {
                    name: "detalle",
                    render: (element)=>{
                        return(<>
                            <p className="text-bold">{element.node.cbteTipo.nemotecnico+(element.node.cbteNro?" ["+element.node.cbteNro+"]":"")}</p>
                            <p>({element.node.cbteDetalle})</p>
                        </>)
                    },
                    rowSpan: 2
                },
                {
                    name: "moneda",
                    className:"text-align-center",
                    customHeader: (<></>),
                    render: (element)=>{
                        return(<>{element.node.cbteMoneda.simbolo}</>)
                    }
                },
                {  
                    name: "cbteImporteNeto",
                    className:"text-right",
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeNeto = element.node.cbteImporteNeto*element.node.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeNeto}/>
                        )
                    }
                },
                {  
                    name: "cbteImporteIva",
                    className:"text-right",
                    type: "money",
                    disableSort: true,
                    render: (element)=>{
                        const importeIva = element.node.cbteImporteIva*element.node.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeIva}/>
                        )
                    }
                },
                {
                    name: "cbteImporteFinal",
                    className:"text-right",
                    type: "money",
                    render: (element)=>{
                        const importeFinal = element.node.cbteImporteFinal*element.node.cbteImpacto;
                        return(
                            <CurrencyFormat additionalClassName="float-right" value={importeFinal}/>
                        )
                    }
                }
            ]}>
        </Datatable>
    );
}

export default ComprobanteList;