import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import CobranzaList from './list'
import CobranzaCreate from './create'
import CobranzaEdit from './edit'
import CobranzaCreateRetencion from './createRetencion'

import { useHistory, useParams } from 'react-router';

function CobranzaIndex(){
    const entityName = "cobranza";
    const [searchFilter, setSearchFilter] = useState({
        id: "",
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&(mode==="edit"||mode==='create-retencion')?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <CobranzaList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></CobranzaList>
                :
                null
            }
            {
                mode==='create'?
                    <CobranzaCreate></CobranzaCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <CobranzaEdit></CobranzaEdit>
                :
                null
            }
            {
                mode==='create-retencion'?
                    <CobranzaCreateRetencion></CobranzaCreateRetencion>
                :
                null
            }
        </div>
    );
}

export default CobranzaIndex;