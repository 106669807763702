import React, { useState } from "react";
import { Button, DropdownToggle, Dropdown, DropdownMenu, Input } from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas, fab);

export default function ReactIconPicker(props) {

    const {pickedIcon, pickIcon} = props;

    const [state, setState] = useState({
        dropdownOpen: false,
        icons: { ...fas, ...fab },
        pickedIcon: pickedIcon,
        search: ''
    })

    const toggle = ()=>{
        setState({
            ...state,
            dropdownOpen: !state.dropdownOpen
        });
    }

    const searchIconHandler = (event) => {
        setState({
            ...state,
            search: event.target.value
        })
    }

    const pickIconHandler = (icon) => {
        toggle();
        setState({
            ...state,
            pickedIcon: icon
        })
        pickIcon(icon)
    }

    const styles = {
        iconContainer: {
          overflow: 'scroll',
          padding: '8px'
        },
        iconSearchInput: {
          margin: '0 0 5px 0'
        },
        iconItem: {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '6px',
          width: '32px',
          height: '32px',
          borderRadius: '50px',
          fontSize: '18px'
        }
      }

      return (
          <Dropdown isOpen={state.dropdownOpen} toggle={toggle}>
          <DropdownToggle caret>
            <i className={pickedIcon.prefix+" fa-"+pickedIcon.iconName}/>
          </DropdownToggle>
          <DropdownMenu style={styles.iconContainer}>
            <Input type="text" name="icon" placeholder="Search Icon" value={state.search}
              onChange={searchIconHandler} style={styles.iconSearchInput} />
              <div className="row">
              {Object.keys(state.icons).filter(key => key !== "faFontAwesomeLogoFull" && state.icons[key]?.iconName?.includes(state.search)).map(key=>{
                return(<div key={state.icons[key].prefix+" fa-"+state.icons[key].iconName} className="col-2">
                    <Button outline color="secondary" style={styles.iconItem} onClick={() => pickIconHandler(state.icons[key])}>
                      <i className={state.icons[key].prefix+" fa-"+state.icons[key].iconName}/>
                    </Button>
                </div>)
              })}
              </div>
          </DropdownMenu>
        </Dropdown>
      );
}