import React, { useEffect, useState } from 'react'
import FormacionAcademicaList from './list'
import FormacionAcademicaCreate from './create'
import FormacionAcademicaEdit from './edit'

export default function FormacionAcademicaTab(props) {
    const { idPostulante, refetchPostulante } = props;
    const [mode, setMode] = useState("list")
    const [editId, setEditId] = useState("list")

    useEffect(() => {
        if(mode==="list"){
         refetchPostulante();
        }
     },[mode, refetchPostulante])

    return (
        <>
            {mode==="list"&&
                <FormacionAcademicaList setMode={setMode} idPostulante={idPostulante} setEditId={setEditId}/>
            }
            {mode==="create"&&
                <FormacionAcademicaCreate setMode={setMode} idPostulante={idPostulante}/>
            }
            {mode==="edit"&&
                <FormacionAcademicaEdit setMode={setMode} editId={editId}/>
            }
        </>
    )
}