import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";
import { DateRfc }  from '../../DateRfc';

const CREATE_USUARIO_MUTATION = gql`
    mutation createUsuario(
        $perfil: Int!
        $apellido: String!
        $email: String!
        $nombre: String!
    ) {
        createUsuario(entity:{
            perfil: $perfil
            apellido: $apellido
            email: $email
            nombre: $nombre
        }) {
            contacto{
                nombre
            }
            idioma{
                nombre
            }
            id
            perfil{
                nombre
            }
            fechaAlta
            fechaExpiracion
            bloqueado
            apellido
            email
            nombre
        }
    }
`;

function UsuarioCreate() {
    const history = useHistory();

    const [perfil, setPerfil] = useState('');
    const [fechaAlta, setFechaAlta] = useState(new DateRfc());
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [nombre, setNombre] = useState('');

    const [t] = useTranslation("global");
    let query = new URLSearchParams(useLocation().search);

    const [createUsuarioMutation] = useMutation(CREATE_USUARIO_MUTATION, {
        onCompleted: (data) => {
            let returnUrl = "/private/usuario/edit/"+data?.createUsuario?.id;
            if(query!=null && (query.get("returnUrl"))){
                returnUrl = query.get("returnUrl");
            }
            history.push(returnUrl);
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createUsuarioMutation({
            variables:{
                perfil: perfil,
                apellido: apellido,
                email: email,
                nombre: nombre,
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardCreateEntity
            entityName="usuario"
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "fechaAlta",
                    type: "date",
                    format: "dd/MM/yyyy HH:mm",
                    disabled: true,
                    maxLength: 19,
                    value: fechaAlta,
                    setValue: setFechaAlta,
                    componentClassName: "col-sm-10 col-xs-12",
                    labelClassName: "col-sm-2 col-xs-3",
                    inputClassName: "col-sm-3 col-xs-9"
                },
                {
                    name: "nombre",
                    type: "text",
                    maxLength: 50,
                    value: nombre,
                    setValue: setNombre,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("usuario.field.nombre")})
                    },
                    componentClassName: "col-sm-5 col-xs-12",
                    labelClassName: "col-sm-4 col-xs-3",
                    inputClassName: "col-sm-6 col-xs-9"
                },
                {
                    name: "apellido",
                    type: "text",
                    maxLength: 50,
                    value: apellido,
                    setValue: setApellido,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("usuario.field.apellido")})
                    },
                    componentClassName: "col-sm-5 col-xs-12",
                    labelClassName: "col-sm-2 col-xs-3",
                    inputClassName: "col-sm-7 col-xs-9"
                },
                {
                    name: "email",
                    type: "text",
                    maxLength: 50,
                    value: email,
                    setValue: setEmail,
                    validationRules:{
                        required: true,
                        email: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("usuario.field.email")}),
                        email: t("common.message.invalid.email.format")
                    },
                    componentClassName: "col-sm-5 col-xs-12",
                    labelClassName: "col-sm-4 col-xs-3",
                    inputClassName: "col-sm-6 col-xs-9"
                },
                {
                    name: "perfil",
                    type: "select",
                    entity:"Perfil",
                    label:"nombre",
                    value: perfil,
                    setValue: setPerfil,
                    translationKey: "usuario.field.perfil",
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("usuario.field.perfil")})
                    },
                    componentClassName: "col-sm-5 col-xs-12",
                    labelClassName: "col-sm-2 col-xs-3",
                    inputClassName: "col-sm-7 col-xs-9"
                }
            ]}
            returnUrl="/usuario"
            >
        </CardCreateEntity>
    )
}

export default UsuarioCreate