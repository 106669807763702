import { createContext } from "react";
const initial = {
    appState: { loggedIn: false },
    isLogged: () => { },
    gqlError: { msg: '' },
    appSetSchemaTypes: (schemaData) => { },
    appSetLogin: (token, userData) => { },
    appSetLogout: () => { },
    appSetAuthToken: (token) => { },
    appClearAuthToken: () => { },
    appGetExpiredUser: () => { },
    appSetExpiredUser: (userNameExpired) => { },
    appSetExpiredUserError: () => { },
    appGetExpiredUserError: (userNameExpiredError) => { },
    appClearExpiredUser: () => { },
    appGetUserData: () => { },
    refreshCurrentUserData: () => { },
    appSetInvalidSession: () => { },
    appGetInvalidSession: () => { },
}

const AuthContext = createContext(initial);

export default AuthContext;