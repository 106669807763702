import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import ContactoAvatarImg from './ContactoAvatarImg';

export default function UsersOnline({usuariosConectados}) {
    const [t] = useTranslation("global");
    return (
        <li className="nav-item dropdown">
            <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false" title={t("misAlertas.label.usuariosConectados")}>
                <i className="fas fa-plug"></i>
                <span className="badge badge-danger navbar-badge position-absolute">{usuariosConectados?.length}</span>
            </button>
            <div  className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                
                <span className="dropdown-item dropdown-header shadow">{t("common.label.usuariosOnlineCount",{0:usuariosConectados?.length})}</span>
                <div id="scroll-users-body" className="scrolled-content">
                    {usuariosConectados?.map(user=>{
                        return(<div key={user.idUsuario}>
                            <div className="dropdown-divider"></div>
                            <button className="dropdown-item">
                                <div className="media">
                                    <ContactoAvatarImg personaFisica={true} id={user.idContacto} foto={user.avatar} className="profile-small-user-img img-size-32 mr-3 img-circle border-info" alt="User Avatar" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            {user?.alias}<span className="small text-muted ml-2"><i className="fas fa-clock"></i> {moment(user?.ultimaActividad).format("HH:mm")}</span>
                                        </h3>
                                        <p className="text-sm text-muted">Logueado el {moment(user?.fechaCreacion).format("DD MMMM yyyy")}</p>
                                    </div>
                                </div>
                            </button>
                            <div className="dropdown-divider"></div>
                        </div>)
                    })}
                </div>
            </div>
        </li>
    )
}

