import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../hooks/useAlertMessage';

const PROYECTO_POSTULANTE_SEARCH_BY_ID = gql`
    query findProyectoPostulanteById(
        $id: Int!
    ) {
    findProyectoPostulanteById(
        id: $id
    ){
        id
        postulante{
            appraisal
            contacto{
                email
                telefonosFijos
                telefonosMoviles
                linkedin
            }
        }
    }
}
`;

const UPDATE_PROYECTO_POSTULANTE_MUTATION = gql`
    mutation updatePostulanteQAInfoEnProyecto(
        $idProyectoPostulante: ID!
        $email: String!
        $appraisal: String
        $linkedin: String
        $telefonosFijos: String
        $telefonosMoviles: String
    ) {
        updatePostulanteQAInfoEnProyecto(
            datos:{
                idProyectoPostulante: $idProyectoPostulante,
                email: $email,
                appraisal: $appraisal,
                linkedin: $linkedin,
                telefonosFijos: $telefonosFijos,
                telefonosMoviles: $telefonosMoviles
            }
        ) {
            id
        }
    }
`;

export default function EditarPostulanteQa(props) {
    const { idProyectoPostulante, refetch } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const [show, setShow] = useState(false);
    let { showErrorToast, clearPopUps } = useAlertMessage();

    const [state, setState] = useState({
        email: "",
        appraisal: "",
        linkedin: "",
        telefonosFijos: "",
        telefonosMoviles: ""
    });

    const [searchProyectoPostulanteById] = useLazyQuery(PROYECTO_POSTULANTE_SEARCH_BY_ID, {
        onCompleted: (data) => {
            const proyectoPostulante = data?.findProyectoPostulanteById;
            setState({
                email: proyectoPostulante.postulante?.contacto?.email,
                appraisal: proyectoPostulante?.postulante?.appraisal?proyectoPostulante?.postulante?.appraisal:"",
                linkedin: proyectoPostulante?.postulante?.contacto?.linkedin?proyectoPostulante?.postulante?.contacto?.linkedin:"",
                telefonosFijos: proyectoPostulante?.postulante?.contacto?.telefonosFijos?proyectoPostulante?.postulante?.contacto?.telefonosFijos:"",
                telefonosMoviles: proyectoPostulante?.postulante?.contacto?.telefonosMoviles?proyectoPostulante?.postulante?.contacto?.telefonosMoviles:""
            });
        }
    });

    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!state.email){
            errorList.push({message: t("common.message.field.required", {0: t("contacto.field.email")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            searchProyectoPostulanteById({
                variables:{
                    id: idProyectoPostulante
                }
            })
        }
    }, [idProyectoPostulante, searchProyectoPostulanteById, show]);

    const handleChange = (name, evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const [updateProyectoPostulanteMutation] = useMutation(UPDATE_PROYECTO_POSTULANTE_MUTATION,{
        onCompleted: (data) => {
            setShow(false);
            if(refetch){
                refetch();
            }
        },
        onError: (error) => {
        }
    });   

    const handleSave = () =>{
        if(validate()){
            updateProyectoPostulanteMutation({
                variables:{
                    idProyectoPostulante: parseInt(idProyectoPostulante),
                    email: state.email,
                    appraisal: state.appraisal?state.appraisal:null,
                    linkedin: state.linkedin?state.linkedin:null,
                    telefonosFijos: state.telefonosFijos?state.telefonosFijos:null,
                    telefonosMoviles: state.telefonosMoviles?state.telefonosMoviles:null,
                }
            });
        }
    }

    return (
        <>
            <button className="btn btn-warning p-1" title={t("proyecto.button.completarDatosQa")} onClick={()=>setShow(true)}>
                <i className="fas fa-edit fa-rotate-270 fa-2x"></i>
            </button>
            <div ref={ref} className="modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("proyecto.label.editarPostulanteQa")}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body parrish-form">
                            <div className="row">

                                <div className="col-xs-12 col-sm-12">
                                    <div className="parrish-form row ">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-email" className="parrish-label col-sm-2 col-xs-12 text-right required">{t("Email")}</label>
                                                <div className="col-sm-10 col-xs-12">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fas fa-envelope"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-email'}
                                                            className="parrish-input"
                                                            maxLength={150}
                                                            value={state.email}
                                                            onChange={e => handleChange("email", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-tel-fijos" className="parrish-label col-sm-2 col-xs-12 text-right">{t("contacto.field.telefonosFijos")}</label>
                                                <div className="col-sm-10 col-xs-12">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fas fa-phone"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-tel-fijos'}
                                                            className="parrish-input"
                                                            maxLength={150}
                                                            value={state.telefonosFijos}
                                                            onChange={e => handleChange("telefonosFijos", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-tel-moviles" className="parrish-label col-sm-2 col-xs-12 text-right">{t("contacto.field.telefonosMoviles")}</label>
                                                <div className="col-sm-10 col-xs-12">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-whatsapp"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off"
                                                            type="text"
                                                            name={'value-tel-moviles'}
                                                            className="parrish-input"
                                                            maxLength={150}
                                                            value={state.telefonosMoviles}
                                                            onChange={e => handleChange("telefonosMoviles", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-linkedin" className="parrish-label col-sm-2 col-xs-12">{t("postulante.field.linkedin")}</label>
                                                <div className="col-sm-10 col-xs-12">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fab fa-linkedin-in"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete="off" type="text"
                                                            name={'value-linkedin'}
                                                            className="parrish-input"
                                                            value={state.linkedin}
                                                            maxLength={150}
                                                            onChange={e => handleChange("linkedin", e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-12 col-md-12">
                                            <div className="form-group row">
                                                <label htmlFor="value-appraisal" className="parrish-label col-sm-2 col-xs-12 align-self-start">{t("postulante.field.appraisal")}</label>
                                                <div className="col-sm-10 col-xs-12">
                                                    <textarea
                                                        name="value-appraisal"
                                                        value={state.appraisal}
                                                        onChange={(e)=>handleChange("appraisal", e)}
                                                        className="form-control form-control-sm" rows="6"/>   
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                {t("common.button.guardar")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
