import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import PaisList from './list'
import PaisCreate from './create'
import PaisEdit from './edit'
import { useHistory, useParams } from 'react-router';

function PaisIndex(){
    const entityName = "pais";
    const [searchFilter, setSearchFilter] = useState({
        nombre: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <PaisList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></PaisList>
                :
                null
            }
            {
                mode==='create'?
                    <PaisCreate></PaisCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <PaisEdit></PaisEdit>
                :
                null
            }
        </div>
    );
}

export default PaisIndex;