import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import {} from "jquery-validation";
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import DeleteEntityButton from '../../../../../../components/DeleteEntityButton';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';

const CONOCIMIENTO_SEARCH_BY_ID = gql`
    query findConocimientoPostulanteById(
        $id: Int!
    ) {
        findConocimientoPostulanteById(
        id: $id
    ){
        id
        conocimiento{
            id
            tipoConocimiento{
                id
            }
        }
        nivel{
            id
        }
    }
}
`;

const UPDATE_CONOCIMIENTO_MUTATION = gql`
    mutation updateConocimientoPostulante(
        $id: ID!
        $conocimiento: Int!
        $nivel: Int!
    ) {
        updateConocimientoPostulante(
            entity:{
                id: $id
                conocimiento: $conocimiento
                nivel: $nivel
            }
        ) {
            id
        }
    }
`;

export default function EditConocimientoPostulante(props) {
    const { editId, setMode } = props;
    const [t] = useTranslation("global");
    const ref = useRef();

    const [tipoConocimiento, setTipoConocimiento] = useState();
    const [conocimiento, setConocimiento] = useState();
    const [nivel, setNivel] = useState();

    let { showErrorToast } = useAlertMessage();

    const { loading, error, data } = useQuery(
        CONOCIMIENTO_SEARCH_BY_ID,
        {
            variables: {
                id: editId,
            }
        }
    );

    useEffect(() => {
        if (!loading && !error){
            const dataConocimientoPostulante = data?.findConocimientoPostulanteById;
            setTipoConocimiento(parseInt(dataConocimientoPostulante?.conocimiento?.tipoConocimiento?.id));
            setConocimiento(parseInt(dataConocimientoPostulante?.conocimiento?.id));
            setNivel(dataConocimientoPostulante?.nivel?.id);
        }
    }, [data?.findConocimientoPostulanteById, error, loading]);

    const [updateConocimientoPostulanteMutation] = useMutation(UPDATE_CONOCIMIENTO_MUTATION, {
        onCompleted: (data) => {
            setMode("list");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateConocimientoPostulanteMutation({
            variables: {
                id: parseInt(editId),
                conocimiento: parseInt(conocimiento),
                nivel: parseInt(nivel)
            }
        });
    }

    useEffect(() => {
        $(ref.current).validate({
            ignore:"",
            rules: {
                "value-tipo-conocimiento": "required",
                "value-conocimiento": "required",
                "value-nivel": "required"
            },
            messages: {
                "value-tipo-conocimiento":{
                    required: t("common.message.field.required", {0: t("conocimiento.field.tipo-conocimiento")}),
                },
                "value-conocimiento":{
                    required: t("common.message.field.required", {0: t("conocimiento.field.conocimiento")}),
                },
                "value-nivel":{
                    required: t("common.message.field.required", {0: t("conocimiento.field.nivel")}),
                }
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');               
            },
            showErrors: function(errorMap, errorList) {
                showErrorToast(errorList);
            }
        });
    },[showErrorToast, t]);

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
            <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
                <div className="card-header">
                    <h3 className="card-title">{t("common.label.detalle")} {t("common.label.id", { 0: editId })}</h3>
                    <div className="card-tools m-0">
                        <button type="submit" className="btn btn-xs bg-info btn-tool">
                            <i className="fas fa-save"></i>
                        </button>
                        <DeleteEntityButton className="ml-2" entity="ConocimientoPostulante" id={editId} onCompleted={()=>{setMode("list")}} />
                        <button
                            className="btn btn-xs bg-info btn-tool ml-2"
                            onClick={()=>{setMode("list")}}>
                                <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">     
                    <div className="parrish-form row ">
                        <div className="col-xs-12 col-sm-6">
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-genero" className="parrish-label col-sm-4 col-xs-12 required">{t("conocimiento.field.tipo")}</label>
                                    <div className="col">
                                        <SelectKeyValue
                                            entity="TipoConocimiento"
                                            name="value-tipo-conocimiento"
                                            value={tipoConocimiento}
                                            required
                                            onChange={(e) => {
                                                setTipoConocimiento(e.target.value);
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-genero" className="parrish-label col-sm-4 col-xs-12 required">{t("conocimiento.field.conocimiento")}</label>
                                    <div className="col">
                                        <SelectKeyValue
                                            entity="Conocimiento"
                                            by="Tipo"
                                            name="value-conocimiento"
                                            value={conocimiento}
                                            filterValue={tipoConocimiento}
                                            filterName="tipo"
                                            required
                                            onChange={(e) => {
                                                setConocimiento(e.target.value);
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12">
                                <div className="form-group row">
                                    <label htmlFor="value-genero" className="parrish-label col-sm-4 col-xs-12 required">{t("conocimiento.field.nivel")}</label>
                                    <div className="col">
                                        <SelectKeyValue
                                            entity="NivelConocimiento"
                                            name="value-nivel"
                                            value={nivel}
                                            required
                                            onChange={(e) => {
                                                setNivel(e.target.value);
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
