import React from 'react'
import { useTranslation } from "react-i18next"
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import CurrencyFormat from './CurrencyFormat';
import Paginator from './Paginator';

export default function Datatable(props) {
    const {
        data,
        loading,
        error,
        sortOptions,
        dataWrapper,
        entityName,
        columnsDefinition,
        columns,
        customCellDefinition,
        setPageSize,
        setPageNumber,
        setSortOptions,
        pageSize,
        pageNumber,
        editUrl,
        conditionalRowStyle,
        conditionalRowValue,
        headerTop,
        headerBottom,
        onEdit,
        noAutoScroll
    } = props;
    const [t] = useTranslation("global");
    let history = useHistory();

    if (columns!=null && customCellDefinition!=null && columns.length!==customCellDefinition.length) {
        throw new Error("Column and Cell definition are not the same length");
    }

    const getColumns = () =>{
        /*if(columnsDefinition!=null){
            return columnsDefinition;
        }*/
        if(columns!=null){
            return columns;
        }else{
            if(data[dataWrapper]?.edges?.length>0){
                return Object.keys(data[dataWrapper]?.edges[0]?.node).filter(key => !key.includes("__"));
            }else{
                return [];
            }
            
        }
    }

    const isDataColumn = (keyColumn) =>{
        if(data[dataWrapper]?.edges?.length>0){
            return Object.keys(data[dataWrapper]?.edges[0]?.node).find(key => keyColumn === key);
        }else{
            return false;
        }
        
    }
    
    const editElement = (id, element) => {
        if(noAutoScroll===false||noAutoScroll===undefined){
            window.scrollTo(0, 0);
        }
        if(editUrl){
            history.push(editUrl+id);
        }else if(onEdit){
            onEdit(element);
        }
    }

    const TableHeader = () =>{
        const toggleSortColumn = (selectedSortColumn) => {
            console.log("SELECTED SORT:", selectedSortColumn);
            if(selectedSortColumn === sortOptions.sortColumn){
                if(sortOptions.sortDirection.toUpperCase()==="ASC"){
                    setSortOptions({sortColumn: selectedSortColumn, sortDirection: "DESC"})
                }else if(sortOptions.sortDirection.toUpperCase()==="DESC"){
                    setSortOptions({sortColumn: selectedSortColumn, sortDirection: "ASC"})
                }
            }else{
                setSortOptions({sortColumn: selectedSortColumn, sortDirection: "ASC"})
            }
        }

        if(columnsDefinition!=null){
            let colSpanSkip=0;
            return(
                <tr>
                {
                    columnsDefinition.map(column => {
                        if(colSpanSkip===0){
                            colSpanSkip = column.colSpan?column.colSpan-1:0;
                            const translationKey = column.translationKey?column.translationKey:entityName+".field."+column.name;
                            const sortKey = column.customSortKey?column.customSortKey:column.name;
                            let sortingClass = "sorting_disabled";
                            let onRowClick = ()=>{};
                            if((isDataColumn(column.name) || column.customSortKey) && !column.disableSort) {
                                sortingClass = "sorting";
                                onRowClick = (key)=>toggleSortColumn(key);
                                if(sortOptions.sortColumn===sortKey){
                                    if(sortOptions.sortDirection.toUpperCase() === "ASC"){
                                        sortingClass = "sorting_asc";
                                    }else if(sortOptions.sortDirection.toUpperCase() === "DESC"){
                                        sortingClass = "sorting_desc";
                                    }
                                }
                            }
                            const headerClassname = column.customClassHeader?column.customClassHeader:"bg-info";
                            return(
                                <th colSpan={column.colSpan} rowSpan={column.rowSpan} onClick={()=>onRowClick(sortKey)} className={`border-bottom-0 ${headerClassname} ${sortingClass}`} key={column.name}>
                                        {
                                            column.customHeader?
                                                column.customHeader
                                            :
                                                t(translationKey)
                                        }
                                </th>
                            )
                        }else{
                            colSpanSkip--;
                            return(null);
                        }
                    })
                }
                </tr>
            )
        }
        return(
            <tr>
            {
                getColumns().map(key => {                  
                    let sortingClass = "";
                    let onRowClick = ()=>{};
                    if(isDataColumn(key)) {
                        sortingClass = "sorting";
                        onRowClick = (key)=>toggleSortColumn(key);
                        if(sortOptions.sortColumn===key){
                            if(sortOptions.sortDirection.toUpperCase() === "ASC"){
                                sortingClass = "sorting_asc";
                            }else if(sortOptions.sortDirection.toUpperCase() === "DESC"){
                                sortingClass = "sorting_desc";
                            }
                        }
                    }

                    return(
                        <th onClick={()=>onRowClick(key)} className={`bg-info ${sortingClass}`} key={key}>
                            {t(entityName+".field."+key)}
                        </th>
                    )
                })
            }
            </tr>
        );
    }

    const renderCell = (element, columnDefinition) =>{
        if(columnDefinition.render!=null){
            return columnDefinition.render(element);
        }else{
            let elementOutput = element.node;
            let splittedCol = columnDefinition.name.split(".");
            splittedCol.forEach(element => {
                elementOutput = elementOutput?elementOutput[element]:null;
            });
            switch(columnDefinition.type) {
                case 'text':
                    return (elementOutput);
                case 'id':
                    return ("#"+elementOutput);
                case 'date':
                    const format = columnDefinition.format?columnDefinition.format:"DD-MM-yyyy";
                    return (elementOutput&&
                            <Moment className="date-centered" format={format}>{elementOutput}</Moment>
                    );
                case 'money':
                    return (
                        <CurrencyFormat
                            additionalClassName="justify-content-end"
                            prefix={columnDefinition.symbol}
                            value={elementOutput}/>
                    );
                case 'boolean':
                    const trueLabel = columnDefinition.trueLabel?columnDefinition.trueLabel:t("common.value.option.yes");
                    const falseLabel = columnDefinition.falseLabel?columnDefinition.falseLabel:t("common.value.option.no");
                    const trueClassname = columnDefinition.trueClassname?columnDefinition.trueClassname:"text-success";
                    const falseClassname = columnDefinition.falseClassname?columnDefinition.falseClassname:"text-danger";
                    
                    const label = elementOutput?trueLabel:falseLabel;
                    const className = elementOutput?trueClassname:falseClassname;
                    
                    return(
                        <div className={'d-flex justify-content-center '+className}>
                            {label}
                        </div>
                    );
                default:
                    return (
                        <p className="text-danger">Type not implemented</p>
                    );
            }
        }
    }

    const TableContent = () =>{
        if(columnsDefinition!=null){
            return (
                data[dataWrapper].edges.map((element, index) => {
                    let rowStyle = "";
                    if(conditionalRowValue && conditionalRowStyle){
                        rowStyle += conditionalRowValue(element.node)?conditionalRowStyle:"";
                    }
                    return(<tr key={element.node.id} className={rowStyle} onDoubleClick={()=>editElement(element.node.id, element)}>
                    {
                        columnsDefinition.map((column, index) => 
                            <td className={"p-1"+(column.className?" "+column.className:"")} key={column.name}>
                                {renderCell(element, column)}
                            </td>
                        )
                    }
                    </tr>)
                })
            );
        }
        if(customCellDefinition!=null){
            return (
                data[dataWrapper].edges.map((element, index) => (
                    <tr key={element.node.id} onDoubleClick={()=>editElement(element.node.id)}>
                    {
                        getColumns().map((key, index) => 
                            <td className="p-2" key={key}>
                                {customCellDefinition[index](element)}
                            </td>
                        )
                    }
                    </tr>
                ))
            );
        }else{
            return(
                data[dataWrapper].edges.map((element, index) => (
                    <tr key={element.node.id} onDoubleClick={()=>editElement(element.node.id)}>
                    {
                        getColumns().map(key => {
                            let elementOutput = element.node[key];
                            if(key.toLowerCase()==="id"){
                                elementOutput = "#"+element.node[key]
                            }
                            return(<td className="p-2" key={key}>{elementOutput}</td>);
                        })
                    }
                    </tr>
                ))
            );
        }
    }

    const columnsCount = columnsDefinition!=null?columnsDefinition.length:getColumns().length

    return (
        <>
            {
                data &&
                data[dataWrapper] &&
                (
                    <>
                    <div className="row mr-0 ml-0">
                        <div className="table-responsive">
                        {
                            data[dataWrapper].edges &&
                            data[dataWrapper].edges.length>0 &&
                            !loading &&
                            !error &&
                            (
                                <table className="table parrish-table dataTable table-bordered table-striped table-hover">
                                    <thead className="text-align-center">
                                        {headerTop&&
                                            headerTop
                                        }
                                        <TableHeader/>
                                        {headerBottom&&
                                            headerBottom
                                        }
                                    </thead>
                                    <tbody>
                                        <TableContent/>
                                    </tbody>
                                </table>
                            )
                        }
                        {
                            data[dataWrapper].edges &&
                            data[dataWrapper].edges.length===0 &&
                            !loading &&
                            !error &&
                            (
                                <table className="table parrish-table dataTable table-bordered table-striped table-hover">
                                    <thead className="text-align-center">
                                        {headerTop&&
                                            headerTop
                                        }
                                        <TableHeader/>
                                        {headerBottom&&
                                            headerBottom
                                        }
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={columnsCount}>
                                                <p>No se encontraron resultados</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        }
                        </div>
                    </div>
                    <div className="row mr-0 ml-0">
                        <div className="col-12 align-flex-right-row p-0">
                            <Paginator data={data[dataWrapper]} setPageSize={setPageSize} setPageNumber={setPageNumber} pageSize={pageSize} pageNumber={pageNumber} />
                        </div>
                    </div>
                    </>
                )
            }
        </>
    )
}