import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import IdeaList from './list'
import IdeaCreate from './create'
import IdeaEdit from './edit'
import { useHistory, useParams } from 'react-router';

function IdeaIndex(){
    const entityName = "idea";
    const [searchFilter, setSearchFilter] = useState({
        nombre: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <IdeaList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></IdeaList>
                :
                null
            }
            {
                mode==='create'?
                    <IdeaCreate></IdeaCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <IdeaEdit></IdeaEdit>
                :
                null
            }
        </div>
    );
}

export default IdeaIndex;