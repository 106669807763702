import { useQuery, gql, useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import DownloadAdjunto from '../../../../../components/DownloadAdjunto';
import SimpleDatatable from '../../../../../components/SimpleDatatable';
import UploadProyectoAdjunto from '../../../../../components/UploadProyectoAdjunto';
import useAlertMessage from '../../../../../hooks/useAlertMessage';

const FIND_ADJUNTO_LIST_BY_PROYECTO = gql`
    query findAdjuntoListByProyecto(
        $idProyecto: Int!
        $sortColumn: String
        $sortDirection: String
    ) {
        findAdjuntoListByProyecto(
            idProyecto: $idProyecto
            sortCriteria: {
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            }
        ){
            referencia
            fechaActualizacion
            contentType
            nombreOriginal
            nombreServidor
            responsable{
                contacto{
                    alias
                }
            }
        }
    }
`;

const DELETE_PROYECTO_ADJUNTO_MUTATION = gql`
    mutation deleteProyectoAdjunto(
        $filename: String!
    ) {
        deleteProyectoAdjunto(filename: $filename){
            referencia
        }
    }
`;

export default function AdjuntosTab(props) {
    const { idProyecto } = props;
    const [t] = useTranslation("global");
    const { showConfirmDelete } = useAlertMessage();

    const [sortOptions] = useState({
        sortColumn: "id",
        sortDirection: "ASC"
    });

    const {data, refetch} = useQuery(
        FIND_ADJUNTO_LIST_BY_PROYECTO,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                idProyecto: idProyecto,
            }
        }
    );

    const [deleteEntityMutation] = useMutation(DELETE_PROYECTO_ADJUNTO_MUTATION, {
        onCompleted: (data) => {
            refetch();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const deleteAction = (filename) => {
        showConfirmDelete(
            filename,
            filename,
            (filename)=>{deleteEntityMutation({
                variables:{
                    filename: filename
                }
            })}
        );
    }

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-header pr-0 pl-0">
                <h3 className="card-title">{t("common.label.listado")}</h3>
                <div className="card-tools m-0">
                    <UploadProyectoAdjunto idProyecto={idProyecto} className="text-right" onCompleted={()=>{refetch()}} />
                </div>
            </div>
            <div className="card-body pr-0 pl-0">
                <SimpleDatatable
                    data={data?.findAdjuntoListByProyecto}
                    entityName="adjunto"
                    columnsDefinition={[
                        {
                            name: "nombreOriginal",
                            type: "text"
                        },
                        {
                            name: "responsable",
                            render: (element)=>{
                                return(<p><Moment format={"DD-MM-yyyy HH:mm"}>{element.fechaActualizacion}</Moment> [{element.responsable.contacto.alias}]</p>)
                            }
                        },
                        {
                            name: "descargar",
                            customHeader: <i className="fas fa-cloud-download-alt" />,
                            customClassHeader: "bg-info text-center pl-0 pr-0",
                            className: "text-center",
                            render: (element)=>{
                                return(<DownloadAdjunto entity="Proyecto" type="tool" filename={element.nombreServidor} originalFilename={element.nombreOriginal} downloadMode={true} contentType={element.contentType} />)
                            }
                        },
                        {
                            name: "eliminar",
                            customHeader: <i className="fas fa-trash" />,
                            customClassHeader: "bg-info text-center pl-0 pr-0",
                            className: "text-center",
                            render: (element)=>{
                                return(
                                    <button type="button" title={t("common.button.eliminar")} className={"btn btn-xs bg-info "} onClick={()=>{deleteAction(element.nombreServidor)}}>
                                        <i className="fas fa-trash"></i>
                                    </button> 
                                )
                            }
                        }
                    ]}
                />
            </div>
        </div>
    )
}
