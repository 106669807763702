import React from 'react'
import { gql, useQuery } from '@apollo/client';
import CustomSelect from './CustomSelect';
import { useTranslation } from 'react-i18next';

export default function SelectKeyValue(props) {
    const [t] = useTranslation("global");

    const {
        entity,
        displayText,
        disabled,
        forceDisabled,
        avoidList,
        placeholder,
        onChange,
        name,
        value,
        minWidth,
        filterValue,
        filterName,
        filterType,
        isClearable=true,
        by
    } = props;
    const byDef = by?"By"+by:"";
    const queryName = `find${entity}KeyValueList${byDef}`;
    const filterDef = filterName?`($${filterName}: ${filterType?filterType:"Int!"})`:``;
    const filterQuery = filterName?`(${filterName}: $${filterName})`:``;

    const SELECT_LIST = gql`
        query ${queryName}${filterDef} {
            ${queryName}${filterQuery}
            {
                id
                label
            }
        }
    `;

    const isDisabled = () =>{
        if(forceDisabled===false)
            return false;
        if(filterName){
            if(!filterValue){
                return true;
            }
        }
        return disabled;
    }

    const { loading, error, data } = useQuery(SELECT_LIST,{
        variables: filterName?{
            [filterName] : filterValue
        }:null,
        skip: isDisabled()
    });

    const displayLabel = (element) =>{
        if(displayText){
            return (displayText(element));
        }
        return (element.label);
    }

    const getOptions = () => {
        if (loading||error||!data)
            return [];

        return data[queryName]?.filter(element => !avoidList?.includes(parseInt(element.id))).map((element) => {
            return {
                label: displayLabel(element),
                value: element.id
            }
        })
    }

    const onChangeFunc = (value, action) =>{
        const finded = getOptions()?.find(element =>{
            return parseInt(element.value)===parseInt(value?.value);
        });
        onChange({target:{value:value?.value}}, finded?finded.label:"")
    }

    const getDefatulValue = () =>{
        if(value){
            const finded = getOptions()?.find(element =>{
                return parseInt(element.value)===parseInt(value);
            });
            return finded;
        }else{
            return null;
        }
    }

    return(
        <CustomSelect
            {...props}
            isClearable={isClearable}
            minWidth={minWidth}
            placeholder={placeholder?placeholder: t("common.label.defaultSelectPlaceholder")}
            value={getDefatulValue()}
            isDisabled={isDisabled()}
            isLoading={loading}
            isRtl={false}
            isSearchable={false}
            name={name}
            options={getOptions()}
            onChange={onChangeFunc}
        />
    );    
}
