import React from 'react'
import { useTranslation } from "react-i18next"
import MenuIcon from './MenuIcon';
import PrivateLink from './navigation/PrivateLink';

export default function PageHeader(props) {
    const {entityName, keyMenu, completeEntityName} = props;
    const [t] = useTranslation("global");
    return (
        <section className="content-header pl-0 pt-2 pb-2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-sm-10">
                        <div className="d-flex align-items-baseline mt-1 mb-1">
                            <MenuIcon keyMenu={keyMenu?keyMenu: completeEntityName?completeEntityName:(entityName?entityName+'.label.menu':null)} className={"mr-3 header-icon align-self-center"}/>
                            <h3 className="text-nowrap text-truncate font-weight-normal m-0 p-1">{t(completeEntityName?completeEntityName:(entityName+".label.menu"))}</h3>
                            <small className="text-nowrap text-truncate align-self-baseline pl-2 text-muted d-none d-lg-flex">{entityName?t(entityName+".label.description"):""}</small>
                        </div>
                    </div>
                    <div className="col toolbar-col d-none d-sm-flex p-0">
                        <div className="float-sm-right">
                            {/*<PrivateLink className="text-secondary" to="/">
                                <i className="fas fa-question mr-3"></i>
                            </PrivateLink>*/}
                            <PrivateLink className="text-secondary" to="/home">
                                <MenuIcon keyMenu="common.menu.home"/>
                            </PrivateLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
