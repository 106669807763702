import React, { useContext, useEffect, useRef, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client';
import AuthContext from '../../../../context/AuthContext';
import CustomSelect from '../../../../components/CustomSelect';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DeleteEntityButton from '../../../../components/DeleteEntityButton';
import SelectKeyValue from '../../../../components/SelectKeyValue';
import $ from 'jquery'
import useAlertMessage from '../../../../hooks/useAlertMessage';

const FIND_FILTRO_POSTULANTE_LIST_BY_USUARIO = gql`
    query findFiltroPostulanteListByUsuario($idUsuario: Int!){
        findFiltroPostulanteListByUsuario(idUsuario: $idUsuario){
            id
            proyecto{
                id
            }
            fecha
            filtro
            nombre
        }
    }
`;

const CREATE_FILTRO_POSTULANTE_MUTATION = gql`
    mutation createFiltroPostulante(
        $filtro: String!
        $nombre: String!
    ) {
        createFiltroPostulante(
            entity: {
                filtro: $filtro,
                nombre: $nombre
            }
        ) {
            id
            nombre
            fecha
        }
    }
`;

const UPDATE_FILTRO_POSTULANTE_MUTATION = gql`
    mutation updateFiltroPostulante(
        $id: ID!
        $filtro: String!
        $nombre: String!
    ) {
        updateFiltroPostulante(
            entity: {
                id: $id
                filtro: $filtro
                nombre: $nombre
            }
        ) {
            id
            nombre
            fecha
        }
    }
`;

const SHARE_FILTRO_POSTULANTE_MUTATION = gql`
    mutation shareFiltroPostulante(
        $idFiltro: Int!
        $idUsuario: Int!
    ) {
        shareFiltroPostulante(
            idFiltro: $idFiltro
            idUsuario: $idUsuario
        )
    }
`;

export default function FilterStore(props) {
    const { filter, setFilter, onFilterSelect, clearFilter, setClearFilter } = props;
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [mode, setMode] = useState("");
    const [modified, setModified] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState();
    const [filterName, setFilterName] = useState();
    const [t] = useTranslation("global");
    let { showErrorToast, clearPopUps, showSuccess } = useAlertMessage();

    const {loading: loadingFiltros, error: errorFiltros, data: dataFiltros, refetch: refetchFiltros} = useQuery(
        FIND_FILTRO_POSTULANTE_LIST_BY_USUARIO,
        {
            variables: {
                idUsuario: userData?.usuario?.id?parseInt(userData?.usuario?.id):null,
            }
        }
    );

    useEffect(() => {
        if(clearFilter){
            setSelectedFilter(null);
            setClearFilter(false);
        }
    }, [clearFilter, setClearFilter]);

    const [createFiltroPostulanteMutation] = useMutation(CREATE_FILTRO_POSTULANTE_MUTATION,{
        onCompleted: (data) => {
            setMode("");
            setModified(false);
            refetchFiltros();
            setFilterName(data?.createFiltroPostulante?.nombre);
            setSelectedFilter({
                value: data?.createFiltroPostulante?.id?parseInt(data?.createFiltroPostulante?.id):null,
                label: data?.createFiltroPostulante?.nombre?data?.createFiltroPostulante?.nombre:"",
                displayValue: moment(data?.createFiltroPostulante?.fecha).format('DD-MM-YYYY')+" - "+data?.createFiltroPostulante?.nombre
            });
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const [updateFiltroPostulanteMutation] = useMutation(UPDATE_FILTRO_POSTULANTE_MUTATION,{
        onCompleted: (data) => {
            setMode("");
            setModified(false);
            refetchFiltros();
            setFilterName(data?.updateFiltroPostulante?.nombre);
            setSelectedFilter({
                value: data?.updateFiltroPostulante?.id?parseInt(data?.updateFiltroPostulante?.id):null,
                label: data?.updateFiltroPostulante?.nombre?data?.updateFiltroPostulante?.nombre:"",
                displayValue: moment(data?.updateFiltroPostulante?.fecha).format('DD-MM-YYYY')+" - "+data?.updateFiltroPostulante?.nombre
            });
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const [shareFiltroPostulanteMutation] = useMutation(SHARE_FILTRO_POSTULANTE_MUTATION,{
        onCompleted: (data) => {
            $(".modal-backdrop.show").remove();
            showSuccess(t("postulante.message.successShareFilter"), () => {
                $(".modal-backdrop.show").remove();
                setShowShareDialog(false);
            });
        },
        onError: (error) =>{
            console.log(error);
        }
    });

    const saveFiltro = () => {
        const filtro = JSON.stringify(filter);
        if(mode==="edit"||mode===""){
            if(filtro&&filterName){
                updateFiltroPostulanteMutation({variables: {
                    id: parseInt(selectedFilter?.value),
                    filtro: filtro,
                    nombre: filterName
                }});
            }
        }

        if(mode==="create"){
            if(filtro&&filterName){
                createFiltroPostulanteMutation({variables: {
                    filtro: filtro,
                    nombre: filterName
                }});
            }
        }

    }

    useEffect(() => {
        if(selectedFilter&&selectedFilter?.value){
            const filterById = dataFiltros?.findFiltroPostulanteListByUsuario.filter(filtroPostulante => parseInt(filtroPostulante?.id)===parseInt(parseInt(selectedFilter.value)));
            if(filterById&&filterById[0]!==undefined){
                const selectedFiltro = filterById?filterById[0].filtro:"";
                const realFiltro = JSON.stringify(filter);
                
                if(selectedFiltro!==realFiltro){
                    setModified(true);
                }else{
                    setModified(false);
                }
            }
        }
    }, [dataFiltros?.findFiltroPostulanteListByUsuario, filter, selectedFilter])

    const ShareDialog = ({show, setShow}) => {
        const ref = useRef();
        const [usuario, setUsuario] = useState("");

        const validate = () => {
            clearPopUps();
            let errorList = [];
            if(!usuario){
                errorList.push({message: t("common.message.field.required", {0: t("postulante.label.usuario")})});
            }
            if(errorList.length>0){
                showErrorToast(errorList);
            }
            return errorList.length === 0;
        }

        const shareFiltro = () =>{
            if(validate()){
                shareFiltroPostulanteMutation({variables:{
                    idFiltro: parseInt(selectedFilter?.value),
                    idUsuario: parseInt(usuario)
                }})
            }
        }

        useEffect(() => {
            $(ref.current).modal(show?'show':'hide');
        }, [show]);
        return (
            <div ref={ref} className="modal" data-backdrop="static" role="dialog">
                { show &&
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header pl-3 p-2">
                                <h5 className="modal-title">{t("postulante.label.compartirFiltro")}</h5>
                                <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body parrish-form">
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="form-group row">
                                            <label htmlFor="value-postulante" className="parrish-label col-2 text-right required">{t("postulante.label.usuario")}</label>
                                            <div className="col-10">
                                                <SelectKeyValue
                                                    entity="UsuarioActivo"
                                                    by="Alias"
                                                    name="value-responsable"
                                                    value={usuario}
                                                    onChange={(e) => {
                                                        setUsuario(e.target.value);
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bg-info ml-1" onClick={()=>{shareFiltro()}}>
                                    {t("postulante.button.compartir")}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (<>
        <ShareDialog show={showShareDialog} setShow={setShowShareDialog}/>
        {(mode==="create"||mode==="edit")?
            <div className="d-flex align-items-center w-100">
                <input autoComplete="off"
                    type="text"
                    placeholder={t("postulante.field.nombreFiltro")}
                    name={'value-nombreFilter'}
                    className="parrish-input"
                    value={filterName}
                    minLength={150}
                    onChange={(e) => setFilterName(e.target.value)}/>
                <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" disabled={!filter||!filterName} onClick={()=>{saveFiltro()}}>
                    <i className="fas fa-save"></i>
                </button>
                <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" onClick={()=>{setMode()}}>
                    <i className="fas fa-times"></i>
                </button>
            </div>
            :
            <div className="d-flex align-items-center w-100">
                <CustomSelect
                    isClearable={true}
                    name="value-filters"
                    value={selectedFilter}
                    onChange={(selected)=>{
                        setSelectedFilter(selected);
                        if(selected?.value){
                            const newFilter = dataFiltros?.findFiltroPostulanteListByUsuario.filter(filtroPostulante => {
                                return parseInt(filtroPostulante?.id)===parseInt(selected.value);
                            });
                            if(newFilter&&newFilter[0]){
                                const jsonNewFilter = JSON.parse(newFilter[0].filtro);
                                setFilter(jsonNewFilter);
                                setFilterName(selected.label);
                                onFilterSelect(jsonNewFilter)
                            }
                        }
                    }}
                    formatOptionLabel={({ value, label, displayValue}) =>displayValue}
                    options={(dataFiltros?.findFiltroPostulanteListByUsuario&&!loadingFiltros&&!errorFiltros)?
                        dataFiltros?.findFiltroPostulanteListByUsuario.map(element=>{
                            return({
                                value: element.id,
                                label: element.nombre,
                                displayValue: moment(element.fecha).format('DD-MM-YYYY')+" - "+element.nombre
                            })
                        })
                    :
                        []}
                />

                <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" disabled={false} onClick={()=>{
                    setFilterName("");
                    setMode("create")
                }}>
                    <i className="fas fa-plus"></i>
                </button>
                {selectedFilter&&
                    <>
                        <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" disabled={false} onClick={()=>{
                            setMode("edit")
                        }}>
                            <i className="fas fa-edit"></i>
                        </button>
                        <DeleteEntityButton className="ml-2" entity="FiltroPostulante" id={selectedFilter?.value} label={selectedFilter?.displayValue} onCompleted={()=>{
                            setSelectedFilter(null)
                            refetchFiltros();
                        }} />
                        <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" disabled={false} onClick={()=>setShowShareDialog(true)}>
                            <i className="fas fa-share-alt"></i>
                        </button>
                    </>
                }
                {modified?
                    <button type="button" className="btn btn-xs btn-info bg-info btn-tool ml-2" disabled={!filter||!filterName} onClick={()=>{saveFiltro()}}>
                        <i className="fas fa-save"></i>
                    </button>
                :
                    null
                }
            </div>
        }
    </>)
}
