import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const QACHECK_SEARCH_BY_ID = gql`
    query findQACheckById(
        $id: Int!
    ) {
    findQACheckById(
        id: $id
    ){
        id
        nombre
        descripcion
    }
}
`;

const UPDATE_QACHECK_MUTATION = gql`
    mutation updateQACheck(
        $id: ID!
        $nombre: String!
        $descripcion: String!
    ) {
        updateQACheck(entity:{
            id: $id
            nombre: $nombre
            descripcion: $descripcion
    }) {
            id
        }
    }
`;

const DELETE_QACHECK_MUTATION = gql`
    mutation deleteQACheck(
        $id: Int!
    ) {
        deleteQACheck(id: $id)
    }
`;

function QACheckEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        QACHECK_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setNombre(data?.findQACheckById?.nombre);
            setDescripcion(data?.findQACheckById?.descripcion);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findQACheckById?.nombre,
        data?.findQACheckById?.descripcion
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateQACheckMutation] = useMutation(UPDATE_QACHECK_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/qaCheck";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteQACheckMutation] = useMutation(DELETE_QACHECK_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/qaCheck");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateQACheckMutation({
            variables:{
                id: id,
                nombre: nombre,
                descripcion: descripcion
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteQACheckMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="qaCheck"
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 100,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("qaCheck.field.nombre")})
                        }
                    },
                    {
                        type: "textarea",
                        name: "descripcion",
                        value: descripcion,
                        setValue: setDescripcion,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("qaCheck.field.descripcion")})
                        },
                        componentClassName: "col-sm-12 col-xs-12",
                        labelClassName: "col-sm-2 col-xs-12",
                        inputClassName: "col-sm-4 col-xs-12"
                    }
                ]}
                returnUrl="/qaCheck"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default QACheckEdit