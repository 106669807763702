import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import ContactoList from './list'
import ContactoCreate from './create'
import ContactoEdit from './edit'
import { useHistory, useParams } from 'react-router';

function ContactoIndex(){
    const entityName = "agenda-contactos";
    const [searchFilter, setSearchFilter] = useState({
        etiqueta: "",
        texto: "",
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName="contacto" />
            {
                mode==='list'?
                    <ContactoList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></ContactoList>
                :
                null
            }
            {
                mode==='create'?
                    <ContactoCreate></ContactoCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <ContactoEdit></ContactoEdit>
                :
                null
            }
        </div>
    );
}

export default ContactoIndex;