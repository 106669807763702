import React, { useContext } from 'react'
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UsersOnline from './UsersOnline';
import AuthContext from '../context/AuthContext';

const MIS_ALERTAS = gql`
    query misAlertas{
        misAlertas{
            cantidadOffLimitsVencidos
            cantidadComprobantesPendientesAprobacion
            cantidadComprobantesPendientesEmision
            cantidadMovimientosPendientesConciliacion
            cantidadPostulantesEmailsFalsos
            usuariosConectados{
                fechaCreacion
                ultimaActividad
                idUsuario
                idContacto
                alias
                avatar
            }
        }
}
`;


export default function Alertas() {
    const { isLogged } = useContext(AuthContext);
    const [t] = useTranslation("global");
    let history = useHistory();
    const {loading, error, data} = useQuery(MIS_ALERTAS, {
        skip: !isLogged(),
        pollInterval: 10000
    });

    return (!loading&&!error&&
        <>
        <UsersOnline usuariosConectados={data?.misAlertas?.usuariosConectados}/>
        {data?.misAlertas?.cantidadPostulantesEmailsFalsos?
        <li className="nav-item dropdown" title={t("misAlertas.label.cantidadPostulantesEmailsFalsos")} onClick={()=>history.push("/private/postulante")}>
            <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                <i className="far fa-envelope"></i>
                <span className="badge badge-danger navbar-badge position-absolute">{data?.misAlertas?.cantidadPostulantesEmailsFalsos}</span>
            </button>
        </li>
        :null
        }
        {data?.misAlertas?.cantidadOffLimitsVencidos?
            <li className="nav-item dropdown" title={t("misAlertas.label.cantidadOffLimitsVencidos")} onClick={()=>history.push("/private/offLimit")}>           
                <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-user-slash"></i>
                    <span className="badge badge-danger navbar-badge position-absolute">{data?.misAlertas?.cantidadOffLimitsVencidos}</span>
                </button>
            </li>
        :null
        }
        
        {data?.misAlertas?.cantidadComprobantesPendientesAprobacion?
        <li className="nav-item dropdown" title={t("misAlertas.label.cantidadComprobantesPendientesAprobacion")} onClick={()=>history.push("/private/proyecto")}>
            <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-user-check"></i>
                <span className="badge badge-danger navbar-badge position-absolute">{data?.misAlertas?.cantidadComprobantesPendientesAprobacion}</span>
            </button>
        </li>
        :null
        }
        {data?.misAlertas?.cantidadComprobantesPendientesEmision?
        <li className="nav-item dropdown" title={t("misAlertas.label.cantidadComprobantesPendientesEmision")} onClick={()=>history.push("/private/facturacion")}>
            <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-dollar-sign"></i>
                <span className="badge badge-danger navbar-badge position-absolute">{data?.misAlertas?.cantidadComprobantesPendientesEmision}</span>
            </button>
        </li>
        :null
        }
        {data?.misAlertas?.cantidadMovimientosPendientesConciliacion?
        <li className="nav-item dropdown" title={t("misAlertas.label.cantidadMovimientosPendientesConciliacion")} onClick={()=>history.push("/private/conciliacion")}>
            <button className="nav-link btn" data-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-check-double"></i>
                <span className="badge badge-danger navbar-badge position-absolute">{data?.misAlertas?.cantidadMovimientosPendientesConciliacion}</span>
            </button>
        </li>
        :null
        }
        
    </>)
}
