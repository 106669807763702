import TIPO_COMPROBANTE from '../enums/TipoComprobante'

const COMPROBANTES_ID_NOTA_CREDITO = [
    TIPO_COMPROBANTE.NOTA_DEBITO_A,
    TIPO_COMPROBANTE.NOTA_DEBITO_B,
    TIPO_COMPROBANTE.NOTA_DEBITO_CEA,
    TIPO_COMPROBANTE.NOTA_DEBITO_CEB,
    TIPO_COMPROBANTE.NOTA_DEBITO_E,
    TIPO_COMPROBANTE.NOTA_CREDITO_A,
    TIPO_COMPROBANTE.NOTA_CREDITO_B,
    TIPO_COMPROBANTE.NOTA_CREDITO_CEA,
    TIPO_COMPROBANTE.NOTA_CREDITO_CEB,
    TIPO_COMPROBANTE.NOTA_CREDITO_E
];

export default class TipoComprobanteHelper {
    static isNotaCreditoDebito = (comprobanteId) => {
        try {
            const intId = parseInt(comprobanteId)
            return COMPROBANTES_ID_NOTA_CREDITO.includes(intId);
        } catch (error) {
            console.error(error);
        }
        return false;
    }
}