import React from 'react'
import NumberFormat from 'react-number-format'

export default function CurrencyFormat(props) {
    const { decimalScale, prefix, value, className, additionalClassName, numberClassName, prefixClassName } = props;
    return (
        <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={decimalScale?decimalScale:2}
            fixedDecimalScale="true"
            value={Math.abs(value)}           
            displayType="text"
            renderText={(formattedValue)=>{
                return (<span className={"d-flex "+((className?className:(value<0?"text-danger":""))+(additionalClassName?(" "+additionalClassName):""))}>
                    {prefix&&
                        <span className={prefixClassName?prefixClassName:""}>{prefix} </span>
                    }
                    <span className={numberClassName?numberClassName:(value<0?"negative-format":"")}>{formattedValue}</span>
                </span>);
            }}/>
    )
}
