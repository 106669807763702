import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SidebarContext from '../context/SidebarContext';
import DownloadAdjuntosDialog from './DownloadAdjuntosDialog';
import FlagButtonBoolean from './FlagButtonBoolean';

export default function FlagsEmpresa(props) {
    const { setSideBarState, show } = useContext(SidebarContext);
    const [t] = useTranslation("global");
    const {empresa} = props;

    useEffect(() => {
        
    }, [])

    const [showAdjuntoDialog, setShowAdjuntoDialog] = useState(false);
    const [adjuntosList, setAdjuntosList] = useState();

    const openAdjuntosDialog = (adjuntoList) =>{
        setAdjuntosList(adjuntoList);
        setShowAdjuntoDialog(true);
    }

    const adjuntoClick= (e) =>{
        e.preventDefault();
        if(empresa.adjuntoList&&empresa.adjuntoList.length>0){
            openAdjuntosDialog(empresa.adjuntoList);
        }
    }

    return(
        <>
            <DownloadAdjuntosDialog entity="Empresa" show={showAdjuntoDialog} setShow={setShowAdjuntoDialog} adjuntosList={adjuntosList}/>
            <div id={"empresa-"+empresa?.id} className="flag-container ml-3 mr-3">
                <div className="row d-flex justify-content-center">
                    <div className={"small-flag bg-info"+(empresa.adjuntoList&&empresa.adjuntoList.length>0?" on":" off")} data-toggle="tooltip" data-html="true" title={empresa.adjuntoList&&empresa.adjuntoList.length>0?t("empresa.label.adjuntos"):null} onClick={(e)=>{adjuntoClick(e)}}>
                        <i className="fas fa-paperclip"></i>
                    </div>
                    <FlagButtonBoolean
                        iconImg="/icons/cliente_24.png"
                        title={t("empresa.label.esClienteTalenters")}
                        value={empresa.cliente}
                    />
                    <FlagButtonBoolean
                        icon="fab fa-searchengin"
                        onClass={"bg-info"}
                        offClass={"bg-info"}
                        title={t("empresa.label.tieneProyectosActivos")}
                        value={empresa.tieneProyectoActivo}
                    />
                </div>
                <div className="row d-flex justify-content-center">
                    <FlagButtonBoolean
                        icon="fas fa-shoe-prints fa-rotate-270"
                        onClass="bg-yellow"
                        offClass="bg-yellow"
                        value={true}
                        title={t("common.label.seguimiento")}
                        setValue={()=>{
                            setSideBarState({
                                mode: "seguimiento",
                                empresa: empresa.id,
                                labelEmpresa: empresa?.contacto?.nombre,
                                labelPostulante: null,
                                labelProyecto: null,
                                postulante: null,
                                proyecto: null,
                                responsable: null,
                                accionSeguimiento: null,
                            })
                            show();
                        }}
                    />
                    <FlagButtonBoolean
                        icon="fas fa-user-secret"
                        onClass="bg-yellow"
                        offClass="bg-yellow"
                        value={true}
                        title={t("common.label.referencia")}
                        setValue={()=>{
                            setSideBarState({
                                mode: "referencias",
                                labelProyecto: null,
                                proyecto: null,
                                labelPostulante: null,
                                postulante: null,
                                empresa: empresa.id,
                                labelEmpresa: empresa?.contacto?.nombre,
                            })
                            show();
                        }}
                    />
                </div>
            </div>
        </>
    );
}
