import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';
import Prioridad from '../enums/Prioridad';
import EstadoMejora from '../enums/EstadoMejora';
import AuthContext from '../../../../context/AuthContext';

const IDEA_SEARCH_BY_ID = gql`
    query findIdeaById(
        $id: Int!
    ) {
    findIdeaById(
        id: $id
    ){
        id
        aprobadoPor{
            id
            contacto{
                id
                alias
            }
        }
        solicitadoPor{
            id
            contacto{
                id
                alias
            }
        }
        fechaAprobacion
        fechaEstimacion
        fechaFinalizacion
        fechaSolicitud
        comentarioAprobacion
        comentarioEstimacion
        descripcion
        esfuerzoEstimado
        estado
        prioridad
        titulo
    }
}
`;

const UPDATE_IDEA_MUTATION = gql`
    mutation updateIdea(
        $id: ID!
        $comentarioAprobacion: String
        $comentarioEstimacion: String
        $descripcion: String!
        $esfuerzoEstimado: String
        $prioridad: String!
        $titulo: String!
    ) {
        updateIdea(entity:{           
            id: $id
            comentarioAprobacion: $comentarioAprobacion
            comentarioEstimacion: $comentarioEstimacion
            descripcion: $descripcion
            esfuerzoEstimado: $esfuerzoEstimado
            prioridad: $prioridad
            titulo: $titulo
        }) {
            aprobadoPor{
                id
            }
            id
            solicitadoPor{
                id
            }
            fechaAprobacion
            fechaEstimacion
            fechaFinalizacion
            fechaSolicitud
            comentarioAprobacion
            comentarioEstimacion
            descripcion
            esfuerzoEstimado
            estado
            prioridad
            titulo
        }
    }
`;

const DELETE_IDEA_MUTATION = gql`
    mutation deleteIdea(
        $id: Int!
    ) {
        deleteIdea(id: $id)
    }
`;

const APROBAR_IDEA_MUTATION = gql`
    mutation aprobarIdea(
        $id: Int!
        $comentario: String!
    ) {
        aprobarIdea(
            id: $id
            comentario: $comentario
        ){
            id
        }
    }
`;

const ESTIMAR_IDEA_MUTATION = gql`
    mutation estimarIdea(
        $id: Int!
        $estimacion: String!
        $comentario: String!
    ) {
        estimarIdea(
            id: $id
            estimacion: $estimacion
            comentario: $comentario
        ){
            id
        }
    }
`;

const TERMINAR_IDEA_MUTATION = gql`
    mutation terminarIdea(
        $id: Int!
    ) {
        terminarIdea(
            id: $id
        ){
            id
        }
    }
`;

const CANCELAR_APROBACION_IDEA_MUTATION = gql`
    mutation cancelarAprobacionIdea(
        $id: Int!
    ) {
        cancelarAprobacionIdea(
            id: $id
        ){
            id
        }
    }
`;

const CANCELAR_ESTIMACION_IDEA_MUTATION = gql`
    mutation cancelarEstimacionIdea(
        $id: Int!
    ) {
        cancelarEstimacionIdea(
            id: $id
        ){
            id
        }
    }
`;

function IdeaEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete } = useAlertMessage();

    const { clearPopUps, showErrorToast } = useAlertMessage();

    const [comentarioAprobacion, setComentarioAprobacion] = useState('');
    const [comentarioEstimacion, setComentarioEstimacion] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [esfuerzoEstimado, setEsfuerzoEstimado] = useState('');
    const [estado, setEstado] = useState('');
    const [prioridad, setPrioridad] = useState('');
    const [titulo, setTitulo] = useState('');

    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        IDEA_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setComentarioAprobacion(data?.findIdeaById?.comentarioAprobacion);
            setComentarioEstimacion(data?.findIdeaById?.comentarioEstimacion);
            setDescripcion(data?.findIdeaById?.descripcion);
            setEsfuerzoEstimado(data?.findIdeaById?.esfuerzoEstimado);
            setEstado(data?.findIdeaById?.estado);
            setPrioridad(data?.findIdeaById?.prioridad);
            setTitulo(data?.findIdeaById?.titulo);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findIdeaById?.aprobadoPor?.id,
        data?.findIdeaById?.solicitadoPor?.id,
        data?.findIdeaById?.fechaAprobacion,
        data?.findIdeaById?.fechaEstimacion,
        data?.findIdeaById?.fechaFinalizacion,
        data?.findIdeaById?.fechaSolicitud,
        data?.findIdeaById?.comentarioAprobacion,
        data?.findIdeaById?.comentarioEstimacion,
        data?.findIdeaById?.descripcion,
        data?.findIdeaById?.esfuerzoEstimado,
        data?.findIdeaById?.estado,
        data?.findIdeaById?.prioridad,
        data?.findIdeaById?.titulo
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateIdeaMutation] = useMutation(UPDATE_IDEA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/idea";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteIdeaMutation] = useMutation(DELETE_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [aprobarIdea] = useMutation(APROBAR_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [estimarIdea] = useMutation(ESTIMAR_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [terminarIdea] = useMutation(TERMINAR_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [cancelarAprobacionIdea] = useMutation(CANCELAR_APROBACION_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [cancelarEstimacionIdea] = useMutation(CANCELAR_ESTIMACION_IDEA_MUTATION, {
        onCompleted: (data) => {
            history.push("/private/idea");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateIdeaMutation({
            variables:{
                id: id,
                comentarioAprobacion: comentarioAprobacion,
                comentarioEstimacion: comentarioEstimacion,
                descripcion: descripcion,
                esfuerzoEstimado: esfuerzoEstimado,
                prioridad: prioridad,
                titulo: titulo
           }
        })
    }

    // TODO PGEN check if it goes with secure delete
    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteIdeaMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    const isSoporte = () =>{
        return userData?.usuario?.email==="soporte@parrish.com.ar";
    }

    const isOwner = () =>{
        return userData?.usuario?.id===data?.findIdeaById?.aprobadoPor?.id;
    }

    const inputEstimacion = data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO||isSoporte()?[{
        name: "esfuerzoEstimado",
        type: "text",
        value: esfuerzoEstimado,
        setValue: setEsfuerzoEstimado,
        disabled: data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO,
        iconText: "hrs",
        iconPosition: "right",
        componentClassName: "col-sm-10 col-12",
        labelClassName: "col-sm-2 col-12 required",
        inputClassName: "col-sm-2 col-9"
    },
    {
        name: "text",
        render: () =>{
            return(
                <div className="form-group row">
                    <label htmlFor="value-comentario-estimacion" className="parrish-label align-self-start required col-sm-2 col-xs-3 ">Comentario Estimación</label>
                    <div className="col-sm-4 col-xs-9">
                        <textarea name="value-comentario-estimacion" value={comentarioEstimacion} onChange={(e)=>{
                            setComentarioEstimacion(e?.target?.value)
                        }} className="form-control form-control-sm" rows="3" disabled={data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO}></textarea>
                    </div>
                    <label htmlFor="value-comentario-aprobacion" className="parrish-label align-self-start required col-sm-2 col-xs-3 ">Comentario Aprobación</label>
                    <div className="col-sm-4 col-xs-9">
                        <textarea name="value-comentario-aprobacion" value={comentarioAprobacion} onChange={(e)=>{setComentarioAprobacion(e?.target?.value)}} className="form-control form-control-sm" rows="3" disabled={isSoporte()||data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.ESTIMADO}></textarea>
                    </div>
                </div>
            )
        },
        componentClassName: "col-sm-10 col-12"
    }]:[];

    const cancelarAprobacion = () => {
        cancelarAprobacionIdea({
            variables:{
                id: id
            }
        })
    }

    const terminarMejora = () => {
        terminarIdea({
            variables:{
                id: id
            }
        })
    }

    const cancelarEstimacion = () => {
        cancelarEstimacionIdea({
            variables:{
                id: id
            }
        })
    }

    const aprobarMejora = () => {
        if(validateAprobarMejora()){
            aprobarIdea({
                variables:{
                    id: id,
                    comentario: comentarioAprobacion
                }
            })
        }
    }

    const validateAprobarMejora = () => {
        clearPopUps();
        let errorList = [];
        if(!comentarioAprobacion){
            errorList.push({message: t("common.message.field.required", {0: t("idea.field.comentarioAprobacion")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const estimarMejora = () => {
        if(validateEstimarMejora()){
            estimarIdea({
                variables:{
                    id: id,
                    estimacion: esfuerzoEstimado,
                    comentario: comentarioEstimacion
                }
            })
        }
    }

    const validateEstimarMejora = () => {
        clearPopUps();
        let errorList = [];
        if(!esfuerzoEstimado){
            errorList.push({message: t("common.message.field.required", {0: t("idea.field.esfuerzoEstimado")})});
        }
        if(!comentarioEstimacion){
            errorList.push({message: t("common.message.field.required", {0: t("idea.field.comentarioEstimacion")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const disableSave = () => {
        if(isSoporte()){
            return data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO;
        }
        return data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO;
    }

    const disableDelete = () => {
        if(isSoporte()){
            return false;
        }
        return data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO
    }


    return (
        <CardEditEntity
            entityName="idea"
            disableSave={disableSave()}
            disableDelete={disableDelete()}
            customHeader={<span className={"badge ml-2 ideas-status-"+(estado?estado.toLowerCase():"")}>{estado}</span>}
            customTools={
                <>
                    {data?.findIdeaById?.estado?.toUpperCase()===EstadoMejora.APROBADO&&
                        <>
                            {isOwner()&&
                                <button type="button" title={t("idea.label.cancelarAprobacion")} className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{cancelarAprobacion()}}>
                                    <i className="fas fa-ban"></i>
                                </button>
                            }
                            {isSoporte()&&
                                <button type="button" title={t("idea.label.terminar")} className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{terminarMejora()}}>
                                    <i className="fas fa-gavel"></i>
                                </button>
                            }
                        </>
                    }
                    {data?.findIdeaById?.estado?.toUpperCase()===EstadoMejora.ESTIMADO&&
                        <>
                            {isSoporte()&&
                                <button type="button" title={t("idea.label.cancelarEstimacion")} disabled={!isSoporte()} className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{cancelarEstimacion()}}>
                                    <i className="fas fa-ban"></i>
                                </button>
                            }
                            {!isSoporte()&&
                                <button type="button" title={t("idea.label.aprobar")} className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{aprobarMejora()}}>
                                    <i className="far fa-handshake"></i>
                                </button>
                            }
                        </>
                    }
                    {data?.findIdeaById?.estado?.toUpperCase()===EstadoMejora.PROPUESTO&&isSoporte()&&
                        <button type="button" title={t("idea.label.estimar")} className="btn btn-xs bg-info btn-tool ml-2" onClick={()=>{estimarMejora()}}>
                            <i className="far fa-clock"></i>
                        </button>
                    }
                </>
            }
            columnsCount={2}
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "fechaSolicitud",
                    type: "date",
                    maxLength: 10,
                    value: data?.findIdeaById?.fechaSolicitud,
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "solicitadoPor",
                    type: "text",
                    value: data?.findIdeaById?.solicitadoPor?.contacto?.alias,
                    disabled: true,
                    componentClassName: "col-sm-3 col-12",
                    labelClassName: "d-none",
                    inputClassName: "col-sm-12 col-12"
                },
                {
                    name: "fechaEstimacion",
                    type: "date",
                    maxLength: 10,
                    value: data?.findIdeaById?.fechaEstimacion?data?.findIdeaById?.fechaEstimacion:"",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "fechaAprobacion",
                    type: "date",
                    maxLength: 10,
                    value: data?.findIdeaById?.fechaAprobacion?data?.findIdeaById?.fechaAprobacion:"",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "aprobadoPor",
                    type: "text",
                    value: data?.findIdeaById?.aprobadoPor?.contacto?.alias,
                    disabled: true,
                    componentClassName: "col-sm-3 col-12",
                    labelClassName: "d-none",
                    inputClassName: "col-sm-12 col-12"
                },
                {
                    name: "fechaFinalizacion",
                    type: "date",
                    maxLength: 10,
                    value: data?.findIdeaById?.fechaFinalizacion?data?.findIdeaById?.fechaFinalizacion:"",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "prioridad",
                    validationJson:{
                        rules: {
                            "value-prioridad":{
                                required: true
                            },
                        },
                        messages: {
                            "value-prioridad":{
                                required: t("common.message.field.required", {0: t("idea.field.prioridad")}),
                            },                           
                        }
                    },
                    render: () =>{
                        return(
                            <div className="form-group row">
                                <label htmlFor="value-prioridad" className="parrish-label required col-sm-2 col-xs-3 ">{t("idea.field.prioridad")}</label>
                                <div className="col-sm-2 col-12">
                                    <select name="value-prioridad" value={prioridad} disabled={data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO} className="parrish-input" onChange={(e)=>setPrioridad(e.target.value)}>
                                        <option value=""></option>
                                        {Object.entries(Prioridad).map(([key, value]) => {
                                            return <option key={key} value={key}>{value}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        )
                    },
                    componentClassName: "col-sm-10 col-12"
                },
                {
                    name: "titulo",
                    disabled: data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO,
                    type: "text",
                    value: titulo,
                    setValue: setTitulo,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("idea.field.titulo")}),
                    },
                    componentClassName: "col-sm-10 col-12",
                    labelClassName: "col-sm-2 col-12",
                    inputClassName: "col-sm-6 col-12"
                },
                {
                    name: "descripcion",
                    validationJson:{
                        rules: {
                            "value-descripcion":{
                                required: true
                            },
                        },
                        messages: {
                            "value-descripcion":{
                                required: t("common.message.field.required", {0: t("idea.field.descripcion")}),
                            },                           
                        }
                    },
                    render: () =>{
                        return(
                            <div className="form-group row">
                                <label htmlFor="value-descripcion" className="parrish-label align-self-start required col-sm-2 col-xs-3 ">Descripción</label>
                                <div className="col-sm-10 col-12">
                                    <textarea values={descripcion} name="value-descripcion" disabled={data?.findIdeaById?.estado?.toUpperCase()!==EstadoMejora.PROPUESTO} className="form-control form-control-sm" rows="3" value={descripcion} onChange={(e)=>{setDescripcion(e.target.value)}}></textarea>
                                </div>
                            </div>
                        )
                    },
                    componentClassName: "col-sm-10 col-12"
                },
                ...inputEstimacion
            ]}
            returnUrl="/idea"
            id={id}
            label={data?.findIdeaById?.solicitadoPor?.contacto?.alias}
            deleteAction={deleteAction}
            loading={loading}
            error={error}
            >
        </CardEditEntity>
    )
}

export default IdeaEdit
