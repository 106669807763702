import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import SelectEntity from '../../../../../components/SelectEntity';
import useAlertMessage from '../../../../../hooks/useAlertMessage';
import TIPO_PLACEMENT from '../../../../../enums/TipoPlacement';
import DatePicker from '../../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import SelectKeyValue from '../../../../../components/SelectKeyValue';
import { DateRfc }  from '../../../DateRfc';

const UPDATE_PLACEMENT_MUTATION = gql`
    mutation updatePlacement(
        $idProyectoPostulante: Int!
        $areaEmpresa: Int!
        $cargo: Int!
        $pais: Int!
        $fechaInicio: LocalDate!
        $fechaOriginacion: LocalDate!
        $sueldoBrutoMensual: Int!
        $beneficios: String
        $bonus: String
        $posicion: String
        $tipoPlacement: Int!
    ) {
        updatePlacement(
            entity:{
                idProyectoPostulante: $idProyectoPostulante
                areaEmpresa: $areaEmpresa
                cargo: $cargo
                pais: $pais
                fechaInicio: $fechaInicio
                fechaOriginacion: $fechaOriginacion
                sueldoBrutoMensual: $sueldoBrutoMensual
                beneficios: $beneficios
                bonus: $bonus
                posicion: $posicion
                tipoPlacement: $tipoPlacement
            }
        ) {
            id
        }
    }
`;

export default function EditarPlacementDialog(props) {
    const { proyectoPostulante, refetch, show, setShow } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const { showErrorToast, clearPopUps } = useAlertMessage();

    const [state, setState] = useState({
        empresaLabel: "",
        areaEmpresa: "",
        cargo: "",
        pais: "",
        fechaInicio: "",
        fechaOriginacion: new DateRfc(),
        posicion: "",
        moneda: "",
        bonus: "",
        beneficios: "",
        sueldoBrutoMensual: "",
        tipoPlacement: ""
    });
    
    const [updatePlacementMutation] = useMutation(UPDATE_PLACEMENT_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });
    
    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!state.cargo){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.cargo")})});
        }
        if(!state.pais){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.pais")})});
        }
        if(!state.moneda){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.moneda")})});
        }
        if(!state.sueldoBrutoMensual){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.sueldoBrutoMensual")})});
        }
        if(!state.areaEmpresa){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.areaEmpresa")})});
        }
        if(!state.fechaOriginacion){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.fechaOriginacion")})});
        }
        if(!state.fechaInicio){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.fechaInicio")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    const setStateValue = (name, value) =>{
        setState({
            ...state,
            [name]: value
        });
    }

    const handleSave = () =>{
        if(validate()){
            updatePlacementMutation({
                variables:{
                    idProyectoPostulante: parseInt(proyectoPostulante.id),
                    areaEmpresa: state.areaEmpresa?parseInt(state.areaEmpresa):null,
                    cargo: state.cargo?parseInt(state.cargo):null,
                    pais: state.pais?parseInt(state.pais):null,
                    fechaInicio: state.fechaInicio?state.fechaInicio:null,
                    fechaOriginacion: state.fechaOriginacion?new DateRfc(state.fechaOriginacion):null,
                    sueldoBrutoMensual: state.sueldoBrutoMensual?state.sueldoBrutoMensual:null,
                    beneficios: state.beneficios?state.beneficios:null,
                    bonus: state.bonus?state.bonus:null,
                    posicion: state.posicion?state.posicion:null,
                    tipoPlacement: state.tipoPlacement?parseInt(state.tipoPlacement):null,
                }
            });
        }
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            const experienciaLaboral = proyectoPostulante?.experienciaLaboral;
            setState({
                empresaLabel: experienciaLaboral.empresa?experienciaLaboral.empresa.contacto.alias:"",
                cargo: experienciaLaboral.cargo?parseInt(experienciaLaboral.cargo.id):"",
                pais: experienciaLaboral.pais?parseInt(experienciaLaboral.pais.id):"",
                moneda: experienciaLaboral.moneda?parseInt(experienciaLaboral.moneda.id):"",
                sueldoBrutoMensual: experienciaLaboral.sueldoBrutoMensual?experienciaLaboral.sueldoBrutoMensual:"",
                areaEmpresa: experienciaLaboral.areaEmpresa?parseInt(experienciaLaboral.areaEmpresa.id):"",
                posicion: experienciaLaboral.posicion?experienciaLaboral.posicion:"",
                fechaInicio: experienciaLaboral.fechaInicio?new DateRfc(experienciaLaboral.fechaInicio):null,
                fechaOriginacion: proyectoPostulante.fechaOriginacion?new DateRfc(proyectoPostulante.fechaOriginacion):null,
                beneficios: experienciaLaboral.beneficios?experienciaLaboral.beneficios:"",
                bonus: experienciaLaboral.bonus?experienciaLaboral.bonus:"",
                tipoPlacement: proyectoPostulante?.tipoPlacement?proyectoPostulante.tipoPlacement:""
            });
        }
    }, [proyectoPostulante, show]);

    return (
        <div ref={ref} className="modal" role="dialog">
            {show&&
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("proyecto.label.editarPlacement")+": "+proyectoPostulante.postulante.contacto.alias}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("postulante.field.tipoPlacement")}</label>
                                <div className="col-10 d-flex align-items-center">
                                    <div className="custom-control custom-radio d-flex mr-2">
                                        <input autoComplete="off" id="etapa-quick-win" name="value-prioridad"
                                            onChange={(e)=>{setStateValue("tipoPlacement", e?.target?.value);}}
                                            checked={parseInt(state.tipoPlacement) === TIPO_PLACEMENT.PLACEMENT}
                                            className="custom-control-input custom-control-input-info"
                                            value={TIPO_PLACEMENT.PLACEMENT}
                                            type="radio"/>
                                        <label htmlFor="etapa-quick-win" className="custom-control-label d-flex align-items-center">
                                            <span className="ml-1">{t("postulante.field.placement")}</span>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex mr-2 ml-2">
                                        <input autoComplete="off" id="etapa-interesting-match" name="value-prioridad"
                                            onChange={(e)=>{setStateValue("tipoPlacement", e?.target?.value);}}
                                            checked={parseInt(state.tipoPlacement) === TIPO_PLACEMENT.DOUBLE_PLACEMENT}
                                            className="custom-control-input custom-control-input-info"
                                            value={TIPO_PLACEMENT.DOUBLE_PLACEMENT}
                                            type="radio"/>
                                        
                                        <label htmlFor="etapa-interesting-match" className="custom-control-label d-flex align-items-center">                                           
                                            <span className="ml-1">{t("postulante.field.doublePlacement")}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-empresa" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.empresa")}</label>
                                        <div className="col">
                                            <input autoComplete="off" className="parrish-input" disabled={true} value={state.empresaLabel}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-areaEmpresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.areaEmpresa")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="AreaEmpresa"
                                                by="Nombre"
                                                name="value-areaEmpresa"
                                                value={state.areaEmpresa}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("areaEmpresa", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-cargo" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.cargo")}</label>
                                        <div className="col">
                                            <SelectEntity
                                                entity="Cargo"
                                                label="nombre"
                                                name="value-cargo"
                                                value={state.cargo}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("cargo", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-posicion" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.posicion")}</label>
                                        <div className="col">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-posicion'}
                                                className="parrish-input"
                                                value={state.posicion}
                                                maxLength={150}
                                                onChange={(e) => {
                                                    setStateValue("posicion", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.pais")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="Pais"
                                                label="nombre"
                                                name={'value-pais'}
                                                value={state.pais}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("pais", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-fecha-originacion" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.fechaOriginacion")}</label>
                                            <div className="col-8">
                                                <DatePicker
                                                    name={'value-fecha-originacion'}
                                                    selected={state.fechaOriginacion}
                                                    onChange={(date) => {
                                                        setStateValue("fechaOriginacion", date)
                                                    }} />
                                            </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-sueldo-bruto" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.sueldoBrutoMensual")}</label>
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <SelectEntity
                                                        entity="Moneda"
                                                        label="simbolo"
                                                        name="value-moneda"
                                                        value={state.moneda}
                                                        required
                                                        disabled
                                                        onChange={(e) => {
                                                            setStateValue("moneda", e?.target?.value);
                                                        }}/>
                                                </div>

                                                <div className="col-8">
                                                    <NumberFormat
                                                        name="value-sueldo-bruto"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        value={state.sueldoBrutoMensual}
                                                        className={"parrish-input"}
                                                        onChange={(e) => {
                                                            const val = e.target?.value;
                                                            if(val){
                                                                setStateValue("sueldoBrutoMensual", parseInt(val.replaceAll(".","").replaceAll(",",".")));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.fechaInicio")}</label>
                                            <div className="col-8">
                                                <DatePicker
                                                    name={'value-periodo-inicio'}
                                                    selected={state.fechaInicio}
                                                    onChange={(date) => {
                                                        setStateValue("fechaInicio", date)
                                                    }}
                                                    selectsStart
                                                    startDate={state.fechaInicio}
                                                    endDate={state.fechaFin}
                                                    dateFormat="MM-yyyy"
                                                    showMonthYearPicker/>
                                            </div>
                                    </div>
                                </div>
                                
                                <div className="col-xs-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-beneficios" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.beneficios")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-beneficios" value={state.beneficios} onChange={(e)=>{setStateValue("beneficios", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-bonus" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.bonus")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-bonus" value={state.bonus} onChange={(e)=>{setStateValue("bonus", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                {t("proyecto.button.guardarPlacement")}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
