import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Datatable from '../../../../../components/Datatable';
import AuthContext from '../../../../../context/AuthContext';
import Moment from 'react-moment';
import moment from 'moment'
import FlagButtonBoolean from '../../../../../components/FlagButtonBoolean';
import FlagsProyecto from '../../../../../components/FlagsProyecto';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import { useHistory } from 'react-router-dom';

const PROYECTOS_LIST = gql`
    query findProyectoPostulantePageByPostulante(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $id: Int!
    ) {
        findProyectoPostulantePageByPostulante(
        postulante: $id,
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                proyecto{
                    id
                    fechaInicioBusqueda
                    ref
                    fechaCierreAdministrativo
                    fechaCierreQa
                    fechaCierreBusqueda
                    fechaOriginacionPlacement
                    areaEmpresa{
                        nombre
                    }
                    cargo{
                        id
                        nombre
                    }
                    posicion
                    estado{
                        id
                        nombre
                        color
                    }
                    empresa{
                        contacto{
                            id
                            foto
                            personaFisica
                            alias
                            email
                            telefonosMoviles
                        }
                    }
                    contacto{
                        id
                        foto
                        personaFisica
                        alias
                        email
                        telefonosMoviles
                    }
                    qaInfo{
                        indice
                        recomendaciones
                    }
                    adjuntoList{
                        referencia
                        fechaActualizacion
                        contentType
                        nombreOriginal
                        nombreServidor
                        responsable{
                            contacto{
                                alias
                            }
                        }
                    }
                    placementList{
                        id
                        label
                    }
                }
                etapa{
                    id
                    nombre
                }
                estado{
                    esFinal
                    nombre
                }
                prioridad{
                    id
                    nombre
                    color
                }
                responsableList {
                    contacto {
                        alias
                    }
                }
            }
        }
    }
}
`;

export default function ProyectosTab() {
    let history = useHistory();
    let { id } = useParams();
    const [t] = useTranslation("global");
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    
    const [sortOptions, setSortOptions] = useState({
        sortColumn: "proyecto.fechaInicioBusqueda",
        sortDirection: "DESC"
    });
    
    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if(id){
            setPageNumber(0)
        }
    }, [id]);

    const whatsappClick= (e,nro) =>{
        e.preventDefault();
        if(nro){
            const cleanNumber = nro.replace(/\D/g, '');
            window.location.href = "whatsapp://send?phone=" + cleanNumber;
        }
    }

    const emailClick= (e,email) =>{
        e.preventDefault();
        if(email){
            window.location.href = "mailto:" + email;
        }
    }

    const {loading, error, data} = useQuery(
        PROYECTOS_LIST,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                id: id
            }
        }
    );

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-body pr-0 pl-0">
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findProyectoPostulantePageByPostulante"
                    entityName="proyecto"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onEdit={(element)=>{
                        history.push("/private/proyecto/edit/"+element.node.proyecto.id)
                    }}
                    headerTop={
                        <tr>
                            <th colSpan="7" className="bg-info pr-2">
                                <p></p>
                            </th>
                            <th colSpan="3" className="bg-info pr-2">
                                <span>{t("postulante.label.avanceProyecto")}</span>
                            </th>
                        </tr>
                    }
                    columnsDefinition={[
                        {
                            name: "proyecto",
                            customSortKey: "proyecto.contacto.alias",
                            translationKey:"proyecto.field.proyecto",
                            className: "col-2",
                            render: (element) =>{
                                return(
                                    <>
                                        <div className="row">
                                            <div className="col d-flex align-items-center justify-content-center pr-3 pl-3">
                                                <div className="text-center d-flex flex-column mt-3 mb-3">
                                                    <div className="text-center d-flex flex-row align-items-center justify-content-center flex-fill">
                                                        <FlagsProyecto proyecto={element.node.proyecto}/>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <p className={"badge mt-2 text-uppercase badge-"+element.node.proyecto.estado.color+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.bus")}: </span>
                                                            {element.node.proyecto.estado.nombre}
                                                        </p>
                                                        <p className={"badge text-uppercase badge-"+(element.node.proyecto.fechaCierreAdministrativo ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.adm")}: </span>
                                                            {element.node.proyecto.fechaCierreAdministrativo ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    
                                                        <p className={"badge text-uppercase badge-"+(element.node.proyecto.fechaCierreQa ?"danger":"success")+" align-self-center p-1 m-0"}>
                                                            <span>{t("proyecto.field.qa")}: </span>
                                                            {element.node.proyecto.fechaCierreQa ? t("proyecto.field.cerrado") : t("proyecto.field.abierto") }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-center flex-fill">
                                                    <ContactoAvatarImg personaFisica={element.node.proyecto.empresa.contacto.personaFisica} id={element.node.proyecto.empresa.contacto.id} foto={element.node.proyecto.empresa.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={element.node.proyecto.qaInfo?.recomendaciones?element.node.proyecto.qaInfo?.recomendaciones:null}>
                                                        <span className="h5 text-nowrap">{t("common.label.qa")} {element.node.proyecto.qaInfo.indice}%</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h5>{element.node.proyecto.ref}</h5>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        },
                        {
                            name: "inicioPlacement",
                            customSortKey:"proyecto.fechaInicioBusqueda",
                            render: (element)=>{
                                const from = moment(element.node.proyecto.fechaInicioBusqueda);
                                const to = element.node.proyecto.fechaPlacement?moment(element.node.proyecto.fechaPlacement):moment();
                                const days = to.diff(from, 'days');
                                const textDesde = "["+days+" días]"
                                return(<>
                                    {element.node.proyecto.fechaInicioBusqueda&&
                                    <p>
                                        <span><b>{t("common.label.inicio")}: </b></span><Moment format={"DD-MM-yyyy"}>{element.node.proyecto.fechaInicioBusqueda}</Moment>

                                    </p>
                                    }
                                    {element.node.proyecto.fechaPlacement&&
                                        <p>
                                            <span><b>{t("proyecto.field.placement")}: </b></span><Moment format={"DD-MM-yyyy"}>{element.node.proyecto.fechaPlacement}</Moment>
                                        </p>
                                    }
                                    <p>
                                        <span><b>{t("common.label.duracion")}: </b></span>{textDesde}
                                    </p>
                                    <p><span className="text-bold">{t("postulante.field.responsables")}: </span></p>
                                    {element.node.responsableList.map(element=>
                                        <h5 key={element.contacto.alias} className='m-0' ><span className="badge badge-info m-0 p-1 font-weight-normal">{element.contacto.alias}</span></h5>
                                    )}
                                </>)
                            }
                        },
                        {
                            name: "ref",
                            translationKey:"proyecto.field.busquedaPerfil",
                            disableSort: true,
                            type: "text",
                            render: (element)=>{
                                return(<>
                                    <p><span className="text-bold">{t("proyecto.field.areaEmpresa")}: </span>{element.node.proyecto.areaEmpresa.nombre}</p>
                                    <p><span className="text-bold">{t("proyecto.field.cargo")}: </span>{element.node.proyecto.cargo.nombre}</p>
                                    <p><span className="text-bold">{t("proyecto.field.posicion")}: </span>{element.node.proyecto.posicion}</p>
                                </>)
                            }
                        },
                        {
                            name: "placements",
                            translationKey:"proyecto.field.placements",
                            disableSort: true,
                            type: "text",
                            render: (element)=>{
                                return(<>
                                    {element.node.proyecto.placementList.map(element=>
                                        <p key={element.label}>{element.label}</p>
                                    )}
                                </>)
                            }
                        },
                        {
                            name: "contacto",
                            type: "text",
                            disableSort: true,
                            render: (element)=>{
                                return(<>
                                    <p className="text-bold">{element.node.proyecto.contacto?.alias}</p>
                                    {element.node.proyecto.contacto?.email&&
                                        <div role="link" class="parrish-link-light" onClick={(e)=>{emailClick(e,element.node.proyecto.contacto?.email)}}>
                                            <i className="fas fa-envelope mr-1"></i>
                                            {element.node.proyecto.contacto?.email}
                                        </div>
                                    }
                                    {element.node.proyecto.contacto?.telefonosFijos&&
                                        <p><i className="far fas fa-phone mr-1"></i>{element.node.proyecto.contacto?.telefonosFijos}</p>
                                    }
                                    {element.node.proyecto.contacto?.telefonosMoviles&&
                                        <div role="link" class="parrish-link-light" onClick={(e)=>{whatsappClick(e,element.node.proyecto.contacto?.telefonosMoviles)}}>
                                            <i className="fab fa-whatsapp mr-1"></i>
                                            {element.node.proyecto.contacto?.telefonosMoviles}
                                        </div>
                                    }
                                </>);
                            }
                        },
                        {
                            name: "prioridad",
                            type: "text",
                            disableSort: true,
                            className: "text-center",
                            rowSpan:2,
                            headerClassname: "text-center sorting_disabled",
                            customHeader: <div className="m-0 d-flex justify-content-center"><FlagButtonBoolean 
                                    icon="fas fa-running"
                                    onClass="bg-maroon"
                                    title={t("postulante.field.prioridad")}
                                    value={true}
                                /></div>,
                            render: (element)=>{
                                return(<div className="d-flex justify-content-center">
                                    <FlagButtonBoolean 
                                        icon="fas fa-running"
                                        onClass={"bg-"+element.node.prioridad.color}
                                        title={element.node.prioridad.nombre}
                                        value={true}
                                    />
                                </div>);
                            }
                        },
                        {
                            name: "etapa",
                            customSortKey:"etapa.id",
                            translationKey: "postulante.field.etapa",
                            type: "text",
                            render: (element)=>{
                                return(<>{element.node.etapa.nombre}</>);
                            }
                        },
                        {
                            name: "estadoProyecto",
                            translationKey: "postulante.field.estado",
                            disableSort: true,
                            type: "text",
                            render: (element)=>{
                                return(<><p className={element.node.estado.esFinal?"text-danger":"text-success"}>{element.node.estado.nombre}</p></>);
                            }
                        }
                    ]}/>
            </div>
        </div>
    )
}