import moment from 'moment';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import AppStateContext from "../context/AuthContext"
import PrivateLink from './navigation/PrivateLink';
import UserAvatarImg from './UserAvatarImg';

export default function UserTools() {
    const [t] = useTranslation("global");
    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    return (
        <>
            <button className="nav-link btn dropdown-toggle d-flex flex-row align-items-center p-2" data-toggle="dropdown" aria-expanded="false">
                <UserAvatarImg className="user-image img-circle elevation-2 m-0 mr-2" alt="User Avatar" />
                <span className="d-none d-md-inline">{t("layout.label.welcome-message", { 0: userData?.usuario?.nombre })}</span>
            </button>
            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: 'inherit', right: '0px' }}>
                <li className="user-header bg-info">
                    <UserAvatarImg className="img-circle elevation-2" alt="User Avatar" />
                    {}
                    <p>
                        {t("{{0}} {{1}}", { 0: userData?.usuario?.nombre, 1: userData?.usuario?.apellido })}<br />
                        {t("[ {{0}} ]", { 0: userData?.usuario?.perfil?.nombre })}
                        <small>{t("layout.label.member-since", { 0: moment(userData?.usuario?.fechaAlta).format('DD/MM/YYYY')})}</small>
                    </p>
                </li>
                <li className="user-footer">
                    <PrivateLink className="btn btn-default btn-flat" to="/mi-perfil">
                        <i className="fas fa-user mr-1"></i>
                        {t("layout.label.mi-perfil")}
                    </PrivateLink>
                    <PrivateLink className="btn btn-default btn-flat float-right" to="/logout">
                        <i className="fas fa-sign-out-alt mr-1"></i>
                        {t("layout.label.logout")}
                    </PrivateLink>
                </li>
            </ul>
        </>
    )
}
