import {useCallback, useContext, useState} from 'react';
import AppStateContext from "../context/AuthContext";
import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import ConfigData from '../config.json'

const LOGIN_MUTATION = gql`
    mutation login(
        $email: String!
        $password: String!
    ) {
        login(input:{ email: $email, password: $password }) {
            authenticationToken
            menuList{
                nombre
                orden
                nivel
                accion
                imagen
                grantedToPerfil
            }
            usuario{
                id
                fechaAlta
                nombre
                apellido
                email
                idioma{
                    codigo
                    nombre
                }
                perfil{
                    nombre
                }
                contacto{
                    id
                    alias
                }
            }
            parametroList{
                nombre
                valor
            }
        }
    }
`;

const LOGOUT_MUTATION = gql`
    mutation logout{
        logout{
            infoCode
            infoMessage
        }
    }
`;

export default function useUser() {
    const history = useHistory();
    
    const { appSetLogin, appSetLogout, appSetExpiredUser, appClearExpiredUser } = useContext(AppStateContext);
    const [state, setstate] = useState({loading: false, error: false, lastUsername: null});

    const [loginMutation] = useMutation(LOGIN_MUTATION, {
        onCompleted: (data) => {
            if(data.login!=null && data.login.authenticationToken!=null && data.login.authenticationToken!==""){
                window.sessionStorage.removeItem("MIS_NOVEDADES_PENDIENTES");
                const userData = data.login;
                const token = userData.authenticationToken;
                delete userData.authenticationToken;
                
                setstate({loading: false, error: false, lastUsername: null});
                appClearExpiredUser();
                appSetLogin(token, userData);
                history.push("/private/home");
            }
        },
        onError: (error) => {
            console.error(error);
            let errorCode;
            if(error?.graphQLErrors?.length>0){
                errorCode = error.graphQLErrors[0].extensions?.code;
            }
            
            if(errorCode === ConfigData.EXPIRED_USER_ERROR_CODE||errorCode === ConfigData.PASSWORD_UPDATED_POLICIES_ERROR_CODE){
                appSetExpiredUser(state.lastUsername);
            }

            if(errorCode === ConfigData.INVALID_SESSION_ERROR_CODE){
                console.log("Invalid session...");
                appSetLogout();
                history.push("/");
            }
            
            setstate({loading: false, error: true, lastUsername: null});
            appSetLogout();
        }
    }, [appSetLogin, setstate]);

    const [logoutMutation] = useMutation(LOGOUT_MUTATION, {
        onCompleted: (data) => {
            console.log(JSON.stringify(data));
            appSetLogout();
            history.push("/");
        },
        onError: (error) => {
            console.log(JSON.stringify(error));
            //console.error(error);
            //Redirect to login
            //window.location.reload();
        }
    }, [appSetLogin, setstate]);

    const logout = useCallback(() =>{
        console.log("Logout...");
        logoutMutation();
    }, [logoutMutation])

    const clearToken = useCallback(() =>{
        console.log("Clearing token...");
        appSetLogout();
    }, [appSetLogout])

    const clearErrors = useCallback(() =>{
        console.log("Clearing error...");
        setstate({loading: false, error: false})
    }, [setstate])

    const login = useCallback((email, password) =>{
        console.log("Login...");
        setstate({loading: true, error: false, lastUsername: email})
        loginMutation({variables: { email: email, password: password }});
    }, [loginMutation, setstate])

    return {
        isLoginLoading: state.loading,
        hasLoginError: state.error,
        login,
        logout,
        clearToken,
        clearErrors
    }
}