import React, { useState } from 'react'
import ConocimientoPostulanteList from './list'
import ConocimientoPostulanteCreate from './create'
import ConocimientoPostulanteEdit from './edit'

export default function ConocimientoPostulanteTab(props) {
    const { idPostulante } = props;
    const [mode, setMode] = useState("list")
    const [editId, setEditId] = useState("list")

    return (
        <>
            {mode==="list"&&
                <ConocimientoPostulanteList setMode={setMode} idPostulante={idPostulante} setEditId={setEditId}/>
            }
            {mode==="create"&&
                <ConocimientoPostulanteCreate setMode={setMode} idPostulante={idPostulante}/>
            }
            {mode==="edit"&&
                <ConocimientoPostulanteEdit setMode={setMode} editId={editId}/>
            }
        </>
    )
}