import React, { useState, useContext } from 'react'
import useUser from "../../../hooks/useUser";
import ConfigData from "../../../config.json"
import { Link, useParams } from 'react-router-dom';
import logoImg from '../../../assets/images/logo.png'
import Swal from 'admin-lte/plugins/sweetalert2/sweetalert2.all'
import { useRef, useEffect } from "react";
import $ from 'jquery'
import {} from "jquery-validation";
import { useTranslation } from "react-i18next"
import AppStateContext from "../../../context/AuthContext"; 
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import useAlertMessage from '../../../hooks/useAlertMessage';
import SecurityPageWrapper from './SecurityPageWrapper';

const EMAIL_CONFIRMATION = gql`
    mutation emailConfirmation(
        $token: String!
    ){
        emailConfirmation(token: $token){
            infoCode
            infoMessage
        }
    }
`;

export default function Login() {
    const [t] = useTranslation("global");
    let { token } = useParams();
    const [username, setUsername] = useState(localStorage.username);
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState((!!localStorage.rememberMeEnable)?localStorage.rememberMeEnable:false);
    const { isLoginLoading, hasLoginError, login, clearErrors } = useUser();
    const ref = useRef();
    const { isExpired, appGetInvalidSession, appSetInvalidSession } = useContext(AppStateContext);

    let { showSuccess, showErrorToast } = useAlertMessage();

    useEffect(() => {
        if(appGetInvalidSession()){
            showErrorToast([{message: t("common.message.invalid.session")}])
            appSetInvalidSession(false);
        }
    }, [appGetInvalidSession, appSetInvalidSession, showErrorToast, t])

    const [emailConfirmationMutation] = useMutation(EMAIL_CONFIRMATION,{
        onCompleted: (data) => {
            setUsername("");
            setIsChecked(false);
            showSuccess(data?.emailConfirmation?.infoMessage, () => {});
        },
        onError: (data) => {
            console.log(data);
        }
    });

    useEffect(() => {
        if(token){
            emailConfirmationMutation({
                variables:{
                    token: token
                }
            })
        }
    }, [emailConfirmationMutation, token])
    
    useEffect(() => {
        $(ref.current).validate({
            rules: {
              email: {
                required: true,
                email: true,
              },
              password: {
                required: true,
                minlength: 5
              },
            },
            messages: {
              email: {
                required: t("common.message.field.required", {0: t("login.label.placeholders.email")}),
                email: t("common.message.invalid.email.format")
              },
              password: {
                required: t("common.message.field.required", {0: t("login.label.placeholders.password")}),
                minlength: t("common.message.field.invalid.format", {0: t("login.label.placeholders.password")})
              },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
              error.addClass('invalid-feedback');
              element.closest('.input-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
              $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass('is-invalid');
            }
        });   
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.rememberMeEnable = isChecked;
        if (isChecked && username !== "") {
            localStorage.username = username;
        } else {
            localStorage.username = "";
        }
        login(username, password);
    }

    if (hasLoginError && !isLoginLoading && isExpired()) {
        Swal.fire({
            icon: 'error',
            text: "La cuenta de usuario ha expirado",
            onClose: () => {
                clearErrors();
            }
        });
    }

    return (
        <SecurityPageWrapper>
            <div className="login-box">
                <div className="card">
                    <div className="card-header text-align-center mt-3">
                        <img src={logoImg} alt={ConfigData.APP_TITLE_NAME} />
                    </div>
                    <div className="card-body login-card-body">
                        
                        <p className="login-box-msg">{t("login.label.login.title")}</p>
                        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">
                            <div className="input-group mb-3">
                                <input autoComplete="off" className="form-control"
                                    name="email"
                                    type="text"
                                    placeholder={t("login.label.placeholders.email")}
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input autoComplete="off" className="form-control"
                                    name="password"
                                    type="password"
                                    placeholder={t("login.label.placeholders.password")}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="custom-control custom-checkbox">
                                        <input className="custom-control-input custom-control-input-info" 
                                            type="checkbox" 
                                            id="remember" 
                                            checked={JSON.parse(isChecked)}
                                            onChange={(e) => setIsChecked(e.target.checked)} />
                                        <label className="custom-control-label" htmlFor="remember">{t("login.label.rememberme")}</label>
                                    </div>

                                </div>
                                <div className="col-6 forgot-password-col">
                                    <Link className="text-info" to="/forgot-password">{t("login.label.forgotpassword.label")}</Link>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <button type="submit" disabled={isLoginLoading} className="btn btn-info btn-block">{t("common.button.entrar")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer text-align-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-align-center">{t("common.label.poweredby")} <a className="text-info" href="http://www.parrish.com.ar/">Parrish Software Factory</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SecurityPageWrapper>
    )
}
