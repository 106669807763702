
const PERMISOS_ESPECIALES = {
    ACCESO_COMERCIAL_ADMINISTRATIVO: 2,
    ALERTA_PENDIENTES_FACTURACION: 3,
    RECIBIR_EMAIL_MOVIMIENTO_APROBADO: 4,
    ALERTA_PENDIENTES_COBRANZA: 5,
    ALERTA_OFFLIMITS_VENCIDOS: 6,
    HABILITAR_QUICKSEARCH_EMPRESAS: 7,
    HABILITAR_QUICKSEARCH_POSTULANTES: 8,
    HABILITAR_QUICKSEARCH_PROYECTOS: 9,
    ALERTA_PENDIENTES_APROBACION: 10,
    ALERTA_PENDIENTES_CONCILIACION: 11,
    ALERTA_POSTULANTES_EMAILS_FALSOS: 12,
    HABILITAR_QUICKLINK_EMPRESAS: 13,
    HABILITAR_QUICKLINK_POSTULANTES: 14,
    HABILITAR_QUICKLINK_PROYECTOS: 15,
}
Object.freeze(PERMISOS_ESPECIALES)

export default PERMISOS_ESPECIALES


