import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

export default function FollowTab(props) {
    const [t] = useTranslation("global");
    const { followUp } = props;
    const [followUpData, setFollowUpData] = useState([]);

    useEffect(() => {
        if(followUp){
            setFollowUpData(Object.keys(followUp).filter(key => key!=="__typename").sort((a,b) => {return a.etapa < b.etapa}).map(key=>{
                return(followUp[key]);
            }));
        }
    }, [followUp])


    const getDataGraph = ()=>{
        const dataGraph = {
            labels: followUpData.map(element=>element.label?element.label.charAt(0):""),
            datasets: [
                {
                    label: 'Descartados',
                    data: followUpData.map(element=>element.descartados),
                    backgroundColor: 'rgba(210, 214, 222, 1)',
                    borderColor: 'rgba(210, 214, 222, 1)',
                },
                {
                    label: 'Activos',
                    data: followUpData.map(element=>element.activos),
                    backgroundColor: '#00a09b',
                    borderColor: '#00a09b',
                }
            ],
        };
        return dataGraph;
    }
    
    const options = {
        plugins: {
            title: {
                display: false,
                text: t('postulante.label.estadoPostulanteProyecto')
            },
            legend: {
                display: false
            },
        },
        responsive: true,
        scales: {
            x: {
            },
            y: {
                grace: '5%',
            }
        },
    };

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            <div className="card-body col-12 align-self-center p-0">
                <Bar height={100} data={getDataGraph()} options={options} />
            </div>
        </div>
    )
}