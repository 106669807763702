import React, { useState, useEffect, useRef, useContext } from 'react'
import { useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import { useTranslation } from "react-i18next"
import CardCreateEntity from '../../../../components/CardCreateEntity';
import { useHistory, useLocation } from "react-router-dom";
import AppStateContext from "../../../../context/AuthContext.js";
import { DateRfc }  from '../../DateRfc';


const CREATE_IDEA_MUTATION = gql`
    mutation createIdea(
        $descripcion: String!
        $prioridad: String!
        $titulo: String!
    ) {
        createIdea(entity:{
            descripcion: $descripcion
            prioridad: $prioridad
            titulo: $titulo
        }) {
            aprobadoPor{
                id
            }
            id
            solicitadoPor{
                id
            }
            fechaAprobacion
            fechaEstimacion
            fechaFinalizacion
            fechaSolicitud
            comentarioAprobacion
            comentarioEstimacion
            descripcion
            esfuerzoEstimado
            estado
            prioridad
            titulo
        }
    }
`;

function IdeaEdit() {
    const history = useHistory();

    const { appGetUserData } = useContext(AppStateContext);
    const userData = appGetUserData();

    const [descripcion, setDescripcion] = useState('');
    const [prioridad, setPrioridad] = useState('');
    const [titulo, setTitulo] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);
    let query = new URLSearchParams(useLocation().search);

    const [createIdeaMutation] = useMutation(CREATE_IDEA_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/idea";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        createIdeaMutation({
            variables:{
                descripcion: descripcion,
                prioridad: prioridad,
                titulo: titulo
            }
        })
    }
    
    useEffect(() => {
        return () => { _isMounted.current = false };
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <CardCreateEntity
            entityName="idea"
            columnsCount={2}
            handleSubmit={handleSubmit}
            values={[
                {
                    name: "fechaSolicitud",
                    type: "date",
                    maxLength: 10,
                    value: new DateRfc(),
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "solicitadoPor",
                    type: "text",
                    value: userData?.usuario?.contacto?.alias,
                    disabled: true,
                    componentClassName: "col-sm-3 col-12",
                    labelClassName: "d-none",
                    inputClassName: "col-sm-12 col-12"
                },
                {
                    name: "fechaEstimacion",
                    type: "date",
                    maxLength: 10,
                    value: "",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "fechaAprobacion",
                    type: "date",
                    maxLength: 10,
                    value: "",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "aprobadoPor",
                    type: "text",
                    value: "",
                    disabled: true,
                    componentClassName: "col-sm-3 col-12",
                    labelClassName: "d-none",
                    inputClassName: "col-sm-12 col-12"
                },
                {
                    name: "fechaFinalizacion",
                    type: "date",
                    maxLength: 10,
                    value: "",
                    disabled: true,
                    componentClassName: "col-sm-4 col-12",
                    labelClassName: "col-sm-5 col-12",
                    inputClassName: "col-sm-7 col-12"
                },
                {
                    name: "prioridad",
                    validationJson:{
                        rules: {
                            "value-prioridad":{
                                required: true
                            },
                        },
                        messages: {
                            "value-prioridad":{
                                required: t("common.message.field.required", {0: t("idea.field.prioridad")}),
                            },                           
                        }
                    },
                    render: () =>{
                        return(
                            <div className="form-group row">
                                <label htmlFor="value-prioridad" className="parrish-label required col-sm-2 col-xs-3 ">{t("idea.field.prioridad")}</label>
                                <div className="col-sm-2 col-12">
                                    <select name="value-prioridad" value={prioridad} className="parrish-input" onChange={(e)=>setPrioridad(e.target.value)}>
                                        <option value=""></option>
                                        <option value="BAJA">BAJA</option>
                                        <option value="MEDIA">MEDIA</option>
                                        <option value="ALTA">ALTA</option>
                                    </select>
                                </div>
                            </div>
                        )
                    },
                    componentClassName: "col-sm-10 col-12"
                },
                {
                    name: "titulo",
                    type: "text",
                    value: titulo,
                    setValue: setTitulo,
                    validationRules:{
                        required: true
                    },
                    validationMessages:{
                        required: t("common.message.field.required", {0: t("idea.field.titulo")}),
                    },
                    componentClassName: "col-sm-10 col-12",
                    labelClassName: "col-sm-2 col-12",
                    inputClassName: "col-sm-6 col-12"
                },
                {
                    name: "descripcion",
                    validationJson:{
                        rules: {
                            "value-descripcion":{
                                required: true
                            },
                        },
                        messages: {
                            "value-descripcion":{
                                required: t("common.message.field.required", {0: t("idea.field.descripcion")}),
                            },                           
                        }
                    },
                    render: () =>{
                        return(
                            <div className="form-group row">
                                <label htmlFor="value-descripcion" className="parrish-label required col-sm-2 col-xs-3 ">Descripción</label>
                                <div className="col-sm-10 col-12">
                                    <textarea values={descripcion} name="value-descripcion" className="form-control" rows="3" onChange={(e)=>{setDescripcion(e.target.value)}}></textarea>
                                </div>
                            </div>
                        )
                    },
                    componentClassName: "col-sm-10 col-12"
                }
            ]}
            returnUrl="/idea"
            >
        </CardCreateEntity>
    )
}

export default IdeaEdit