import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {} from "jquery-validation";
import 'admin-lte/plugins/inputmask/jquery.inputmask'
import { useTranslation } from "react-i18next"
import useAlertMessage from '../../../../hooks/useAlertMessage';
import CardEditEntity from '../../../../components/CardEditEntity';
import { useLocation } from 'react-router-dom';

const TIPOPROYECTO_SEARCH_BY_ID = gql`
    query findTipoProyectoById(
        $id: Int!
    ) {
    findTipoProyectoById(
        id: $id
    ){
        id
        tipoAcuerdoComercial {
            id
            nombre
        }
        nombre
        nombreCorto
    }
}
`;

const UPDATE_TIPOPROYECTO_MUTATION = gql`
    mutation updateTipoProyecto(
        $id: ID!
        $tipoAcuerdoComercial: Int!
        $nombre: String!
        $nombreCorto: String!
    ) {
        updateTipoProyecto(entity:{
            id: $id
            tipoAcuerdoComercial: $tipoAcuerdoComercial
            nombre: $nombre
            nombreCorto: $nombreCorto
    }) {
            id
            tipoAcuerdoComercial {
                id
                nombre
            }
            nombre
            nombreCorto
        }
    }
`;

const DELETE_TIPOPROYECTO_MUTATION = gql`
    mutation deleteTipoProyecto(
        $id: Int!
    ) {
        deleteTipoProyecto(id: $id)
    }
`;

function TipoProyectoEdit() {
    const history = useHistory();
    let { id } = useParams();
    let { showConfirmDelete, showMessageWithTimerAndRedirect } = useAlertMessage();

    const [tipoAcuerdoComercial, setTipoAcuerdoComercial] = useState('');
    const [nombre, setNombre] = useState('');
    const [nombreCorto, setNombreCorto] = useState('');

    const [t] = useTranslation("global");
    const _isMounted = useRef(true);

    const {loading, error, data } = useQuery(
        TIPOPROYECTO_SEARCH_BY_ID,
        {
            variables: {
                id: id
            }
        }
    );
    
    useEffect(() => {
        if (!loading && !error){
            setTipoAcuerdoComercial(data?.findTipoProyectoById?.tipoAcuerdoComercial?.id?data?.findTipoProyectoById?.tipoAcuerdoComercial?.id:"");
            setNombre(data?.findTipoProyectoById?.nombre);
            setNombreCorto(data?.findTipoProyectoById?.nombreCorto);
        }
        return () => { _isMounted.current = true };
    }, [
        loading,
        error,
        data?.findTipoProyectoById?.tipoAcuerdoComercial?.id,
        data?.findTipoProyectoById?.nombre,
        data?.findTipoProyectoById?.nombreCorto
    ])
    

    let query = new URLSearchParams(useLocation().search);

    const [updateTipoProyectoMutation] = useMutation(UPDATE_TIPOPROYECTO_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                let returnUrl = "/private/tipoProyecto";
                if(query!=null && (query.get("returnUrl"))){
                    returnUrl = query.get("returnUrl");
                }
                history.push(returnUrl);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const [deleteTipoProyectoMutation] = useMutation(DELETE_TIPOPROYECTO_MUTATION, {
        onCompleted: (data) => {
            showMessageWithTimerAndRedirect('success', t("common.message.confirmation.delete.success"), 1500, "/private/tipoProyecto");
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const saveAction = () =>{
        updateTipoProyectoMutation({
            variables:{
                id: id,
                tipoAcuerdoComercial: tipoAcuerdoComercial,
                nombre: nombre,
                nombreCorto: nombreCorto
           }
        })
    }

    const deleteAction = (id, label) => {
        showConfirmDelete(
            id,
            "["+id+"] "+label,
            (id)=>{deleteTipoProyectoMutation({
                variables:{
                    id: id
                }
            })}
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        saveAction();
    }

    return (
        <>
            <CardEditEntity
                entityName="tipoProyecto"
                columnsCount={1}
                handleSubmit={handleSubmit}
                values={[
                    {
                        name: "nombre",
                        type: "text",
                        maxLength: 50,
                        value: nombre,
                        setValue: setNombre,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.nombre")})
                        }
                    },
                    {
                        name: "nombreCorto",
                        type: "text",
                        maxLength: 4,
                        value: nombreCorto,
                        setValue: setNombreCorto,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.nombreCorto")})
                        }
                    },
                    {
                        name: "tipoAcuerdoComercial",
                        type: "select",
                        entity:"TipoAcuerdoComercial",
                        label: "nombre",
                        value: tipoAcuerdoComercial,
                        setValue: setTipoAcuerdoComercial,
                        validationRules:{
                            required: true
                        },
                        validationMessages:{
                            required: t("common.message.field.required", {0: t("tipoProyecto.field.tipoAcuerdoComercial")})
                        }
                    }
                ]}
                returnUrl="/tipoProyecto"
                id={id}
                label={nombre}
                deleteAction={deleteAction}
                loading={loading}
                error={error}
                >
            </CardEditEntity>
        </>
    )
}

export default TipoProyectoEdit