import React, { useEffect, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import useAlertMessage from '../../../../../hooks/useAlertMessage';

const SIGN_OFF_POSTULANTE_EN_PROYECTO_MUTATION = gql`
    mutation signOffPostulanteEnProyecto(
        $idProyectoPostulante: Int!
        $comentario: String!
    ) {
        signOffPostulanteEnProyecto(
            idProyectoPostulante: $idProyectoPostulante
            comentario: $comentario
        ) {
            id
        }
    }
`;

export default function SignOffPostulanteQa(props) {
    const { idProyectoPostulante, refetch } = props;
    const ref = useRef();
    const [t] = useTranslation("global");
    const [show, setShow] = useState(false);
    const { showErrorToast, clearPopUps } = useAlertMessage();
    const [comentario, setComentario] = useState("");
    
    const [signOffPostulanteEnProyectoMutation] = useMutation(SIGN_OFF_POSTULANTE_EN_PROYECTO_MUTATION,{
        onCompleted: (data) => {
            if(refetch){
                refetch()
            }
            setShow(false);
        },
        onError: (error) => {
        }
    });   
    
    const validate = () => {
        clearPopUps();
        let errorList = [];
        if(!comentario){
            errorList.push({message: t("common.message.field.required", {0: t("postulante.field.comentario")})});
        }
        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }
    
    const handleSave = () =>{
        if(validate()){
            signOffPostulanteEnProyectoMutation({
                variables:{
                    idProyectoPostulante: parseInt(idProyectoPostulante),
                    comentario: comentario
                }
            });
        }
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
    }, [show]);

    return (
        <>
            <button className="btn btn-warning p-1" title={t("proyecto.button.signOff")} onClick={()=>setShow(true)}>
                <i className="fas fa-hands-wash fa-2x"></i>
            </button>
            <div ref={ref} className="modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("proyecto.label.signOffPostulanteQa")}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body parrish-form">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="form-group row">
                                        <label htmlFor="value-comentario" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("Comentario")}</label>
                                        <div className="col-sm-10 col-xs-12">
                                            <textarea
                                                name="value-comentario"
                                                value={comentario}
                                                onChange={e => setComentario(e.target.value)}
                                                className="form-control form-control-sm" rows="6"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handleSave()}}>
                                {t("common.button.guardar")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
