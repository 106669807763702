import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useParams } from 'react-router';
import ContactoAvatarImg from '../../../../../components/ContactoAvatarImg';
import Datatable from '../../../../../components/Datatable';
import FlagsPostulante from '../../../../../components/FlagsPostulante';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';
import PrivateLink from '../../../../../components/navigation/PrivateLink';
import AsociarProyectoDialog from '../../postulante/dialogs/asociarProyecto';
import EditarContactoDialog from '../../proyecto/dialogs/editarContactoDialog';
import AuthContext from '../../../../../context/AuthContext';
import SidebarContext from '../../../../../context/SidebarContext';

const EXPERIENCIA_LABORAL_LIST = gql`
    query findExperienciaLaboralPageByEmpresaAndAreaList(
        $sortColumn: String
        $sortDirection: String
        $pageSize: Int
        $pageNumber: Int!
        $idEmpresa: Int!
        $areaList: [Int!]
    ) {
        findExperienciaLaboralPageByEmpresaAndAreaList(
        idEmpresa: $idEmpresa
        areaList: $areaList
        pageCriteria: {
            sortColumn: $sortColumn
            sortDirection: $sortDirection
            pageSize: $pageSize
            pageNumber: $pageNumber
        }
    ) {
        queryInfo {
            totalElements
            totalPages
        }
        pageInfo {
            hasPreviousPage
            hasNextPage
        }
        edges {
            node {
                id
                fechaInicio
                fechaFin
                posicion
                areaEmpresa{
                    nombre
                }
                cargo{
                    nombre
                }
                postulante{
                    id
                    fechaActualizacion
                    destacado
                    fechaNacimientoEstimada
                    incubadora
                    incubadoraProspecto
                    opportunity
                    placement
                    verificar
                    offLimits
                    qaInfo{
                        indice
                        recomendaciones
                    }
                    contacto{
                        id
                        alias
                        personaFisica
                        email
                        telefonosFijos
                        telefonosMoviles
                        foto
                        linkedin
                    }
                    adjuntoList{
                        referencia
                        fechaActualizacion
                        contentType
                        nombreOriginal
                        nombreServidor
                        responsable{
                            contacto{
                                alias
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export default function EmpleadosTab() {
    const { setSideBarState, show } = useContext(SidebarContext);
    let { id } = useParams();
    const [t] = useTranslation("global");
    const { appGetUserData } = useContext(AuthContext);
    const userData = appGetUserData();
    const [idPostulante, setIdPostulante] = useState();
    const [aliasPostulante, setAliasPostulante] = useState();
    const [showProyectoDialog, setShowProyectoDialog] = useState(false);

    const [areaList, setAreaList] = useState([]);

    const [sortOptions, setSortOptions] = useState({
        sortColumn: "postulante.contacto.alias",
        sortDirection: "ASC"
    });

    const pageSizeDefaultParam = userData?.parametroList?.find(param =>{return param.nombre==="parametro.value.app.config.default.page.size"})?.valor;
    const [pageSize, setPageSize] = useState(pageSizeDefaultParam?parseInt(pageSizeDefaultParam):10);
    const [pageNumber, setPageNumber] = useState(0);
    useEffect(() => {
        setPageNumber(0)
    }, [areaList, id]);

    const {loading, error, data, refetch} = useQuery(
        EXPERIENCIA_LABORAL_LIST,
        {
            variables: {
                sortColumn: sortOptions.sortColumn,
                sortDirection: sortOptions.sortDirection,
                pageSize: pageSize,
                pageNumber: pageNumber,
                idEmpresa: id,
                areaList: areaList?areaList:null
            }
        }
    );

    return (
        <div className="card card-info card-outline card-outline-tabs bg-transparent shadow-none mb-0">
            {/*
                loading &&
                !error && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                )
            */}
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-sm">
                        <div className="form-group row">
                            <label htmlFor="value-nombre" className="col-12">{t("experienciaLaboral.field.areaEmpresa")}</label>
                            <div className="col-12">
                                <MultipleAutocomplete
                                    name="value-contacto"
                                    entity="AreaEmpresa"
                                    by="Nombre"
                                    filterName="nombre"
                                    setValue={(value)=>{
                                        setAreaList(value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Datatable
                    data={data}
                    loading={loading}
                    error={error}
                    sortOptions={sortOptions}
                    dataWrapper="findExperienciaLaboralPageByEmpresaAndAreaList"
                    entityName="experienciaLaboral"
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOptions={setSortOptions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    columnsDefinition={[
                        {
                            name: "postulante",
                            customSortKey: "postulante.contacto.alias",
                            translationKey: "postulante.label.entity",
                            className: "col-2",
                            render: (element) =>{
                                const postulante = element.node.postulante;
                                return(
                                    <>
                                        <div className="row">
                                            <div className="col d-flex justify-content-center">
                                                <div className="text-center">
                                                    <FlagsPostulante postulante={postulante} />
                                                </div>
                                                <div className="text-center">
                                                    <ContactoAvatarImg id={postulante?.contacto.id} personaFisica={postulante.contacto.personaFisica} foto={postulante.contacto.foto} className="profile-user-img postulante-table-avatar img-fluid img-circle border-info" alt="User profile picture"/>
                                                    {postulante.contacto?.fechaNacimiento&&
                                                        <p className={"text-nowrap "+(postulante.fechaNacimientoEstimada?"text-danger":"")}>({postulante.contacto?.fechaNacimiento? moment().diff(postulante.contacto?.fechaNacimiento, 'years',false)+" "+t("postulante.field.anios") : ""})</p>
                                                    }
                                                    <p data-template='<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>' data-toggle="tooltip" data-html="true" title={postulante.qaInfo?.recomendaciones?postulante.qaInfo?.recomendaciones:null}>
                                                        <span className="h5 text-nowrap">{t("common.label.qa")} {postulante.qaInfo.indice}%</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <PrivateLink className="text-dark" to={"/postulante/edit/"+postulante?.id}>
                                                    <h4 className="hover-text-info">[{postulante.id}] {postulante.contacto.alias}</h4>
                                                </PrivateLink>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        },
                        {
                            name: "trabajoActual",
                            customHeader: <span title={t("Experiencia laboral actual")}><i className="fas fa-laptop"></i></span>,
                            disableSort: true,
                            customClassHeader: "bg-info text-center pl-0 pr-0",
                            className: "text-center",
                            render: (element)=>{
                                return(
                                    !element.node.fechaFin&&
                                        <p className={"badge badge-info align-self-center"}>
                                            <i className="fas fa-laptop"></i>
                                        </p>
                                );
                            }
                        },
                        {
                            name: "periodo",
                            translationKey: "postulante.field.periodo",
                            colSpan: 2,
                            type: "text",
                            customSortKey: "fechaInicio",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaInicio &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaInicio}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "periodoFin",
                            translationKey: "postulante.field.periodo",
                            type: "text",
                            customSortKey: "fechaFin",
                            className: "text-center",
                            render: (element)=>{
                                return( element.node.fechaFin &&
                                    <p className="text-nowrap">
                                        <span><Moment className="text-uppercase" format={"MMM yyyy"}>{element.node.fechaFin}</Moment></span>
                                    </p>);
                            }
                        },
                        {
                            name: "areaEmpresa",
                            customSortKey: "areaEmpresa.nombre",
                            render: (element)=>{
                                return(<p>{element.node.areaEmpresa.nombre}</p>);
                            }
                        },
                        {
                            name: "cargo",
                            customSortKey: "cargo.nombre",
                            render: (element)=>{
                                return(<p>{element.node.cargo.nombre}</p>);
                            }
                        },
                        {
                            name: "posicion",
                            type: "text"
                        },
                        {
                            name: "acciones",
                            customHeader: <></>,
                            customClassHeader: "bg-info text-center pl-0 pr-0",
                            className: "text-center",
                            render: (element)=>{
                                return(
                                    <>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                title={t("postulante.field.seguimiento")}
                                                className={"btn btn-xs bg-yellow m-1"}
                                                onClick={()=>{
                                                    setSideBarState({
                                                        mode: "seguimiento",
                                                        postulante: element.node.postulante.id,
                                                        labelPostulante: element.node.postulante?.contacto?.alias,
                                                        labelEmpresa: null,
                                                        labelProyecto: null,
                                                        empresa: null,
                                                        proyecto: null,
                                                        responsable: null,
                                                        accionSeguimiento: null,
                                                    })
                                                    show();
                                                }}>
                                                <i className="fas fa-shoe-prints fa-rotate-270"></i>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-xs bg-yellow m-1" onClick={()=>{
                                                    setSideBarState({
                                                        mode: "referencias",
                                                        labelProyecto: null,
                                                        proyecto: null,
                                                        labelPostulante: element.node.postulante?.contacto?.alias,
                                                        postulante: element.node.postulante.id,
                                                        labelEmpresa: null,
                                                        empresa: null
                                                    })
                                                    show();
                                                }}>
                                                    <i className="fas fa-user-secret"></i>
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                title={t("postulante.field.asociarProyecto")}
                                                className={"btn btn-xs bg-info m-1"}
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setAliasPostulante(element.node.postulante?.contacto?.alias);
                                                    setIdPostulante(element.node.postulante.id);
                                                    setShowProyectoDialog(true);
                                                }}>
                                                <i className="fas fa-search-plus"></i>
                                            </button>
                                            <EditarContactoDialog contactoId={element.node.postulante?.contacto?.id} refetch={refetch} />
                                        </div>
                                    </>
                               )
                            }
                        }
                        
                    ]}/>
            </div>
            <AsociarProyectoDialog show={showProyectoDialog} setShow={setShowProyectoDialog} idPostulante={idPostulante} aliasPostulante={aliasPostulante} />
       </div>
    )
}