import React, { useContext, useEffect, useRef, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import $ from 'jquery'
import AuthContext from '../context/AuthContext';
import Blockquote from './Blockquote';

const FIND_MIS_NOVEDADES_PENDIENTES = gql`
    query findMisNovedadesPendientes{
        findMisNovedadesPendientes{
            id
            fechaPublicacion
            descripcion
            titulo
            tipoNovedad{
                id
                nombre
                color
                icono
            }
    }
}
`;

const LEER_NOVEDAD_APP_LIST = gql`
    mutation leerNovedadAppList(
        $novedadList:  [Int!]!
    ) {
        leerNovedadAppList(novedadList: $novedadList)
    }
`;

export default function NovedadesApp() {
    const [t] = useTranslation("global");
    const { isLogged } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const ref = useRef();

    const [novedadesApp, setNovedadesApp] = useState(window.sessionStorage.getItem("MIS_NOVEDADES_PENDIENTES")?JSON.parse(JSON.parse(window.sessionStorage.getItem("MIS_NOVEDADES_PENDIENTES"))):null);

    const [novedadesQuery] = useLazyQuery(FIND_MIS_NOVEDADES_PENDIENTES,{
        onCompleted: (data)=>{
            if(data?.findMisNovedadesPendientes){
                const novedades = JSON.stringify(data?.findMisNovedadesPendientes);
                setNovedadesApp(JSON.parse(novedades));
                window.sessionStorage.setItem("MIS_NOVEDADES_PENDIENTES", JSON.stringify(novedades));
            }else{
                novedadesApp(null)
                window.sessionStorage.setItem("MIS_NOVEDADES_PENDIENTES", null);
            }
        }
    });

    const [leerNovedades] = useMutation(LEER_NOVEDAD_APP_LIST, {
        onCompleted: (data) => {
            novedadesQuery();
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if(window.sessionStorage.getItem("MIS_NOVEDADES_PENDIENTES")===null && isLogged()){
          novedadesQuery();
        }
      }, [isLogged, novedadesQuery])

      useEffect(() => {
        setShow(novedadesApp&&novedadesApp.length>0);  
      }, [novedadesApp])

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
    }, [show]);

    return (
        <>
            <div ref={ref} className="modal"  data-backdrop="static" data-keyboard="false" role="dialog">
                {novedadesApp&&novedadesApp.length>0&&
                    <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header pl-3 p-2">
                                <h5 className="modal-title">{t("novedadesApp.label.titulo")}</h5>
                            </div>
                            <div className="modal-body parrish-form">
                                <div className="timeline mb-4 w-100">
                                    {
                                        novedadesApp?.map((novedad)=>{
                                            return(
                                                <div key={novedad.id}>
                                                    <i className={"text-white "+novedad.tipoNovedad.icono+" bg-"+novedad.tipoNovedad.color}/>
                                                    <div className="timeline-item">
                                                        <h3 className="timeline-header w-100 d-flex align-items-center">
                                                            <b className={"mr-auto text-dark"}>{novedad.titulo}</b>
                                                            <div className="float-right d-flex align-items-center">
                                                                <span className="text-muted small align-self-center mr-2 text-bold">{novedad.tipoNovedad.nombre}</span>
                                                            </div>
                                                        </h3>
                                                        <div className="timeline-body">
                                                            <Blockquote text={novedad.descripcion}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bg-info ml-2" onClick={()=>{
                                    leerNovedades({
                                        variables: {
                                            novedadList: novedadesApp.map(element=>parseInt(element.id))
                                        }
                                    });
                                }}>
                                    {t("common.button.cerrar")}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>)
}
