import React, { useState } from "react";
import ProfilerStateContext from "../context/ProfilerContext";

function ProfilerStateProvider({ children }) {
  // app state
  const [stateTime, setStateTime] = useState(0);
  const [operationsStack, setOperationsStack] = useState([]);

  return (
    <ProfilerStateContext.Provider value={{ 
      time: stateTime,
      setTime: (time) => {
        setStateTime(time);
      },
      operationsStack: operationsStack,
      setOperationsStack: setOperationsStack
      }}>
        {children}
    </ProfilerStateContext.Provider>
  );
}

export default ProfilerStateProvider;