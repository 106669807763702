import React, { useState } from "react";
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, Observable } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import ConfigData from './config.json'
import AppStateContext from "./context/AuthContext.js";
import useAlertMessage from "./hooks/useAlertMessage";
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import GlobalBlockingLoading from "./components/GlobalBlockingLoading";
import { createUploadLink } from 'apollo-upload-client';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { useTranslation } from "react-i18next";
import { DateRfc }  from "./pages/app/DateRfc";

const ErrorTypes = {
  CLIENT_ERROR: "CLIENT-ERROR",
  SERVER_ERROR: "SERVER-ERROR",
  SECURITY_ERROR: "SECURITY-ERROR",
}

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const STACK_SIZE = 10;
function GlobalLoadingIndicator() {
  const status = useApolloNetworkStatus({
    shouldHandleOperation: (operation) => {
      const opName = operation?.operationName ? operation?.operationName : "";
      return !opName.endsWith("KeyValueListByAlias") && !opName.endsWith("findMisNovedadesPendientes") && !opName.endsWith("misAlertas");
    }
  });
  return <GlobalBlockingLoading numPendingQueries={status.numPendingQueries} numPendingMutations={status.numPendingMutations} />;
}

function AppStateProvider({ children }) {
  const [t] = useTranslation("global");
  const [userDataSessionStore, setUserDataSessionStore] = useState(window.localStorage.getItem("user-data") ? JSON.parse(window.localStorage.getItem("user-data")) : {});
  const [authToken, setAuthToken] = useState(window.sessionStorage.getItem("jwt") ? window.sessionStorage.getItem("jwt") : '');
  // app state
  const [appState, setAppState] = useState({ loggedIn: false });
  const [gqlError] = useState({ msg: '' });
  const [userExpired, setUserExpired] = useState(null);
  const [userExpiredError, setUserExpiredError] = useState(null);
  const [invalidSession, setInvalidSession] = useState(false);

  const addOperation = (operation) => {
    let operationsStack = window.sessionStorage.getItem("operations") ? JSON.parse(window.sessionStorage.getItem("operations")) : [];
    operationsStack.unshift(operation);
    window.sessionStorage.setItem("operations", JSON.stringify(operationsStack.slice(0, STACK_SIZE)));
  }

  const appSetLogin = (token, userData) => {
    //authToken = token;
    appSetInvalidSession(false);
    setAuthToken(token);
    //userDataSessionStore = JSON.stringify(userData);
    setUserDataSessionStore(userData);
    window.sessionStorage.setItem("jwt", token);
    if(userData){
      window.localStorage.setItem("user-data", JSON.stringify(userData));
    }
    setAppState({ ...appState, loggedIn: true });
  };

  const appSetLogout = () => {
    appSetInvalidSession(false);
    appClearAuthToken();
    setAppState({ ...appState, loggedIn: false });
  };

  const appClearAuthToken = () => {
    //authToken = '';
    setAuthToken("");
    //userDataSessionStore = '';
    setUserDataSessionStore({})
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("user-data");
  };

  const refreshCurrentUserData = (userData) => {
    /*userDataSessionStore = JSON.stringify({
      ...appGetUserData(),
      usuario: userData
    });*/
    setUserDataSessionStore({
      ...appGetUserData(),
      usuario: userData
    });
    window.sessionStorage.setItem("user-data", JSON.stringify(userDataSessionStore));
  };
  
  const isLogged = () => {
    return Boolean(authToken)
  };
  const isExpired = () => { return Boolean(userExpired); };

  const appSetAuthToken = (token) => { setAuthToken(token); };
  const appGetAuthToken = () => { return authToken; };
  const appGetUserData = () => { return userDataSessionStore; };

  const appSetExpiredUser = (userNameExpired) => { setUserExpired(userNameExpired) };
  const appGetExpiredUser = () => { return userExpired; };
  const appSetExpiredUserError = (userNameExpiredError) => { setUserExpiredError(userNameExpiredError) };
  const appGetExpiredUserError = () => { return userExpiredError; };
  const appClearExpiredUser = () => { setUserExpired(null); setUserExpiredError(null) };

  const appSetInvalidSession = (invalidSession) => { setInvalidSession(invalidSession) };
  const appGetInvalidSession = () => { return invalidSession; };

  // apollo client
  const cache = new InMemoryCache({});
  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then((operation) => {
          operation.setContext({
            headers: {
              authorization: `Bearer ${appGetAuthToken()}`,
              "app-token": ConfigData.APP_TOKEN
            }
          });
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );

  const wsLink = new WebSocketLink({
    uri: ConfigData.BACKEND_SUSCRIPTIONS_URL,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        return {
          // this works for me
          Authorization: `Bearer ${appGetAuthToken()}`,
          "app-token": ConfigData.APP_TOKEN

          // this did not work for me
          //headers: {Authorization: token ? `Bearer ${token}` : ""},
        };
      },
    }
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    link,
  );

  let { showErrorList, showReactMessage /*showConfirmDeleteWithPin, showConfirmDelete, showMessageWithTimerAndRedirect*/ } = useAlertMessage();

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new DateRfc() });
    return forward(operation);
  });

  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const time = new DateRfc() - operation.getContext().start;
      if(!operation.operationName.includes("misAlertas")){
        addOperation({ ...operation, time })
      }
      return data;
    })
  });

  const additiveLink = ApolloLink.from([
    timeStartLink,
    logTimeLink
  ]);

  const client = new ApolloClient({
    link: splitLink.concat(additiveLink).concat(ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        let clientErrors = [];
        let serverErrors = [];
        let securityErrors = [];
        let unhandledErrors = [];
        if (networkError) {
          if(networkError){
            showReactMessage(null,
              <>
                <span className="fa-stack fa-2x m-4">
                  <i className="fas fa-plug fa-stack-2x"></i>
                  <i className="fas fa-slash fa-stack-2x text-gray"></i>
                </span>
                <div className="mt-4">
                  <h3>{t("common.label.problemasDeConeccion")}</h3>
                  <p className="small text-muted">{t("common.label.problemasDeConeccionNota")}</p>
                </div>
              </>
            );
            return;
          }else{
            serverErrors.push([t("common.message.unexpected-internal-server-error")]);
          }
          console.error(networkError);
          //showErrorList(["[Network error]: " + networkError]);
        }
        
        const expiredUsersErrors = graphQLErrors?.filter(graphQLError=>graphQLError.extensions?.code==="login.message.invalid.expired-user");
        const loginPasswordPoliciesErrors = graphQLErrors?.filter(graphQLError=>graphQLError.extensions?.code==="login.message.invalid-password-updated-policies");
        const expiredSessionErrors = graphQLErrors?.filter(graphQLError=>graphQLError.extensions?.code===ConfigData.INVALID_SESSION_ERROR_CODE);
        
        //No intervenir en el caso de usuarios expirados, lo maneja el hook 'useUser'
        if(!(expiredUsersErrors?.length>0||loginPasswordPoliciesErrors?.length>0||expiredSessionErrors?.length>0)){
          graphQLErrors?.filter(graphQLError=>graphQLError.extensions?.code!=="login.message.invalid.expired-user").forEach(graphQLError => {
            const errorType = graphQLError.extensions?.type;
            const errorMsg = graphQLError.message?graphQLError.message:graphQLError.extensions?.code;
            
            switch (errorType) {
              case ErrorTypes.CLIENT_ERROR:
                clientErrors.push(graphQLError)
                break;
              case ErrorTypes.SERVER_ERROR:
                serverErrors.push(errorMsg)
                break;
              case ErrorTypes.SECURITY_ERROR:
                securityErrors.push(errorMsg)
                break;
              default:
                unhandledErrors.push(errorMsg)
                break;
            }
          });
  
          if (securityErrors.length) {
            showErrorList(securityErrors, () => { }, "security-error");
            return;
          }
          if (serverErrors.length) {
            showErrorList(serverErrors, () => { }, "server-error");
            return;
          }
          if (clientErrors.length) {
            showErrorList(clientErrors, () => { }, "client-error");
            return;
          }
        }else{
          if(expiredUsersErrors.length>0){
            appSetExpiredUserError(expiredUsersErrors[0]?.message);
          }
          if(loginPasswordPoliciesErrors.length>0){
            appSetExpiredUserError(loginPasswordPoliciesErrors[0]?.message);
          }
          if(expiredSessionErrors?.length>0){
            console.log("Invalid session...");
            appSetInvalidSession(true);
            appSetLogout();
          }
          return;
        }

        //setGQLError({ msg: err });
      }),
      requestLink,
      createUploadLink({
        uri: process.env.REACT_APP_API_URL
      })
    ])),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

  return (
    <AppStateContext.Provider value={{
      appState,
      gqlError,
      isLogged,
      isExpired,
      appSetLogin,
      appSetLogout,
      appSetAuthToken,
      appClearAuthToken,
      appSetExpiredUser,
      appGetExpiredUser,
      appSetExpiredUserError,
      appGetExpiredUserError,
      appClearExpiredUser,
      appGetUserData,
      refreshCurrentUserData,
      appSetInvalidSession,
      appGetInvalidSession
    }}>
      <ApolloProvider client={client}>
        <GlobalLoadingIndicator />
        {children}
      </ApolloProvider>
    </AppStateContext.Provider>
  );
}

export default AppStateProvider;