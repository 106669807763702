import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../components/PageHeader';
import IndustriaList from './list'
import IndustriaCreate from './create'
import IndustriaEdit from './edit'
import { useHistory, useParams } from 'react-router';

function IndustriaIndex(){
    const entityName = "industria";
    const [searchFilter, setSearchFilter] = useState({
        nombre: ""
    });

    const { mode, id } = useParams();
    const history = useHistory();

    useEffect(() => {
        if(!mode){
            history.replace("/private/"+entityName+"/list");    
        }else{
            const idParam = id&&mode==="edit"?"/"+id:"";
            history.replace("/private/"+entityName+"/"+mode+idParam);
        }
    }, [history, id, mode])

    return (
        <div className="content-wrapper px-3">
            <PageHeader entityName={entityName} />
            {
                mode==='list'?
                    <IndustriaList searchFilter={searchFilter} setSearchFilter={setSearchFilter}></IndustriaList>
                :
                null
            }
            {
                mode==='create'?
                    <IndustriaCreate></IndustriaCreate>
                :
                null
            }
            {
                mode==='edit'?
                    <IndustriaEdit></IndustriaEdit>
                :
                null
            }
        </div>
    );
}

export default IndustriaIndex;