import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppStateProvider from './Provider';
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_es from "./i18n/es/index"
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {} from 'admin-lte/dist/js/adminlte'
import ProfilerStateProvider from './provider/ProfilerStateProvider';

moment.updateLocale('es', {
  monthsShort : ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
});

moment.tz.setDefault("UTC");

// TODO CHRIS que levante los idiomas en funcion de los archivos que
// tiene, de modo que agregar un nuevo idioma, signifique solo agregar
// el archivo de traducciones en la carpeta i18n.
/**
 *  Dentro del archivo /src/i18n/{idioma}/index.js se debe mantener
 *  el array "translationFiles" actualizado con los archivos que se
 *  van a importar (solo el nombre sin la extension ni la ruta)
 */

// TODO CHRIS ver si hacemos que cada "entidad o pantalla" tenga su propio
// archivo de traducciones, y la app los levanta todos. Ej: industria-es.json, institucion-es.json, etc

// TODO CHRIS la aplicacion no hace nada en particular cuando el back devuelve acceso denegado
// deberiamos poner un cartel especial. en particular, me pasa que el back responde acceso denegado pq
// me parece q se vencio la sesion. en cuyo caso, habria que redirigirlo al login o poner una pantalla para
// q vuelva a validar su usuario (adminlte tiene una ya hecha)
i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es()
    },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <ProfilerStateProvider>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </ProfilerStateProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();