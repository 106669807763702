import React, { useEffect, useState, useRef } from 'react'
import { useMutation, gql } from '@apollo/client';
import PageHeader from '../../../../components/PageHeader'
import { useTranslation } from "react-i18next"
import $ from 'jquery'
import 'bootstrap'
import useAlertMessage from '../../../../hooks/useAlertMessage';
import ReactSummernote from 'react-summernote';
//import 'react-summernote/lang/summernote-es-ES'; 

const OPEN_SUPPORT_MUTATION = gql`
    mutation openSupportTicket(
        $subject: String!
	    $message: String!
    ) {
        openSupportTicket(input:{
            subject: $subject
            message: $message
        })
    }
`;

function SoporteTecnico() {
    const [t] = useTranslation("global");
    const { showSuccess, showErrorToast } = useAlertMessage();
    const ref = useRef();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const _isMounted = useRef(true);

    useEffect(() => {
        let validationJson = {
            rules:{
                "soporte-titulo": "required",
            },
            messages: {
                "soporte-titulo": {
                    required: t("common.message.field.required", {0: t("soporte.field.titulo")})
                },
            },
            errorElement: 'span',
            errorClass: "text-danger text-sm",
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            },
            showErrors: function(errorMap, errorList) {
                if(errorList.length>0){
                    showErrorToast(errorList);
                }
            }
        };
        $(ref.current).validate(validationJson);   

        return () => { _isMounted.current = true };
    }, [showErrorToast, t])

    const [openSupportTicket] = useMutation(OPEN_SUPPORT_MUTATION, {
        onCompleted: (data) => {
            if(_isMounted.current){
                showSuccess(t("common.message.confirmation.send.success"),()=>{
                    setTitle("");
                    setMessage("");
                    $('#soporte-message').summernote('reset');
                });
            }
        },
        onError: (error) => {
            console.error(error);
        }
    }, []);

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(message == null || message ===""){
            var errorHtmlList = "<ul>";
            errorHtmlList = errorHtmlList+"<li>"+t("common.message.field.required", {0: t("soporte.field.message")})+"</li>";   
            errorHtmlList = errorHtmlList.concat("</ul>")
            
            showErrorToast(errorHtmlList);
        }else{
            openSupportTicket({
                variables:{
                    subject: title,
                    message: message
                }
            })
        }
    }

    return (
        <form autoComplete="off" ref={ref} onSubmit={handleSubmit} noValidate="novalidate">       
            <div className="content-wrapper px-3">
                <PageHeader entityName="soporte" keyMenu="common.menu.soporte" />
                <div className="card card-outline card-info">
                    <div className="card-header pl-3 pt-2 pb-2">
                        <h3 className="card-title"><i className="nav-icon fas fa-envelope mr-2"></i>{t("soporte.label.cardTitle")}</h3>
                    </div>
                    <div className="card-body">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="form-group row">
                                    <div className="col-12 mb-3">
                                        <p className="text-sm">{t("soporte.label.explicacion", {interpolation: { escapeValue: true }})}</p>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="soporte-titulo" className="parrish-label">{t("soporte.field.title")}</label>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <input autoComplete="off"
                                            id="soporte-titulo"
                                            name="soporte-titulo"
                                            className="col-12 parrish-input"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}>
                                        </input>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="soporte-text" className="parrish-label">{t("soporte.field.text")}</label>
                                    </div>
                                    <div className="col-12 pl-0 pr-0">
                                        <ReactSummernote
                                            value={message}
                                            id="soporte-message"
                                            name="soporte-message"
                                            className="col-12"
                                            options={{
                                                lang: 'es-ES',
                                                height: 350,
                                                dialogsInBody: true,
                                                toolbar: [
                                                    ['style', ['style']],
                                                    ['font', ['bold', 'underline', 'clear']],
                                                    ['fontname', ['fontname']],
                                                    ['para', ['ul', 'ol', 'paragraph']],
                                                    ['table', ['table']],
                                                    ['insert', ['link', 'picture', 'video']],
                                                    ['view', ['fullscreen', 'codeview']]
                                                ]
                                                }
                                            }
                                            onChange={setMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-info float-right">{t("common.button.enviar")}</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SoporteTecnico
