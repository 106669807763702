import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import ETAPA_PROYECTO from '../../../../../../enums/EtapaProyecto';
import $ from 'jquery'
import SelectEntity from '../../../../../../components/SelectEntity';
import DatePicker from '../../../../../../components/DatePicker';
import NumberFormat from 'react-number-format';
import useAlertMessage from '../../../../../../hooks/useAlertMessage';
import TIPO_PLACEMENT from '../../../../../../enums/TipoPlacement';
import SelectKeyValue from '../../../../../../components/SelectKeyValue';
import { DateRfc }  from '../../../../DateRfc';

const AVANZAR_POSTULANTE_EN_PROYECTO = gql`
    mutation avanzarPostulanteEnProyecto(
        $idProyectoPostulante: Int!
    ) {
        avanzarPostulanteEnProyecto(idProyectoPostulante: $idProyectoPostulante) {id}
    }
`;

const RETROCEDER_POSTULANTE_EN_PROYECTO = gql`
    mutation retrocederPostulanteEnProyecto(
        $idProyectoPostulante: Int!
    ) {
        retrocederPostulanteEnProyecto(idProyectoPostulante: $idProyectoPostulante) {id}
    }
`;

const CONFIRMAR_PLACEMENT_EN_PROYECTO = gql`
    mutation confirmarPlacementEnProyecto(
        $idProyectoPostulante: Int!
        $areaEmpresa: Int!
        $cargo: Int!
        $pais: Int!
        $fechaInicio: LocalDate!
        $fechaOriginacion: LocalDate!
        $sueldoBrutoMensual: Int!
        $tipoPlacement: Int!
        $posicion: String
        $bonus: String
        $beneficios: String
    ) {
        confirmarPlacementEnProyecto(
            entity:{
                idProyectoPostulante: $idProyectoPostulante
                areaEmpresa: $areaEmpresa
                cargo: $cargo
                pais: $pais
                fechaInicio: $fechaInicio
                fechaOriginacion: $fechaOriginacion
                sueldoBrutoMensual: $sueldoBrutoMensual
                tipoPlacement: $tipoPlacement
                posicion: $posicion
                bonus: $bonus
                beneficios: $beneficios
            }
        ) {
            id
        }
    }
`;

export default function ControlEtapas(props) {
    const {proyectoPostulante, refetch, proyectoRefetch, completePlacement} = props;
    const [t] = useTranslation("global");
    const ref = useRef();
    let { showErrorToast, clearPopUps } = useAlertMessage();
    
    const [show, setShow] = useState();
    const [state, setState] = useState({
        idProyectoPostulante: "",
        areaEmpresa: "",
        cargo: "",
        pais: "",
        fechaInicio: "",
        posicion: "",
        moneda: "",
        sueldoBrutoMensual: "",
        tipoPlacement: TIPO_PLACEMENT.PLACEMENT
    });

    const validatePlacementForm = () => {
        clearPopUps();
        let errorList = [];
        if(!state.cargo){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.cargo")})});
        }
        if(!state.pais){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.pais")})});
        }
        if(!state.moneda){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.moneda")})});
        }
        if(!state.sueldoBrutoMensual){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.sueldoBrutoMensual")})});
        }
        if(!state.areaEmpresa){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.areaEmpresa")})});
        }
        if(!state.fechaInicio){
            errorList.push({message: t("common.message.field.required", {0: t("proyecto.field.fechaInicio")})});
        }

        if(errorList.length>0){
            showErrorToast(errorList);
        }
        return errorList.length === 0;
    }

    function setStateValue(name, value) {
        setState({
            ...state,
            [name]: value
        });
    }

    const [retrocederPostulanteMutation, { loading: retrocederLoading }] = useMutation(RETROCEDER_POSTULANTE_EN_PROYECTO,{
        onCompleted: (data) => {
            if(refetch)
                refetch();
        },
        onError: (error) => {
        }
    });

    const [avanzarPostulanteMutation, { loading: avanzarLoading }] = useMutation(AVANZAR_POSTULANTE_EN_PROYECTO,{
        onCompleted: (data) => {
            if(refetch)
                refetch();
        },
        onError: (error) => {
        }
    });

    const [agregarPlacementMutation, { loading: confirmarLoading }] = useMutation(CONFIRMAR_PLACEMENT_EN_PROYECTO,{
        onCompleted: (data) => {
            setShow(false);
            if(refetch)
                refetch();
                proyectoRefetch();
            if(completePlacement)
                completePlacement();
        },
        onError: (error) => {
        }
    });

    const avanzarPostulante = () => {
        avanzarPostulanteMutation({variables: {idProyectoPostulante: proyectoPostulante.id}})
    }

    const retrocederPostulante = () => {
        retrocederPostulanteMutation({variables: {idProyectoPostulante: proyectoPostulante.id}})
    }

    const openPopupPlacement = () => {
        setShow(true);
    }

    const handlePlacement = () => {
        if(validatePlacementForm()){
            agregarPlacementMutation({variables: {
                idProyectoPostulante: parseInt(proyectoPostulante.id),
                areaEmpresa: parseInt(state.areaEmpresa),
                cargo: parseInt(state.cargo),
                pais: parseInt(state.pais),
                posicion: state.posicion,
                fechaInicio: state.fechaInicio,
                fechaOriginacion: state.fechaOriginacion?new DateRfc(state.fechaOriginacion):null,
                sueldoBrutoMensual: state.sueldoBrutoMensual,
                bonus: state.bonus,
                beneficios: state.beneficios,
                tipoPlacement: state.tipoPlacement
            }})
        }
    }

    const loadingMutations = () => {
        return avanzarLoading||retrocederLoading||confirmarLoading;
    }

    useEffect(() => {
        $(ref.current).modal(show?'show':'hide');
        if(show){
            setState({
                idProyectoPostulante: proyectoPostulante.id,
                areaEmpresa: proyectoPostulante?.proyecto?.areaEmpresa?parseInt(proyectoPostulante.proyecto.areaEmpresa.id):"",
                cargo: proyectoPostulante?.proyecto?.cargo?parseInt(proyectoPostulante.proyecto.cargo.id):"",
                pais: proyectoPostulante?.proyecto?.pais?parseInt(proyectoPostulante.proyecto.pais.id):"",
                posicion: proyectoPostulante?.proyecto?.posicion?proyectoPostulante.proyecto.posicion:"",
                fechaInicio: "",
                fechaOriginacion: new DateRfc(),
                moneda: proyectoPostulante.proyecto?.acuerdoInicial?.moneda?parseInt(proyectoPostulante.proyecto.acuerdoInicial.moneda.id):"",
                sueldoBrutoMensual: proyectoPostulante?.proyecto?.acuerdoInicial?.sueldoBrutoMensual?proyectoPostulante.proyecto?.acuerdoInicial.sueldoBrutoMensual:"",
                beneficios: proyectoPostulante.proyecto?.beneficios?proyectoPostulante.proyecto.beneficios:"",
                bonus: proyectoPostulante?.proyecto?.bonus?proyectoPostulante.proyecto?.bonus:"",
                tipoPlacement: TIPO_PLACEMENT.PLACEMENT
            });
        }
    }, [proyectoPostulante, show]);

    return (<>
        <div ref={ref} className="modal" role="dialog">
            {show&&
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-3 p-2">
                            <h5 className="modal-title">{t("proyecto.label.agregarPlacement")+proyectoPostulante.postulante.contacto.alias}</h5>
                            <button type="button" className="close" onClick={()=>{$(ref.current).modal('hide');setShow(false)}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label htmlFor="value-prioridad" className="parrish-label col-sm-2 col-xs-12 text-right required align-self-start">{t("postulante.field.tipoPlacement")}</label>
                                <div className="col-10 d-flex align-items-center">
                                    <div className="custom-control custom-radio d-flex mr-2">
                                        <input autoComplete="off" id="etapa-quick-win" name="value-prioridad"
                                            onChange={(e)=>{setStateValue("tipoPlacement", e?.target?.value);}}
                                            checked={parseInt(state.tipoPlacement) === TIPO_PLACEMENT.PLACEMENT}
                                            className="custom-control-input custom-control-input-info"
                                            value={TIPO_PLACEMENT.PLACEMENT}
                                            type="radio"/>
                                        <label htmlFor="etapa-quick-win" className="custom-control-label d-flex align-items-center">
                                            <span className="ml-1">{t("postulante.field.placement")}</span>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex mr-2 ml-2">
                                        <input autoComplete="off" id="etapa-interesting-match" name="value-prioridad"
                                            onChange={(e)=>{setStateValue("tipoPlacement", e?.target?.value);}}
                                            checked={parseInt(state.tipoPlacement) === TIPO_PLACEMENT.DOUBLE_PLACEMENT}
                                            className="custom-control-input custom-control-input-info"
                                            value={TIPO_PLACEMENT.DOUBLE_PLACEMENT}
                                            type="radio"/>
                                        
                                        <label htmlFor="etapa-interesting-match" className="custom-control-label d-flex align-items-center">                                           
                                            <span className="ml-1">{t("postulante.field.doublePlacement")}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="parrish-form row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-empresa" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.empresa")}</label>
                                        <div className="col">
                                            <input autoComplete="off" className="parrish-input" disabled={true} value={proyectoPostulante.proyecto.empresa.contacto.alias}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-areaEmpresa" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.areaEmpresa")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="AreaEmpresa"
                                                by="Nombre"
                                                name="value-areaEmpresa"
                                                value={state.areaEmpresa}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("areaEmpresa", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-cargo" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.cargo")}</label>
                                        <div className="col">
                                            <SelectEntity
                                                entity="Cargo"
                                                label="nombre"
                                                name="value-cargo"
                                                value={state.cargo}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("cargo", e?.target?.value);
                                                }}/> 
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-posicion" className="parrish-label col-sm-4 col-xs-12">{t("postulante.field.posicion")}</label>
                                        <div className="col">
                                            <input autoComplete="off"
                                                type="text"
                                                name={'value-posicion'}
                                                className="parrish-input"
                                                value={state.posicion}
                                                maxLength={150}
                                                onChange={(e) => {
                                                    setStateValue("posicion", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-pais" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.pais")}</label>
                                        <div className="col">
                                            <SelectKeyValue
                                                entity="Pais"
                                                label="nombre"
                                                name={'value-pais'}
                                                value={state.pais}
                                                required
                                                onChange={(e) => {
                                                    setStateValue("pais", e?.target?.value);
                                                }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-fecha-originacion" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.fechaOriginacion")}</label>
                                            <div className="col-8">
                                                <DatePicker
                                                    name={'value-fecha-originacion'}
                                                    selected={state.fechaOriginacion}
                                                    onChange={(date) => {
                                                        setStateValue("fechaOriginacion", date)
                                                    }} />
                                            </div>
                                    </div>
                                </div>
                                
                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-sueldo-bruto" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.sueldoBrutoMensual")}</label>
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <SelectEntity
                                                        entity="Moneda"
                                                        label="simbolo"
                                                        name="value-moneda"
                                                        value={state.moneda}
                                                        required
                                                        disabled
                                                        onChange={(e) => {
                                                            setStateValue("moneda", e?.target?.value);
                                                        }}/>
                                                </div>

                                                <div className="col-8">
                                                    <NumberFormat
                                                        name="value-sueldo-bruto"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        value={state.sueldoBrutoMensual}
                                                        className={"parrish-input"}
                                                        onChange={(e) => {
                                                            const val = e.target?.value;
                                                            if(val){
                                                                setStateValue("sueldoBrutoMensual", parseInt(val.replaceAll(".","").replaceAll(",",".")));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="value-periodo-inicio" className="parrish-label col-sm-4 col-xs-12 required">{t("postulante.field.fechaInicio")}</label>
                                            <div className="col-8">
                                                <DatePicker
                                                    name={'value-periodo-inicio'}
                                                    selected={state.fechaInicio}
                                                    onChange={(date) => {
                                                        setStateValue("fechaInicio", date)
                                                    }}
                                                    selectsStart
                                                    startDate={state.fechaInicio}
                                                    endDate={state.fechaFin}
                                                    dateFormat="MM-yyyy"
                                                    showMonthYearPicker/>
                                            </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-beneficios" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.beneficios")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-beneficios" value={state.beneficios} onChange={(e)=>{setStateValue("beneficios", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group row text-left">
                                                <label htmlFor="value-bonus" className="parrish-label-left col-sm-12 col-xs-12">{t("proyecto.field.bonus")}</label>
                                                <div className="col-sm-12 col-xs-12">
                                                    <textarea name="value-bonus" value={state.bonus} onChange={(e)=>{setStateValue("bonus", e?.target?.value)}} className="form-control form-control-sm" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bg-info ml-1" onClick={()=>{handlePlacement()}}>
                                {t("proyecto.button.confirmarPlacement")}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className="d-flex justify-content-center">
            {loadingMutations()?
                <div className="overlay">
                    <i className="fas fa-sync-alt fa-spin"></i>
                </div>
            :
                <>
                    <button
                        type="button"
                        title={t("postulante.button.retrocederEtapa")}
                        className={"btn btn-xs bg-info m-1"}
                        disabled={parseInt(proyectoPostulante?.etapa?.id?proyectoPostulante.etapa.id:0)===ETAPA_PROYECTO.SOURCE||proyectoPostulante.estado.esFinal}
                        onClick={(e)=>{ retrocederPostulante(); }}>
                            <i className="fas fa-play fa-rotate-180"></i>
                    </button>
                    {parseInt(proyectoPostulante?.etapa?.id?proyectoPostulante.etapa.id:0)!==ETAPA_PROYECTO.CANDIDATO?
                        <button
                            type="button"
                            title={t("postulante.button.avanzarEtapa")}
                            className={"btn btn-xs bg-info m-1"}
                            disabled={proyectoPostulante.estado.esFinal}
                            onClick={(e)=>{ avanzarPostulante(); }}>
                            <i className="fas fa-play"></i>
                        </button>
                        :
                        <button
                            type="button"
                            title={t("postulante.field.agregarPlacement")}
                            className={"btn btn-xs bg-success m-1"}
                            onClick={(e)=>{ openPopupPlacement(); }}>
                            <i className="far fa-handshake"></i>
                        </button>
                    }
                </>
            }
        </div>
    </>)
}
