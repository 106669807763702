import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SelectEntity from '../../../../../components/SelectEntity';
import SelectKeyValue from '../../../../../components/SelectKeyValue';

export default function DetalleTab(props) {
    const [t] = useTranslation("global");
    const { state, setState } = props;

    function handleChange(name, evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        });
    }

    const linkClick= (e,url) =>{
        e.preventDefault();
        if(url){
            // Check if the URL starts with "http://" or "https://"
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                // If not, prepend "http://"
                url = "https://" + url;
            }
            
            window.open(url,"_blank");
        }
    }

    useEffect(() => {
        if(!state.pais){
            const updatedState = {
                ...state
            };
            updatedState.provincia = "";       
            setState(updatedState);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pais]);

    useEffect(() => {
        if(!state.direccionFiscalPais){
            const updatedState = {
                ...state
            };
            updatedState.direccionFiscalProvincia = "";       
            setState(updatedState);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.direccionFiscalPais]);

    return (
        <div className="parrish-form row ">

            <div className="col-xs-12 col-sm-6">

                <div className="parrish-form row ">
                    <div className="col-12 text-center">
                        <h3>{t("empresa.label.datosContacto")}</h3>
                    </div>
                </div>
                <div className="parrish-form row ">
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-linkedin" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.linkedin")}</label>
                            <div className="col">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.linkedin)}}>
                                        <span className="input-group-text">
                                            <i className="fab fa-linkedin-in"></i>
                                        </span>
                                    </div>
                                    <input autoComplete="off" type="text"
                                        name={'value-linkedin'}
                                        className="parrish-input"
                                        value={state.linkedin}
                                        maxLength={150}
                                        onChange={e => handleChange("linkedin", e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-telefonosFijos" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.telefonosFijos")}</label>
                            <div className="col">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fas fa-phone"></i>
                                        </span>
                                    </div>
                                    <input autoComplete="off" type="text"
                                        name={'value-telefonosFijos'}
                                        className="parrish-input"
                                        value={state.telefonosFijos}
                                        onChange={e => handleChange("telefonosFijos", e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-website" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.website")}</label>
                            <div className="col">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend cursor-pointer" onClick={(e)=>{linkClick(e,state.website)}}>
                                        <span className="input-group-text">
                                            <i className="fas fa-globe-americas"></i>
                                        </span>
                                    </div>
                                    <input autoComplete="off" type="text"
                                        name={'value-website'}
                                        className="parrish-input"
                                        value={state.website}
                                        maxLength={150}
                                        onChange={e => handleChange("website", e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-dominioEmails" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.dominioEmails")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-dominioEmails'}
                                    className="parrish-input"
                                    value={state.dominioEmails}
                                    onChange={e => handleChange("dominioEmails", e)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="parrish-form row mt-4">
                    <div className="col-12 text-center">
                        <h3>{t("empresa.label.domicilio")}</h3>
                    </div>
                </div>
                <div className="parrish-form row ">
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-domicilio" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.domicilio")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-domicilio'}
                                    className="parrish-input"
                                    value={state.domicilio}
                                    onChange={e => handleChange("domicilio", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-localidad" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.localidad")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-localidad'}
                                    className="parrish-input"
                                    value={state.localidad}
                                    onChange={e => handleChange("localidad", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-pais" className="parrish-label col-4 text-right font-weight-normal">{t("empresa.field.pais")}</label>
                            <div className="col-8">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-pais'}
                                    value={state.pais}
                                    required
                                    onChange={(e) => {
                                        handleChange("pais", e)
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-provincia" className="parrish-label col-4 text-right font-weight-normal">{t("empresa.field.provincia")}</label>
                            <div className="col-8">
                                <SelectKeyValue
                                    entity="Provincia"
                                    by="PaisNombre"
                                    label="nombre"
                                    name={'value-provincia'}
                                    value={state.provincia}
                                    filterValue={state.pais}
                                    filterName="idPais"
                                    onChange={(e) => {
                                        handleChange("provincia", e)
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-codigoPostal" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.codigoPostal")}</label>
                            <div className="col-2">
                                <input autoComplete="off" type="text"
                                    name={'value-codigoPostal'}
                                    className="parrish-input"
                                    value={state.codigoPostal}
                                    onChange={e => handleChange("codigoPostal", e)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="parrish-form row mt-4">
                    <div className="col-xs-12 col-sm-12">
                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h3>{t("empresa.field.observaciones")}</h3>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 col-xs-12">
                                <textarea
                                    name="value-observaciones"
                                    className="form-control form-control-sm"
                                    rows="15"
                                    value={state.observaciones}
                                    onChange={(e)=>{handleChange("observaciones", e)}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xs-12 col-sm-6">
                <div className="parrish-form row ">
                    <div className="col-12 text-center">
                        <h3>{t("empresa.label.datosFacturacion")}</h3>
                    </div>
                </div>
                <div className="parrish-form row ">
                <div className="col-xs-12 col-sm-12">
                    <div className="form-group row">
                            <label htmlFor="value-razonSocial" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.razonSocial")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-razonSocial'}
                                    className="parrish-input"
                                    value={state.razonSocial}
                                    onChange={e => handleChange("razonSocial", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-cuit" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.cuit")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-cuit'}
                                    className="parrish-input"
                                    value={state.cuit}
                                    onChange={e => handleChange("cuit", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-condicionIva" className="parrish-label col-4 text-right font-weight-normal">{t("empresa.field.condicionIva")}</label>
                            <div className="col-8">
                                <SelectEntity
                                    entity="CondicionIva"
                                    label="nombre"
                                    name={'value-condicionIva'}
                                    value={state.condicionIva}
                                    required
                                    onChange={(e) => {
                                        handleChange("condicionIva", e)
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-diasCobroEfectivo" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.diasCobroEfectivo")}</label>
                            <div className="col-2">
                                <input autoComplete="off" type="text"
                                    name={'value-diasCobroEfectivo'}
                                    className="parrish-input"
                                    value={state.diasCobroEfectivo}
                                    onChange={e => handleChange("diasCobroEfectivo", e)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="parrish-form row mt-4">
                    <div className="col-12 text-center">
                        <h3>{t("empresa.label.domicilioFiscal")}</h3>
                    </div>
                </div>
                <div className="parrish-form row ">
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-direccionFiscalDomicilio" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.domicilio")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-direccionFiscalDomicilio'}
                                    className="parrish-input"
                                    value={state.direccionFiscalDomicilio}
                                    onChange={e => handleChange("direccionFiscalDomicilio", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-direccionFiscalLocalidad" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.localidad")}</label>
                            <div className="col">
                                <input autoComplete="off" type="text"
                                    name={'value-direccionFiscalLocalidad'}
                                    className="parrish-input"
                                    value={state.direccionFiscalLocalidad}
                                    onChange={e => handleChange("direccionFiscalLocalidad", e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-direccionFiscalPais" className="parrish-label col-4 text-right font-weight-normal">{t("empresa.field.pais")}</label>
                            <div className="col-8">
                                <SelectKeyValue
                                    entity="Pais"
                                    label="nombre"
                                    name={'value-direccionFiscalPais'}
                                    value={state.direccionFiscalPais}
                                    required
                                    onChange={(e) => {
                                        handleChange("direccionFiscalPais", e)
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-direccionFiscalProvincia" className="parrish-label col-4 text-right font-weight-normal">{t("empresa.field.provincia")}</label>
                            <div className="col-8">
                                <SelectKeyValue
                                    entity="Provincia"
                                    by="PaisNombre"
                                    label="nombre"
                                    name={'value-direccionFiscalProvincia'}
                                    value={state.direccionFiscalProvincia}
                                    filterValue={state.direccionFiscalPais}
                                    filterName="idPais"
                                    onChange={(e) => {
                                        handleChange("direccionFiscalProvincia", e)
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <div className="form-group row">
                            <label htmlFor="value-direccionFiscalCodigoPostal" className="parrish-label col-sm-4 col-xs-12">{t("empresa.field.codigoPostal")}</label>
                            <div className="col-2">
                                <input autoComplete="off" type="text"
                                    name={'value-direccionFiscalCodigoPostal'}
                                    className="parrish-input"
                                    value={state.direccionFiscalCodigoPostal}
                                    onChange={e => handleChange("direccionFiscalCodigoPostal", e)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="parrish-form row mt-4">
                    <div className="col-xs-12 col-sm-12">
                        <div className="parrish-form row ">
                            <div className="col-12 text-center">
                                <h3>{t("empresa.field.observacionesFacturacion")}</h3>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 col-xs-12">
                                <textarea
                                    name="value-observacionesFacturacion"
                                    className="form-control form-control-sm"
                                    rows="15"
                                    value={state.observacionesFacturacion}
                                    onChange={(e)=>{handleChange("observacionesFacturacion", e)}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}