import React from 'react'
import { useTranslation } from "react-i18next"
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import CurrencyFormat from './CurrencyFormat';

export default function SimpleDatatable(props) {
    const {
        data,
        entityName,
        columnsDefinition,
        headerTop,
        headerBottom,
        editUrl,
        onEdit,
        noAutoScroll,
        sortOptions,
        setSortOptions
    } = props;
    const [t] = useTranslation("global");
    let history = useHistory();

    const getColumns = () =>{
        return Object.keys(data).filter(key => !key.includes("__"));
    }

    const isDataColumn = (keyColumn) =>{
        if(data>0){
            return Object.keys(data[0]).find(key => keyColumn === key);
        }else{
            return false;
        }
        
    }
    
    const TableHeader = () =>{
        const toggleSortColumn = (selectedSortColumn) => {
            console.log("SELECTED SORT:", selectedSortColumn);
            if(selectedSortColumn === sortOptions.sortColumn){
                if(sortOptions.sortDirection.toUpperCase()==="ASC"){
                    setSortOptions({sortColumn: selectedSortColumn, sortDirection: "DESC"})
                }else if(sortOptions.sortDirection.toUpperCase()==="DESC"){
                    setSortOptions({sortColumn: selectedSortColumn, sortDirection: "ASC"})
                }
            }else{
                setSortOptions({sortColumn: selectedSortColumn, sortDirection: "ASC"})
            }
        }

        if(columnsDefinition!=null){
            let colSpanSkip=0;
            return(
                <tr>
                {
                    columnsDefinition.map(column => {
                        if(colSpanSkip===0){
                            colSpanSkip = column.colSpan?column.colSpan:0;
                            const translationKey = column.translationKey?column.translationKey:entityName+".field."+column.name;
                            const headerClassname = column.customClassHeader?column.customClassHeader:"bg-info sorting_disabled";
                            const sortKey = column.customSortKey?column.customSortKey:column.name;
                            let sortingClass = "sorting_disabled";
                            let onRowClick = ()=>{};
                            if((isDataColumn(column.name) || column.customSortKey) && !column.disableSort) {
                                sortingClass = "sorting";
                                onRowClick = (key)=>toggleSortColumn(key);
                                if(sortOptions?.sortColumn===sortKey){
                                    if(sortOptions?.sortDirection.toUpperCase() === "ASC"){
                                        sortingClass = "sorting_asc";
                                    }else if(sortOptions?.sortDirection.toUpperCase() === "DESC"){
                                        sortingClass = "sorting_desc";
                                    }
                                }
                            }
                            if(column.tdHeader!==true){
                                return(
                                    <th colSpan={column.colSpan} rowSpan={column.rowSpan} onClick={()=>onRowClick(sortKey)} className={`border-bottom-0 ${headerClassname} ${sortingClass}`} key={column.name}>                                        {column.customHeader?
                                            column.customHeader
                                            :
                                            t(translationKey)
                                        }
                                    </th>
                                )
                            }else{
                                return(
                                    <td colSpan={column.colSpan} rowSpan={column.rowSpan} className={headerClassname} key={column.name}>
                                        {column.customHeader?
                                            column.customHeader
                                            :
                                            t(translationKey)
                                        }
                                    </td>
                                )
                            }
                        }else{
                            colSpanSkip--;
                            return(null);
                        }
                    })
                }
                </tr>
            )
        }
        return(
            <tr>
            {
                getColumns().map(key => {                  
                    return(
                        <th className={`bg-info sorting_disabled`} key={key}>
                            {t(entityName+".field."+key)}
                        </th>
                    )
                })
            }
            </tr>
        );
    }

    const renderCell = (elementOutput, columnDefinition) =>{
        if(columnDefinition.render!=null){
            return columnDefinition.render(elementOutput);
        }else{
            let splittedCol = columnDefinition.name.split(".");
            splittedCol.forEach(element => {
                elementOutput = elementOutput?elementOutput[element]:null;
            });
            switch(columnDefinition.type) {
                case 'text':
                    return (elementOutput);
                case 'id':
                    return ("#"+elementOutput);
                case 'date':
                    const format = columnDefinition.format?columnDefinition.format:"DD-MM-yyyy";
                    return (elementOutput&&
                            <Moment className="date-centered" format={format}>{elementOutput}</Moment>
                    );
                case 'money':
                    return (
                        <CurrencyFormat
                            additionalClassName="justify-content-end"
                            prefix={columnDefinition.symbol}
                            value={elementOutput}/>
                    );
                case 'boolean':
                    const trueLabel = columnDefinition.trueLabel?columnDefinition.trueLabel:t("common.value.option.yes");
                    const falseLabel = columnDefinition.falseLabel?columnDefinition.falseLabel:t("common.value.option.no");
                    const trueClassname = columnDefinition.trueClassname?columnDefinition.trueClassname:"text-success";
                    const falseClassname = columnDefinition.falseClassname?columnDefinition.falseClassname:"text-danger";
                    
                    const label = elementOutput?trueLabel:falseLabel;
                    const className = elementOutput?trueClassname:falseClassname;
                    
                    return(
                        <div className={'d-flex justify-content-center '+className}>
                            {label}
                        </div>
                    );
                default:
                    return (
                        <p className="text-danger">Type not implemented</p>
                    );
            }
        }
    }

    const editElement = (id, element) => {
        if(noAutoScroll===false||noAutoScroll===undefined){
            window.scrollTo(0, 0);
        }
        if(editUrl){
            history.push(editUrl+id);
        }else if(onEdit){
            onEdit(element);
        }
    }

    const TableContent = () =>{
        if(columnsDefinition!=null){
            return (
                data.map((element, index) => {
                    return(<tr key={entityName+"-"+index} onDoubleClick={()=>editElement(element.id, element)}>
                    {
                        columnsDefinition.map((column, index) => 
                            <td className={"p-1"+(column.className?" "+column.className:"")} key={column.name}>
                                {renderCell(element, column)}
                            </td>
                        )
                    }
                    </tr>)
                })
            );
        }else{
            return(
                data.map((element, index) => (
                    <tr key={entityName+"-"+index} onDoubleClick={()=>editElement(element.node.id, element.node)}>
                    {
                        getColumns().map(key => {
                            let elementOutput = element[key];
                            if(key.toLowerCase()==="id"){
                                elementOutput = "#"+element[key]
                            }
                            return(<td className="p-2" key={key}>{elementOutput}</td>);
                        })
                    }
                    </tr>
                ))
            );
        }
    }
    const columnsCount = columnsDefinition!=null?columnsDefinition.length:getColumns().length
    
    return (
        <div className="row mr-0 ml-0">
            <div className="table-responsive">
                    <table className="table parrish-table dataTable table-bordered table-striped table-hover">
                        <thead className="text-align-center">
                            {headerTop &&
                                headerTop
                            }
                            <TableHeader/>
                            {headerBottom &&
                                headerBottom
                            }
                        </thead>
                        <tbody>
                        {
                            (data && data.length>0)?
                            (
                                <TableContent/>
                            )
                        :
                            <tr>
                                <td colSpan={columnsCount}>
                                    <p className="p-2 pb-0">No se encontraron resultados</p>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
            </div>
        </div>
    )
}